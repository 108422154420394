import { ISODateTime, UUID } from "api/types";

export interface MarketplaceStatusAttempt {
  id: UUID;
  createdAt: ISODateTime;
  error: string;
  isSuccess: boolean;
  statusEvent: {
    id: UUID;
    desiredStatus: DesiredStatusChoices;
    processingStatus: ProcessingStatusChoices;
  };
}

export interface MarketplaceStatusItem {
  id: UUID;
  processingStatus: ProcessingStatusChoices;
  orderId: string;
}

export enum DesiredStatusChoices {
  PROCESSING = "PROCESSING",
  SENT = "SENT",
  FINISHED = "FINISHED",
  CANCELLED = "CANCELLED",
}

export enum ProcessingStatusChoices {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
  NOT_IMPLEMENTED = "NOT_IMPLEMENTED",
}
