import { ReturnListItem } from "api/wms/returns/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { isTomorrow } from "date-fns";
import { calculateTimeDifference } from "pages/wms/unloadingsList/utils/calculateTimeDifference";
import { combineDateFormat } from "pages/wms/unloadingsList/utils/combineDateFormat";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { ReturnDate } from "./ReturnDate";

interface Props {
  fontSize: TypographyProps["fontSize"];
  fontWeight: TypographyProps["fontWeight"];
  returnDetails: ReturnListItem;
}

export const ReturnStart = ({ fontSize, fontWeight, returnDetails }: Props) => {
  if (returnDetails.startedAt)
    return (
      <ReturnDate date={returnDetails.startedAt} fontSize={fontSize} fontWeight={fontWeight} />
    );

  const combinedDate = combineDateFormat(
    returnDetails.estimatedDateOfArrivalDate,
    returnDetails.estimatedDateOfArrivalTime,
  );

  if (!combinedDate) return <EmptyValue fontSize={fontSize} fontWeight={fontWeight} />;

  if (isTomorrow(new Date(combinedDate))) return <Tag label="jutro" variant="outline" />;

  return (
    <Tag
      label={calculateTimeDifference(String(combinedDate)).label}
      variant={calculateTimeDifference(String(combinedDate)).variant}
    />
  );
};
