import { AccessorFn, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { LinkColumnDef } from "./types";
import { Link } from "components/miloDesignSystem/atoms/link";
import { EMPTY_VALUE, EmptyValue } from "../createTableColumns";

export const linkColumn = <T,>(columnDef: LinkColumnDef<T>): ColumnDef<T> => {
  const baseColumnHelper = createColumnHelper<T>();
  return baseColumnHelper.accessor((row => row) as AccessorFn<T>, {
    header: columnDef.header,
    size: columnDef.size,
    cell: info => {
      if (columnDef.textRenderer(info.row.original) === undefined)
        return (
          <EmptyValue
            fontSize={columnDef.typographyProps?.fontSize || "16"}
            fontWeight={columnDef.typographyProps?.fontWeight || "700"}
          />
        );
      return (
        <Link
          fontSize={columnDef.typographyProps?.fontSize || "16"}
          fontWeight={columnDef.typographyProps?.fontWeight || "700"}
          target={columnDef.target}
          theme={columnDef.theme || "light"}
          to={columnDef.to(info.row.original)}
          linkClassName="noWrap"
        >
          {columnDef.textRenderer(info.row.original) || EMPTY_VALUE}
        </Link>
      );
    },
  });
};
