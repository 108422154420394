import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiBoxEdit } from "components/miloDesignSystem/atoms/icons/MdiBoxEdit";
import { PackageExchangeRouting } from "routes/wms/ProductExchangeRouting";

export const packageExchangeModuleLink: ModuleLink = {
  url: "package-exchange",
  label: "Wymiana paczek",
  icon: MdiBoxEdit,
  routing: PackageExchangeRouting,
};
