import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useQuery } from "hooks";
import { manufacturersActions } from "api/manufacturers/actions";
import { assertIsDefined } from "utilities/assertIsDefined";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";

export const GeneralInfoSection = () => {
  const { query } = useQuery();
  const { data: manufacturer } = manufacturersActions.useManufacturer(Number(query.panelId), {
    enabled: Boolean(query.panelId),
  });
  const context = useScrollableContext();
  assertIsDefined(manufacturer);

  return (
    <RightPanelSection>
      <div ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700" className="mb-2">
          {manufacturer.name}
        </Typography>
      </div>
      <InfoLabel title="pełna nazwa" className="mb-2">
        <TextField.Async
          placeholder="Wpisz pełną nazwę"
          containerClassName="flex-1"
          mutationHook={manufacturersActions.usePatchManufacturer}
          transformQueryData={fullName => ({
            id: manufacturer.id,
            fullName,
          })}
          value={manufacturer.fullName}
          size="small"
        />
      </InfoLabel>
      <InfoLabel title="nazwa skrócona" className="mb-2">
        <TextField.Async
          placeholder="Wpisz nazwę skróconą"
          containerClassName="flex-1"
          mutationHook={manufacturersActions.usePatchManufacturer}
          transformQueryData={name => ({
            id: manufacturer.id,
            name,
          })}
          value={manufacturer.name}
          size="small"
        />
      </InfoLabel>
      <InfoLabel title="email" className="mb-2">
        <TextField.Async
          placeholder="Wpisz email"
          containerClassName="col-4 p-0"
          mutationHook={manufacturersActions.usePatchManufacturer}
          transformQueryData={email => ({
            id: manufacturer.id,
            email,
          })}
          value={manufacturer.email}
          size="small"
        />
      </InfoLabel>
      <InfoLabel title="telefon" className="mb-2">
        <TextField.Async
          placeholder="Wpisz nr telefonu"
          containerClassName="col-4 p-0"
          mutationHook={manufacturersActions.usePatchManufacturer}
          transformQueryData={phone => ({
            id: manufacturer.id,
            phone,
          })}
          value={manufacturer.phone}
          size="small"
        />
      </InfoLabel>
      <InfoLabel title="NIP" className="mb-2">
        <TextField.Async
          placeholder="Wpisz NIP"
          containerClassName="col-4 p-0"
          mutationHook={manufacturersActions.usePatchManufacturer}
          transformQueryData={taxId => ({
            id: manufacturer.id,
            taxId,
          })}
          value={manufacturer.taxId}
          size="small"
        />
      </InfoLabel>
      <InfoLabel title="REGON" className="mb-2">
        <TextField.Async
          placeholder="Wpisz REGON"
          containerClassName="col-4 p-0"
          mutationHook={manufacturersActions.usePatchManufacturer}
          transformQueryData={businessRegisterNumber => ({
            id: manufacturer.id,
            businessRegisterNumber,
          })}
          value={manufacturer.businessRegisterNumber}
          size="small"
        />
      </InfoLabel>
      <InfoLabel title="adres" className="mb-2 align-items-start">
        <div className="d-flex flex-1 flex-column">
          <TextField.Async
            placeholder="Wpisz adres"
            containerClassName="col-4 p-0 mb-2"
            label="Adres (ulica nr budynku/lokalu)"
            mutationHook={manufacturersActions.usePatchManufacturer}
            transformQueryData={street => ({
              id: manufacturer.id,
              street,
            })}
            value={manufacturer.street}
            size="small"
          />
          <div className="d-flex align-items-center gap-2">
            <TextField.Async
              placeholder="Wpisz kod pocztowy"
              label="Kod pocztowy"
              containerClassName="col-2 p-0"
              mutationHook={manufacturersActions.usePatchManufacturer}
              transformQueryData={postCode => ({
                id: manufacturer.id,
                postCode,
              })}
              value={manufacturer.postCode}
              size="small"
            />
            <TextField.Async
              placeholder="Wpisz miasto"
              containerClassName="col-4 p-0"
              label="Miasto"
              mutationHook={manufacturersActions.usePatchManufacturer}
              transformQueryData={city => ({
                id: manufacturer.id,
                city,
              })}
              value={manufacturer.city}
              size="small"
            />
          </div>
        </div>
      </InfoLabel>
    </RightPanelSection>
  );
};
