import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import forumIcon from "assets/images/forum.svg";
import { CallCenterOrders } from "routes/CallCenterOrders";

export const orders: ModuleLink = {
  url: "orders",
  label: "Komunikacja z klientem",
  icon: forumIcon,
  routing: CallCenterOrders,
};
