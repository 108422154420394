import { Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { RouteList as RouteListView } from "pages/manufacturingNew/routeList/RouteList";
import { ProductionProgress } from "pages/logistics/routeProgress/ProductionProgress";

export const RouteListRouting = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list/:tab`}
        component={RouteListView}
        title="Milo - Trasy"
      />
      <Route
        component={ProductionProgress}
        exact={true}
        path={`${match.path}/progress/:resourceId`}
        title="Milo - produkcja - trasa"
      />
      <Route exact={true} path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};
