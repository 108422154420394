import { TradingDocument } from "api/trading-documents/models";
import styles from "../../CreateDraftDocument.module.css";
import { cx, pluralize } from "utilities";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { CURRENCY_TYPE } from "CONSTANTS";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";

interface Props {
  draftInvoice: TradingDocument;
}

export const PaymentDetailsSection = ({ draftInvoice }: Props) => {
  return (
    <div className={cx(styles.paymentDetailsSection, "d-flex justify-content-between pt-3")}>
      <div className="d-flex align-items-center">
        <div className={cx(styles.paymentDetailsSectionContainer, "mr-3")}>
          <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
            Forma płatności
          </Typography>
          <TextField.Async
            mutationHook={usePatchTradingDocumentMutation}
            size="small"
            transformQueryData={expectedPaymentForm => ({
              id: draftInvoice.id,
              expectedPaymentForm,
            })}
            value={getExpectedPaymentForm(draftInvoice)}
          />
        </div>
        <div className={styles.paymentDetailsSectionContainer}>
          <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
            Termin płatności
          </Typography>
          {draftInvoice.basePaymentDeadlineDelta ? (
            <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
              {draftInvoice.basePaymentDeadlineDelta}{" "}
              {pluralize.pl(draftInvoice.basePaymentDeadlineDelta, {
                singular: "dzień",
                plural: "dni",
                other: "dni",
              })}
            </Typography>
          ) : (
            <EmptyValue fontSize="14" fontWeight="700" />
          )}
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <Typography color="neutralBlack88" fontSize="16" fontWeight="600">
            razem do zapłaty:
          </Typography>
          <div
            className={cx(
              styles.paymentDetailsSectionSummaryAmount,
              "d-flex align-items-center justify-content-end",
            )}
          >
            <AmountDisplay
              amount={
                draftInvoice.amountSummary.totalWithTax
                  ? String(draftInvoice.amountSummary.totalWithTax)
                  : "0.00"
              }
              className={styles.amountDisplay}
              currency={draftInvoice.currency as CURRENCY_TYPE}
              decimalTypographyProps={{ fontSize: "12", fontWeight: "700" }}
              integerTypographyProps={{ fontSize: "20", fontWeight: "600" }}
            />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <Typography color="neutralBlack88" fontSize="16" fontWeight="600">
            pozostaje do zapłaty:
          </Typography>
          <div
            className={cx(
              styles.paymentDetailsSectionSummaryAmount,
              "d-flex align-items-center justify-content-end",
            )}
          >
            <AmountDisplay
              amount={
                draftInvoice.amountSummary.totalWithTax
                  ? String(draftInvoice.amountSummary.totalWithTax)
                  : "0.00"
              }
              className={styles.amountDisplay}
              currency={draftInvoice.currency as CURRENCY_TYPE}
              decimalTypographyProps={{ fontSize: "10", fontWeight: "500" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const getExpectedPaymentForm = (draftInvoice: TradingDocument): string => {
  if (!Boolean(draftInvoice.expectedPaymentForm)) return EMPTY_VALUE;
  if (draftInvoice.expectedPaymentForm === "CASH" || draftInvoice.expectedPaymentForm === "ONLINE")
    return tradingDocumentConstants.expectedPaymentFormDict[draftInvoice.expectedPaymentForm];
  return draftInvoice.expectedPaymentForm;
};
