import { VisiblePreviousStage } from "api/manufacturingNew/models";
import { CustomSwitch } from "components/utils/customSwitch";
import { useRemovePreviousStage } from "../../hooks/useRemovePreviousStage";
import { UUID } from "api/types";
import { useTogglePreviousStageVisibility } from "../../hooks/useTogglePreviousStageVisibility";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";

interface Props {
  manufacturingStageId: UUID;
  previousStage: VisiblePreviousStage;
}

export const PreviousStage = ({ manufacturingStageId, previousStage }: Props) => {
  const removeStageMutation = useRemovePreviousStage();
  const toggleStageVisibilityMutation = useTogglePreviousStageVisibility();

  return (
    <div className="d-flex align-items-center gap-2 pb-2">
      <div className="col-3 px-0">
        <div className="position-relative">
          <div className="d-flex align-items-center">
            <CustomSwitch
              checked={previousStage.isVisible}
              disabled={toggleStageVisibilityMutation.isLoading}
              label={previousStage.stage.name}
              name="isVisible"
              onChange={() =>
                toggleStageVisibilityMutation.mutate({
                  toUpdate: {
                    isVisible: !previousStage.isVisible,
                  },
                  stage: previousStage.id,
                  manufacturingStageId,
                })
              }
            />
          </div>
        </div>
      </div>
      <div>
        <Tooltip title="Usuń etap">
          <IconButton
            icon={MdiDelete}
            onClick={() =>
              removeStageMutation.mutate({
                manufacturingStageId,
                stage: previousStage.id,
              })
            }
            variant="transparent"
          />
        </Tooltip>
      </div>
    </div>
  );
};
