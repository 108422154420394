import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { deleteManufacturingSettingsPreviousStage } from "api/manufacturingNew/calls";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";
import { AddPreviousStageToManufacturingSettings } from "api/manufacturingNew/models";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { getAnyErrorKey } from "utilities";
import { Assign } from "utility-types";

export const useRemovePreviousStage = () => {
  return withDeleteConfirmation(
    useMutation(
      ({
        stage,
      }: Assign<
        Pick<AddPreviousStageToManufacturingSettings, "stage">,
        { manufacturingStageId: UUID }
      >) => {
        return deleteManufacturingSettingsPreviousStage(stage);
      },
      ({ queryUtils, toastr }) => ({
        onMutate: args => {
          return queryUtils.handleMutate<ManufacturingStage>(
            manufacturingNewKeys.manufacturingStage(String(args.manufacturingStageId)),
            draft => {
              draft.settings.visiblePreviousStages = draft.settings.visiblePreviousStages.filter(
                visiblePreviousStage => visiblePreviousStage.id !== args.stage,
              );
            },
          );
        },
        onError: (error, _, context) => {
          const prevPanel = context as ManufacturingStage;
          queryUtils.rollback(
            manufacturingNewKeys.manufacturingStage(String(prevPanel.id)),
            prevPanel,
          );
          toastr.open({
            type: "warning",
            title: "Wymagane działanie",
            text: getAnyErrorKey(error),
          });
        },
      }),
    ),
  )();
};
