import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useQuery } from "hooks";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { queryString } from "utilities";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useBasketsColumns } from "./useBasketsColumns";
import { RightPanel } from "./rightPanel/RightPanel";
import { wmsBasketsActions } from "api/wms/baskets/actions";
import { BasketListItem } from "api/wms/baskets/models";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";

export const BasketList = () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);
  const {
    data: baskets,
    error,
    isPreviousData,
    isLoading,
    pagination,
  } = wmsBasketsActions.useGetBaskets(search);
  const columns = useBasketsColumns();
  const tableProps = useTableFeatureConnector({
    rows: baskets,
    withPagination: { pagination, defaultPaginationVisibility: true },
    withDrawer: "wmsBaskets",
  });

  const postBasketMutation = wmsBasketsActions.usePostBasket();
  return (
    <>
      <PageWrapper>
        <PageHeader
          searchInput={{
            label: "Szukaj wśród koszy",
            tags: [],
          }}
          createButton={{
            alt: "dodaj",
            disabled: false,
            img: darkPlusIcon,
            label: "Dodaj kosz",
            onClick: () => postBasketMutation.mutate({}),
          }}
          viewLabel="WMS_BASKETS"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<BasketListItem>
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={mainListUiSchema}
            {...tableProps}
          />

          <RightPanel />
        </div>
      </PageWrapper>
    </>
  );
};
