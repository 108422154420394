import { tradingDocumentsActions } from "api/trading-documents/actions";
import { MyPurchaseInvoiceToReview, TradingDocument } from "api/trading-documents/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { useQuery } from "hooks";
import { ReplyModal } from "../../components/actionToolbar/ReplyModal";

interface Props {
  selectedRows: TradingDocument[] | MyPurchaseInvoiceToReview[];
}

export const ConfirmInvoices = ({ selectedRows }: Props) => {
  const { query } = useQuery();
  const panelId = query.panelId;
  const {
    bulkSalesInvoiceConfirmMutation,
    replyModal,
  } = tradingDocumentsActions.useBulkSalesAccountConfirmation(panelId);

  return (
    <>
      <Tooltip title="Zatwierdź faktury">
        <IconButton
          icon={MdiCheck}
          onClick={() => {
            bulkSalesInvoiceConfirmMutation.mutate({
              tradingDocumentsIds: selectedRows.map(tradingDocument => tradingDocument.id),
            });
          }}
          variant="transparent"
          theme="dark"
        />
      </Tooltip>
      {replyModal.isOpen && replyModal.state && (
        <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
      )}
    </>
  );
};
