import { LoadingDetails } from "api/wms/models";
import { InfoLabel } from "components/common/infoLabel";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../RightPanel.module.css";
import QRCode from "qrcode.react";
import { useSelector, useStateModal } from "hooks";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { dateUtils } from "utilities";
import { getParsedDuration } from "pages/wms/shared/utils/getParsedDuration";
import { TimePicker } from "components/utils/timePicker";
import { useLoadingPatchMutation, useLoadingPatchMutationWithoutParsing } from "api/wms/hooks";
import { LoadingDeparture } from "./LoadingDeparture";
import { LoadingStart } from "../../components/LoadingStart";
import { LoadingFinish } from "../../components/LoadingFinish";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import {
  LoadingWorkspaceModal,
  LoadingWorkspaceState,
} from "./loadingWorkspaceModal/LoadingWorkspaceModal";
import { TextField } from "components/miloDesignSystem/atoms/textField/TextField";
import { assertIsDefined } from "utilities/assertIsDefined";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";

interface Props {
  loading: LoadingDetails;
  readOnly?: boolean;
  visibility?: {
    ramp?: boolean;
  };
}

export const GeneralInfoSection = ({
  loading,
  readOnly = false,
  visibility = { ramp: true },
}: Props) => {
  const ramps = useSelector(store => store.partials.wmsRamps);
  const estimatedTimeOfLoadingTimeMutation = useLoadingPatchMutation();
  const loadingWorkspaceModal = useStateModal<LoadingWorkspaceState>();

  return (
    <>
      <RightPanelSection padding="px-3 pt-0 pb-2">
        <div className="d-flex align-items-start justify-content-between gap-2">
          <div>
            {visibility.ramp && (
              <InfoLabel title="rampa">
                <Select.Async
                  disabled={readOnly}
                  items={ramps.map(ramp => ({
                    text: ramp.name,
                    type: MenuItemType.TEXT,
                    value: ramp.id,
                  }))}
                  mutationHook={useLoadingPatchMutationWithoutParsing}
                  transformQueryData={ramp => {
                    const foundRamp = ramps.find(_ramp => _ramp.id === ramp);
                    assertIsDefined(foundRamp);
                    return {
                      id: loading.id,
                      toUpdate: {
                        ramp: {
                          id: foundRamp.id,
                          name: foundRamp.name,
                        },
                      },
                    };
                  }}
                  selected={loading.ramp?.id ?? ""}
                />
              </InfoLabel>
            )}
            <InfoLabel title="planowany początek załadunku">
              <div className="d-flex align-items-center gap-2">
                <DatePicker.Async
                  isNullable
                  mutationHook={useLoadingPatchMutation}
                  transformQueryData={value => ({
                    id: loading.id,
                    toUpdate: {
                      estimatedTimeOfLoadingDate: value ? dateUtils.formatDateToIso(value) : value,
                    },
                  })}
                  label="Data"
                  value={loading.estimatedTimeOfLoadingDate}
                />
                <TimePicker
                  disabled={estimatedTimeOfLoadingTimeMutation.isLoading || readOnly}
                  inProgress={estimatedTimeOfLoadingTimeMutation.isLoading}
                  look="common"
                  onBlur={time =>
                    estimatedTimeOfLoadingTimeMutation.mutate({
                      id: loading.id,
                      toUpdate: { estimatedTimeOfLoadingTime: time || null },
                    })
                  }
                  overwrites={{
                    container: { className: styles.formHeight },
                    input: { className: styles.formHeight },
                  }}
                  value={loading.estimatedTimeOfLoadingTime || ""}
                />
              </div>
            </InfoLabel>
            <InfoLabel title="opis">
              <TextField.Async
                disabled={readOnly}
                mutationHook={useLoadingPatchMutation}
                placeholder="Wpisz opis"
                size="small"
                transformQueryData={description => ({
                  id: loading.id,
                  toUpdate: { description },
                })}
                value={loading.description}
              />
            </InfoLabel>
            <InfoLabel title="wyjazd">
              <LoadingDeparture loading={loading} />
            </InfoLabel>
            <div className="d-flex align-items-center gap-2 mb-2">
              <div className={styles.timeContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  start:
                </Typography>
                <LoadingStart fontSize="14" fontWeight="500" loading={loading} />
              </div>
              <div className={styles.timeContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  zakończenie:
                </Typography>
                <LoadingFinish fontSize="14" fontWeight="500" loading={loading} />
              </div>
              <div className={styles.timeContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  czas załadunku:
                </Typography>
                <Typography fontSize="14" fontWeight="600">
                  {loading.duration && Boolean(loading.duration.length)
                    ? getParsedDuration(loading.duration)
                    : EMPTY_VALUE}
                </Typography>
              </div>
            </div>
            <InfoLabel title="kontrahenci">
              <div className={styles.suppliers}>
                <Typography fontSize="14" fontWeight="700">
                  {loading.sellers && Boolean(loading.sellers.length)
                    ? loading.sellers.map(seller => seller.name).join(", ")
                    : EMPTY_VALUE}
                </Typography>
              </div>
            </InfoLabel>
            <InfoLabel className={styles.collaborators} title="osoby zaangażowane">
              <div className="d-flex flex-column gap-1">
                {loading.collaborators ? (
                  loading.collaborators.map(collaborator => (
                    <div
                      className="d-flex align-items-center gap-1 cursor-pointer"
                      key={collaborator.id}
                      onClick={() =>
                        loadingWorkspaceModal.open({
                          user: collaborator,
                          loading: loading.id,
                        })
                      }
                    >
                      <Avatar size="sm" user={collaborator} theme="light" />
                      <Typography className="ml-1" fontSize="12" fontWeight="700" noWrap>
                        {collaborator.firstName} {collaborator.lastName}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <EmptyValue />
                )}
              </div>
            </InfoLabel>
          </div>
          <div className="d-flex flex-column gap-1">
            <div>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                QR kod wydania
              </Typography>
            </div>
            <QRCode value={loading.qrCode} />
          </div>
        </div>
      </RightPanelSection>
      {loadingWorkspaceModal.isOpen &&
        loadingWorkspaceModal.state?.loading &&
        loadingWorkspaceModal.state.user && (
          <LoadingWorkspaceModal
            close={loadingWorkspaceModal.close}
            state={loadingWorkspaceModal.state}
          />
        )}
    </>
  );
};
