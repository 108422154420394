import { DOMAIN } from "ENDPOINTS";
import { indexesActions } from "api/manufacturing/indexes/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiQrCodeDownload } from "components/miloDesignSystem/atoms/icons/MdiQrCodeDownload";
import { MdiRedo } from "components/miloDesignSystem/atoms/icons/MdiRedo";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { ClickOutsideHandler, CommonError } from "components/utils";
import { Title } from "pages/crm/customers/customerDetails/components/Title";
import { SectionWrapper } from "pages/crm/customers/customerDetails/components/sectionWrapper/SectionWrapper";
import { useState } from "react";
import { useParams } from "react-router";
import { cx, getAnyErrorKey, dateUtils, pluralize } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const IndexSummary = () => {
  const { indexId } = useParams<{ indexId: string }>();
  const { data: indexDetails, error, isLoading } = indexesActions.useIndex(indexId);
  const isDeletedMutation = indexesActions.usePatchIndex();
  const [labelsAmount, setLabelsAmount] = useState("1");

  if (isLoading)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4 align-self-start"
        width="516px"
        minHeight={516}
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4 align-self-start"
        width="516px"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  assertIsDefined(indexDetails);

  return (
    <SectionWrapper className="mr-3 mb-3 p-3 pb-4 align-self-start" width="516px">
      <div className="d-flex flex-column">
        <Typography fontSize="26" fontWeight="700">
          {indexDetails.name || EMPTY_VALUE}
        </Typography>
        <Typography fontSize="10" fontWeight="300">
          dodano: {dateUtils.formatDateToDisplay(indexDetails.created)}
        </Typography>
      </div>
      <div className="d-flex align-items-center gap-1">
        <Typography
          color={indexDetails.isDeleted ? "neutralBlack48" : "info300"}
          fontSize="10"
          fontWeight="600"
        >
          {indexDetails.isDeleted ? "wycofano ze sprzedaży" : "w sprzedaży"}
        </Typography>
        <Button
          className="text-uppercase"
          isLoading={isDeletedMutation.isLoading}
          onClick={() =>
            isDeletedMutation.mutate({
              id: indexDetails.id,
              toUpdate: {
                isDeleted: indexDetails.isDeleted ? false : true,
              },
            })
          }
          size="small"
          startIcon={MdiRedo}
          variant="transparent"
        >
          {indexDetails.isDeleted ? "przywróć" : "wycofaj"}
        </Button>
      </div>
      <div className="d-flex flex-column gap-2 pt-3 pb-4">
        <Title>Szczegóły indeksu</Title>
        <DisplayInfo title="wariant produktu">
          <Typography color="neutralBlack88" fontSize="16" fontWeight="700">
            {indexDetails.product?.name || EMPTY_VALUE}
          </Typography>
        </DisplayInfo>
        <DisplayInfo title="kod">
          <Typography color="neutralBlack88" fontSize="16" fontWeight="700">
            {indexDetails.signature}
          </Typography>
        </DisplayInfo>
        <DisplayInfo title="SKU">
          <Typography color="neutralBlack88" fontSize="16" fontWeight="700">
            {indexDetails.sku || EMPTY_VALUE}
          </Typography>
        </DisplayInfo>
        <DisplayInfo className="mb-2" title="EAN">
          <Typography color="neutralBlack88" fontSize="16" fontWeight="700">
            {indexDetails.ean || EMPTY_VALUE}
          </Typography>
        </DisplayInfo>
        <DisplayInfo className="mb-2" title="paczki">
          <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
            {indexDetails.packagesCount}
          </Typography>
        </DisplayInfo>
        <div className="d-flex flex-column gap-2">
          {indexDetails.attributesValues.map((attribute, index) => (
            <DisplayInfo
              key={`${attribute.attribute.name}-${index}`}
              title={attribute.attribute.name}
            >
              <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
                {attribute.value.name}
              </Typography>
            </DisplayInfo>
          ))}
        </div>
      </div>
      <div className="d-flex flex-column gap-2 pt-3 pb-4">
        <Title>Etykiety</Title>
        <div className="d-flex flex-column gap-2">
          <div className="d-flex align-items-center gap-2">
            <Popover
              hideOnClickOutside={false}
              content={({ hide }) => (
                <ClickOutsideHandler
                  onClickOutside={() => {
                    hide();
                    setLabelsAmount("1");
                  }}
                  outsideClickIgnoreClass={ignoreClickOutsideClassName}
                >
                  <div className="d-flex flex-column gap-2">
                    <Typography color="neutralBlack88" fontSize="12" fontWeight="700">
                      Dla ilu produktów chcesz pobrać etykiety?
                    </Typography>
                    <TextField
                      onChange={event => {
                        if (Number(event.target.value) >= 0) setLabelsAmount(event.target.value);
                      }}
                      type="number"
                      value={labelsAmount}
                    />
                    <div className="d-flex align-items-center gap-3 py-2">
                      {Number(labelsAmount) > 0 ? (
                        <a
                          href={`${DOMAIN}indexes/print-labels/${indexDetails.id}?count=${labelsAmount}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <Button
                            className="text-uppercase"
                            onClick={hide}
                            size="medium"
                            variant="deepPurple"
                          >
                            Pobierz{" "}
                            {pluralize.pl(Number(labelsAmount), {
                              singular: "etykietę",
                              other: "etykiety",
                              plural: "etykiety",
                            })}
                          </Button>
                        </a>
                      ) : (
                        <Button
                          className="text-uppercase"
                          disabled
                          size="medium"
                          variant="deepPurple"
                        >
                          Pobierz etykiety
                        </Button>
                      )}
                    </div>
                  </div>
                </ClickOutsideHandler>
              )}
              variant="DARK"
            >
              <Button
                className="text-uppercase"
                size="small"
                startIcon={MdiQrCodeDownload}
                variant="gray"
              >
                Pobierz
              </Button>
            </Popover>
            <Typography className="italic" color="neutralBlack64" fontSize="12" fontWeight="600">
              na paczki z indeksu
            </Typography>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

const DisplayInfo = ({
  children,
  className,
  title,
}: {
  children: JSX.Element;
  className?: string;
  title: string;
}) => {
  return (
    <div className={cx("d-flex align-items-center gap-2", className)}>
      <Typography color="neutralBlack64" fontSize="14" fontWeight="400">
        {title}:
      </Typography>
      {children}
    </div>
  );
};

const ignoreClickOutsideClassName = "index-summary-labels-popover-ignore-class-name";
