import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import forkliftIcon from "assets/images/forklift.svg";
import { Unloadings } from "routes/wms/Unloadings";
import { tabDict } from "pages/wms/unloadingsList/utils/getTabs";
import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";

export const unloadings: ModuleLink = {
  url: "unloadings",
  label: "Rozładunki",
  icon: forkliftIcon,
  subSection: {
    renderer: <TabSubSection tabs={tabDict} baseUrl="/wms/unloadings/list" />,
    isExpandedByDefault: true,
  },
  routing: Unloadings,
};
