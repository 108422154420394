import { indexesActions } from "api/manufacturing/indexes/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { SectionWrapper } from "pages/crm/customers/customerDetails/components/sectionWrapper/SectionWrapper";
import { useParams } from "react-router";
import { getAnyErrorKey } from "utilities";
import styles from "./ExternalIndexesTab.module.css";
import cardIcon from "assets/images/card.svg";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Table } from "components/miloDesignSystem/molecules/table";
import { IndexAssignment } from "api/indexes/models";
import { useExternalIndexesColumns } from "./useExternalIndexesColumns";
import { useToggle } from "hooks";
import { AddExternalIndex } from "./addExternalIndex/AddExternalIndex";

export const ExternalIndexesTab = () => {
  const { indexId } = useParams<{ indexId: string }>();
  const { data, error, isLoading } = indexesActions.useIndexAssignments(Number(indexId), {
    enabled: Boolean(indexId),
  });
  const columns = useExternalIndexesColumns();
  const addExternalIndexModal = useToggle();

  if (isLoading)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  if (!data?.assignments.length)
    return (
      <div className="d-flex flex-column align-items-center mt-4">
        <img alt="brak numerów obcych" src={cardIcon} className={styles.emptyIcon} />
        <Typography fontSize="16" fontWeight="700" className="my-2">
          Jeszcze nie dodano numeru
        </Typography>
        <Typography color="neutralBlack48" fontSize="14" fontWeight="500">
          Dodaj pierwszy numer klikając w przycisk poniżej
        </Typography>
        <div className="pt-4">
          <Button
            onClick={addExternalIndexModal.open}
            size="medium"
            startIcon={MdiAdd}
            variant="deepPurple"
          >
            Dodaj numer obcy
          </Button>
        </div>
        {addExternalIndexModal.isOpen && <AddExternalIndex close={addExternalIndexModal.close} />}
      </div>
    );

  assertIsDefined(data);

  return (
    <div className="d-flex flex-column pb-3 flex-1">
      <div className="mt-2">
        <Table<IndexAssignment>
          columns={columns}
          rows={data.assignments}
          isLoading={false}
          error={null}
          uiSchema={{
            header: {
              backgroundColor: "neutralWhite100",
            },
            cell: {
              height: "36",
            },
          }}
        />
      </div>
      <Button
        size="small"
        variant="gray"
        onClick={addExternalIndexModal.open}
        className="ml-3 mt-2 text-uppercase"
        startIcon={MdiAdd}
      >
        Dodaj kolejny
      </Button>
      {addExternalIndexModal.isOpen && <AddExternalIndex close={addExternalIndexModal.close} />}
    </div>
  );
};
