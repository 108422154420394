import { dictToList } from "utilities";

export type Tab = "all" | "not-ordered" | "ordered" | "received" | "cancelled";

const tabDict: Record<Tab, string> = {
  all: "Wszystkie",
  "not-ordered": "Niezlecone",
  ordered: "Zlecone",
  received: "Dostarczone",
  cancelled: "Anulowane",
};

export const getTabs = () =>
  dictToList(tabDict).map(({ key, value }) => ({ name: key, label: value }));
