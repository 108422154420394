import { SchemaSearchCollection } from "api/wms/layout/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFns } from "utilities";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useCollectionColumns = () => {
  return useCreateTableColumns<SchemaSearchCollection>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: " ",
        size: 310,
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.accessor(row => row.ramp, {
        header: " ",
        id: "ramp",
        size: 50,
        cell: info => {
          const ramp = info.getValue();
          if (!ramp) return null;
          return <Tag label={ramp.name} variant="info" />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: " ",
        id: "dateTime",
        size: 100,
        cell: info => {
          const collection = info.getValue();
          if (
            (!Boolean(collection.data) && !Boolean(collection.time)) ||
            (!Boolean(collection.data) && collection.time)
          )
            return null;
          if (!Boolean(collection.time) && collection.data)
            return (
              <Typography className="text-right w-100" fontSize="12" fontWeight="700">
                {dateFns.formatRelative(new Date(collection.data), "dd.MM.yyyy", true)}
              </Typography>
            );
          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {dateFns.formatRelative(new Date(`${collection.data}T${collection.time}`))}
            </Typography>
          );
        },
      }),
    ];
  });
};
