import shoppingCardIcon from "assets/images/shoppingCartPink.svg";
import salesIcon from "assets/images/store.svg";
import homeIcon from "assets/images/homeLight.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import favicon from "assets/images/favicons/fv_sales.svg";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { MdiShoppingCart } from "components/miloDesignSystem/atoms/icons/MdiShoppingCart";
import { MdiTrolley } from "components/miloDesignSystem/atoms/icons/MdiTrolley";
import { MdiDocument } from "components/miloDesignSystem/atoms/icons/MdiDocument";
import { MdiStore } from "components/miloDesignSystem/atoms/icons/MdiStore";
import { MdiFactory } from "components/miloDesignSystem/atoms/icons/MdiFactory";
import { MdiPayments } from "components/miloDesignSystem/atoms/icons/MdiPayments";
import { MdiStickyNote2 } from "components/miloDesignSystem/atoms/icons/MdiStickyNote2";
import { Dashboard } from "routes/sales/Dashboard";

export const sales: ModuleNavigation = {
  favicon,
  color: colorPalette.magenta200,
  navigationConfig: {
    label: "Sprzedaż",
    icon: { background: "#431d87", src: shoppingCardIcon },
  },
  menuDropdownIcon: salesIcon,
  name: "SALES",
  url: "/sales",
  navigationSections: [
    {
      url: "dashboard",
      label: "Pulpit",
      icon: homeIcon,
      routing: Dashboard,
      isAccessible: false,
    },
    {
      url: "orders",
      label: "Zamówienia",
      icon: MdiShoppingCart,
      routing: () => <></>,
      isAccessible: false,
    },
    {
      url: "complaints",
      label: "Reklamacje",
      icon: MdiTrolley,
      routing: () => <></>,
      isAccessible: false,
    },
    {
      url: "manufacturing-orders",
      label: "Zlecenia do dostawców",
      icon: MdiTrolley,
      routing: () => <></>,
      isAccessible: false,
    },
    {
      url: "offers",
      label: "Oferty",
      icon: MdiDocument,
      routing: () => <></>,
      isAccessible: false,
    },
    {
      url: "sales-accounts",
      label: "Konta sprzedażowe",
      icon: MdiStore,
      routing: () => <></>,
      isAccessible: false,
    },
    {
      url: "producers",
      label: "Producenci",
      icon: MdiFactory,
      routing: () => <></>,
      isAccessible: false,
    },
    {
      url: "price-lists",
      label: "Cenniki",
      icon: MdiPayments,
      routing: () => <></>,
      isAccessible: false,
    },
    {
      url: "sms-template",
      label: "Szablony SMS",
      icon: MdiStickyNote2,
      routing: () => <></>,
      isAccessible: false,
    },
  ],
};
