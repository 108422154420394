import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import supportNavIcon from "assets/images/supportAgent.svg";
import supportMenuIcon from "assets/images/bok.svg";
import { dashboard } from "./routes/dashboard";
import { routes } from "./routes/routes";
import favicon from "assets/images/favicons/fv_bok.svg";
import { orders } from "./routes/orders";

export const callCenter: ModuleNavigation = {
  favicon,
  color: "#55bca6",
  navigationConfig: {
    label: "Biuro obsługi klienta",
    icon: {
      background: "#1e4c40",
      src: supportNavIcon,
    },
  },
  dashboard,
  menuDropdownIcon: supportMenuIcon,
  name: "CALL_CENTER",
  url: "/call-center",
  navigationSections: [orders, routes],
};
