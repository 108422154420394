import { Checkbox, CommonError, Modal, Pagination } from "components/utils";
import localStyles from "./AddEmployeeModal.module.css";
import cx from "classnames";
import whitePlusIcon from "assets/images/20w.svg";
import { useUpholsteriesEmployeesQuery } from "api/upholsteries/hooks";
import { useState } from "react";
import { Button } from "components/common";
import { useQuery, useToastr } from "hooks";
import { getAnyErrorKey, queryString } from "utilities";
import { Employee } from "api/new-production-plans/models";
import { useListCheckboxes } from "hooks/useListCheckboxes";
import { pluralize } from "utilities";
import { useQueryClient } from "react-query";
import { addEmployeesToProductionPlan } from "api/new-production-plans/calls";
import { productionPlansKeys } from "api/new-production-plans/keys";
import { useMutation } from "hooks/useMutation";
import { FiltersBar } from "./subcomponents/filtersBar/FiltersBar";
import { EmployeeList } from "./subcomponents/employeeList/EmployeeList";
import { EmployeeKindExtended } from "CONSTANTS";

interface Props {
  assignedEmployees: Employee[];
  close: () => void;
  productionPlanId: string;
  refetch: () => void;
}

export interface Filters {
  search: string;
  kind: EmployeeKindExtended | "";
}

export const AddEmployeeModal = ({
  assignedEmployees,
  close,
  productionPlanId,
  refetch,
}: Props) => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const [filters, setFilters] = useState<Filters>({ search: "", kind: "" });
  const search = queryString.stringify({
    page: query.page,
    search: filters.search,
    kind: filters.kind,
  });
  const { data: employees, error, isLoading, pagination } = useUpholsteriesEmployeesQuery(search);
  const checkboxes = useListCheckboxes("default");
  const toastr = useToastr();
  const queryClient = useQueryClient();

  const assignedEmployeeIds = new Set(assignedEmployees.map(employee => employee.id));
  const unassignedEmployees = employees.filter(employee => !assignedEmployeeIds.has(employee.id));
  const unassignedEmployeeIds = unassignedEmployees.map(employee => employee.id);
  const areAllEmployeesChecked = checkboxes.areAllChecked(unassignedEmployeeIds);

  const pickAllCheckbox = (
    <Checkbox
      checked={areAllEmployeesChecked && checkboxes.valuesArray.length > 0}
      className="mr-2"
      label={
        areAllEmployeesChecked && checkboxes.valuesArray.length > 0
          ? "Odznacz wszystkich"
          : "Zaznacz wszystkich"
      }
      name=""
      onChange={() => {
        areAllEmployeesChecked ? checkboxes.reset() : checkboxes.addMany(unassignedEmployees);
      }}
      overwrites={{ wrapper: { style: { marginLeft: 8 } } }}
    />
  );

  const addEmployeeToProductionPlanMutation = useMutation(
    ({ ids, productionPlanId }: { ids: number[]; productionPlanId: string }) =>
      addEmployeesToProductionPlan({
        stageManufacturingPlanId: productionPlanId,
        employees: ids,
      }),
    {
      onSuccess: () => {
        refetch();
        queryClient.invalidateQueries({
          queryKey: productionPlansKeys.shelfItems(productionPlanId),
        });
        close();
      },
      onError: error => {
        close();
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    },
  );

  if (error) {
    return <CommonError status={error._httpStatus_} />;
  }

  if (isLoading) return null;

  return (
    <Modal isOpen close={close} overrides={{ container: { className: localStyles.modal } }}>
      <div>
        <div className={cx(localStyles.title, "mt-2 mb-4")}>Dodaj pracowników</div>
        <FiltersBar filters={filters} setFilters={setFilters} />
        <div className={localStyles.employees}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div
              className={cx(
                localStyles.checkedNumberLabel,
                "d-flex align-items-center justify-content-center",
              )}
            >
              {pickAllCheckbox}
              (zaznaczono {checkboxes.valuesArray.length}{" "}
              {pluralize.pl(checkboxes.valuesArray.length, {
                singular: "pracownika",
                plural: "pracowników",
                other: "pracowników",
              })}
              )
            </div>
            <Button
              kind="primary"
              size="medium"
              onClick={() =>
                addEmployeeToProductionPlanMutation.mutate({
                  ids: checkboxes.valuesArray.map(value => value.id),
                  productionPlanId: productionPlanId,
                })
              }
              disabled={checkboxes.valuesArray.length === 0}
            >
              <div className="d-flex align-items-center">
                <img alt="" src={whitePlusIcon} className="mr-1" />
                <span className="">Dodaj pracowników</span>
              </div>
            </Button>
          </div>
          <EmployeeList
            assignedEmployees={assignedEmployees}
            checkboxes={checkboxes}
            employees={employees}
          />
          <Pagination pagination={pagination} sticky />
        </div>
      </div>
    </Modal>
  );
};
