import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiMiloDisconnect = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 12C24 14.4406 23.2714 16.711 22.0198 18.6056L18.6991 15.2849C19.186 14.2938 19.4595 13.1788 19.4595 12C19.4595 7.88025 16.1197 4.54054 12 4.54054C10.8212 4.54054 9.70623 4.81398 8.71511 5.30089L5.39436 1.98015C7.28897 0.728598 9.5594 0 12 0C18.6274 0 24 5.37258 24 12ZM0 12C0 9.0463 1.06716 6.34184 2.83695 4.25116L0.792969 2.20718L2.20718 0.792969L23.2072 21.793L21.793 23.2072L19.7488 21.1631C17.6582 22.9328 14.9537 24 12 24C5.37258 24 0 18.6274 0 12ZM4.54054 12C4.54054 10.3007 5.10877 8.73406 6.06559 7.4798L8.87106 10.2853C8.59158 10.7942 8.43262 11.3786 8.43262 12.0002C8.43262 13.9705 10.0299 15.5678 12.0002 15.5678C12.6218 15.5678 13.2062 15.4088 13.7151 15.1293L16.5202 17.9344C15.2659 18.8912 13.6993 19.4595 12 19.4595C7.88025 19.4595 4.54054 16.1197 4.54054 12ZM15.5678 12.0002C15.5678 12.0505 15.5667 12.1006 15.5646 12.1504L11.8499 8.43572C11.8998 8.43366 11.9499 8.43262 12.0002 8.43262C13.9705 8.43262 15.5678 10.0299 15.5678 12.0002Z"
    />
  </SvgWrapper>
);
