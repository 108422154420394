import { ListOrderGroup } from "api/order-groups/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateUtils } from "utilities";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { getManufacturingStatusTag } from "../shared/getManufacturingStatusTag";

export const useOrderGroupColumns = () => {
  return useCreateTableColumns<ListOrderGroup>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 158,
        typographyProps: {
          fontSize: "14",
          fontWeight: "700",
        },
      }),
      columnHelper.text(row => row.name, {
        header: "nazwa",
        size: 150,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.manufacturingStatus, {
        header: "status produkcji",
        size: 128,
        cell: info => {
          const manufacturingStatus = info.getValue();
          return getManufacturingStatusTag(manufacturingStatus);
        },
      }),
      columnHelper.accessor(row => row, {
        header: "odbiór",
        size: 168,
        cell: info => {
          const date = info.getValue().pickUpDate;
          const time = info.getValue().pickUpTime;
          const formattedTime = time?.length === 8 ? time.slice(0, -3) : time;
          return (
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="14" fontWeight="600" color="neutralBlack48">
                {time ? formattedTime : "brak godziny"}
              </Typography>
              <Typography fontSize="14" fontWeight="600">
                {date ? dateUtils.formatDateToDisplay(new Date(date)) : "brak daty"}
              </Typography>
            </div>
          );
        },
      }),
    ];
  });
};
