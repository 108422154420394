import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { InvoiceType, TradingDocument, TradingDocumentType } from "api/trading-documents/models";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler/FileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDownloadXml } from "components/miloDesignSystem/atoms/icons/MdiDownloadXml";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { fileFactoryUtils } from "utilities/fileFactory";

interface Props {
  invoiceType: InvoiceType;
  selectedRows: TradingDocument[];
  type: TradingDocumentType;
}

export const TradingDocumentXml = ({ invoiceType, selectedRows, type }: Props) => {
  return (
    <FileDownloadHandler
      factoryFn={() =>
        tradingDocumentFileFactory.optimaXml(
          { invoiceType, type },
          selectedRows.map(tradingDocument => tradingDocument.id),
          invoiceType === "CORRECTION"
            ? `Korekty-${fileFactoryUtils.getDate()}`
            : `Faktury-${fileFactoryUtils.getDate()}`,
        )
      }
      type="xml"
    >
      {({ download, isLoading }) => (
        <Tooltip title="Pobierz XML dla zaznaczonych">
          <IconButton
            icon={MdiDownloadXml}
            isLoading={isLoading}
            onClick={download}
            variant="transparent"
            theme="dark"
          />
        </Tooltip>
      )}
    </FileDownloadHandler>
  );
};
