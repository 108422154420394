import { manufacturersActions } from "api/manufacturers/actions";
import { manufacturingSchemaActions } from "api/manufacturing/schemas/actions";
import { Stage } from "api/other/models";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError } from "components/utils";
import { RightPanelSection } from "components/utils/drawer";
import { useQuery, useSelector } from "hooks";
import { queryString } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";

export const StagesSection = () => {
  const { query } = useQuery();
  const { data: manufacturer } = manufacturersActions.useManufacturer(Number(query.panelId), {
    enabled: Boolean(query.panelId),
  });
  const stagesSearch = queryString.stringify({
    pageSize: 999,
    manufacturerId: query.panelId,
  });
  const stages = useSelector(store => store.partials.stages);
  const {
    data: manufacturerStages,
    error,
    isLoading,
  } = manufacturingSchemaActions.useManufacturerStages(stagesSearch);
  const addManufacturerStage = manufacturingSchemaActions.usePostManufacturerStage();
  const removeManufacturerStage = manufacturingSchemaActions.useRemoveManufacturerStage();

  assertIsDefined(manufacturer);

  if (isLoading)
    return (
      <RightPanelSection title="Etapy, w których bierze udział">
        <div className="p-3 d-flex align-items-center justify-content-center">
          <Spinner size={26} />
        </div>
      </RightPanelSection>
    );

  if (error)
    return (
      <RightPanelSection title="Etapy, w których bierze udział">
        <div className="p-3 d-flex align-items-center justify-content-center">
          <CommonError status={error._httpStatus_} />
        </div>
      </RightPanelSection>
    );

  if (!manufacturerStages) return null;

  const isStageAdded = (stage: Stage) =>
    manufacturerStages.some(manufacturerStage => manufacturerStage.schemaStage.id === stage.id);

  return (
    <RightPanelSection
      title={
        <div className="d-flex align-items-center gap-2">
          <Typography color="neutralBlack48" fontSize="14" fontWeight="700">
            Etapy, w których bierze udział
          </Typography>
          {(addManufacturerStage.isLoading || removeManufacturerStage.isLoading) && (
            <Spinner size={16} />
          )}
        </div>
      }
    >
      <div className="d-flex flex-column gap-2">
        {stages.map(stage => (
          <div className="d-flex align-items-center mb-2 gap-2" key={stage.id}>
            <Checkbox
              checked={isStageAdded(stage)}
              label={stage.name}
              onChange={() => {
                if (isStageAdded(stage)) {
                  const searchedManufacturerStage = manufacturerStages.find(
                    manufacturerStage => manufacturerStage.schemaStage.id === stage.id,
                  );
                  assertIsDefined(searchedManufacturerStage);
                  removeManufacturerStage.mutate(searchedManufacturerStage.id);
                } else {
                  addManufacturerStage.mutate({
                    manufacturer: manufacturer.id,
                    schemaStage: stage.id,
                  });
                }
              }}
              size="sm"
            />
          </div>
        ))}
      </div>
    </RightPanelSection>
  );
};
