import { StateLabel, colorType } from "components/common/stateLabel";
import { WarehouseWorkerListItem, WarehouseWorkerOccupation } from "api/wms/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useWarehousemanListColumns = () =>
  useCreateTableColumns<WarehouseWorkerListItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.firstName, {
          header: "imię",
          size: 100,
        }),
        columnHelper.text(row => row.lastName, {
          header: "nazwisko",
          size: 120,
        }),
        columnHelper.accessor(row => row.occupation, {
          header: "stanowisko",
          size: 200,
          cell: info => {
            if (!info.getValue()) return null;
            const data = occupationDict[info.getValue() as WarehouseWorkerOccupation];
            return (
              <StateLabel kind={data.color} className="overflow uppercase fw-700 ">
                {data.label}
              </StateLabel>
            );
          },
        }),
        columnHelper.accessor(row => row.isActive, {
          header: "status",
          size: 80,
          cell: info => (
            <StateLabel
              kind={info.getValue() ? "deepPurple" : "bgGrey10"}
              className="overflow uppercase fw-700 "
            >
              {info.getValue() ? "Aktywny" : "Nieaktywny"}
            </StateLabel>
          ),
        }),
        columnHelper.number(row => row.monthlyFurnitureCount?.IN || 0, {
          textAlign: "right",
          header: "l. przyj. meb.",
          size: 100,
        }),
        columnHelper.number(row => row.monthlyPackageCount?.IN || 0, {
          textAlign: "right",
          header: "l. przyj. pacz.",
          size: 100,
        }),
        columnHelper.number(row => row.monthlyFurnitureCount?.OUT || 0, {
          textAlign: "right",
          header: "l. wyd. meb.",
          size: 100,
        }),
        columnHelper.number(row => row.monthlyPackageCount?.OUT || 0, {
          textAlign: "right",
          header: "l. wyd. pacz.",
          size: 100,
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );

const occupationDict: Record<WarehouseWorkerOccupation, { color: colorType; label: string }> = {
  [WarehouseWorkerOccupation.WAREHOUSEMAN]: { color: "bgYellow2", label: "magazynier" },
  [WarehouseWorkerOccupation.PICKER]: { color: "indigo", label: "picker" },
  [WarehouseWorkerOccupation.PACKER]: { color: "blue", label: "pakujący" },
};
