import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { PackageExchangeStatus } from "api/order-packages-exchange/enums";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { assertIsDefined } from "utilities/assertIsDefined";
import { UnlinkPackage } from "pages/wms/packageExchange/shared/UnlinkPackage";
import { NormalizePackagesListItem } from "pages/wms/packageExchange/shared/ProductListItem";
import { packageColumnFactory } from "pages/wms/packageExchange/shared/packageColumnFactory";

export const useExchangeColumns = () => {
  return useCreateTableColumns<NormalizePackagesListItem>(({ columnHelper }) => {
    const columns = packageColumnFactory(columnHelper);
    return [
      columns.packageName(100),
      columns.sku(),
      columnHelper.accessor(row => row.exchangeStatus, {
        header: "status",
        size: 100,
        cell: info => {
          const status = info.getValue();
          assertIsDefined(status);
          return getPackageExchangeStatusTag(status);
        },
      }),
      columnHelper.date(row => row.confirmedAt, {
        header: "data wymiany",
        size: 104,
        format: "dd.MM.yyyy, HH:mm",
        typographyProps: {
          fontSize: "12",
          fontWeight: "600",
        },
      }),
      columns.createdBy(),
      columns.location(),
      columnHelper.text(row => row.linkedOrder?.signature, {
        header: "z zamówienia",
        size: 100,
        typographyProps: {
          fontSize: "12",
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row, {
        header: " ",
        id: "unlink",
        size: 32,
        cell: info => {
          const row = info.getValue();
          return <UnlinkPackage row={row} />;
        },
      }),
    ];
  });
};

function getPackageExchangeStatusTag(
  status: NonNullable<NormalizePackagesListItem["exchangeStatus"]>,
) {
  switch (status) {
    case PackageExchangeStatus.FINISHED:
      return <Tag label="Wymieniono" variant="success" type="outlined" />;

    case PackageExchangeStatus.IN_PROGRESS:
      return <Tag label="Przypisano" variant="info" type="outlined" />;

    case PackageExchangeStatus.NOT_STARTED:
      return <Tag label="Dostępna" variant="deepPurple50" type="outlined" />;

    default:
      const exhaustiveCheck: never = status;
      console.error(`Unhandled status: ${exhaustiveCheck}`);
      return <EmptyValue />;
  }
}
