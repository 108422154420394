import { withFilters } from "components/utils/withFilters";
import { filterList } from "./filterList";
import { TradingDocument, TradingDocumentTab } from "api/trading-documents/models";
import { RouteComponentProps } from "react-router";
import { useQuery, useToggle } from "hooks";
import { getSearch } from "../shared/utils/getSearch";
import { useFinancesAmountSummary, useTradingDocuments } from "api/trading-documents/hooks";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useAdvancesColumns } from "./useAdvancesColumns";
import { PageHeader } from "components/common";
import { dateUtils } from "utilities";
import { startOfDay, subDays } from "date-fns";
import { getTabs } from "../shared/utils/getTabs";
import { advances } from "components/common/moduleNavigation/moduleConfig/finances/routes/advances";
import { AmountSummaryModal } from "../salesInvoicesLIst/components/amountSummaryModal/AmountSummaryModal";
import { AmountSummaryBottomBar } from "../shared/components/amountSummaryBottomBar/AmountSummaryBottomBar";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { RightPanel } from "./rightPanel/RightPanel";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { ChangeIssueOrDeliveryInvoiceDate } from "../shared/tableToolbar/changeIssueOrDeliveryInvoiceDate/ChangeIssueOrDeliveryInvoiceDate";
import { LedgerAccountSelector } from "../shared/tableToolbar/ledgerAccountSelector/LedgerAccountSelector";
import { WaproFakirXml } from "../shared/tableToolbar/waproFakirXml/WaproFakirXml";
import { TradingDocumentPdf } from "../shared/tableToolbar/tradingDocumentPdf/TradingDocumentPdf";
import { DownloadRevisor } from "../shared/tableToolbar/downloadRevisor/DownloadRevisor";
import { TradingDocumentXml } from "../shared/tableToolbar/tradingDocumentXml/TradingDocumentXml";

interface HeaderProps {
  tab: TradingDocumentTab;
}

export const AdvanceInvoices = withFilters(
  filterList,
  ({ match }: RouteComponentProps<{ tab: TradingDocumentTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const search = getSearch({ invoiceType: "ADVANCE", query, tab, type: "INVOICE" });
    const {
      data: tradingDocuments,
      error,
      isLoading,
      isPreviousData,
      pagination,
    } = useTradingDocuments(search);
    const columns = useAdvancesColumns();
    const tableMultiSelect = useTableMultiSelect({ rows: tradingDocuments });
    const tableProps = useTableFeatureConnector({
      rows: tradingDocuments,
      withDrawer: "advanceInvoices",
      withMultiSelect: tableMultiSelect,
      withPagination: { pagination, defaultPaginationVisibility: true },
    });

    return (
      <PageWrapper>
        <Header tab={tab} />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<TradingDocument>
            columns={columns}
            error={error}
            isLoading={isLoading || isPreviousData}
            uiSchema={{
              header: {
                backgroundColor: "grey25",
              },
              cell: {
                height: "27",
              },
            }}
            {...tableProps}
          />
          <RightPanel />
          <TableToolbar
            close={tableMultiSelect.clear}
            numberOfSelectedItems={tableMultiSelect.selectedRows.length}
          >
            <ChangeIssueOrDeliveryInvoiceDate selectedRows={tableMultiSelect.selectedRows} />
            <LedgerAccountSelector selectedRows={tableMultiSelect.selectedRows} />
            <WaproFakirXml selectedRows={tableMultiSelect.selectedRows} />
            <TradingDocumentPdf
              invoiceType="PROFORMA"
              selectedRows={tableMultiSelect.selectedRows}
            />
            <DownloadRevisor selectedRows={tableMultiSelect.selectedRows} />
            <TradingDocumentXml
              invoiceType="PROFORMA"
              selectedRows={tableMultiSelect.selectedRows}
              type="INVOICE"
            />
          </TableToolbar>
        </div>
      </PageWrapper>
    );
  },
);

const Header = ({ tab }: HeaderProps) => {
  const { query } = useQuery({ exclude: ["panelId", "page"] });
  const search = getSearch({ invoiceType: "ADVANCE", query, tab, type: "INVOICE" });
  const { data: amountSummary, isLoading, error } = useFinancesAmountSummary(search);
  const amountSummaryModal = useToggle();

  return (
    <>
      <PageHeader
        additionalListParams={{
          type: "INVOICE",
          invoiceType: "ADVANCE",
        }}
        bottomBarButtons={
          <div>
            <div className="d-flex align-items-center justify-content-end gap-2">
              <AmountSummaryBottomBar
                amountSummary={amountSummary}
                amountSummaryIsLoading={isLoading}
                error={error}
                invoiceType="ADVANCE"
              />
              <Button
                className="text-uppercase"
                disabled={isLoading || Boolean(error)}
                endIcon={MdiArrowForward}
                onClick={amountSummaryModal.open}
                size="small"
                variant="gray"
              >
                Szczegóły
              </Button>
              <span className="line-divider lineDividerMargin" />
            </div>
          </div>
        }
        searchInput={{
          label: "Szukaj...",
          tags: [
            {
              name: "issueDateFrom",
              label: "data wystawienia (od)",
              value: dateUtils.formatDateToIso(startOfDay(subDays(new Date(), 7))),
            },
            {
              name: "issueDateTo",
              label: "data wystawienia (do)",
              value: dateUtils.formatDateToIso(new Date()),
            },
          ],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `finances/${advances.url}`,
          urlSpan: "list",
        }}
        viewLabel="ADVANCES"
      />
      {amountSummaryModal.isOpen && amountSummary && (
        <AmountSummaryModal amountSummary={amountSummary} close={amountSummaryModal.close} />
      )}
    </>
  );
};
