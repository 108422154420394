import { wmsReturnsActions } from "api/wms/returns/actions";
import { ReturnWorkspaceState, WmsReturnGroupPackage } from "api/wms/returns/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "./ReturnWorkspaceModal.module.css";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { PackageStockStatus, WmsAction } from "api/wms/models";
import { cx } from "utilities";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useReturnWorkspaceColumns } from "./useReturnWorkspaceColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import { WorkspaceInventoryHeader } from "pages/wms/shared/components/WorkspaceInventoryHeader";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";

interface Props {
  close: () => void;
  state: ReturnWorkspaceState;
}

export const ReturnWorkspaceModal = ({ close, state }: Props) => {
  const { data: workspace, error, isLoading, isFetching } = wmsReturnsActions.useGetReturnWorkspace(
    {
      wmsReturnId: state.wmsReturnId,
      user: state.user.id,
    },
  );
  const columns = useReturnWorkspaceColumns();

  if (isLoading)
    return (
      <Modal
        close={close}
        isOpen
        width={990}
        title={
          <Typography fontSize="20" fontWeight="700">
            Obszar roboczy
          </Typography>
        }
      >
        <div className="pb-5 pt-3 ml-3">
          <Spinner size={26} />
        </div>
      </Modal>
    );

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        width={990}
        title={
          <Typography fontSize="20" fontWeight="700">
            Obszar roboczy
          </Typography>
        }
      >
        <div className="mt-3 py-5">
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  assertIsDefined(workspace);

  return (
    <Modal
      close={close}
      isOpen
      width={990}
      title={
        <Typography fontSize="20" fontWeight="700">
          Obszar roboczy
        </Typography>
      }
    >
      <div className={styles.lineItemContainer}>
        <div className="d-flex align-items-center gap-2 pb-1">
          <Avatar size="sm" theme="light" user={state.user} />
          <Typography fontSize="14" fontWeight="700">
            {state.user.firstName} {state.user.lastName}
          </Typography>
        </div>
        <WorkspaceInventoryHeader
          signature={workspace.wmsReturn.signature}
          ramp={workspace.wmsReturn.ramp}
          source={WmsAction.RETURN}
          status={workspace.wmsReturn.status}
          joinedAt={workspace.user.joinedAt}
          totalProgress={workspace.wmsReturn.totalProgress}
          participation={workspace.user.participation}
        />
        <div className={styles.tableArea}>
          {workspace.wmsReturnGroups.map(returnGroup => (
            <div key={returnGroup.id}>
              <div className={styles.orderHeader}>
                <div className={styles.orderHeaderGeneralInfo}>
                  <Typography fontSize="16" fontWeight="700">
                    {returnGroup.signature}
                  </Typography>
                  {returnGroup.address.countryCode && (
                    <CountryFlag countryCode={returnGroup.address.countryCode} />
                  )}
                </div>
                <div className={styles.orderHeaderCustomer}>
                  <Typography fontSize="12" fontWeight="700" noWrap>
                    {Boolean(returnGroup.suppliers.length)
                      ? returnGroup.suppliers.map(supplier => supplier.name).join(", ")
                      : EMPTY_VALUE}
                  </Typography>
                </div>
                <div className={styles.orderHeaderAddress}>
                  <Typography fontSize="12" fontWeight="400">
                    {returnGroup.address.postCode} {returnGroup.address.city}
                  </Typography>
                </div>
              </div>
              <div className={styles.tableContainer}>
                {Boolean(returnGroup.packages.length) && (
                  <Table<WmsReturnGroupPackage>
                    rows={returnGroup.packages}
                    columns={columns}
                    isLoading={isLoading || isFetching}
                    error={error}
                    uiSchema={comfortableListUiSchema}
                    overrides={() => {
                      return {
                        row: row => {
                          return {
                            className: cx({
                              [styles.finishedItem]:
                                row.stockStatus === PackageStockStatus.IN_STOCK,
                            }),
                          };
                        },
                      };
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.closeBtn}>
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
