import {
  CreateCorrectionDocumentPayload,
  OrderPositionForCreatingInvoice,
  PreviewCorrectionDocument,
} from "api/trading-documents/models";
import { CustomModal } from "components/utils/customModal";
import { FieldArray, Formik, FormikHelpers } from "formik";
import { Assign } from "utility-types";
import { getAllPositions } from "./utils/getAllPositions";
import { getInitialValues } from "./utils/getInitialValues";
import cx from "classnames";
import styles from "./CreateCorrectionManuallyModal.module.css";
import { CustomerSection } from "pages/customers/customerOrdersList/OrdersList/createInvoicesForMultipleOrdersModal/components/CustomerSection";
import { Checkbox, FormInput } from "components/utils";
import { IsEditedLabel } from "./components/IsEditedLabel";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { validationSchema } from "./utils/validationSchema";
import { SummarySection } from "./components/SummarySection";
import { AdditionalPaymentInfoSection } from "./components/AdditionalPaymentInfoSection";
import { CurrencyConvertionSection } from "./components/currencyConvertionSection/CurrencyConvertionSection";
import { WholeAmountToPay } from "./components/WholeAmountToPay";
import { UUID } from "api/types";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { Button } from "components/miloDesignSystem/atoms/button";

interface Props {
  close: () => void;
  isOpen: boolean;
  state: {
    preview: PreviewCorrectionDocument;
    expectedPaymentForm: string;
    tradingDocumentId: UUID;
  } | null;
  type: "SALES" | "ADVANCE" | "RECEIPT";
}

export type CreateCorrectionPosition = OrderPositionForCreatingInvoice & {
  orderSignature: string;
  initialAmountWithTax: number;
  initialQuantity: number;
  initialAmountWithoutTax: number;
  initialTaxAmountBeforeRounding: number;
};

export interface CreateCorrectionValues {
  positions: Assign<CreateCorrectionPosition[], { isPositionEdited: boolean }>;
}

export const CreateCorrectionManuallyModal = ({ close, isOpen, state, type }: Props) => {
  const createCorrectionMutation = tradingDocumentsActions.useCreateCorrection(close);
  const createCorrectionForAdvanceInvoiceMutation = tradingDocumentsActions.useCreateCorrectionForAdvanceInvoice(
    close,
  );

  if (!state) return null;

  // all positions from all orders merged into one array
  const allPositions = getAllPositions(state.preview.orders);

  const handleSubmit = (
    values: CreateCorrectionValues,
    actions: FormikHelpers<CreateCorrectionValues>,
  ) => {
    const formValues: CreateCorrectionDocumentPayload = {
      tradingDocument: state.tradingDocumentId,
      positions: values.positions
        .filter(position => !position.alreadyInvoiced)
        .map(position => {
          return {
            amountWithTax: position.amountWithTax,
            discount: position.discount,
            discountAmount: position.discountAmount,
            indexId: position.indexId,
            name: position.name,
            orderItemId: position.orderItemId,
            sourceId: position.sourceId,
            quantity: position.quantity,
            vatRate: position.vatRate,
          };
        }),
    };

    (type === "ADVANCE"
      ? createCorrectionForAdvanceInvoiceMutation
      : createCorrectionMutation
    ).mutate(formValues, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return (
    <CustomModal
      close={close}
      isOpen={isOpen}
      overrides={{
        container: { className: styles.modalContainer },
        title: { className: styles.modalTitle },
      }}
      title={`Utwórz korektę ${state.preview.masterDocumentSignature &&
        state.preview.masterDocumentSignature !== undefined &&
        `(dla faktury ${state.preview.masterDocumentSignature})`}`}
    >
      <Formik
        initialValues={getInitialValues(allPositions)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="d-flex gap-4 justify-content-between">
              <div>
                <div className="px-3 py-2">
                  <div className="pb-3">
                    <div className={cx(styles.tableHeader, styles.tableHeaderPositions)}>
                      <div>#</div>
                      <div>Zaznacz</div>
                      <div>nazwa towaru</div>
                      <div>zamówienie</div>
                      <div className="d-flex align-items-center justify-content-end">liczba</div>
                      <div>jedn.</div>
                      <div className="d-flex align-items-center justify-content-end">
                        cena netto
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        cena brutto
                      </div>
                      <div className="d-flex align-items-center justify-content-end">VAT</div>
                      <div className="d-flex align-items-center justify-content-end">
                        Wartość netto
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        Wartość brutto
                      </div>
                    </div>
                    <FieldArray
                      name="positions"
                      render={({ push }) => (
                        <div className={styles.positionList}>
                          {values.positions.map((position, positionIndex) => (
                            <div
                              className={cx(
                                styles.tableRow,
                                styles.tableRowPositions,
                                styles.tableRowFixedHeight,
                                {
                                  [styles.stripesBgGrey]: position.alreadyInvoiced,
                                  [styles.inaccessible]: position.alreadyInvoiced,
                                },
                              )}
                              key={positionIndex}
                            >
                              <div className="fw-700">{position.displayPosition}.</div>
                              <div className="d-flex align-items-center justify-content-center">
                                <Checkbox
                                  checked={!position.alreadyInvoiced}
                                  name={`positions[${positionIndex}].alreadyInvoiced`}
                                  onChange={value => {
                                    if (position.alreadyInvoiced) {
                                      setFieldValue(
                                        `positions[${positionIndex}].alreadyInvoiced`,
                                        false,
                                      );
                                    } else {
                                      setFieldValue(
                                        `positions[${positionIndex}].alreadyInvoiced`,
                                        true,
                                      );
                                    }
                                  }}
                                />
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <div className={cx(styles.overflow, "fw-700")}>{position.name}</div>
                                <IsEditedLabel position={position} />
                              </div>
                              <div className="fw-700">
                                {position.orderSignature && position.orderSignature.length > 0
                                  ? position.orderSignature
                                  : "--"}
                              </div>
                              {position.alreadyInvoiced ? (
                                <div className="d-flex align-items-center justify-content-end fw-700">
                                  {position.quantity}
                                </div>
                              ) : (
                                <FormInput
                                  name={`positions[${positionIndex}].quantity`}
                                  onChange={() => {
                                    // mark position as edited
                                    setFieldValue(
                                      `positions[${positionIndex}].isPositionEdited`,
                                      true,
                                    );
                                  }}
                                  overwrites={{
                                    floatingLabel: { className: "d-none" },
                                    input: {
                                      className: styles.amountWithoutTaxFormWrapperInput,
                                    },
                                    wrapper: {
                                      className: styles.amountWithoutTaxFormWrapper,
                                    },
                                  }}
                                  type="number"
                                  maximum={values.positions[positionIndex].initialQuantity}
                                />
                              )}

                              <div className="fw-700">szt.</div>

                              <div className="d-flex align-items-center justify-content-end">
                                {position.amountWithoutTax !== undefined ? (
                                  <FinanceAmountFormat value={position.amountWithoutTax} />
                                ) : (
                                  <div className="text-red-4">błąd przelicz.</div>
                                )}
                              </div>

                              {position.alreadyInvoiced ? (
                                <div className="d-flex align-items-center justify-content-end">
                                  <FinanceAmountFormat value={position.amountWithTax} />
                                </div>
                              ) : (
                                <FormInput
                                  name={`positions[${positionIndex}].amountWithTax`}
                                  onChange={e => {
                                    const newAmountWithoutTax =
                                      Number(e.target.value) / (1 + position.vatRate / 100);

                                    // set new vat tax amount
                                    setFieldValue(
                                      `positions[${positionIndex}].taxAmountBeforeRounding`,
                                      e.target.value.length > 0
                                        ? Number(e.target.value) - newAmountWithoutTax
                                        : undefined,
                                    );

                                    // set new amount without tax
                                    setFieldValue(
                                      `positions[${positionIndex}].amountWithoutTax`,
                                      e.target.value.length > 0 ? newAmountWithoutTax : undefined,
                                    );

                                    // mark position as edited
                                    setFieldValue(
                                      `positions[${positionIndex}].isPositionEdited`,
                                      true,
                                    );
                                  }}
                                  overwrites={{
                                    floatingLabel: { className: "d-none" },
                                    input: {
                                      className: styles.amountWithoutTaxFormWrapperInput,
                                    },
                                    wrapper: {
                                      className: styles.amountWithoutTaxFormWrapper,
                                    },
                                  }}
                                  type="number"
                                />
                              )}
                              <div className="d-flex align-items-center justify-content-end">
                                {position.vatRate}%
                              </div>

                              <div className="d-flex align-items-center justify-content-end">
                                {position.amountWithoutTax !== undefined ? (
                                  <FinanceAmountFormat
                                    value={(position.quantity * position.amountWithoutTax).toFixed(
                                      2,
                                    )}
                                  />
                                ) : (
                                  <div className="text-red-4">błąd przelicz.</div>
                                )}
                              </div>

                              <div className="d-flex align-items-center justify-content-end">
                                {position.quantity < 0 ? (
                                  <div className="d-flex align-items-center justify-content-end text-color-coral">
                                    Błąd przeliczania
                                  </div>
                                ) : (
                                  <FinanceAmountFormat
                                    value={(position.quantity * position.amountWithTax).toFixed(2)}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    />
                    <SummarySection preview={state.preview} values={values} />
                    <AdditionalPaymentInfoSection
                      expectedPaymentForm={state.expectedPaymentForm}
                      preview={state.preview}
                      values={values}
                    />
                  </div>
                  <CurrencyConvertionSection preview={state.preview} values={values} />
                  <div>
                    <div className="d-flex align-items-center justify-content-end gap-3">
                      <div className="d-flex align-items-center justify-content-end body-14-600">
                        razem do zapłaty:
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        {state.preview.amountSummaryCurrencyConvert.exchangeRate ? (
                          <strong className="body-18-800">
                            <div className="d-flex">
                              <WholeAmountToPay
                                convertionRate={
                                  state.preview.amountSummaryCurrencyConvert.exchangeRate
                                }
                                currency={state.preview.currency}
                                fieldName="amountWithTax"
                                isMainSummary
                                values={values}
                              />
                            </div>
                          </strong>
                        ) : (
                          <EmptyValue fontSize="18" fontWeight="800" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CustomerSection preview={state.preview} />
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button
                className="text-uppercase"
                onClick={close}
                size="medium"
                variant="transparent"
              >
                Anuluj
              </Button>
              <Button
                className={cx("text-uppercase", {
                  [styles.noPositionsToInvoiceBtn]: !values.positions.find(
                    position => position.alreadyInvoiced === false,
                  ),
                })}
                disabled={
                  isSubmitting ||
                  !values.positions.find(position => position.alreadyInvoiced === false)
                }
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                <div className="btnBaseMedium btnBase">Wystaw korektę{isSubmitting && "..."}</div>
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </CustomModal>
  );
};
