import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useRoute } from "hooks/apiPrimitives";
import { useRef } from "react";
import { DeliverySection } from "./components/deliverySection/DeliverySection";
import { GeneralInfoSection } from "./components/GeneralInfoSection";
import { NoteSection } from "./components/NoteSection";
import { PanelHeader } from "./components/PanelHeader";
import { TitleSection } from "./components/TitleSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data: route, error, isLoading } = useRoute(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!route) return null;

  return (
    <DrawerRightPanel key={route.id} className="position-relative">
      <PanelHeader />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody myRef={clearCommentsRef}>
          <TitleSection route={route} />
          <GeneralInfoSection route={route} />
          <DeliverySection route={route} />
          <NoteSection route={route} />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};
