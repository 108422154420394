import { createColumnHelper, AccessorFn, DisplayColumnDef, ColumnDef } from "@tanstack/react-table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { cx, dateFns } from "utilities/utilities";
import { Assign } from "utility-types";
import { EMPTY_VALUE } from "../createTableColumns";
import { TextAlign } from "./types";
import { AlignedColumnHeader } from "../subcomponents/AlignedColumnHeader";

const FORMAT_RELATIVE = "formatRelative" as const;
export const dateColumn = <T,>(
  accessorFn: AccessorFn<T, string | null>,
  columnDef: Assign<
    Pick<DisplayColumnDef<T>, "size">,
    {
      textAlign?: TextAlign;
      typographyProps?: TypographyProps;
      header: string;
      /**
       * @default "dd.MM.yyyy"
       */
      format?: string | typeof FORMAT_RELATIVE;
    }
  >,
): ColumnDef<T> => {
  const { textAlign = "left" } = columnDef;
  const baseColumnHelper = createColumnHelper<T>();
  return baseColumnHelper.accessor(accessorFn, {
    id: columnDef.header,
    header: () => <AlignedColumnHeader header={columnDef.header} textAlign={textAlign} />,
    size: columnDef.size,
    cell: info => {
      const value = info.getValue();
      const formattedDate = (() => {
        if (!value) return "";
        if (columnDef.format === FORMAT_RELATIVE) {
          return dateFns.formatRelative(new Date(value));
        }

        return dateFns.format(new Date(value), columnDef.format || "dd.MM.yyyy");
      })();

      if (!value) {
        return (
          <Typography
            className={cx({
              "text-right w-100": textAlign === "right",
              "text-left w-100": textAlign === "left",
              "text-center w-100": textAlign === "center",
            })}
            fontSize={columnDef.typographyProps?.fontSize || "12"}
            fontWeight={columnDef.typographyProps?.fontWeight || "700"}
            {...columnDef.typographyProps}
          >
            {EMPTY_VALUE}
          </Typography>
        );
      }
      return (
        <Typography
          className={cx({
            "text-right w-100": textAlign === "right",
            "text-left w-100": textAlign === "left",
            "text-center w-100": textAlign === "center",
          })}
          fontSize={columnDef.typographyProps?.fontSize || "12"}
          fontWeight={columnDef.typographyProps?.fontWeight || "700"}
          {...columnDef.typographyProps}
        >
          {formattedDate}
        </Typography>
      );
    },
  }) as ColumnDef<T>;
};
