import localStyles from "./EmployeeHeader.module.css";
import cx from "classnames";
import { Employee } from "api/new-production-plans/models";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { useConfirmModal } from "hooks";
import { UUID } from "api/types";
import { useRemoveEmployee } from "../../hooks/useRemoveEmployee";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { EmployeeKindExtended } from "CONSTANTS";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { MdiViewPdf } from "components/miloDesignSystem/atoms/icons/MdiViewPdf";
import { MdiWorkProgress } from "components/miloDesignSystem/atoms/icons/MdiWorkProgress";

interface Props {
  employee: Employee;
  productionPlanId: UUID;
}

const employeeKindSuffixesDict: Record<EmployeeKindExtended, string> = {
  UPHOLSTERER: "tapicera",
  SEAMSTRESS: "szwacza",
  CUTTER: "krojczego",
  CARPENTER: "stolarza",
  PACKER: "pakowacza",
  WAREHOUSEMAN: "magazyniera",
  FOAMER: "piankowacza",
  MATTRESSER: "materacowego",
};

export const EmployeeHeader = ({ employee, productionPlanId }: Props) => {
  const confirmationModal = useConfirmModal();
  const removeEmployeeMutation = useRemoveEmployee();

  return (
    <div
      className={cx(localStyles.employeeHeader, "d-flex align-items-center", {
        [localStyles.employeeHeaderBorder]: Boolean(employee.employeeWorkingDays.length),
      })}
    >
      {employee.employeeWorkingDays.map((employeeWorkingDay, index) => {
        if (index === 0) {
          return (
            <div
              key={employeeWorkingDay.id}
              className={cx(
                localStyles.employeeHeaderInner,
                localStyles.employeeHeaderInnerFirst,
                "d-flex align-items-center justify-content-between gap-2",
              )}
            >
              <div className="d-flex align-items-center gap-2">
                <AvatarOrInitials
                  avatarSrc={employee.avatar}
                  firstName={employee.firstName}
                  lastName={employee.lastName}
                  className={cx(localStyles.avatar, localStyles.avatarSmall)}
                />
                <div>
                  {employee.firstName} {employee.lastName}
                </div>
                <Tooltip title={`Usuń ${employeeKindSuffixesDict[employee.kind]}`}>
                  <IconButton
                    icon={MdiClose}
                    onClick={() =>
                      confirmationModal.open({
                        text: `Czy na pewno chcesz usunąć z planu pracownika "${employee.firstName} ${employee.lastName}"?`,
                        confirmText: "Usuń",
                        callback: () =>
                          removeEmployeeMutation.mutate({
                            id: employee.id,
                            productionPlanId: productionPlanId,
                          }),
                      })
                    }
                    variant="transparent"
                  />
                </Tooltip>
                {!employeeWorkingDay.isAvailable && (
                  <div className={localStyles.dayOffLabel}>Urlop</div>
                )}
              </div>
              <div className="d-flex align-items-center gap-1">
                <Tooltip title="Podgląd planu">
                  <IconButton icon={MdiViewPdf} variant="transparent" />
                </Tooltip>
                <Tooltip title={`Pobierz plan dla ${employeeKindSuffixesDict[employee.kind]}`}>
                  <IconButton icon={MdiDownloadPdf} variant="transparent" />
                </Tooltip>
                <Tooltip title="Podgląd wykonanych zleceń na cały dzień">
                  <IconButton icon={MdiWorkProgress} variant="transparent" />
                </Tooltip>
              </div>
            </div>
          );
        }

        return (
          <div
            key={employeeWorkingDay.id}
            className={cx(localStyles.employeeHeaderInner, "d-flex align-items-center")}
          >
            <div
              className={cx("d-flex w-100 justify-content-between", {
                [localStyles.dayOffLabel]: !employeeWorkingDay.isAvailable,
              })}
            >
              <div>{!employeeWorkingDay.isAvailable ? "Urlop" : ""}</div>
              <Tooltip title="Podgląd wykonanych zleceń na cały dzień">
                <IconButton icon={MdiWorkProgress} variant="transparent" />
              </Tooltip>
            </div>
          </div>
        );
      })}
    </div>
  );
};
