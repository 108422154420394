import { PageHeader } from "components/common";
import { Chip } from "components/miloDesignSystem/atoms/chip";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select";
import { SelectProps } from "components/miloDesignSystem/molecules/select/types";
import { useQuery, useSelector } from "hooks";
import { useNavigate } from "hooks/useNavigate";
import { dateFns } from "utilities";
import { quarterOptions, useFinancesFilters } from "../useFinancesFilters";
import styles from "./styles.module.css";

const yearOptions = dateFns
  .eachYearOfInterval({ start: new Date(2023, 11, 30), end: new Date() })
  .map(date => dateFns.format(date, "yyyy"));

export const ListHeader = () => {
  const { months, businessEntityId, years } = useFinancesFilters();
  const { updateQuery, search } = useQuery();
  const businessEntities = useSelector(store => store.partials.businessEntities).filter(
    seller => seller.kind === "INTERNAL",
  );
  const navigate = useNavigate();

  const businessEntitiesOptions: SelectProps["items"] = businessEntities.map(businessEntity => {
    return {
      text: businessEntity.companyName,
      type: MenuItemType.TEXT,
      value: businessEntity.id,
    };
  });

  const yearsOptions: SelectProps["items"] = yearOptions.map(year => {
    return {
      text: year,
      type: MenuItemType.TEXT,
      value: year,
    };
  });

  return (
    <div>
      <PageHeader
        title="Bilans przychodów i kosztów"
        searchBar={false}
        bottomBarButtons={false}
        bottomBarTitle={
          <div className="d-flex align-items-center justify-content-between py-2 flex-1">
            <Select
              textFieldProps={{ size: "default" }}
              label="Podmiot"
              className={styles.search}
              items={businessEntitiesOptions}
              selected={businessEntityId}
              onChange={value => navigate(`/finances/finances-balance/${value}${search}`)}
            />

            <div className="d-flex align-items-center gap-1">
              {Object.entries(quarterOptions).map(([value, label]) => (
                <Chip
                  key={label}
                  label={label}
                  onClick={() => {
                    if (months.includes(value)) {
                      const newMonths = months
                        .split(",")
                        .filter(month => !value.split(",").includes(month))
                        .toString();
                      updateQuery({ months: newMonths });
                      return;
                    }
                    updateQuery({ months: `${months},${value}` });
                  }}
                  selected={months.includes(value)}
                  variant="primary"
                />
              ))}
              <Select
                className="mb-1"
                label="Rok"
                items={yearsOptions}
                selected={years}
                onChange={years => updateQuery({ years })}
              />
            </div>
          </div>
        }
        viewLabel="SHIPPING_SHIPMENTS"
      />
    </div>
  );
};
