import { useUnloads } from "api/wms/hooks";
import { Unloading, UnloadingTab } from "api/wms/models";
import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { getSearch } from "./utils/getSearch";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { getTabs } from "./utils/getTabs";
import { unloadings as unloadingsApi } from "components/common/moduleNavigation/moduleConfig/wms/routes/unloadings";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useUnloadingsColumns } from "./hooks/useUnloadingsColumns";
import { RightPanel } from "./rightPanel/RightPanel";
import { useMutation } from "hooks/useMutation";
import { postUnloading } from "api/wms/calls";
import { getAnyErrorKey } from "utilities";
import { wmsKeys } from "api/wms/keys";
import { WarehouseFillingStatus } from "../shared/components/WarehouseFillingStatus";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";

export const UnloadingsList = ({ match }: RouteComponentProps<{ tab: UnloadingTab }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const { data: unloadings, error, isPreviousData, isLoading, pagination } = useUnloads(search);
  const columns = useUnloadingsColumns();
  const tableProps = useTableFeatureConnector({
    rows: unloadings,
    withDrawer: "wmsUnloadings",
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  const createUnloadingMutation = useMutation(postUnloading, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(wmsKeys.unloads.list());
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono rozładunek",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj wśród rozładunków",
          tags: [],
        }}
        createButton={{
          alt: "utwórz",
          disabled: createUnloadingMutation.isLoading,
          img: darkPlusIcon,
          label: "Utwórz rozładunek",
          onClick: () =>
            createUnloadingMutation.mutate({
              id: crypto.randomUUID(),
            }),
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `wms/${unloadingsApi.url}`,
          urlSpan: "list",
        }}
        bottomBarButtons={<WarehouseFillingStatus />}
        viewLabel="WMS_UNLOADINGS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<Unloading>
          columns={columns}
          isLoading={isLoading || isPreviousData}
          error={error}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
      </div>
    </PageWrapper>
  );
};
