import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { FreeSingleItemQuantitiesListItem } from "api/orders/models";
import { Attributes, hasAttributes } from "components/miloDesignSystem/molecules/attributes";
import { Link } from "components/miloDesignSystem/atoms/link";

export const useProductListColumns = () => {
  return useCreateTableColumns<FreeSingleItemQuantitiesListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "mebel",
        size: 300,
        typographyProps: {
          fontSize: "16",
        },
      }),
      columnHelper.accessor(row => row.index.attributes, {
        header: "wariant",
        size: 300,
        cell: info => {
          const attributes = info.getValue();
          if (!attributes || !hasAttributes(attributes)) return <EmptyValue />;
          return <Attributes attributes={attributes} />;
        },
      }),
      columnHelper.number(row => row.packagesCount, {
        textAlign: "right",
        header: "paczki",
        size: 40,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.text(row => row.customer?.name, {
        header: "kontrahent",
        size: 120,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.date(row => row.warehouseReceivedAt, {
        header: "przyjęto",
        size: 90,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.order, {
        header: "zamówienie",
        size: 200,
        cell: info => {
          const order = info.getValue();
          if (!order) return <EmptyValue fontSize="14" fontWeight="500" />;
          return (
            <Link fontSize="14" fontWeight="500" to={`/orders/list/active/all?panelId=${order.id}`}>
              {order.signature}
            </Link>
          );
        },
      }),
    ];
  });
};
