import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { GeneralInfoSection } from "./generalInfoSection/GeneralInfoSection";
import { PanelHeader } from "./panelHeader/PanelHeader";
import { RemainingOrders } from "./remainingOrders/RemainingOrders";
import { LabelList } from "./labelList/LabelList";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { useRef } from "react";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { TitleSection } from "./titleSection/TitleSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { close } = useDrawer("externalManufacturerItems");
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data, error, isLoading } = externalManufacturingActions.useManufacturerLineItem(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(data);

  return (
    <TriggerScrollContext>
      <DrawerRightPanel className="position-relative" key={panelId}>
        <PanelHeader close={close} lineItemDetails={data} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody hasCommentsModule>
            <TitleSection lineItemDetails={data} />
            <GeneralInfoSection lineItemDetails={data} />
            <LabelList />
            <RemainingOrders lineItemDetails={data} />
          </RightPanelBody>
          <CommentsGenericSection
            outsideRef={clearCommentsRef}
            paramName="externalManufacturingLineItem"
            endpointUrl={`/external-manufacturing/orders/line-items-comments`}
            commentedObject={data}
          />
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
