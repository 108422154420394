import { Header } from "./subcomponents/header/Header";
import styles from "./WarehouseSchema.module.css";
import { SearchEntityDetails } from "./subcomponents/searchEntityDetails/SearchEntityDetails";
import { useQuery } from "hooks";
import { WarehouseSchemaToolbar } from "./subcomponents/warehouseSchemaToolbar/WarehouseSchemaToolbar";
import {
  CollectionDetails,
  CollectionKind,
  CollectionLayout,
  FieldKind,
  FillingStatus,
  LayoutSchema,
  RampActivity,
  RampStatus,
} from "api/wms/layout/models";
import { Area } from "./subcomponents/area/Area";
import { Field } from "./subcomponents/field/Field";
import { wmsLayoutActions } from "api/wms/layout/actions";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { ModuleNavigation } from "components/common/moduleNavigation/components/moduleNavigation/ModuleNavigation";
import { cx } from "utilities";
import { FulfillmentEntityDetails } from "./subcomponents/fulfillmentEntityDetails/FulfillmentEntityDetails";
import { RightPanel } from "pages/warehouseSchema/schema/subcomponents/rightPanel/RightPanel";
import { useDrawer } from "hooks/useDrawer";
import { ListAndDrawerWrapper } from "components/utils/drawer";
import { useWarehouseZoom } from "./hooks";

interface SchemaProps {
  schema: LayoutSchema;
}

export const WarehouseSchema = () => {
  const { query } = useQuery();
  const { panelId, hallId } = query;
  const drawerController = useDrawer("warehouseSchema");

  return (
    <ListAndDrawerWrapper>
      <div
        className={cx(
          styles.wrapper,
          styles.wrapperForClosedNavbar,
          "d-flex flex-1 overflow-hidden overflow-auto",
          {
            [styles.isPanelOpened]: Boolean(panelId),
          },
        )}
      >
        <div className="overflow-auto d-flex flex-column flex-1 position-relative outline-0">
          <div className={styles.menuToolbar}>
            <ModuleNavigation module="wms" />
          </div>
          <Header />
          <SearchEntityDetails />
          <FulfillmentEntityDetails />
          <Schema />
        </div>
      </div>
      {drawerController.isActive(panelId) && (
        <RightPanel close={drawerController.close} selectedHall={Number(hallId)} />
      )}
      <WarehouseSchemaToolbar />
    </ListAndDrawerWrapper>
  );
};

const Schema = () => {
  const { query } = useQuery();
  const { hallId, kind } = query;
  const drawerController = useDrawer("warehouseSchema");
  const { data: hall } = wmsLayoutActions.useLayoutSchema(Number(hallId), {
    enabled: Boolean(hallId),
  });
  const zoom = useWarehouseZoom();
  const viewScale = zoom / 100;

  const collectionId = query["searchCollectionId"];
  const { data: collectionLayout } = wmsLayoutActions.useRouteLayoutCollection(
    { hallId: Number(hallId), kind: kind as CollectionKind, collectionId: Number(collectionId) },
    {
      enabled: Boolean(collectionId) && Boolean(hallId),
    },
  );

  const { data: collectionDetails } = wmsLayoutActions.useCollectionDetails(
    {
      id: Number(collectionId),
      kind: kind as CollectionKind,
    },
    {
      enabled: Boolean(collectionId) && Boolean(kind),
    },
  );

  const { data: rampStatus } = wmsLayoutActions.useRampStatus(Number(hallId), {
    enabled: Boolean(hallId),
  });

  const { data: fillingStatus } = wmsLayoutActions.useFillingStatus(Number(hallId), {
    enabled: Boolean(hallId),
  });

  if (!hallId || !hall || !hall.schema) return null;

  return (
    <>
      <div className={styles.container} style={{ transform: `scale(${viewScale})` }}>
        <MarginBottom schema={hall.schema} />
        <MarginRight schema={hall.schema} />
        <Background schema={hall.schema} />
        {hall.schema?.areas.map(area => {
          return (
            <Area area={area} viewScale={viewScale} key={area.id}>
              {area.fields.map(field => {
                const color = getFieldColor({
                  collectionDetails,
                  collectionLayout,
                  rampStatuses: rampStatus,
                  isDetailPanelOpened: drawerController.isActive(field.id),
                  fillingStatus,
                  field,
                  isCurrentlySearching: Boolean(collectionId),
                });

                return (
                  <div
                    key={String(field.id) + field.wmsId}
                    onClick={() => drawerController.togglePanel(field.id)}
                  >
                    <Field
                      key={`${field.kind}-${field.id}`}
                      area={area}
                      field={field}
                      viewScale={viewScale}
                      color={color || undefined}
                    />
                  </div>
                );
              })}
            </Area>
          );
        })}
      </div>
    </>
  );
};

const Background = ({ schema }: SchemaProps) => {
  const widths = schema.areas.map(area => area.coords.x + area.width).sort((a, b) => a - b);
  const heights = schema.areas.map(area => area.coords.y + area.height).sort((a, b) => a - b);
  const backgroundFactor = -40;
  return (
    <div
      className={styles.background}
      style={{
        top: backgroundFactor,
        left: 0,
        height: heights[heights.length - 1] + MARGIN_BOTTOM,
        width: widths[widths.length - 1] + MARGIN_RIGHT,
      }}
    />
  );
};

const MarginRight = ({ schema }: SchemaProps) => {
  const widths = schema.areas.map(area => area.coords.x + area.width).sort((a, b) => a - b);
  return (
    <div
      className={styles.margin}
      style={{
        height: `${MARGIN_BOTTOM}px`,
        width: `${MARGIN_TOP}px`,
        top: 0,
        left: widths[widths.length - 1],
      }}
    />
  );
};

const MarginBottom = ({ schema }: SchemaProps) => {
  const heights = schema.areas.map(area => area.coords.y + area.height).sort((a, b) => a - b);
  return (
    <div
      className={styles.margin}
      style={{
        top: heights[heights.length - 1],
        height: `${MARGIN_BOTTOM}px`,
        width: `${MARGIN_TOP}px`,
        left: 0,
      }}
    />
  );
};

export const MARGIN_TOP = 500;
export const MARGIN_BOTTOM = 1000;
export const MARGIN_RIGHT = 1000;

function getFieldColor({
  field,
  fillingStatus,
  collectionLayout,
  rampStatuses,
  collectionDetails,
  isDetailPanelOpened,
  isCurrentlySearching,
}: {
  collectionDetails: CollectionDetails | null;
  collectionLayout: CollectionLayout | null;
  rampStatuses: RampStatus[] | null;
  fillingStatus: FillingStatus | null;
  field: LayoutSchema["areas"][number]["fields"][number];
  isDetailPanelOpened: boolean;
  isCurrentlySearching: boolean;
}) {
  if (isDetailPanelOpened) return colorPalette.deepPurple400;
  if (field.wmsId === null) return colorPalette.danger900;

  if (isInCollection(field, collectionLayout)) return collectionDetails?.color;

  if (field.kind === FieldKind.RAMP) {
    const rampStatus = rampStatuses?.find(rampStatus => rampStatus.id === field.wmsId);
    const isRampActive = rampStatus?.activity === RampActivity.ACTIVE;
    if (isRampActive) {
      return colorPalette.success500;
    }
  }

  if (isFilled(field, fillingStatus) && !isCurrentlySearching) return colorPalette.orange200;

  return "";
}

function isInCollection(
  field: LayoutSchema["areas"][number]["fields"][number],
  layoutCollection: CollectionLayout | null,
): boolean {
  switch (field.kind) {
    case FieldKind.COLUMN:
      return Boolean(layoutCollection?.columns.includes(Number(field.wmsId)));

    case FieldKind.RACK:
      return Boolean(layoutCollection?.racks.includes(Number(field.wmsId)));

    case FieldKind.FIELD:
      return Boolean(layoutCollection?.fields.includes(Number(field.wmsId)));

    case FieldKind.RAMP:
      return Boolean(layoutCollection?.ramps.includes(String(field.wmsId)));

    default:
      const exhaustiveCheck: never = field.kind;
      console.error(`Unhandled field kind: ${exhaustiveCheck}`);
      return false;
  }
}

function isFilled(
  field: LayoutSchema["areas"][number]["fields"][number],
  fillingStatus: FillingStatus | null,
): boolean {
  switch (field.kind) {
    case FieldKind.COLUMN:
      return Boolean(fillingStatus?.columns.includes(Number(field.wmsId)));

    case FieldKind.RACK:
      return Boolean(fillingStatus?.racks.includes(Number(field.wmsId)));

    case FieldKind.FIELD:
      return Boolean(fillingStatus?.fields.includes(Number(field.wmsId)));

    case FieldKind.RAMP:
      return Boolean(fillingStatus?.ramps.includes(String(field.wmsId)));

    default:
      const exhaustiveCheck: never = field.kind;
      console.error(`Unhandled field kind: ${exhaustiveCheck}`);
      return false;
  }
}
