import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useConfirmModal, useRedux } from "hooks";
import { useQueryClient } from "react-query";
import { useMutation } from "hooks/useMutation";
import { deleteManufacturingStage } from "api/manufacturingNew/calls";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiRedo } from "components/miloDesignSystem/atoms/icons/MdiRedo";

interface Props {
  close: () => void;
  manufacturingStage: ManufacturingStage;
}

export const PanelHeader = ({ close, manufacturingStage }: Props) => {
  const queryClient = useQueryClient();
  const confirmModal = useConfirmModal();
  const { isTriggerElementHidden } = useScrollableContext();
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());
  const deleteMutation = useMutation(deleteManufacturingStage, {
    onSuccess: () => {
      queryClient.invalidateQueries(manufacturingNewKeys.manufacturingStages());
      refetchPartials();
      close();
    },
  });

  return (
    <RightPanelHeader>
      <div className="d-flex w-100 align-items-center">
        {isTriggerElementHidden && (
          <SlidingHeaderWrapper className="nowrap">{manufacturingStage.name}</SlidingHeaderWrapper>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Button
            className="text-uppercase"
            disabled
            size="small"
            startIcon={MdiRedo}
            variant="gray"
          >
            Przywróć ustawienia domyślne
          </Button>
          <span className="divider line-divider" />
          <Menu
            disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
            menuItems={[
              {
                type: MenuItemType.TEXT,
                options: {
                  color: "red100",
                  iconStart: MdiDelete,
                },
                onClick: () =>
                  confirmModal.open({
                    text: `Czy na pewno chcesz usunąć etap ${manufacturingStage.name}?`,
                    confirmText: "Usuń",
                    callback: () => deleteMutation.mutate(manufacturingStage.id),
                  }),
                text: "Usuń etap",
              },
            ]}
          />
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </div>
    </RightPanelHeader>
  );
};
