import { useMutation } from "hooks/useMutation";
import { wmsReturnFileFactory, wmsReturnsApi } from "./api";
import { getAnyErrorKey } from "utilities";
import { CreateReturn, ReturnDetails } from "./models";
import { FormikHelpers } from "formik";
import { wmsReturnsKeys } from "./keys";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createApiQuery } from "hooks/createApiQuery";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useErrorToastr } from "hooks/useErrorToastr";
import { UUID } from "api/types";
import { fileDownloader } from "fileDownloader";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { assertIsDefined } from "utilities/assertIsDefined";

const useGetReturns = createPaginatedApiQuery(wmsReturnsApi.getReturns);
const useGetReturn = createApiQuery(wmsReturnsApi.getReturn);
const useGetPackagesToReturn = createPaginatedApiQuery(wmsReturnsApi.getPackagesToReturn);
const useGetPackageToReturn = createApiQuery(wmsReturnsApi.getPackageToReturnDetails);
const useGetReturnWorkspace = createApiQuery(wmsReturnsApi.getReturnWorkspaceInventory);

const usePostReturn = (close: () => void) => {
  const createReturn = useMutation(wmsReturnsApi.postReturn, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(wmsReturnsKeys.list());
      close();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Dodano zwrot",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  const handleSubmit = (values: CreateReturn, actions: FormikHelpers<CreateReturn>) => {
    createReturn.mutate(values, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

const useDownloadReturnPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (id: UUID, signature: string) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = wmsReturnFileFactory.returnPdf({
      id,
      signature,
    });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useDownloadReturnLineItemPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (id: UUID, signature: string, wmsReturnId: UUID) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = wmsReturnFileFactory.returnLineItemPdf({
      id,
      signature,
      wmsReturn: wmsReturnId,
    });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const usePostStartReturn = () => {
  return useMutation(wmsReturnsApi.postStartReturn, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostFinishReturn = () => {
  return useMutation(wmsReturnsApi.postFinishReturn, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useRemoveReturn = (close: () => void, returnDetails: ReturnDetails) => {
  return withDeleteConfirmation(
    useMutation(wmsReturnsApi.deleteReturn, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(wmsReturnsKeys.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto zwrot "${returnDetails.signature}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć ten zwrot?",
  )();
};

const usePatchReturn = () => {
  return useMutation(wmsReturnsApi.patchReturn, ({ queryUtils }) => ({
    onMutate: args => {
      const prevList = queryUtils.handlePaginatedListUpdate(wmsReturnsKeys.list(), args.id, args);
      const prevPanel = queryUtils.handleMutate(wmsReturnsKeys.details(args.id), args);
      return { prevList, prevPanel };
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollbackList(wmsReturnsKeys.list(), onMutateReturn.prevList, id);
      queryUtils.rollback(wmsReturnsKeys.details(id), onMutateReturn.prevPanel, error);
    },
  }));
};

export const wmsReturnsActions = {
  useDownloadReturnLineItemPdf,
  useDownloadReturnPdf,
  useGetPackagesToReturn,
  useGetPackageToReturn,
  useGetReturns,
  useGetReturn,
  useGetReturnWorkspace,
  usePatchReturn,
  usePostReturn,
  usePostFinishReturn,
  usePostStartReturn,
  useRemoveReturn,
};
