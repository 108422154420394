import { customerActions } from "api/customers/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiMilo } from "components/miloDesignSystem/atoms/icons/MdiMilo";
import { useState } from "react";
import { useParams } from "react-router";
import { assertIsDefined } from "utilities/assertIsDefined";
import { ConnectAppWithCustomer } from "./ConnectAppWithCustomer";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { MdiMiloDisconnect } from "components/miloDesignSystem/atoms/icons/MdiMiloDisconnect";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";

export const MiloIntegration = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = customerActions.useCustomer(Number(customerId));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const editCustomerConnectionMutation = customerActions.usePatchCustomer();
  assertIsDefined(customer);

  if (!isSubmitting && !customer.isConnected)
    return (
      <div className="d-flex flex-column gap-3 pt-2 pb-4">
        <Typography
          className="text-uppercase pt-2"
          color="neutralBlack48"
          fontSize="10"
          fontWeight="700"
        >
          Łączenie aplikacji z klientem
        </Typography>
        <Button
          className="text-uppercase"
          onClick={() => setIsSubmitting(true)}
          size="small"
          startIcon={MdiMilo}
          variant="gray"
        >
          Połącz Milo z klientem
        </Button>
      </div>
    );

  if (isSubmitting)
    return (
      <div className="d-flex flex-column gap-2 pt-2">
        <Typography
          className="text-uppercase pt-2"
          color="neutralBlack48"
          fontSize="10"
          fontWeight="700"
        >
          Łączenie aplikacji z klientem
        </Typography>
        <ConnectAppWithCustomer customer={customer} setIsSubmitting={setIsSubmitting} />
      </div>
    );

  return (
    <div className="d-flex flex-column gap-3 pt-2 pb-4">
      <Typography
        className="text-uppercase pt-2"
        color="neutralBlack48"
        fontSize="10"
        fontWeight="700"
      >
        Łączenie aplikacji z klientem
      </Typography>
      <TextField.Async
        containerClassName="w-50"
        disabled
        label="Klucz producenta"
        mutationHook={customerActions.usePatchCustomer}
        size="small"
        transformQueryData={integrationToken => ({
          id: customer.id,
          toUpdate: {
            integrationToken,
          },
        })}
        type="password"
        value={customer.integrationToken}
      />
      <TextField.Async
        disabled
        label="Adres Milo"
        mutationHook={customerActions.usePatchCustomer}
        size="small"
        transformQueryData={url => ({
          id: customer.id,
          toUpdate: {
            url,
          },
        })}
        value={customer.url}
      />
      {customer.isConnected && (
        <div>
          <Tag label="Połączono" startIcon={MdiCheck} variant="success" />
        </div>
      )}
      <Button
        className="text-uppercase"
        onClick={() =>
          editCustomerConnectionMutation.mutate({
            id: customer.id,
            toUpdate: {
              isConnected: false,
            },
          })
        }
        size="small"
        startIcon={MdiMiloDisconnect}
        variant="gray"
      >
        Rozłącz
      </Button>
      <Typography className="italic" color="neutralBlack48" fontSize="10" fontWeight="600" noWrap>
        Po rozłączeniu możliwe będzie wprowadzenie nowych danych
      </Typography>
    </div>
  );
};
