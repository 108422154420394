import { ManufacturingStatus } from "api/routes/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";

export function getManufacturingStatusTag(manufacturingStatus: ManufacturingStatus) {
  switch (manufacturingStatus) {
    case ManufacturingStatus.FINISHED:
      return <Tag label="Przygotowana" variant="success" />;

    case ManufacturingStatus.IN_PROGRESS:
      return <Tag label="w trakcie" variant="warning" />;

    case ManufacturingStatus.PENDING:
      return <Tag label="nie rozpoczęto" variant="quaternary" type="outlined" />;

    default:
      const exhaustiveCheck: never = manufacturingStatus;
      console.error(`Unhandled manufacturing status: ${exhaustiveCheck}`);
      return null;
  }
}
