import { getProfile, patchProfile, profilesApi } from "api/profiles/calls";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createPrimitiveHook } from "hooks/createPrimitiveHook";
import { useMutation } from "hooks/useMutation";
import { createAsyncUpdateConnector, getAnyErrorKey } from "utilities";

const useProfiles = createPaginatedApiQuery(profilesApi.getProfiles);
export const useProfile = createPrimitiveHook(getProfile);
export const useUpdateProfile = createAsyncUpdateConnector(patchProfile);

const usePatchProfileModule = () =>
  useMutation(profilesApi.patchProfileModule, ({ toastr }) => ({
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

export const profilesActions = { usePatchProfileModule, useProfiles };
