import { ReturnDetails, ReturnStatus } from "api/wms/returns/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { StartOrFinishReturnButton } from "./StartOrFinishReturnButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { wmsReturnsActions } from "api/wms/returns/actions";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";

interface Props {
  close: () => void;
  returnDetails: ReturnDetails;
}

export const PanelHeader = ({ close, returnDetails }: Props) => {
  const { isTriggerElementHidden } = useScrollableContext();
  const handleDownloadReturnPdf = wmsReturnsActions.useDownloadReturnPdf();
  const deleteMutation = wmsReturnsActions.useRemoveReturn(close, returnDetails);

  return (
    <RightPanelHeader>
      <div className="d-flex align-items-center gap-2">
        <StartOrFinishReturnButton returnDetails={returnDetails} />
        {isTriggerElementHidden && (
          <SlidingHeaderWrapper className="noWrap">{returnDetails.signature}</SlidingHeaderWrapper>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
        <Tooltip title="Pobierz etykiety zwrotu">
          <IconButton
            icon={<MdiQrCode size="16" />}
            onClick={() => handleDownloadReturnPdf(returnDetails.id, returnDetails.signature)}
            variant="transparent"
          />
        </Tooltip>
        <Menu
          disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
          menuItems={[
            {
              type: MenuItemType.TEXT,
              options: {
                disabled:
                  deleteMutation.isLoading || returnDetails.status === ReturnStatus.FINISHED,
                color: "red100",
                iconStart: MdiDelete,
              },
              onClick: () => deleteMutation.mutate(returnDetails.id),
              text: "Usuń",
            },
          ]}
        />
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};
