import { UUID } from "api/types";
import { ApiFetcher } from "hooks/createApiQuery";
import { developerKeys } from "./keys";
import { queryFetch } from "apiConnectors/queryFetch";
import { Developer } from "api/other/models";
import { DeepPartial } from "utility-types";

const getDeveloperSettings = ({ id }: { id: UUID }): ApiFetcher<Developer["settings"][number]> => ({
  key: developerKeys.settings.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/developers/settings/" + id,
    }),
});

const patchDeveloperSettings = (data: DeepPartial<Developer["settings"][number]>) =>
  queryFetch<Developer["settings"][number]>({
    method: "PATCH",
    url: "/developers/settings/" + data.id,
    data,
  });

export const developerApi = {
  getDeveloperSettings,
  patchDeveloperSettings,
};
