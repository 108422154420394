import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { financesKeys } from "./keys";
import { FinanceReportBalance } from "./models";
import { CreateDraftDocumentPositionPayload } from "api/trading-documents/models";

export const getReportBalance = ({
  search,
}: {
  search: string;
}): ApiFetcher<FinanceReportBalance> => ({
  key: financesKeys.getReportBalance(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/reports/balance" + search,
    }),
});

const postDraftDocumentPosition = (data: CreateDraftDocumentPositionPayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents-items/work-in-progress-items",
    data,
  });

export const financesApi = {
  getReportBalance,
  postDraftDocumentPosition,
};
