import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { PropsWithChildren } from "react";
import { cx } from "utilities";

export const Title = ({
  children,
  fontWeight = "700",
  className,
}: PropsWithChildren<{ fontWeight?: TypographyProps["fontWeight"]; className?: string }>) => {
  return (
    <Typography
      fontSize="10"
      fontWeight={fontWeight}
      color="neutralBlack48"
      className={cx("text-uppercase", className)}
    >
      {children}
    </Typography>
  );
};
