import { PageHeader } from "components/common";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { getTabs } from "../utils/getTabs";
import { manufacturingEmployees } from "components/common/moduleNavigation/moduleConfig/manufacturing/routes/manufacturingEmployees";

export const ListHeader = ({ open }: { open: () => void }) => {
  return (
    <div>
      <PageHeader
        tabs={{
          list: getTabs(),
          routesRoot: `manufacturing/${manufacturingEmployees.url}`,
          urlSpan: "list",
        }}
        createButton={{
          alt: "Plus",
          img: darkPlusIcon,
          label: "Dodaj pracownika",
          onClick: open,
        }}
        searchInput={{
          label: "Szukaj wśród pracowników",
          tags: [],
        }}
        viewLabel="MANUFACTURING_EMPLOYEES"
      />
    </div>
  );
};
