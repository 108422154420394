import { manufacturingActions } from "api/manufacturing/actions";
import { materialsActions } from "api/manufacturing/materials/actions";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { RightPanelSection } from "components/utils/drawer/Drawer";
import { useQuery } from "hooks";
import { queryString } from "utilities";

export const TitleSection = () => {
  const { query } = useQuery();
  const { data: material } = materialsActions.useMaterial(query.panelId, {});
  const context = useScrollableContext();

  return (
    <RightPanelSection>
      <div className="d-flex align-items-center gap-2" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {material?.name}
        </Typography>
        <AwaitingTag />
      </div>
    </RightPanelSection>
  );
};

const AwaitingTag = () => {
  const { query } = useQuery();

  const { data: orders } = manufacturingActions.useGetManufacturingMaterialOrders(
    queryString.stringify({
      pageSize: "999",
      materials: query.panelId,
    }),
  );

  const lastOrder = orders?.filter(order => !order.receivedAt)[0];
  if (!lastOrder) return null;
  return <Tag label="Oczekuje na dostawę" variant="info" />;
};
