import styles from "./TreeMenu.module.css";
import { useEffect, useState } from "react";
import { ContentProps, SectionConfig, SidebarProps, TreeMenuProps } from "./types";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities";
import { useMenuScroll } from "./useMenuScroll";
import { IconRenderer } from "components/miloDesignSystem/atoms/shared/IconRenderer";

const HIGHLIGHT_DELAY = 650;

export const TreeMenu = ({ children, sections }: TreeMenuProps) => {
  const [activeSubsection, setActiveSubsection] = useState("");
  const [observerDisabled, setObserverDisabled] = useState(false);

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      setObserverDisabled(true);

      setTimeout(() => {
        setActiveSubsection(id);
        setObserverDisabled(false);
      }, HIGHLIGHT_DELAY);
    }
  };

  useEffect(() => {
    setActiveSubsection(sections[0]?.subsections[0]?.id);
  }, [sections]);

  return children({
    Content: (
      <Content
        sections={sections}
        observerDisabled={observerDisabled}
        setActiveSubsection={setActiveSubsection}
      />
    ),
    Sidebar: (
      <Sidebar
        activeSubsection={activeSubsection}
        handleScroll={handleScroll}
        sections={sections}
      />
    ),
  });
};

const Sidebar = ({
  activeSubsection,
  handleScroll,
  sections,
}: Pick<TreeMenuProps, "sections"> & SidebarProps) => {
  const handleSectionClick = (section: SectionConfig) => {
    if (section.subsections.length > 0) {
      handleScroll(section.subsections[0].id);
    }
  };

  const isSubsectionPartOfSection = (section: SectionConfig) =>
    section.subsections.some(subsection => subsection.id === activeSubsection);

  return (
    <div className={styles.sidebar}>
      {sections.map(section => (
        <div className={styles.section} key={section.id}>
          <div className={styles.sectionMenuHeader} onClick={() => handleSectionClick(section)}>
            {section.icon && (
              <IconRenderer
                color={isSubsectionPartOfSection(section) ? "deepPurple400" : "neutralBlack48"}
                icon={section.icon}
                size={18}
              />
            )}
            <Typography
              color={isSubsectionPartOfSection(section) ? "deepPurple500" : "neutralBlack76"}
              fontSize="14"
              fontWeight="700"
              noWrap
            >
              {section.menuLabel}
            </Typography>
          </div>
          {section.subsections.map(subsection => (
            <div
              key={subsection.id}
              className={styles.sidebarSubsectionWrapper}
              onClick={() => handleScroll(subsection.id)}
            >
              <div
                className={cx(styles.sidebarSubsection, {
                  [styles.activeSidebarSubsection]: activeSubsection === subsection.id,
                })}
              >
                <Typography
                  color={activeSubsection === subsection.id ? "deepPurple400" : "neutralBlack64"}
                  fontSize="12"
                  fontWeight="700"
                >
                  {subsection.menuLabel}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const Content = ({
  observerDisabled,
  sections,
  setActiveSubsection,
}: Pick<TreeMenuProps, "sections"> & ContentProps) => {
  const { contentRef, sectionRefs } = useMenuScroll(observerDisabled, setActiveSubsection);

  return (
    <div className={styles.content} ref={contentRef}>
      {sections.map(section => (
        <div className="pt-2 pb-4" key={section.id}>
          <Typography
            className={styles.contentHeader}
            color="neutralBlack88"
            fontSize="18"
            fontWeight="700"
            noWrap
          >
            {section.menuLabel}
          </Typography>
          {section.content}
          {section.subsections.map(subsection => (
            <div
              className={styles.subsection}
              id={subsection.id}
              key={subsection.id}
              ref={el => (sectionRefs.current[subsection.id] = el)}
            >
              {subsection.content}
            </div>
          ))}
        </div>
      ))}
      <div className={styles.spacer} />
    </div>
  );
};
