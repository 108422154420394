import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import { wmsUnloadingApi } from "./api";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { deleteUnloading, wmsFileFactory } from "../calls";
import { wmsKeys } from "../keys";
import { UnloadingDetails } from "../models";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useErrorToastr } from "hooks/useErrorToastr";
import { fileDownloader } from "fileDownloader";
import { UUID } from "api/types";

const usePostStartUnloading = () => {
  return useMutation(wmsUnloadingApi.postStartUnloading, ({ toastr, queryClient }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostFinishUnloading = () => {
  return useMutation(wmsUnloadingApi.postFinishUnloading, ({ toastr, queryClient }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useRemoveUnloading = (close: () => void, unloading: UnloadingDetails) => {
  return withDeleteConfirmation(
    useMutation(deleteUnloading, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(wmsKeys.unloads.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto rozładunek "${unloading.signature}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć ten rozładunek?",
  )();
};

const useDownloadUnloadingLineItemPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (lineItemId: UUID, unloading: UUID, signature: string) => {
    if (!lineItemId || !unloading) throw new Error("An error occurred");
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = wmsFileFactory.unloadingLineItemPdf({
      unloading,
      signature,
      id: lineItemId,
    });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

export const wmsUnloadingActions = {
  usePostFinishUnloading,
  usePostStartUnloading,
  useRemoveUnloading,
  useDownloadUnloadingLineItemPdf,
};
