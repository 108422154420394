import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { orderActions } from "api/orders/actions";
import { PackageSection } from "./components/PackageSection";
import { PanelHeader } from "./components/PanelHeader";
import { GeneralInfoSection } from "./components/GeneralInfoSection";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data, error, isLoading } = orderActions.useGetOrderFreeSingleItemsQuantitiesDetails(
    panelId,
    {
      enabled: Boolean(panelId),
    },
  );

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(data);

  return (
    <TriggerScrollContext>
      <DrawerRightPanel className="position-relative" key={panelId}>
        <PanelHeader data={data} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <GeneralInfoSection data={data} />
            <Typography fontSize="14" fontWeight="600" color="neutralBlack48" className="px-3 py-2">
              Paczki
            </Typography>
            <PackageSection packages={data.packages} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
