import { Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { SemiFinishedMaterialsList } from "pages/manufacturingNew/demandAndAvailability/SemiFinishedMaterialsList";

export const SemiFinishedMaterialsRouting = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list/:tab`}
        component={SemiFinishedMaterialsList}
        title="Milo - półprodukty"
      />
      <Route exact={true} path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};
