import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import chairIcon from "assets/images/chairAlt.svg";

export const indexes: ModuleLink = {
  url: "indexes",
  label: "Indeksy",
  icon: chairIcon,
  routing: () => <></>,
  isAccessible: false,
};
