import { tradingDocumentsActions } from "api/trading-documents/actions";
import { TradingDocument } from "api/trading-documents/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiEvent } from "components/miloDesignSystem/atoms/icons/MdiEvent";
import { Popover } from "components/miloDesignSystem/atoms/popover/Popover";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { ClickOutsideHandler } from "components/utils/ClickOutsideHandler";
import { useState } from "react";
import { dateUtils } from "utilities";

interface Props {
  selectedRows: TradingDocument[];
}

export const ChangeIssueOrDeliveryInvoiceDate = ({ selectedRows }: Props) => {
  const [invoiceIssueDate, setInvoiceIssueDate] = useState(dateUtils.formatDateToIso(new Date()));
  const [invoiceDeliveryDate, setInvoiceDeliveryDate] = useState(
    dateUtils.formatDateToIso(new Date()),
  );
  const issueAndDeliveryDatesMutation = tradingDocumentsActions.useBulkPatchTradingDocuments();

  return (
    <Popover
      hideOnClickOutside={false}
      content={({ hide }) => (
        <ClickOutsideHandler
          onClickOutside={() => {
            hide();
            setInvoiceIssueDate(dateUtils.formatDateToIso(new Date()));
            setInvoiceDeliveryDate(dateUtils.formatDateToIso(new Date()));
          }}
          outsideClickIgnoreClass={ignoreClickOutsideClassName}
        >
          <div>
            <Typography className="mt-2 mb-1" fontSize="14" fontWeight="700">
              Wskaż nową datę wystawienia
            </Typography>
            <DatePicker
              calendarClassName={ignoreClickOutsideClassName}
              isNullable={false}
              onChange={date => date && setInvoiceIssueDate(dateUtils.formatDateToIso(date))}
              value={invoiceIssueDate}
            />
            <Typography className="mt-2 mb-1" fontSize="14" fontWeight="700">
              Wskaż nową datę sprzedaży
            </Typography>
            <DatePicker
              calendarClassName={ignoreClickOutsideClassName}
              isNullable={false}
              onChange={date => date && setInvoiceDeliveryDate(dateUtils.formatDateToIso(date))}
              value={invoiceDeliveryDate}
            />
            <div className="d-flex flex-1 justify-content-end mt-3 w-100">
              <Button
                className="text-uppercase w-100"
                isLoading={issueAndDeliveryDatesMutation.isLoading}
                onClick={() => {
                  issueAndDeliveryDatesMutation.mutate(
                    {
                      ids: selectedRows.map(tradingDocument => tradingDocument.id),
                      toUpdate: {
                        invoiceIssueDate,
                        invoiceDeliveryDate,
                      },
                    },
                    {
                      onSuccess: hide,
                    },
                  );
                }}
                size="small"
                variant="deepPurple"
              >
                Zmień daty
              </Button>
            </div>
          </div>
        </ClickOutsideHandler>
      )}
      variant="DARK"
    >
      <div>
        <Tooltip title="Zmień datę wystawienia lub sprzedaży">
          <IconButton icon={MdiEvent} variant="transparent" theme="dark" />
        </Tooltip>
      </div>
    </Popover>
  );
};

const ignoreClickOutsideClassName = "trading-documents-list-popover-ignore-class-name";
