import { useTradingDocument } from "api/trading-documents/hooks";
import { BulkSalesInvoiceConfirmPreview } from "api/trading-documents/models";
import { proformasActions } from "api/trading-documents/proforma/actions";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery, useStateModal } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { ReplyModal } from "pages/tradingDocuments/shared/components/actionToolbar/ReplyModal";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { DownloadTradingDocumentPdf } from "pages/tradingDocuments/shared/components/downloadTradingDocumentPdf/DownloadTradingDocumentPdf";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";

export const PanelHeader = () => {
  const { close } = useDrawer("proformaInvoices");
  const { query } = useQuery();
  const { panelId } = query;
  const { data: proforma } = useTradingDocument({ id: panelId });
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();
  const createSalesInvoiceFromProformaMutation = proformasActions.useCreateSalesInvoiceFromProforma();
  const { isTriggerElementHidden } = useScrollableContext();

  if (!proforma) return null;

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2">
            <ConfirmCheckbox tradingDocument={proforma} replyModal={replyModal} />
            <div>Proforma&nbsp;{proforma.signature}</div>
          </SlidingHeaderWrapper>
        ) : (
          <ConfirmTradingDocument tradingDocument={proforma} />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          {proforma.status === "CONFIRMED" && (
            <Button
              className="text-uppercase"
              isLoading={createSalesInvoiceFromProformaMutation.isLoading}
              onClick={() => createSalesInvoiceFromProformaMutation.mutate(proforma.id)}
              size="small"
              startIcon={MdiAdd}
              variant="gray"
            >
              Przekształć do faktury sprzedażowej
            </Button>
          )}
          <DownloadTradingDocumentPdf tradingDocument={proforma} />
          <MoreInfoButton close={close} invoice={proforma} panelId={panelId} />
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      {replyModal.isOpen && (
        <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
      )}
    </>
  );
};
