import React from "react";
import {
  useMenuState,
  Menu as BaseMenu,
  MenuItem as BaseMenuItem,
  MenuButton,
  MenuStateReturn,
} from "reakit/Menu";
import { Typography } from "../typography";
import rightArrowIcon from "assets/images/chevronRight.svg";
import styles from "./Menu.module.css";
import cx from "classnames";
import { MenuItem, MenuItemType } from "./types";
import { highlightTextFragment } from "utilities/highlightTextFragment";
import { noop } from "utilities";

interface Props {
  disclosure: JSX.Element;
  menuItems: MenuItem[];
}

export const Menu = React.forwardRef(({ disclosure, menuItems, ...props }: Props, ref) => {
  const menu = useMenuState();

  return (
    <>
      <MenuButton ref={ref} {...menu} {...props} {...disclosure.props}>
        {disclosureProps => React.cloneElement(disclosure, disclosureProps)}
      </MenuButton>
      <MenuList menuItems={menuItems} menuState={menu} />
    </>
  );
});

export const MenuList = ({
  menuItems,
  menuState,
  hideOnClickOutside = true,
  className,
  searchValue,
  showNoMenuItemsInfo = true,
}: {
  menuItems: MenuItem[];
  menuState: MenuStateReturn;
  hideOnClickOutside?: boolean;
  className?: string;
  searchValue?: string;
  showNoMenuItemsInfo?: boolean;
}) => {
  if (!menuItems.length) {
    if (!showNoMenuItemsInfo) {
      return <BaseMenu {...menuState}></BaseMenu>;
    }
    return (
      <BaseMenu
        {...menuState}
        aria-label="base-menu"
        hideOnClickOutside={hideOnClickOutside}
        className={cx(styles.dropMenu, className)}
      >
        <div className={styles.dropDownOption}>
          <Typography fontSize="14" fontWeight="700">
            Brak wyników
          </Typography>
        </div>
      </BaseMenu>
    );
  }

  return (
    <BaseMenu
      {...menuState}
      aria-label="base-menu"
      hideOnClickOutside={hideOnClickOutside}
      className={cx(styles.dropMenu, className)}
    >
      {menuItems.map((menuItem, index) => {
        const itemComponent = getMenuComponent(menuItem, searchValue);
        return (
          <BaseMenuItem {...menuState} {...itemComponent.props} key={index}>
            {itemProps => React.cloneElement(itemComponent, itemProps)}
          </BaseMenuItem>
        );
      })}
    </BaseMenu>
  );
};

const getMenuComponent = (menuItem: MenuItem, searchValue?: string) => {
  switch (menuItem.type) {
    case MenuItemType.AVATAR:
    case MenuItemType.TEXT:
      const Icon = menuItem.options?.iconStart;
      return (
        <div
          className={cx(styles.dropDownOption, menuItem?.options?.className, {
            [styles.disabled]: menuItem.options && menuItem.options.disabled,
          })}
          onClick={menuItem.options && !menuItem.options.disabled ? menuItem.onClick : noop}
          onKeyDown={event => {
            switch (event.key) {
              case "Enter":
                event.preventDefault();
                menuItem.onClick();
                return;
            }
          }}
        >
          {menuItem.type === MenuItemType.AVATAR ? (
            <img alt="awatar" src={menuItem.avatar} style={{ height: "16px", width: "16px" }} />
          ) : Icon && typeof Icon === "function" ? (
            <Icon color={menuItem?.options?.color} size="16" />
          ) : null}
          <Typography color={menuItem.options?.color} fontSize="14" fontWeight="700">
            {highlightTextFragment(searchValue, menuItem.text, menuItem.text)}
          </Typography>
          {menuItem?.options?.buttonEnd}
        </div>
      );
    case MenuItemType.ICON:
      return (
        <div
          className={cx(styles.dropDownOption, menuItem?.options?.className, {
            [styles.disabled]: menuItem.options && menuItem.options.disabled,
          })}
          onClick={menuItem.options && !menuItem.options.disabled ? menuItem.onClick : noop}
          onKeyDown={event => {
            switch (event.key) {
              case "Enter":
                event.preventDefault();
                menuItem.onClick();
                return;
            }
          }}
        >
          {typeof menuItem.icon === "string" ? (
            <img alt="" src={menuItem.icon} style={{ height: "16px", width: "16px" }} />
          ) : menuItem.icon && typeof menuItem.icon === "function" ? (
            <menuItem.icon color={menuItem?.options?.color} size="16" />
          ) : null}
          <Typography color={menuItem.options?.color} fontSize="14" fontWeight="700">
            {highlightTextFragment(searchValue, menuItem.text, menuItem.text)}
          </Typography>
          {menuItem?.options?.buttonEnd}
        </div>
      );
    case MenuItemType.SECTION:
      return (
        <div className={styles.section}>
          {menuItem.title && (
            <div className={styles.header}>
              <Typography
                className={styles.headerTypography}
                color="neutralBlack48"
                fontSize="12"
                fontWeight="700"
              >
                {menuItem.title}
              </Typography>
            </div>
          )}
          <Menu
            disclosure={
              <div className={cx(styles.dropDownOption, styles.sectionDisclosure)}>
                <Typography fontSize="14" fontWeight="700">
                  {menuItem.text}
                </Typography>
                <img alt="submenu" src={rightArrowIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            }
            menuItems={menuItem.menuItems}
          />
        </div>
      );
  }
};
