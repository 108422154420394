/* eslint-disable no-loop-func */
import { ColumnDef, Table } from "@tanstack/react-table";
import { cx } from "utilities";
import styles from "./TableLoader.module.css";
import { TABLE_PADDING_HORIZONTAL } from "../../constants";
import { Loader } from "components/miloDesignSystem/atoms/loader";
import { UiSchema } from "../../uiSchemas";
import { Assign } from "utility-types";
import { Cell, TableProps } from "../../types";

export const DEFAULT_LOADER_HEIGHT = 12;
export const DEFAULT_ROW_LOADER_WIDTH = 65;
export const DEFAULT_HEADER_LOADER_WIDTH = 30;
export const DEFAULT_CELL_PADDING = 6;
const DEFAULT_LOADER_ROWS = 10;

type Props<T, TCell extends Cell<T>> = Assign<
  {
    containerRef: React.RefObject<HTMLDivElement>;
    table: Table<T>;
    uiSchema: UiSchema;
    columns: ColumnDef<T, TCell>[] | TCell[];
  },
  Pick<TableProps<T>, "overrides">
>;

export const TableLoader = <T, TCell extends Cell<T>>({
  containerRef,
  table,
  columns,
  uiSchema,
  overrides,
}: Props<T, TCell>) => {
  const hasRows = table.getRowModel().rows.length > 0;
  const width = table.getTotalSize() + TABLE_PADDING_HORIZONTAL;

  // Render 10 (or custom number) default rows for the first render
  if (!hasRows || containerRef.current?.offsetHeight === undefined) {
    return (
      <div className={styles.disableScroll}>
        {(() => {
          const loaderRows: JSX.Element[] = [];
          const loaderRowsCounter =
            (overrides && overrides?.().loaderRowsCounter) || DEFAULT_LOADER_ROWS;
          for (let i = 0; i < loaderRowsCounter; i++) {
            loaderRows.push(
              <div
                className={cx(styles.row, "d-flex align-items-center")}
                key={i}
                style={{ width, maxWidth: width, minWidth: width }}
              >
                {columns.map((cell, index) => (
                  <LoaderCell
                    height={uiSchema.cell.height}
                    key={`${cell.header}-${cell.size}-${index}`}
                    width={cell.size}
                  />
                ))}
              </div>,
            );
          }

          return loaderRows;
        })()}
      </div>
    );
  }

  const determineRowNumber = (): number => {
    if (hasRows) return table.getRowModel().rows.length;
    return containerRef.current!.offsetHeight / Number(uiSchema.cell.height);
  };

  return (
    <div className={styles.disableScroll}>
      {(() => {
        const numberOfRows = determineRowNumber();
        const loaderRows: JSX.Element[] = [];
        for (let i = 0; i < numberOfRows; i++) {
          loaderRows.push(
            <div
              className={cx(styles.row, "d-flex align-items-center")}
              style={{ width, maxWidth: width, minWidth: width }}
            >
              {columns.map((cell, index) => {
                if ((cell as TCell).columns) {
                  const subRows: JSX.Element[] = [];
                  (cell as TCell).columns.map((subCell: Cell<T>, index: number) => {
                    return subRows.push(
                      <LoaderCell
                        height={uiSchema.cell.height}
                        key={`subCell-${index}`}
                        width={subCell.size}
                      />,
                    );
                  });

                  return subRows;
                }
                return (
                  <LoaderCell
                    height={uiSchema.cell.height}
                    key={`cell-${index}`}
                    width={cell.size}
                  />
                );
              })}
            </div>,
          );
        }
        return loaderRows;
      })()}
    </div>
  );
};

export const LoaderCell = ({
  height,
  width,
}: {
  height: string | number;
  width: number | undefined;
}) => {
  return (
    <div className="pr-2 d-flex align-items-center" style={{ height: Number(height) }}>
      <Loader
        height={DEFAULT_LOADER_HEIGHT}
        width={(width ?? DEFAULT_ROW_LOADER_WIDTH) - DEFAULT_CELL_PADDING}
      />
    </div>
  );
};
