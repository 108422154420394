import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFns, dateUtils, pluralize } from "utilities";

interface Props {
  date: string;
}

export const DeliveryDeadline = ({ date }: Props) => {
  const differenceInDays = dateFns.differenceInDays(new Date(date), new Date());
  const textColor = getShipmentDeadlineTextColor(differenceInDays);
  const formattedDate = dateUtils.formatDateToDisplay(date);

  const displayDate = (() => {
    if (differenceInDays === 0) {
      return "dzisiaj";
    }

    if (differenceInDays > 0) {
      return `za ${differenceInDays} ${pluralize.pl(differenceInDays, {
        singular: "dzień",
        plural: "dni",
        other: "dni",
      })}`;
    }
    if (differenceInDays < 0) {
      return `${-differenceInDays} ${pluralize.pl(-differenceInDays, {
        singular: "dzień",
        plural: "dni",
        other: "dni",
      })} temu`;
    }
  })();

  return (
    <div className="d-flex gap-2 align-items-baseline">
      <Typography fontSize="14" fontWeight="700" color={textColor}>
        {formattedDate}
      </Typography>
      <Typography fontSize="12" fontWeight="600" color={textColor}>
        {displayDate}
      </Typography>
    </div>
  );
};

const getShipmentDeadlineTextColor = (differenceInDays: number): ColorPalette => {
  if (differenceInDays >= 3 && differenceInDays <= 5) {
    return "warning500";
  }
  if (differenceInDays <= 2) {
    return "danger500";
  }
  return "neutralBlack88";
};
