import calculateIcon from "assets/images/calculatePink.svg";
import accountIcon from "assets/images/account.svg";
import favicon from "assets/images/favicons/fv_account.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";

export const account: ModuleNavigation = {
  favicon,
  color: "#b65eff",
  navigationConfig: {
    label: "Konto",
    icon: {
      background: "#431d87",
      src: calculateIcon,
    },
  },
  menuDropdownIcon: accountIcon,
  name: "ACCOUNT",
  url: "/account",
  navigationSections: [],
};
