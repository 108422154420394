// src/ENDPOINTS.ts
const local = "http://localhost:8000/";

export const DOMAIN = (function() {
  const customDomain = localStorage.getItem("customDomain");
  if (customDomain) return customDomain;

  const api = process.env.REACT_APP_API;

  if (api === "local") return local;

  if (api) return `https://${api}.api.imilo.pl/`;

  throw new Error("API not set");
})();
