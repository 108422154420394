import { TradingDocument } from "api/trading-documents/models";
import { PaymentForm } from "../components/PaymentForm";
import { InfoLabel } from "components/common/infoLabel/InfoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { FinanceAmountFormat } from "components/common/financeAmountFormat/FinanceAmountFormat";

interface Props {
  tradingDocument: TradingDocument;
}

export const PaymentDetails = ({ tradingDocument }: Props) => {
  return (
    <div>
      <div className="d-flex justify-content-between pt-3 row">
        <div className="pt-1 col-6">
          <div className="pb-3">
            <InfoLabel title="forma płatności">
              <PaymentForm
                expectedPaymentForm={tradingDocument.expectedPaymentForm}
                id={tradingDocument.id}
                invoiceType={tradingDocument.invoiceType}
              />
            </InfoLabel>
            <InfoLabel title="wystawił(/a)">
              <Typography fontSize="14" fontWeight="700" noWrap>
                {tradingDocument.signatureOfAnAuthorizedPerson || EMPTY_VALUE}
              </Typography>
            </InfoLabel>
          </div>
        </div>
        <div className="col-6 pl-4 overflow-hidden">
          <div className="d-flex align-items-center justify-content-end gap-3">
            <Typography fontSize="14" fontWeight="500">
              razem do {tradingDocument.amountSummary.totalWithTax > 0 ? "zapłaty" : "zwrotu"}:
            </Typography>
            <div className="d-flex align-items-center">
              <Typography fontSize="18" fontWeight="700">
                <FinanceAmountFormat value={Math.abs(tradingDocument.amountSummary.totalWithTax)} />
              </Typography>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="700">
                {tradingDocument.currency}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-2">
        <InfoLabel title="zapłacono">
          <Typography fontSize="16" fontWeight="800">
            {tradingDocumentConstants.invoicePaymentStatusDict[tradingDocument.paymentStatus] ||
              EMPTY_VALUE}
          </Typography>
        </InfoLabel>
      </div>
    </div>
  );
};
