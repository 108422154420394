import { routeKeys } from "api/keys";
import { RouteListItem } from "api/routes/models";
import { useQuery, useQueryUtils } from "hooks";
import { createApiQuery } from "hooks/createApiQuery";
import { useMutation } from "hooks/useMutation";
import { RouteEditedHandler } from "pages/routes/shared/RouteEditedHandler";
import { PartialOf } from "typeUtilities";
import { parsePatchData } from "utilities/parsePatchData";
import { getLogisticsRouteSimplified, patchRoute } from "./calls";

export const useLogisticsRouteSimplified = createApiQuery(getLogisticsRouteSimplified);

export const useRoutePatchMutation = () => {
  const { handlePaginatedListUpdate, handleMutate, rollbackList, rollback } = useQueryUtils();

  return useMutation(
    ({ id, toUpdate }: { id: number; toUpdate: PartialOf<RouteListItem> }) =>
      patchRoute(parsePatchData(toUpdate), id),
    ({ queryClient, queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        RouteEditedHandler(id);
        const prevPanel = handleMutate(routeKeys.route(String(id)), toUpdate);
        const prevList = handlePaginatedListUpdate(routeKeys.routes(), id, toUpdate);
        return { prevList, prevPanel };
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      onError: (error, { id }, onMutateReturn) => {
        const { prevList, prevPanel } = onMutateReturn as { prevList: any; prevPanel: any };
        rollback(routeKeys.route(String(id)), prevPanel, error);
        rollbackList(routeKeys.routes(), prevList, id);
      },
    }),
  );
};

export const useRouteConfirmMutation = (
  setShowLoader?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { handlePaginatedListUpdate, handleMutate, rollbackList, rollback } = useQueryUtils();

  return useMutation(
    ({ id, toUpdate }: { id: number; toUpdate: PartialOf<RouteListItem> }) =>
      patchRoute(parsePatchData(toUpdate), id),
    {
      onMutate: ({ id, toUpdate }) => {
        RouteEditedHandler(id);
        if (setShowLoader) {
          setShowLoader(true);
        }
        if (panelId && panelId === String(id)) {
          const prevPanel = handleMutate(routeKeys.route(String(id)), toUpdate);
          const prevList = handlePaginatedListUpdate(routeKeys.routes(), id, toUpdate);
          return { prevList, prevPanel };
        }
        const prevList = handlePaginatedListUpdate(routeKeys.routes(), id, toUpdate);
        return { prevList };
      },
      onError: (error, { id }, onMutateReturn) => {
        const { prevList, prevPanel } = onMutateReturn as { prevList: any; prevPanel: any };
        if (panelId && panelId === String(id)) {
          rollback(routeKeys.route(String(id)), prevPanel, error);
        }
        rollbackList(routeKeys.routes(), prevList, id, error);
      },
    },
  );
};
