import { LoadingTab } from "api/wms/models";

export const tabDict: Record<LoadingTab, string> = {
  [LoadingTab["all"]]: "Wszystkie",
  [LoadingTab["in-progress"]]: "W trakcie",
  [LoadingTab["not-started"]]: "Spodziewane",
  [LoadingTab["finished"]]: "Zakończone",
};

export const getTabs = (): { label: string; name: string }[] => {
  return Object.entries(tabDict).map(([name, label]) => ({ label, name }));
};
