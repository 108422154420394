import { UUID } from "api/types";
import { useQuery } from "hooks";
import { useParams } from "react-router";
import { dateFns } from "utilities";

export const useFinancesFilters = () => {
  const { query } = useQuery();
  const { businessEntityId } = useParams<{ businessEntityId: UUID }>();

  return {
    businessEntityId,
    months: query.months || getDefaultMonths(),
    years: query.years || getDefaultYear(),
  };
};

export const quarterOptions: Record<string, string> = {
  "1,2,3": "I KWARTAŁ",
  "4,5,6": "II KWARTAŁ",
  "7,8,9": "III KWARTAŁ",
  "10,11,12": "IV KWARTAŁ",
};

function getDefaultYear() {
  const previousMonth = dateFns.subMonths(new Date(), 1);
  return dateFns.format(previousMonth, "yyyy");
}

function getDefaultMonths() {
  const previousMonth = dateFns.subMonths(new Date(), 1);
  const formattedMonth = dateFns.format(previousMonth, "M");
  return Object.keys(quarterOptions).find(months => months.includes(formattedMonth))!;
}
