import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { financesOverviewApi } from "./api";

const useSoldPackages = createPaginatedApiQuery(financesOverviewApi.getSoldPackages);
const useSoldIndexes = createPaginatedApiQuery(financesOverviewApi.getSoldIndexes);

export const financesOverviewActions = {
  useSoldPackages,
  useSoldIndexes,
};
