import { AwaitingTransportTab } from "./utils/getTabs";
import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { getSearch } from "./utils/getSearch";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { getTabs } from "./utils/getTabs";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useAwaitingTransportColumns } from "./useAwaitingTransportColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { awaitingTransportModuleLink } from "components/common/moduleNavigation/moduleConfig/wms/routes/awaitingTransportModuleLink";
import { wmsAwaitingTransportsActions } from "api/wms/awaiting-transports/actions";
import { AwaitingTransportListItem } from "api/wms/awaiting-transports/models";
import { RightPanel } from "./rightPanel/RightPanel";
import { FLAVOR } from "CONSTANTS";
import { withFilters } from "components/utils/withFilters";
import { FilterType } from "components/common/filters/types";

export const AwaitingTransport = withFilters(
  FLAVOR === "b2b"
    ? []
    : [
        {
          type: FilterType.Select,
          label: "Czy awizacja ma utworzony rozładunek?",
          name: "hasUnloading",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Search,
          label: "kontrahent",
          name: "customer",
          searchBy: "customers",
        },
      ],
  ({ match }: RouteComponentProps<{ tab: AwaitingTransportTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const search = getSearch({ query, tab });
    const {
      data,
      error,
      isPreviousData,
      isLoading,
      pagination,
    } = wmsAwaitingTransportsActions.useGetAwaitingTransports(search);
    const columns = useAwaitingTransportColumns();

    const tableProps = useTableFeatureConnector({
      rows: data,
      withDrawer: "wmsAwaitingTransport",
      withPagination: { pagination, defaultPaginationVisibility: true },
    });

    const createMutation = wmsAwaitingTransportsActions.usePostAwaitingTransport();

    return (
      <PageWrapper>
        <PageHeader
          searchInput={{
            label: "Szukaj...",
            tags: [],
          }}
          createButton={{
            alt: "utwórz",
            disabled: createMutation.isLoading,
            img: darkPlusIcon,
            label: "Utwórz awizację",
            onClick: () => createMutation.mutate({}),
          }}
          tabs={{
            list: getTabs(),
            routesRoot: `${FLAVOR === "b2b" ? "client" : "wms"}/${awaitingTransportModuleLink.url}`,
            urlSpan: "list",
          }}
          viewLabel="WMS_AWAITING_TRANSPORT"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<AwaitingTransportListItem>
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={mainListUiSchema}
            {...tableProps}
          />
          <RightPanel />
        </div>
      </PageWrapper>
    );
  },
);
