import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import favicon from "assets/images/favicons/fv_devmode.svg";
import devPanelMenuIcon from "assets/images/devPanel.svg";
import { dashboard } from "./routes/dashboard";
import devModeIcon from "assets/images/devmode.svg";

export const developer: ModuleNavigation = {
  favicon,
  color: "#2B8EFA",
  navigationConfig: {
    label: "Panel developera",
    icon: { src: devModeIcon, background: "#003F82" },
  },
  menuDropdownIcon: devPanelMenuIcon,
  name: "DEVELOPER",
  url: "/developer",
  dashboard,
  navigationSections: [],
};
