import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { IndexSummary } from "./indexSummary/IndexSummary";
import { IndexTabSection } from "./indexTabSection/IndexTabSection";

export const IndexDetails = () => {
  return (
    <PageWrapper>
      <PageHeader title="Kartoteka wariantu" searchBar={false} viewLabel="MANUFACTURING_INDEXES" />
      <div className="d-flex overflow-hidden my-2 mx-3">
        <IndexSummary />
        <IndexTabSection />
      </div>
    </PageWrapper>
  );
};
