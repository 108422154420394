import { Unloading } from "api/wms/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFns, pluralize } from "utilities";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { UnloadingStart } from "../components/UnloadingStart";
import { parse } from "date-fns";
import { UnloadingFinish } from "../components/UnloadingFinish";
import { combineDateFormat } from "../utils/combineDateFormat";
import { formatHours } from "pages/wms/shared/utils/formatHours";
import { formatMinutes } from "pages/wms/shared/utils/formatMinutes";
import { useDownloadUnloadingPdf } from "./useDownloadUnloadingPdf";

export const useUnloadingsColumns = () => {
  const handleDownloadUnloadingPdf = useDownloadUnloadingPdf();

  return useCreateTableColumns<Unloading>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "sygnatura",
        size: 200,
        cell: info => {
          const unloading = info.getValue();
          return (
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="14" fontWeight="700">
                {unloading.signature}
              </Typography>
              {unloading.pauseStartedAt && !unloading.pauseEndedAt && (
                <Tag label="Trwa pauza" variant="warning" />
              )}
            </div>
          );
        },
      }),
      columnHelper.text(
        row =>
          row.suppliers && Boolean(row.suppliers.length)
            ? row.suppliers.map(supplier => supplier.name).join(", ")
            : null,
        {
          header: "producenci",
          size: 250,
        },
      ),
      columnHelper.text(row => row.description, {
        header: "opis",
        size: 150,
      }),
      columnHelper.accessor(row => row.ramp, {
        header: "rampa",
        size: 40,
        cell: info => {
          const ramp = info.getValue();
          if (!ramp)
            return <EmptyValue className="d-flex justify-content-center w-100" fontWeight="700" />;
          return (
            <div className="d-flex justify-content-center w-100">
              <Tag label={ramp.name} variant="warning" />
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "estimatedDateOfArrival",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            przyjazd
          </Typography>
        ),
        size: 90,
        cell: info => {
          const date = info.getValue().estimatedDateOfArrivalDate;
          const time = info.getValue().estimatedDateOfArrivalTime;
          const combinedDate = combineDateFormat(date, time);

          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {!combinedDate ? "---" : `${dateFns.format(new Date(combinedDate), "H:mm")}`}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "start",
        size: 120,
        cell: info => {
          return <UnloadingStart fontSize="12" fontWeight="700" unloading={info.getValue()} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "zakończenie",
        size: 90,
        cell: info => {
          return <UnloadingFinish fontSize="12" fontWeight="700" unloading={info.getValue()} />;
        },
      }),
      columnHelper.text(
        row => {
          if (!row.duration || !Boolean(row.duration.length)) return null;

          const matchDays = row.duration.match(/^\d+\s/);

          const parsedTime = parse(
            matchDays ? row.duration.slice(matchDays[0].length) : row.duration,
            "HH:mm:ss.SSSSSS",
            new Date(),
          );

          if (matchDays) {
            const days = parseInt(matchDays[0], 10);
            return `${days} ${pluralize.pl(days, {
              singular: "dzień",
              plural: "dni",
              other: "dni",
            })}, ${formatHours(parsedTime)} ${formatMinutes(parsedTime)}`;
          }

          return `${formatHours(parsedTime)} ${formatMinutes(parsedTime)}`;
        },
        {
          header: "czas trwania",
          size: 90,
        },
      ),
      columnHelper.accessor(row => row, {
        header: "etykiety",
        size: 43,
        cell: info => {
          const unloading = info.getValue();
          return (
            <div className="d-flex justify-content-center w-100">
              <IconButton
                icon={<MdiQrCode size="16" />}
                onClick={event => {
                  event.stopPropagation();
                  handleDownloadUnloadingPdf(unloading);
                }}
                variant="gray"
              />
            </div>
          );
        },
      }),
    ];
  });
};
