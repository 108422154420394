import { QueryFetchError } from "api/types";
import { FormikHelpers } from "formik";

export const handleSubmitResponse = <T>(
  actions: FormikHelpers<T>,
  otherActions?: {
    onSuccess?: () => void;
    onError?: () => void;
  },
) => {
  return {
    onSuccess: () => {
      actions.setSubmitting(false);
      otherActions?.onSuccess?.();
    },
    onError: (error: QueryFetchError) => {
      actions.setSubmitting(false);
      actions.setErrors(error.response?.data);
      otherActions?.onError?.();
    },
  };
};
