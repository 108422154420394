import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./AddOrdersBySignaturesModal.module.css";
import { Formik, FormikHelpers } from "formik";
import { cx } from "utilities";
import { FormTextarea } from "components/utils";
import { validationSchema } from "./validationSchema";
import { AwaitingTransportDetails } from "api/wms/awaiting-transports/models";
import { wmsAwaitingTransportsActions } from "api/wms/awaiting-transports/actions";
import { handleSubmitResponse } from "utilities/handleSubmitResponse";

interface Props {
  close: () => void;
  awaitingTransport: AwaitingTransportDetails;
}

export const AddOrdersBySignaturesModal = ({ close, awaitingTransport }: Props) => {
  const addOrdersMutation = wmsAwaitingTransportsActions.useAddOrdersToAwaitingTransport();

  const handleSubmit = (values: FormState, actions: FormikHelpers<FormState>) => {
    const orderRegex = /\b[^,\n ]+(?=[,\s\n]|$)/g;
    const parsedExternalIds = values.orders.match(orderRegex);
    addOrdersMutation.mutate(
      {
        externalIds: parsedExternalIds || [],
        awaitingTransport: awaitingTransport.id,
      },

      {
        ...handleSubmitResponse(actions),
        onSuccess: payload => {
          close();
          handleSubmitResponse(actions).onSuccess();
        },
      },
    );
  };

  const initialValues: FormState = {
    orders: "",
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj zamówienia do awizacji
        </Typography>
      }
      width={480}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="p-3 d-flex flex-column">
              <FormTextarea
                autoFocus
                name="orders"
                label="Nr zamówień"
                placeholder="Wklej kolejne numery zamówień oddzielając je przecinkiem"
                overrides={{ textarea: { className: styles.textarea } }}
              />
              <div className="d-flex align-items-center mt-3 gap-3">
                <Button
                  className="text-uppercase"
                  onClick={close}
                  size="medium"
                  variant="transparent"
                >
                  Anuluj
                </Button>
                <Button
                  className="text-uppercase"
                  isLoading={isSubmitting}
                  size="medium"
                  type="submit"
                  variant="deepPurple"
                >
                  Dodaj do awizacji{isSubmitting && "..."}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

type FormState = { orders: string };
