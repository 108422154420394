import calculateIcon from "assets/images/calculatePink.svg";
import accountancyIcon from "assets/images/rules.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import favicon from "assets/images/favicons/fv_rules.svg";

export const rules: ModuleNavigation = {
  favicon,
  color: "#b65eff",
  navigationConfig: {
    label: "Reguły",
    icon: { src: calculateIcon, background: "#431d87" },
  },
  menuDropdownIcon: accountancyIcon,
  name: "RULES",
  url: "/rules",
  navigationSections: [],
};
