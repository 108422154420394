import { logbookActions } from "api/logbook/hooks";
import { ManufacturingUnitListViewDetails } from "api/manufacturing/units/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { HistoryLog } from "components/miloDesignSystem/atoms/historyLog/HistoryLog";
import { MdiHistory } from "components/miloDesignSystem/atoms/icons/MdiHistory";
import { MdiKeyboardArrowDown } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowDown";
import { MdiKeyboardArrowUp } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowUp";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import styles from "./HistorySection.module.css";
import { useState } from "react";
import { queryString } from "utilities";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";

interface Props {
  unitDetails: ManufacturingUnitListViewDetails;
}

export const HistorySection = ({ unitDetails }: Props) => {
  const [showPreviousEvents, setShowPreviousEvents] = useState(false);
  const search = queryString.stringify({
    pageSize: "999",
    manufacturingWorkingUnit: unitDetails.id,
  });
  const { data: logs, isLoading } = logbookActions.useLogsQuery(search);

  return (
    <div className="px-3 pb-2 flex-1">
      <Typography fontSize="14" fontWeight="600" color="neutralBlack48" className="mb-2">
        Historia zlecenia
      </Typography>
      {isLoading && <Spinner size={22} />}
      {!Boolean(logs.length) && (
        <EmptySection
          AdditionalInfoRenderer={
            <Typography color="neutralBlack48" fontSize="12" fontWeight="700">
              Tu pojawi się zapis zdarzeń związanych ze zleceniem
            </Typography>
          }
          className={styles.emptyHistory}
          icon={MdiHistory}
          label="To zlecenie jeszcze nie ma swojej historii"
        />
      )}
      {(showPreviousEvents ? logs : logs.slice(0, 3)).map(log => (
        <HistoryLog
          content={log.description}
          created={log.created}
          id={log.id}
          key={log.id}
          kind={log.kind}
          responsible={log.user}
        />
      ))}
      {logs.length > 3 && (
        <Button
          size="small"
          variant="gray"
          endIcon={showPreviousEvents ? MdiKeyboardArrowUp : MdiKeyboardArrowDown}
          className="text-uppercase mt-1 mb-2"
          onClick={() => setShowPreviousEvents(prev => !prev)}
        >
          {showPreviousEvents ? "Ukryj" : "Pokaż"} wcześniejsze zdarzenia
        </Button>
      )}
    </div>
  );
};
