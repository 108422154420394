import { CreateWarehouseman, WarehouseWorkerOccupation } from "api/wms/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Formik } from "formik";
import { useSubmitWarehouseWorker } from "../hooks/useSubmitWarehouseWorker";
import styles from "./AddWarehousemanModal.module.css";
import cx from "classnames";
import { Button } from "components/miloDesignSystem/atoms/button";
import { validationSchema } from "./validationSchema";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Select } from "components/miloDesignSystem/molecules/select";
import { SelectProps } from "components/miloDesignSystem/molecules/select/types";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";

interface Props {
  close: () => void;
}

const occupationsToSelect: Record<WarehouseWorkerOccupation, SelectProps["items"][number]> = {
  WAREHOUSEMAN: {
    text: "Magazynier",
    type: MenuItemType.TEXT,
    value: WarehouseWorkerOccupation.WAREHOUSEMAN,
  },
  PACKER: {
    text: "Pakujący",
    type: MenuItemType.TEXT,
    value: WarehouseWorkerOccupation.PACKER,
  },
  PICKER: {
    text: "Picker",
    type: MenuItemType.TEXT,
    value: WarehouseWorkerOccupation.PICKER,
  },
};

const initialValues: CreateWarehouseman = {
  email: "",
  firstName: "",
  lastName: "",
  occupation: "",
  phone: "",
};

export const AddWarehousemanModal = ({ close }: Props) => {
  const handleSubmit = useSubmitWarehouseWorker(close);

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Utwórz magazyniera
        </Typography>
      }
      width={480}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, values }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className={cx(styles.modalForm, "d-flex flex-column px-3 pb-3 pt-1")}>
              <Select.Form<CreateWarehouseman>
                items={Object.values(occupationsToSelect)}
                name="occupation"
                label="Stanowisko"
                className="mb-2"
                textFieldProps={{ size: "default" }}
              />
              <TextField.Form<CreateWarehouseman>
                containerClassName="mb-2"
                label="Imię"
                name="firstName"
                placeholder="Imię"
              />
              <TextField.Form<CreateWarehouseman>
                containerClassName="mb-2"
                label="Nazwisko"
                name="lastName"
                placeholder="Nazwisko"
              />
              <TextField.Form<CreateWarehouseman>
                containerClassName="mb-2"
                label="E-mail"
                name="email"
                placeholder="E-mail"
              />
              <TextField.Form<CreateWarehouseman>
                containerClassName="mb-2"
                label="Nr telefonu"
                name="phone"
                placeholder="Nr telefonu"
              />
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button isLoading={isSubmitting} size="medium" type="submit" variant="deepPurple">
                Dodaj{isSubmitting && "..."}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
