import { WarehouseWorkerDetails } from "api/wms/models";
import { InfoLabel } from "components/common/infoLabel";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  warehouseWorker: WarehouseWorkerDetails;
}

export const GeneralInfoSection = ({ warehouseWorker }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection>
      <div ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {warehouseWorker.firstName} {warehouseWorker.lastName}
        </Typography>
      </div>
      <InfoLabel title="e-mail">
        <Typography fontSize="14" fontWeight="700">
          {warehouseWorker.email}
        </Typography>
      </InfoLabel>
    </RightPanelSection>
  );
};
