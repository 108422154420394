import { FinancesAmountSummary, InvoiceType } from "api/trading-documents/models";
import { CustomModal } from "components/utils/customModal";
import { RightPanelTableRow } from "components/utils/drawer";
import { financesAmountSummaryListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { AmountSummaryListItem } from "./AmountSummaryListItem";
import styles from "./AmountSummaryModal.module.css";

interface Props {
  amountSummary: FinancesAmountSummary;
  close: () => void;
  invoiceType?: InvoiceType;
}

export const AmountSummaryModal = ({ amountSummary, close, invoiceType }: Props) => {
  return (
    <CustomModal
      close={close}
      isOpen
      overrides={{ container: { className: styles.modalContainer } }}
      title="Podsumowanie dla walut"
    >
      <>
        <div className="pb-3">
          <RightPanelTableRow grid={financesAmountSummaryListConfig.grid} hover={false}>
            <div>#</div>
            <div>netto</div>
            <div>netto [PLN]</div>
            <div>VAT</div>
            <div>VAT [PLN]</div>
          </RightPanelTableRow>
          {Object.entries(amountSummary.currencies).map((currency, index) => (
            <AmountSummaryListItem currencyItem={currency} invoiceType="ADVANCE" key={index} />
          ))}
        </div>
      </>
    </CustomModal>
  );
};
