import { Route } from "api/routes/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { dateFns } from "utilities";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  route: Route;
}

export const GeneralInfoSection = ({ route }: Props) => {
  const formattedTime =
    route.loadingTime?.length === 8 ? route.loadingTime.slice(0, -3) : route.loadingTime;

  return (
    <RightPanelSection>
      <InfoLabel title="utworzono">
        <div className="d-flex align-items-center gap-1">
          {route.createdBy.avatar && <Avatar size="sm" user={route.createdBy} />}
          <Typography fontSize="14" fontWeight="700">
            {route.createdBy.firstName} {route.createdBy.lastName}
          </Typography>
          <Typography fontSize="14" fontWeight="700">
            {route.created ? dateFns.formatRelative(new Date(route.created)) : EMPTY_VALUE}
          </Typography>
        </div>
      </InfoLabel>

      <InfoLabel title="godz. i data załadunku">
        <div className="d-flex align-items-center gap-2">
          <Typography fontSize="14" fontWeight="500" color="neutralBlack48">
            {route.loadingTime ? formattedTime : "brak godziny"}
          </Typography>
          <Typography fontSize="14" fontWeight="700">
            {route.loadingDate
              ? dateFns.format(new Date(route.loadingDate), "dd.MM.yyyy")
              : "brak daty"}
          </Typography>
        </div>
      </InfoLabel>
      <InfoLabel title="kierowca">
        <Typography fontSize="14" fontWeight="500">
          {route.driver ? `${route.driver.firstName} ${route.driver.lastName}` : EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="drugi kierowca">
        <Typography fontSize="14" fontWeight="500">
          {route.secondaryDriver
            ? `${route.secondaryDriver.firstName} ${route.secondaryDriver.lastName}`
            : EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="samochód">
        <Typography fontSize="14" fontWeight="500">
          {route.car ? route.car.registrationNumber : EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="rampa">
        <Typography fontSize="14" fontWeight="500">
          {route.ramp ? route.ramp.name : EMPTY_VALUE}
        </Typography>
      </InfoLabel>
    </RightPanelSection>
  );
};
