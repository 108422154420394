import { routeActions } from "api/routes/actions";
import { PageHeader } from "components/common";
import { useNavigate } from "hooks/useNavigate";
import { useParams } from "react-router";
import { dateFns, dateUtils } from "utilities";
import styles from "../RouteProgress.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiArrowBack } from "components/miloDesignSystem/atoms/icons/MdiArrowBack";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { useProductionProgressResourceType } from "../ProductionProgress";
import { orderGroupActions, useOrderGroupQuery, useOrderQuery } from "hooks/apiPrimitives";

interface Props {
  clearSelected: () => void;
}
export const ListHeader = ({ clearSelected }: Props) => {
  const resourceType = useProductionProgressResourceType();

  if (resourceType === "route") {
    return <RouteListHeader clearSelected={clearSelected} />;
  }
  if (resourceType === "orderGroup") {
    return <OrderGroupListHeader clearSelected={clearSelected} />;
  }
  if (resourceType === "order") {
    return <OrderListHeader />;
  }
  return null;
};

const OrderGroupListHeader = ({ clearSelected }: Props) => {
  const { resourceId } = useParams<{ resourceId: string }>();
  const { data, isLoading } = useOrderGroupQuery(Number(resourceId));
  return (
    <div>
      <PageHeader
        title={
          <div className="d-flex align-items-center">
            <Typography fontSize="20" fontWeight="700" className="mr-2">
              Postęp produkcji na grupie zamówień:
            </Typography>
            {isLoading ? <Spinner size={20} /> : data?.signature}
          </div>
        }
        viewLabel="LOGISTICS_ROUTES"
        bottomBarButtons={
          <>
            <div className="d-flex gap-1">
              <Typography fontSize="12" fontWeight="400">
                data odbioru:
              </Typography>
              <Typography fontSize="12" fontWeight="700" className={styles.text}>
                {data?.pickUpDate
                  ? dateUtils.formatDateToDisplay(new Date(data?.pickUpDate))
                  : EMPTY_VALUE}
              </Typography>
            </div>

            <OrderGroupNavigation clearSelected={clearSelected} />
          </>
        }
      />
    </div>
  );
};

const OrderGroupNavigation = ({ clearSelected }: { clearSelected: () => void }) => {
  const { resourceId } = useParams<{ resourceId: string }>();
  const { data } = orderGroupActions.useOrderGroupPositions(Number(resourceId));

  const navigate = useNavigate();

  const navigateToRoute = (routeId: number) => {
    clearSelected();
    return navigate(`/manufacturing/orderGroups/progress/${routeId}`);
  };

  const navigateToNextEntry = (() => {
    if (data?.next) return () => navigateToRoute(data?.next!);
  })();

  const navigateToPrevEntry = (() => {
    if (data?.previous) return () => navigateToRoute(data?.previous!);
  })();

  return (
    <PositionsView
      navigateToNextEntry={navigateToNextEntry}
      navigateToPrevEntry={navigateToPrevEntry}
    />
  );
};

const RouteListHeader = ({ clearSelected }: Props) => {
  const { resourceId } = useParams<{ resourceId: string }>();
  const { data, isLoading } = routeActions.useManufacturingRoute(Number(resourceId));
  return (
    <div>
      <PageHeader
        title={
          <div className="d-flex align-items-center">
            <Typography fontSize="20" fontWeight="700" className="mr-2">
              Postęp produkcji na trasie:
            </Typography>
            {isLoading ? <Spinner size={20} /> : data?.signature}
          </div>
        }
        viewLabel="LOGISTICS_ROUTES"
        bottomBarButtons={
          <>
            <div className="d-flex gap-1">
              <Typography fontSize="12" fontWeight="400">
                data załadunku:
              </Typography>
              <Typography fontSize="12" fontWeight="700" className={styles.text}>
                {data?.loadingDate
                  ? dateFns.format(new Date(data?.loadingDate), "dd.MM.yyyy")
                  : EMPTY_VALUE}
              </Typography>
            </div>
            <div className="d-flex gap-1">
              <Typography fontSize="12" fontWeight="400">
                data wyjazdu:
              </Typography>
              <Typography fontSize="12" fontWeight="700" className={styles.text}>
                {data?.departureDate
                  ? dateFns.format(new Date(data?.departureDate), "dd.MM.yyyy")
                  : EMPTY_VALUE}
              </Typography>
            </div>
            <RouteNavigation clearSelected={clearSelected} />
          </>
        }
      />
    </div>
  );
};

const OrderListHeader = () => {
  const { resourceId } = useParams<{ resourceId: string }>();
  const { data, isLoading } = useOrderQuery(Number(resourceId));
  return (
    <div>
      <PageHeader
        title={
          <div className="d-flex align-items-center">
            <Typography fontSize="20" fontWeight="700" className="mr-2">
              Postęp produkcji na zamówieniu:
            </Typography>
            {isLoading ? <Spinner size={20} /> : data?.signature}
          </div>
        }
        viewLabel="LOGISTICS_ROUTES"
      />
    </div>
  );
};

const RouteNavigation = ({ clearSelected }: { clearSelected: () => void }) => {
  const { resourceId } = useParams<{ resourceId: string }>();
  const { data } = routeActions.useManufacturingRoutesPosition(Number(resourceId));

  const navigate = useNavigate();

  const navigateToRoute = (routeId: number) => {
    clearSelected();
    return navigate(`/manufacturing/routes/progress/${routeId}`);
  };

  const navigateToNextEntry = (() => {
    if (data?.next) return () => navigateToRoute(data.next!);
  })();

  const navigateToPrevEntry = (() => {
    if (data?.previous) return () => navigateToRoute(data.previous!);
  })();

  return (
    <PositionsView
      navigateToNextEntry={navigateToNextEntry}
      navigateToPrevEntry={navigateToPrevEntry}
    />
  );
};

const PositionsView = ({
  navigateToNextEntry,
  navigateToPrevEntry,
}: {
  navigateToNextEntry?: () => void;
  navigateToPrevEntry?: () => void;
}) => {
  return (
    <div className="d-flex align-items-center gap-2">
      <Button
        size="small"
        variant="gray"
        startIcon={MdiArrowBack}
        className="uppercase"
        disabled={!Boolean(navigateToNextEntry)}
        onClick={navigateToNextEntry}
      >
        poprzednia
      </Button>
      <Button
        size="small"
        variant="gray"
        endIcon={MdiArrowForward}
        className="uppercase"
        disabled={!Boolean(navigateToPrevEntry)}
        onClick={navigateToPrevEntry}
      >
        następna
      </Button>
    </div>
  );
};
