import { materialsActions } from "api/manufacturing/materials/actions";
import {
  ManufacturingMaterialKind,
  MaterialAvailabilityStatus,
  MaterialListItem,
} from "api/manufacturing/materials/models";
import { UUID } from "api/types";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { useQuery, useToggle } from "hooks";
import { useParams } from "react-router";
import { materialsUtils } from "utilities/materials";
import { useMaterialColumns } from "./useMaterialColumns";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common/pageHeader/PageHeader";
import { capitalizeFirstLetter, cx } from "utilities";
import { semiFinishedMaterials as semiFinishedMaterialsNavigation } from "components/common/moduleNavigation/moduleConfig/manufacturing/routes/semiFinishedMaterials";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { CommonError } from "components/utils";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import styles from "./DemandAndAvailability.module.css";
import { RightPanel } from "./rightPanel/RightPanel";
import { CreateMaterial } from "./createMaterial/CreateMaterial";

export const SemiFinishedMaterialsList = () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const { tab } = useParams<{ tab: UUID | "all" }>();
  const {
    data: semiFinishedMaterials,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = materialsActions.useMaterials(
    materialsUtils.getMaterialsSearch({
      kind: ManufacturingMaterialKind.SEMI_FINISHED,
      query,
      tab,
    }),
  );
  const {
    data: categories,
    error: categoriesError,
    isLoading: areCategoriesLoading,
  } = materialsActions.useMaterialCategories(materialsUtils.getCategoriesSearch());

  const createMaterialModal = useToggle();

  const tableMultiSelect = useTableMultiSelect({ rows: semiFinishedMaterials });
  const tableProps = useTableFeatureConnector({
    rows: semiFinishedMaterials,
    withDrawer: "manufacturingRawMaterials",
    withPagination: { pagination, defaultPaginationVisibility: true },
    withMultiSelect: tableMultiSelect,
  });

  const columns = useMaterialColumns({});

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        tabs={
          categories
            ? {
                list: [{ name: "all", label: "Wszystkie" }].concat(
                  categories.map(category => ({
                    name: category.id,
                    label: capitalizeFirstLetter(category.name),
                  })),
                ),
                routesRoot: `manufacturing/${semiFinishedMaterialsNavigation.url}/list`,
              }
            : undefined
        }
        title={
          areCategoriesLoading ? (
            <div className="d-flex align-items-center mb-1">
              <Spinner size={20} />
            </div>
          ) : categoriesError ? (
            <CommonError status={categoriesError?._httpStatus_} size="small" />
          ) : (
            undefined
          )
        }
        createButton={{ label: "Dodaj półprodukt", onClick: createMaterialModal.open }}
        viewLabel="SEMI_FINISHED_MATERIALS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<MaterialListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
          overrides={() => {
            return {
              ...tableProps?.overrides?.(),
              row: row => {
                return {
                  className: cx(
                    {
                      [styles.veryLowStock]:
                        row.stock.stockStatus === MaterialAvailabilityStatus.VERY_LOW,
                    },
                    tableProps?.overrides?.().row?.(row).className,
                  ),
                };
              },
            };
          }}
        />
        <RightPanel />
        {createMaterialModal.isOpen && (
          <CreateMaterial
            close={createMaterialModal.close}
            kind={ManufacturingMaterialKind.SEMI_FINISHED}
          />
        )}
      </div>
    </PageWrapper>
  );
};
