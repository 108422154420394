import { ManufacturingStageSchema } from "api/manufacturingNew/models";
import { RightPanelTableRow } from "components/utils/drawer";
import { LinkToPage } from "components/utils/linkToPage";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { dateFns } from "utilities";
import { stagesSchemasListConfig } from "../../utils/panelTablesConfig";
import styles from "../../rightPanel/RightPanel.module.css";
import cx from "classnames";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";

interface Props {
  schema: ManufacturingStageSchema;
}

export const Schema = ({ schema }: Props) => {
  return (
    <RightPanelTableRow grid={stagesSchemasListConfig.grid}>
      {schema.signature ? (
        <LinkToPage
          content={<div className="body-14-600">{schema.signature}</div>}
          url={`/manufacturing/manufacturing-schemas/list/all?panelId=${schema.id}`}
        />
      ) : (
        <div className="body-14-600">---</div>
      )}
      <div className="overflow fw-700">{schema.name}</div>
      <div>
        <div className="overflow fw-700">{schema.description ? schema.description : "---"}</div>
      </div>
      <div>{dateFns.format(new Date(schema.createdAt), "dd.MM.yyyy")}</div>
      <div>
        <div className="d-flex align-items-center gap-1">
          {schema.createdBy ? (
            <div>
              <Tooltip title={`${schema.createdBy.firstName} ${schema.createdBy.lastName}`}>
                <div>
                  <AvatarOrInitials
                    avatarSrc={schema.createdBy.avatar}
                    firstName={schema.createdBy.firstName}
                    lastName={schema.createdBy.lastName}
                    className={cx(styles.avatar, styles.avatarSmall)}
                  />
                </div>
              </Tooltip>
            </div>
          ) : (
            "---"
          )}
        </div>
      </div>
    </RightPanelTableRow>
  );
};
