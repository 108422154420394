import { PurchaseInvoiceToReview } from "api/trading-documents/models";
import { UUID } from "api/types";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { CustomerInfoSection } from "./components/customerInfoSection/CustomerInfoSection";
import { GeneralInfoSection } from "./components/generalInfoSection/GeneralInfoSection";
import { PanelHeader } from "./components/PanelHeader";
import styles from "./RightPanel.module.css";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { useRef, useState } from "react";
import { CommonError, MockupLoader } from "components/utils";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { ItemsSection } from "./itemsSection/ItemsSection";

interface Props {
  tradingDocumentId: UUID;
  invoiceToReview: PurchaseInvoiceToReview;
}

export const RightPanel = ({ tradingDocumentId, invoiceToReview }: Props) => {
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const {
    data: tradingDocument,
    error,
    isLoading,
  } = tradingDocumentsActions.useImportedPurchaseInvoice(
    { id: tradingDocumentId },
    { enabled: Boolean(tradingDocumentId) },
  );
  const [emptyCurrencyWarning, setEmptyCurrencyWarning] = useState(false);

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!tradingDocument) return null;

  return (
    <DrawerRightPanel className={styles.widerDrawer} key={invoiceToReview.id}>
      <PanelHeader invoiceToReview={invoiceToReview} />
      <RightPanelBody myRef={clearCommentsRef} hasCommentsModule>
        <GeneralInfoSection
          emptyCurrencyWarning={emptyCurrencyWarning}
          invoice={invoiceToReview}
          setEmptyCurrencyWarning={setEmptyCurrencyWarning}
        />
        <ItemsSection
          emptyCurrencyWarning={emptyCurrencyWarning}
          setEmptyCurrencyWarning={setEmptyCurrencyWarning}
          tradingDocument={tradingDocument}
        />
        <CustomerInfoSection invoice={invoiceToReview} />
      </RightPanelBody>
      <CommentsGenericSection
        outsideRef={clearCommentsRef}
        paramName="tradingDocument"
        endpointUrl="/finances/comments/items"
        commentedObject={invoiceToReview}
      />
    </DrawerRightPanel>
  );
};
