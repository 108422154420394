import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { OrderLineItemDetails } from "api/external-manufacturing/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";

interface Props {
  close: () => void;
  lineItemDetails: OrderLineItemDetails;
}

export const PanelHeader = ({ close, lineItemDetails }: Props) => {
  const handleDownloadExternalManufacturingLineItemLabelPdf = externalManufacturingActions.useDownloadExternalManufacturingLineItemsLabelsPdf();
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <RightPanelHeader>
      {isTriggerElementHidden && (
        <SlidingHeaderWrapper className="noWrap">{lineItemDetails.signature}</SlidingHeaderWrapper>
      )}
      <div className="d-flex align-items-center justify-content-end flex-1">
        <Tooltip title="Pobierz etykietę">
          <IconButton
            icon={<MdiQrCode size="16" />}
            onClick={() => {
              handleDownloadExternalManufacturingLineItemLabelPdf(
                lineItemDetails.id,
                lineItemDetails.signature,
              );
            }}
            variant="transparent"
          />
        </Tooltip>
        <span className="divider line-divider" />
        <Tooltip title="Zamknij panel">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};
