import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiSide = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path d="M9.02751 13.9277V15.1094H6.01186V13.9277H9.02751ZM6.46047 8V15.1094H5V8H6.46047Z" />
    <path d="M17.3966 12.6045H15.9113V11.418H17.3966C17.6027 11.418 17.7705 11.3724 17.9001 11.2812C18.033 11.1901 18.1294 11.0648 18.1892 10.9053C18.249 10.7425 18.2789 10.5602 18.2789 10.3584C18.2789 10.1533 18.2473 9.96289 18.1842 9.78711C18.1244 9.60807 18.0297 9.46322 17.9001 9.35254C17.7738 9.24186 17.606 9.18652 17.3966 9.18652H16.3798V15.1094H14.9243V8H17.3966C17.8851 8 18.3055 8.10091 18.6577 8.30273C19.01 8.50456 19.2791 8.78288 19.4652 9.1377C19.6546 9.48926 19.7493 9.8929 19.7493 10.3486C19.7493 10.8076 19.6546 11.2064 19.4652 11.5449C19.2791 11.8802 19.01 12.1406 18.6577 12.3262C18.3055 12.5117 17.8851 12.6045 17.3966 12.6045Z" />
    <path d="M11 5.6L11 4L13 4V5.6L11 5.6ZM11 10.9333L11 7.73333H13V10.9333H11ZM11 16.2667L11 13.0667H13V16.2667H11ZM11 20L11 18.4H13V20H11Z" />
    <rect x="2" y="2" width="20" height="2" fill="black" fillOpacity="0.88" />
    <rect x="2" y="20" width="20" height="2" fill="black" fillOpacity="0.88" />
  </SvgWrapper>
);
