import { useRedux, useSelector } from "hooks";
import { useEffect } from "react";

export const useUnpaidInvoiceToastr = () => {
  const isInfoAboutTurnOffMiloVisible = useSelector(
    store => store.partials.developers.settings[0]?.isInfoAboutTurnOffMiloVisible,
  );
  const [dispatch, { ui }] = useRedux();

  useEffect(() => {
    if (isInfoAboutTurnOffMiloVisible) dispatch(ui.setShowUnpaidInvoiceToastr(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
