import { ColumnDef } from "@tanstack/table-core";
import { CURRENCY_TYPE } from "CONSTANTS";
import {
  NormalizedTradingDocumentSummary,
  TradingDocument,
  TradingDocumentSummaryType,
} from "api/trading-documents/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay/AmountDisplay";
import {
  ColumnHelperArgs,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useSummaryColumns = (tradingDocument: TradingDocument) => {
  return useCreateTableColumns<NormalizedTradingDocumentSummary>(({ columnHelper }) => {
    const amountColumns = columnsFactory(columnHelper);
    return [
      columnHelper.stretchContent,
      columnHelper.accessor(row => row.totalWithTax, {
        id: "totalWithTaxAmount",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            kwota do zapłaty
          </Typography>
        ),
        size: 100,
        cell: info => {
          const isFirstRow = info.row.index === 0;
          const totalWithTax = info.getValue();
          if (!isFirstRow) return null;
          return (
            <AmountDisplay
              amount={totalWithTax ? String(totalWithTax) : "0.00"}
              className="justify-content-end w-100 text-right"
              currency={tradingDocument.currency as CURRENCY_TYPE}
              integerTypographyProps={{
                fontSize: "12",
                fontWeight: "700",
              }}
              decimalTypographyProps={{
                fontSize: "10",
                fontWeight: "700",
              }}
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "additionalLabels",
        header: " ",
        size: 40,
        cell: info => {
          const isFirstRow = info.row.index === 0;
          const rowType = info.getValue().type;
          if (isFirstRow && rowType !== TradingDocumentSummaryType.TOTAL)
            return (
              <Typography
                className="text-right w-100"
                color="neutralBlack48"
                fontSize="12"
                fontWeight="400"
              >
                w tym:
              </Typography>
            );
          if (rowType === TradingDocumentSummaryType.TOTAL)
            return (
              <Typography
                className="text-right w-100"
                color="neutralBlack48"
                fontSize="12"
                fontWeight="400"
              >
                suma:
              </Typography>
            );
          return null;
        },
      }),
      columnHelper.text(row => (row.vatRate ? `${row.vatRate}%` : " "), {
        header: "VAT",
        size: 45,
        typographyProps: {
          fontSize: "12",
          fontWeight: "700",
        },
      }),
      amountColumns.summaryAmount("totalWithoutTax", "wartość netto", 100),
      amountColumns.summaryAmount("totalTax", "kwota VAT", 120),
      amountColumns.summaryAmount("totalWithTax", "wartość brutto", 120),
    ];
  });
};

const columnsFactory = (columnHelper: ColumnHelperArgs<NormalizedTradingDocumentSummary>) => {
  return {
    summaryAmount: (
      field: "totalWithTax" | "totalTax" | "totalWithoutTax",
      header: string,
      size: number,
    ) =>
      columnHelper.accessor(row => row, {
        id: field,
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            {header}
          </Typography>
        ),
        size,
        cell: info => {
          const summary = info.getValue();
          if (summary.type === TradingDocumentSummaryType.VAT_RATE)
            return (
              <AmountDisplay
                amount={summary[field] ? String(summary[field]) : "0.00"}
                className="justify-content-end w-100 text-right"
                integerTypographyProps={{
                  fontSize: "12",
                  fontWeight: "700",
                }}
                decimalTypographyProps={{
                  fontSize: "10",
                  fontWeight: "700",
                }}
              />
            );
          return (
            <AmountDisplay
              amount={summary[field] ? String(summary[field]) : "0.00"}
              className="justify-content-end w-100 text-right"
              integerTypographyProps={{ fontSize: "14", fontWeight: "600" }}
              decimalTypographyProps={{ fontSize: "12", fontWeight: "600" }}
            />
          );
        },
      }) as ColumnDef<NormalizedTradingDocumentSummary>,
  };
};
