import { AutoAssignOrderBy, AutoAssignProcedure } from "api/new-production-plans/enums";
import { ShelfFilters, ShelfItem } from "api/new-production-plans/models";
import { UUID } from "api/types";
import { RadioLabels } from "components/utils";
import { useAutoAssignManufacturingWorkingUnits } from "pages/productionPlans/productionPlanDetails/hooks/useAutoAssignManufacturingWorkingUnits";
import { useState } from "react";
import { queryString } from "utilities";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { productionPlansUtils } from "utilities/productionPlans";

interface Props {
  close: () => void;
  filters: ShelfFilters;
  productionPlanId: UUID;
  search: string;
  shelfItem?: ShelfItem;
}

export const AutoAssignModal = ({ close, filters, productionPlanId, search, shelfItem }: Props) => {
  const [procedure, setProcedure] = useState<AutoAssignProcedure>(AutoAssignProcedure.FILL);
  const [orderBy, setOrderBy] = useState<AutoAssignOrderBy[]>([AutoAssignOrderBy.SOURCE_DEPARTURE]);

  const autoAssignMutation = useAutoAssignManufacturingWorkingUnits();

  const handleSubmit = () => {
    autoAssignMutation.mutate(
      {
        filters,
        group: shelfItem
          ? {
              groupBy: queryString.parse(search).groupBy,
              groupByFilter:
                shelfItem.header !== "Pozostałe"
                  ? shelfItem.origin
                  : queryString.parse(search).groupBy,
              groupByValue: shelfItem.filterValue ? shelfItem.filterValue : "",
            }
          : undefined,
        rules: {
          procedure,
          orderBy,
        },
        id: productionPlanId,
      },
      {
        onSuccess: () => {
          close();
        },
      },
    );
  };

  const changeOrderBy = (value: string | number | null) => {
    if (!value) return;
    if (orderBy.includes(value as AutoAssignOrderBy)) {
      setOrderBy(prevOrderByList => prevOrderByList.filter(orderBy => orderBy !== String(value)));
    } else {
      setOrderBy(prevOrderByList => {
        return [...prevOrderByList, value as AutoAssignOrderBy];
      });
    }
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Rozłóż automatycznie
        </Typography>
      }
      width={480}
    >
      <div className="d-flex flex-column gap-3">
        <div className="px-3">
          <Typography color="neutralBlack48" fontSize="16" fontWeight="700">
            Strategia przydziału:
          </Typography>
          <RadioLabels
            allowUncheck={false}
            items={productionPlansUtils.autoAssignProceduresOptions}
            name=""
            onChange={({ value }) => {
              if (!value) return;
              setProcedure(value as AutoAssignProcedure);
            }}
            value={procedure}
          />
        </div>
        <div className="px-3">
          <Typography color="neutralBlack48" fontSize="16" fontWeight="700">
            Przydziel w pierwszej kolejności wg:
          </Typography>
          <RadioLabels
            items={productionPlansUtils.autoAssignOrderByOptions}
            multiple
            name=""
            onChange={({ value }) => changeOrderBy(value)}
            values={orderBy}
          />
        </div>
        <div className="d-flex align-items-center gap-2 p-3 borderTop">
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Anuluj
          </Button>
          <Button
            className="text-uppercase"
            isLoading={autoAssignMutation.isLoading}
            onClick={handleSubmit}
            size="medium"
            variant="deepPurple"
          >
            Rozłóż automatycznie
          </Button>
        </div>
      </div>
    </Modal>
  );
};
