import { config as defaultConfig } from "./default";
import { OwnerConfig } from "./types";
import { configMerger } from "./utils";

export const config: OwnerConfig = {
  b2b: configMerger(defaultConfig.b2b, {
    orderPanel: {
      allowOrderConfirmation: false,
    },
  }),
  externalManufacturing: defaultConfig.externalManufacturing,
  main: defaultConfig.main,
  manufacturing: defaultConfig.manufacturing,
  transport: defaultConfig.transport,
};
