import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiMaterials } from "components/miloDesignSystem/atoms/icons/MdiMaterials";
import { RawMaterialsRouting } from "routes/manufacturingNew/RawMaterialsRouting";

export const rawMaterials: ModuleLink = {
  url: "raw-materials",
  label: "Surowce - zapotrzebowanie i stan",
  icon: MdiMaterials,
  routing: RawMaterialsRouting,
};
