import {
  ManufacturingGroup,
  ManufacturingUnit,
  ManufacturingUnitGroupType,
} from "api/manufacturing/units/models";
import { ColumnType } from "../ColumnView";
import { useQuery } from "hooks";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import styles from "./ManufacturingPanel.module.css";
import { cx } from "utilities";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { ManufacturingPanel } from "./ManufacturingPanel";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import cuid from "cuid";

interface Props {
  columnType: ColumnType;
  unit?: ManufacturingUnit | null;
  group?: ManufacturingGroup | null;
  inProgressSearch?: string;
  setGroupDetails?: React.Dispatch<React.SetStateAction<ManufacturingGroup | null>>;
}

export const DrawerRenderer = ({
  columnType,
  group,
  inProgressSearch,
  unit,
  setGroupDetails,
}: Props) => {
  const { query } = useQuery();
  const { unitPanelId } = query;

  if (!unitPanelId) return null;

  if (columnType === ColumnType.IN_PROGRESS)
    return <InProgressPanelRenderer inProgressSearch={inProgressSearch} />;

  return (
    <PanelRenderer
      columnType={columnType}
      group={group}
      setGroupDetails={setGroupDetails}
      unit={unit}
    />
  );
};

const InProgressPanelRenderer = ({ inProgressSearch }: Pick<Props, "inProgressSearch">) => {
  const { query } = useQuery();
  const { unitPanelId } = query;

  const {
    data: unitInProgressDetails,
    error,
    isLoading,
  } = manufacturingUnitsActions.useGetManufacturingUnitGroupDetails(unitPanelId);

  if (error)
    return (
      <div className={cx(styles.panelWrapper, "pb-2")}>
        {error && <CommonError status={error._httpStatus_} />}
      </div>
    );

  if (isLoading)
    return (
      <div className={cx(styles.panelWrapper, "pb-2")}>
        <div className="d-flex align-items-center justify-content-center">
          <Spinner size={24} />
        </div>
      </div>
    );

  if (!unitInProgressDetails || inProgressSearch === undefined) return null;

  if (unitInProgressDetails.type === ManufacturingUnitGroupType.GROUP)
    return (
      <ManufacturingPanel
        columnType={ColumnType.IN_PROGRESS}
        downloadLabelFn={() =>
          manufacturingFileFactory.manufacturingItemPdf(
            unitInProgressDetails.manufacturingItems.map(item => item.id),
            unitInProgressDetails.signature,
          )
        }
        panel={{
          attributes: unitInProgressDetails.attributes,
          elementsCount: unitInProgressDetails.manufacturingItems.length,
          id: unitInProgressDetails.id,
          signature: unitInProgressDetails.signature,
          type: ManufacturingUnitGroupType.GROUP,
        }}
      />
    );

  return (
    <ManufacturingPanel
      columnType={ColumnType.IN_PROGRESS}
      downloadLabelFn={() =>
        manufacturingFileFactory.manufacturingItemPdf(
          [unitInProgressDetails.manufacturingItem.id],
          unitInProgressDetails.signature,
        )
      }
      employeeMutation={() =>
        manufacturingUnitsActions.useInProgressManufacturingUnitPatch(inProgressSearch)
      }
      panel={{
        attributes: unitInProgressDetails.attributes,
        employee: unitInProgressDetails.employee,
        groupSignature: unitInProgressDetails.group?.signature,
        id: unitInProgressDetails.id,
        isDeclined: unitInProgressDetails.isDeclined,
        isCancelled: unitInProgressDetails.isCancelled,
        implementedBy: unitInProgressDetails.implementedBy,
        manufacturer: unitInProgressDetails.manufacturer,
        manufacturingItemId: unitInProgressDetails.manufacturingItem.id,
        note: unitInProgressDetails.note,
        orderSignature: unitInProgressDetails.order?.signature,
        priority: unitInProgressDetails.priority,
        scheduledAt: unitInProgressDetails.scheduledAt,
        signature: unitInProgressDetails.signature,
        type: ManufacturingUnitGroupType.MANUFACTURING_WORKING_UNIT,
      }}
      priorityMutation={() =>
        manufacturingUnitsActions.useInProgressManufacturingUnitPatch(inProgressSearch)
      }
    />
  );
};

const PanelRenderer = ({
  columnType,
  group,
  setGroupDetails,
  unit,
}: Pick<Props, "columnType" | "group" | "setGroupDetails" | "unit">) => {
  const {
    data: unitDetails,
    error,
    isLoading,
  } = manufacturingUnitsActions.useGetTodoManufacturingUnit(unit ? unit.id : "", {
    enabled: Boolean(unit),
  });

  if (error)
    return (
      <div className={cx(styles.panelWrapper, "pb-2")}>
        {error && <CommonError status={error._httpStatus_} />}
      </div>
    );

  if (isLoading)
    return (
      <div className={cx(styles.panelWrapper, "pb-2")}>
        <div className="d-flex align-items-center justify-content-center">
          <Spinner size={24} />
        </div>
      </div>
    );

  if (columnType === ColumnType.TODO && group)
    return (
      <ManufacturingPanel
        columnType={ColumnType.TODO}
        downloadLabelFn={panel =>
          manufacturingFileFactory.manufacturingItemPdf(
            panel.groupElements!.map(groupElement => groupElement.manufacturingItemId!),
            panel.modelName!,
          )
        }
        panel={{
          attributes: group.attributesValues,
          elementsCount: group.elements.length,
          groupElements: manufacturingStagesUtils.normalizeTodoGroupedUnits(group.elements),
          id: `todo-group-${cuid()}`,
          modelName: group.modelName,
          type: ManufacturingUnitGroupType.GROUP,
        }}
        setGroupDetails={setGroupDetails}
      />
    );

  if (!unitDetails) return null;

  const panelDetails = {
    attributes: unitDetails.attributeValues,
    employee: unitDetails.employee,
    finishedAt: columnType === ColumnType.READY ? unitDetails.finishedAt : undefined,
    id: unitDetails.id,
    implementedBy: unitDetails.implementedBy,
    isCancelled: unitDetails.isCancelled,
    isDeclined: unitDetails.isDeclined,
    manufacturer: unitDetails.manufacturer,
    manufacturingItemId: unitDetails.manufacturingItemId,
    orderSignature: unitDetails.orderSignature,
    priority: unitDetails.priority,
    productName: unitDetails.name,
    scheduledAt: unitDetails.scheduledAt,
    signature: unitDetails.signature,
    type: ManufacturingUnitGroupType.MANUFACTURING_WORKING_UNIT,
  };

  if (columnType === ColumnType.TODO)
    return (
      <ManufacturingPanel
        columnType={ColumnType.TODO}
        downloadLabelFn={panel =>
          manufacturingFileFactory.manufacturingItemPdf(
            [panel.manufacturingItemId!],
            panel.signature!,
          )
        }
        panel={panelDetails}
        priorityMutation={() => manufacturingUnitsActions.useTodoManufacturingUnitPatch()}
      />
    );

  return (
    <ManufacturingPanel
      columnType={ColumnType.READY}
      downloadLabelFn={panel =>
        manufacturingFileFactory.manufacturingItemPdf(
          [panel.manufacturingItemId!],
          panel.signature!,
        )
      }
      employeeMutation={() => manufacturingUnitsActions.useReadyManufacturingUnitPatch()}
      panel={panelDetails}
    />
  );
};
