import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { MyPurchaseInvoiceToReview, TradingDocument } from "api/trading-documents/models";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler/FileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDownloadDoc } from "components/miloDesignSystem/atoms/icons/MdiDownloadDoc";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";

interface Props {
  selectedRows: TradingDocument[] | MyPurchaseInvoiceToReview[];
}

export const DownloadRevisor = ({ selectedRows }: Props) => {
  return (
    <FileDownloadHandler
      type="epp"
      factoryFn={() =>
        tradingDocumentFileFactory.revisorPdf(
          selectedRows.map(tradingDocument => tradingDocument.id),
        )
      }
    >
      {({ download, isLoading }) => (
        <Tooltip title="Pobierz Revisor dla zaznaczonych">
          <IconButton
            isLoading={isLoading}
            icon={MdiDownloadDoc}
            onClick={download}
            variant="transparent"
            theme="dark"
          />
        </Tooltip>
      )}
    </FileDownloadHandler>
  );
};
