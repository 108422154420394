import { wmsBasketsActions } from "api/wms/baskets/actions";
import { BasketListItem } from "api/wms/baskets/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { BasketStatusTag } from "./shared/BasketStatusTag";

export const useBasketsColumns = () => {
  const handleDownloadLabel = wmsBasketsActions.useDownloadBasketLabel();
  return useCreateTableColumns<BasketListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.code, {
        header: "nr kosza",
        size: 300,
      }),
      columnHelper.accessor(row => row.isEmpty, {
        header: "status",
        size: 100,
        cell: info => {
          const isEmpty = info.getValue();
          return <BasketStatusTag isEmpty={isEmpty} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "etykieta",
        size: 45,
        cell: info => {
          const basket = info.getValue();
          return (
            <div className="d-flex justify-content-center w-100">
              <IconButton
                icon={<MdiQrCode size="16" />}
                onClick={event => {
                  event.stopPropagation();
                  handleDownloadLabel(basket.id, basket.code);
                }}
                variant="transparent"
              />
            </div>
          );
        },
      }),
    ];
  });
};
