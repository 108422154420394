import { RightPanelHeader } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useRoute } from "hooks/apiPrimitives";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiMapAlt } from "components/miloDesignSystem/atoms/icons/MdiMapAlt";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useDrawer } from "hooks/useDrawer";
import { useNavigate } from "hooks/useNavigate";

export const PanelHeader = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { close } = useDrawer("manufacturingRoute");
  const { data: route } = useRoute(panelId);
  const navigate = useNavigate();

  if (!route) return null;

  return (
    <RightPanelHeader>
      <Typography fontSize="16" fontWeight="600">
        {route.signature}
      </Typography>
      <div className="d-flex align-items-center justify-content-end gap-1 position-relative">
        <Tooltip title="Edytuj trasę">
          <IconButton
            icon={MdiMapAlt}
            onClick={() => navigate(`/routes/creator/${route.id}`)}
            variant="transparent"
          />
        </Tooltip>
        <span className="divider line-divider" />

        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};
