import { PickingDetails } from "api/wms/models";
import { InfoLabel } from "components/common/infoLabel";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../RightPanel.module.css";
import QRCode from "qrcode.react";
import { useSelector, useStateModal } from "hooks";
import { usePickingPatchMutation, usePickingPatchMutationWithoutParsing } from "api/wms/hooks";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { PickingFinishDate } from "../../components/PickingFinishDate";
import { PickingStartDate } from "../../components/PickingStartDate";
import { dateFns, dateUtils } from "utilities";
import { getParsedDuration } from "pages/wms/shared/utils/getParsedDuration";
import { TimePicker } from "components/utils/timePicker";
import { PickingDeparture } from "./PickingDeparture";
import {
  PickingWorkspaceModal,
  PickingWorkspaceState,
} from "./pickingWorkspaceModal/PickingWorkspaceModal";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { TextField } from "components/miloDesignSystem/atoms/textField/TextField";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { assertIsDefined } from "utilities/assertIsDefined";

interface Props {
  picking: PickingDetails;
  readOnly?: boolean;
  visibility?: {
    ramp?: boolean;
  };
}

export const GeneralInfoSection = ({
  picking,
  readOnly = false,
  visibility = { ramp: true },
}: Props) => {
  const ramps = useSelector(store => store.partials.wmsRamps);
  const estimatedTimeOfPickingTimeMutation = usePickingPatchMutation();
  const pickingWorkspaceModal = useStateModal<PickingWorkspaceState>();

  return (
    <>
      <RightPanelSection padding="px-3 pt-0 pb-2">
        <div className="d-flex align-items-start justify-content-between gap-2">
          <div>
            {visibility.ramp && (
              <InfoLabel title="rampa">
                <Select.Async
                  disabled={readOnly}
                  items={ramps.map(ramp => ({
                    text: ramp.name,
                    type: MenuItemType.TEXT,
                    value: ramp.id,
                  }))}
                  mutationHook={usePickingPatchMutationWithoutParsing}
                  transformQueryData={ramp => {
                    const foundRamp = ramps.find(_ramp => _ramp.id === ramp);
                    assertIsDefined(foundRamp);
                    return {
                      id: picking.id,
                      toUpdate: {
                        ramp: {
                          id: foundRamp.id,
                          name: foundRamp.name,
                        },
                      },
                    };
                  }}
                  selected={picking.ramp?.id ?? ""}
                />
              </InfoLabel>
            )}
            <InfoLabel title="planowany początek pickingu">
              <div className="d-flex align-items-center gap-2">
                <DatePicker.Async
                  isNullable
                  mutationHook={usePickingPatchMutation}
                  transformQueryData={value => ({
                    id: picking.id,
                    toUpdate: {
                      estimatedTimeOfPickingDate: value ? dateUtils.formatDateToIso(value) : value,
                    },
                  })}
                  label="Data"
                  value={picking.estimatedTimeOfPickingDate}
                />
                <TimePicker
                  disabled={estimatedTimeOfPickingTimeMutation.isLoading || readOnly}
                  inProgress={estimatedTimeOfPickingTimeMutation.isLoading}
                  look="common"
                  onBlur={time =>
                    estimatedTimeOfPickingTimeMutation.mutate({
                      id: picking.id,
                      toUpdate: { estimatedTimeOfPickingTime: time || null },
                    })
                  }
                  overwrites={{
                    container: { className: styles.formHeight },
                    input: { className: styles.formHeight },
                  }}
                  value={picking.estimatedTimeOfPickingTime || ""}
                />
              </div>
            </InfoLabel>
            <InfoLabel title="opis">
              <TextField.Async
                disabled={readOnly}
                mutationHook={usePickingPatchMutation}
                placeholder="Wpisz opis"
                size="small"
                transformQueryData={description => ({
                  id: picking.id,
                  toUpdate: { description },
                })}
                value={picking.description}
              />
            </InfoLabel>
            <InfoLabel title="wyjazd">
              <PickingDeparture picking={picking} />
            </InfoLabel>
            <InfoLabel title="załadunek">
              <Typography fontSize="14" fontWeight="600">
                {picking.estimatedTimeOfLoading
                  ? dateFns.format(new Date(picking.estimatedTimeOfLoading), "H: mm")
                  : EMPTY_VALUE}
              </Typography>
            </InfoLabel>
            <div className="d-flex align-items-center gap-2 mb-2">
              <div className={styles.timeContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  start:
                </Typography>
                <PickingStartDate fontSize="14" fontWeight="500" picking={picking} />
              </div>
              <div className={styles.timeContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  zakończenie:
                </Typography>
                <PickingFinishDate fontSize="14" fontWeight="500" picking={picking} />
              </div>
              <div className={styles.timeContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  czas pickingu:
                </Typography>
                <Typography fontSize="14" fontWeight="600">
                  {picking.duration && Boolean(picking.duration.length)
                    ? getParsedDuration(picking.duration)
                    : EMPTY_VALUE}
                </Typography>
              </div>
            </div>
            <InfoLabel title="kontrahenci">
              <div className={styles.suppliers}>
                <Typography fontSize="14" fontWeight="700">
                  {picking.suppliers && Boolean(picking.suppliers.length)
                    ? picking.suppliers.join(", ")
                    : EMPTY_VALUE}
                </Typography>
              </div>
            </InfoLabel>
            <InfoLabel className={styles.collaborators} title="osoby zaangażowane">
              <div className="d-flex flex-column gap-1">
                {picking.collaborators ? (
                  picking.collaborators.map(collaborator => (
                    <div
                      className="d-flex align-items-center gap-1 cursor-pointer"
                      key={collaborator.id}
                      onClick={() =>
                        pickingWorkspaceModal.open({
                          user: collaborator,
                          picking: picking.id,
                        })
                      }
                    >
                      <Avatar size="sm" user={collaborator} theme="light" />
                      <Typography className="ml-1" fontSize="12" fontWeight="700" noWrap>
                        {collaborator.firstName} {collaborator.lastName}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <EmptyValue />
                )}
              </div>
            </InfoLabel>
          </div>
          <div className="d-flex flex-column gap-1">
            <div>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                QR kod przyjęcia
              </Typography>
            </div>
            <QRCode value={picking.qrCode} />
          </div>
        </div>
      </RightPanelSection>
      {pickingWorkspaceModal.isOpen &&
        pickingWorkspaceModal.state?.picking &&
        pickingWorkspaceModal.state.user && (
          <PickingWorkspaceModal
            close={pickingWorkspaceModal.close}
            state={pickingWorkspaceModal.state}
          />
        )}
    </>
  );
};
