import { Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { FinancesBalance } from "pages/logistics/financesBalance/FinancesBalance";
import { useSelector, useSettings } from "hooks";

export const FinancesBalanceRouting = ({ match }: RouteComponentProps) => {
  const businessEntities = useSelector(store => store.partials.businessEntities).filter(
    seller => seller.kind === "INTERNAL",
  );
  const settings = useSettings();

  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => (
          <Redirect
            to={`${match.path}/${settings?.tradingDocument
              ?.defaultSellerForAutomaticallyDocuments || businessEntities[0]?.id}`}
          />
        )}
      />
      <Route
        exact={true}
        path={`${match.path}/:businessEntityId`}
        component={FinancesBalance}
        title="Milo - bilans przychodów i kosztów"
      />

      <Route exact={true} path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};
