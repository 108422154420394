import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiMilo = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4.54054C7.88025 4.54054 4.54054 7.88025 4.54054 12C4.54054 16.1197 7.88025 19.4595 12 19.4595C16.1197 19.4595 19.4595 16.1197 19.4595 12C19.4595 7.88025 16.1197 4.54054 12 4.54054ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#765EE3"
    />
    <path
      d="M12.0004 13.2977C12.7169 13.2977 13.2977 12.7169 13.2977 12.0004C13.2977 11.2839 12.7169 10.7031 12.0004 10.7031C11.2839 10.7031 10.7031 11.2839 10.7031 12.0004C10.7031 12.7169 11.2839 13.2977 12.0004 13.2977Z"
      fill="#5EE3CB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0002 12.9732C12.5375 12.9732 12.9732 12.5375 12.9732 12.0002C12.9732 11.4628 12.5375 11.0272 12.0002 11.0272C11.4628 11.0272 11.0272 11.4628 11.0272 12.0002C11.0272 12.5375 11.4628 12.9732 12.0002 12.9732ZM8.43262 12.0002C8.43262 10.0299 10.0299 8.43262 12.0002 8.43262C13.9705 8.43262 15.5678 10.0299 15.5678 12.0002C15.5678 13.9705 13.9705 15.5678 12.0002 15.5678C10.0299 15.5678 8.43262 13.9705 8.43262 12.0002Z"
      fill="#5EE3CB"
    />
  </SvgWrapper>
);
