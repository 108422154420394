import { countryCodes } from "CONSTANTS";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { MdiRadioButtonChecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonChecked";
import { MdiRadioButtonUnchecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonUnchecked";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Formik, FormikHelpers } from "formik";
import { useSelector } from "hooks";
import { cx } from "utilities";
import styles from "../PickupPoints.module.css";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { handleSubmitResponse } from "utilities/handleSubmitResponse";
import { AddCourierToPickupPointDTO, PickupPointListItem } from "api/shipping/pickup-points/models";
import { pickupPointsActions } from "api/shipping/pickup-points/actions";
import { StartingPoint } from "api/other/models";

interface Props {
  close: () => void;
  pickupPoints: PickupPointListItem[];
  startingPoint: StartingPoint;
}

export const AddCourier = ({ close, pickupPoints, startingPoint }: Props) => {
  const initialValues: AddCourierToPickupPointDTO = {
    isDefault: false,
    pickupPoint: startingPoint.id,
    shippingService: null,
  };
  const countries = Object.fromEntries(countryCodes.map(country => [country.value, country.name]));
  const shippingServices = useSelector(store => store.partials.shippingShippingServices);
  const postMutation = pickupPointsActions.useCreatePickupPoint();

  const handleSubmit = (
    values: AddCourierToPickupPointDTO,
    actions: FormikHelpers<AddCourierToPickupPointDTO>,
  ) => {
    postMutation.mutate(values, handleSubmitResponse(actions, { onSuccess: close }));
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj kuriera do punktu startowego
        </Typography>
      }
      width={470}
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="py-2 px-3 d-flex flex-column gap-3">
              <div>
                <Typography fontSize="16" fontWeight="600" noWrap>
                  {startingPoint.name || EMPTY_VALUE}
                </Typography>
                <Typography fontSize="16" fontWeight="600" noWrap>
                  {startingPoint.street}
                </Typography>
                <Typography fontSize="16" fontWeight="600" noWrap>
                  {startingPoint.postCode} {startingPoint.city}
                </Typography>
                <Typography fontSize="16" fontWeight="600" noWrap>
                  {countries[startingPoint.countryCode] || EMPTY_VALUE}
                </Typography>
              </div>
              <div className="d-flex flex-column pt-3 gap-2">
                <Typography className="pb-2" fontSize="14" fontWeight="500">
                  Wybierz kuriera
                </Typography>
                <div className={cx(styles.shippingServices, "d-flex flex-column gap-2")}>
                  {shippingServices
                    .filter(shippingService =>
                      pickupPoints.every(
                        pickupPoint => pickupPoint.shippingService.id !== shippingService.id,
                      ),
                    )
                    .map(shippingService => (
                      <div
                        className="cursor-pointer"
                        key={shippingService.id}
                        onClick={() => setFieldValue("shippingService", shippingService.id)}
                      >
                        <Tag
                          label={shippingService.name}
                          startIcon={
                            values.shippingService === shippingService.id
                              ? MdiRadioButtonChecked
                              : MdiRadioButtonUnchecked
                          }
                          type={
                            values.shippingService === shippingService.id ? "filled" : "outlined"
                          }
                          variant={
                            values.shippingService === shippingService.id
                              ? "deepPurple50"
                              : "quaternary"
                          }
                        />
                      </div>
                    ))}
                </div>
                <div className="pt-3">
                  <Checkbox.Form
                    disabled={!values.shippingService}
                    label="Oznacz jako domyślny punkt dla tego kuriera"
                    name="isDefault"
                    size="sm"
                  />
                </div>
              </div>
            </div>
            <div className="p-3 d-flex align-items-center gap-2">
              <Button
                className="text-uppercase"
                onClick={close}
                size="medium"
                variant="transparent"
              >
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Gotowe
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
