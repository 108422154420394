import {
  EmailStatus as ServiceProviderEmailStatus,
  ManufacturingOrderStatus,
} from "api/manufacturing/models";
import { EmailStatus } from "api/messages/models";
import { TagProps } from "components/miloDesignSystem/atoms/tag";

const manufacturingOrderStatusToTag: Record<ManufacturingOrderStatus, TagProps<string>> = {
  NOT_ORDERED: { label: "Nie zlecono", variant: "warning" },
  ORDERED: { label: "Zlecono", variant: "info" },
  RECEIVED: { label: "Przyjęto na magazyn", variant: "success" },
};

const emailStatusToTag: Record<ServiceProviderEmailStatus, TagProps<string>> = {
  DELIVERED: { label: "Doręczony", variant: "success", type: "outlined" },
  ERROR: { label: "Błąd wysyłki", variant: "danger", type: "outlined" },
  NOT_SENT: { label: "Niewysłany", variant: "warning", type: "outlined" },
  SEEN: { label: "Odczytany", variant: "deepPurple50", type: "outlined" },
  SENT: { label: "Wysłany", variant: "info", type: "outlined" },
};

const emailStatusDict: Record<EmailStatus, TagProps<string>> = {
  CLICKED_LINK: { label: "odczytano", type: "outlined", variant: "success" },
  DECLINED: { label: "nie wysłano", type: "filled", variant: "danger" },
  OPENED: { label: "odczytano", type: "outlined", variant: "success" },
  SENT: { label: "wysłano", type: "outlined", variant: "deepPurple400" },
};

export const manufacturingConstants = {
  emailStatusDict,
  manufacturingOrderStatusToTag,
  emailStatusToTag,
};
