import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Formik, FormikHelpers } from "formik";
import { cx } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Material } from "api/manufacturing/materials/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useAddProviderColumns } from "./useAddProviderColumns";
import { ToggleHookState } from "hooks";
import { UUID } from "api/types";
import { materialsActions } from "api/manufacturing/materials/actions";
import { assertIsDefined } from "utilities/assertIsDefined";

interface Props {
  modal: ToggleHookState;
  materialId: UUID;
  manufacturers: Material["providers"];
}

export interface SetProviderAsDefaultFormValues {
  manufacturers: Material["providers"];
}
export const SetProviderAsDefaultModal = (props: Props) => {
  const columns = useAddProviderColumns();
  const postMutation = materialsActions.usePatchMaterialManufacturer();

  const initialManufacturers = (() => {
    if (props.manufacturers.some(m => m.isMain)) {
      return props.manufacturers;
    }
    return props.manufacturers.map((manufacturer, index) =>
      index === 0 ? { ...manufacturer, isMain: true } : manufacturer,
    );
  })();
  const initialValues: SetProviderAsDefaultFormValues = {
    manufacturers: initialManufacturers,
  };

  const handleSubmit = (
    values: SetProviderAsDefaultFormValues,
    actions: FormikHelpers<SetProviderAsDefaultFormValues>,
  ) => {
    const manufacturerToUpdate = values.manufacturers.find(e => e.isMain);
    assertIsDefined(manufacturerToUpdate);
    postMutation.mutate(
      { id: manufacturerToUpdate.id, isMain: true },
      {
        onSuccess: () => {
          actions.setSubmitting(false);
          props.modal.close();
        },
        onError: error => {
          actions.setSubmitting(false);
          actions.setErrors(error.response?.data);
        },
      },
    );
  };

  return (
    <Modal
      close={props.modal.close}
      isOpen
      width={688}
      title={
        <Typography fontSize="20" fontWeight="700">
          Wskaż domyślnego dostawcę
        </Typography>
      }
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting, isValid, values, setFieldValue, setValues }) => {
          return (
            <>
              <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
                <div className="mx-3">
                  <Table<Material["providers"][number]>
                    overrides={() => ({
                      table: { className: "overflow-visible" },
                      tableInnerWrapper: { className: "overflow-visible" },
                    })}
                    rows={values.manufacturers}
                    columns={columns}
                    error={null}
                    isLoading={false}
                    uiSchema={{
                      header: {
                        backgroundColor: "neutralWhite88",
                      },
                      cell: {
                        height: "34",
                      },
                    }}
                  />
                </div>

                <div className="d-flex align-items-center gap-2 p-3">
                  <Button
                    className="text-uppercase"
                    onClick={props.modal.close}
                    size="medium"
                    variant="transparent"
                  >
                    Anuluj
                  </Button>
                  <Button
                    className="text-uppercase"
                    isLoading={isSubmitting}
                    size="medium"
                    type="submit"
                    variant="deepPurple"
                  >
                    Zmień
                  </Button>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
