import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { InfoLabel } from "components/common/infoLabel";
import { StateLabel } from "components/common/stateLabel";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  manufacturingStage: ManufacturingStage;
}

export const GeneralInfoSection = ({ manufacturingStage }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection>
      <div className="d-flex align-items-center gap-2 mb-2" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {manufacturingStage.name}
        </Typography>
        <StateLabel
          kind="none"
          style={{
            backgroundColor: manufacturingStage.backgroundColor,
            color: manufacturingStage.textColor,
          }}
        >
          {manufacturingStage.name}
        </StateLabel>
      </div>
      <InfoLabel title="dodane przez">
        {manufacturingStage.createdBy ? (
          <div className="d-flex align-items-center gap-1">
            <Avatar size="sm" user={manufacturingStage.createdBy} theme="light" />
            <Typography className="ml-1" fontSize="12" fontWeight="700" noWrap>
              {manufacturingStage.createdBy.firstName} {manufacturingStage.createdBy.lastName}
            </Typography>
          </div>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
      <InfoLabel title="rodzaj produkcji">
        <Typography fontSize="14" fontWeight="500">
          {manufacturingStage.implementedBy
            ? manufacturingStage.implementedBy === "IN_HOUSE"
              ? "wewnętrzna"
              : "kontraktor"
            : EMPTY_VALUE}
        </Typography>
      </InfoLabel>
    </RightPanelSection>
  );
};
