import { customerActions } from "api/customers/actions";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { useParams } from "react-router";
import styles from "./PaymentsSection.module.css";
import { cx } from "utilities";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { RecurringInvoiceCreationStrategy } from "api/customers/models";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";

export const Invoicing = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = customerActions.useCustomer(Number(customerId));
  assertIsDefined(customer);

  return (
    <div className="d-flex flex-column gap-3 px-1">
      <Typography className="text-uppercase" color="neutralBlack48" fontSize="10" fontWeight="700">
        Wystawianie faktur
      </Typography>
      <div className="py-2">
        <Radio.Async
          checked={
            customer.recurringInvoiceCreationStrategy ===
              RecurringInvoiceCreationStrategy.EVERY_X_DAYS ||
            customer.recurringInvoiceCreationStrategy ===
              RecurringInvoiceCreationStrategy.ON_GIVEN_DAY
          }
          helperText="Faktury wysyłają się we wskazanym przez Ciebie dniu"
          label="Faktury wystawiają się automatycznie"
          mutationHook={customerActions.usePatchCustomer}
          size="sm"
          transformQueryData={() => ({
            id: customer.id,
            toUpdate: {
              recurringInvoiceCreationStrategy: RecurringInvoiceCreationStrategy.EVERY_X_DAYS,
            },
          })}
        />
        <div className={cx(styles.subRadios, "d-flex flex-column gap-3 py-3")}>
          <div className="d-flex flex-column gap-1">
            <Radio.Async
              checked={
                customer.recurringInvoiceCreationStrategy ===
                RecurringInvoiceCreationStrategy.ON_GIVEN_DAY
              }
              helperText="Np. każdego pierwszego dnia miesiąca"
              label="Danego dnia miesiąca"
              mutationHook={customerActions.usePatchCustomer}
              size="sm"
              transformQueryData={() => ({
                id: customer.id,
                toUpdate: {
                  recurringInvoiceCreationStrategy: RecurringInvoiceCreationStrategy.ON_GIVEN_DAY,
                },
              })}
            />
            <div className={styles.subRadios}>
              <TextField.Async
                containerClassName={styles.invoicingInput}
                disabled={
                  customer.recurringInvoiceCreationStrategy !==
                  RecurringInvoiceCreationStrategy.ON_GIVEN_DAY
                }
                mutationHook={customerActions.usePatchCustomer}
                label="Dzień miesiąca"
                transformQueryData={invoiceCreationDay => ({
                  id: customer.id,
                  toUpdate: {
                    invoiceCreationDay: Number(invoiceCreationDay),
                  },
                })}
                size="small"
                type="number"
                value={customer.invoiceCreationDay ?? ""}
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-1">
            <Radio.Async
              checked={
                customer.recurringInvoiceCreationStrategy ===
                RecurringInvoiceCreationStrategy.EVERY_X_DAYS
              }
              helperText="Np. co 30 dni"
              label="Co n dni"
              mutationHook={customerActions.usePatchCustomer}
              size="sm"
              transformQueryData={() => ({
                id: customer.id,
                toUpdate: {
                  recurringInvoiceCreationStrategy: RecurringInvoiceCreationStrategy.EVERY_X_DAYS,
                },
              })}
            />
            <div className={styles.subRadios}>
              <TextField.Async
                containerClassName={styles.invoicingInput}
                disabled={
                  customer.recurringInvoiceCreationStrategy !==
                  RecurringInvoiceCreationStrategy.EVERY_X_DAYS
                }
                mutationHook={customerActions.usePatchCustomer}
                label="Liczba dni"
                transformQueryData={invoiceCreationEveryXDays => ({
                  id: customer.id,
                  toUpdate: {
                    invoiceCreationEveryXDays: Number(invoiceCreationEveryXDays),
                  },
                })}
                size="small"
                type="number"
                value={customer.invoiceCreationEveryXDays ?? ""}
              />
            </div>
          </div>
        </div>
        <Radio.Async
          checked={
            customer.recurringInvoiceCreationStrategy === RecurringInvoiceCreationStrategy.NONE
          }
          label="Samodzielnie wystawiam faktury"
          mutationHook={customerActions.usePatchCustomer}
          size="sm"
          transformQueryData={() => ({
            id: customer.id,
            toUpdate: {
              recurringInvoiceCreationStrategy: RecurringInvoiceCreationStrategy.NONE,
            },
          })}
        />
      </div>
    </div>
  );
};
