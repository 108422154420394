import { UUID } from "api/types";
import { wmsBasketsActions } from "api/wms/baskets/actions";
import { PackageInBasket } from "api/wms/baskets/models";
import { wmsUtilsActions } from "api/wms/utils/actions";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiEmergencyHome } from "components/miloDesignSystem/atoms/icons/MdiEmergencyHome";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Link } from "components/miloDesignSystem/atoms/link";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { LoaderCell } from "components/miloDesignSystem/molecules/table/components/tableLoader/TableLoader";
import { mainListBigUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { errorStatusToMessageDict } from "components/utils";
import { PropsWithChildren } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const usePackageInBasketColumns = (rows: PackageInBasket[]) => {
  const handleDownloadPackageLabel = wmsBasketsActions.useDownloadPackageLabel();
  const { data: packagesOrders, error, isLoading } = wmsUtilsActions.useGetPackageOrderInfo(
    rows.map(_package => _package.uniquePackageCode),
    { enabled: Boolean(rows.length) },
  );

  return useCreateTableColumns<PackageInBasket>(
    ({ columnHelper }) => {
      return [
        columnHelper.accessor(row => row, {
          header: "paczka",
          size: 340,
          cell: info => {
            const packageInBasket = info.getValue();
            return (
              <AsyncFetchCellWrapper
                field="name"
                id={`${packageInBasket.packageInternalId}.${packageInBasket.uniquePackageCode}`}
              >
                <PackageNameColumn
                  id={`${packageInBasket.packageInternalId}.${packageInBasket.uniquePackageCode}`}
                />
              </AsyncFetchCellWrapper>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          header: "nr zew. zamówienia",
          size: 130,
          cell: info => {
            const _package = info.getValue();
            const packageOrderInfo = packagesOrders?.find(
              _searchedPackage => _searchedPackage.code === _package.uniquePackageCode,
            );
            if (error || isLoading)
              return <LoaderCell height={mainListBigUiSchema.cell.height} width={100} />;

            if (!packageOrderInfo?.order.externalId) return <EmptyValue fontSize="14" />;

            return (
              <Typography fontSize="14" fontWeight="600" noWrap>
                {packageOrderInfo?.order?.externalId}
              </Typography>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          header: "zamówienie",
          size: 120,
          cell: info => {
            const _package = info.getValue();
            const searchedPackage = packagesOrders?.find(
              _searchedPackage => _searchedPackage.code === _package.uniquePackageCode,
            );
            if (error || isLoading)
              return <LoaderCell height={mainListBigUiSchema.cell.height} width={100} />;

            if (!searchedPackage?.order) return <EmptyValue fontSize="14" />;

            return (
              <Link
                fontSize="14"
                fontWeight="600"
                target="_blank"
                to={`/orders/list/active/all?panelId=${searchedPackage.order.id}`}
              >
                {searchedPackage.order.signature}
              </Link>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          id: "packageLabel",
          header: () => (
            <Typography
              className="text-center w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              etykieta
            </Typography>
          ),
          size: 40,
          cell: info => {
            const _package = info.getValue();
            return (
              <div className="d-flex align-items-center justify-content-center ml-1">
                <Tooltip title="Pobierz etykietę paczki">
                  <IconButton
                    icon={MdiQrCode}
                    onClick={() =>
                      handleDownloadPackageLabel(
                        _package.uniquePackageCode,
                        _package.packageInternalId,
                      )
                    }
                    variant="transparent"
                  />
                </Tooltip>
              </div>
            );
          },
        }),
      ];
    },
    {
      shouldDisplayIndexColumn: true,
    },
  );
};

const AsyncFetchCellWrapper = ({
  id,
  field,
  children,
}: PropsWithChildren<{
  id: UUID;
  field: "name";
}>) => {
  const { data, isLoading, error } = wmsUtilsActions.useGetPackageInfo(id);

  if (isLoading) {
    return <LoaderCell height={mainListBigUiSchema.cell.height} width={94} />;
  }

  if (error) {
    return (
      <>
        <MdiEmergencyHome color="deepOrange500" size="16" />
        <Typography fontSize="14" fontWeight="500" color="deepOrange500" className="ml-1" noWrap>
          {errorStatusToMessageDict[error._httpStatus_]}
        </Typography>
      </>
    );
  }

  if (!data?.[field]) {
    return <EmptyValue fontSize="14" fontWeight="700" />;
  }

  return <>{children}</>;
};

const PackageNameColumn = ({ id }: { id: UUID }) => {
  const { data } = wmsUtilsActions.useGetPackageInfo(id);
  assertIsDefined(data);

  return (
    <>
      <Typography fontSize="14" fontWeight="700" noWrap className="mr-1">
        {data.details?.position &&
          `${data.details.position.current}/${data.details.position.totalInCollection}`}
      </Typography>

      <Typography fontSize="14" fontWeight="700" noWrap>
        {data.name}
      </Typography>
    </>
  );
};
