import { createColumnHelper, AccessorFn, ColumnDef } from "@tanstack/react-table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useState } from "react";
import { EMPTY_VALUE } from "../createTableColumns";
import styles from "../CreateTableColumns.module.css";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { EditableColumnProps, TColumnDef } from "./types";
import { LoaderCell } from "components/miloDesignSystem/molecules/table/components/tableLoader/TableLoader";
import { mainListBigUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { cx } from "utilities";
import { AlignedColumnHeader } from "../subcomponents/AlignedColumnHeader";

export const textColumn = <
  T,
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>(
  accessorFn: AccessorFn<T>,
  columnDef: TColumnDef<T, TData, TError, TVariables, TContext>,
): ColumnDef<T> => {
  const { textAlign = "left" } = columnDef;
  const baseColumnHelper = createColumnHelper<T>();
  return baseColumnHelper.accessor(accessorFn, {
    id: columnDef.header,
    header: () => <AlignedColumnHeader header={columnDef.header} textAlign={textAlign} />,
    size: columnDef.size,
    cell: info => {
      const value = info.getValue();
      if (columnDef.isLoading || columnDef.error)
        return <LoaderCell height={mainListBigUiSchema.cell.height} width={columnDef.size} />;
      if (columnDef.editable)
        return <EditableTextColumn columnDef={columnDef} row={info.row} value={value} />;
      return (
        <Typography
          className={cx({
            "text-right w-100": textAlign === "right",
            "text-left w-100": textAlign === "left",
            "text-center w-100": textAlign === "center",
          })}
          fontSize={columnDef.typographyProps?.fontSize || "12"}
          fontWeight={columnDef.typographyProps?.fontWeight || "700"}
          noWrap
          {...columnDef.typographyProps}
        >
          {value || EMPTY_VALUE}
        </Typography>
      );
    },
  });
};

const EditableTextColumn = <
  T,
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>({
  columnDef,
  row,
  value,
}: EditableColumnProps<T, TData, TError, TVariables, TContext>) => {
  const [isEditing, setIsEditing] = useState(false);
  const { textAlign = "left" } = columnDef;

  if (!isEditing)
    return (
      <div
        className={styles.editableCell}
        onClick={e => {
          e.stopPropagation();
          setIsEditing(true);
        }}
      >
        <Typography
          className={cx({
            "text-right w-100": textAlign === "right",
            "text-left w-100": textAlign === "left",
            "text-center w-100": textAlign === "center",
          })}
          fontSize={columnDef.typographyProps?.fontSize || "12"}
          fontWeight={columnDef.typographyProps?.fontWeight || "700"}
          noWrap
          {...columnDef.typographyProps}
        >
          {value || EMPTY_VALUE}
        </Typography>
      </div>
    );
  return (
    <div className={styles.editInput}>
      <TextField.Async
        autoFocus
        mutationHook={columnDef.editable!.mutationHook}
        onBlur={() => setIsEditing(false)}
        size="small"
        transformQueryData={value => columnDef.editable!.transformQueryData(value, row)}
        value={value as string}
      />
    </div>
  );
};
