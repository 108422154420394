import { DayOfTheWeek, daysOfTheWeek, daysOfTheWeekConjugated } from "CONSTANTS";
import { StateLabel } from "components/common/stateLabel";
import { AsyncInput, Select } from "components/utils";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../ManufacturingStagesList.module.css";
import { CustomSwitch } from "components/utils/customSwitch";
import { patchManufacturingStageDefaultSettings } from "api/manufacturingNew/calls";
import { useMutation } from "hooks/useMutation";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useManufacturingStage } from "api/manufacturingNew/hooks";
import { ManufacturingStage } from "api/manufacturing/schemas/models";

interface Props {
  manufacturingStage: ManufacturingStage;
}

export const Days = ({ manufacturingStage }: Props) => {
  const { handleMutate } = useManufacturingStage(manufacturingStage.id, {
    enabled: false,
  });

  const patchMutation = useMutation(patchManufacturingStageDefaultSettings, {
    onMutate: toUpdate => {
      return handleMutate(draft => {
        draft.settings = { ...draft.settings, ...toUpdate.data };
      });
    },
    onError: (error, { id }, rollback) => {
      // @ts-ignore
      rollback(error);
    },
  });

  const conjugatedDaysValueDict = Object.entries(daysOfTheWeekConjugated).map(day =>
    Object.assign({ startAt: day[0], name: day[1] }),
  );

  const handleDayToggle = (name: DayOfTheWeek) => {
    const newDaysOfTheWeek = manufacturingStage.settings.daysOfTheWeek.map(day => {
      if (day.name === name) {
        return {
          ...day,
          isSelected: !day.isSelected,
        };
      }
      return day;
    });

    patchMutation.mutate({
      data: { daysOfTheWeek: newDaysOfTheWeek },
      id: manufacturingStage.settings.id,
    });
  };

  return (
    <>
      <RightPanelSection title="Zbuduj mi plan, który ma:">
        <div className="d-flex align-items-center gap-1 pb-3">
          <div className="d-flex align-items-center gap-1 pr-3">
            <AsyncInput
              value={manufacturingStage.settings.numberOfWorkingDays}
              name="days"
              type="number"
              className="inputSmall"
              disabled={patchMutation.isLoading}
              onChange={value => {
                patchMutation.mutate({
                  data: { numberOfWorkingDays: value },
                  id: manufacturingStage.settings.id,
                });
              }}
            />
            <div className="text-black-6 fs-12">dni</div>
          </div>
          <div className="d-flex align-items-center gap-1 pr-3">
            {manufacturingStage.settings.daysOfTheWeek.map(day => (
              <button
                key={day.name}
                className="mx-0 px-0"
                onClick={() => handleDayToggle(day.name)}
              >
                <StateLabel
                  className="fs-12 fw-700 text-black-default lowercase"
                  kind={day.isSelected ? "deepPurple" : "default"}
                >
                  {daysOfTheWeek[day.name]}
                </StateLabel>
              </button>
            ))}
          </div>
          <Select
            buttonClassName={styles.customStyleButton}
            overwrites={{ dropdownList: { className: styles.customStyleList } }}
            size="small"
            onChange={value => {
              patchMutation.mutate({
                data: { startAt: value.startAt },
                id: manufacturingStage.settings.id,
              });
            }}
            items={conjugatedDaysValueDict}
            label="Zaczyna się"
            selectedItem={
              conjugatedDaysValueDict.find(
                dayNameObject => dayNameObject.startAt === manufacturingStage.settings.startAt,
              ).name ?? null
            }
          />
        </div>
        <div className="pb-3 d-flex gap-2">
          <CustomSwitch
            onChange={value => {
              patchMutation.mutate({
                data: { skipHolidays: !manufacturingStage.settings.skipHolidays },
                id: manufacturingStage.settings.id,
              });
            }}
            checked={manufacturingStage.settings.skipHolidays ?? false}
            name="skipHolidays"
          />
          <Typography fontSize="12" fontWeight="700" className="nowrap">
            Pomijaj święta i dni wolne
          </Typography>
        </div>
      </RightPanelSection>
    </>
  );
};
