import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery } from "hooks";
import { Button } from "components/common";
import downloadPdfIcon from "assets/images/download_pdf.svg";
import hidePanelIcon from "assets/images/keyboardTab.svg";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";
import { useNavigate } from "hooks/useNavigate";
import { useLocation } from "react-router";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button as DesignSystemButton } from "components/miloDesignSystem/atoms/button";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { DownloadTradingDocumentXml } from "pages/tradingDocuments/shared/components/downloadTradingDocumentXml/DownloadTradingDocumentXml";

interface Props {
  close: () => void;
}

export const PanelHeader = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const navigate = useNavigate();
  const location = useLocation();
  const { data: purchaseInvoice } = useTradingDocument({ id: panelId });
  const { isTriggerElementHidden } = useScrollableContext();

  if (!purchaseInvoice) return null;

  return (
    <RightPanelHeader>
      {isTriggerElementHidden ? (
        <SlidingHeaderWrapper className="gap-2">
          <DesignSystemButton
            className="text-uppercase"
            onClick={() => {
              navigate(`/finances/import-invoices/check?id=${purchaseInvoice.id}`, {
                fromMyInvoices: `${location.pathname}${location.search}`,
              });
            }}
            size="small"
            startIcon={MdiCheck}
            variant="gray"
          >
            Przejdź do zatwierdzania
          </DesignSystemButton>
          <div>Faktura VAT&nbsp;{purchaseInvoice.signature}</div>
        </SlidingHeaderWrapper>
      ) : (
        <div>
          <DesignSystemButton
            className="text-uppercase"
            onClick={() => {
              navigate(`/finances/import-invoices/check?id=${purchaseInvoice.id}`, {
                fromMyInvoices: `${location.pathname}${location.search}`,
              });
            }}
            size="small"
            startIcon={MdiCheck}
            variant="gray"
          >
            Przejdź do zatwierdzania
          </DesignSystemButton>
        </div>
      )}
      <div className="d-flex align-items-center justify-content-end gap-1">
        <DownloadTradingDocumentXml tradingDocument={purchaseInvoice} />
        <Button
          kind="transparent-black"
          size="square-s"
          rel="noopener noreferrer"
          href={`${purchaseInvoice.tradingDocumentPdf?.pdf}`}
          target="_blank"
          as="a"
          title="Podgląd PDF z danymi faktury zakupowej"
        >
          <div className="btnBase btnBaseSmall">
            <img alt="Pobierz PDF" src={downloadPdfIcon} />
          </div>
        </Button>
        <MoreInfoButton close={close} invoice={purchaseInvoice} panelId={panelId} />
        <span className="divider line-divider" />
        <Button kind="transparent-black" onClick={close} size="square-s">
          <div className="btnBase btnBaseSmall">
            <img
              alt="Zamknij panel"
              src={hidePanelIcon}
              style={{ height: "16px", width: "16px" }}
            />
          </div>
        </Button>
      </div>
    </RightPanelHeader>
  );
};
