import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { Button } from "components/common";
import cx from "classnames";
import { ManufacturingPlan } from "api/manufacturingNew/models";
import checkIcon from "assets/images/check_small.svg";
import whiteCheckIcon from "assets/images/checkSmallWhite.svg";
import { useManufacturingPlanOnListPatchMutation } from "api/manufacturingNew/hooks";
import { CheckboxGreen } from "components/utils/checkboxGreen";
import { MoreInfoButton } from "components/common/moreInfoButton/MoreInfoButton";
import popUpStyles from "components/common/moreInfoButton/MoreInfoButton.module.css";
import { useMutation } from "hooks/useMutation";
import { deleteManufacturingPlan } from "api/manufacturingNew/calls";
import deleteRed from "assets/images/delete_red.svg";
import { useConfirmModal } from "hooks";
import { useQueryClient } from "react-query";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Button as MdsButton } from "components/miloDesignSystem/atoms/button/Button";
import { MdiPlanDetails } from "components/miloDesignSystem/atoms/icons/MdiPlanDetails";
import { useNavigate } from "hooks/useNavigate";

interface Props {
  close: () => void;
  manufacturingPlan: ManufacturingPlan;
}

export const PanelHeader = ({ close, manufacturingPlan }: Props) => {
  const patchMutation = useManufacturingPlanOnListPatchMutation();
  const queryClient = useQueryClient();
  const confirmModal = useConfirmModal();
  const deleteMutation = useMutation(deleteManufacturingPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries(manufacturingNewKeys.manufacturingPlans());
      close();
    },
  });
  const { isTriggerElementHidden } = useScrollableContext();
  const navigate = useNavigate();

  return (
    <RightPanelHeader>
      <div className="d-flex w-100 align-items-center">
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2 nowrap">
            <CheckboxGreen
              checked={manufacturingPlan.isConfirmed}
              disabled={patchMutation.isLoading}
              name="isRequired"
              onChange={() => {
                patchMutation.mutate({
                  id: manufacturingPlan.id,
                  toUpdate: { isConfirmed: !manufacturingPlan.isConfirmed },
                });
              }}
            />
            <div>{manufacturingPlan.signature}</div>
          </SlidingHeaderWrapper>
        ) : (
          <Button
            disabled={patchMutation.isLoading}
            kind={manufacturingPlan.isConfirmed ? "tealGreen" : "confirm"}
            onClick={() => {
              patchMutation.mutate({
                id: manufacturingPlan.id,
                toUpdate: { isConfirmed: !manufacturingPlan.isConfirmed },
              });
            }}
            size="btnSizeSmall"
          >
            <div className="btnBase btnBaseSmall">
              {!manufacturingPlan.isConfirmed ? (
                <img alt="Gotowe" src={checkIcon} style={{ height: "16px", width: "16px" }} />
              ) : (
                <img alt="Gotowe" src={whiteCheckIcon} style={{ height: "16px", width: "16px" }} />
              )}
              <div className="fs-10">
                {!manufacturingPlan.isConfirmed ? "Zatwierdź" : "Zatwierdzono"}
              </div>
            </div>
          </Button>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <MdsButton
            className="text-uppercase"
            onClick={() => navigate(`/production-plans/details/${manufacturingPlan.id}`)}
            size="small"
            startIcon={MdiPlanDetails}
            variant="gray"
          >
            Szczegóły planu
          </MdsButton>
          <MoreInfoButton
            children={
              <>
                <button
                  disabled={deleteMutation.isLoading}
                  onClick={() => {
                    confirmModal.open({
                      text: `Czy na pewno chcesz usunąć plan ${manufacturingPlan.signature}?`,
                      confirmText: "Usuń",
                      callback: () => deleteMutation.mutate(manufacturingPlan.id),
                    });
                  }}
                  className={cx("px-3 py-2 w-100", popUpStyles.optionRow)}
                >
                  <div className="d-flex align-items-center justify-content-between gap-2">
                    <div className="d-flex align-items-center gap-2">
                      <img src={deleteRed} alt="Usuń" />
                      <div className="body-14-600 text-red-4">Usuń</div>
                    </div>
                  </div>
                </button>
              </>
            }
          />
          <span className="divider line-divider" />
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </div>
    </RightPanelHeader>
  );
};
