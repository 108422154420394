import { IndexesTab, ProductKind } from "api/manufacturing/indexes/models";
import { TagProps } from "components/miloDesignSystem/atoms/tag";

const indexesListTabDict: Record<IndexesTab, string> = {
  upholstery: "Tapicerowane",
  box: "Skrzyniowe",
  frame: "Szkieletowe",
  set: "Zestawy",
  other: "Inne",
};

const productKindDict: Record<ProductKind, string> = {
  box: "Skrzyniowy",
  frame: "Szkieletowy",
  other: "Inny",
  set: "Zestaw",
  upholstery: "Tapicerowany",
};

const productKindVariants: Record<
  ProductKind,
  { label: string; variant: TagProps<string>["variant"] }
> = {
  box: { label: productKindDict.box, variant: "info" },
  frame: { label: productKindDict.frame, variant: "deepPurple50" },
  other: { label: productKindDict.other, variant: "warning" },
  set: { label: productKindDict.set, variant: "danger" },
  upholstery: { label: productKindDict.upholstery, variant: "deepPurple400" },
};

const getIndexesListTabs = (): { label: string; name: IndexesTab }[] =>
  Object.entries(indexesListTabDict).map(([name, label]) => ({
    label,
    name: name as IndexesTab,
  }));

export const indexesConstants = {
  getIndexesListTabs,
  indexesListTabDict,
  productKindDict,
  productKindVariants,
};
