import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import truckIcon from "assets/images/truck.svg";
import { Loadings } from "routes/wms/Loadings";
import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";
import { tabDict } from "pages/wms/loadingsList/utils/getTabs";

export const loadings: ModuleLink = {
  url: "loadings",
  label: "Załadunki",
  icon: truckIcon,
  subSection: {
    renderer: <TabSubSection tabs={tabDict} baseUrl="/wms/loadings/list" />,
    isExpandedByDefault: true,
  },
  routing: Loadings,
};
