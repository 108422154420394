import { InvoiceType, TradingDocumentType } from "api/trading-documents/models";
import { UUID } from "api/types";
import { tradingDocumentConstants } from "constants/tradingDocuments";

const getTradingDocumentLink = (
  id: UUID,
  type: TradingDocumentType,
  invoiceType: InvoiceType,
): string => {
  if (type === "RECEIPT") return `/finances/receipts/list/all?panelId=${id}`;
  return `/finances/${tradingDocumentConstants.tradingDocumentTypeToTabDict[invoiceType]}/list/all?panelId=${id}`;
};

const getTradingDocumentType = (type: TradingDocumentType, invoiceType: InvoiceType): string => {
  if (type === "RECEIPT") return "paragon";
  return tradingDocumentConstants.invoiceTypeDict[invoiceType];
};

export const tradingDocumentUtils = {
  getTradingDocumentLink,
  getTradingDocumentType,
};
