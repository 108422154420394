import { useMutation } from "hooks/useMutation";
import { manufacturingSchemaApi } from "./api";
import { getAnyErrorKey } from "utilities";
import { PostManufacturingSchema, PostManufacturingStage } from "./models";
import { FormikHelpers } from "formik";
import { assertIsDefined } from "utilities/assertIsDefined";
import { manufacturingSchemasKeys } from "./keys";
import { useRedux } from "hooks";
import { ManufacturingSchema } from "api/manufacturingNew/models";
import immer from "immer";
import { QueryClient } from "react-query";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";

const useManufacturerStages = createPaginatedApiQuery(manufacturingSchemaApi.getManufacturerStages);

const usePatchManufacturingStage = () => {
  return useMutation(manufacturingSchemaApi.patchManufacturingStage, ({ queryUtils }) => ({
    onMutate: toUpdate => {
      const prevDetails = queryUtils.handleMutate(
        manufacturingSchemasKeys.manufacturingStage.details(toUpdate.id),
        toUpdate,
      );

      const prevList = queryUtils.handlePaginatedListUpdate(
        manufacturingSchemasKeys.manufacturingStage.list(),
        toUpdate.id,
        toUpdate,
      );

      return { prevDetails, prevList };
    },
    onError: (error, toUpdate, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(
        manufacturingSchemasKeys.manufacturingStage.details(toUpdate.id),
        onMutateReturn.prevDetails,
        error,
      );
      queryUtils.rollbackList(
        manufacturingSchemasKeys.manufacturingStage.list(),
        onMutateReturn.prevList,
        toUpdate.id,
      );
    },
  }));
};

const usePatchManufacturingSchemaStages = (panelKey: string[]) => {
  const handleRollback = (prevPanel: ManufacturingSchema, queryClient: QueryClient) =>
    queryClient.setQueryData<ManufacturingSchema>(panelKey, prevPanel);

  return useMutation(
    manufacturingSchemaApi.patchManufacturingSchemaStages,
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        const prevPanel = queryClient.getQueryData<ManufacturingSchema>(panelKey);

        queryClient.setQueryData<ManufacturingSchema>(panelKey, schema => {
          assertIsDefined(schema);
          return immer(schema, draft => {
            const stageToUpdate = draft.stages.find(_stage => _stage.relation?.id === args.id);
            if (stageToUpdate) {
              if (args.child) {
                stageToUpdate.relation!.child = String(args.child);
              }
              if (args.isFirst) {
                stageToUpdate.isFirst = args.isFirst;
              }
              if (args.isLast) {
                stageToUpdate.isLast = args.isLast;
              }
            }
          });
        });

        return prevPanel;
      },
      onSuccess: () => queryClient.invalidateQueries(panelKey),
      onError: (error, _, prevPanel) => {
        assertIsDefined(prevPanel);
        handleRollback(prevPanel, queryClient);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const usePostManufacturingSchema = (close: () => void) => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  const createManufacturingSchema = useMutation(
    manufacturingSchemaApi.postManufacturingSchema,
    ({ queryClient, toastr }) => ({
      onSuccess: payload => {
        queryClient.invalidateQueries();
        refetchPartials();
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Dodano schemat produkcyjny: ${payload.name}`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: PostManufacturingSchema,
    actions: FormikHelpers<PostManufacturingSchema>,
  ) => {
    createManufacturingSchema.mutate(values, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

const useRemoveManufacturerStage = () => {
  return useMutation(manufacturingSchemaApi.deleteManufacturerStage, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostManufacturerStage = () => {
  return useMutation(manufacturingSchemaApi.postManufacturerStage, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostManufacturingStage = (close: () => void) => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  const createManufacturingStage = useMutation(
    manufacturingSchemaApi.postManufacturingStage,
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        refetchPartials();
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Dodano etap produkcyjny",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: PostManufacturingStage,
    actions: FormikHelpers<PostManufacturingStage>,
  ) => {
    createManufacturingStage.mutate(values, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

export const manufacturingSchemaActions = {
  useManufacturerStages,
  usePatchManufacturingSchemaStages,
  usePatchManufacturingStage,
  usePostManufacturerStage,
  usePostManufacturingSchema,
  usePostManufacturingStage,
  useRemoveManufacturerStage,
};
