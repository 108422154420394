import { AccessorFn, ColumnDef, createColumnHelper } from "@tanstack/table-core";
import { CountryColumnDef } from "./types";
import { EmptyValue } from "../createTableColumns";
import { LoaderCell } from "components/miloDesignSystem/molecules/table/components/tableLoader/TableLoader";
import { mainListBigUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";

export const countryColumn = <T,>(
  accessorFn: AccessorFn<T>,
  columnDef: CountryColumnDef<T>,
): ColumnDef<T> => {
  const baseColumnHelper = createColumnHelper<T>();
  return baseColumnHelper.accessor(accessorFn, {
    id: columnDef.header,
    header: columnDef.header,
    size: columnDef.size,
    cell: info => {
      if (columnDef.isLoading || columnDef.error)
        return <LoaderCell height={mainListBigUiSchema.cell.height} width={columnDef.size} />;
      if (
        !Boolean(columnDef.countryFlagProps.countryCode?.(info.row).length) &&
        !Boolean(columnDef.countryFlagProps.currency?.(info.row).length)
      )
        return <EmptyValue fontSize="12" fontWeight="700" />;
      if (columnDef.countryFlagProps.countryCode)
        return <CountryFlag countryCode={columnDef.countryFlagProps.countryCode(info.row)} />;
      return <CountryFlag currency={columnDef.countryFlagProps.currency(info.row)} />;
    },
  });
};
