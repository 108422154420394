import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../SingleItemQuantitiesForRoute.module.css";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiBottomPanelClose } from "components/miloDesignSystem/atoms/icons/MdiBottomPanelClose";
import { useSingleItemQuantitiesForRoute } from "api/orders/hooks";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useFilters } from "hooks/useFilters";
import { SingleItemQuantitiesForRoute } from "api/orders/models";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { scheduleConfirmationStatusVariant, useProductsColumns } from "./useProductsColumns";
import { SearchField } from "components/utils/searchField/SearchField";
import { Chip } from "components/miloDesignSystem/atoms/chip";
import { ordersUtils } from "utilities/orders";
import { cx } from "utilities";

interface Props {
  route: number;
  toggleSingleItemQuantitiesPanel: () => void;
}

export const ProductsList = ({ route, toggleSingleItemQuantitiesPanel }: Props) => {
  const { filters, searchParams, setFilter } = useFilters({
    page: 1,
    route,
    pageSize: 30,
    search: "",
    scheduleConfirmationStatus: "",
    manufacturingItemStatusIn: "",
  });
  const {
    data: products,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = useSingleItemQuantitiesForRoute(searchParams);
  const columns = useProductsColumns();

  const selectedStatuses = filters["scheduleConfirmationStatus"]?.split(",") || [];
  const selectedManufacturingStatuses = filters["manufacturingItemStatusIn"]?.split(",") || [];

  return (
    <div className={styles.productsListWrapper}>
      <div className={styles.productsListPanelHeader}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <Typography color="neutralWhite100" fontSize="18" fontWeight="700">
            Meble na trasie
          </Typography>
          <IconButton
            icon={<MdiBottomPanelClose color="neutralWhite100" size="16" />}
            onClick={toggleSingleItemQuantitiesPanel}
            theme="dark"
            variant="transparent"
          />
        </div>
      </div>
      <div className={styles.filters}>
        <div className={styles.filtersContent}>
          {/* TODO: Replace with new molecule 'search' */}
          <SearchField
            isNewLayout
            debounce={500}
            value={filters.search || ""}
            onUpdate={value => {
              setFilter("search", value);
            }}
            overrides={{
              input: { className: styles.searchInput },
              wrapper: { className: styles.searchWrapper },
            }}
          />
          <div className={cx("d-flex align-items-center gap-3", styles.filterWrapper)}>
            <div className="d-flex align-items-center gap-1">
              <Typography color="neutralBlack48" fontSize="10" fontWeight="700" noWrap>
                Potwierdzenie daty odbioru:
              </Typography>
              <div className="d-flex gap-1 text-uppercase">
                {Object.entries(scheduleConfirmationStatusVariant).map(([status, object]) => (
                  <Chip
                    key={status}
                    label={object.label}
                    onClick={val => {
                      if (val) {
                        setFilter(
                          "scheduleConfirmationStatus",
                          [
                            ...selectedStatuses.filter(status => Boolean(status.length)),
                            status,
                          ].toString(),
                        );
                        return;
                      }

                      setFilter(
                        "scheduleConfirmationStatus",
                        selectedStatuses
                          .filter(
                            selectedStatus => selectedStatus !== status && Boolean(status.length),
                          )
                          .toString(),
                      );
                    }}
                    variant="primary"
                    theme="light"
                    selected={Boolean(
                      selectedStatuses.find(selectedStatus => selectedStatus === status),
                    )}
                  />
                ))}
              </div>
            </div>
            <div className="d-flex align-items-center gap-1">
              <Typography color="neutralBlack48" fontSize="10" fontWeight="700" noWrap>
                Status produkcji:
              </Typography>
              <div className="d-flex gap-1 text-uppercase">
                {Object.entries(ordersUtils.singleItemQuantitiesManufacturingStatusOptions).map(
                  ([status, object]) => (
                    <Chip
                      key={status}
                      label={object.label}
                      onClick={val => {
                        if (val) {
                          setFilter(
                            "manufacturingItemStatusIn",
                            [
                              ...selectedManufacturingStatuses.filter(status =>
                                Boolean(status.length),
                              ),
                              status,
                            ].toString(),
                          );
                          return;
                        }

                        setFilter(
                          "manufacturingItemStatusIn",
                          selectedManufacturingStatuses
                            .filter(
                              manufacturingStatus =>
                                manufacturingStatus !== status && Boolean(status.length),
                            )
                            .toString(),
                        );
                      }}
                      variant="primary"
                      theme="light"
                      selected={Boolean(
                        selectedManufacturingStatuses.find(
                          manufacturingStatus => manufacturingStatus === status,
                        ),
                      )}
                    />
                  ),
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.productsListBody}>
        <div className="position-relative">
          <Table<SingleItemQuantitiesForRoute>
            rows={products}
            columns={columns}
            onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={comfortableListUiSchema}
            pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
          />
        </div>
      </div>
    </div>
  );
};
