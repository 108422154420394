import { useFilterContext } from "components/utils/withFilters";
import styles from "../ModuleList.module.css";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const ModuleListBody = ({ children, className, style }: Props) => {
  const filterContext = useFilterContext();
  return (
    <div
      className={cx(className, styles.moduleListBody, {
        [styles.filterBarOpened]: filterContext?.appliedFilters.length > 0,
      })}
      style={style}
    >
      {children}
    </div>
  );
};
