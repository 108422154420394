import { manufacturingActions } from "api/manufacturing/actions";
import { InfoLabel } from "components/common/infoLabel";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { RightPanelSection } from "components/utils/drawer";
import { TimePicker } from "components/utils/timePicker";
import { format, parseISO, set } from "date-fns";
import { useQuery } from "hooks";
import { dateUtils } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";

export const GeneralInfoSection = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: order } = manufacturingActions.useManufacturingOrder(panelId);
  const scheduledEmailDateMutation = manufacturingActions.usePatchManufacturingOrder();

  if (!order) return null;

  return (
    <RightPanelSection padding="px-3 pt-0">
      <InfoLabel title="dostawca">
        <Typography fontSize="16" fontWeight="700">
          {order.manufacturer.name}
        </Typography>
      </InfoLabel>
      <InfoLabel title="planowana wysyłka">
        <DatePicker
          disabled={scheduledEmailDateMutation.isLoading}
          isNullable={false}
          onChange={scheduledDate => {
            assertIsDefined(scheduledDate);
            if (order.scheduledEmailSendAt) {
              const newDate = set(parseISO(order.scheduledEmailSendAt), {
                date: scheduledDate.getDate(),
                month: scheduledDate.getMonth(),
                year: scheduledDate.getFullYear(),
              });
              scheduledEmailDateMutation.mutate({
                id: order.id,
                scheduledEmailSendAt: format(newDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
              });
            }
            scheduledEmailDateMutation.mutate({
              id: order.id,
              scheduledEmailSendAt: dateUtils.formatDateToIso(scheduledDate),
            });
          }}
          value={order.scheduledEmailSendAt}
        />
        <div className="pt-1">
          <TimePicker
            disabled={!order.scheduledEmailSendAt}
            onBlur={time => {
              if (time && order.scheduledEmailSendAt) {
                const timeComponents = time.split(":");
                const newDate = set(parseISO(order.scheduledEmailSendAt), {
                  hours: Number(timeComponents[0]),
                  minutes: Number(timeComponents[1]),
                });
                scheduledEmailDateMutation.mutate({
                  id: order.id,
                  scheduledEmailSendAt: format(newDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
                });
              } else {
                scheduledEmailDateMutation.mutate({
                  id: order.id,
                  scheduledEmailSendAt: null,
                });
              }
            }}
            value={
              order.scheduledEmailSendAt
                ? format(new Date(order.scheduledEmailSendAt), "HH:mm")
                : ""
            }
          />
        </div>
      </InfoLabel>
      <InfoLabel title="odbiorcy">
        <div className="d-flex align-items-center gap-2 cut-text">
          <Typography color="neutralBlack88" fontSize="16" fontWeight="700" noWrap>
            {order.deliveries[0].name}
          </Typography>
          {order.deliveries.length > 1 && (
            <Typography color="deepPurple400" fontSize="16" fontWeight="700">
              +{order.deliveries.length - 1}
            </Typography>
          )}
        </div>
      </InfoLabel>
      <InfoLabel title="adresy odbiorców">
        <div>
          {order.deliveries.map(delivery => (
            <Typography
              color="neutralBlack88"
              fontSize="14"
              fontWeight="400"
              key={`${delivery.name}-${delivery.street}`}
              noWrap
            >
              {delivery.street}
              {delivery.street && ","} {delivery.postCode} {delivery.city}
            </Typography>
          ))}
        </div>
      </InfoLabel>
      <InfoLabel title="adres email">
        <TextField.Async
          label="Adres email"
          mutationHook={manufacturingActions.usePatchManufacturingOrder}
          size="small"
          transformQueryData={email => ({
            id: order.id,
            email,
          })}
          value={order.email}
        />
      </InfoLabel>
    </RightPanelSection>
  );
};
