import styled from "@emotion/styled";
import { manufacturersActions } from "api/manufacturers/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { Tabs } from "components/miloDesignSystem/molecules/tabs";
import { CommonError } from "components/utils";
import { SectionWrapper } from "pages/crm/customers/customerDetails/components/sectionWrapper/SectionWrapper";
import { useParams } from "react-router";
import { getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { GeneralInfoTab } from "./tabs/generalInfoTab/GeneralInfoTab";
import { GroupedOrdersTab } from "./tabs/groupedOrdersTab/GroupedOrdersTab";

export const ServiceProviderTabSection = () => {
  const { serviceProviderId } = useParams<{ serviceProviderId: string }>();
  const { data: serviceProvider, error, isLoading } = manufacturersActions.useContractorDetails(
    serviceProviderId,
    {
      enabled: Boolean(serviceProviderId),
    },
  );

  if (isLoading)
    return (
      <SectionWrapper
        className="mb-3 d-flex flex-1 justify-content-center align-items-center"
        width="100%"
        minHeight={516}
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error)
    return (
      <SectionWrapper
        className="mb-3 d-flex flex-1 justify-content-center align-items-center"
        width="100%"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  assertIsDefined(serviceProvider);

  return (
    <SectionWrapper className="mb-3 pt-3 d-flex flex-column flex-1 overflow-hidden">
      <Tabs mode="state">
        <div className="d-flex gap-2 px-3">
          <Tabs.TabItem label="Zamówienia zgrupowane" value={2} />
          <Tabs.TabItem label="Zamówienia pojedyncze" value={3} />
          <Tabs.TabItem label="Rozliczenia" value={4} />
          <Tabs.TabItem label="Dane ogólne" value={1} />
        </div>
        <StyledLine />
        <Tabs.TabContentRenderer value={1}>
          <GeneralInfoTab />
        </Tabs.TabContentRenderer>
        <Tabs.TabContentRenderer value={2}>
          <GroupedOrdersTab />
        </Tabs.TabContentRenderer>
      </Tabs>
    </SectionWrapper>
  );
};

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--neutralBlack16);
  margin-top: -1px;
`;
