import { manufacturersActions } from "api/manufacturers/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { CommonError } from "components/utils";
import { Title } from "pages/crm/customers/customerDetails/components/Title";
import { SectionWrapper } from "pages/crm/customers/customerDetails/components/sectionWrapper/SectionWrapper";
import { useParams } from "react-router";
import { cx, getAnyErrorKey, yup } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "./GeneralInfoTab.module.css";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { countryCodes, smsCountryDict } from "CONSTANTS";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";

export const GeneralInfoTab = () => {
  const { serviceProviderId } = useParams<{ serviceProviderId: string }>();
  const { data: serviceProvider, error, isLoading } = manufacturersActions.useContractorDetails(
    serviceProviderId,
    {
      enabled: Boolean(serviceProviderId),
    },
  );

  if (isLoading)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  assertIsDefined(serviceProvider);

  return (
    <div className="p-3 pb-3 d-flex flex-column">
      <Title className="mb-2">Nazwa firmy i Przedstawiciela</Title>
      <TextField.Async
        mutationHook={manufacturersActions.usePatchContractor}
        label="Pełna nazwa"
        transformQueryData={fullName => ({
          id: serviceProvider.id,
          fullName,
        })}
        containerClassName={styles.input}
        value={serviceProvider.fullName}
        size="small"
        helperText="Będzie używana do dokumentów finansowych i w oficjalnej korespondencji"
      />
      <TextField.Async
        validation={yup.string().required("Skrócona nazwa jest wymagana")}
        mutationHook={manufacturersActions.usePatchContractor}
        label="Nazwa skrócona"
        transformQueryData={name => ({
          id: serviceProvider.id,
          name,
        })}
        containerClassName={styles.input}
        value={serviceProvider.name}
        size="small"
        helperText="Będzie wyświetlana w aplikacji"
      />
      <TextField.Async
        mutationHook={manufacturersActions.usePatchContractor}
        label="Imię"
        transformQueryData={ownerFirstName => ({
          id: serviceProvider.id,
          ownerFirstName,
        })}
        containerClassName={styles.input}
        value={serviceProvider.ownerFirstName}
        size="small"
      />
      <TextField.Async
        mutationHook={manufacturersActions.usePatchContractor}
        label="Nazwisko"
        transformQueryData={ownerLastName => ({
          id: serviceProvider.id,
          ownerLastName,
        })}
        containerClassName={styles.input}
        value={serviceProvider.ownerLastName}
        size="small"
      />
      <Title className="mt-4 mb-2">Dane teleadresowe</Title>
      <TextField.Async
        mutationHook={manufacturersActions.usePatchContractor}
        label="Adres"
        transformQueryData={street => ({
          id: serviceProvider.id,
          street,
        })}
        containerClassName={styles.input}
        value={serviceProvider.street}
        size="small"
        helperText="Np. Nazwa miejscowości, ulica, nr ulicy, nr budynku, nr lokalu"
      />
      <div className="d-flex align-items-center gap-2 mb-2">
        <TextField.Async
          mutationHook={manufacturersActions.usePatchContractor}
          label="Kod pocztowy"
          transformQueryData={postCode => ({
            id: serviceProvider.id,
            postCode,
          })}
          containerClassName={styles.postCode}
          value={serviceProvider.postCode}
          size="small"
        />
        <TextField.Async
          mutationHook={manufacturersActions.usePatchContractor}
          label="Miasto"
          transformQueryData={city => ({
            id: serviceProvider.id,
            city,
          })}
          containerClassName={styles.city}
          value={serviceProvider.city}
          size="small"
        />
      </div>
      <Select.Async
        mutationHook={manufacturersActions.usePatchContractor}
        transformQueryData={countryCode => ({
          id: serviceProvider.id,
          countryCode: countryCode as string,
        })}
        className={cx("mb-2", styles.countryCode)}
        label="Kod kraju"
        items={countryCodes.map(countryCode => ({
          text: `${countryCode.value} ${
            smsCountryDict[countryCode.value] !== undefined
              ? `(${smsCountryDict[countryCode.value]})`
              : ``
          } `,
          value: countryCode.value,
          type: MenuItemType.ICON,
          icon: countryCode.icon,
        }))}
        selected={serviceProvider.countryCode}
      />
      <TextField.Async
        mutationHook={manufacturersActions.usePatchContractor}
        label="Telefon"
        transformQueryData={phone => ({
          id: serviceProvider.id,
          phone,
        })}
        containerClassName={styles.input}
        value={serviceProvider.phone}
        size="small"
      />
      <TextField.Async
        validation={yup.string().email("Podaj poprawny adres email")}
        mutationHook={manufacturersActions.usePatchContractor}
        label="Email"
        transformQueryData={email => ({
          id: serviceProvider.id,
          email,
        })}
        containerClassName={styles.input}
        value={serviceProvider.email}
        size="small"
      />
      <Title className="mt-4 mb-2">numery identyfikacyjne firmy</Title>
      <TextField.Async
        validation={yup.string().required("NIP jest wymagany")}
        mutationHook={manufacturersActions.usePatchContractor}
        label="NIP"
        transformQueryData={taxId => ({
          id: serviceProvider.id,
          taxId,
        })}
        containerClassName={styles.input}
        value={serviceProvider.taxId}
        size="small"
        helperText="Musi składać się z 10 cyfr"
      />
      <TextField.Async
        validation={yup.string().required("REGON jest wymagany")}
        mutationHook={manufacturersActions.usePatchContractor}
        label="REGON"
        transformQueryData={businessRegisterNumber => ({
          id: serviceProvider.id,
          businessRegisterNumber,
        })}
        containerClassName={styles.input}
        value={serviceProvider.businessRegisterNumber}
        size="small"
        helperText="Musi składać się z 9 lub 14 cyfr"
      />
    </div>
  );
};
