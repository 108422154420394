import { FLAVOR } from "CONSTANTS";
import { ExternalManufacturerItem } from "api/external-manufacturing/models";
import { orderActions } from "api/orders/actions";
import { AddOrdersToUnloadingResponse } from "api/orders/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button } from "components/miloDesignSystem/atoms/button";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useStateModal } from "hooks";
import { ResponseModal } from "pages/wms/unloadingsList/rightPanel/components/addOrdersBySignaturesModal/ResponseModal";
import { MdiDownloadCsv } from "../../../../components/miloDesignSystem/atoms/icons/MdiDownloadCsv";
import { externalManufacturingFileFactory } from "api/external-manufacturing/api";
import { UUID } from "api/types";

interface Props {
  close: () => void;
  id: UUID;
  signature: string;
  orders: ExternalManufacturerItem["orders"];
}

export const PanelHeader = ({ close, id, signature, orders }: Props) => {
  const { isTriggerElementHidden } = useScrollableContext();
  const createUnloadingMutation = orderActions.useCreateUnloadingFromOrders();
  const responseModal = useStateModal<AddOrdersToUnloadingResponse>();
  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden && (
          <SlidingHeaderWrapper>
            <Typography fontSize="16" fontWeight="600">
              {signature}
            </Typography>
          </SlidingHeaderWrapper>
        )}
        <div className="d-flex align-items-center justify-content-end flex-1">
          {FLAVOR === "main" && (
            <Button
              size="small"
              variant="gray"
              isLoading={createUnloadingMutation.isLoading}
              startIcon={MdiAdd}
              onClick={() => {
                createUnloadingMutation.mutate(
                  { orderIds: [...new Set(orders.map(order => order.id))] },
                  {
                    onSuccess: payload => {
                      responseModal.open(payload);
                    },
                  },
                );
              }}
            >
              Utwórz rozładunek
            </Button>
          )}
          {FLAVOR === "main" && (
            <div className="d-flex align-items-center">
              <span className="divider line-divider" />
              <FileDownloadHandler
                factoryFn={() =>
                  externalManufacturingFileFactory.externalManufacturingOrderCsv([id], signature)
                }
                type="csv"
              >
                {({ download, isLoading }) => (
                  <Tooltip title="Pobierz CSV zlecenia">
                    <IconButton
                      icon={MdiDownloadCsv}
                      isLoading={isLoading}
                      onClick={download}
                      variant="transparent"
                    />
                  </Tooltip>
                )}
              </FileDownloadHandler>
            </div>
          )}
          <span className="divider line-divider" />
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </RightPanelHeader>
      {responseModal.isOpen && responseModal.state && (
        <ResponseModal close={responseModal.close} state={responseModal.state} />
      )}
    </>
  );
};
