import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import rampIcon from "assets/images/releases.svg";
import { Ramps } from "routes/wms/Ramps";

export const ramps: ModuleLink = {
  url: "ramps",
  label: "Rampy",
  icon: rampIcon,
  routing: Ramps,
};
