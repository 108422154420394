import { getRoutes } from "api/routes/calls";
import { wmsReturnsActions } from "api/wms/returns/actions";
import { CreateReturn } from "api/wms/returns/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Search } from "components/miloDesignSystem/molecules/search";
import { Formik } from "formik";
import { cx } from "utilities";
import { validationSchema } from "./validationSchema";
import { ErrorMessage } from "components/utils";

interface Props {
  close: () => void;
}

const initialValues: CreateReturn = {
  routeId: "",
};

export const AddReturnModal = ({ close }: Props) => {
  const handleSubmit = wmsReturnsActions.usePostReturn(close);

  return (
    <Modal
      close={close}
      isOpen
      width={480}
      title={
        <Typography fontSize="20" fontWeight="700">
          Utwórz zwrot
        </Typography>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="mx-3 mb-3 mt-2 d-flex flex-column align-items-start">
              <div className="w-50 mb-3">
                <Search
                  fetcherFn={getRoutes}
                  isNullable={false}
                  onChange={field => setFieldValue("routeId", String(field.id))}
                  transformQuery={{
                    isSettled: "true",
                  }}
                />
                <ErrorMessage name="routeId" />
              </div>
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Utwórz{isSubmitting && "..."}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
