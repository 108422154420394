import { manufacturingActions } from "api/manufacturing/actions";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { PanelHeader } from "./panelHeader/PanelHeader";
import { TitleSection } from "./titleSection/TitleSection";
import { GeneralInfoSection } from "./generalInfoSection/GeneralInfoSection";
import { ReceiveSection } from "./receiveSection/ReceiveSection";
import { ManufacturerSingleOrdersSection } from "./manufacturerSingleOrdersSection/ManufacturerSingleOrdersSection";
import { EmailSection } from "./emailSection/EmailSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: order, error, isLoading } = manufacturingActions.useManufacturingOrder(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!order) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={order.id} className="position-relative">
        <PanelHeader />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <TitleSection />
            <GeneralInfoSection />
            <ReceiveSection />
            <ManufacturerSingleOrdersSection />
            <EmailSection />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
