import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import cuid from "cuid";
import { DashedLabel } from "../../../subcomponents/DashedLabel";
import { ColumnType } from "../../ColumnView";
import { useEffect, useState } from "react";
import { ManufacturingUnit } from "api/manufacturing/units/models";
import { ColumnWrapper } from "../ColumnWrapper";
import { useQuery, useSelector } from "hooks";
import { ColumnSearch } from "../../../subcomponents/ColumnSearch";
import { ErrorType } from "hooks/createPaginatedQuery";
import { Droppable } from "react-beautiful-dnd";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { Button } from "components/miloDesignSystem/atoms/button";
import { dateUtils, queryString } from "utilities";
import { parseISO, subDays } from "date-fns";
import { ManufacturingTicket } from "../../shared/manufacturingTicket/ManufacturingTicket";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { manufacturingActions } from "api/manufacturing/actions";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { UserWithInitials } from "api/users/models";
import { DrawerRenderer } from "../../panel/DrawerRenderer";
import { useStageId } from "pages/manufacturingNew/manufacturingStages/hooks/useStageId";

interface ReadySectionProps {
  columnType: ColumnType | null;
  isDraggingMutationInProgress: boolean;
  setColumnType: React.Dispatch<React.SetStateAction<ColumnType | null>>;
}

interface ReadyUnitProps {
  columnType: ColumnType | null;
  error: ErrorType | null;
  isFetching: boolean;
  isLoading: boolean;
  isDraggingMutationInProgress: boolean;
  setColumnType: React.Dispatch<React.SetStateAction<ColumnType | null>>;
  setUnitDetails: React.Dispatch<React.SetStateAction<ManufacturingUnit | null>>;
  units: ManufacturingUnit[];
}

export const ReadySection = ({
  columnType,
  isDraggingMutationInProgress,
  setColumnType,
}: ReadySectionProps) => {
  const { query, updateQuery } = useQuery();
  const { unitPanelId } = query;
  const stageId = useStageId();
  const { data: defaultFilters } = manufacturingActions.useStageBoardDefaultAttributesKind(
    queryString.stringify({
      schemaStage: stageId,
    }),
  );
  const attributesKinds = manufacturingStagesUtils.getAttributesKinds(defaultFilters!);
  const readyUnitsSearch = manufacturingStagesUtils.getReadyColumnSearch(
    query,
    stageId,
    attributesKinds,
  );
  const [unitDetails, setUnitDetails] = useState<ManufacturingUnit | null>(null);
  const {
    data: units,
    error,
    isFetching,
    isPreviousData,
    isLoading,
  } = manufacturingUnitsActions.useGetReadyManufacturingUnits(readyUnitsSearch, {
    enabled: Boolean(query.finishedAtFrom && query.finishedAtTo),
    keepPreviousData: true,
  });
  const { refetch: refetchCountStats } = manufacturingUnitsActions.useManufacturingItemCount(
    {
      stageId,
      search: queryString.stringify({
        finishedAtFrom: query.finishedAtFrom,
        finishedAtTo: query.finishedAtTo,
      }),
    },
    {
      enabled: Boolean(query.finishedAtFrom && query.finishedTo),
    },
  );

  useEffect(() => {
    refetchCountStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.finishedAtFrom, query.finishedAtTo]);

  if (isLoading && !isPreviousData)
    return (
      <div className="position-relative d-flex flex-1 col-4 px-0">
        <ColumnWrapper>
          <div className="d-flex align-items-center justify-content-center h-100">
            <Spinner size={48} />
          </div>
        </ColumnWrapper>
      </div>
    );

  return (
    <div className="d-flex flex-column flex-1 col-4 px-0">
      <Droppable droppableId={manufacturingStagesConstants.READY_DROPPABLE}>
        {(provided, snapshot) => (
          <div
            className="d-flex flex-1 flex-column overflow-hidden"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <ColumnWrapper isDragOver={snapshot.isDraggingOver}>
              <ReadyUnits
                columnType={columnType}
                error={error}
                isFetching={isFetching}
                isLoading={isLoading}
                isDraggingMutationInProgress={isDraggingMutationInProgress}
                setColumnType={setColumnType}
                setUnitDetails={setUnitDetails}
                units={units}
              />
              {provided.placeholder}
            </ColumnWrapper>
          </div>
        )}
      </Droppable>
      {columnType === ColumnType.READY && (
        <div className="d-flex flex-column mt-2">
          <DrawerRenderer columnType={ColumnType.READY} unit={unitDetails} />
        </div>
      )}
      {columnType === ColumnType.READY && unitPanelId ? null : (
        <div className="d-flex w-100 justify-content-center align-items-center flex-column mt-2">
          <Button
            className="text-uppercase"
            onClick={() => {
              updateQuery({
                finishedAtFrom: query.finishedAtFrom
                  ? dateUtils.formatDateToIso(subDays(parseISO(query.finishedAtFrom), 1))
                  : dateUtils.formatDateToIso(new Date()),
              });
              if (!query.finishedAtTo) {
                updateQuery({
                  finishedAtTo: dateUtils.formatDateToIso(new Date()),
                });
              }
            }}
            size="small"
            variant="gray"
          >
            Wczytaj z poprzedniego dnia
          </Button>
        </div>
      )}
    </div>
  );
};

const ReadyUnits = ({
  columnType,
  error,
  isFetching,
  isLoading,
  isDraggingMutationInProgress,
  setColumnType,
  setUnitDetails,
  units,
}: ReadyUnitProps) => {
  const { query, updateQuery } = useQuery();
  const stageId = useStageId();
  const { data: manufacturingItemCount } = manufacturingUnitsActions.useManufacturingItemCount({
    stageId,
    search: queryString.stringify({
      finishedAtFrom: query.finishedAtFrom,
      finishedAtTo: query.finishedAtTo,
    }),
  });
  const employees = useSelector(store => store.partials.employees);
  const employeesOptions = employees.map(employee => ({
    icon: employee.avatar,
    text: `${employee.firstName} ${employee.lastName}`,
    type: MenuItemType.ICON,
    value: employee.id,
  }));

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between py-2 gap-3">
        <div className="d-flex align-items-center gap-2">
          <Typography color="neutralBlack88" fontSize="14" fontWeight="600">
            Gotowe
          </Typography>
          <Typography color="deepPurple400" fontSize="14" fontWeight="700">
            {manufacturingItemCount?.counts.finished || "brak"}
          </Typography>
          {(isFetching || isDraggingMutationInProgress) && <Spinner size={16} />}
        </div>
        <ColumnSearch
          isLoading={isFetching}
          queryKey="readyUnitsSearch"
          onChange={search => updateQuery({ ...query, readyUnitsSearch: search ?? "" })}
          value={query["readyUnitsSearch"]}
        />
      </div>
      {error && (
        <div className="d-flex align-items-center justify-content-center h-100">
          <CommonError status={error._httpStatus_} />
        </div>
      )}
      {!isLoading && !error && (
        <div className="d-flex flex-column gap-3">
          {Object.entries(manufacturingStagesUtils.getSortedManufacturingUnits(units))
            .filter(([, unitsSegment]) => Boolean(unitsSegment.length))
            .map(([label, unitsSegment], index) => (
              <div className="d-flex flex-column gap-1" key={`${label}+${cuid()}`}>
                {!label && index === 0 ? null : <DashedLabel label={label} />}
                {unitsSegment
                  .sort((a, b) => {
                    const priorityOrder = { A: 1, B: 2, C: 3, "": 4 };
                    return priorityOrder[a.priority] - priorityOrder[b.priority];
                  })
                  .map(unit => (
                    <ManufacturingTicket
                      assignEmployee={(ticket, hide) => (
                        <Select.Async
                          items={employeesOptions}
                          mutationHook={() =>
                            manufacturingUnitsActions.useReadyManufacturingUnitPatch(hide)
                          }
                          selected={ticket.employee?.id ?? null}
                          transformQueryData={employee => {
                            const searchedEmployee = employees.find(
                              _employee => _employee.id === employee,
                            );
                            assertIsDefined(searchedEmployee);
                            return {
                              id: ticket.id,
                              toUpdate: {
                                employee: searchedEmployee as UserWithInitials,
                              },
                            };
                          }}
                        />
                      )}
                      attributes={ticket => (
                        <>
                          <div className="d-flex align-items-center gap-1">
                            {manufacturingStagesConstants.attributeCategoryIconsDict["PRODUCT"]}
                            <Typography fontSize="16" fontWeight="700">
                              {ticket.modelName}
                            </Typography>
                            {ticket.attributes.map(attribute => (
                              <Typography
                                key={`ready-section-unit-attribute-${attribute.attribute.id}`}
                                fontSize="14"
                                fontWeight="600"
                              >
                                {attribute.value.name}
                              </Typography>
                            ))}
                          </div>
                        </>
                      )}
                      downloadLabelFn={ticket =>
                        manufacturingFileFactory.manufacturingItemPdf(
                          [ticket.manufacturingItems[0]],
                          ticket.signature!,
                        )
                      }
                      isColumnActive={columnType === ColumnType.READY}
                      isDragged={false}
                      isLatest={ticket =>
                        manufacturingStagesUtils.isLatestTicket(
                          unitsSegment.map(unit => parseISO(unit.finishedAt)),
                          ticket.recentlyMovedDate!,
                        )
                      }
                      key={unit.id}
                      onClick={ticket => {
                        updateQuery({
                          unitPanelId:
                            query.unitPanelId && query.unitPanelId === ticket.id ? "" : ticket.id,
                        });
                        setUnitDetails(unit);
                        setColumnType(ColumnType.READY);
                      }}
                      ticket={{
                        attributes: unit.attributeValues,
                        employee: unit.employee,
                        id: unit.id,
                        isDeclined: unit.isDeclined,
                        isCancelled: unit.isCancelled,
                        implementedBy: unit.implementedBy,
                        manufacturer: unit.manufacturer?.name,
                        manufacturingItems: [unit.manufacturingItem],
                        modelName: unit.name,
                        note: unit.note,
                        priority: unit.priority,
                        recentlyMovedDate: unit.finishedAt,
                        signature: unit.signature,
                      }}
                    />
                  ))}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
