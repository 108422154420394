import { indexesActions } from "api/manufacturing/indexes/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { CommonError } from "components/utils";
import { SectionWrapper } from "pages/crm/customers/customerDetails/components/sectionWrapper/SectionWrapper";
import { useParams } from "react-router";
import { cx, getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "../../../IndexDetails.module.css";
import { Title } from "pages/crm/customers/customerDetails/components/Title";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { IndexManufacturingStatistics } from "./IndexManufacturingStatistics";

export const GeneralInformationTab = () => {
  const { indexId } = useParams<{ indexId: string }>();
  const { data: indexDetails, error, isLoading } = indexesActions.useIndex(indexId);

  if (isLoading)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  assertIsDefined(indexDetails);

  return (
    <div className={cx(styles.tabScroll, "d-flex flex-column pb-3 flex-1")}>
      <IndexManufacturingStatistics indexDetails={indexDetails} />
      <div className="d-flex flex-column px-3 pt-3 pb-2">
        <TextField.Async
          containerClassName="mb-2"
          label="Nazwa"
          mutationHook={indexesActions.usePatchIndex}
          transformQueryData={name => ({
            id: indexDetails.id,
            toUpdate: {
              name,
            },
          })}
          size="small"
          value={indexDetails.name}
        />
        <TextField.Async
          containerClassName={styles.input}
          label="SKU"
          mutationHook={indexesActions.usePatchIndex}
          transformQueryData={sku => ({
            id: indexDetails.id,
            toUpdate: {
              sku,
            },
          })}
          size="small"
          value={indexDetails.sku}
        />
        <TextField.Async
          containerClassName={styles.input}
          label="EAN"
          mutationHook={indexesActions.usePatchIndex}
          transformQueryData={ean => ({
            id: indexDetails.id,
            toUpdate: {
              ean,
            },
          })}
          size="small"
          value={indexDetails.ean}
        />
      </div>
      <div className="d-flex flex-column px-3 pt-3 pb-2">
        <Title className="mb-2">Wymiary po złożeniu</Title>
        <TextField.Async
          containerClassName={styles.smallInput}
          label="Waga (kg)"
          mutationHook={indexesActions.usePatchIndex}
          transformQueryData={weight => ({
            id: indexDetails.id,
            toUpdate: {
              weight,
            },
          })}
          size="small"
          type="number"
          value={indexDetails.weight}
        />
        <TextField.Async
          containerClassName={styles.smallInput}
          label="Wysokość (cm)"
          mutationHook={indexesActions.usePatchIndex}
          transformQueryData={height => ({
            id: indexDetails.id,
            toUpdate: {
              height,
            },
          })}
          size="small"
          type="number"
          value={indexDetails.height}
        />
        <TextField.Async
          containerClassName={styles.smallInput}
          label="Szerokość (cm)"
          mutationHook={indexesActions.usePatchIndex}
          transformQueryData={width => ({
            id: indexDetails.id,
            toUpdate: {
              width,
            },
          })}
          size="small"
          type="number"
          value={indexDetails.width}
        />
        <TextField.Async
          containerClassName={styles.smallInput}
          label="Głębokość (cm)"
          mutationHook={indexesActions.usePatchIndex}
          transformQueryData={depth => ({
            id: indexDetails.id,
            toUpdate: {
              depth,
            },
          })}
          size="small"
          type="number"
          value={indexDetails.depth}
        />
      </div>
      <div className="d-flex flex-column px-3 pt-3 pb-2">
        <Title className="mb-1">Charakterystyka</Title>
        {indexDetails.attributesValues.map((attribute, index) => (
          <div className="d-flex flex-column pb-3" key={index}>
            <Typography color="neutralBlack48" fontSize="10" fontWeight="700" noWrap>
              {attribute.attribute.name}
            </Typography>
            <div style={{ width: "fit-content" }}>
              <Tag
                label={attribute.value.name}
                startIcon={attribute.value.picture || undefined}
                type="outlined"
                variant="quaternary"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
