import { FreeSingleItemQuantitiesDetails } from "api/orders/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useDrawer } from "hooks/useDrawer";

interface Props {
  data: FreeSingleItemQuantitiesDetails;
}

export const PanelHeader = ({ data }: Props) => {
  const { close } = useDrawer("freeSingleItemsQuantitiesDetails");
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <RightPanelHeader>
      {isTriggerElementHidden && (
        <SlidingHeaderWrapper className="noWrap">{data.name}</SlidingHeaderWrapper>
      )}
      <div className="d-flex align-items-center justify-content-end flex-1">
        <Tooltip title="Zamknij panel">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};
