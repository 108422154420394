import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import analytics from "assets/images/moduleNavigationIcons/analytics.svg";
import { Overview } from "routes/wms/Overview";

export const overview: ModuleLink = {
  url: "overview",
  label: "Zestawienie zbiorcze",
  icon: analytics,
  routing: Overview,
};
