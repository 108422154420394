import { Route } from "components/utils";
import { StartingPoints } from "pages/logistics/pickupPoints/PickupPoints";
import { NotFound } from "pages/notFound";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const PickupPoints = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={StartingPoints}
        exact
        path={`${match.path}/list/all`}
        title="Milo - Logistyka - Punkty startowe"
      />
      <Route component={NotFound} exact path="*" title="Milo" />
    </Switch>
  );
};
