import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import palletIcon from "assets/images/favicons/fv_pallet.svg";
import favicon from "assets/images/favicons/fv_producer.svg";
import externalManufacturingIcon from "assets/images/pallet.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { manufacturerLineItems } from "./routes/manufacturerLineItems";

export const externalManufacturerPanel: ModuleNavigation = {
  favicon,
  color: colorPalette.pink200,
  navigationConfig: {
    label: "Panel producenta",
    icon: { src: palletIcon, background: colorPalette.pink600 },
  },
  menuDropdownIcon: externalManufacturingIcon,
  name: "EXTERNAL_MANUFACTURER_PANEL",
  url: "/external-manufacturer-panel",
  navigationSections: [manufacturerLineItems],
};
