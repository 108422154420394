import { Marker as MapMarker } from "@react-google-maps/api";
import { useAssignItem } from "../hooks/useAssignItem";
import { useUnassignOrder } from "../hooks/useUnassignOrder";
import { useInfoWindow } from "../hooks/useInfoWindow";
import { useRouteViewState } from "../routeCreatorState";
import { Route } from "api/routes/models";
import { RoutePoint } from "./Map";
import { useToastr } from "hooks";
import hatIcon from "assets/images/mapMarkers/hat.png";

interface Props {
  routePoint: RoutePoint;
  route: Route;
  visible?: boolean;
  isLoading: boolean;
}

export const Marker = ({ routePoint, visible = true, route, isLoading }: Props) => {
  const { toggleInfoWindow } = useInfoWindow();
  const { unassignOrder } = useUnassignOrder(route);
  const { assignOrder } = useAssignItem(route);
  const actions = useRouteViewState("slave", state => state.actions);
  const toastr = useToastr();

  const handleRightClick = () => {
    if (isLoading) return null;
    if (routePoint.type === "passpoint") {
      actions.openLoader("Trwa odpinanie punktu przelotowego");
      unassignOrder(routePoint.id, "passpoint");
    }

    if (!routePoint.isHidden) {
      if (routePoint.isPinned) {
        actions.openLoader("Trwa odpinanie punktu");
        unassignOrder(routePoint.id, "order");
      } else {
        actions.openLoader("Trwa przypinanie punktu");
        assignOrder({
          id: routePoint.id,
          point: routePoint.point,
          type: "order",
          address: null,
          warehouseDeliveryDetails: routePoint.warehouseDeliveryDetails,
          hasGuaranteedDeliveryBeforeChristmas: routePoint.hasGuaranteedDeliveryBeforeChristmas,
        });
      }
    }

    if (routePoint.isHidden) {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: "Punkt jest ukryty",
      });
    }
  };

  return (
    <>
      {routePoint.hasGuaranteedDeliveryBeforeChristmas && (
        <MapMarker
          position={routePoint.point}
          visible={visible}
          options={{ zIndex: 999 }}
          icon={{ url: hatIcon, anchor: new google.maps.Point(17, 20) }}
        />
      )}
      <MapMarker
        position={routePoint.point}
        visible={visible}
        onClick={() =>
          routePoint.type === "order" && toggleInfoWindow(Number(routePoint.id), routePoint.point)
        }
        onRightClick={handleRightClick}
        icon={routePoint.icon}
        label={routePoint.label}
      />
    </>
  );
};
