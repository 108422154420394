import { DOMAIN } from "ENDPOINTS";
import { FileExtension } from "components/miloDesignSystem/atoms/fileDownloadHandler/types";

interface FileDownloaderArgs {
  onProgress?: (progress: number) => void;
  url: string;
  type?: FileExtension;
  method?: "GET" | "POST";
  data?: Record<string, any>;
  name: string;
  hasUrlWithDomain?: boolean;
}

/**
 * removes duplicate slashes
 */
function removeSlash(url: string) {
  const arr = url.split("?");
  if (arr[0].charAt(0) === "/") {
    arr[0] = arr[0].substring(1);
    const search = arr[1] ? "?" + arr[1] : "";
    return arr[0] + search;
  }
  return url;
}

export function fileDownloader({
  onProgress,
  url,
  type,
  name,
  data,
  method = "GET",
}: FileDownloaderArgs) {
  return new Promise<
    | { status: "success"; error: null; httpStatus: number }
    | { status: "failure"; error: Record<string, string>; httpStatus: number }
  >(resolve => {
    function handleProgress(e: ProgressEvent<EventTarget>) {
      function getTotal() {
        if (e.lengthComputable) {
          return e.total;
        } else {
          console.error("File size cannot be computed, because response is gzipped");
          return null;
        }
      }
      const total = getTotal();
      var percentComplete = total ? Number(((e.loaded / total) * 100).toFixed(0)) : 0;
      onProgress?.(percentComplete);
    }
    var req = new XMLHttpRequest();
    if (onProgress) {
      req.onprogress = handleProgress;
    }
    const token = localStorage && localStorage.token;
    const urlWithDomain = DOMAIN + removeSlash(url);
    req.open(method, urlWithDomain, true);

    req.responseType = "blob";
    req.setRequestHeader("Authorization", "Bearer " + token);

    if (!data) {
      req.setRequestHeader("Content-Type", `Application/${type}`);
    }
    req.onreadystatechange = async function() {
      if (req.readyState === 4) {
        if (req.status === 200) {
          const blob = req.response;
          const overwrittenTypeFromResponse = type ? type : req.response.type.split("/")[1];
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          link.download = `${name}.${overwrittenTypeFromResponse}`;
          link.dispatchEvent(new MouseEvent("click"));
          link.remove();
          resolve({ status: "success", error: null, httpStatus: req.status });
        } else {
          const error = await req.response?.text();
          try {
            resolve({ status: "failure", error: JSON.parse(error), httpStatus: req.status });
          } catch (err) {
            resolve({
              status: "failure",
              error: { message: "Nie udało się pobrać pliku." },
              httpStatus: req.status,
            });
          }
        }
      }
    };
    if (data) {
      req.setRequestHeader("Content-Type", "application/json");
      req.send(JSON.stringify(data));
    } else {
      req.send(null);
    }
  });
}
