import { Route } from "components/utils";
import { RouteInvoicesList } from "pages/logistics/routeInvoicesList/RouteInvoicesList";
import { RouteHistory } from "pages/logistics/routesList/history/RouteHistory";
import { RoutesList } from "pages/logistics/routesList/RoutesList";
import { NotFound } from "pages/notFound";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const Routes = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={RouteHistory}
        exact={true}
        path={`${match.path}/:routeId/history`}
        title="Milo - historia trasy"
      />
      <Route
        component={RoutesList}
        exact={true}
        path={`${match.path}/list/:tab`}
        title="Milo - logistyka - trasy"
      />
      <Route
        component={RouteInvoicesList}
        exact={true}
        path={`${match.path}/:routeId/trading-documents/:tab`}
        title="Milo - logistyka - faktury trasy"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};
