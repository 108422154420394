import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiOrderGroup } from "components/miloDesignSystem/atoms/icons/MdiOrderGroup";
import { OrderGroupListRouting } from "routes/manufacturingNew/OrderGroupListRouting";

export const orderGroupList: ModuleLink = {
  url: "orderGroups",
  label: "Grupy zamówień",
  icon: MdiOrderGroup,
  routing: OrderGroupListRouting,
};
