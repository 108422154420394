import palletIcon from "assets/images/favicons/fv_pallet.svg";
import externalManufacturingIcon from "assets/images/pallet.svg";
import homeIcon from "assets/images/homeLight.svg";
import favicon from "assets/images/favicons/fv_producer.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { MdiListAlt } from "components/miloDesignSystem/atoms/icons/MdiListAlt";
import { MdiSingleOrders } from "components/miloDesignSystem/atoms/icons/MdiSingleOrders";
import { MdiOrdersGroup } from "components/miloDesignSystem/atoms/icons/MdiOrdersGroup";
import { MdiAnalytics } from "components/miloDesignSystem/atoms/icons/MdiAnalytics";
import { MdiSettings } from "components/miloDesignSystem/atoms/icons/MdiSettings";
import { MdiGroupByProducer } from "components/miloDesignSystem/atoms/icons/MdiGroupByProducer";
import { ExternalManufacturingOrdersRouting } from "routes/externalManufacturing/ExternalManufacturingOrdersRouting";
import { ManufacturerLineItems } from "routes/externalManufacturing/ManufacturerLineItems";

export const externalManufacturingConfig: ModuleNavigation = {
  favicon,
  color: colorPalette.pink200,
  navigationConfig: {
    label: "Zlecenia do producentów",
    icon: { src: palletIcon, background: colorPalette.pink600 },
  },
  menuDropdownIcon: externalManufacturingIcon,
  name: "EXTERNAL_MANUFACTURING",
  url: "/external-manufacturing",
  dashboard: {
    url: "dashboard",
    label: "Pulpit",
    icon: homeIcon,
    routing: () => <></>,
    isAccessible: false,
  },
  navigationSections: [
    {
      url: "orders",
      label: "Lista zleceń do producentów",
      icon: MdiListAlt,
      routing: ExternalManufacturingOrdersRouting,
      isAccessible: true,
    },
    {
      url: "manufacturer-line-items",
      label: "Poj. zlecenia do producentów",
      icon: MdiSingleOrders,
      routing: ManufacturerLineItems,
    },
    {
      url: "link3",
      label: "Zgrupowane zlecenia producenta",
      icon: MdiOrdersGroup,
      routing: () => <></>,
      isAccessible: false,
    },
    {
      url: "link4",
      label: "Zlecenia zgrupowane po producencie",
      icon: MdiGroupByProducer,
      routing: () => <></>,
      isAccessible: false,
    },
    {
      url: "link5",
      label: "Analityka",
      icon: MdiAnalytics,
      routing: () => <></>,
      isAccessible: false,
    },
    {
      url: "link6",
      label: "Ustawienia",
      icon: MdiSettings,
      routing: () => <></>,
      isAccessible: false,
    },
  ],
};
