import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities/utilities";
import { TextAlign } from "../columns/types";

interface Props {
  header: string;
  textAlign: TextAlign;
}

export const AlignedColumnHeader = ({ header, textAlign }: Props) => {
  return (
    <Typography
      className={cx({
        "text-right w-100": textAlign === "right",
        "text-left w-100": textAlign === "left",
        "text-center w-100": textAlign === "center",
      })}
      color="neutralBlack48"
      fontSize="12"
      fontWeight="400"
    >
      {header}
    </Typography>
  );
};
