import chairBlue from "assets/images/moduleNavigationIcons/chair-blue.svg";
import inventoryIcon from "assets/images/products.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { dashboard } from "./routes/dashboard";
import { products } from "./routes/products";
import { attributes } from "./routes/attributes";
import { indexes } from "./routes/indexes";
import { assortment } from "./routes/assortment";
import { inventoryPopularity } from "./routes/inventory-popularity";
import { priceLists } from "./routes/price-lists";
import favicon from "assets/images/favicons/fv_products.svg";
import { miloOffers } from "./routes/milo-offers";

export const inventory: ModuleNavigation = {
  favicon,
  color: "#01D6FB",
  navigationConfig: {
    label: "Produkty",
    icon: { src: chairBlue, background: "#005968" },
  },
  menuDropdownIcon: inventoryIcon,
  name: "INVENTORY",
  url: "/inventory",
  dashboard,
  navigationSections: [
    products,
    indexes,
    attributes,
    assortment,
    priceLists,
    inventoryPopularity,
    miloOffers,
  ],
};
