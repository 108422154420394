import { tradingDocumentsActions } from "api/trading-documents/actions";
import { MyPurchaseInvoiceToReview, TradingDocument } from "api/trading-documents/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiEdit } from "components/miloDesignSystem/atoms/icons/MdiEdit";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Popover } from "components/miloDesignSystem/atoms/popover/Popover";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { ClickOutsideHandler } from "components/utils/ClickOutsideHandler";
import { useSelector } from "hooks";
import { useState } from "react";

interface Props {
  selectedRows: TradingDocument[] | MyPurchaseInvoiceToReview[];
}

export const LedgerAccountSelector = ({ selectedRows }: Props) => {
  const ledgerAccounts = useSelector(store => store.partials.ledgerAccounts);
  const [accountancyAccount, setAccountancyAccount] = useState<string>();
  const ledgerAccountMutation = tradingDocumentsActions.useBulkPatchTradingDocuments();

  return (
    <Popover
      hideOnClickOutside={false}
      content={({ hide }) => (
        <ClickOutsideHandler
          onClickOutside={() => {
            hide();
            setAccountancyAccount(undefined);
          }}
          outsideClickIgnoreClass={ignoreClickOutsideClassName}
        >
          <div>
            <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
              Jakie konto księgowe przypisać?
            </Typography>
            <div className="d-flex">
              <div>
                <Select
                  items={ledgerAccounts.map(account => ({
                    value: account.id,
                    text: account.name,
                    type: MenuItemType.TEXT,
                  }))}
                  onChange={account => account && setAccountancyAccount(String(account))}
                  label="Wybierz konto"
                  selected={accountancyAccount ?? null}
                />
              </div>
            </div>
            <div className="d-flex flex-1 justify-content-end mt-3">
              <Button
                className="text-uppercase w-100"
                disabled={accountancyAccount === undefined || ledgerAccountMutation.isLoading}
                size="small"
                variant="deepPurple"
                onClick={() => {
                  ledgerAccountMutation.mutate(
                    {
                      ids: selectedRows.map(tradingDocument => tradingDocument.id),
                      toUpdate: {
                        ledgerAccount: accountancyAccount,
                      },
                    },
                    {
                      onSuccess: hide,
                    },
                  );
                }}
                isLoading={ledgerAccountMutation.isLoading}
              >
                Zmień konto księgowe
              </Button>
            </div>
          </div>
        </ClickOutsideHandler>
      )}
      variant="DARK"
    >
      <div>
        <Tooltip title="Zmień konta księgowe dla zaznaczonych">
          <IconButton icon={MdiEdit} variant="transparent" theme="dark" />
        </Tooltip>
      </div>
    </Popover>
  );
};

const ignoreClickOutsideClassName = "trading-documents-list-popover-ignore-class-name";
