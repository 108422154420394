import {
  FiscalizeReceiptPayload,
  ReceiptPrintingDisabledStatus,
  TradingDocument,
} from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { dateUtils, pluralize } from "utilities";
import { Link } from "react-router-dom";
import styles from "./CorrespondingReceiptSection.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiReceiptLong } from "components/miloDesignSystem/atoms/icons/MdiReceiptLong";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { useStateModal } from "hooks";
import { FailedResponseModal } from "components/miloDesignSystem/organisms/failedResponseModal/FailedResponseModal";

interface Props {
  tradingDocument: TradingDocument;
}

interface ReplyModal {
  openReplyModal: (stateToSet: { preview: FiscalizeReceiptPayload }) => void;
}

export const CorrespondingReceiptSection = ({ tradingDocument }: Props) => {
  const fiscalizationReplyModal = useStateModal<{ preview: FiscalizeReceiptPayload }>();

  if (!tradingDocument.correspondingReceipt) return null;

  const isPrintingDisabled =
    !tradingDocument.correspondingReceipt.isPrintingAllowed &&
    tradingDocument.correspondingReceipt.printingDisabledStatus ===
      ReceiptPrintingDisabledStatus.RECEIPT_0_AMOUNT;

  const isNotStartedOrFailed =
    tradingDocument.correspondingReceipt.printingStatus === "NOT_STARTED" ||
    tradingDocument.correspondingReceipt.printingStatus === "FAILED";

  if (isPrintingDisabled && isNotStartedOrFailed)
    return (
      <RightPanelSection>
        <div className="d-flex align-items-center gap-2">
          <InfoLabel title="paragon">
            <Tag label="Paragon zawiera pozycje o wartości 0" variant="danger" />
          </InfoLabel>
        </div>
      </RightPanelSection>
    );

  return (
    <>
      <RightPanelSection>
        <>
          <div className="d-flex align-items-center gap-2">
            <InfoLabel title="paragon">
              <FiscalizationSectionDetails
                openReplyModal={fiscalizationReplyModal.open}
                tradingDocument={tradingDocument}
              />
            </InfoLabel>
          </div>
          {tradingDocument.correspondingReceipt.printingStatus === "FINISHED" && (
            <div className="d-flex align-items-center gap-2 pb-2">
              <InfoLabel title="nr paragonu:">
                <Link
                  className={styles.link}
                  to={`/finances/receipts/list/all?panelId=${tradingDocument.correspondingReceipt.id}`}
                >
                  <div>{tradingDocument.correspondingReceipt.signature}</div>
                </Link>
              </InfoLabel>
            </div>
          )}
        </>
      </RightPanelSection>
      {fiscalizationReplyModal.isOpen && fiscalizationReplyModal.state && (
        <FailedResponseModal
          close={fiscalizationReplyModal.close}
          data={{
            success: Boolean(fiscalizationReplyModal.state.preview.message.valid.objects.length)
              ? {
                  message: `Pomyślnie sfiskalizowano paragon dla: ${fiscalizationReplyModal.state.preview.message.valid.objects
                    .map(document => document.signature)
                    .join(", ")}`,
                }
              : null,
            failed:
              getNotFiscalizedReceiptsNumber(fiscalizationReplyModal.state.preview) > 0
                ? {
                    message: getInvalidFiscalizationMessage(fiscalizationReplyModal.state.preview),
                    reasons: Object.values(
                      fiscalizationReplyModal.state.preview.message.invalid,
                    ).map(reason => ({
                      message: reason.message,
                      signatures: reason.objects.map(object => object.signature),
                    })),
                  }
                : null,
          }}
          title="Status fiskalizacji"
        />
      )}
    </>
  );
};

const FiscalizationSectionDetails = ({ openReplyModal, tradingDocument }: Props & ReplyModal) => {
  if (!tradingDocument.correspondingReceipt) return null;

  switch (tradingDocument.correspondingReceipt.printingStatus) {
    case "NOT_STARTED":
      return (
        <div className="d-flex align-items-center gap-3">
          <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
            nie wydrukowany
          </Typography>
          <FiscalizationButton openReplyModal={openReplyModal} tradingDocument={tradingDocument} />
        </div>
      );
    case "IN_PROGRESS":
      return <Tag label="w trakcie fiskalizacji" variant="warning" />;
    case "FAILED":
      return (
        <div className="d-flex align-items-center gap-3">
          <Tag label="niepowodzenie" variant="danger" />
          <FiscalizationButton openReplyModal={openReplyModal} tradingDocument={tradingDocument} />
        </div>
      );
    case "FINISHED":
      return (
        <div className="d-flex align-items-center gap-3">
          <Tag label="wydrukowano" variant="info" />
          <Typography fontSize="10" fontWeight="400">
            {tradingDocument.correspondingReceipt.printingRequestedAt
              ? dateUtils.formatDateAndTimeToDisplay(
                  tradingDocument.correspondingReceipt.printingRequestedAt,
                )
              : EMPTY_VALUE}
          </Typography>
        </div>
      );
    default:
      const exhaustiveCheck = tradingDocument.correspondingReceipt.printingStatus;
      console.error(`Unhandled printing status: ${exhaustiveCheck}`);
      return null;
  }
};

const FiscalizationButton = ({ tradingDocument, openReplyModal }: Props & ReplyModal) => {
  const fiscalizeReceiptMutation = tradingDocumentsActions.useFiscalizeReceipt(
    openReplyModal,
    tradingDocument,
  );

  if (Boolean(tradingDocument.items.flatMap(item => item.tradingDocumentItems).length))
    return (
      <Button
        className="text-uppercase"
        onClick={() =>
          fiscalizeReceiptMutation.mutate({
            id: tradingDocument.correspondingReceipt!.id,
          })
        }
        size="small"
        startIcon={MdiReceiptLong}
        theme="light"
        variant="gray"
      >
        Fiskalizuj
      </Button>
    );
  return (
    <Typography fontSize="12" fontWeight="700">
      brak pozycji na fakturze
    </Typography>
  );
};

const getInvalidFiscalizationMessage = (preview: FiscalizeReceiptPayload): string => {
  const notFiscalized = getNotFiscalizedReceiptsNumber(preview);
  if (notFiscalized === 0) return "";
  if (notFiscalized === 1) return "Nie sfiskalizowano paragonu";
  return `Nie sfiskalizowano ${pluralize.pl(notFiscalized, {
    singular: "paragonu",
    plural: "paragonów",
    other: "paragonów",
  })}`;
};

const getNotFiscalizedReceiptsNumber = (preview: FiscalizeReceiptPayload): number => {
  return Object.values(preview.message.invalid).reduce((acc, values) => {
    acc += values.objects.length;
    return acc;
  }, 0);
};
