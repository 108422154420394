import { uniquePackagesAction } from "api/unique-packages/actions";
import { LongHeldPackage } from "api/unique-packages/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";

interface Props {
  packages: LongHeldPackage[];
}

export const SettlePackage = ({ packages }: Props) => {
  const settlePackageMutation = uniquePackagesAction.useBulkUpdateLongHeldPackages();

  return (
    <div>
      <Tooltip title="Ustaw jako rozliczone">
        <IconButton
          icon={MdiCheck}
          isLoading={settlePackageMutation.isLoading}
          onClick={() =>
            settlePackageMutation.mutate({
              objects: packages.map(_package => _package.id),
              isSettled: true,
            })
          }
          theme="dark"
          variant="transparent"
        />
      </Tooltip>
    </div>
  );
};
