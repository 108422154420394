import { Pagination } from "../types";
import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { ContractorDetails, Manufacturer, ManufacturerListItem } from "./models";
import { manufacturersKeys } from "./keys";
import { ApiFetcher } from "hooks/createApiQuery";
import { queryFetch } from "apiConnectors/queryFetch";
import { CreateManufacturerSchema } from "pages/manufacturingNew/manufacturers/createManufacturer/CreateManufacturer";
import { Assign } from "utility-types";
import { parsePatchData } from "utilities/parsePatchData";

const api = tokenRefreshMiddleware(apiFetch);

export const getManufacturers = (search: string = "") =>
  api<Pagination<any>>({
    method: "GET",
    url: "/manufacturers/items" + search,
  });

export const postSendOrdersToManufacturer = (data: number[]) =>
  api<any>({
    method: "POST",
    url: "/manufacturers/send-selected-orders-to-manufacturer",
    data: { ids: data },
  });

export const getManufacturer = (id: number | string) =>
  api<Manufacturer>({
    method: "GET",
    url: "/manufacturers/items/" + id,
  });

export const patchManufacturer = (id: number | string, data: Partial<Manufacturer>) =>
  api<Manufacturer>({
    method: "PATCH",
    url: "/manufacturers/items/" + id,
    data,
  });

export const deleteManufacturer = (id: number | string) =>
  api<void>({
    method: "DELETE",
    url: "/manufacturers/items/" + id,
  });

export const postManufacturer = (
  data: Omit<Manufacturer, "id" | "productionOrders" | "isConnected" | "integrationToken">,
) =>
  api<Manufacturer>({
    method: "POST",
    url: "/manufacturers/items",
    data,
  });

export const manufacturerEnableConnection = (id: number | string) =>
  api<{ integrationToken: string; message: string; isConnected: boolean }>({
    method: "POST",
    url: `/manufacturers/items/${id}/enable-connection`,
  });

const getManufacturersQuery = (search: string): ApiFetcher<Pagination<ManufacturerListItem>> => ({
  key: manufacturersKeys.manufacturers(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturers/items" + search,
    }),
});

const getContractorDetails = (id: string): ApiFetcher<ContractorDetails> => ({
  key: manufacturersKeys.serviceProvider.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturers/contractors/" + id,
    }),
});

const patchContractor = (data: Assign<Partial<ContractorDetails>, { id: number }>) =>
  queryFetch<ContractorDetails>({
    method: "PATCH",
    url: "/manufacturers/contractors/" + data.id,
    data: parsePatchData(data),
  });

const getManufacturerQuery = (id: number): ApiFetcher<Manufacturer> => ({
  key: manufacturersKeys.manufacturer(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturers/items/" + id,
    }),
});

const patchManufacturerQuery = (data: Assign<Partial<Manufacturer>, { id: number }>) =>
  queryFetch<Manufacturer>({
    method: "PATCH",
    url: "/manufacturers/items/" + data.id,
    data: parsePatchData(data),
  });

const deleteManufacturerQuery = (id: number | string) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturers/items/" + id,
  });

const postManufacturerQuery = (data: CreateManufacturerSchema) =>
  queryFetch<Manufacturer>({
    method: "POST",
    url: "/manufacturers/items",
    data,
  });

export const manufacturersApi = {
  getContractorDetails,
  getManufacturersQuery,
  getManufacturerQuery,
  patchContractor,
  patchManufacturerQuery,
  deleteManufacturerQuery,
  postManufacturerQuery,
};
