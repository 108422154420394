import { Pagination } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { parsePatchData } from "utilities/parsePatchData";
import { Assign } from "utility-types";
import { labelsKeys } from "./keys";
import { CreateLabel, Label } from "./models";
import { fileFactoryUtils } from "utilities/fileFactory";

export const getLabels = (search: string = ""): ApiFetcher<Pagination<Label>> => ({
  key: labelsKeys.label.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/labels/items" + search,
    }),
});

export const getLabel = (id: string): ApiFetcher<Label> => ({
  key: labelsKeys.label.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/labels/items/" + id,
    }),
  globalOptions: {
    enabled: Boolean(id),
  },
});

export const postLabel = (data: CreateLabel) =>
  queryFetch<Label>({
    method: "POST",
    url: "/labels/items",
    data,
  });

export const patchLabel = (data: Assign<Partial<Label>, { id: string | number }>) =>
  queryFetch<Label>({
    method: "PATCH",
    url: "/labels/items/" + data.id,
    data: parsePatchData(data),
  });

export const deleteLabel = (id: string) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/labels/items/" + id,
  });

export const labelsFactory = (() => {
  return {
    labels: (search: string = "", name: string) => ({
      url: `/labels/siqp-preview${search}`,
      name: `${name}-${fileFactoryUtils.getDate()}`,
    }),
  };
})();
