import ruleGreyIcon from "assets/images/ruleGrey.svg";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { CorrectionInvoices } from "routes/CorrectionInvoices";
import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";
import { tradingDocumentsTabDict } from "pages/tradingDocuments/shared/utils/getTabs";

export const correctionInvoices: ModuleLink = {
  url: "corrections",
  label: "Korekty",
  icon: ruleGreyIcon,
  subSection: {
    renderer: <TabSubSection baseUrl="/finances/corrections/list" tabs={tradingDocumentsTabDict} />,
    isExpandedByDefault: false,
  },
  routing: CorrectionInvoices,
};
