import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import chairIcon from "assets/images/moduleNavigationIcons/chair.svg";
import { ProductsRouting } from "routes/wms/ProductsRouting";

export const products: ModuleLink = {
  url: "products",
  label: "Produkty",
  icon: chairIcon,
  routing: ProductsRouting,
};
