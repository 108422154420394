import { FreeSingleItemQuantitiesDetails } from "api/orders/models";
import { InfoLabel } from "components/common/infoLabel";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Attributes } from "components/miloDesignSystem/molecules/attributes";
import { RightPanelSection } from "components/utils/drawer";
import { dateUtils } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  data: FreeSingleItemQuantitiesDetails;
}

export const GeneralInfoSection = ({ data }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection padding="px-3 pt-2 pb-1">
      <div ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {data.name}
        </Typography>
      </div>
      <div className="mb-2">
        <Attributes attributes={data.index.attributes} />
      </div>
      <InfoLabel title="kontrahent (producent)">
        {data.customer ? (
          <Typography fontSize="14" fontWeight="700">
            {data.customer.name}
          </Typography>
        ) : (
          <EmptyValue fontSize="14" fontWeight="700" />
        )}
      </InfoLabel>
      <InfoLabel title="przyjęto">
        {data.warehouseReceivedAt ? (
          <Typography fontSize="14" fontWeight="400">
            {dateUtils.formatDateToDisplay(data.warehouseReceivedAt)}
          </Typography>
        ) : (
          <EmptyValue fontSize="14" fontWeight="400" />
        )}
      </InfoLabel>
    </RightPanelSection>
  );
};
