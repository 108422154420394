import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import trolleyIcon from "assets/images/trolley.svg";
import { CarrierOrders } from "routes/logistics/CarrierOrders";

export const carrierOrders: ModuleLink = {
  url: "carrier-orders",
  label: "Zlecenia do przewoźnika",
  icon: trolleyIcon,
  routing: CarrierOrders,
};
