import { TradingDocument } from "api/trading-documents/models";
import { RightPanelTableRow } from "components/utils/drawer";
import { correctionSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { Link } from "react-router-dom";
import styles from "../CorrectionSection.module.css";
import cx from "classnames";
import { dateFns } from "utilities";
import React from "react";
import { tradingDocumentConstants } from "constants/tradingDocuments";

interface Props {
  correction: TradingDocument["corrections"][number];
  position: number;
}

export const Correction = ({ correction, position }: Props) => {
  return (
    <RightPanelTableRow grid={correctionSectionListConfig.grid}>
      <div className="fw-700">{position}.</div>
      <div>
        <div className={styles.correctionSignature}>
          <Link
            className={styles.signatureLink}
            to={`/finances/corrections/list/all?panelId=${correction.id}`}
          >
            {correction.signature}
          </Link>
        </div>
      </div>
      <div className={cx(styles.reason, "fs-12 fw-700")}>
        {correction.reasons.length > 0
          ? correction.reasons.map((reason, index) => (
              <React.Fragment key={index}>
                {correction.reasons.length > index + 1 ? (
                  <>
                    {tradingDocumentConstants.correctionReasonsMap[reason.field][reason.action]}
                    ,&nbsp;
                  </>
                ) : (
                  <>{tradingDocumentConstants.correctionReasonsMap[reason.field][reason.action]}</>
                )}
              </React.Fragment>
            ))
          : "--"}
      </div>
      <div>
        <div className={styles.date}>
          {correction.invoiceDeliveryDate
            ? dateFns.format(new Date(correction.invoiceDeliveryDate), "dd.MM.yyyy")
            : "--"}
        </div>
      </div>
      <div className={styles.date}>
        {correction.paymentDeadline
          ? dateFns.format(new Date(correction.paymentDeadline), "dd.MM.yyyy")
          : "--"}
      </div>
      <div>
        <Link className={styles.signatureLink} to="#"></Link>
      </div>
    </RightPanelTableRow>
  );
};
