import { RadioProps, RadioTheme } from "./types";
import styles from "./Radio.module.css";
import { useMemo, useState } from "react";
import cuid from "cuid";
import { Typography } from "../typography";
import { cx, getAnyErrorKey, omit, yup } from "utilities";
import { TypographyProps } from "../typography/types";
import { ColorPalette } from "../colorsPalette";
import { MDSAsyncType, MDSFormType } from "typeUtilities";
import { AxiosError } from "axios";
import { useField, useFormikContext } from "formik";

const labelFontSizeSelector: Record<RadioProps["size"], TypographyProps["fontSize"]> = {
  sm: "12",
  md: "14",
};

const helperTextColor: Record<RadioTheme, ColorPalette> = {
  dark: "neutralWhite48",
  light: "neutralBlack48",
};

export const Radio = ({
  checked,
  disabled,
  helperText,
  label,
  onClick,
  size,
  theme = "light",
  error,
}: RadioProps) => {
  const id = useMemo(() => cuid(), []);

  const labelColor: Record<RadioTheme, ColorPalette> = {
    dark: disabled ? "neutralWhite64" : "neutralWhite100",
    light: disabled ? "neutralBlack64" : "neutralBlack88",
  };

  return (
    <div
      id={id}
      className={cx(styles.radioContainer, {
        "cursor-not-allowed": disabled,
        "align-items-center": !Boolean(helperText),
      })}
      onClick={event => {
        event.stopPropagation();
        if (!disabled) onClick(!checked);
      }}
    >
      <div
        className={cx(styles[`radioBase-${size}`], {
          [styles[`radioBase-${theme}-checked`]]: checked,
          [styles[`radioBase-${theme}-not-checked`]]: !checked,
          [styles.disabled]: disabled,
        })}
      >
        {checked && <div className={styles[`checkedRadioInnerDot-${size}`]} />}
      </div>
      <div>
        <Typography
          color={labelColor[theme]}
          fontSize={labelFontSizeSelector[size]}
          fontWeight="700"
        >
          {label}
        </Typography>
        <Typography
          className="italic"
          color={helperTextColor[theme]}
          fontSize="10"
          fontWeight="600"
        >
          {helperText}
        </Typography>
        {error && typeof error === "string" && (
          <Typography className="mt-1 ml-2" color="danger500" fontSize="10" fontWeight="500" noWrap>
            {error}
          </Typography>
        )}
      </div>
    </div>
  );
};

function AsyncRadio<TData = unknown, TError = unknown, TVariables = unknown, TContext = unknown>(
  props: MDSAsyncType<Omit<RadioProps, "error">, boolean, TData, TError, TVariables, TContext>,
) {
  const [validationError, setValidationError] = useState("");
  const mutation = props.mutationHook();

  const mutate = (value: RadioProps["checked"]) => {
    try {
      if (props.validation) {
        const validation = yup.object({
          temp: props.validation,
        });
        validation.validateSync({ temp: value });
        setValidationError("");
      }
      const data = props.transformQueryData(value);
      mutation.mutate(data);
    } catch (error) {
      setValidationError(getAnyErrorKey(error as AxiosError));
    }
  };

  const propsToForward = omit(props, ["transformQueryData", "mutationHook"]);

  return (
    <Radio
      disabled={mutation.isLoading}
      {...propsToForward}
      error={validationError || getAnyErrorKey(mutation.error as AxiosError)}
      onClick={e => mutate(e)}
    />
  );
}

function RadioForm<TForm>(props: MDSFormType<RadioProps, TForm>) {
  const [field, meta] = useField(props.name as string);
  const propsToForward = omit(props, ["name"]);
  const { setFieldValue } = useFormikContext<TForm>();

  return (
    <Radio
      {...propsToForward}
      {...field}
      checked={Boolean(field.value)}
      onClick={value => setFieldValue(props.name as string, value)}
      error={meta.touched && meta.error}
    />
  );
}

Radio.Form = RadioForm;
Radio.Async = AsyncRadio;
