import { useCarrierOrders } from "api/logistics/carrierOrders/hooks";
import { CarrierOrder } from "api/logistics/carrierOrders/models";
import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useQuery } from "hooks";
import { queryString } from "utilities";
import { useCarrierOrdersListColumns } from "./hooks/useCarrierOrdersListColumns";
import { RightPanel } from "./rightPanel/RightPanel";
import { filterFactory, withFilters } from "components/utils/withFilters";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";

const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.SelectUser,
    kind: "carrier",
    label: "przewoźnik",
    multiple: true,
    name: "carrier",
    searchBy: "users",
  },
]);

export const CarrierOrdersList = withFilters(filterList, () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);

  const { data: carrierOrders, error, isLoading, pagination } = useCarrierOrders(search);

  const columns = useCarrierOrdersListColumns();

  const tableProps = useTableFeatureConnector({
    rows: carrierOrders,
    withDrawer: "logisticsCarriersOrders",
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj wśród zleceń do przewoźników",
          tags: [],
        }}
        viewLabel="LOGISTICS_CARRIER_ORDERS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<CarrierOrder>
          columns={columns}
          isLoading={isLoading}
          error={error}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
      </div>
    </PageWrapper>
  );
});
