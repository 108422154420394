import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { ManufacturersRouting } from "routes/manufacturingNew/ManufacturersRouting";
import { MdiFactory } from "components/miloDesignSystem/atoms/icons/MdiFactory";

export const manufacturers: ModuleLink = {
  url: "manufacturers",
  label: "Dostawcy",
  icon: MdiFactory,
  routing: ManufacturersRouting,
};
