import { PreviewCorrectionDocument } from "api/trading-documents/models";
import { RightPanelTableRow } from "components/utils/drawer";
import { currencyConvertSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { CreateCorrectionValues } from "../../CreateCorrectionManuallyModal";
import { CurrencyConvertionPerVatRate } from "./CurrencyConvertionPerVatRate";
import { SummaryAmount } from "../SummaryAmount";

interface Props {
  preview: PreviewCorrectionDocument;
  values: CreateCorrectionValues;
}

export const CurrencyConvertionSection = ({ preview, values }: Props) => {
  return (
    <div className="pt-4">
      <RightPanelTableRow grid={currencyConvertSectionListConfig.grid} hover={false}>
        <div>kurs</div>
        <div>z dnia</div>
        <div></div>
        <div>VAT</div>
        <div className="d-flex align-items-center justify-content-end">wartość netto</div>
        <div className="d-flex align-items-center justify-content-end">kwota VAT</div>
        <div className="d-flex align-items-center justify-content-end">wartość brutto</div>
      </RightPanelTableRow>
      {preview.amountSummaryPerVatRateCurrencyConvert.map((currencyConvert, index) => (
        <CurrencyConvertionPerVatRate
          currencyConvert={currencyConvert}
          key={index}
          preview={preview}
          values={values}
        />
      ))}
      <RightPanelTableRow grid={currencyConvertSectionListConfig.grid}>
        <div />
        <div />
        <div className="d-flex align-items-center justify-content-end text-grey-35">suma:</div>
        <div />
        <div className="d-flex align-items-center justify-content-end gap-1">
          {preview.amountSummaryCurrencyConvert.exchangeRate ? (
            <SummaryAmount
              conversionRate={preview.amountSummaryCurrencyConvert.exchangeRate}
              currency={preview.currency}
              fieldName="amountWithoutTax"
              summaries={preview.amountSummaryPerVatRate}
              values={values}
            />
          ) : (
            "--"
          )}
        </div>
        <div className="d-flex align-items-center justify-content-end gap-1">
          {preview.amountSummaryCurrencyConvert.exchangeRate ? (
            <SummaryAmount
              conversionRate={preview.amountSummaryCurrencyConvert.exchangeRate}
              currency={preview.currency}
              fieldName="taxAmountBeforeRounding"
              summaries={preview.amountSummaryPerVatRate}
              values={values}
            />
          ) : (
            "--"
          )}
        </div>
        <div className="d-flex align-items-center justify-content-end gap-1">
          {preview.amountSummaryCurrencyConvert.exchangeRate ? (
            <SummaryAmount
              conversionRate={preview.amountSummaryCurrencyConvert.exchangeRate}
              currency={preview.currency}
              fieldName="amountWithTax"
              summaries={preview.amountSummaryPerVatRate}
              values={values}
            />
          ) : (
            "--"
          )}
        </div>
      </RightPanelTableRow>
    </div>
  );
};
