import { PartialOf } from "typeUtilities";
import { ManufacturingEmployeeDetails, ManufacturingEmployeeListItem } from "./models";
import { queryFetch } from "apiConnectors/queryFetch";
import { parsePatchData } from "utilities/parsePatchData";
import { ApiFetcher } from "hooks/createApiQuery";
import { Pagination } from "api/types";
import { manufacturingEmployeesKeys } from "./keys";
import { getFileDownloadDate } from "utilities";

const getManufacturingEmployees = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingEmployeeListItem>> => ({
  key: manufacturingEmployeesKeys.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/employees/items" + search,
    }),
});

const getManufacturingEmployee = (
  employeeId: string,
): ApiFetcher<ManufacturingEmployeeDetails> => ({
  key: manufacturingEmployeesKeys.details(employeeId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/employees/items/" + employeeId,
    }),
});

const patchManufacturingEmployee = ({
  data,
  id,
}: {
  data: PartialOf<ManufacturingEmployeeDetails>;
  id: number;
}) =>
  queryFetch<ManufacturingEmployeeDetails>({
    method: "PATCH",
    url: "/manufacturing/employees/items/" + id,
    data: parsePatchData(data),
  });

const deleteManufacturingEmployee = (employeeId: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/employees/items/" + employeeId,
  });

export const manufacturingEmployeeFileFactory = (() => {
  return {
    employeeLabel: (employee: ManufacturingEmployeeDetails) => ({
      url: "/upholsteries/get-employee-label/" + employee.id,
      name: `${employee.firstName}-${employee.lastName}-${getFileDownloadDate()}`,
    }),
  };
})();

export const manufacturingEmployeesApi = {
  deleteManufacturingEmployee,
  getManufacturingEmployee,
  getManufacturingEmployees,
  patchManufacturingEmployee,
};
