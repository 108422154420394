import { CollectionKind } from "api/wms/layout/models";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";
import { MdiOrderGroup } from "components/miloDesignSystem/atoms/icons/MdiOrderGroup";
import { MdiPackage2 } from "components/miloDesignSystem/atoms/icons/MdiPackage2";
import { MdiRoute } from "components/miloDesignSystem/atoms/icons/MdiRoute";
import { MdiShoppingCart } from "components/miloDesignSystem/atoms/icons/MdiShoppingCart";

const collectionToIconDict: Record<CollectionKind, JSX.Element> = {
  ORDER: <MdiShoppingCart color="magenta300" size="14" />,
  ORDER_GROUP: <MdiOrderGroup color="purple200" size="14" />,
  PACKAGE: <MdiPackage2 color="purple300" size="14" />,
  PRODUCT: <MdiChair color="cyan300" size="14" />,
  ROUTE: <MdiRoute color="deepPurple400" size="14" />,
};

export const warehouseSchemaConstants = {
  collectionToIconDict,
};
