import { indexesActions } from "api/manufacturing/indexes/actions";
import { IndexListItem } from "api/manufacturing/indexes/models";
import { UUID } from "api/types";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiSchemeAlt } from "components/miloDesignSystem/atoms/icons/MdiSchemeAlt";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Select } from "components/miloDesignSystem/molecules/select";
import { ClickOutsideHandler } from "components/utils";
import { useSelector } from "hooks";
import { useState } from "react";

interface Props {
  indexes: IndexListItem[];
}

export const AssignManufacturingSchema = ({ indexes }: Props) => {
  const [schemaId, setSchemaId] = useState<UUID | null>(null);
  const manufacturingSchemas = useSelector(store => store.partials.manufacturingSchemas);
  const schemasOptions = manufacturingSchemas.map(schema => ({
    text: `${schema.name} (${schema.signature})`,
    type: MenuItemType.TEXT,
    value: schema.id,
  }));
  const assignSchemaMutation = indexesActions.useBulkUpdateIndexes();

  return (
    <Popover
      content={({ hide }) => (
        <ClickOutsideHandler
          onClickOutside={() => {
            hide();
            setSchemaId(null);
          }}
          outsideClickIgnoreClass={ignoreClickOutsideClassName}
        >
          <div>
            <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
              Przypisz schemat produkcji
            </Typography>
            <Select
              items={schemasOptions}
              onChange={schemaId => schemaId && setSchemaId(schemaId as string)}
              selected={schemaId ?? null}
            />
            <div className="mt-3">
              <Button
                className="text-uppercase w-100"
                onClick={() =>
                  assignSchemaMutation.mutate(
                    {
                      indexesIds: indexes.map(index => index.id),
                      manufacturingSchemaId: schemaId,
                    },
                    {
                      onSuccess: () => {
                        hide();
                        setSchemaId(null);
                      },
                    },
                  )
                }
                size="small"
                variant="deepPurple"
              >
                Przypisz schemat
              </Button>
            </div>
          </div>
        </ClickOutsideHandler>
      )}
      hideOnClickOutside={false}
      variant="DARK"
    >
      <div>
        <Tooltip title="Przypisz schemat produkcji">
          <IconButton
            icon={MdiSchemeAlt}
            isLoading={assignSchemaMutation.isLoading}
            theme="dark"
            variant="transparent"
          />
        </Tooltip>
      </div>
    </Popover>
  );
};

const ignoreClickOutsideClassName = "indexes-list-bulk-assign-schema-ignore-class-name";
