import cx from "classnames";
import styles from "../AddRoutesFiltersModal.module.css";
import React from "react";
import { DatePicker } from "components/utils/datePicker";
import { dateFns } from "utilities";

interface Props {
  departureDate: string;
  setDepartureDate: React.Dispatch<React.SetStateAction<string>>;
}

export const AdditionalRoutesFilters = ({ departureDate, setDepartureDate }: Props) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <span className={cx(styles.checkedNumberLabel, "pt-1")}>data wyjazdu:</span>
      <DatePicker
        className="d-inline-flex pt-1"
        onChange={date => {
          if (!date) {
            setDepartureDate("");
          } else {
            setDepartureDate(dateFns.format(new Date(date), "yyyy-MM-dd"));
          }
        }}
        overwrites={{ popup: { className: styles.datePickerPopup } }}
        value={departureDate}
      />
    </div>
  );
};
