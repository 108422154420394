import { EmployedPlace } from "api/manufacturingNew/models";

const transfromCentsToPln = (amount: number) => {
  return (amount / 100).toFixed(2);
};

const employmentPlaceDict: Record<EmployedPlace, string> = {
  EXTERNAL: "u kontrahenta",
  INTERNAL: "wewnętrznie",
};

export const manufacturingEmployeesUtils = {
  employmentPlaceDict,
  transfromCentsToPln,
};
