import { materialsActions } from "api/manufacturing/materials/actions";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useQuery } from "hooks";
import { useDefaultFilters } from "hooks/useDefaultFilters";
import { queryString } from "utilities";
import { useMaterialsDemandsColumns } from "./useMaterialsDemandsColumns";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common/pageHeader/PageHeader";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { StageMaterialDemands } from "api/manufacturing/materials/models";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { withFilters } from "components/utils/withFilters";
import { FilterList } from "./filterList";
import { useStageId } from "../../hooks/useStageId";

export const MaterialDemands = withFilters(FilterList, () => {
  const stageId = useStageId();
  useDefaultFilters({
    schemaStages: stageId || "",
  });
  const { query } = useQuery();
  const {
    data: demands,
    error,
    isLoading,
    isPreviousData,
  } = materialsActions.useStageMaterialsDemands(queryString.stringify(query), {
    enabled: Boolean(stageId),
  });

  const tableProps = useTableFeatureConnector({
    rows: demands!,
  });
  const columns = useMaterialsDemandsColumns();

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        title={
          <Typography fontSize="20" fontWeight="700">
            Zapotrzebowanie na materiały
          </Typography>
        }
        viewLabel="MANUFACTURING_STAGE_MATERIALS_DEMANDS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<StageMaterialDemands>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
      </div>
    </PageWrapper>
  );
});
