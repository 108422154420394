import { useMemo, useState } from "react";
import { useMenuButton, useMenuState } from "reakit";
import { SelectProps } from "./types";
import v4 from "cuid";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { MenuList } from "components/miloDesignSystem/atoms/menu";
import { IconMenuItem, MenuItem, MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiKeyboardArrowDown } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowDown";
import styles from "./Select.module.css";
import { cx, getAnyErrorKey, omit, yup } from "utilities";
import { ClickOutsideHandler } from "components/utils";
import { Assign } from "utility-types";
import { UseMutationResult } from "react-query";
import { AxiosError } from "axios";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { StringSchema } from "yup";
import { MDSFormType } from "typeUtilities";
import { useField, useFormikContext } from "formik";

export const Select = ({
  textFieldProps,
  selected,
  items,
  onChange,
  label,
  className,
  theme = "light",
  disabled,
}: SelectProps) => {
  const menu = useMenuState({});
  const menuButton = useMenuButton(menu);
  const [searchValue, setSearchValue] = useState("");
  const clickOutsideIgnoreClass = useMemo(() => `click-outside-select-omit-${v4()}`, []);
  const selectedItem = items.find(item => String(item.value) === String(selected));

  const filteredMenuItems = items.filter(item =>
    item.text.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
  );

  const menuItems = filteredMenuItems.map(
    menuItem =>
      ({
        ...menuItem,
        options: {
          ...menuItem.options,
          className: selectedItem?.value === menuItem.value ? styles.selected : "",
        },

        onClick: () => {
          setSearchValue("");
          onChange(menuItem.value);
          menu.hide();
        },
      } as MenuItem),
  );

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = e => {
    if (disabled) return;
    e.stopPropagation();
    menu.setVisible(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!menu.visible) {
      menu.setVisible(true);
    }
    switch (event.key) {
      case "Up":
      case "ArrowUp":
        event.preventDefault();
        menu.last();
        return;
      case "Down":
      case "ArrowDown":
        event.preventDefault();
        menu.first();
        return;
      case "Enter":
        event.preventDefault();

        if (menuItems.length) {
          menuItems[0]?.onClick();
        }
        return;
      case "Esc":
      case "Escape":
        event.preventDefault();
        setSearchValue("");
        menu.hide();
        return;
    }
  };

  return (
    <ClickOutsideHandler
      onClickOutside={() => {
        menu.setVisible(false);
        setSearchValue("");
      }}
      outsideClickIgnoreClass={clickOutsideIgnoreClass}
    >
      <div
        ref={menuButton.ref}
        className={cx(className, clickOutsideIgnoreClass)}
        onClick={() => {
          if (!menu.visible && !disabled) {
            menu.setVisible(true);
            menu.first();
          }
        }}
      >
        <TextField
          disabled={disabled}
          inputClassName={cx(
            { [styles[`selectedPlaceholder-${theme}`]]: Boolean(selectedItem) && !disabled },
            clickOutsideIgnoreClass,
          )}
          theme={theme}
          endIcon={MdiKeyboardArrowDown}
          startIcon={
            selectedItem?.type === MenuItemType.ICON
              ? ((selectedItem as unknown) as IconMenuItem).icon
              : textFieldProps?.startIcon
          }
          label={label}
          value={searchValue}
          placeholder={selectedItem?.text || "Szukaj..."}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          {...textFieldProps}
          size={textFieldProps?.size || "small"}
        />

        <MenuList
          searchValue={searchValue}
          className={clickOutsideIgnoreClass}
          hideOnClickOutside={false}
          menuItems={menuItems}
          menuState={menu}
        />
      </div>
    </ClickOutsideHandler>
  );
};

function AsyncSelect<TData = unknown, TError = unknown, TVariables = unknown, TContext = unknown>(
  props: Assign<
    SelectProps,
    {
      mutationHook: () => UseMutationResult<TData, TError, TVariables, TContext>;
      transformQueryData: (value: SelectProps["selected"]) => TVariables;
      validation?: StringSchema<string>;
      onChange?: never;
    }
  >,
) {
  const [validationError, setValidationError] = useState("");
  const mutation = props.mutationHook();
  const mutate = (value: SelectProps["selected"]) => {
    try {
      if (props.validation) {
        const validation = yup.object({
          temp: props.validation,
        });
        validation.validateSync({ temp: value });
        setValidationError("");
      }
      const data = props.transformQueryData(value);
      mutation.mutate(data);
    } catch (error) {
      setValidationError(getAnyErrorKey(error as AxiosError));
    }
  };
  const propsToForward = omit(props, ["transformQueryData", "mutationHook"]);
  return (
    <Select
      {...propsToForward}
      onChange={e => mutate(e)}
      textFieldProps={
        ({
          disabled: mutation.isLoading,
          error: validationError || getAnyErrorKey(mutation.error as AxiosError),
          endIcon: mutation.isLoading ? <Spinner size={16} /> : MdiKeyboardArrowDown,
          ...props.textFieldProps,
        } as unknown) as SelectProps["textFieldProps"]
      }
    />
  );
}

function FormSelect<TForm>(props: MDSFormType<SelectProps, TForm>) {
  const [field, meta] = useField(props.name as string);
  const propsToForward = omit(props, ["name"]);
  const { setFieldValue } = useFormikContext<TForm>();

  return (
    <Select
      {...propsToForward}
      items={props.items}
      {...field}
      onChange={value => setFieldValue(props.name as string, value)}
      selected={field.value}
      textFieldProps={
        ({
          error: meta.touched && meta.error,
          ...props.textFieldProps,
        } as unknown) as SelectProps["textFieldProps"]
      }
    />
  );
}

Select.Async = AsyncSelect;
Select.Form = FormSelect;
