import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { walletsApi } from "./api";

const useEmployeeTransactions = createPaginatedApiQuery(
  walletsApi.getManufacturingWalletsEmployeeTransactions,
);

export const walletsActions = {
  useEmployeeTransactions,
};
