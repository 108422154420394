import { createColumnHelper } from "@tanstack/react-table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useMemo } from "react";
import { ReportDetails } from "api/finances/models";
import { NormalizedFinanceBalanceReport } from "./FinancesBalance";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";

const columnHelper = createColumnHelper<NormalizedFinanceBalanceReport>();

export const useFinancesBalanceColumns = () => {
  return useMemo(() => {
    return [
      columnHelper.accessor(row => row, {
        id: "label",
        header: "",
        size: 170,
        cell: info => {
          const value = info.getValue();

          const isNested = info.row.depth;
          if (isNested) {
            const currencies = [
              ...new Set([
                value.gross?.currency,
                value.net?.currency,
                value.vat?.currency,
                value.vatOss?.currency,
              ]),
            ].filter(Boolean);

            return (
              <Typography fontSize="12" fontWeight="700" noWrap>
                {currencies.join(", ")}
              </Typography>
            );
          }

          return (
            <Typography fontSize="14" fontWeight="700" noWrap>
              {value.label}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.net, {
        id: "net",
        header: () => (
          <Typography fontSize="12" fontWeight="400" color="neutralBlack48" className="text-right">
            netto
          </Typography>
        ),
        size: 170,
        cell: info => {
          const value = info.getValue();

          const isNested = info.row.depth;

          if (isNested) {
            return <PriceRenderer isNested reportDetails={value} />;
          }
          return <PriceRenderer reportDetails={value} />;
        },
      }),
      columnHelper.accessor(row => row.vat, {
        id: "vat",
        header: () => (
          <Typography fontSize="12" fontWeight="400" color="neutralBlack48" className="text-right">
            VAT
          </Typography>
        ),
        size: 170,
        cell: info => {
          const value = info.getValue();

          const isNested = info.row.depth;
          if (isNested) {
            return <PriceRenderer isNested reportDetails={value} />;
          }
          return <PriceRenderer reportDetails={value} />;
        },
      }),
      columnHelper.accessor(row => row.gross, {
        id: "gross",
        header: () => (
          <Typography fontSize="12" fontWeight="400" color="neutralBlack48" className="text-right">
            brutto
          </Typography>
        ),
        size: 170,
        cell: info => {
          const value = info.getValue();

          const isNested = info.row.depth;
          if (isNested) {
            return <PriceRenderer isNested reportDetails={value} />;
          }
          return <PriceRenderer reportDetails={value} />;
        },
      }),
      columnHelper.accessor(row => row.vatOss, {
        id: "vatOss",
        header: () => (
          <Typography fontSize="12" fontWeight="400" color="neutralBlack48" className="text-right">
            VAT OSS
          </Typography>
        ),
        size: 170,
        cell: info => {
          const value = info.getValue();

          const isNested = info.row.depth;
          if (isNested) {
            return <PriceRenderer isNested reportDetails={value} />;
          }
          return <PriceRenderer reportDetails={value} />;
        },
      }),
    ];
  }, []);
};

const PriceRenderer = ({
  isNested = false,
  reportDetails,
}: {
  reportDetails: ReportDetails["gross"];
  isNested?: boolean;
}) => {
  const fontSize: TypographyProps["fontSize"] = isNested ? "14" : "16";
  const fontWeight: TypographyProps["fontWeight"] = isNested ? "500" : "700";
  if (!reportDetails)
    return (
      <div className="d-flex align-items-baseline justify-content-end flex-1">
        <EmptyValue fontSize={fontSize} fontWeight={fontWeight} />
      </div>
    );

  return (
    <div className="d-flex align-items-baseline justify-content-end flex-1">
      <AmountDisplay
        amount={reportDetails.amount}
        currency={reportDetails.currency}
        decimalTypographyProps={{
          fontSize: "12",
          fontWeight: "600",
          noWrap: true,
          color: "neutralBlack48",
        }}
        integerTypographyProps={{ fontSize, fontWeight, noWrap: true }}
      />
    </div>
  );
};
