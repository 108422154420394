import { useTradingDocument } from "api/trading-documents/hooks";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { CorrectionSection } from "pages/tradingDocuments/shared/components/rightPanel/correctionSection/CorrectionSection";
import { CorrespondingDocumentSection } from "pages/tradingDocuments/shared/components/rightPanel/correspondingDocumentsSection/CorrespondingDocumentSection";
import { GeneralInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/generalInfoSection/GeneralInfoSection";
import { NotificationSection } from "pages/tradingDocuments/shared/components/rightPanel/notificationSection/NotificationSection";
import { TitleSection } from "pages/tradingDocuments/shared/components/rightPanel/titleSection/TitleSection";
import { AmountSection } from "./components/AmountSection";
import { MasterDocumentSection } from "./components/MasterDocumentSection";
import { PanelHeader } from "./components/PanelHeader";
import { useRef } from "react";
import { RemindersSection } from "../../shared/components/rightPanel/remindersSection/RemindersSection";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { BuyerInfo } from "pages/tradingDocuments/shared/components/rightPanel/buyerInfoSection/BuyerInfo";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { RecipientInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/recipientInfoSection/RecipientInfoSection";
import { ItemsSection } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/correctionInvoiceItems/ItemsSection";
import { useDrawer } from "hooks/useDrawer";
import { FinancesPayments } from "pages/tradingDocuments/shared/components/rightPanel/paymentSection/FinancesPayments";

export const RightPanel = () => {
  const { close } = useDrawer("correctionInvoices");
  const { query } = useQuery();
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data: tradingDocument, error, isLoading } = useTradingDocument(
    { id: panelId },
    { enabled: Boolean(panelId) },
  );

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!tradingDocument) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={tradingDocument.id}>
        <PanelHeader close={close} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody myRef={clearCommentsRef} hasCommentsModule>
            <TitleSection tradingDocument={tradingDocument} />
            <GeneralInfoSection tradingDocument={tradingDocument} />
            <AmountSection tradingDocument={tradingDocument} />
            <ItemsSection tradingDocument={tradingDocument} />
            <MasterDocumentSection tradingDocument={tradingDocument} />
            <FinancesPayments tradingDocument={tradingDocument} />
            <NotificationSection tradingDocument={tradingDocument} />
            {Boolean(tradingDocument.corrections.length) && (
              <CorrectionSection tradingDocument={tradingDocument} />
            )}
            {Boolean(tradingDocument.correspondingDocuments.length) && (
              <CorrespondingDocumentSection tradingDocument={tradingDocument} />
            )}
            <RemindersSection tradingDocument={tradingDocument} />
            <BuyerInfo tradingDocument={tradingDocument} />
            <RecipientInfoSection tradingDocument={tradingDocument} />
          </RightPanelBody>
          <CommentsGenericSection
            outsideRef={clearCommentsRef}
            paramName="tradingDocument"
            endpointUrl="/finances/comments/items"
            commentedObject={tradingDocument}
          />
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
