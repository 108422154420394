import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "../shared/CustomerStyles.module.css";
import { Customer } from "api/customers/models";

export const useCustomerColumns = () => {
  return useCreateTableColumns<Customer>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "kontrahent",
        size: 240,
        typographyProps: {
          fontSize: "16",
        },
      }),
      columnHelper.text(
        row => `${Boolean(row.street.length) && `${row.street}, `}${row.postCode} ${row.city}`,
        {
          header: "adres",
          size: 240,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "14",
            fontWeight: "500",
          },
        },
      ),
      columnHelper.text(row => row.businessRegisterNumber, {
        header: "REGON",
        size: 100,
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "14",
          fontWeight: "400",
        },
      }),
      columnHelper.text(row => row.taxId, {
        header: "NIP",
        size: 100,
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "14",
          fontWeight: "400",
        },
      }),
      columnHelper.accessor(row => row.accountBalance, {
        id: "accountBalance",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            stan bieżący
          </Typography>
        ),
        size: 100,
        cell: info => {
          const accountBalance = info.getValue();
          if (!accountBalance) return <EmptyValue className="text-right w-100" fontSize="16" />;
          return (
            <AmountDisplay
              amount={accountBalance.amount || "0.00"}
              className={styles.amountDisplay}
              currency={accountBalance.currency}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.paidAmount, {
        id: "paidAmount",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            opłacone
          </Typography>
        ),
        size: 100,
        cell: info => {
          const paidAmount = info.getValue();
          if (!paidAmount) return <EmptyValue className="text-right w-100" fontSize="16" />;
          return (
            <AmountDisplay
              amount={paidAmount.amount || "0.00"}
              className={styles.amountDisplay}
              currency={paidAmount.currency}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.amountToPay, {
        id: "amountToPay",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            pozostało
          </Typography>
        ),
        size: 100,
        cell: info => {
          const amountToPay = info.getValue();
          if (!amountToPay) return <EmptyValue className="text-right w-100" fontSize="16" />;
          return (
            <AmountDisplay
              amount={amountToPay.amount || "0.00"}
              className={styles.amountDisplay}
              currency={amountToPay.currency}
            />
          );
        },
      }),
    ];
  });
};
