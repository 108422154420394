import { HideOrderOnMap } from "api/routes/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./HidePointModal.module.css";
import { Formik, FormikHelpers } from "formik";
import { cx } from "utilities";
import { FormInput } from "components/utils";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "react-query";
import { useMutation } from "hooks/useMutation";
import { patchHideOrderOnMap } from "api/routes/calls";
import { OrderForRoute, OrderPointsForRoute } from "api/orders/models";
import { ordersKeys, routeKeys } from "api/keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useQuery } from "hooks";
import immer from "immer";
import { useInfoWindow } from "pages/routes/creator/hooks/useInfoWindow";
import { getHidePointDates } from "../utils/getHidePointDates";
import { Chip } from "components/miloDesignSystem/atoms/chip";
import { useState } from "react";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";

interface Props {
  close: () => void;
  order: OrderForRoute;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  routePointsSearch: string;
}

export const HidePointModal = ({ close, order, refetch, routePointsSearch }: Props) => {
  const { closeInfoWindow } = useInfoWindow();
  const { query } = useQuery();
  const chipSelectorDates = getHidePointDates();
  const [pickedDate, setPickedDate] = useState<string | number | null>(chipSelectorDates[0].value);

  const initialValues: HideOrderOnMap = {
    hideOnMapTo: order.delivery.isHidden ? order.delivery.hideOnMapTo : String(pickedDate),
    hideUntilIssueIsSolved: order.delivery.hideUntilIssueIsSolved,
    hideIssue: order.delivery.hideIssue,
  };

  const isDateSelected = (value: string | number | null): boolean => {
    return value === pickedDate;
  };

  const hideOrderMutation = useMutation(
    ({ id, toUpdate }: { id: number; toUpdate: HideOrderOnMap }) =>
      patchHideOrderOnMap(toUpdate, id),
    ({ queryClient, toastr, queryUtils }) => ({
      onSuccess: (_, args) => {
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: order.delivery.isHidden ? "Zmieniono parametry ukrycia punktu" : "Ukryto punkt",
        });
        refetch();
        if (Boolean(query["showHiddenPoints"]) && query["showHiddenPoints"] === "true") {
          queryUtils.handleMutate<OrderForRoute>(
            ordersKeys.orderForRoute(String(args.id)),
            draft => {
              draft.delivery.isHidden = true;
              draft.delivery.hideOnMapTo = args.toUpdate.hideOnMapTo;
              draft.delivery.hideUntilIssueIsSolved = args.toUpdate.hideUntilIssueIsSolved;
            },
          );

          queryClient.setQueryData<OrderPointsForRoute>(
            routeKeys.routeCreatorPoints(routePointsSearch),
            currentList => {
              assertIsDefined(currentList);
              return immer(currentList, draft => {
                draft.results = draft.results.map(orderPoint => {
                  if (orderPoint.id === args.id) {
                    return {
                      ...orderPoint,
                      isHidden: true,
                      hideOnMapTo: args.toUpdate.hideOnMapTo,
                      hideUntilIssueIsSolved: args.toUpdate.hideUntilIssueIsSolved,
                    };
                  }
                  return orderPoint;
                });
              });
            },
          );
        } else {
          queryClient.setQueryData<OrderPointsForRoute>(
            routeKeys.routeCreatorPoints(routePointsSearch),
            currentList => {
              assertIsDefined(currentList);
              return immer(currentList, draft => {
                draft.results = draft.results.filter(orderPoint => orderPoint.id !== args.id);
              });
            },
          );
          closeInfoWindow();
        }

        close();
      },
    }),
  );

  const handleSubmit = (values: HideOrderOnMap, actions: FormikHelpers<HideOrderOnMap>) => {
    hideOrderMutation.mutate(
      {
        id: order.id,
        toUpdate: {
          hideIssue: values.hideIssue,
          hideOnMapTo: !values.hideUntilIssueIsSolved ? String(pickedDate) : values.hideOnMapTo,
          hideUntilIssueIsSolved: values.hideUntilIssueIsSolved,
        },
      },
      {
        onSuccess: () => {
          actions.setSubmitting(false);
        },
        onError: error => {
          actions.setSubmitting(false);
          actions.setErrors(error.response?.data);
        },
      },
    );
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Wybierz opcję
        </Typography>
      }
      width={495}
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className={cx(styles.modalForm, "d-flex flex-column pr-3 pl-3 gap-2 pt-2")}>
              <div className="d-flex flex-column gap-1 mb-3">
                <Radio
                  checked={!values.hideUntilIssueIsSolved}
                  label="Na czas określony"
                  onClick={status => {
                    setFieldValue("hideUntilIssueIsSolved", !status);
                    if (status) {
                      setFieldValue("hideOnMapTo", pickedDate);
                    } else {
                      setFieldValue("hideOnMapTo", null);
                    }
                  }}
                  size="sm"
                />
                <div className={styles.chipsSelector}>
                  {chipSelectorDates.map(date => {
                    return (
                      <Chip
                        icon={isDateSelected(date.value) ? MdiCheck : undefined}
                        key={date.label}
                        label={date.label}
                        onClick={() => {
                          if (pickedDate !== date.value) {
                            setPickedDate(date.value);
                            setFieldValue("hideOnMapTo", date.value);
                          } else {
                            setPickedDate(chipSelectorDates[0].value);
                            setFieldValue("hideOnMapTo", chipSelectorDates[0].value);
                          }
                        }}
                        selected={isDateSelected(date.value)}
                        variant="primary"
                      />
                    );
                  })}
                </div>
                <Radio
                  checked={values.hideUntilIssueIsSolved}
                  label="Do czasu rozwiązania problemu"
                  onClick={status => {
                    setFieldValue("hideUntilIssueIsSolved", status);
                    if (status) {
                      setFieldValue("hideOnMapTo", null);
                    } else {
                      setFieldValue("hideOnMapTo", pickedDate);
                    }
                  }}
                  size="sm"
                />
              </div>
              <FormInput
                label="Powód ukrycia (opcjonalnie)"
                name="hideIssue"
                placeholder="Opisz problem"
              />
            </div>
            <div className="d-flex align-items-center gap-3 p-3 borderTop">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                {order.delivery.isHidden ? "Zmień" : "Ukryj punkt"}
                {isSubmitting && "..."}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
