import HRMenuIcon from "assets/images/humanResourcesModule.svg";
import group from "assets/images/moduleNavigationIcons/group.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { dashboard } from "./routes/dashboard";
import { users } from "./routes/users";
import { employees } from "./routes/employees";
import favicon from "assets/images/favicons/fv_hr.svg";
import { drivers } from "./routes/drivers";

export const humanResources: ModuleNavigation = {
  favicon,
  color: "#7694FF",
  navigationConfig: {
    label: "Kadry",
    icon: { src: group, background: "#1A2679" },
  },
  menuDropdownIcon: HRMenuIcon,
  name: "HUMAN_RESOURCES",
  url: "/human-resources",
  dashboard,
  navigationSections: [users, employees, drivers],
};
