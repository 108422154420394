import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { ProductLight } from "api/products/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";

interface Props {
  close: () => void;
  product: ProductLight;
}

export const PanelHeader = ({ close, product }: Props) => {
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <RightPanelHeader>
      <div className="d-flex w-100 align-items-center">
        {isTriggerElementHidden && (
          <SlidingHeaderWrapper className="nowrap">{product.name}</SlidingHeaderWrapper>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </div>
    </RightPanelHeader>
  );
};
