import { postManufacturingSettingsPreviousStage } from "api/manufacturingNew/calls";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";
import { AddPreviousStageToManufacturingSettings } from "api/manufacturingNew/models";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import { Assign } from "utility-types";
import cuid from "cuid";
import { getAnyErrorKey } from "utilities";
import { ManufacturingStage } from "api/manufacturing/schemas/models";

export const useAddPreviousStage = () => {
  return useMutation(
    ({
      position,
      setting,
      stage,
    }: Assign<
      AddPreviousStageToManufacturingSettings,
      { manufacturingStageId: UUID; stageName: string }
    >) => {
      return postManufacturingSettingsPreviousStage({ position, setting, stage });
    },
    ({ queryClient, toastr, queryUtils }) => ({
      onMutate: args => {
        return queryUtils.handleMutate<ManufacturingStage>(
          manufacturingNewKeys.manufacturingStage(String(args.manufacturingStageId)),
          draft => {
            draft.settings.visiblePreviousStages.push({
              id: cuid(),
              isVisible: true,
              position: args.position,
              stage: {
                id: args.stage,
                name: args.stageName,
              },
            });
          },
        );
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          manufacturingNewKeys.manufacturingStage(String(variables.manufacturingStageId)),
        );
      },
      onError: (error, _, context) => {
        const prevPanel = context as ManufacturingStage;
        queryUtils.rollback(
          manufacturingNewKeys.manufacturingStage(String(prevPanel.id)),
          prevPanel,
        );
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};
