import { dateFns } from "./utilities";

const getDate = () => dateFns.format(new Date(), "dd-MM-yyyy-HH-mm");

const formatSignature = (signature: string) => {
  return signature.replace(/\//g, "-");
};

export const fileFactoryUtils = {
  formatSignature,
  getDate,
};
