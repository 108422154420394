import { CURRENCY_TYPE, EmployeeKindExtended } from "CONSTANTS";
import { EmployedPlace } from "api/manufacturingNew/models";
import { ISODate, ISODateTime, UUID } from "api/types";
import { UserWithInitials } from "api/users/models";

export enum Transaction {
  ADVANCE_PAYMENT = "ADVANCE_PAYMENT",
  WITHDRAWAL = "WITHDRAWAL",
}

export enum MethodOfSettlement {
  FIXED_SALARY = "FIXED_SALARY",
  PIECEWORK = "PIECEWORK",
}

export interface ManufacturingEmployeeListItem extends UserWithInitials {
  dateOfEmployment: ISODate;
  email: string;
  employedPlace: EmployedPlace;
  isDeleted: boolean;
  kind: EmployeeKindExtended;
  number: string;
  phone: string;
  user: UserWithInitials;
  wallet: {
    balance: number;
    id: UUID;
    latestPayment: {
      amount: number;
      createdAt: ISODateTime;
      currency: CURRENCY_TYPE;
      transactionType: Transaction;
    } | null;
  } | null;
}

export interface ManufacturingEmployeeDetails extends UserWithInitials {
  dateOfEmployment: ISODate | null;
  email: string;
  employedPlace: EmployedPlace;
  isDeleted: boolean;
  kind: EmployeeKindExtended;
  methodOfSettlement: MethodOfSettlement;
  number: string;
  phone: string;
  user: number;
  wallet: {
    balance: number;
    currency: CURRENCY_TYPE;
    id: UUID;
    unconfirmedTransactions: number;
  } | null;
}
