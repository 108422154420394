import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiShelves } from "components/miloDesignSystem/atoms/icons/MdiShelves";
import { LongHeldPackagesRouting } from "routes/wms/LongHeldPackagesRouting";

export const longHeldPackages: ModuleLink = {
  url: "long-held-packages",
  label: "Meble długo trzymane w magazynie",
  icon: MdiShelves,
  routing: LongHeldPackagesRouting,
};
