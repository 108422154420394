import calculateIcon from "assets/images/calculatePink.svg";
import accountancyIcon from "assets/images/support.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import favicon from "assets/images/favicons/fv_help.svg";

export const support: ModuleNavigation = {
  favicon,
  color: "#b65eff",
  navigationConfig: {
    label: "Wsparcie",
    icon: { src: calculateIcon, background: "#431d87" },
  },
  menuDropdownIcon: accountancyIcon,
  name: "SUPPORT",
  url: "/support",
  navigationSections: [],
};
