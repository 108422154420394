import { ManufacturingSmsStatus } from "api/manufacturing/models";
import { MdiAvgPace } from "components/miloDesignSystem/atoms/icons/MdiAvgPace";
import { MdiCallMissed } from "components/miloDesignSystem/atoms/icons/MdiCallMissed";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { TagProps } from "components/miloDesignSystem/atoms/tag";

const customerConfirmationDict: Record<ManufacturingSmsStatus, TagProps<string>> = {
  CONFIRMED: { startIcon: MdiCheck, label: "potwierdzono", type: "filled", variant: "success" },
  DECLINED: { startIcon: MdiCallMissed, label: "odrzucono", type: "filled", variant: "danger" },
  NOT_SENT: { label: "nie wysłano", type: "outlined", variant: "deepPurple400" },
  PENDING: { startIcon: MdiAvgPace, label: "oczekuje", type: "filled", variant: "warning" },
};

export const callCenterOrdersUtils = {
  customerConfirmationDict,
};
