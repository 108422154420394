import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import indexesIcon from "assets/images/moduleNavigationIcons/chair.svg";
import { SoldIndexesRouting } from "routes/finances/SoldIndexes";

export const soldIndexes: ModuleLink = {
  url: "sold-indexes",
  label: "Sprzedane indeksy",
  icon: indexesIcon,
  routing: SoldIndexesRouting,
};
