import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { LongHeldPackage } from "api/unique-packages/models";
import { parseLocation } from "utilities/parseLocation";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Link } from "components/miloDesignSystem/atoms/link";
import { uniquePackagesAction } from "api/unique-packages/actions";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";

export const useLongHeldPackagesColumns = () => {
  return useCreateTableColumns<LongHeldPackage>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "nazwa",
        size: 350,
        typographyProps: { fontSize: "16", fontWeight: "700" },
      }),
      columnHelper.accessor(row => `${row.position}/${row.totalPackagesInCollection}`, {
        header: "nr paczki / z ilu paczek",
        size: 140,
        cell: info => {
          const _package = info.getValue();
          return (
            <Typography className="text-center w-100" fontSize="14" fontWeight="600">
              {_package}
            </Typography>
          );
        },
      }),
      columnHelper.text(
        row => (row.supplier && Boolean(row.supplier.name) ? row.supplier.name : null),
        {
          header: "kontrahent",
          size: 120,
          typographyProps: {
            fontSize: "14",
          },
        },
      ),
      columnHelper.date(row => row.setAsReceivedAt, {
        header: "od kiedy w mag.?",
        size: 98,
        typographyProps: { fontSize: "14", fontWeight: "500" },
      }),
      columnHelper.accessor(row => row, {
        header: "dni",
        size: 50,
        cell: info => {
          const _package = info.getValue();
          return (
            <Typography fontSize="14" fontWeight="700">
              {_package.daysInWarehouse}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.order, {
        header: "zamówienie",
        size: 130,
        cell: info => {
          const order = info.getValue();
          if (!order) return <EmptyValue fontSize="14" fontWeight="500" />;
          return (
            <div onClick={event => event.stopPropagation()}>
              <Link
                fontSize="14"
                fontWeight="500"
                to={`/orders/active/list/all?panelId=${order.id}`}
              >
                {order.signature}
              </Link>
            </div>
          );
        },
      }),
      columnHelper.text(row => (row.location ? parseLocation(String(row.location)).name : null), {
        header: "lokalizacja",
        size: 110,
        typographyProps: { fontSize: "14", fontWeight: "800" },
      }),
      columnHelper.accessor(row => row, {
        header: "czy rozliczono?",
        size: 100,
        cell: info => {
          const _package = info.getValue();
          return (
            <div onClick={event => event.stopPropagation()}>
              <Checkbox.Async
                checked={_package.isSettled}
                mutationHook={uniquePackagesAction.usePatchLongHeldPackage}
                size="sx"
                transformQueryData={isSettled => ({
                  id: _package.id,
                  toUpdate: {
                    isSettled,
                  },
                })}
              />
            </div>
          );
        },
      }),
    ];
  });
};
