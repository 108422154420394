import cx from "classnames";
import styles from "../LeftPanel.module.css";
import { Route } from "api/routes/models";
import { ToggleHookState, useQueryUtils, useSalesSettings } from "hooks";
import {
  AsyncInput,
  ErrorMessage,
  Modal,
  Select,
  StatusHandler,
  StatusHandlerHelpers,
} from "components/utils";
import { Order, OrderPaymentSource, OrderPaymentStatus, OrderPaymentType } from "api/orders/models";
import { Sales } from "api/milo-settings/models";
import { availablePaymentTypesDict, paymentTypeDict } from "CONSTANTS";
import { patchPayment } from "api/payments/calls";
import { getAnyErrorKey, getPaymentSourceBasedOnPaymentType } from "utilities";
import { Button } from "components/common";
import { routeKeys } from "api/keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { patchDelivery } from "api/deliveries/calls";
import { orderConstants } from "constants/orders";

interface Props {
  modal: ToggleHookState;
  order: Route["orders"][number];
  route: Route;
}

interface UpdatePayment {
  discount?: number;
  paidAmount?: number;
  status?: OrderPaymentStatus;
  type?: OrderPaymentType;
  source?: OrderPaymentSource;
}

export const OrderPaymentUpdateModal = ({ modal, order, route }: Props) => {
  const { paymentType } = useSalesSettings();
  const { handleMutate } = useQueryUtils();
  const allPaymentTypeOptions: {
    id: OrderPaymentType;
    name: string;
    disabled: boolean;
  }[] = Object.entries(paymentType).map(([id, value]) => {
    const type = paymentTypeDict[id as keyof Sales["paymentType"]] as OrderPaymentType;
    return {
      id: type,
      name: availablePaymentTypesDict[type],
      disabled: !value,
    };
  });

  const closeModal = () => {
    modal.close();
  };

  const updatePayment = (toUpdate: UpdatePayment) => {
    handleMutate<Route>(routeKeys.route(String(route.id)), draft => {
      const orderToUpdate = draft.orders.find(el => el.id === order.id);
      assertIsDefined(orderToUpdate);
      Object.assign(orderToUpdate.payment, toUpdate);
      return draft;
    });
  };

  const updateDelivery = (toUpdate: Order["delivery"]["amount"]) => {
    handleMutate<Route>(routeKeys.route(String(route.id)), draft => {
      const orderToUpdate = draft.orders.find(el => el.id === order.id);
      assertIsDefined(orderToUpdate);
      orderToUpdate.delivery.amount = toUpdate;
      return draft;
    });
  };

  async function handleUpdateDelivery(
    amount: Order["delivery"]["amount"],
    helpers: StatusHandlerHelpers,
  ) {
    helpers.startFetching();
    const [payload, error] = await patchDelivery(order.delivery.id, { amount });
    if (payload) {
      helpers.stopFetching();
      updateDelivery(amount);
    } else if (error) {
      helpers.stopFetching({ message: getAnyErrorKey(error) });
    }
  }

  async function handleUpdatePayment(toUpdate: UpdatePayment, helpers: StatusHandlerHelpers) {
    const [payload, error] = await patchPayment(order.payment.id, toUpdate);
    if (payload) {
      helpers.stopFetching();
      updatePayment(toUpdate);
    } else if (error) {
      helpers.stopFetching({ message: getAnyErrorKey(error) });
    }
  }

  return (
    <Modal
      isOpen={modal.isOpen}
      close={closeModal}
      overrides={{
        container: {
          className: cx(styles.orderUpdateModalContainer, styles.orderPaymentUpdateModalMargin),
        },
      }}
    >
      {order.payment ? (
        <div>
          <div className="font-bold fs-18 mb-4">Edytuj dane płatności</div>
          <div className="font-bold fs-14 mb-2 text-color-grey">Zamówienie</div>
          <div className="font-bold fs-14 mb-3">{order.signature}</div>
          <div className="fs-14 font-bold text-color-grey mb-1">Dane płatności</div>
          <div>
            <div className="my-3">
              <StatusHandler>
                {helpers => (
                  <AsyncInput
                    look="common"
                    inProgress={helpers.isFetching}
                    disabled={helpers.isFetching}
                    value={order.payment.discount}
                    error={helpers.errors.message}
                    label="Rabat"
                    onChange={value => handleUpdatePayment({ discount: value }, helpers)}
                  />
                )}
              </StatusHandler>
            </div>
            <div className="mt-3">
              <StatusHandler>
                {helpers => (
                  <AsyncInput
                    look="common"
                    inProgress={helpers.isFetching}
                    disabled={helpers.isFetching}
                    value={order.delivery.amount}
                    error={helpers.errors.message}
                    label="Transport"
                    onChange={value => handleUpdateDelivery(value.toString(), helpers)}
                  />
                )}
              </StatusHandler>
            </div>
            <div className="mt-3">
              <StatusHandler>
                {helpers => (
                  <AsyncInput
                    look="common"
                    inProgress={helpers.isFetching}
                    disabled={helpers.isFetching}
                    value={order.payment.paidAmount}
                    error={helpers.errors.message}
                    label="Opłacona kwota"
                    onChange={value => handleUpdatePayment({ paidAmount: value }, helpers)}
                  />
                )}
              </StatusHandler>
            </div>
            <div className={`mt-3 ${styles.selects}`}>
              <div>
                <div className="fs-14 font-bold text-color-grey mb-1">Status płatności</div>
                <StatusHandler>
                  {helpers => (
                    <div onClick={e => e.stopPropagation()}>
                      <Select
                        onChange={item =>
                          item &&
                          handleUpdatePayment(
                            { status: item.id as Order["payment"]["status"] },
                            helpers,
                          )
                        }
                        disabled={helpers.isFetching}
                        defaultSelected={order.payment.status}
                        items={orderConstants.paymentStatusItems}
                        size="regular"
                        mode="common"
                      />
                      <ErrorMessage type="text" text={helpers.errors.message} />
                    </div>
                  )}
                </StatusHandler>
              </div>
              <div>
                <div className="fs-14 font-bold text-color-grey mb-1">Forma płatności</div>
                <StatusHandler>
                  {helpers => (
                    <div onClick={e => e.stopPropagation()}>
                      <Select
                        mode="common"
                        onChange={item =>
                          item &&
                          handleUpdatePayment(
                            {
                              type: item.id as Order["payment"]["type"],
                              source: getPaymentSourceBasedOnPaymentType(item.id),
                            },
                            helpers,
                          )
                        }
                        disabled={helpers.isFetching}
                        overwrites={{ dropdownList: { className: styles.dropdownList } }}
                        itemToDisplay={({ name, disabled, id }, selectedItem) => (
                          <div
                            className={cx({
                              [styles.disabled]: disabled,
                              [styles.selected]: id === selectedItem?.id,
                            })}
                          >
                            {name}
                          </div>
                        )}
                        defaultSelected={order.payment!.type}
                        items={allPaymentTypeOptions}
                        size="regular"
                      />
                      <ErrorMessage type="text" text={helpers.errors.message} />
                    </div>
                  )}
                </StatusHandler>
              </div>
            </div>
            <div className="mt-3">
              <Button kind="primary" size="medium" onClick={closeModal}>
                Gotowe
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="font-bold fs-18 mb-4">Edytuj dane płatności</div>
      )}
    </Modal>
  );
};
