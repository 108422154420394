import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { patchManufacturingSettingsPreviousStage } from "api/manufacturingNew/calls";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";
import { VisiblePreviousStage } from "api/manufacturingNew/models";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import { PartialOf } from "typeUtilities";
import { getAnyErrorKey } from "utilities";

export const useTogglePreviousStageVisibility = () => {
  return useMutation(
    ({
      stage,
      toUpdate,
    }: {
      stage: UUID;
      manufacturingStageId: UUID;
      toUpdate: PartialOf<VisiblePreviousStage>;
    }) => {
      return patchManufacturingSettingsPreviousStage(toUpdate, stage);
    },
    ({ queryUtils, toastr }) => ({
      onMutate: args => {
        return queryUtils.handleMutate<ManufacturingStage>(
          manufacturingNewKeys.manufacturingStage(String(args.manufacturingStageId)),
          draft => {
            const previousStageToUpdate = draft.settings.visiblePreviousStages.find(
              visiblePreviousStage => visiblePreviousStage.id === args.stage,
            );
            if (!previousStageToUpdate) return;

            if (args.toUpdate.isVisible !== undefined) {
              previousStageToUpdate.isVisible = args.toUpdate.isVisible;
            }
          },
        );
      },
      onError: (error, _, prevPanel) => {
        queryUtils.rollback(
          manufacturingNewKeys.manufacturingStage(String(prevPanel?.id)),
          prevPanel,
        );
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};
