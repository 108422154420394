import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { colorPalette } from "../colorsPalette";
import { LineProps } from "./types";

export const Line = ({ className, color, height, lineStyle }: LineProps) => {
  return <StyledLine className={className} color={color} height={height} lineStyle={lineStyle} />;
};

const StyledLine = styled.div<Pick<LineProps, "color" | "height" | "lineStyle">>`
  border: ${props => (props.height ? `${props.height}px` : `1px`)};
  border-style: ${props => props.lineStyle};
  width: 100%;
  ${props =>
    props.color
      ? css`
          border-color: ${colorPalette[props.color]};
        `
      : css`
          border-color: var(--neutralBlack24);
        `}
`;
