import { ISODate, ISODateTime, ISOTime, UUID } from "api/types";
import { UserWithInitials } from "api/users/models";
import { Address, BaseRamp, InventoryUser, PackageStockStatus } from "../models";

export enum ReturnStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}

export enum ReturnLineItemType {
  ORDER = "ORDER",
  INDEX = "INDEX",
  PACKAGE = "PACKAGE",
}

export interface CreateReturn {
  routeId: string;
}

export interface ReturnListItem {
  actualTimeOfArrival: ISODateTime | null;
  collaborators: UserWithInitials[];
  duration: string;
  estimatedDateOfArrivalDate: ISODate | null;
  estimatedDateOfArrivalTime: ISOTime | null;
  finishedAt: ISODateTime | null;
  id: UUID;
  packagesInReturnCount: number;
  pauseEndedAt: ISODateTime | null;
  pauseStartedAt: ISODateTime | null;
  qrCode: string;
  ramp: BaseRamp | null;
  returnedPackagesCount: number;
  signature: string;
  startedAt: ISODateTime | null;
  status: ReturnStatus;
  suppliers: {
    id: number | string;
    name: string;
    type: string;
  }[];
}

export interface ReturnDetails extends ReturnListItem {}

export interface StartOrFinishReturnPayload {
  wmsReturnId: UUID;
}

export interface ReturnLineItem {
  address: Address;
  buyer: {
    firstName: string;
    lastName: string;
  } | null;
  collaborators: UserWithInitials[];
  estimatedDateOfArrival: ISODate | null;
  id: UUID;
  inStockAt: ISODateTime | null;
  locations: string[];
  name: string;
  numberOfPackageGroups: {
    inStock: number;
    total: number;
  };
  position: number;
  signature: string;
  sourceExternalId: string;
  status: ReturnStatus;
  suppliers: {
    id: string;
    name: string;
  }[];
  type: ReturnLineItemType;
  wmsReturn: UUID;
}

export interface ReturnLineItemPackageGroup {
  id: UUID;
  name: string;
  packages: {
    addedToStockAt: ISODateTime | null;
    collaborator: UserWithInitials;
    id: UUID;
    location: string;
    name: string;
    status: ReturnStatus;
    stockStatus: PackageStockStatus;
  }[];
  status: ReturnStatus;
}

export interface ReturnLineItemDetails {
  address: Address;
  buyer: {
    firstName: string;
    lastName: string;
  } | null;
  collaborators: UserWithInitials[];
  createdAt: ISODateTime;
  id: UUID;
  locations: string[];
  name: string;
  packagesGroups: ReturnLineItemPackageGroup[];
  position: number;
  signature: string;
  status: ReturnStatus;
  suppliers: {
    city: string;
    countryCode: string;
    id: number;
    name: string;
    postCode: string;
    street: string;
    type: string;
  }[];
  type: ReturnLineItemType;
  updatedAt: ISODateTime | null;
  wmsReturn: {
    id: UUID;
    ramp: BaseRamp | null;
    signature: string;
    status: ReturnStatus;
  };
}

export interface NormalizedReturnPackageGroup {
  addedToStockAt: ISODateTime | null;
  collaborator: UserWithInitials | null;
  id: UUID;
  location: string | null;
  name: string;
  stockStatus: PackageStockStatus | null;
  subRows: NormalizedReturnPackageGroup[];
}

export interface ReturnWorkspaceState {
  user: UserWithInitials;
  wmsReturnId: UUID;
}

export interface ReturnWorkspace {
  user: InventoryUser;
  wmsReturn: {
    ramp: BaseRamp | null;
    signature: string;
    status: ReturnStatus;
    totalProgress: number;
  };
  wmsReturnGroups: WmsReturnGroup[];
}

export interface WmsReturnGroup {
  address: Address;
  id: UUID;
  packages: WmsReturnGroupPackage[];
  position: number;
  signature: string;
  status: ReturnStatus;
  suppliers: {
    id: string | number;
    name: string;
    type: string;
  }[];
}

export interface WmsReturnGroupPackage {
  addedToStockAt: ISODateTime | null;
  id: UUID;
  location: string;
  name: string;
  stockStatus: PackageStockStatus;
}
