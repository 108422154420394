import { PopupMenu } from "components/common/popupMenu";
import popUpstyles from "pages/tradingDocuments/shared/TradingDocumentsLists.module.css";
import history from "assets/images/history.svg";
import fileCopy from "assets/images/fileCopy.svg";
import editIcon from "assets/images/edit.svg";
import { Button } from "components/common";
import moreOptionsIcon from "assets/images/threeDots.svg";
import cx from "classnames";
import { Link } from "react-router-dom";
import { DeleteTradingDocumentBtn } from "../../deleteTradingDocumentBtn/DeleteTradingDocumentBtn";
import { TradingDocument } from "api/trading-documents/models";
import { getInvoiceLabel, tradingDocumentFileFactory } from "api/trading-documents/calls";
import drawIcon from "assets/images/draw.svg";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";

interface Props {
  close: () => void;
  invoice: TradingDocument;
  panelId: string;
}

export const MoreInfoButton = ({ close, invoice, panelId }: Props) => {
  const type = getInvoiceLabel(invoice.invoiceType, invoice.type);

  return (
    <PopupMenu
      customStyles={popUpstyles}
      customButton={
        <Button
          data-for="external-options"
          data-event="click"
          data-event-off="mouseleave"
          data-tip="2"
          kind="transparent-black"
          size="square-s"
        >
          <div className="btnBase btnBase16 btnBaseSmall">
            <img alt="Więcej opcji" src={moreOptionsIcon} />
          </div>
        </Button>
      }
      dataFor="external-options"
    >
      <div className={popUpstyles.optionList}>
        {invoice.invoiceType === "SALES" && (
          <div className={cx("px-3 py-2", popUpstyles.optionRow)}>
            {invoice.kind === "OWN_ENTITY" && invoice.status === "WORK_IN_PROGRESS" ? (
              <Link
                title="Edytuj fakturę"
                to={`/finances/create-work-in-progress-document/${invoice.id}`}
                className="d-flex align-items-center justify-content-between gap-2"
              >
                <div className="d-flex align-items-center justify-content-between gap-2">
                  <div className="d-flex align-items-center gap-2">
                    <img src={editIcon} alt="Edytuj" />
                    <div className="body-14-600">Edytuj</div>
                  </div>
                </div>
              </Link>
            ) : (
              <div
                className={cx(
                  popUpstyles.disabledLink,
                  "d-flex align-items-center justify-content-between gap-2 cursor-not-allowed",
                )}
              >
                <div className="d-flex align-items-center gap-2">
                  <img src={editIcon} alt="Edytuj" />
                  <div className="body-14-600">Edytuj</div>
                </div>
              </div>
            )}
          </div>
        )}
        {invoice.invoiceType !== "PROFORMA" && (
          <FileDownloadHandler
            factoryFn={() => tradingDocumentFileFactory.salesInvoiceDuplicatePdf(invoice)}
            type="pdf"
          >
            {({ download, isLoading }) => (
              <div className={cx("px-3 py-2 w-100", popUpstyles.optionRow)} onClick={download}>
                <div className="d-flex align-items-center gap-2">
                  <img src={fileCopy} alt="Duplikat" />
                  <div className="body-14-600">Duplikat faktury</div>
                  {isLoading && <Spinner size={16} />}
                </div>
              </div>
            )}
          </FileDownloadHandler>
        )}
        <hr style={{ margin: "5px 0" }} />
        {invoice.invoiceType === "PURCHASE" && (
          <div className={cx("px-3 py-2 w-100", popUpstyles.optionRow)}>
            <Link
              title="Połącz fakturę z PZ"
              to={`/finances/purchases/connect-invoice/${invoice.id}`}
              className="d-flex align-items-center justify-content-between gap-2"
            >
              <div className="d-flex align-items-center gap-2 btnBase16">
                <img alt="Połącz fakturę z PZ" src={drawIcon} />
                <div className="body-14-600">Połącz fakturę z PZ</div>
              </div>
            </Link>
          </div>
        )}
        <div className={cx("px-3 py-2 w-100", popUpstyles.optionRow)}>
          <Link
            to={{
              pathname: `/finances/${type}/${panelId}/history`,
              state: { invoice },
            }}
            className="d-flex align-items-center justify-content-between gap-2"
          >
            <div className="d-flex align-items-center gap-2">
              <img src={history} alt="Historia" />
              <div className="body-14-600">Pokaż historię faktury</div>
            </div>
          </Link>
        </div>
        <hr style={{ margin: "5px 0" }} />
        <DeleteTradingDocumentBtn
          close={close}
          tradingDocumentId={invoice.id}
          tradingDocumentSignature={invoice.signature}
          tradingDocumentStatus={invoice.status}
        />
      </div>
    </PopupMenu>
  );
};
