import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import deleteRed from "assets/images/delete_red.svg";
import cx from "classnames";
import { ManufacturingSchema } from "api/manufacturingNew/models";
import popUpStyles from "components/common/moreInfoButton/MoreInfoButton.module.css";
import { MoreInfoButton } from "components/common/moreInfoButton/MoreInfoButton";
import { useQueryClient } from "react-query";
import { useMutation } from "hooks/useMutation";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";
import { useConfirmModal } from "hooks";
import { deleteManufacturingSchema } from "api/manufacturingNew/calls";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";

interface Props {
  close: () => void;
  manufacturingSchema: ManufacturingSchema;
}

export const PanelHeader = ({ close, manufacturingSchema }: Props) => {
  const queryClient = useQueryClient();
  const confirmModal = useConfirmModal();
  const deleteMutation = useMutation(deleteManufacturingSchema, {
    onSuccess: () => {
      queryClient.invalidateQueries(manufacturingNewKeys.manufacturingSchemas());
      close();
    },
  });
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <RightPanelHeader>
      <div className="d-flex w-100 align-items-center">
        {isTriggerElementHidden && (
          <SlidingHeaderWrapper className="nowrap">{manufacturingSchema.name}</SlidingHeaderWrapper>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <span className="divider line-divider" />
          <MoreInfoButton
            children={
              <>
                <button
                  disabled={deleteMutation.isLoading}
                  onClick={() => {
                    confirmModal.open({
                      text: `Czy na pewno chcesz usunąć schemat ${manufacturingSchema.signature}?`,
                      confirmText: "Usuń",
                      callback: () => deleteMutation.mutate(manufacturingSchema.id),
                    });
                  }}
                  className={cx("px-3 py-2 w-100", popUpStyles.optionRow)}
                >
                  <div className="d-flex align-items-center justify-content-between gap-2">
                    <div className="d-flex align-items-center gap-2">
                      <img src={deleteRed} alt="Usuń" />
                      <div className="body-14-600 text-red-4">Usuń</div>
                    </div>
                  </div>
                </button>
              </>
            }
          />
          <span className="divider line-divider" />
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </div>
    </RightPanelHeader>
  );
};
