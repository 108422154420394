import { filterFactory } from "components/utils/withFilters";

export const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Search,
    searchBy: "warehouses",
    label: "magazyn",
    multiple: true,
    name: "warehouses",
  },
  {
    type: FilterType.DateRange,
    label: "Przyjęto / Wydano",
    name: ["inAtFrom", "inAtTo"],
    additionalKeys: ["outAtFrom", "outAtTo"],
    showMonths: true,
  },
  {
    type: FilterType.Date,
    label: "data wartości magazynu",
    name: "inAt",
  },
]);
