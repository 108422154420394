import { manufacturingActions } from "api/manufacturing/actions";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler/FileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useDrawer } from "hooks/useDrawer";

export const PanelHeader = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { close } = useDrawer("manufacturingManufacturerOrders");
  const { data: order } = manufacturingActions.useManufacturingOrder(panelId);
  const { isTriggerElementHidden } = useScrollableContext();

  if (!order) return null;

  return (
    <RightPanelHeader>
      {isTriggerElementHidden ? (
        <SlidingHeaderWrapper>
          <Typography fontSize="16" fontWeight="600">
            {order.signature}
          </Typography>
        </SlidingHeaderWrapper>
      ) : (
        <div />
      )}
      <div className="d-flex align-items-center justify-content-end gap-1">
        <FileDownloadHandler
          factoryFn={() =>
            manufacturingFileFactory.manufacturingMaterialOrders(order.id, order.signature)
          }
          type="pdf"
        >
          {({ download, isLoading }) => (
            <Tooltip title="Pobierz etykietę">
              <IconButton
                icon={MdiQrCode}
                isLoading={isLoading}
                onClick={event => {
                  event.stopPropagation();
                  download();
                }}
                variant="transparent"
              />
            </Tooltip>
          )}
        </FileDownloadHandler>
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};
