import { format, parse } from "date-fns";

export const months = [
  "styczeń",
  "luty",
  "marzec",
  "kwiecień",
  "maj",
  "czerwiec",
  "lipiec",
  "sierpień",
  "wrzesień",
  "październik",
  "listopad",
  "grudzień",
];

export const getMonthName = (date: string): { year: string; month: string } => {
  const parsedDate = parse(date, "yyyy-MM", new Date());

  return {
    year: format(parsedDate, "yyyy"),
    month: months[parseInt(format(parsedDate, "MM")) - 1],
  };
};
