import { Order } from "api/orders/models";
import { InfoLabel } from "../infoLabel";
import { marketplaceStatusesActions } from "api/marketplace-statuses/actions";
import { queryString } from "utilities";
import { DesiredStatusChoices, MarketplaceStatusAttempt } from "api/marketplace-statuses/models";
import { marketplaceStatusesUtilities } from "pages/orders/ordersList/rightPanel/marketplaceStatusesSection/utilities";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  RetryButton,
  shouldRetryBeDisplayed,
} from "pages/orders/ordersList/rightPanel/marketplaceStatusesSection/MarketplaceStatusesSection";

interface Props {
  orderId: Order["id"];
}

export const MarketplaceShipping = ({ orderId }: Props) => {
  const search = queryString.stringify({ orderId, desiredStatus: DesiredStatusChoices.SENT });
  const { data, isLoading } = marketplaceStatusesActions.useGetMarketplaceStatusesAttempts(search);

  const lastStatus = data[0];
  if (isLoading) {
    return (
      <InfoLabel title="status przekazania nr przesyłki" className="mt-3">
        <Spinner size={20} />
      </InfoLabel>
    );
  }

  if (!lastStatus) {
    return (
      <InfoLabel title="status przekazania nr przesyłki" className="mt-3">
        <Typography fontSize="14" fontWeight="600">
          brak statusu
        </Typography>
      </InfoLabel>
    );
  }

  return (
    <InfoLabel title="status przekazania nr przesyłki" className="mt-3">
      {marketplaceStatusesUtilities.getProcessingStatusTag(lastStatus.statusEvent.processingStatus)}{" "}
      <ErrorStatus status={lastStatus} />
    </InfoLabel>
  );
};

const ErrorStatus = ({ status }: { status: MarketplaceStatusAttempt }) => {
  if (!shouldRetryBeDisplayed(status)) return null;
  return (
    <RetryButton buttonVariant="transparent" statusEventId={status.statusEvent.id}>
      Synchronizuj
    </RetryButton>
  );
};
