import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { UUID } from "api/types";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";

interface Props {
  close: () => void;
  id: UUID;
  signature: string;
}

export const CancelManufacturingItem = ({ close, id, signature }: Props) => {
  const cancelManufacturingItemMutation = manufacturingUnitsActions.useCancelManufacturingItem(
    close,
  );

  return (
    <Modal
      close={close}
      isOpen
      width={480}
      title={
        <Typography fontSize="20" fontWeight="700">
          Oznacz zlecenie jako anulowane
        </Typography>
      }
    >
      <div className="px-3">
        <Typography fontSize="16" fontWeight="600">
          Proces produkcji już się rozpoczął, czy na pewno chcesz anulować zlecenie {signature}?
        </Typography>
        <div className="d-flex align-items-center gap-2 py-3">
          <Button className="text-uppercase" onClick={close} size="large" variant="transparent">
            Nie
          </Button>
          <Button
            className="text-uppercase"
            isLoading={cancelManufacturingItemMutation.isLoading}
            onClick={() => cancelManufacturingItemMutation.mutate(id)}
            size="large"
            variant="deepPurple"
          >
            Tak
          </Button>
        </div>
      </div>
    </Modal>
  );
};
