import { filterFactory } from "components/utils/withFilters";
import { useSelector } from "hooks";

export const FilterList = () => {
  const stages = useSelector(store => store.partials.stages);

  return filterFactory(({ FilterType }) => {
    return [
      {
        type: FilterType.Select,
        kind: "label",
        label: "Potrzebny w etapie",
        multiple: true,
        name: "schemaStages",
        options: stages.map(stage => ({
          label: stage.name,
          value: stage.id,
        })),
      },
    ];
  });
};
