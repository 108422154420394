import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  tradingDocument: TradingDocument;
}

export const BuyerInfo = ({ tradingDocument }: Props) => {
  return (
    <RightPanelSection title="Nabywca">
      <div className="pb-2 flex-1 w-50 d-flex flex-column gap-1">
        <TextField.Async
          label="Nazwa firmy"
          disabled={tradingDocument.status === "CONFIRMED"}
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz nazwę firmy"
          size="small"
          transformQueryData={buyerCompanyName => ({
            id: tradingDocument.id,
            buyerCompanyName,
          })}
          value={tradingDocument.buyerCompanyName}
        />
        <TextField.Async
          label="Imię"
          mutationHook={usePatchTradingDocumentMutation}
          disabled={tradingDocument.status === "CONFIRMED"}
          placeholder="Wpisz imię"
          size="small"
          transformQueryData={buyerFirstName => ({
            id: tradingDocument.id,
            buyerFirstName,
          })}
          value={tradingDocument.buyerFirstName}
        />
        <TextField.Async
          label="Nazwisko"
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz nazwisko"
          disabled={tradingDocument.status === "CONFIRMED"}
          size="small"
          transformQueryData={buyerLastName => ({
            id: tradingDocument.id,
            buyerLastName,
          })}
          value={tradingDocument.buyerLastName}
        />
        <TextField.Async
          label="Adres"
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz adres (ulicę)"
          disabled={tradingDocument.status === "CONFIRMED"}
          size="small"
          transformQueryData={buyerStreet => ({
            id: tradingDocument.id,
            buyerStreet,
          })}
          value={tradingDocument.buyerStreet}
        />
        <div className="d-flex gap-2 justify-content-between">
          <TextField.Async
            label="Kod pocztowy"
            mutationHook={usePatchTradingDocumentMutation}
            placeholder="Wpisz kod pocztowy"
            disabled={tradingDocument.status === "CONFIRMED"}
            size="small"
            transformQueryData={buyerPostCode => ({
              id: tradingDocument.id,
              buyerPostCode,
            })}
            value={tradingDocument.buyerPostCode}
          />
          <TextField.Async
            label="Miasto"
            mutationHook={usePatchTradingDocumentMutation}
            placeholder="Wpisz miasto"
            disabled={tradingDocument.status === "CONFIRMED"}
            size="small"
            transformQueryData={buyerCity => ({
              id: tradingDocument.id,
              buyerCity,
            })}
            value={tradingDocument.buyerCity}
          />
        </div>
        <TextField.Async
          label="Nr telefonu"
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz numer telefonu"
          disabled={tradingDocument.status === "CONFIRMED"}
          size="small"
          transformQueryData={buyerPhone => ({
            id: tradingDocument.id,
            buyerPhone,
          })}
          value={tradingDocument.buyerPhone}
        />
        <TextField.Async
          label="Email"
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz email"
          disabled={tradingDocument.status === "CONFIRMED"}
          size="small"
          transformQueryData={buyerEmail => ({
            id: tradingDocument.id,
            buyerEmail,
          })}
          value={tradingDocument.buyerEmail}
        />
        <TextField.Async
          label="NIP"
          mutationHook={usePatchTradingDocumentMutation}
          disabled={tradingDocument.status === "CONFIRMED"}
          placeholder="Wpisz NIP"
          size="small"
          transformQueryData={buyerTaxId => ({
            id: tradingDocument.id,
            buyerTaxId,
          })}
          value={tradingDocument.buyerTaxId}
        />
        <TextField.Async
          label="Nr konta bankowego"
          mutationHook={usePatchTradingDocumentMutation}
          disabled={tradingDocument.status === "CONFIRMED"}
          placeholder="Wpisz numer konta bankowego"
          size="small"
          transformQueryData={buyerBankAccountNumber => ({
            id: tradingDocument.id,
            buyerBankAccountNumber,
          })}
          value={tradingDocument.buyerBankAccountNumber}
        />
      </div>
    </RightPanelSection>
  );
};
