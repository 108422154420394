import { useEffect, useLayoutEffect, useRef } from "react";
import { useRedux, useSelector } from "hooks";
import apiFetch, { connectStoreToApiMiddleware } from "apiConnectors/fetchConnector";
import VERSION from "../../VERSION";
import { getUserInfo } from "api/users/calls";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { permittedUserTypes } from "CONSTANTS";

export const Initializer = () => {
  const [dispatch, { partials, ui, auth, settings: settingsStore }] = useRedux();
  const user = useSelector(state => state.auth.user);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const userTrigger = user?.id;
  const isFetched = useRef(false);
  const initialMount = useRef(true);

  /**
   * Connect state to api middleware so it could dispatch actions about idle/busy to the store
   */
  useEffect(() => {
    connectStoreToApiMiddleware(dispatch);
  }, [dispatch]);

  /**
   * We want to fetch partials immediately on init and then only if app is idle.
   * The point is to speed up other requests on page reload.
   */
  useEffect(() => {
    async function getUser() {
      const [user] = await getUserInfo();

      if (user) {
        if (!permittedUserTypes.includes(user.type)) {
          dispatch(partials.setPristine({ isPristine: true }));
          dispatch(auth.resetStore());
          return;
        }
        dispatch(auth.saveUser(user));
      }
    }

    if (isAuthenticated) {
      dispatch(settingsStore.fetchSettings());
    }

    if (isAuthenticated && initialMount.current) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, dispatch, isAuthenticated, settingsStore]);

  useEffect(() => {
    if (userTrigger && isFetched.current === false) {
      isFetched.current = true;
      dispatch(partials.fetchPartials());
    }
  }, [dispatch, userTrigger, partials]);

  // https://dev.to/flexdinesh/cache-busting-a-react-app-22lk
  // read a meta.json file on each refresh that isn't cached by the browser.
  // Block the interface if there is a version mismatch and force user to hard reload.
  useEffect(() => {
    async function handleVersionLock() {
      const [appMeta] = await apiFetch<{ appVersion: string }>({
        method: "GET",
        url: "/meta.json",
        origin: "/",
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
        },
      });
      if (appMeta) {
        if (appMeta.appVersion > VERSION.build) {
          console.log("app needs to be updated!");
          dispatch(ui.enableVersionLock());
        } else {
          console.log("app is up to date.");
          dispatch(ui.disableVersionLock());
        }
      } else {
        console.log("app version cannot be validated.");
      }
    }
    handleVersionLock();
  }, [ui, dispatch]);

  useEffect(() => {
    if (!userTrigger) {
      isFetched.current = false;
    }
  }, [userTrigger]);

  useEffect(() => {
    initialMount.current = false;
  }, []);

  useLayoutEffect(() => {
    const sheet = document.styleSheets[0];

    const colors = Object.entries(colorPalette).reduce((acc, [prefix, value]) => {
      return `${acc}; --${prefix}:${value}`;
    }, "");

    sheet.insertRule(`:root{ ${colors} }`);
  }, []);

  return null;
};
