import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiReturn } from "components/miloDesignSystem/atoms/icons/MdiReturn";
import { ReturnsRouting } from "routes/wms/ReturnsRouting";

export const returns: ModuleLink = {
  url: "returns",
  label: "Zwroty",
  icon: MdiReturn,
  routing: ReturnsRouting,
};
