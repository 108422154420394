import { Button } from "components/miloDesignSystem/atoms/button";
import { cx, queryString } from "utilities";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../../../../IndexDetails.module.css";
import { IndexStages } from "api/manufacturing/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardArrowDown } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowDown";
import { useState } from "react";
import { MdiKeyboardArrowUp } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowUp";
import { MdiEdit } from "components/miloDesignSystem/atoms/icons/MdiEdit";
import { StageDetails } from "./StageDetails";
import { useParams } from "react-router";
import { manufacturingActions } from "api/manufacturing/actions";
import { Tag } from "components/miloDesignSystem/atoms/tag";

export const Stage = ({
  stage,
  index,
}: {
  stage: IndexStages["stages"][number];
  index: number;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { indexId } = useParams<{ indexId: string }>();

  const { data } = manufacturingActions.useStageComponentItems(
    queryString.stringify({ stage: stage.stageId, index: indexId }),
    {
      onSuccess: payload => {
        if (!payload.length) {
          setIsEditing(true);
        }
      },
    },
  );

  const hasDeletedMaterials = data.some(recipeComponent => recipeComponent.recipe.isDeleted);

  return (
    <div
      className={cx(styles.stage, "p-3 mb-2", {
        [styles.stageWithRemovedMaterial]: hasDeletedMaterials,
      })}
    >
      <div className={"d-flex align-items-center justify-content-between flex-1"}>
        <div className="d-flex align-items-center gap-2">
          <Typography fontSize="10" fontWeight="700" color="neutralBlack48">
            Etap {index + 1}.
          </Typography>
          <Typography fontSize="18" fontWeight="700">
            {stage.name}
          </Typography>
        </div>
        <div className="d-flex align-items-center gap-2">
          {!isVisible && hasDeletedMaterials && <Tag label="Usunięto materiał" variant="warning" />}
          {isVisible && (
            <>
              {isEditing ? (
                <Button
                  disabled={!data.length}
                  size="medium"
                  variant="deepPurple"
                  onClick={() => setIsEditing(false)}
                  className="text-uppercase"
                >
                  Gotowe
                </Button>
              ) : (
                <IconButton
                  icon={MdiEdit}
                  variant="transparent"
                  onClick={() => setIsEditing(true)}
                />
              )}
            </>
          )}
          <IconButton
            icon={isVisible ? MdiKeyboardArrowUp : MdiKeyboardArrowDown}
            variant="transparent"
            onClick={() => setIsVisible(p => !p)}
          />
        </div>
      </div>
      {isVisible && <StageDetails isEditing={isEditing} stage={stage} />}
    </div>
  );
};
