import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./AddOrdersBySignaturesModal.module.css";
import { Formik } from "formik";
import { cx } from "utilities";
import { FormTextarea } from "components/utils";
import { useStateModal } from "hooks";
import { ResponseModal } from "./ResponseModal";
import { validationSchema } from "./validationSchema";
import { AddOrdersToUnloading, UnloadingDetails } from "api/wms/models";
import { Assign } from "utility-types";
import { useAddOrdersToUnloading } from "./useAddOrdersToUnloading";
import { AddOrdersToUnloadingResponse } from "api/orders/models";

interface Props {
  close: () => void;
  unloading: UnloadingDetails;
}

export type AddOrdersToUnloadingForm = Assign<
  Pick<AddOrdersToUnloading, "unloadingId">,
  { orders: string }
>;

export const AddOrdersBySignaturesModal = ({ close, unloading }: Props) => {
  const responseModal = useStateModal<AddOrdersToUnloadingResponse>();
  const handleSubmit = useAddOrdersToUnloading(responseModal, unloading);

  const initialValues: AddOrdersToUnloadingForm = {
    orders: "",
    unloadingId: unloading.id,
  };

  return (
    <>
      <Modal
        close={close}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Dodaj zamówienia do rozładunku
          </Typography>
        }
        width={480}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
              <div className="p-3 d-flex flex-column">
                <FormTextarea
                  autoFocus
                  name="orders"
                  label="Nr zamówień"
                  placeholder="Wklej kolejne numery zamówień odzielając je przecinkiem"
                  overrides={{ textarea: { className: styles.textarea } }}
                />
                <div className="d-flex align-items-center mt-3 gap-3">
                  <Button
                    className="text-uppercase"
                    onClick={close}
                    size="medium"
                    variant="transparent"
                  >
                    Anuluj
                  </Button>
                  <Button
                    className="text-uppercase"
                    isLoading={isSubmitting}
                    size="medium"
                    type="submit"
                    variant="deepPurple"
                  >
                    Dodaj do rozładunku{isSubmitting && "..."}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
      {responseModal.isOpen && responseModal.state && (
        <ResponseModal close={responseModal.close} state={responseModal.state} />
      )}
    </>
  );
};
