import styles from "../ColumnView.module.css";
import { cx } from "utilities";

interface Props {
  children: React.ReactNode;
  isDragOver?: boolean;
}

export const ColumnWrapper = ({ children, isDragOver }: Props) => {
  return (
    <div
      className={cx(styles.basicColumn, "d-flex flex-column flex-1 overflow-scroll p-2", {
        [styles.columnDraggedOver]: isDragOver,
      })}
    >
      {children}
    </div>
  );
};
