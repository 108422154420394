import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Courier } from "api/shipping/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { shippingServiceConstants } from "constants/shippingServiceConstants";

export const useShippingCouriersColumns = () => {
  return useCreateTableColumns<Courier>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "nazwa",
        size: 140,
      }),
      columnHelper.text(row => shippingServiceConstants[row.provider].name, {
        header: "serwis",
        size: 140,
      }),
      columnHelper.text(row => row.clientNumber, {
        header: "numer klienta",
        size: 150,
      }),
      columnHelper.accessor(row => row.isConnected, {
        header: "połączono",
        size: 130,
        cell: info => {
          if (!Boolean(info.getValue())) return "---";
          return <Tag label="Tak" variant="success" />;
        },
      }),
    ];
  });
};
