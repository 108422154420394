import { NormalizedReturnPackageGroup } from "api/wms/returns/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { wmsReturnConstants } from "constants/returns";
import { dateFns } from "utilities";
import { parseLocation } from "utilities/parseLocation";
import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const usePackageToReturnDetailsColumns = () => {
  return useCreateTableColumns<NormalizedReturnPackageGroup>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row.name, {
        header: "nazwa paczki",
        size: 330,
        cell: info => {
          const name = info.getValue();
          const isNested = info.row.depth;
          return (
            <Typography
              color={isNested ? "neutralBlack88" : "neutralBlack48"}
              fontSize="12"
              fontWeight="700"
              noWrap
            >
              {name || EMPTY_VALUE}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.location, {
        header: "lokalizacja",
        size: 100,
        cell: info => {
          const location = info.getValue();
          const isNested = info.row.depth;
          if (!isNested) return null;
          return (
            <Typography fontSize="14" fontWeight="700" noWrap>
              {parseLocation(location).name}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.stockStatus, {
        header: "status",
        size: 120,
        cell: info => {
          const status = info.getValue();
          const isNested = info.row.depth;
          if (!isNested) return null;
          if (!status) return <EmptyValue fontSize="16" fontWeight="700" />;
          return (
            <Tag
              label={wmsReturnConstants.packageGroupStatusObject[status].label}
              variant={wmsReturnConstants.packageGroupStatusObject[status].variant}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.addedToStockAt, {
        id: "when",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            kiedy?
          </Typography>
        ),
        size: 110,
        cell: info => {
          const isNested = info.row.depth;
          if (!isNested) return null;
          const addedToStockAt = info.getValue();
          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {addedToStockAt
                ? dateFns.formatRelative(new Date(addedToStockAt), "dd.MM.yyyy, H:mm")
                : EMPTY_VALUE}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.collaborator, {
        header: "kto?",
        size: 26,
        cell: info => {
          const isNested = info.row.depth;
          const collaborator = info.getValue();
          if (!isNested) return null;
          return (
            <div className="d-flex align-items-center pb-1">
              <Avatar size="sm" theme="light" user={collaborator} />
            </div>
          );
        },
      }),
    ];
  });
};
