import { customerActions } from "api/customers/actions";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Select } from "components/miloDesignSystem/molecules/select";
import { useSelector } from "hooks";
import { useParams } from "react-router";
import { assertIsDefined } from "utilities/assertIsDefined";

export const PriceLists = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = customerActions.useCustomer(Number(customerId));
  const priceLists = useSelector(store => store.partials.priceLists);
  assertIsDefined(customer);

  const priceListsOptions = priceLists.map(priceList => ({
    text: priceList.name,
    type: MenuItemType.TEXT,
    value: priceList.id,
  }));

  return (
    <div className="d-flex flex-column gap-3 px-1 pt-2 pb-5">
      <Typography className="text-uppercase" color="neutralBlack48" fontSize="10" fontWeight="700">
        Cennik
      </Typography>
      <Select.Async
        className="w-50"
        items={priceListsOptions}
        mutationHook={customerActions.usePatchCustomer}
        selected={customer.priceList?.id ?? null}
        transformQueryData={priceList => {
          const selectedPriceList = priceLists.find(_priceList => _priceList.id === priceList);
          assertIsDefined(selectedPriceList);
          return {
            id: customer.id,
            toUpdate: {
              priceList: selectedPriceList,
            },
          };
        }}
      />
    </div>
  );
};
