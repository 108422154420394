import { StageMaterialDemands } from "api/manufacturing/materials/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { compareAsc, parseISO } from "date-fns";
import { dateFns } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useMaterialsDemandsColumns = () => {
  return useCreateTableColumns<StageMaterialDemands>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "materiał",
        size: 300,
        typographyProps: {
          fontSize: "16",
        },
      }),
      columnHelper.text(row => `${row.demand} ${row.unit.shortName}`, {
        header: "potrzebne",
        size: 90,
        textAlign: "right",
        typographyProps: {
          fontSize: "16",
        },
      }),
      columnHelper.accessor(row => row, {
        id: "stockAvailable",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            dostępne
          </Typography>
        ),
        size: 90,
        cell: info => {
          const materialDemand = info.getValue();
          return (
            <Typography
              className="w-100 text-right"
              color={
                materialDemand.demand > materialDemand.stock.inStock
                  ? "warning500"
                  : "neutralBlack88"
              }
              fontSize="14"
              fontWeight="500"
              noWrap
            >
              {materialDemand.stock.inStock} {materialDemand.unit.shortName}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "alreadyOrdered",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            już zamówiono
          </Typography>
        ),
        size: 90,
        cell: info => {
          const materialDemand = info.getValue();
          return (
            <Typography
              className="w-100 text-right"
              color={
                materialDemand.stock.inStock + materialDemand.stock.alreadyOrdered >=
                  materialDemand.demand && materialDemand.stock.alreadyOrdered !== 0
                  ? "success500"
                  : "neutralBlack88"
              }
              fontSize="14"
              fontWeight="700"
              noWrap
            >
              {materialDemand.stock.alreadyOrdered === 0
                ? EMPTY_VALUE
                : `${materialDemand.stock.alreadyOrdered} ${materialDemand.unit.shortName}`}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.stock.deliveryDates, {
        header: "ostatnia dostawa",
        size: 110,
        cell: info => {
          const deliveryDates = info.getValue();

          if (!Boolean(deliveryDates.length)) return <EmptyValue fontSize="14" fontWeight="400" />;

          const dates = deliveryDates.map(dateStr => parseISO(dateStr));
          const lastDeliveryDate = dates.sort(compareAsc).pop();

          return (
            <Typography fontSize="14" fontWeight="400" noWrap>
              {lastDeliveryDate ? dateFns.formatRelative(lastDeliveryDate) : EMPTY_VALUE}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.schemaStage, {
        header: "potrzebny w etapie",
        size: 150,
        cell: info => {
          const stage = info.getValue();
          return <Tag label={stage.name} type="outlined" variant="quaternary" />;
        },
      }),
    ];
  });
};
