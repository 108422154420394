import { ToggleHookState, useQuery, useToggle } from "hooks";
import { DrawerSection } from "components/utils/drawer";
import { marketplaceStatusesActions } from "api/marketplace-statuses/actions";
import { dateUtils, queryString } from "utilities";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiHistory } from "components/miloDesignSystem/atoms/icons/MdiHistory";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MarketplaceStatusAttempt, ProcessingStatusChoices } from "api/marketplace-statuses/models";
import { marketplaceStatusesUtilities } from "./utilities";
import { MdiRepeat } from "components/miloDesignSystem/atoms/icons/MdiRepeat";
import { ButtonProps } from "components/miloDesignSystem/atoms/button/types";
import { PropsWithChildren } from "react";

const useGetSearch = () => {
  const orderId = useQuery().query.panelId;
  return queryString.stringify({ orderId });
};

export const MarketplaceStatusesSection = () => {
  const search = useGetSearch();
  const { data } = marketplaceStatusesActions.useGetMarketplaceStatusesAttempts(search);
  const modalController = useToggle();

  if (!data.length)
    return (
      <DrawerSection title="Status w serwisie zewnętrznym">
        <Typography fontSize="14" fontWeight="400">
          Brak statusu z serwisu
        </Typography>
      </DrawerSection>
    );

  const lastStatus = data[0];

  return (
    <DrawerSection title="Status w serwisie zewnętrznym">
      <div>
        <div className="d-flex align-items-center gap-2">
          <StatusLabel status={lastStatus} />
          {marketplaceStatusesUtilities.getMarketplaceStatusTag(lastStatus)}
          <IconButton onClick={modalController.open} icon={MdiHistory} variant="transparent" />
          {modalController.isOpen && (
            <MarketplaceStatusesHistory modalController={modalController} />
          )}
        </div>
        <ErrorStatus buttonVariant="gray" status={lastStatus} />
      </div>
    </DrawerSection>
  );
};

const MarketplaceStatusesHistory = ({ modalController }: { modalController: ToggleHookState }) => {
  const search = useGetSearch();
  const { data } = marketplaceStatusesActions.useGetMarketplaceStatusesAttempts(search);
  return (
    <Modal
      close={modalController.close}
      isOpen
      width={640}
      title={
        <Typography fontSize="20" fontWeight="700">
          Statusy z serwisu zewnętrznego
        </Typography>
      }
    >
      <div className="px-3">
        {data.map(status => (
          <div className="border-bottom py-2" key={status.id}>
            <div className="d-flex align-items-center gap-2 justify-content-between">
              <StatusLabel status={status} />

              {marketplaceStatusesUtilities.getMarketplaceStatusTag(status)}
            </div>
            <ErrorStatus buttonVariant="deepPurple" status={status} />
          </div>
        ))}

        <div className="py-3">
          <Button
            className="text-uppercase"
            onClick={modalController.close}
            size="large"
            variant="transparent"
          >
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const StatusLabel = ({ status }: { status: MarketplaceStatusAttempt }) => {
  return (
    <Typography fontSize="14" fontWeight="400">
      Ostatni przekazany status w serwisie zewnętrznym z dn.{" "}
      {dateUtils.formatDateToDisplay(status.createdAt)},{" "}
      {dateUtils.formatDateToTime(status.createdAt)}:
    </Typography>
  );
};

const ErrorStatus = ({
  status,
  buttonVariant,
}: {
  status: MarketplaceStatusAttempt;
  buttonVariant: ButtonProps["variant"];
}) => {
  if (!shouldRetryBeDisplayed(status)) return null;

  if (status.statusEvent.processingStatus === ProcessingStatusChoices.NOT_IMPLEMENTED)
    return (
      <div className="mt-2">
        <Typography fontSize="14" fontWeight="400" className="mb-2">
          Serwis zewnętrzny nie jest jeszcze obsługiwany przez Milo.
        </Typography>
      </div>
    );

  return (
    <div className="mt-2">
      <Typography fontSize="14" fontWeight="400" color="danger600" className="mb-2">
        {status.error}
      </Typography>
      <RetryButton buttonVariant={buttonVariant} statusEventId={status.statusEvent.id}>
        Ponów
      </RetryButton>
    </div>
  );
};

export function shouldRetryBeDisplayed(status: MarketplaceStatusAttempt) {
  if (status.statusEvent.processingStatus === ProcessingStatusChoices.FAILED) return true;

  if (status.error) return true;

  return false;
}

export const RetryButton = ({
  buttonVariant,
  statusEventId,
  children,
}: PropsWithChildren<{
  buttonVariant: ButtonProps["variant"];
  statusEventId: MarketplaceStatusAttempt["statusEvent"]["id"];
}>) => {
  const retryMutation = marketplaceStatusesActions.usePostMarketplaceStatusesRetry();
  return (
    <Button
      onClick={() => retryMutation.mutate({ statusEventId })}
      isLoading={retryMutation.isLoading}
      variant={buttonVariant}
      size="small"
      className="text-uppercase"
      endIcon={MdiRepeat}
    >
      {children}
    </Button>
  );
};
