import { ManufacturerEmailsTab } from "api/manufacturing/models";
import { QueryParams } from "api/types";
import { queryString } from "./utilities";

const getManufacturerEmailsTabs = (): { label: string; name: ManufacturerEmailsTab }[] => {
  return [
    { label: "Wszystkie", name: "all" },
    { label: "Wysłane", name: "sent" },
    { label: "Niewysłane", name: "not-sent" },
  ];
};

const getManufacturerEmailsSearch = ({
  manufacturerId,
  query,
  tab,
}: {
  manufacturerId: string;
  query: QueryParams;
  tab: ManufacturerEmailsTab;
}): string => {
  const tabQuery = {
    status: tab === "not-sent" ? "DECLINED" : tab === "sent" ? "SENT" : "",
  };
  return queryString.stringify({
    ...query,
    ...tabQuery,
    manufacturer: manufacturerId,
  });
};

export const manufacturersUtils = {
  getManufacturerEmailsSearch,
  getManufacturerEmailsTabs,
};
