import { WalletEmployeeTransaction } from "api/manufacturing/wallet/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { manufacturingWalletsConstants } from "constants/manufacturingWallets";
import { manufacturingEmployeesUtils } from "utilities/manufacturingEmployees";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useTransactionsColumns = () => {
  return useCreateTableColumns<WalletEmployeeTransaction>(
    ({ columnHelper }) => {
      return [
        columnHelper.date(row => row.createdAt, {
          header: "data",
          size: 70,
          textAlign: "left",
          typographyProps: {
            fontSize: "12",
            fontWeight: "500",
          },
        }),
        columnHelper.amount(
          row => String(manufacturingEmployeesUtils.transfromCentsToPln(row.amount)),
          {
            header: "kwota",
            size: 135,
            amountDisplayProps: {
              currency: row => row.original.currency,
            },
            textAlign: "right",
          },
        ),
        columnHelper.amount(
          row =>
            String(manufacturingEmployeesUtils.transfromCentsToPln(row.balanceAfterTransaction)),
          {
            header: "saldo",
            size: 135,
            amountDisplayProps: {
              currency: row => row.original.currency,
            },
            textAlign: "right",
          },
        ),
        columnHelper.accessor(row => row.createdBy, {
          header: "dodane przez",
          size: 100,
          cell: info => {
            const user = info.getValue();
            if (!user) return <EmptyValue fontWeight="700" />;
            return (
              <div className="d-flex align-items-center gap-1">
                <Avatar size="sm" user={user} />
                <Typography fontSize="12" fontWeight="700" noWrap>
                  {user.firstName} {user.lastName}
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row.transactionType, {
          header: "typ płatności",
          size: 90,
          cell: info => {
            const transactionType = info.getValue();

            return (
              <Tag
                label={manufacturingWalletsConstants.transactionTypeOptions[transactionType].label}
                type="outlined"
                variant={
                  manufacturingWalletsConstants.transactionTypeOptions[transactionType].variant
                }
              />
            );
          },
        }),
        // TODO: waiting for backend
        // columnHelper.accessor(row => row, {
        //   id: "settlement",
        //   header: () => (
        //     <Typography
        //       className="text-right w-100"
        //       color="neutralBlack48"
        //       fontSize="12"
        //       fontWeight="400"
        //     >
        //       rozliczenie
        //     </Typography>
        //   ),
        //   size: 100,
        //   cell: () => {
        //     return (
        //       <div className="d-flex align-items-center gap-1">
        //         <Button
        //           className="text-uppercase"
        //           disabled
        //           endIcon={MdiChevronRight}
        //           onClick={event => event.stopPropagation()}
        //           size="small"
        //           variant="gray"
        //         >
        //           Pokaż
        //         </Button>
        //         <IconButton
        //           disabled
        //           icon={MdiDownloadPdf}
        //           onClick={event => event.stopPropagation()}
        //           variant="gray"
        //         />
        //       </div>
        //     );
        //   },
        // }),
      ];
    },
    {
      shouldDisplayIndexColumn: true,
    },
  );
};
