import { Filters } from "../../AddEmployeeModal";
import cx from "classnames";
import localStyles from "./FiltersBar.module.css";
import { SearchField } from "components/utils/searchField/SearchField";
import { useQuery } from "hooks";
import { employeeKindConfigDict } from "CONSTANTS";
import { dictToList } from "utilities";
import { Tag } from "components/miloDesignSystem/atoms/tag";

interface Props {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

export const FiltersBar = ({ filters, setFilters }: Props) => {
  const { query, setQuery } = useQuery({ exclude: ["panelId"] });

  return (
    <div className={cx(localStyles.filters, "mb-3 d-flex align-items-center")}>
      <SearchField
        value={filters.search}
        onUpdate={value => {
          setFilters(filters => {
            return { ...filters, search: value };
          });
          setQuery({ ...query, page: "1" });
        }}
      />
      <span className={cx(localStyles.filtersLabel, "mr-3 ml-3")}>typ:</span>
      <div className="mb-1 d-flex align-items-center gap-1 flex-wrap">
        {dictToList(employeeKindConfigDict).map(({ key, value }) => (
          <div
            className="cursor-pointer"
            key={key}
            onClick={() => {
              setFilters(filters => ({
                ...filters,
                kind: filters.kind === key ? "" : key,
              }));
              setQuery({ ...query, page: "1" });
            }}
          >
            <Tag
              label={value.name.toLowerCase()}
              type={filters.kind === key ? "filled" : "outlined"}
              variant={filters.kind === key ? "deepPurple400" : "quaternary"}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
