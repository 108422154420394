import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { formatDistanceToNow, isToday, isYesterday } from "date-fns";
import { dateUtils, pluralize } from "utilities";

const formatCustomDateLabel = (date: Date) => {
  const now = new Date();
  const distance = formatDistanceToNow(date);

  if (distance.includes("minute") && !distance.includes("hour")) {
    const minutesAgo = Math.abs(Math.floor(date.getTime() - now.getTime()) / (1000 * 60));
    if (Math.trunc(minutesAgo) === 0) return "<1 min temu";
    return `${Math.trunc(minutesAgo)} ${pluralize.pl(Math.trunc(minutesAgo), {
      singular: "minutę",
      plural: "minuty",
      other: "minut",
    })} temu`;
  } else if (distance.includes("hour") && !distance.includes("day")) {
    const hoursAgo = Math.floor(Math.abs(date.getTime() - now.getTime()) / (1000 * 60 * 60));
    const minutesAgo = Math.floor(Math.abs(date.getTime() - now.getTime()) / (1000 * 60)) % 60;

    if (hoursAgo <= 6) {
      return `${hoursAgo !== 0 ? `${Math.trunc(hoursAgo)}h` : ""} ${Math.trunc(
        minutesAgo,
      )}min temu`;
    } else if (isToday(date)) {
      return "dzisiaj";
    } else if (isYesterday(date)) {
      return `wczoraj, ${dateUtils.formatDateToTime(date)}`;
    }
  } else if (isToday(date)) {
    return "dzisiaj";
  } else if (isYesterday(date)) {
    return `wczoraj, ${dateUtils.formatDateToTime(date)}`;
  } else {
    return dateUtils.formatDateToDisplay(date);
  }
};

interface Props {
  createdAt: string;
  fontSize: TypographyProps["fontSize"];
  fontWeight: TypographyProps["fontWeight"];
}

export const CreatedAtLabel = ({ createdAt, fontSize, fontWeight }: Props) => {
  return (
    <Typography fontSize={fontSize} fontWeight={fontWeight}>
      {formatCustomDateLabel(new Date(createdAt))}
    </Typography>
  );
};
