import { AutoAssignOrderBy, AutoAssignProcedure } from "api/new-production-plans/enums";
import { dictToList } from "./utilities";

const autoAssignProceduresDict: Record<AutoAssignProcedure, string> = {
  EVEN: "wypełniaj po równo",
  FILL: "wypełniaj po kolei",
};

const autoAssignOrderByDict: Record<AutoAssignOrderBy, string> = {
  priority: "najwyższy priorytet",
  source_departure: "najwcześniejsze daty wyjazdu",
};

const autoAssignProceduresOptions = dictToList(autoAssignProceduresDict).map(({ key, value }) => ({
  id: key,
  name: value,
}));

const autoAssignOrderByOptions = dictToList(autoAssignOrderByDict).map(({ key, value }) => ({
  id: key,
  name: value,
}));

export const productionPlansUtils = {
  autoAssignProceduresOptions,
  autoAssignOrderByOptions,
};
