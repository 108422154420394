import { cx } from "utilities";
import styles from "./SchemaSearch.module.css";
import { NormalizedItem, RecentSearchProps, SearchListProps } from "./types";
import { MdiHistory } from "components/miloDesignSystem/atoms/icons/MdiHistory";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { warehouseSchemaConstants } from "constants/warehouseSchema";
import { normalizeItem } from "./SchemaSearch";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Table } from "components/miloDesignSystem/molecules/table";
import { SchemaSearchCollection, SchemaSearchRecentSearch } from "api/wms/layout/models";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useCollectionColumns } from "./useCollectionColumns";
import { MdiRoute } from "components/miloDesignSystem/atoms/icons/MdiRoute";
import { MdiOrderGroup } from "components/miloDesignSystem/atoms/icons/MdiOrderGroup";
import { MdiShoppingCart } from "components/miloDesignSystem/atoms/icons/MdiShoppingCart";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";
import { MdiPackage2 } from "components/miloDesignSystem/atoms/icons/MdiPackage2";
import { useQuery } from "hooks";

export const SearchList = ({ data, handleMenuItemClick, isLoading }: SearchListProps) => {
  return (
    <div className={cx(styles.searchList, "py-2")}>
      <RecentSearch data={data} handleMenuItemClick={handleMenuItemClick} isLoading={isLoading} />
      <CollectionList
        handleMenuItemClick={handleMenuItemClick}
        icon={<MdiRoute size="16" />}
        isLoading={isLoading}
        rows={data?.routes || []}
        title="Najbliższe trasy"
      />
      <CollectionList
        handleMenuItemClick={handleMenuItemClick}
        icon={<MdiOrderGroup size="16" />}
        isLoading={isLoading}
        rows={data?.orderGroups || []}
        title="Najbliższe grupy"
      />
      <CollectionList
        handleMenuItemClick={handleMenuItemClick}
        icon={<MdiShoppingCart size="16" />}
        isLoading={isLoading}
        rows={data?.orders || []}
        title="Zamówienia"
      />
      <CollectionList
        handleMenuItemClick={handleMenuItemClick}
        icon={<MdiChair size="16" />}
        isLoading={isLoading}
        rows={data?.products || []}
        title="Produkty"
      />
      <CollectionList
        handleMenuItemClick={handleMenuItemClick}
        icon={<MdiPackage2 size="16" />}
        isLoading={isLoading}
        rows={data?.packages || []}
        title="Paczki"
      />
    </div>
  );
};

interface CollectionListProps {
  icon: JSX.Element;
  handleMenuItemClick: (value: NormalizedItem | null) => void;
  isLoading: boolean;
  rows: SchemaSearchCollection[];
  title: string;
}

const CollectionList = ({
  handleMenuItemClick,
  icon,
  isLoading,
  rows,
  title,
}: CollectionListProps) => {
  const { query } = useQuery();
  const { kind, searchCollectionId } = query;
  const columns = useCollectionColumns();

  const isCollectionItemSelected = (item: SchemaSearchCollection) => {
    return (
      (searchCollectionId === String(item.id) && kind === item.kind) ||
      (query.searchCollectionId === String(item.id) && query.kind === item.kind)
    );
  };

  if (!isLoading && !Boolean(rows.length)) return null;

  return (
    <div className="pt-2 pb-3">
      {!isLoading && !Boolean(rows.length) ? null : (
        <div className="d-flex align-items-center gap-2 px-3 pb-2">
          {icon}
          <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
            {String(title).toUpperCase()}
          </Typography>
        </div>
      )}
      <Table<SchemaSearchCollection>
        columns={columns}
        rows={rows?.slice(0, 3)}
        error={null}
        isLoading={isLoading}
        onRowClick={(rowId, e) => {
          const foundItem = rows.find(item => String(item.id) === rowId);
          assertIsDefined(foundItem);
          if (isCollectionItemSelected(foundItem)) handleMenuItemClick(null);
          else handleMenuItemClick(normalizeItem(foundItem));
        }}
        uiSchema={comfortableListUiSchema}
        overrides={() => {
          return {
            loaderRowsCounter: 3,
            hideHeader: true,
            noResultComponent: <div />,
            row: row => {
              return {
                className: cx({
                  [styles.selectedRow]: isCollectionItemSelected(row),
                }),
              };
            },
          };
        }}
      />
    </div>
  );
};

const RecentSearch = ({ data, handleMenuItemClick, isLoading }: RecentSearchProps) => {
  const { query } = useQuery();
  const { kind, searchCollectionId } = query;

  const isRecentSearchItemSelected = (recentItem: SchemaSearchRecentSearch): boolean => {
    return (
      (searchCollectionId === recentItem.id && kind === recentItem.kind) ||
      (query.searchCollectionId === recentItem.id && query.kind === recentItem.kind)
    );
  };

  return (
    <div className="pt-2 pb-3">
      <div className="d-flex align-items-center gap-2 pb-2 px-3">
        <MdiHistory size="16" />
        <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
          {String("ostatnie wyszukiwania").toUpperCase()}
        </Typography>
      </div>
      <div className={cx(styles.tags, "d-flex align-items-center flex-wrap gap-1 px-3 py-1")}>
        {isLoading ? (
          <Spinner size={16} />
        ) : (
          data?.recentSearch.slice(0, 8).map(recentSearchItem => (
            <div
              className="cursor-pointer"
              key={recentSearchItem.id}
              onClick={() => {
                if (isRecentSearchItemSelected(recentSearchItem)) handleMenuItemClick(null);
                else handleMenuItemClick(normalizeItem(recentSearchItem));
              }}
            >
              <Tag
                startIcon={warehouseSchemaConstants.collectionToIconDict[recentSearchItem.kind]}
                label={recentSearchItem.name}
                type={isRecentSearchItemSelected(recentSearchItem) ? "filled" : "outlined"}
                variant={
                  isRecentSearchItemSelected(recentSearchItem) ? "deepPurple50" : "quaternary"
                }
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};
