import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import stagesAlt from "assets/images/moduleNavigationIcons/stagesAlt.svg";
import { ManufacturingStages } from "routes/manufacturingNew/ManufacturingStages";

export const manufacturingStages: ModuleLink = {
  url: "manufacturing-stages",
  label: "Etapy produkcji",
  icon: stagesAlt,
  routing: ManufacturingStages,
};
