import { Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { IndexesList } from "pages/manufacturingNew/indexes/IndexesList";
import { IndexDetails } from "pages/manufacturingNew/indexes/indexDetails/IndexDetails";

export const IndexesRouting = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/upholstery`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/upholstery`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list/:tab`}
        component={IndexesList}
        title="Milo - warianty"
      />
      <Route
        component={IndexDetails}
        exact={true}
        path={`${match.path}/:indexId/details`}
        title="Milo - szczegóły indeksu"
      />
      <Route exact={true} path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};
