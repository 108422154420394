import { ApiFetcher } from "hooks/createApiQuery";
import { wmsUtilsKeys } from "./keys";
import { PackageInfo, PackageOrderInfo } from "./models";
import { queryFetch } from "apiConnectors/queryFetch";

const getPackageInfo = (uniquePackage: string): ApiFetcher<PackageInfo> => ({
  key: wmsUtilsKeys.packageInfo(uniquePackage),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/utils/package-info?uniquePackage=${uniquePackage}`,
    }),
});

const getPackageOrderInfo = (uniqueCodes: string[]): ApiFetcher<PackageOrderInfo[]> => ({
  key: wmsUtilsKeys.packageOrderInfo(uniqueCodes.join(",")),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/utils/package-order-info?uniqueCodes=${encodeURIComponent(uniqueCodes.join(","))}`,
    }),
});

export const wmsUtilsApi = {
  getPackageInfo,
  getPackageOrderInfo,
};
