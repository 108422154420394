import { yup } from "utilities";

export const validationSchema = yup.object().shape({
  customer: yup
    .string()
    .nullable()
    .required("Kontrahent jest wymagany"),
  externalCode: yup.string().required("Numer obcy jest wymagany"),
  externalName: yup.string().required("Nazwa jest wymagana"),
});
