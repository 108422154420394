import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { ReturnList } from "pages/wms/returnList/ReturnList";
import { RouteComponentProps } from "react-router";
import { Redirect, Switch } from "react-router-dom";

export const ReturnsRouting = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        component={ReturnList}
        exact
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}`} />}
        title="Milo - WMS - zwroty"
      />
      <Route component={NotFound} exact path="*" title="Milo" />
    </Switch>
  );
};
