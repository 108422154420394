import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { assertIsDefined } from "utilities/assertIsDefined";
import { shippingActions } from "api/shipping/actions";
import { GeneralInfoSection } from "./components/GeneralInfoSection";
import { DefaultOptionsSection } from "./components/DefaultOptionsSection";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { PanelHeader } from "./components/PanelHeader";
import { AuthorizationSection } from "./components/AuthorizationSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: courier, error, isLoading } = shippingActions.useShippingCourier(Number(panelId), {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(courier);

  return (
    <TriggerScrollContext>
      <DrawerRightPanel className="position-relative" key={panelId}>
        <PanelHeader courier={courier} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <AuthorizationSection courier={courier} />
            <GeneralInfoSection courier={courier} />
            <DefaultOptionsSection courier={courier} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
