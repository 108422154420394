import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import calendarViewMonth from "assets/images/moduleNavigationIcons/calendar_view_month.svg";
import { ManufacturingPlans } from "routes/manufacturingNew/ManufacturingPlans";

export const manufacturingPlans: ModuleLink = {
  url: "manufacturing-plans",
  label: "Plany produkcji",
  icon: calendarViewMonth,
  routing: ManufacturingPlans,
};
