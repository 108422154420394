import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import styles from "../OrdersSection.module.css";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useState } from "react";
import { useParams } from "react-router";
import { customerActions } from "api/customers/actions";
import { assertIsDefined } from "utilities/assertIsDefined";
import { AddressCard } from "./AddressCard";
import { MdiKeyboardArrowDown } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowDown";
import { MdiKeyboardArrowUp } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowUp";
import { useToggle } from "hooks";
import { AddOrEditDeliveryAddress } from "./addOrEditDeliveryAddress/AddOrEditDeliveryAddress";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection";
import { MdiLocationOn } from "components/miloDesignSystem/atoms/icons/MdiLocationOn";

export const DeliveryAddresses = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = customerActions.useCustomer(Number(customerId));
  assertIsDefined(customer);
  const [showMoreAddresses, setShowMoreAddresses] = useState(false);
  const addOrEditAddressModal = useToggle();

  return (
    <>
      <Typography className="text-uppercase" color="neutralBlack48" fontSize="10" fontWeight="700">
        Adresy dostawy
      </Typography>
      {Boolean(customer.addresses.length) && (
        <div className={styles.addDeliveryBtn}>
          <Button
            className="text-uppercase mb-2"
            onClick={addOrEditAddressModal.open}
            size="small"
            startIcon={MdiAdd}
            variant="gray"
          >
            Dodaj adres dostawy
          </Button>
        </div>
      )}
      <div className="d-flex flex-column gap-3 px-1 pt-4 pb-1 mb-3">
        <div className="d-flex flex-wrap gap-2">
          {Boolean(customer.addresses.length) ? (
            (showMoreAddresses
              ? customer.addresses
              : customer.addresses.slice(0, 3)
            ).map(address => <AddressCard address={address} customer={customer} key={address.id} />)
          ) : (
            <EmptySection
              AdditionalInfoRenderer={
                <Button
                  className="text-uppercase mt-2"
                  onClick={addOrEditAddressModal.open}
                  size="small"
                  startIcon={MdiAdd}
                  variant="gray"
                >
                  Dodaj adres dostawy
                </Button>
              }
              className="w-100"
              icon={MdiLocationOn}
              label="Kontrahent nie ma jeszcze dodanego adresu dostawy"
            />
          )}
        </div>
        {Boolean(customer.addresses.length > 3) && (
          <div>
            <Button
              className="text-uppercase"
              endIcon={showMoreAddresses ? MdiKeyboardArrowUp : MdiKeyboardArrowDown}
              onClick={() => setShowMoreAddresses(prev => !prev)}
              size="small"
              variant="transparent"
            >
              {showMoreAddresses ? "Ukryj dodatkowe adresy" : "Pokaż więcej adresów"}
            </Button>
          </div>
        )}
      </div>
      {addOrEditAddressModal.isOpen && (
        <AddOrEditDeliveryAddress close={addOrEditAddressModal.close} customer={customer} />
      )}
    </>
  );
};
