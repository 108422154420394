import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";

export const wmsLayoutKeys = {
  collectionDetails: (collectionId: number) => ["collectionSearchDetails", collectionId],
  getSearchCollections: createPaginatedKey("searchCollections"),
  getSchemaSearchCollections: (search: string) => ["schemaSearchCollections", search],
  getPackagesInLocation: createPaginatedKey("packagesInLocation"),
  getLayoutSchema: (id: number) => ["layoutSchema", id],
  getRampStatus: (id: number) => ["rampStatus", id],
  getFillingStatus: (id: number) => ["fillingStatus", id],
  getLayoutField: (hallId: number, nodeId: UUID) => ["layoutField", hallId, nodeId],
  collectionLayout: (hallId: number, collectionId: number) => [
    "collectionSearchLayout",
    hallId,
    collectionId,
  ],
};
