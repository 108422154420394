import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { useQuery, useToggle } from "hooks";
import { useManufacturerColumns } from "./useManufacturerColumns";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { Table } from "components/miloDesignSystem/molecules/table";
import { queryString } from "utilities";
import { manufacturersActions } from "api/manufacturers/actions";
import { ManufacturerKind, ManufacturerListItem } from "api/manufacturers/models";
import { CreateManufacturer } from "./createManufacturer/CreateManufacturer";
import { RightPanel } from "./rightPanel/RightPanel";

export const ManufacturerList = () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify({ ...query, kind: ManufacturerKind.SUPPLIER });
  const {
    data,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = manufacturersActions.useManufacturers(search);
  const createManufacturerModal = useToggle();

  const tableProps = useTableFeatureConnector({
    rows: data,
    withDrawer: "manufacturer",
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  const columns = useManufacturerColumns();

  return (
    <PageWrapper>
      <PageHeader
        createButton={{
          alt: "Plus",
          img: darkPlusIcon,
          label: "Dodaj dostawcę",
          onClick: createManufacturerModal.open,
        }}
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        bigTitle="Dostawcy"
        viewLabel="MANUFACTURERS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<ManufacturerListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={{
            header: {
              backgroundColor: "neutralWhite88",
            },
            cell: {
              height: "26",
            },
          }}
          {...tableProps}
        />
        <RightPanel />
      </div>
      {createManufacturerModal.isOpen && (
        <CreateManufacturer close={createManufacturerModal.close} />
      )}
    </PageWrapper>
  );
};
