import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import QRCode from "qrcode.react";
import { wmsRampActions } from "api/wms/ramps/actions";
import { RampListItem } from "api/wms/ramps/models";
import { TextField } from "components/miloDesignSystem/atoms/textField";

interface Props {
  ramp: RampListItem;
}

export const GeneralInfoSection = ({ ramp }: Props) => {
  return (
    <RightPanelSection padding="px-3 pt-0 pb-2">
      <div className="d-flex align-items-start justify-content-between gap-2">
        <div>
          <InfoLabel title="nazwa">
            <TextField.Async
              mutationHook={wmsRampActions.usePatchRamp}
              placeholder="Wpisz nazwę"
              size="small"
              transformQueryData={name => ({
                id: ramp.id,
                toUpdate: { name },
              })}
              value={ramp.name}
            />
          </InfoLabel>
          <InfoLabel title="kod rampy">
            <TextField.Async
              mutationHook={wmsRampActions.usePatchRamp}
              placeholder="Wpisz kod rampy"
              size="small"
              transformQueryData={code => ({
                id: ramp.id,
                toUpdate: { code },
              })}
              value={ramp.code}
            />
          </InfoLabel>
          <InfoLabel title="hala">
            <TextField.Async
              mutationHook={wmsRampActions.usePatchRamp}
              placeholder="Wpisz halę"
              size="small"
              transformQueryData={hall => ({
                id: ramp.id,
                toUpdate: { hall },
              })}
              value={ramp.hall}
            />
          </InfoLabel>
        </div>
        <div className="d-flex flex-column gap-1">
          <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
            QR kod rampy
          </Typography>
          <QRCode value={ramp.qrCode} />
        </div>
      </div>
    </RightPanelSection>
  );
};
