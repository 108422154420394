import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import personIcon from "assets/images/person4.svg";
import { Warehouseman } from "routes/wms/Warehouseman";

export const warehousemen: ModuleLink = {
  url: "warehousemen",
  label: "Magazynierzy",
  icon: personIcon,
  routing: Warehouseman,
};
