import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import manufacturerLineItemsIcon from "assets/images/singleOrders.svg";
import { ManufacturerLineItems } from "routes/externalManufacturing/ManufacturerLineItems";

export const manufacturerLineItems: ModuleLink = {
  url: "manufacturer-line-items",
  label: "Poj. zlecenia produkcyjne",
  icon: manufacturerLineItemsIcon,
  routing: ManufacturerLineItems,
};
