import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";

export const manufacturingUnitsKeys = {
  manufacturingGroup: {
    list: createPaginatedKey("manufacturingGroups"),
  },
  todoManufacturingUnit: {
    list: createPaginatedKey("todoManufacturingUnits"),
    details: (unitId: UUID) => ["todoManufacturingUnit", unitId],
  },
  readyManufacturingUnit: {
    list: createPaginatedKey("readyManufacturingUnits"),
  },
  inProgressGroupedUnits: {
    list: createPaginatedKey("inProgressGroupedUnits"),
  },
  manufacturingUnitGroup: {
    list: createPaginatedKey("manufacturingUnitsGroups"),
    details: (unitGroupId: UUID) => ["manufacturingUnitGroup", unitGroupId],
  },
  manufacturingItem: {
    count: (stageId: UUID) => ["manufacturingItemCount", stageId],
  },
  listView: {
    prioritizedUnits: createPaginatedKey("prioritizedUnits"),
    normalUnits: createPaginatedKey("normalUnits"),
    listUnitDetails: (unitId: UUID) => ["listUnitDetails", unitId],
  },
  customOrderedMaterials: {
    list: createPaginatedKey("customOrderedMaterials"),
  },
};
