import Pie, { ProvidedProps, PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import { Group } from "@visx/group";
import { animated, useTransition, interpolate } from "react-spring";
import { noop } from "utilities";

export interface PieChartData {
  label: string;
  value: number;
  color: string;
}

// accessor functions
const usage = (d: PieChartData) => d.value;

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export type PieProps = {
  width: number;
  height: number;
  margin?: typeof defaultMargin;
  animate?: boolean;
  thickness?: number;
  data: PieChartData[];
};

export function PieChart({
  width,
  height,
  margin = defaultMargin,
  animate = true,
  thickness = 60,
  data,
}: PieProps) {
  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;

  return (
    <div className="position-relative" style={{ width, height }}>
      <svg width={width} height={height}>
        <rect rx={14} width={width} height={height} fill="url('#visx-pie-gradient')" />
        {Boolean(data.length) && (
          <Group top={centerY + margin.top} left={centerX + margin.left}>
            <Pie data={data} pieValue={usage} outerRadius={radius} innerRadius={radius - thickness}>
              {pie => (
                <AnimatedPie<PieChartData>
                  {...pie}
                  animate={animate}
                  getKey={arc => arc.data.label}
                  onClickDatum={noop}
                  getColor={arc => arc.data.color}
                />
              )}
            </Pie>
          </Group>
        )}
      </svg>
    </div>
  );
}

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  // startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  // endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  startAngle: 0,
  endAngle: Math.PI,
  opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  onClickDatum: (d: PieArcDatum<Datum>) => void;
  delay?: number;
};

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum,
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(
    arcs,
    getKey,
    // @ts-ignore react-spring doesn't like this overload
    {
      from: animate ? fromLeaveTransition : enterUpdateTransition,
      enter: enterUpdateTransition,
      update: enterUpdateTransition,
      leave: animate ? fromLeaveTransition : enterUpdateTransition,
    },
  );

  return (
    <>
      {transitions
        .filter(transition => transition.item)
        .map(
          ({
            item: arc,
            props,
            key,
          }: {
            item: PieArcDatum<Datum>;
            props: AnimatedStyles;
            key: string;
          }) => {
            return (
              <g key={key}>
                <animated.path
                  // compute interpolated path d attribute from intermediate angle values
                  d={interpolate([props.startAngle, props.endAngle], (startAngle, endAngle) =>
                    path({
                      ...arc,
                      startAngle,
                      endAngle,
                    }),
                  )}
                  fill={getColor(arc)}
                  onClick={() => onClickDatum(arc)}
                  onTouchStart={() => onClickDatum(arc)}
                />
              </g>
            );
          },
        )}
    </>
  );
}
