import { useLoading } from "api/wms/hooks";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { PanelHeader } from "./components/PanelHeader";
import { TitleSection } from "./components/TitleSection";
import { GeneralInfoSection } from "./components/GeneralInfoSection";
import { ProgressSection } from "./components/ProgressSection";
import { LineItemsSection } from "./components/lineItemsSection/LineItemsSection";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";

export const RightPanel = () => {
  const { query } = useQuery();
  const { close } = useDrawer("wmsLoadings");
  const { panelId } = query;
  const { data: loading, error, isLoading } = useLoading(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(loading);

  return (
    <TriggerScrollContext>
      <DrawerRightPanel className="position-relative" key={panelId}>
        <PanelHeader close={close} loading={loading} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <TitleSection loading={loading} />
            <GeneralInfoSection loading={loading} />
            <ProgressSection loading={loading} />
            <LineItemsSection loading={loading} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
