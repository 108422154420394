import { MdiCalendarMonth } from "components/miloDesignSystem/atoms/icons/MdiCalendarMonth";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { forwardRef, useMemo, useState } from "react";
import ReactDatePicker, { CalendarContainer, registerLocale } from "react-datepicker";
import {
  capitalizeFirstLetter,
  cx,
  dateFns,
  getAnyErrorKey,
  dateUtils,
  noop,
  omit,
  yup,
} from "utilities";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./DatePicker.module.css";
import {
  AsyncNotNullable,
  AsyncNullable,
  CustomDateInputProps,
  DatePickerProps,
  DayProps,
  EndInputSectionProps,
  HeaderProps,
} from "./types";
import pl from "date-fns/locale/pl";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { getMonth, getYear, isEqual } from "date-fns";
import { MdiChevronLeft } from "components/miloDesignSystem/atoms/icons/MdiChevronLeft";
import { MdiChevronRight } from "components/miloDesignSystem/atoms/icons/MdiChevronRight";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Select } from "../select/Select";
import { months } from "pages/wms/warehousemanList/rightPanel/utils/getMonthName";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import cuid from "cuid";
import { AxiosError } from "axios";
import { MDSAsyncType, MDSFormType } from "typeUtilities";
import { useField, useFormikContext } from "formik";

registerLocale("pl", pl);

export const DatePicker = ({
  autoFocus = false,
  calendarClassName,
  disabled,
  isNullable = true,
  label,
  placeholder = "dd/mm/yyyy",
  onChange,
  textFieldProps,
  value,
  ...rest
}: DatePickerProps) => {
  const selectedDate = value ? new Date(value) : null;
  const customId = useMemo(() => cuid(), []);

  const CustomDateInput = forwardRef<HTMLInputElement, CustomDateInputProps>((props, ref) => {
    return (
      <div
        className={cx("d-flex align-items-center gap-2", styles.customDateInput, {
          [styles.disabledDateInput]: disabled,
        })}
        onClick={props.onClick}
      >
        <TextField
          disabled={disabled}
          EndInputSection={
            <EndInputSection
              clearInputValue={() => onChange(null)}
              hasSelectedValue={Boolean(selectedDate) && !disabled}
              isNullable={isNullable}
              theme={textFieldProps?.theme || "light"}
            />
          }
          endIcon={MdiCalendarMonth}
          label={label}
          onChange={noop}
          theme={textFieldProps?.theme || "light"}
          value={selectedDate ? dateFns.format(selectedDate, "dd/MM/yyyy") : ""}
          placeholder={placeholder}
          ref={ref}
          {...textFieldProps}
        />
      </div>
    );
  });

  return (
    <ReactDatePicker
      autoComplete="off"
      autoFocus={autoFocus}
      calendarClassName={calendarClassName}
      calendarContainer={props => (
        <CalendarContainer
          className={cx(props.className, styles["new-date-picker"], styles.calendarContainer)}
        >
          <div className={cx(styles.calendarBody, "position-relative")}>{props.children}</div>
        </CalendarContainer>
      )}
      customInput={<CustomDateInput />}
      dateFormat="dd/MM/yyyy"
      disabled={disabled}
      id={customId}
      locale="pl"
      onChange={onChange}
      popperClassName={styles.popper}
      portalId="root-portal"
      renderCustomHeader={params => <Header params={params} />}
      renderDayContents={(dayOfMonth, date) => <Day date={date} value={selectedDate} />}
      selected={selectedDate}
      showPopperArrow={false}
      wrapperClassName={styles.wrapper}
      value={selectedDate ? dateFns.format(selectedDate, "dd/MM/yyyy") : undefined}
      {...rest}
    />
  );
};

const Day = ({ date, value }: DayProps) => {
  return (
    <div
      className={cx(styles.day, {
        [styles.today]: date && dateFns.isToday(date),
        [styles.selectedDayInAnotherMonth]:
          date &&
          value &&
          date.getDate() === value.getDate() &&
          !isEqual(
            new Date(dateUtils.formatDateToIso(date)),
            new Date(dateUtils.formatDateToIso(value)),
          ),
        [styles.selectedDay]:
          date &&
          value &&
          isEqual(
            new Date(dateUtils.formatDateToIso(date)),
            new Date(dateUtils.formatDateToIso(value)),
          ),
      })}
    >
      <Typography color="inherit" fontSize="16" fontWeight="700">
        {date && dateFns.format(date, "d")}
      </Typography>
    </div>
  );
};

const Header = ({ params }: HeaderProps) => {
  const monthOptions: {
    value: string;
    text: string;
    type: MenuItemType;
  }[] = months.map(option => ({
    value: option,
    text: `${capitalizeFirstLetter(option)} ${getYear(params.date)}`,
    type: MenuItemType.TEXT,
  }));

  return (
    <div className={styles.header}>
      <IconButton
        disabled={params.prevMonthButtonDisabled}
        icon={MdiChevronLeft}
        onClick={params.decreaseMonth}
        size="small"
        variant="transparent"
      />
      <Select
        className={styles.monthPicker}
        items={monthOptions}
        selected={months[getMonth(params.date)]}
        onChange={value => params.changeMonth(months.indexOf(String(value)))}
      />
      <IconButton
        disabled={params.nextMonthButtonDisabled}
        icon={MdiChevronRight}
        onClick={params.increaseMonth}
        size="small"
        variant="transparent"
      />
    </div>
  );
};

const EndInputSection = ({
  clearInputValue,
  hasSelectedValue,
  isNullable,
  showLoader,
  theme,
}: EndInputSectionProps) => {
  return (
    <div className="d-flex align-items-center gap-1">
      {showLoader && <Spinner size={16} />}
      {hasSelectedValue && isNullable && !showLoader && (
        <IconButton
          icon={MdiClose}
          onClick={event => {
            event.stopPropagation();
            clearInputValue();
          }}
          size="small"
          theme={theme}
          variant="transparent"
        />
      )}
    </div>
  );
};

function AsyncDatePicker<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>(
  props: MDSAsyncType<DatePickerProps, string | null, TData, TError, TVariables, TContext> &
    (AsyncNullable<TVariables> | AsyncNotNullable<TVariables>),
) {
  const [validationError, setValidationError] = useState("");
  const mutation = props.mutationHook();
  const mutate = (value: DatePickerProps["value"]) => {
    try {
      if (props.validation) {
        const validation = yup.object({
          temp: props.validation,
        });
        validation.validateSync({ temp: value });
        setValidationError("");
      }
      const data = props.transformQueryData(value as string);
      mutation.mutate(data);
    } catch (error) {
      setValidationError(getAnyErrorKey(error as AxiosError));
    }
  };
  const propsToForward = omit(props, ["transformQueryData", "mutationHook"]);

  return (
    <DatePicker
      {...propsToForward}
      onChange={e => mutate(e ? dateUtils.formatDateToIso(e) : e)}
      textFieldProps={
        ({
          disabled: mutation.isLoading,
          error: validationError || getAnyErrorKey(mutation.error as AxiosError),
          endIcon: MdiCalendarMonth,
          EndInputSection: (
            <EndInputSection
              clearInputValue={() => mutate(null)}
              hasSelectedValue={Boolean(propsToForward.value) && !propsToForward.disabled}
              isNullable={propsToForward.isNullable}
              showLoader={mutation.isLoading}
              theme={propsToForward.textFieldProps?.theme || "light"}
            />
          ),
        } as unknown) as DatePickerProps["textFieldProps"]
      }
    />
  );
}

function FormDatePicker<TForm>(props: MDSFormType<DatePickerProps, TForm>) {
  const [field, meta] = useField(props.name as string);
  const propsToForward = omit(props, ["name"]);
  const { setFieldValue } = useFormikContext<TForm>();

  return (
    <DatePicker
      {...propsToForward}
      {...field}
      onChange={value => {
        if (value) setFieldValue(props.name as string, dateUtils.formatDateToIso(value));
        else setFieldValue(props.name as string, value);
      }}
      textFieldProps={
        ({
          error: meta.touched && meta.error,
          ...props.textFieldProps,
        } as unknown) as DatePickerProps["textFieldProps"]
      }
    />
  );
}

DatePicker.Async = AsyncDatePicker;
DatePicker.Form = FormDatePicker;
