import { useMutation } from "hooks/useMutation";
import { startingPointsApi } from "./api";
import { getAnyErrorKey } from "utilities";
import { useRedux } from "hooks";

const usePostStartingPoint = () => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  return useMutation(startingPointsApi.postStartingPoint, ({ toastr }) => ({
    onSuccess: () => {
      refetchPartials();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePatchStartingPoint = () => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  return useMutation(startingPointsApi.patchStartingPoint, ({ toastr }) => ({
    onSuccess: () => {
      refetchPartials();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

export const startingPointsActions = {
  usePatchStartingPoint,
  usePostStartingPoint,
};
