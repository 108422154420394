import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { NormalizedOrder } from "./ViewOrdersWithWeightsModal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TextField } from "components/miloDesignSystem/atoms/textField";

export const useOrderColumns = () => {
  return useCreateTableColumns<NormalizedOrder>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.signature, {
          header: "zamówienie",
          size: 200,
          typographyProps: {
            fontSize: "16",
            fontWeight: "700",
          },
        }),
        columnHelper.text(row => row.buyer, {
          header: "klient",
          size: 200,
          typographyProps: {
            fontSize: "16",
            fontWeight: "400",
          },
        }),
        columnHelper.text(row => row.address, {
          header: "adres",
          size: 308,
          typographyProps: {
            fontSize: "16",
            fontWeight: "400",
          },
        }),
        columnHelper.accessor(row => row.weight, {
          header: "waga",
          size: 150,
          cell: info => {
            return (
              <div className="d-flex flex-1 align-items-center gap-1">
                <TextField.Form
                  size="small"
                  name={`orders.[${info.row.index}].weight`}
                  type="number"
                />
                <Typography fontSize="12" fontWeight="600" color="neutralBlack64">
                  KG
                </Typography>
              </div>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};
