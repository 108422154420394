import { TreeMenu } from "components/miloDesignSystem/molecules/treeMenu";
import styles from "./ConfigurationTab.module.css";
import { configurationSections } from "./sections/ConfigurationSections";
import { useParams } from "react-router";
import { customerActions } from "api/customers/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";

export const ConfigurationTab = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer, isLoading, error } = customerActions.useCustomer(Number(customerId));

  if (isLoading)
    return (
      <div className="d-flex flex-1 overflow-hidden">
        <Spinner size={26} />
      </div>
    );

  if (error)
    return (
      <div className="d-flex flex-1 overflow-hidden">
        <CommonError status={error._httpStatus_} />
      </div>
    );

  if (!customer) return null;

  return (
    <div className="d-flex flex-1 overflow-hidden">
      <TreeMenu sections={configurationSections}>
        {({ Content, Sidebar }) => (
          <div className="d-flex w-100">
            <div className={styles.sidebar}>{Sidebar}</div>
            <div className="px-4 py-3 d-flex w-100">{Content}</div>
          </div>
        )}
      </TreeMenu>
    </div>
  );
};
