export const correctionSectionListConfig = {
  grid: "15px 1fr 2fr 80px 80px 1fr",
};

export const notificationSectionListConfig = {
  grid: "15px 1.5fr 1fr 1fr 80px 80px",
};

export const whEntrySectionListConfig = {
  grid: "15px 2fr 1fr 1fr 1fr 1fr",
};

export const currencyConvertSectionListConfig = {
  grid: "105px 116px 50px 40px 1fr 1fr 1fr",
};

export const currentlyPrintedReceiptsSectionListConfig = {
  grid: "20px 1fr 1fr 1.5fr 1fr 1fr",
};

export const automaticSmsScheduleSectionListConfig = {
  grid: "1fr 1fr 100px 60px 80px",
};

export const financesAmountSummaryListConfig = {
  grid: "20px 0.5fr 0.5fr 0.5fr 0.5fr",
};
