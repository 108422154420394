import { AddProductToDraftInvoicePayload } from "api/trading-documents/models";
import { financesApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { FormikHelpers } from "formik";
import { getAnyErrorKey } from "utilities";
import { useMutation } from "hooks/useMutation";

const useReportBalance = createApiQuery(financesApi.getReportBalance);

const useAddProductToDraftDocument = (close: () => void) => {
  const addProductToDraftDocumentMutation = useMutation(
    financesApi.postDraftDocumentPosition,
    ({ toastr, queryClient }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Dodano indeks",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: AddProductToDraftInvoicePayload,
    actions: FormikHelpers<AddProductToDraftInvoicePayload>,
  ) => {
    if (values.productElements[0].index) {
      addProductToDraftDocumentMutation.mutate(
        {
          tradingDocumentId: values.tradingDocumentId,
          positions: [
            {
              amountWithTax: values.amountWithTax,
              discount: values.discount,
              name: values.name,
              orderId: values.orderId,
              quantity: values.quantity,
              vatRate: values.vatRate,
              indexId: values.productElements[0].index,
            },
          ],
        },
        {
          onSuccess: () => actions.setSubmitting(false),
          onError: error => {
            actions.setSubmitting(false);
            actions.setErrors(error.response?.data);
          },
        },
      );
    }
  };

  return handleSubmit;
};

export const financesActions = {
  useAddProductToDraftDocument,
  useReportBalance,
};
