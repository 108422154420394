import { CarrierOrder } from "api/logistics/carrierOrders/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { queryString } from "utilities";
import { DOMAIN } from "ENDPOINTS";
import { MdiViewPdf } from "components/miloDesignSystem/atoms/icons/MdiViewPdf";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";

interface Props {
  carrierOrder: CarrierOrder;
  close: () => void;
}

export const PanelHeader = ({ carrierOrder, close }: Props) => {
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden && (
          <SlidingHeaderWrapper className="nowrap">
            {carrierOrder.route.signature}
          </SlidingHeaderWrapper>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Tooltip title="Podgląd PDF ze zleceniem przewozu">
            <IconButton
              icon={<MdiViewPdf size="22" />}
              onClick={() => {
                window.open(
                  `${DOMAIN}carrier-orders/pdf${queryString.stringify({
                    routesIds: carrierOrder.route.id,
                  })}`,
                  "_blank",
                );
              }}
              variant="transparent"
            />
          </Tooltip>
          <span className="divider line-divider" />
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </RightPanelHeader>
    </>
  );
};
