import { PickingTab } from "api/wms/models";

export const tabDict: Record<PickingTab, string> = {
  [PickingTab["all"]]: "Wszystkie",
  [PickingTab["in-progress"]]: "W trakcie",
  [PickingTab["not-started"]]: "Spodziewane",
  [PickingTab["finished"]]: "Zakończone",
};

export const getTabs = (): { label: string; name: string }[] => {
  return Object.entries(tabDict).map(([name, label]) => ({ label, name }));
};
