import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Header } from "./subcomponents/Header";
import { SettingsTabSection } from "./sections/SettingsTabSection";

export const Dashboard = () => {
  return (
    <PageWrapper>
      <Header />
      <div className="d-flex align-items-center my-2 mx-3">
        <SettingsTabSection />
      </div>
    </PageWrapper>
  );
};
