import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import chairIcon from "assets/images/moduleNavigationIcons/chair.svg";

export const etiquettes: ModuleLink = {
  url: "etiquettes",
  label: "Etykiety",
  icon: chairIcon,
  routing: () => <></>,
  isAccessible: false,
};
