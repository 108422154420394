import { RightPanelSection } from "components/utils/drawer";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { ProgressSection } from "pages/wms/unloadingsList/rightPanel/components/progressSection/ProgressSection";
import { UUID } from "api/types";
import { useUnloading } from "api/wms/hooks";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import { OrdersSection } from "pages/wms/unloadingsList/rightPanel/components/ordersSection/OrdersSection";
import { GeneralInfoSection } from "pages/wms/unloadingsList/rightPanel/components/generalInfoSection/GeneralInfoSection";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Link } from "components/miloDesignSystem/atoms/link";

export const UnloadingSection = ({ unloadingId }: { unloadingId: UUID }) => {
  const { data: unloading, error, isLoading } = useUnloading(unloadingId, {
    enabled: Boolean(unloadingId),
  });
  if (error) {
    return (
      <RightPanelSection padding="px-3 pt-2 pb-0">
        <div className="d-flex align-items-center flex-1 justify-content-center">
          <CommonError status={error._httpStatus_} />;
        </div>
      </RightPanelSection>
    );
  }

  if (isLoading) {
    return (
      <RightPanelSection padding="px-3 pt-2 pb-0">
        <div className="d-flex align-items-center flex-1 justify-content-center">
          <Spinner size={34} />
        </div>
      </RightPanelSection>
    );
  }
  assertIsDefined(unloading);

  return (
    <div>
      <RightPanelSection className="px-3 pt-2 pb-0">
        <div className="d-flex align-items-center gap-2">
          <Typography fontSize="16" fontWeight="500">
            Rozładunek:
          </Typography>
          <Link
            fontSize="18"
            fontWeight="700"
            to={`/wms/unloadings/list/all?panelId=${unloadingId}`}
          >
            {unloading.signature}
          </Link>
        </div>
      </RightPanelSection>
      <GeneralInfoSection visibility={{ ramp: false }} unloading={unloading} readOnly />
      <ProgressSection unloading={unloading} readOnly />
      <OrdersSection visibility={{ addIndexButton: false }} unloading={unloading} />
    </div>
  );
};
