import { ForwardedRef, PropsWithChildren } from "react";
import { Typography } from "../typography";
import React from "react";
import { TypographyProps } from "../typography/types";
import styled from "@emotion/styled";
import {
  ButtonStyle,
  buttonVariants,
  disabledButtonVariants,
} from "components/miloDesignSystem/atoms/shared/buttonVariants";
import { ButtonProps, ButtonSize } from "./types";
import { Spinner } from "../spinner";
import { colorPalette } from "../colorsPalette";
import styles from "./Button.module.css";
import { cx } from "utilities";
import { buttonLoaderColors, buttonLoaderSize } from "../shared/buttonLoaderVariants";

const DEFAULT_ICON_SIZE = "16" as const;

const buttonSizes: Record<ButtonSize, { [stylesProp: string]: string }> = {
  small: {
    borderRadius: "4px",
    display: "flex",
    gap: "6px",
    height: "26px",
    padding: "4px 8px",
  },
  medium: {
    borderRadius: "6px",
    display: "flex",
    gap: "10px",
    height: "30px",
    padding: "6px 12px",
  },
  large: {
    borderRadius: "8px",
    display: "flex",
    gap: "10px",
    height: "38px",
    padding: "8px 16px",
  },
};

const StyledButton = styled.button<{
  cursorStyle: string;
  size: ButtonSize;
  variantStyles: {
    default: ButtonStyle;
    hover: ButtonStyle;
    active: ButtonStyle;
  };
}>`
  position: relative;
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  font-style: normal;
  gap: 10px;
  justify-content: center;
  padding: 1px;
  ${props => buttonSizes[props.size]}
  ${props => props.variantStyles.default}
  svg {
    fill: ${props => colorPalette[props.variantStyles.default.icon!]};
  }
  &:hover {
    ${props => props.variantStyles.hover};
  }
  &:hover svg {
    fill: ${props => colorPalette[props.variantStyles.hover.icon!]};
  }
  &:active {
    ${props => props.variantStyles.active};
  }
  &:active svg {
    fill: ${props => colorPalette[props.variantStyles.active.icon!]};
  }
  cursor: ${props => props.cursorStyle};
`;

const sizeSelector: Record<ButtonSize, TypographyProps["fontSize"]> = {
  large: "16",
  medium: "12",
  small: "10",
};

export const Button = React.forwardRef(
  (
    {
      children,
      className,
      disabled = false,
      endIcon,
      isLoading = false,
      size,
      startIcon,
      variant,
      theme = "light",
      typographyProps,
      ...rest
    }: PropsWithChildren<ButtonProps>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const variantStyles = (() => {
      if (disabled && variant !== "success") {
        return disabledButtonVariants[variant][theme];
      }
      return buttonVariants[variant][theme];
    })();

    const cursorStyle = (() => {
      if (disabled) return "not-allowed !important";
      if (isLoading) return "wait !important";
      return "pointer !important";
    })();

    return (
      <StyledButton
        className={className}
        cursorStyle={cursorStyle}
        disabled={disabled || isLoading}
        type="button"
        ref={ref}
        size={size}
        variantStyles={variantStyles}
        {...rest}
      >
        {startIcon && (
          <div
            className={cx({
              [styles.notVisible]: isLoading,
            })}
          >
            <StartIcon startIcon={startIcon} />
          </div>
        )}

        <Typography
          className={cx({
            [styles.notVisible]: isLoading,
          })}
          color="inherit"
          fontFamily={typographyProps?.fontFamily || "sofiaSans"}
          fontSize={sizeSelector[size]}
          fontWeight="700"
        >
          {children}
        </Typography>
        {isLoading && (
          <div className={styles.loaderPosition}>
            <Spinner size={buttonLoaderSize[size]} color={buttonLoaderColors[theme][variant]} />
          </div>
        )}
        {endIcon && (
          <div
            className={cx({
              [styles.notVisible]: isLoading,
            })}
          >
            <EndIcon endIcon={endIcon} />
          </div>
        )}
      </StyledButton>
    );
  },
);

const StartIcon = ({ startIcon: StartIcon }: Pick<ButtonProps, "startIcon">) => {
  if (StartIcon && typeof StartIcon === "function") return <StartIcon size={DEFAULT_ICON_SIZE} />;
  if (StartIcon) return StartIcon;
  return null;
};

const EndIcon = ({ endIcon: EndIcon }: Pick<ButtonProps, "endIcon">) => {
  if (EndIcon && typeof EndIcon === "function") return <EndIcon size={DEFAULT_ICON_SIZE} />;
  if (EndIcon) return EndIcon;
  return null;
};
