import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiDetach = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path d="M4.65776 15.2634C3.48618 16.435 3.48618 18.3345 4.65776 19.5061C5.82933 20.6777 7.72882 20.6777 8.9004 19.5061L11.3753 17.0312C11.7658 16.6407 12.399 16.6407 12.7895 17.0312C13.18 17.4217 13.18 18.0549 12.7895 18.4454L10.3146 20.9203C8.36199 22.8729 5.19616 22.8729 3.24354 20.9203C1.29092 18.9677 1.29092 15.8019 3.24354 13.8492L5.71842 11.3744C6.10894 10.9838 6.7421 10.9838 7.13263 11.3744C7.52315 11.7649 7.52315 12.398 7.13263 12.7886L4.65776 15.2634Z" />
    <path d="M15.2644 4.65873C16.436 3.48716 18.3355 3.48716 19.5071 4.65873C20.6786 5.8303 20.6786 7.7298 19.5071 8.90137L17.0322 11.3762C16.6417 11.7668 16.6417 12.3999 17.0322 12.7905C17.4227 13.181 18.0559 13.181 18.4464 12.7905L20.9213 10.3156C22.8739 8.36296 22.8739 5.19714 20.9213 3.24452C18.9687 1.2919 15.8028 1.2919 13.8502 3.24452L11.3753 5.71939C10.9848 6.10992 10.9848 6.74308 11.3753 7.13361C11.7659 7.52413 12.399 7.52413 12.7895 7.13361L15.2644 4.65873Z" />
    <path d="M15.2646 17.7988C15.8169 17.7988 16.2646 18.2465 16.2646 18.7988V21.3846C16.2646 21.9369 15.8169 22.3846 15.2646 22.3846C14.7124 22.3846 14.2646 21.9369 14.2646 21.3846V18.7988C14.2646 18.2465 14.7124 17.7988 15.2646 17.7988Z" />
    <path d="M17.7998 15.2637C17.7998 15.816 18.2475 16.2637 18.7998 16.2637H21.3856C21.9379 16.2637 22.3856 15.816 22.3856 15.2637C22.3856 14.7114 21.9379 14.2637 21.3856 14.2637H18.7998C18.2475 14.2637 17.7998 14.7114 17.7998 15.2637Z" />
    <path d="M6.36328 8.89941C6.36328 8.34713 5.91557 7.89941 5.36328 7.89941H2.7775C2.22521 7.89941 1.7775 8.34713 1.7775 8.89941C1.7775 9.4517 2.22521 9.89941 2.7775 9.89941H5.36328C5.91557 9.89941 6.36328 9.4517 6.36328 8.89941Z" />
    <path d="M8.89844 6.36328C8.34615 6.36328 7.89844 5.91557 7.89844 5.36328V2.7775C7.89844 2.22521 8.34615 1.7775 8.89844 1.7775C9.45072 1.7775 9.89844 2.22521 9.89844 2.7775V5.36328C9.89844 5.91557 9.45072 6.36328 8.89844 6.36328Z" />
  </SvgWrapper>
);
