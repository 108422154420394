import pinImg from "assets/images/32g.svg";
import cashImg from "assets/images/26.svg";
import weightImg from "assets/images/39g.svg";
import routeImg from "assets/images/40g.svg";
import volumeImg from "assets/images/139.svg";
import { Route } from "api/routes/models";
import { IconLabel } from "components/utils/iconLabel/IconLabel";
import { Button } from "components/common";
import { DOMAIN } from "ENDPOINTS";
import { Link } from "react-router-dom";
import { useSelector } from "hooks";

interface Props {
  route: Route;
}
// we have two the same components here and: (note for future maintenance)
// if you want to change something in this component
// don't forget about second one:
// src/pages/drivers/diverRoutes/rightPanel/countersSection/CountersSection.tsx
export const CountersSection = ({ route }: Props) => {
  const me = useSelector(store => store.auth.user!);

  const calculated = route.orders.reduce(
    (acc, order) => {
      const amount = order.items.reduce((acc, el) => acc + el.amount * el.quantity, 0);
      acc.weight += Number(order.totalWeight);
      acc.price += amount;
      acc.volume += Number(order.totalVolume);
      return acc;
    },
    { weight: 0, price: 0, volume: 0 },
  );

  return (
    <>
      <div className="d-flex flex-wrap mt-4">
        <IconLabel img={pinImg} title="punkty" value={String(route.orders.length)} />
        <IconLabel
          img={cashImg}
          title="kwota"
          manyValues
          value={
            route.paymentSummary?.length > 0
              ? route.paymentSummary
                  .map(summary => {
                    return `${summary.summary} ${summary.currency}`;
                  })
                  .join("; ")
              : "0.00"
          }
        />
        <IconLabel img={weightImg} title="waga" value={`${calculated.weight.toFixed(1)}kg`} />
        <IconLabel
          img={routeImg}
          title="długość trasy"
          value={`${(route.length / 1000).toFixed(0)}km`}
        />
        <IconLabel img={volumeImg} title="kubatura" value={`${calculated.volume.toFixed(1)}m³`} />
        <IconLabel
          img={cashImg}
          title="kwota potwierdzona"
          manyValues
          value={
            route.paymentSummary?.length > 0
              ? route.paymentSummary
                  .map(summary => {
                    if (summary.summaryConfirmedDelivery !== undefined)
                      return `${summary.summaryConfirmedDelivery} ${summary.currency}`;
                    return "--";
                  })
                  .join("; ")
              : "0.00"
          }
        />
      </div>
      <div className="d-flex justify-content-between mt-2">
        <Button as={Link} kind="create" to={`/finances/sales/list/all?routes=${route.id}`}>
          <div className="btnBase btnBaseSmall">Przejdź do faktur trasy</div>
        </Button>
        <Button
          as="a"
          href={`${DOMAIN}routes/${route.id}/preview-invoices?user=${me.id}`}
          kind="primary"
          rel="noopener noreferrer"
          size="small"
          target="_blank"
          title="Podgląd PDF faktur dla trasy"
        >
          Podgląd PDF faktur dla trasy
        </Button>
      </div>
    </>
  );
};
