import {
  createProductionOrdersQuery,
  getManufacturerOrder,
  getManufacturerOrders,
} from "api/production-orders/calls-manufacturer";
import {
  getProductionOrder,
  getProductionOrders,
} from "api/production-orders/calls-production-orders";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher, createApiQuery } from "hooks/createApiQuery";
import { createPrimitiveHook, createPrimitivePaginatedHook } from "hooks/createPrimitiveHook";
import { createMutationHook } from "utilities/createMutationHook";
import { productionOrderKeys } from "./keys";
import { ManufacturerOrder } from "./models";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";

export const useManufacturerOrders = createPrimitivePaginatedHook(getManufacturerOrders);
export const useManufacturerOrder = createPrimitiveHook(getManufacturerOrder);
export const useProductionOrders = createPrimitivePaginatedHook(getProductionOrders);
export const useProductionOrder = createPrimitiveHook(getProductionOrder);

export const useManufacturerOrderQuery = createApiQuery(
  (id: string): ApiFetcher<ManufacturerOrder> => ({
    key: productionOrderKeys.forManufacturers.order(id),
    fetcher: () =>
      queryFetch({
        method: "GET",
        url: "/production-orders/for-manufacturer/" + id,
      }),
  }),
);

export const useManufacturerOrderPackageMutation = createMutationHook(
  (id: number, toUpdate: Partial<{ isReceived: boolean }>) =>
    queryFetch<unknown>({
      method: "PATCH",
      url: "/production-orders/item-packages/" + id,
      data: toUpdate,
    }),
);

const useCreateProductionOrders = () =>
  useMutation(createProductionOrdersQuery, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        text: getAnyErrorKey(error),
        title: "Wymagane działanie",
        type: "warning",
      });
    },
  }));

export const productionOrdersActions = {
  useCreateProductionOrders,
};
