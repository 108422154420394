import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import productionSchemas from "assets/images/moduleNavigationIcons/schemeAlt.svg";
import { ManufacturingSchemas } from "routes/manufacturingNew/ManufacturingSchemas";

export const manufacturingSchemas: ModuleLink = {
  url: "manufacturing-schemas",
  label: "Schematy produkcji",
  icon: productionSchemas,
  routing: ManufacturingSchemas,
};
