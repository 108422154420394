import { manufacturingActions } from "api/manufacturing/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { useFilters } from "hooks/useFilters";
import { SectionWrapper } from "pages/crm/customers/customerDetails/components/sectionWrapper/SectionWrapper";
import { useParams } from "react-router";
import { getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import folder from "assets/images/folder.svg";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../../../IndexDetails.module.css";
import { MdiSchemeAlt } from "components/miloDesignSystem/atoms/icons/MdiSchemeAlt";
import { Stage } from "./components/Stage";
import { Link } from "components/miloDesignSystem/atoms/link";
import { useNavigate } from "hooks/useNavigate";
import { IndexStages } from "api/manufacturing/models";
import { AssignSchema } from "pages/manufacturingNew/shared/assignSchema/AssignSchema";
import { IndexDetails } from "api/manufacturing/indexes/models";
import { indexesActions } from "api/manufacturing/indexes/actions";

interface Props {
  indexDetails: IndexDetails;
}

export const ProductionSchemaTab = ({ indexDetails }: Props) => {
  const { indexId } = useParams<{ indexId: string }>();
  const { searchParams } = useFilters<{ index: string }>({ index: indexId });
  const { data, error, isLoading, refetch } = manufacturingActions.useIndexStages(searchParams);
  const schemaMutation = indexesActions.usePatchIndex();

  if (isLoading)
    return (
      <SectionWrapper
        className="d-flex flex-1 justify-content-center align-items-center"
        width="100%"
        minHeight={516}
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error)
    return (
      <SectionWrapper
        className="d-flex flex-1 justify-content-center align-items-center"
        width="100%"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  const schema = data[0];

  if (!data.length) {
    return (
      <div className="d-flex flex-column align-items-center mt-4">
        <img alt="brak zleceń" src={folder} className={styles.emptyIcon} />

        <Typography fontSize="16" fontWeight="700" className="my-2">
          Wybrany wariant nie ma przypisanego schematu
        </Typography>
        <Typography color="neutralBlack48" fontSize="14" fontWeight="500">
          Dodaj go klikając w przycisk poniżej
        </Typography>
        <div className="pt-4">
          <AssignSchema
            customButton={props => (
              <Button onClick={props.onClick} size="medium" startIcon={MdiAdd} variant="deepPurple">
                Dodaj schemat
              </Button>
            )}
            indexDetails={{
              initialSchema: schema,
              name: indexDetails.name,
              signature: indexDetails.signature,
            }}
          >
            {({ close, schema }) => (
              <Button
                className="text-uppercase"
                endIcon={MdiSchemeAlt}
                isLoading={schemaMutation.isLoading}
                onClick={() =>
                  schemaMutation.mutate(
                    {
                      id: indexDetails.id,
                      toUpdate: {
                        manufacturingSchema: schema,
                      },
                    },
                    {
                      onSuccess: () => {
                        refetch();
                        close();
                      },
                    },
                  )
                }
                size="small"
                variant="deepPurple"
              >
                Przypisz schemat
              </Button>
            )}
          </AssignSchema>
        </div>
      </div>
    );
  }

  assertIsDefined(data);

  return (
    <div className="d-flex flex-column flex-1 mt-3 mx-3 overflow-auto">
      <Typography
        color="neutralBlack48"
        fontSize="10"
        fontWeight="700"
        className="mb-2 text-uppercase"
      >
        schemat:
      </Typography>
      {schema && (
        <div className="d-flex align-items-center gap-2 mb-4">
          <Typography fontSize="20" fontWeight="700">
            {schema.name}
          </Typography>
          <Link
            fontSize="14"
            fontWeight="500"
            to={`/manufacturing/manufacturing-schemas/list/all?panelId=${schema.id}`}
          >
            {schema.signature}
          </Link>
          <AssignSchema
            indexDetails={{
              initialSchema: schema,
              name: indexDetails.name,
              signature: indexDetails.signature,
            }}
          >
            {({ close, schema }) => (
              <Button
                className="text-uppercase"
                endIcon={MdiSchemeAlt}
                isLoading={schemaMutation.isLoading}
                onClick={() =>
                  schemaMutation.mutate(
                    {
                      id: indexDetails.id,
                      toUpdate: {
                        manufacturingSchema: schema,
                      },
                    },
                    {
                      onSuccess: () => {
                        refetch();
                        close();
                      },
                    },
                  )
                }
                size="small"
                variant="deepPurple"
              >
                Zmień schemat
              </Button>
            )}
          </AssignSchema>
        </div>
      )}
      <Typography
        fontSize="10"
        fontWeight="700"
        className="text-uppercase mb-2"
        color="neutralBlack48"
      >
        etapy produkcji:
      </Typography>
      <ProductionSchemas schema={schema} />
    </div>
  );
};

const ProductionSchemas = ({ schema }: { schema?: IndexStages }) => {
  const navigate = useNavigate();

  if (!schema?.stages.length) {
    return (
      <div className="d-flex flex-column align-items-center mt-4">
        <img alt="brak zleceń" src={folder} className={styles.emptyIcon} />

        <Typography fontSize="16" fontWeight="700" className="my-2">
          Wybrany schemat jeszcze nie ma dodanych etapów
        </Typography>
        <Typography color="neutralBlack48" fontSize="14" fontWeight="500">
          Dodaj je klikając w przycisk poniżej
        </Typography>
        <div className="pt-4">
          <Button
            size="medium"
            startIcon={MdiAdd}
            variant="deepPurple"
            onClick={() => {
              navigate(`/manufacturing/manufacturing-schemas/list/all?panelId=${schema?.id}`);
            }}
          >
            Dodaj etapy
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      {schema.stages.map((stage, index) => (
        <Stage key={stage.id} stage={stage} index={index} />
      ))}
    </div>
  );
};
