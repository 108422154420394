import { IndexAssignment } from "api/indexes/models";
import { indexesActions } from "api/manufacturing/indexes/actions";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { useSelector } from "hooks";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { MdiDelete } from "../../../../../../../components/miloDesignSystem/atoms/icons/MdiDelete";

export const useExternalIndexesColumns = () => {
  const customers = useSelector(store => store.partials.customers);
  const removeAssignmentMutation = indexesActions.useRemoveIndexAssignment();

  return useCreateTableColumns<IndexAssignment>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => customers.find(customer => customer.id === row.customer)?.name, {
          header: "kontrahent",
          size: 150,
          typographyProps: {
            fontSize: "14",
          },
        }),
        columnHelper.text(row => row.externalName, {
          header: "nazwa",
          size: 250,
          typographyProps: {
            fontSize: "14",
            fontWeight: "400",
          },
        }),
        columnHelper.text(row => row.externalCode, {
          header: "kod",
          size: 120,
          typographyProps: {
            fontSize: "14",
          },
        }),
        columnHelper.accessor(row => row, {
          id: "deleteExternalIndex",
          header: " ",
          size: 30,
          cell: info => {
            const assignment = info.getValue();

            return (
              <IconButton
                icon={MdiDelete}
                isLoading={removeAssignmentMutation.isLoading}
                onClick={() => removeAssignmentMutation.mutate(assignment.id)}
                variant="transparent"
              />
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};
