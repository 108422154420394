import styles from "../../WarehouseFinancialState.module.css";
import cx from "classnames";
import { usePackagesInOrOutSummary } from "api/trading-documents/hooks";
import { dateFns, queryString } from "utilities";
import { getDateThirtyDaysBeforeToday } from "../../utils/getDateThirtyDaysBeforeToday";
import { useFilters } from "hooks/useFilters";
import { Loader } from "components/utils/loader";
import { CommonError, RightPanelHandler } from "components/utils";
import { InvoicePagination } from "components/common/invoicePagination/InvoicePagination";
import { NoResults } from "components/utils/noResults";
import { ListDrawerWrapper } from "components/utils/drawer";
import { PackageOutRow } from "./PackageOutRow";
import { RightPanel } from "./rightPanel/RightPanel";
import { useQuery, useToastr } from "hooks";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { handleCsvDownload } from "../../utils/handleCsvDownload";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadCsv } from "../../../../../components/miloDesignSystem/atoms/icons/MdiDownloadCsv";

export type PackagesInFilters = {
  page: string;
  search: string;
  outAtFrom: string;
  outAtTo: string;
};

const initialFilters: PackagesInFilters = {
  page: "1",
  search: "",
  outAtFrom: getDateThirtyDaysBeforeToday(),
  outAtTo: dateFns.format(new Date(), "yyyy-MM-dd"),
};

export const PackagesOutList = () => {
  const { query } = useQuery();
  const { warehouses, outAtFrom, outAtTo } = query;
  const { setFilter, filters } = useFilters<PackagesInFilters>(initialFilters);
  const location = useLocation();
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const toastr = useToastr();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const warehouses = searchParams.get("warehouses");
    const outAtFrom = searchParams.get("outAtFrom");
    const outAtTo = searchParams.get("outAtTo");

    if (warehouses || outAtFrom || outAtTo) {
      setFilter("page", "1");
    }
  }, [location.search, setFilter]);

  const search = queryString.stringify({ ...filters, warehouses, outAtFrom, outAtTo });
  const { data: packagesOut, error, isFetching, isLoading, pagination } = usePackagesInOrOutSummary(
    search,
  );

  const noResults = !packagesOut.length && !isLoading && !error;

  if (error) {
    return (
      <div className="w-100 pb-3">
        <div className={styles.listTable}>
          <h3 className={cx(styles.listHeader, "pt-3 pb-1 px-3")}>Wydano przez ostatnie 30 dni</h3>
          <div className="mt-4">
            <CommonError status={error._httpStatus_} />
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="w-100 pb-3">
        <Loader module="finances" />
      </div>
    );
  }

  return (
    <div className="w-100 pb-3">
      {isFetching && <Loader module="finances" />}
      <RightPanelHandler name="warehousePackagesOutSummary" overrittenPanelKey="packageOut">
        {({ close, isActive, isHighlighted, togglePanel }) => (
          <ListDrawerWrapper>
            <div className={styles.listTable}>
              <h3 className={cx(styles.listHeader, "pt-3 pb-1 px-3")}>
                Wydano przez ostatnie 30 dni
              </h3>
              <div className="d-flex align-items-center justify-content-between px-3 pb-2">
                <div className={styles.search} style={{ maxWidth: "600px", minWidth: "320px" }}>
                  <input
                    type="search"
                    onChange={event => {
                      setFilter("search", event.target.value);
                      setFilter("page", "1");
                    }}
                    placeholder="Szukaj wydanych paczek..."
                  />
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <Tooltip title="Pobierz CSV dla wydanych paczek">
                    <IconButton
                      icon={MdiDownloadCsv}
                      onClick={() => {
                        handleCsvDownload(
                          downloadFeedbackToastr,
                          getCsvDownloadFileName(query),
                          getCsvDownloadQueryParams(query),
                          toastr,
                        );
                      }}
                      variant="transparent"
                    />
                  </Tooltip>
                  {Boolean(packagesOut.length) ? (
                    <InvoicePagination
                      mode="manual"
                      onChange={page => {
                        setFilter("page", String(page));
                      }}
                      overrides={{ wrapper: { className: styles.paginationBackground } }}
                      page={Number(filters.page)}
                      pagination={pagination}
                      position="relative"
                    />
                  ) : (
                    <div style={{ height: "42px" }} />
                  )}
                </div>
              </div>
              <div className={cx(styles.tableHeader, styles.tableHeaderWarehouseState)}>
                <div>#</div>
                <div>nazwa paczki</div>
                <div>kod</div>
                <div>wydanie</div>
                <div className="d-flex align-items-center justify-content-end">ostatnia cena</div>
                <div className="d-flex align-items-center justify-content-end">wydano</div>
                <div className="d-flex align-items-center justify-content-end">suma wydań</div>
              </div>
              {noResults ? (
                <NoResults
                  on={noResults}
                  overwrites={{ background: { className: styles.noResult } }}
                  subHeader="przyjęte paczki"
                />
              ) : (
                <div
                  className={cx(styles.list, "w-100")}
                  style={{ maxHeight: "50vh", height: "initial" }}
                >
                  {packagesOut.map((packageOut, index) => (
                    <PackageOutRow
                      index={index}
                      isActive={isActive}
                      isHighlighted={isHighlighted}
                      key={`${packageOut.id}-out`}
                      packageOut={packageOut}
                      togglePanel={togglePanel}
                    />
                  ))}
                </div>
              )}
            </div>
            <RightPanel close={close} />
          </ListDrawerWrapper>
        )}
      </RightPanelHandler>
    </div>
  );
};

const getCsvDownloadQueryParams = (query: { [x: string]: string }): string => {
  return queryString.stringify({
    outAtFrom: query["outAtFrom"],
    outAtTo: query["outAtTo"],
  });
};

const getCsvDownloadFileName = (query: { [x: string]: string }): string => {
  const outAtFrom = query["outAtFrom"] ? query["outAtFrom"] : "";
  const outAtTo = query["outAtTo"] ? query["outAtTo"] : "";
  if (outAtFrom && outAtTo) return `csv-wydanie-${outAtFrom}-${outAtTo}`;
  return `csv-wydanie`;
};
