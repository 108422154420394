import { useQuery } from "hooks";
import { getSearch } from "./utils/getSearch";
import { financesOverviewActions } from "api/trading-documents/overview/actions";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { Table } from "components/miloDesignSystem/molecules/table";
import { SoldPackage } from "api/trading-documents/overview/models";
import { Assign } from "utility-types";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import cuid from "cuid";
import { useSoldPackagesColumns } from "./useSoldPackagesColumns";
import { dateFns, dateUtils } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiDownloadCsv } from "components/miloDesignSystem/atoms/icons/MdiDownloadCsv";
import { useDefaultFilters } from "hooks/useDefaultFilters";
import { filterFactory, withFilters } from "components/utils/withFilters";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { financesOverviewFileFactory } from "api/trading-documents/overview/api";

const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Select,
    kind: "label",
    label: "Grupuj po",
    name: "grouping_variable",
    options: [
      { label: "dzień", value: "DAY" },
      { label: "tydzień", value: "WEEK" },
      { label: "miesiąc", value: "MONTH" },
    ],
  },
  {
    type: FilterType.DateRange,
    label: "okres",
    name: ["start_date", "end_date"],
  },
]);

export const SoldPackagesList = withFilters(filterList, () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query });
  const {
    data: soldPackages,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = financesOverviewActions.useSoldPackages(search);
  const columns = useSoldPackagesColumns();
  const tableProps = useTableFeatureConnector({
    rows: soldPackages.map(soldPackage => ({
      ...soldPackage,
      id: cuid(),
    })),
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  useDefaultFilters({
    grouping_variable: "DAY",
    start_date: dateUtils.formatDateToIso(dateFns.subDays(new Date(), 14)),
    end_date: dateUtils.formatDateToIso(new Date()),
  });

  return (
    <>
      <PageWrapper>
        <PageHeader
          bigTitle="Zestawienie sprzedanych paczek"
          searchInput={{
            label: "Szukaj...",
            tags: [],
          }}
          actionButtons={
            <div className="d-flex align-items-center gap-1">
              <FileDownloadHandler
                factoryFn={() => financesOverviewFileFactory.soldPackagesCsv(search)}
                type="csv"
              >
                {({ download, isLoading }) => (
                  <Button
                    className="text-uppercase"
                    isLoading={isLoading}
                    onClick={download}
                    size="small"
                    startIcon={MdiDownloadCsv}
                    variant="gray"
                  >
                    Pobierz zestawienie sprzedanych paczek
                  </Button>
                )}
              </FileDownloadHandler>
            </div>
          }
          viewLabel="FINANCES_OVERVIEW_SOLD_PACKAGES"
        />

        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<Assign<SoldPackage, { id: string }>>
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={mainListUiSchema}
            {...tableProps}
          />
        </div>
      </PageWrapper>
    </>
  );
});
