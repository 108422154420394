import {
  NormalizedTradingDocumentSummary,
  TradingDocument,
  TradingDocumentSummaryType,
} from "api/trading-documents/models";
import { useSummaryColumns } from "./useSummaryColumns";
import cuid from "cuid";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";

interface Props {
  tradingDocument: TradingDocument;
}

export const SummarySection = ({ tradingDocument }: Props) => {
  const normalizedRows = getNormalizedSummaryData(
    tradingDocument.amountSummaryPerVatRate,
    tradingDocument.amountSummary,
  );
  const columns = useSummaryColumns(tradingDocument);

  return (
    <div className="pt-4">
      <Table<NormalizedTradingDocumentSummary>
        columns={columns}
        rows={normalizedRows}
        isLoading={false}
        error={null}
        uiSchema={comfortableListUiSchema}
      />
    </div>
  );
};

const getNormalizedSummaryData = (
  amountSummaryPerVatRate: TradingDocument["amountSummaryPerVatRate"],
  amountSummary: TradingDocument["amountSummary"],
): NormalizedTradingDocumentSummary[] => {
  return [
    ...amountSummaryPerVatRate.map(summary => ({
      ...summary,
      id: cuid(),
      type: TradingDocumentSummaryType.VAT_RATE,
    })),
    {
      id: cuid(),
      totalWithoutTax: amountSummary.totalWithoutTax,
      totalWithTax: amountSummary.totalWithTax,
      totalTax: amountSummary.totalTax,
      type: TradingDocumentSummaryType.TOTAL,
    },
  ];
};
