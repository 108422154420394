import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import { pickupPointsApi } from "./api";
import { pickupPointsKeys } from "./keys";
import { StartingPoint } from "api/other/models";
import { startingPointsUtils } from "utilities/startingPoints";
import { assertIsDefined } from "utilities/assertIsDefined";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";

const usePickupPoints = createPaginatedApiQuery(pickupPointsApi.getPickupPoints);

const useCreatePickupPoint = () => {
  return useMutation(pickupPointsApi.postPickupPoint, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePatchPickupPoint = (startingPoint: StartingPoint) => {
  return useMutation(pickupPointsApi.patchPickupPoint, ({ queryClient, queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        pickupPointsKeys.pickupPoint.list(
          startingPointsUtils.getPickupPointsSearch(startingPoint.id),
        ),
        id,
        toUpdate,
      );

      return { prevList };
    },
    onSuccess: () => queryClient.invalidateQueries(),
    onError: (error, { id }, onMutationReturn) => {
      assertIsDefined(onMutationReturn);
      queryUtils.rollbackList(
        pickupPointsKeys.pickupPoint.list(
          startingPointsUtils.getPickupPointsSearch(startingPoint.id),
        ),
        onMutationReturn.prevList,
        id,
        error,
      );
    },
  }));
};

const useDeletePickupPoint = () => {
  return withDeleteConfirmation(
    useMutation(
      pickupPointsApi.deletePickupPoint,
      ({ queryUtils }) => queryUtils.invalidateOnSuccessAndHandleError,
    ),
  )();
};

export const pickupPointsActions = {
  useCreatePickupPoint,
  useDeletePickupPoint,
  usePatchPickupPoint,
  usePickupPoints,
};
