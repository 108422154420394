import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useErrorToastr } from "hooks/useErrorToastr";
import {
  deletePackageAssign,
  getPackagesQuery,
  postPackageAssign,
  warehouseFileFactory,
} from "./calls";
import { fileDownloader } from "fileDownloader";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";

const usePackages = createPaginatedApiQuery(getPackagesQuery);

const useDownloadPackagesCsv = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async () => {
    const tstr = downloadFeedbackToastr.open({ type: "csv", calculateProgress: false });
    const { url, name } = warehouseFileFactory.packagesCsv();
    const response = await fileDownloader({
      url,
      name,
      type: "csv",
      onProgress: tstr.updateProgress,
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else if (response.error) {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useDownloadIndexesCsv = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async () => {
    const tstr = downloadFeedbackToastr.open({ type: "csv", calculateProgress: false });
    const { url, name } = warehouseFileFactory.indexesCsv();
    const response = await fileDownloader({
      url,
      name,
      type: "csv",
      onProgress: tstr.updateProgress,
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else if (response.error) {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useDownloadOrderCsv = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async () => {
    const tstr = downloadFeedbackToastr.open({ type: "csv", calculateProgress: false });
    const { url, name } = warehouseFileFactory.orderCsv();
    const response = await fileDownloader({
      url,
      name,
      type: "csv",
      onProgress: tstr.updateProgress,
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else if (response.error) {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useAddPackageToIndex = (
  setIsAddingPackage: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  return useMutation(postPackageAssign, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      setIsAddingPackage(false);
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useRemovePackageFromIndex = () => {
  return withDeleteConfirmation(
    useMutation(deletePackageAssign, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Usunięto paczkę z indeksu",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć tę paczkę z indeksu?",
  )();
};

export const warehouseActions = {
  useAddPackageToIndex,
  useDownloadIndexesCsv,
  useDownloadOrderCsv,
  useDownloadPackagesCsv,
  usePackages,
  useRemovePackageFromIndex,
};
