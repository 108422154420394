import { manufacturingActions } from "api/manufacturing/actions";
import { ManufacturerEmailsTab } from "api/manufacturing/models";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useQuery } from "hooks";
import { RouteComponentProps, useParams } from "react-router";
import { useManufacturerEmailsColumns } from "./useManufacturerEmailsColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { PageHeader } from "components/common";
import { manufacturers } from "components/common/moduleNavigation/moduleConfig/manufacturing/routes/manufacturers";
import { manufacturersUtils } from "utilities/manufacturers";
import { ManufacturingEmail } from "../../../../api/manufacturing/models";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { manufacturersActions } from "api/manufacturers/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";

export const ManufacturerEmailsList = ({
  match,
}: RouteComponentProps<{ tab: ManufacturerEmailsTab }>) => {
  const { tab } = match.params;
  const { manufacturerId } = useParams<{ manufacturerId: string }>();
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = manufacturersUtils.getManufacturerEmailsSearch({ manufacturerId, query, tab });
  const {
    data: emails,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = manufacturingActions.useManufacturerOrderEmails(search);
  const {
    data: manufacturer,
    isLoading: isManufacturerLoading,
  } = manufacturersActions.useManufacturer(Number(manufacturerId), {
    enabled: Boolean(manufacturerId),
  });

  const columns = useManufacturerEmailsColumns();
  const tableProps = useTableFeatureConnector({
    rows: emails,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  return (
    <PageWrapper>
      <PageHeader
        tabs={{
          list: manufacturersUtils.getManufacturerEmailsTabs(),
          routesRoot: `manufacturing/${manufacturers.url}/${manufacturerId}/emails`,
          title: isManufacturerLoading ? (
            <Spinner size={16} />
          ) : manufacturer ? (
            manufacturer.name
          ) : null,
        }}
        searchInput={{
          label: "Szukaj wśród wiadomości email",
          tags: [],
        }}
        viewLabel="MANUFACTURERS_EMAILS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<ManufacturingEmail>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
      </div>
    </PageWrapper>
  );
};
