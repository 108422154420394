import { Product } from "api/products/models";
import { Search } from "components/miloDesignSystem/molecules/search";
import { getProductsQuery } from "api/products/calls";
import { ProductForm } from "./ProductForm";
import { TradingDocument } from "api/trading-documents/models";

interface Props {
  close: () => void;
  draftInvoice: TradingDocument;
  product: Product | null;
  setProduct: React.Dispatch<React.SetStateAction<Product | null>>;
}

export const ProductSection = ({ close, draftInvoice, product, setProduct }: Props) => {
  return (
    <div>
      <div className="pt-2 pb-4">
        <Search
          fetcherFn={getProductsQuery}
          isNullable
          onChange={product => setProduct(product)}
          transformQuery={{ isDeleted: "false" }}
          normalizeItem={product => getNormalizedProduct(product)}
        />
      </div>
      {product && <ProductForm close={close} draftInvoice={draftInvoice} product={product} />}
    </div>
  );
};

const getNormalizedProduct = (product: Product) => ({
  value: product ? String(product.id) : "",
  text: product ? `${product.name}, ${product.attributes.map(attribute => attribute.name)}` : "",
});
