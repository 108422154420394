import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { FreeSingleItemQuantitiesDetails } from "api/orders/models";
import { hasAttributes } from "components/miloDesignSystem/molecules/attributes";
import { Attributes } from "components/miloDesignSystem/molecules/attributes";

export const usePackageColumns = () => {
  return useCreateTableColumns<FreeSingleItemQuantitiesDetails["packages"][number]>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.name, {
          header: "mebel",
          size: 275.5,
        }),
        columnHelper.accessor(row => row.description, {
          header: "atrybuty",
          size: 275.5,
          cell: info => {
            const description = info.getValue();
            if (!description || !hasAttributes(description)) return <EmptyValue />;
            return <Attributes attributes={description} noWrap />;
          },
        }),
        columnHelper.text(row => row.location, {
          header: "lokalizacje",
          size: 95,
          typographyProps: {
            fontSize: "14",
            fontWeight: "600",
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};
