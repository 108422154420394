import { UnloadingDetails, statusDict } from "api/wms/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  unloading: UnloadingDetails;
}

export const TitleSection = ({ unloading }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <div className="d-flex align-items-center gap-2" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {unloading.signature}
        </Typography>
        <Tag
          startIcon={MdiPoint}
          label={statusDict[unloading.status].label}
          variant={statusDict[unloading.status].variant}
        />
        {unloading.ramp && <Tag label={`Rampa ${unloading.ramp?.name}`} variant="warning" />}
      </div>
    </RightPanelSection>
  );
};
