import { RightPanelSection } from "components/utils/drawer";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { UUID } from "api/types";
import { usePicking } from "api/wms/hooks";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { GeneralInfoSection } from "pages/wms/pickingsList/rightPanel/components/GeneralInfoSection";
import { ProgressSection } from "pages/wms/pickingsList/rightPanel/components/ProgressSection";
import { LineItemsSection } from "pages/wms/pickingsList/rightPanel/components/LineItemsSection";
import { Link } from "components/miloDesignSystem/atoms/link";

export const PickingSection = ({ pickingId }: { pickingId: UUID }) => {
  const { data: picking, error, isLoading } = usePicking(pickingId, {
    enabled: Boolean(pickingId),
  });

  if (error) {
    return (
      <RightPanelSection className="px-3 pt-2 pb-0">
        <div className="d-flex align-items-center flex-1 justify-content-center">
          <CommonError status={error._httpStatus_} />
        </div>
      </RightPanelSection>
    );
  }

  if (isLoading) {
    return (
      <RightPanelSection className="px-3 pt-2 pb-0">
        <div className="d-flex align-items-center flex-1 justify-content-center">
          <Spinner size={34} />
        </div>
      </RightPanelSection>
    );
  }
  assertIsDefined(picking);

  return (
    <div className="mt-3">
      <RightPanelSection className="px-3 pt-2 pb-0">
        <div className="d-flex align-items-center gap-2">
          <Typography fontSize="16" fontWeight="500">
            Picking:
          </Typography>
          <Link fontSize="18" fontWeight="700" to={`/wms/pickings/list/all?panelId=${pickingId}`}>
            {picking.signature}
          </Link>
        </div>
      </RightPanelSection>
      <GeneralInfoSection picking={picking} readOnly visibility={{ ramp: false }} />
      <ProgressSection picking={picking} readyOnly />
      <LineItemsSection picking={picking} />
    </div>
  );
};
