import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { TradingDocument } from "api/trading-documents/models";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler/FileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDownloadXml } from "components/miloDesignSystem/atoms/icons/MdiDownloadXml";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { fileFactoryUtils } from "utilities/fileFactory";

interface Props {
  tradingDocument: TradingDocument;
}

export const DownloadTradingDocumentXml = ({ tradingDocument }: Props) => {
  return (
    <FileDownloadHandler
      factoryFn={() =>
        tradingDocumentFileFactory.optimaXml(
          {
            invoiceType: tradingDocument.invoiceType,
            type: tradingDocument.type,
          },
          [tradingDocument.id],
          `Faktura-${fileFactoryUtils.formatSignature(
            tradingDocument.signature,
          )}-${fileFactoryUtils.getDate()}`,
        )
      }
      type="xml"
    >
      {({ download, isLoading }) => (
        <Tooltip title="Pobierz XML z danymi faktury">
          <IconButton
            icon={<MdiDownloadXml size="18" />}
            isLoading={isLoading}
            onClick={download}
            variant="transparent"
          />
        </Tooltip>
      )}
    </FileDownloadHandler>
  );
};
