import { Customer } from "api/customers/models";
import { sellersActions } from "api/sellers/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { CommonError } from "components/utils/commonError";
import styles from "../PaymentsSection.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Select } from "components/miloDesignSystem/molecules/select";
import { dictToList } from "utilities";
import { CURRENCY_TYPE, countryCurrenciesFlags } from "CONSTANTS";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { DefaultPaymentMethod, defaultPaymentMethods } from "api/sellers/models";

interface Props {
  customer: Customer;
}

export const DefaultTransactionContent = ({ customer }: Props) => {
  const { data: businessEntity, error, isLoading } = sellersActions.useSeller(
    {
      id: customer.businessEntity!.id,
    },
    { enabled: Boolean(customer.businessEntity) },
  );
  const currenciesOptions = dictToList(countryCurrenciesFlags).map(({ key, value }) => ({
    icon: value,
    text: key,
    type: MenuItemType.ICON,
    value: key,
  }));
  const defaultPaymentMethodsOptions = defaultPaymentMethods.map(defaultMethod => ({
    text: defaultMethod.name,
    type: MenuItemType.TEXT,
    value: defaultMethod.value,
  }));

  if (isLoading)
    return (
      <div className="d-flex flex-column gap-3 px-1 py-2">
        <Typography
          className="text-uppercase"
          color="neutralBlack48"
          fontSize="10"
          fontWeight="700"
        >
          Domyślne dane transakcyjne
        </Typography>
        <Spinner size={26} />
      </div>
    );

  if (error)
    return (
      <div className="d-flex flex-column gap-3 px-1 py-2">
        <Typography
          className="text-uppercase"
          color="neutralBlack48"
          fontSize="10"
          fontWeight="700"
        >
          Domyślne dane transakcyjne
        </Typography>
        <CommonError status={error._httpStatus_} />
      </div>
    );

  if (!businessEntity) return null;

  return (
    <div className="d-flex flex-column gap-3 py-2">
      <Typography className="text-uppercase" color="neutralBlack48" fontSize="10" fontWeight="700">
        Domyślne dane transakcyjne
      </Typography>
      <TextField.Async
        containerClassName="w-50"
        label="Bank [PLN]"
        mutationHook={sellersActions.usePatchSeller}
        transformQueryData={plnBankName => ({
          id: businessEntity.id,
          toUpdate: {
            plnBankName,
          },
        })}
        size="small"
        value={businessEntity.plnBankName}
      />
      <TextField.Async
        containerClassName="w-50"
        label="Nr konta bankowego [PLN]"
        mutationHook={sellersActions.usePatchSeller}
        transformQueryData={plnBankAccountNumber => ({
          id: businessEntity.id,
          toUpdate: {
            plnBankAccountNumber,
          },
        })}
        size="small"
        value={businessEntity.plnBankAccountNumber}
      />
      <TextField.Async
        containerClassName="w-50"
        label="Bank [EUR]"
        mutationHook={sellersActions.usePatchSeller}
        transformQueryData={eurBankName => ({
          id: businessEntity.id,
          toUpdate: {
            eurBankName,
          },
        })}
        size="small"
        value={businessEntity.eurBankName}
      />
      <TextField.Async
        containerClassName="w-50"
        label="Nr konta bankowego [EUR]"
        mutationHook={sellersActions.usePatchSeller}
        transformQueryData={eurBankAccountNumber => ({
          id: businessEntity.id,
          toUpdate: {
            eurBankAccountNumber,
          },
        })}
        size="small"
        value={businessEntity.eurBankAccountNumber}
      />
      <Select.Async
        className={styles.currencySelector}
        items={currenciesOptions}
        label="Waluta (faktury)"
        mutationHook={sellersActions.usePatchSeller}
        selected={businessEntity.currency}
        transformQueryData={currency => ({
          id: businessEntity.id,
          toUpdate: {
            currency: currency as CURRENCY_TYPE,
          },
        })}
      />
      <Select.Async
        className={styles.defaultPaymentSelector}
        items={defaultPaymentMethodsOptions}
        label="Metoda płatności (faktury)"
        mutationHook={sellersActions.usePatchSeller}
        selected={businessEntity.defaultPaymentMethod}
        transformQueryData={defaultPaymentMethod => ({
          id: businessEntity.id,
          toUpdate: {
            defaultPaymentMethod: defaultPaymentMethod as DefaultPaymentMethod,
          },
        })}
      />
      <div className="d-flex align-items-baseline gap-2">
        <TextField.Async
          containerClassName={styles.invoicingInput}
          mutationHook={sellersActions.usePatchSeller}
          label="Płatność w ciągu"
          transformQueryData={defaultTimeToPay => ({
            id: businessEntity.id,
            toUpdate: {
              defaultTimeToPay: Number(defaultTimeToPay),
            },
          })}
          size="small"
          type="number"
          value={businessEntity.defaultTimeToPay}
        />
        <Typography color="neutralBlack64" fontSize="12" fontWeight="600">
          dni
        </Typography>
      </div>
    </div>
  );
};
