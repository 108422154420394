import { manufacturingActions } from "api/manufacturing/actions";
import { IndexStages, StageComponentKind } from "api/manufacturing/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router";
import { getAnyErrorKey, queryString } from "utilities";
import styles from "../../../../IndexDetails.module.css";
import { Recipe } from "./Recipe";
import { MdiArrowLongForward } from "components/miloDesignSystem/atoms/icons/MdiArrowLongForward";
import styled from "@emotion/styled";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { Button } from "components/miloDesignSystem/atoms/button";
import components from "assets/images/components.svg";
import { useTabContext } from "components/miloDesignSystem/molecules/tabs";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { css } from "@emotion/core";
import { FinishedRecipe } from "./FinishedRecipe";

export const StageDetails = ({
  stage,
  isEditing,
}: {
  stage: IndexStages["stages"][number];
  isEditing: boolean;
}) => {
  const { indexId } = useParams<{ indexId: string }>();
  const { data, isLoading, error } = manufacturingActions.useStageComponentItems(
    queryString.stringify({ stage: stage.stageId, index: indexId }),
  );
  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center py-4">
        <Spinner size={24} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="d-flex align-items-center justify-content-center py-4">
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </div>
    );
  }

  if (isEditing) {
    return <EditingView stage={stage} />;
  }

  const inputComponents = data.filter(component => component.kind === StageComponentKind.INPUT);

  const outputComponents = data.filter(component => component.kind === StageComponentKind.OUTPUT);

  return (
    <div className="d-flex align-items-center mt-4">
      <div className="d-flex flex-1 flex-column">
        {inputComponents.map((component, index) => (
          <div className="mb-1">
            <Recipe
              isDragDisabled
              id={component.id}
              index={index}
              key={component.id}
              recipe={component.recipe}
            />
          </div>
        ))}
      </div>

      <MdiArrowLongForward color="neutralBlack100" className="mx-1" />
      <div className="d-flex flex-1 flex-column">
        {stage.isThisTheLastStage ? (
          <div className="mb-1">
            <FinishedRecipe />
          </div>
        ) : (
          outputComponents.map((component, index) => (
            <div className="mb-1">
              <Recipe
                isDragDisabled
                isOutput
                id={component.id}
                index={index}
                key={component.id}
                recipe={component.recipe}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export const EditingView = ({ stage }: { stage: IndexStages["stages"][number] }) => {
  const { indexId } = useParams<{ indexId: string }>();
  const { data, isLoading, error } = manufacturingActions.useRecipes(
    queryString.stringify({ indexId }),
  );
  const tabContext = useTabContext();
  const postStageComponentMutation = manufacturingActions.usePostStageComponent();
  const deleteStageComponentMutation = manufacturingActions.useDeleteStageComponent();
  const patchStageComponentMutation = manufacturingActions.usePatchStageComponent();

  const handleDrop = (result: DropResult) => {
    if (!result.destination) return;
    if (result.destination.droppableId === result.source.droppableId) return;

    if (result.destination?.droppableId === BAG_DROP_ID) {
      deleteStageComponentMutation.mutate(result.draggableId);
      return;
    }

    if (
      result.destination.droppableId === INPUT_DROP_ID &&
      result.source.droppableId === OUTPUT_DROP_ID
    ) {
      patchStageComponentMutation.mutate({
        id: result.draggableId,
        kind: StageComponentKind.INPUT,
      });
      return;
    }

    if (
      result.destination.droppableId === OUTPUT_DROP_ID &&
      result.source.droppableId === INPUT_DROP_ID
    ) {
      patchStageComponentMutation.mutate({
        id: result.draggableId,
        kind: StageComponentKind.OUTPUT,
      });
      return;
    }

    if (result.destination?.droppableId === INPUT_DROP_ID) {
      postStageComponentMutation.mutate({
        recipe: result.draggableId,
        index: Number(indexId),
        kind: StageComponentKind.INPUT,
        stage: stage.stageId,
      });
      return;
    }

    if (result.destination?.droppableId === OUTPUT_DROP_ID) {
      postStageComponentMutation.mutate({
        recipe: result.draggableId,
        index: Number(indexId),
        kind: StageComponentKind.OUTPUT,
        stage: stage.stageId,
      });
      return;
    }
  };

  if (!data.length && !isLoading && !error) {
    return (
      <div className="d-flex flex-column align-items-center mt-4">
        <img alt="brak zleceń" src={components} className={styles.emptyIcon} />

        <Typography fontSize="16" fontWeight="700" className="my-2">
          Jeszcze nie utworzono receptury
        </Typography>

        <div className="pt-2 pb-3">
          <Button
            size="medium"
            startIcon={MdiAdd}
            variant="deepPurple"
            onClick={() => tabContext.setCurrentTab(2)}
          >
            Dodaj materiał
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId={BAG_DROP_ID}>
          {(provided, snapshot) => (
            <>
              <MaterialsDropSection
                className="d-flex flex-wrap mt-2 mb-3 px-0 flex-row"
                isDraggingOver={snapshot.isDraggingOver}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {isLoading && (
                  <div className="d-flex align-items-center justify-content-center py-4">
                    <Spinner size={24} />
                  </div>
                )}
                {error && (
                  <div className="d-flex align-items-center justify-content-center py-4">
                    <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
                  </div>
                )}
                {data.map((recipe, index) => (
                  <div key={recipe.id} className={styles.recipeWrapper}>
                    <Recipe
                      usedAsInput={Boolean(recipe.usedAsInput)}
                      usedAsOutput={Boolean(recipe.usedAsOutput)}
                      id={recipe.id}
                      index={index}
                      recipe={recipe}
                    />
                  </div>
                ))}
              </MaterialsDropSection>
            </>
          )}
        </Droppable>

        <div className="d-flex align-items-center">
          <AddedMaterialBox kind={StageComponentKind.INPUT} stage={stage} />
          <MdiArrowLongForward color="neutralBlack100" className="mx-1" />
          {stage.isThisTheLastStage ? (
            <AddedMaterialBox isDragDisabled kind={StageComponentKind.OUTPUT} stage={stage} />
          ) : (
            <AddedMaterialBox kind={StageComponentKind.OUTPUT} stage={stage} />
          )}
        </div>
      </DragDropContext>
    </div>
  );
};

const AddedMaterialBox = ({
  stage,
  kind,
  isDragDisabled = false,
}: {
  stage: IndexStages["stages"][number];
  kind: StageComponentKind;
  isDragDisabled?: boolean;
}) => {
  const { indexId } = useParams<{ indexId: string }>();
  const { data, isLoading, error } = manufacturingActions.useStageComponentItems(
    queryString.stringify({ stage: stage.stageId, index: indexId }),
  );
  const components = data.filter(component => component.kind === kind);

  return (
    <Droppable
      droppableId={kind === StageComponentKind.INPUT ? INPUT_DROP_ID : OUTPUT_DROP_ID}
      isDropDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <StaticDropSection
          ref={provided.innerRef}
          {...provided.droppableProps}
          isDraggingOver={snapshot.isDraggingOver}
          disabledDrop={Boolean(
            components.find(component => component.recipe.id === snapshot.draggingOverWith),
          )}
        >
          <Typography
            fontSize="10"
            fontWeight="700"
            color="neutralBlack48"
            className="text-uppercase mb-1"
          >
            {kind === StageComponentKind.INPUT ? "Potrzebne materiały" : "Efekt końcowy etapu"}
          </Typography>
          {!isDragDisabled && (
            <DashedBorder className="d-flex align-items-center justify-content-center px-2 py-4 flex-1">
              <Typography fontSize="14" fontWeight="500" color="neutralBlack64">
                {kind === StageComponentKind.INPUT
                  ? "Przeciągnij i upuść aby dodać materiał"
                  : "Przeciągnij i upuść aby dodać punkt końcowy etapu"}
              </Typography>
            </DashedBorder>
          )}

          {isLoading && (
            <div className="d-flex align-items-center justify-content-center py-3">
              <Spinner size={24} />
            </div>
          )}
          {error && (
            <div className="d-flex align-items-center justify-content-center py-3">
              <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
            </div>
          )}
          {(!stage.isThisTheLastStage ||
            (stage.isThisTheLastStage && kind !== StageComponentKind.OUTPUT)) &&
            components.map((component, index) => (
              <Recipe
                isDragDisabled={isDragDisabled}
                id={component.id}
                index={index}
                key={component.id}
                recipe={component.recipe}
                isOutput={kind === StageComponentKind.OUTPUT}
              />
            ))}
          {stage.isThisTheLastStage && kind === StageComponentKind.OUTPUT && <FinishedRecipe />}
          {provided.placeholder}
        </StaticDropSection>
      )}
    </Droppable>
  );
};

const BAG_DROP_ID = "BAG_DROP_ID";
const INPUT_DROP_ID = "INPUT_DROP_ID";
const OUTPUT_DROP_ID = "OUTPUT_DROP_ID";

const DashedBorder = styled.div`
  border: 1px dashed var(--neutralBlack16);
  border-radius: 4px;
`;

const StyledDropSection = styled.div<{
  isDraggingOver: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 8px;
  flex: 1;
  gap: 4px;
  border-radius: 8px;
  border: 1px
    ${props =>
      props.isDraggingOver ? "solid var(--deepPurple400)" : "dashed var(--neutralBlack16)"};
  border-radius: 4px;
  background-color: ${props =>
    props.isDraggingOver ? "var(--neutralBlack4)" : "var(--neutralWhite100)"};
`;

const MaterialsDropSection = styled(StyledDropSection)<{
  isDraggingOver: boolean;
}>`
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: var(--neutralWhite100);
  ${props =>
    props.isDraggingOver &&
    css({ border: "1px solid var(--deepPurple400)", backgroundColor: "var(--neutralBlack4)" })};
`;

const StaticDropSection = styled(StyledDropSection)<{ disabledDrop: boolean }>`
  width: calc(50% - 24px);
  max-width: calc(50% - 24px);
  min-width: calc(50% - 24px);
  ${props => props.disabledDrop && css({ border: "1px solid var(--danger400)" })};
`;
