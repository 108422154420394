import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { ManufacturingUnitListViewItem } from "api/manufacturing/units/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiAssign } from "components/miloDesignSystem/atoms/icons/MdiAssign";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { ClickOutsideHandler } from "components/utils";
import { useSelector } from "hooks";
import { useState } from "react";

interface Props {
  units: ManufacturingUnitListViewItem[];
}

export const AssignEmployee = ({ units }: Props) => {
  const [employeeId, setEmployeeId] = useState<number | null>(null);
  const employees = useSelector(store => store.partials.employees);
  const employeesOptions = employees.map(employee => ({
    text: `${employee.firstName} ${employee.lastName}`,
    type: MenuItemType.TEXT,
    value: employee.id,
  }));
  const assignEmployee = manufacturingUnitsActions.useBulkUpdateManufacturingUnitsList();

  return (
    <Popover
      content={({ hide }) => (
        <ClickOutsideHandler
          onClickOutside={() => {
            hide();
            setEmployeeId(null);
          }}
          outsideClickIgnoreClass={ignoreClickOutsideClassName}
        >
          <div>
            <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
              Przypisz pracownika
            </Typography>
            <Select
              items={employeesOptions}
              onChange={employeeId => employeeId && setEmployeeId(employeeId as number)}
              selected={employeeId ?? null}
            />
            <div className="mt-3">
              <Button
                className="text-uppercase w-100"
                disabled={!employeeId}
                onClick={() =>
                  assignEmployee.mutate(
                    {
                      manufacturingWorkingUnitsIds: units.map(unit => unit.id),
                      employeeId: employeeId!,
                    },
                    {
                      onSuccess: () => {
                        hide();
                        setEmployeeId(null);
                      },
                    },
                  )
                }
                size="small"
                variant="deepPurple"
              >
                Przypisz pracownika
              </Button>
            </div>
          </div>
        </ClickOutsideHandler>
      )}
      hideOnClickOutside={false}
      variant="DARK"
    >
      <div>
        <Tooltip title="Przypisz pracownika">
          <IconButton
            icon={MdiAssign}
            isLoading={assignEmployee.isLoading}
            theme="dark"
            variant="transparent"
          />
        </Tooltip>
      </div>
    </Popover>
  );
};

const ignoreClickOutsideClassName = "stages-list-bulk-assign-employee-ignore-class-name";
