import { ColumnHelperArgs } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { NormalizePackagesListItem } from "./ProductListItem";
import { PackageExchangeStatus } from "api/order-packages-exchange/enums";
import { Tag } from "components/miloDesignSystem/atoms/tag";

export const packageColumnFactory = (columnHelper: ColumnHelperArgs<NormalizePackagesListItem>) => {
  return {
    packageName: (size: number) =>
      columnHelper.text<NormalizePackagesListItem>(row => row.packageName, {
        header: "paczka",
        size,
        typographyProps: {
          fontSize: "16",
          fontWeight: "600",
        },
      }),
    sku: () =>
      columnHelper.text<NormalizePackagesListItem>(row => row.internalId, {
        header: "SKU",
        size: 74,
        typographyProps: {
          fontSize: "14",
          fontWeight: "400",
        },
      }),
    location: () =>
      columnHelper.text<NormalizePackagesListItem>(row => row.location, {
        header: "Lokalizacja",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
    createdBy: () =>
      columnHelper.avatar<NormalizePackagesListItem>(row => row.confirmedBy, {
        header: "Przez",
        avatarProps: { size: "sm" },
        size: 34,
      }),
  };
};

export function getExchangeStatusTag(
  status: NonNullable<NormalizePackagesListItem["exchangeStatus"]>,
  packageWarehouseStatus: NormalizePackagesListItem["packageWarehouseStatus"],
) {
  if (status === PackageExchangeStatus.FINISHED) {
    return <Tag label="Wymieniono" variant="success" type="outlined" />;
  }
  if (packageWarehouseStatus?.isReleased) {
    return <Tag label="Wydano" variant="quaternary" type="outlined" />;
  }

  switch (status) {
    case PackageExchangeStatus.IN_PROGRESS:
      return <Tag label="Przypisano" variant="info" type="outlined" />;

    case PackageExchangeStatus.NOT_STARTED: {
      if (!packageWarehouseStatus?.isReceived) return "";
      return <Tag label="Dostępna" variant="deepPurple50" type="outlined" />;
    }

    default:
      const exhaustiveCheck: never = status;
      console.error(`Unhandled status: ${exhaustiveCheck}`);
      return "";
  }
}
