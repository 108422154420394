import { uniquePackagesAction } from "api/unique-packages/actions";
import { LongHeldPackage } from "api/unique-packages/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiScanDelete } from "components/miloDesignSystem/atoms/icons/MdiScanDelete";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";

interface Props {
  packages: LongHeldPackage[];
}

export const UnsettlePackage = ({ packages }: Props) => {
  const unsettlePackageMutation = uniquePackagesAction.useBulkUpdateLongHeldPackages();

  return (
    <div>
      <Tooltip title="Ustaw jako nierozliczone">
        <IconButton
          icon={MdiScanDelete}
          isLoading={unsettlePackageMutation.isLoading}
          onClick={() =>
            unsettlePackageMutation.mutate({
              objects: packages.map(_package => _package.id),
              isSettled: false,
            })
          }
          theme="dark"
          variant="transparent"
        />
      </Tooltip>
    </div>
  );
};
