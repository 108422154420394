import { ColorPalette } from "../colorsPalette";
import { BaseIcon } from "../icons/types";

export enum MenuItemType {
  AVATAR = "AVATAR",
  SECTION = "SECTION",
  TEXT = "TEXT",
  ICON = "ICON",
}

export interface BaseMenuItem {
  options?: {
    disabled?: boolean;
    divider?: React.ReactNode;
    color?: ColorPalette;
    className?: string;
    iconStart?: React.FunctionComponent<BaseIcon>;
    buttonEnd?: React.ReactNode;
  };
  onClick: () => void;
  text: string;
}

interface AvatarMenuItem extends BaseMenuItem {
  avatar: string;
  type: MenuItemType.AVATAR;
}

export interface IconMenuItem extends BaseMenuItem {
  icon: React.FunctionComponent<BaseIcon> | string;
  type: MenuItemType.ICON;
}
interface TextMenuItem extends BaseMenuItem {
  type: MenuItemType.TEXT;
}

interface SectionMenuItem extends BaseMenuItem {
  type: MenuItemType.SECTION;
  title?: string;
  menuItems: MenuItem[];
}

export type MenuItem = AvatarMenuItem | TextMenuItem | SectionMenuItem | IconMenuItem;
// | CheckboxMenuItem
// | RadioMenuItem
// | SwitchMenuItem

//TODO: implement Checkbox WHEN: when needed
// export interface CheckboxMenuItem extends BaseMenuItem {
//   checkbox: React.ReactNode;
//   type: MenuItemKind.CHECKBOX;
// }

//TODO: implement Checkbox WHEN: when needed
// export interface RadioMenuItem extends BaseMenuItem {
//   radio: React.ReactNode;
//   type: MenuItemKind.RADIO;
// }

//TODO: implement Checkbox WHEN: when needed
// export interface SwitchMenuItem extends BaseMenuItem {
//   switch: React.ReactNode;
//   type: MenuItemKind.SWITCH;
// }
