import { FLAVOR } from "CONSTANTS";
import { useRedux, useSelector, useThresholdToggle } from "hooks";
import { externalManufacturerModuleConfig, moduleConfig } from "../../moduleConfig";
import { ExternalManufacturerModule, Module } from "../../moduleTypes/moduleTypes";
import { useLocation } from "react-router";
import { isModuleLink } from "typePredicates";

export const useWithDefaultNavigationCollapse = ({
  useThresholdToggleReturn,
}: {
  useThresholdToggleReturn: ReturnType<typeof useThresholdToggle>;
}): ReturnType<typeof useThresholdToggle> => {
  const isMain = FLAVOR === "main";
  const location = useLocation();
  const module = location.pathname.split("/")[1];
  const { isViewNavigationOpen, toggleViewNavigation } = useLocalViewState();
  const moduleView = location.pathname.split("/")[2];

  const navigationSections = isMain
    ? moduleConfig[module as Module]?.navigationSections
    : externalManufacturerModuleConfig[module as ExternalManufacturerModule]?.navigationSections;

  const collapsedNavigationSections = navigationSections?.filter(e => {
    if (isModuleLink(e)) {
      return e.isNavigationDefaultCollapsed;
    }
    return false;
  });

  const match = collapsedNavigationSections?.find(e => {
    if (isModuleLink(e)) {
      return e.url === moduleView;
    }
    return false;
  });

  return {
    isOpen: match ? isViewNavigationOpen : useThresholdToggleReturn.isOpen,
    shouldBeOpen: match ? isViewNavigationOpen : useThresholdToggleReturn.shouldBeOpen,
    toggleOpen: match ? toggleViewNavigation : useThresholdToggleReturn.toggleOpen,
  };
};

const useLocalViewState = () => {
  const location = useLocation();
  const viewNavigationState = useSelector(state => state.ui.viewNavigationState);
  const [dispatch, { ui }] = useRedux();
  const isViewNavigationOpen = viewNavigationState.includes(location.pathname);
  return {
    isViewNavigationOpen: isViewNavigationOpen,
    toggleViewNavigation: () => {
      const newState = isViewNavigationOpen
        ? viewNavigationState.filter(viewNavigation => viewNavigation !== location.pathname)
        : [...viewNavigationState, location.pathname];
      dispatch(ui.setViewNavigationState(newState));
    },
  };
};
