import { CreatePickingOrLoadingSource } from "api/orders/models";
import { usePickings } from "api/wms/hooks";
import { wmsPickingActions } from "api/wms/pickings/actions";
import { InfoLabel } from "components/common/infoLabel";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { Link } from "components/miloDesignSystem/atoms/link/Link";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { CommonError } from "components/utils";
import { useQuery } from "hooks";
import { queryString } from "utilities";

interface Props {
  signature: string;
  source: CreatePickingOrLoadingSource;
}

export const PickingLink = ({ signature, source }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const search = queryString.stringify({
    source,
    sourceId: panelId,
  });
  const { data: pickings, error, isLoading } = usePickings(search);
  const createPickingMutation = wmsPickingActions.useCreatePicking(search);

  if (error)
    return (
      <InfoLabel title="Picking">
        <CommonError status={error._httpStatus_} />
      </InfoLabel>
    );

  if (isLoading)
    return (
      <InfoLabel title="Picking">
        <Spinner size={20} />
      </InfoLabel>
    );

  if (!pickings || !Boolean(pickings.length))
    return (
      <InfoLabel title="Picking">
        <Button
          className="text-uppercase"
          isLoading={createPickingMutation.isLoading}
          onClick={() =>
            createPickingMutation.mutate({
              id: panelId,
              signature,
              kind: source,
            })
          }
          size="small"
          startIcon={MdiAdd}
          variant="gray"
        >
          Utwórz picking
        </Button>
      </InfoLabel>
    );

  return (
    <InfoLabel title="Picking">
      <Link
        fontSize="14"
        fontWeight="700"
        key={pickings[0].id}
        to={`/wms/pickings/list/all?panelId=${pickings[0].id}`}
      >
        {pickings[0].signature}
      </Link>
    </InfoLabel>
  );
};
