import { ProductExchange } from "api/order-packages-exchange/models";
import { NormalizePackagesListItem } from "./ProductListItem";

export const normalizeProductExchangePackage = (
  productExchange: ProductExchange,
): NormalizePackagesListItem[] => {
  return productExchange.packages.map(
    ({
      confirmedAt,
      packageWarehouseStatus,
      confirmedBy,
      id,
      linkedCode,
      location,
      package: _package,
      status,
      linkedOrder,
      uniqueCode,
    }) => ({
      confirmedAt,
      uniqueCode,
      confirmedBy,
      linkedOrder,
      id,
      packageWarehouseStatus,
      linkedCode,
      location,
      exchangeStatus: status,
      internalId: _package.internalId,
      packageName: _package.name,
      sourceStatus: null,
      isAvailable: true,
    }),
  );
};
