import { OrderPackageExchange } from "api/order-packages-exchange/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  orderExchange: OrderPackageExchange;
}

export const TitleSection = ({ orderExchange }: Props) => {
  return (
    <RightPanelSection padding="px-3 pt-1">
      <Typography fontSize="26" fontWeight="700">
        {orderExchange.signature}
      </Typography>
    </RightPanelSection>
  );
};
