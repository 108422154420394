import { StartingPoint } from "api/other/models";
import { pickupPointsActions } from "api/shipping/pickup-points/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { startingPointsUtils } from "utilities/startingPoints";
import styles from "../PickupPoints.module.css";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useCouriersColumns } from "./useCouriersColumns";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { countryCodes } from "CONSTANTS";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection";

interface Props {
  close: () => void;
  startingPoint: StartingPoint;
}

export const EditOrRemoveCourier = ({ close, startingPoint }: Props) => {
  const {
    data: pickupPoints,
    error,
    isLoading,
    isPreviousData,
  } = pickupPointsActions.usePickupPoints(
    startingPointsUtils.getPickupPointsSearch(startingPoint.id),
  );
  const columns = useCouriersColumns(startingPoint);
  const tableProps = useTableFeatureConnector({
    rows: pickupPoints,
  });
  const countries = Object.fromEntries(countryCodes.map(country => [country.value, country.name]));

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Edytuj kurierów
        </Typography>
      }
      width={440}
    >
      <div className="px-3">
        <div className="d-flex flex-column py-2 gap-3">
          <div>
            <Typography fontSize="16" fontWeight="600" noWrap>
              {startingPoint.name || EMPTY_VALUE}
            </Typography>
            <Typography fontSize="16" fontWeight="600" noWrap>
              {startingPoint.street}
            </Typography>
            <Typography fontSize="16" fontWeight="600" noWrap>
              {startingPoint.postCode} {startingPoint.city}
            </Typography>
            <Typography fontSize="16" fontWeight="600" noWrap>
              {countries[startingPoint.countryCode] || EMPTY_VALUE}
            </Typography>
          </div>
          <div className={styles.shippingServices}>
            <Table
              columns={columns}
              error={error}
              isLoading={isLoading || isPreviousData}
              uiSchema={{
                header: {
                  backgroundColor: "neutralWhite100",
                },
                cell: {
                  height: "34",
                },
              }}
              {...tableProps}
              overrides={() => ({
                hideHeader: !pickupPoints.length,
                noResultComponent: <EmptySection label="Brak kurierów" />,
              })}
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap-2 py-3">
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
