import { PurchaseInvoiceToReview } from "api/trading-documents/models";
import styles from "./PdfSection.module.css";
import arrowLeftIcon from "assets/images/keyboardArrowLeft.svg";
import refreshIcon from "assets/images/restartAlt.svg";
import cx from "classnames";
import { Pagination as PaginationType } from "hooks/createPaginatedQuery";
import { Button } from "components/common";
import arrowRightIcon from "assets/images/keyboardArrowRight.svg";
import { ImportInvoicesPagination } from "components/utils/importInvoicesPagination";
import { useMutation } from "hooks/useMutation";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { useQueryClient } from "react-query";
import { useQueryUtils } from "hooks";
import { refreshImportInvoicePdf } from "api/trading-documents/calls";
import { Pagination } from "api/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { StateLabel } from "components/common/stateLabel";
import { Link } from "react-router-dom";
import { useQuery } from "hooks";
import { tradingDocumentConstants } from "constants/tradingDocuments";

interface Props {
  invoiceToReview: PurchaseInvoiceToReview;
  pagination: PaginationType;
  previousListUrl: string;
  search: string;
}

export const PdfSection = ({ invoiceToReview, pagination, previousListUrl, search }: Props) => {
  const queryClient = useQueryClient();
  const { rollback } = useQueryUtils();
  const { query } = useQuery();

  const refreshMutation = useMutation(refreshImportInvoicePdf, {
    onMutate: toUpdate => {
      const prevList = queryClient.getQueryData(
        tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
      );
      queryClient.setQueryData<Pagination<PurchaseInvoiceToReview>>(
        tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
        currentList => {
          assertIsDefined(currentList);
          const tradingDocument = currentList.results.find(
            tradingDocument => tradingDocument.tradingDocumentPdf.id === toUpdate.id,
          );
          assertIsDefined(tradingDocument);
          tradingDocument.tradingDocumentPdf.status = "IN_PROGRESS";
          return { ...currentList, results: [tradingDocument] };
        },
      );
      return { prevList };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(tradingDocumentsKeys.purchaseInvoicesToReview.list(search));
    },
    onError: error => {
      //@ts-ignore
      rollback(error);
    },
  });

  return (
    <div className={cx(styles.pdfSection, "px-0 col-12")}>
      <div className="d-flex align-items-center justify-content-between col-6 pl-3 py-2">
        <div className="d-flex justify-content-start pr-2 pl-0 align-items-center h-100">
          <Button
            as={Link}
            kind="create"
            to={
              Boolean(previousListUrl.length)
                ? previousListUrl
                : `/finances/import-invoices/list/all?panelId=${invoiceToReview.id}`
            }
          >
            <div className="btnBase btnBaseSmall">
              <img alt="Wróć" src={arrowLeftIcon} style={{ height: "16px", width: "16px" }} />
              Wróć do listy
            </div>
          </Button>
          <StateLabel
            className="mx-2 p-1 px-2 nowrap"
            kind={
              tradingDocumentConstants.invoiceProcessingStatusColorDict[
                invoiceToReview.tradingDocumentPdf.status
              ]
            }
          >
            {
              tradingDocumentConstants.invoiceProcessingStatusDict[
                invoiceToReview.tradingDocumentPdf.status
              ]
            }
          </StateLabel>
          <div className={cx(styles.failureReason, "d-flex align-items-center")}>
            <div className="nowrap">{invoiceToReview.tradingDocumentPdf.failureReason}</div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="mr-3">
            <Button
              onClick={() => refreshMutation.mutate({ id: invoiceToReview.tradingDocumentPdf.id })}
              kind="create-transparent"
            >
              <div className="btnBase btnBaseSmall">
                <img alt="Odśwież" src={refreshIcon} style={{ height: "16px", width: "16px" }} />
              </div>
            </Button>
          </div>
          {query && query["id"] ? (
            <Button
              as={Link}
              kind="create"
              onClick={event => event.stopPropagation()}
              title="Zatwierdź fakturę"
              to="/finances/import-invoices/check"
            >
              <div className="btnBase btnBaseSmall">
                <img alt="Gotowe" src={arrowRightIcon} style={{ height: "16px", width: "16px" }} />
                Przejdź do kolejnych
              </div>
            </Button>
          ) : (
            <ImportInvoicesPagination pagination={pagination} />
          )}
        </div>
      </div>
      <div className="d-flex align-items-start col-6 pl-3 pb-2">
        <div className={styles.pdfViewer}>
          <object
            data={invoiceToReview.tradingDocumentPdf?.pdf}
            type="application/pdf"
            width="100%"
            height="800px"
          >
            <div className="d-flex align-items-center p-1">
              <div>Nie udało się załadować pliku PDF.&nbsp;&nbsp;</div>
              <div>
                <a
                  href={invoiceToReview.tradingDocumentPdf?.pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pobierz&nbsp;
                </a>
                w zamian.
              </div>
            </div>
          </object>
        </div>
      </div>
    </div>
  );
};
