import { manufacturingSchemaActions } from "api/manufacturing/schemas/actions";
import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  manufacturingStage: ManufacturingStage;
}

export const StageSettingsSection = ({ manufacturingStage }: Props) => {
  const isAbleToCommissionMutation = manufacturingSchemaActions.usePatchManufacturingStage();

  const isDisabled = (checkedValue: boolean, otherValues: boolean[]): boolean => {
    return checkedValue && otherValues.every(value => !value);
  };

  return (
    <div className="d-flex flex-column py-2">
      <RightPanelSection title="W tym etapie:">
        <div className="mb-2">
          <Radio.Async
            disabled={isDisabled(manufacturingStage.isQaStage, [
              manufacturingStage.isNotProducingComponents,
              manufacturingStage.isProducingComponents,
              manufacturingStage.isThisTheLastStage,
            ])}
            mutationHook={manufacturingSchemaActions.usePatchManufacturingStage}
            transformQueryData={() => ({
              id: manufacturingStage.id,
              isQaStage: true,
              isThisTheLastStage: false,
              isNotProducingComponents: false,
              isProducingComponents: false,
            })}
            checked={manufacturingStage.isQaStage}
            label="Następuje sprawdzenie jakości"
            helperText="Jego efektem jest potwierdzenie jakości produktu lub zgłoszenie wymaganych poprawek"
            size="sm"
          />
        </div>
        <div>
          <Radio.Async
            disabled={isDisabled(manufacturingStage.isNotProducingComponents, [
              manufacturingStage.isQaStage,
              manufacturingStage.isProducingComponents,
              manufacturingStage.isThisTheLastStage,
            ])}
            mutationHook={manufacturingSchemaActions.usePatchManufacturingStage}
            transformQueryData={() => ({
              id: manufacturingStage.id,
              isNotProducingComponents: true,
              isQaStage: false,
              isThisTheLastStage: false,
              isProducingComponents: false,
            })}
            checked={manufacturingStage.isNotProducingComponents}
            label="Nic nie powstaje"
            size="sm"
          />
        </div>
        <div>
          <Radio.Async
            disabled={isDisabled(manufacturingStage.isProducingComponents, [
              manufacturingStage.isQaStage,
              manufacturingStage.isNotProducingComponents,
              manufacturingStage.isThisTheLastStage,
            ])}
            mutationHook={manufacturingSchemaActions.usePatchManufacturingStage}
            transformQueryData={() => ({
              id: manufacturingStage.id,
              isProducingComponents: true,
              isQaStage: false,
              isNotProducingComponents: false,
              isThisTheLastStage: false,
            })}
            checked={manufacturingStage.isProducingComponents}
            label="Powstaje półprodukt"
            size="sm"
          />
        </div>
        <div>
          <Radio.Async
            disabled={isDisabled(manufacturingStage.isThisTheLastStage, [
              manufacturingStage.isQaStage,
              manufacturingStage.isNotProducingComponents,
              manufacturingStage.isProducingComponents,
            ])}
            mutationHook={manufacturingSchemaActions.usePatchManufacturingStage}
            transformQueryData={() => ({
              id: manufacturingStage.id,
              isThisTheLastStage: true,
              isQaStage: false,
              isNotProducingComponents: false,
              isProducingComponents: false,
            })}
            checked={manufacturingStage.isThisTheLastStage}
            label="Powstaje gotowy produkt"
            size="sm"
          />
        </div>
      </RightPanelSection>
      <RightPanelSection title="Ten etap">
        <Checkbox
          checked={manufacturingStage.isAbleToCommission}
          label="Można zlecać na zewnątrz"
          size="sm"
          onChange={isAbleToCommission =>
            isAbleToCommissionMutation.mutate({
              id: manufacturingStage.id,
              isAbleToCommission,
            })
          }
        />
      </RightPanelSection>
    </div>
  );
};
