import { LongHeldPackage } from "api/unique-packages/models";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { SettlePackage } from "./subcomponents/SettlePackage";
import { UnsettlePackage } from "./subcomponents/UnsettlePackage";
import { LongHeldPackagesCsvReport } from "./subcomponents/LongHeldPackagesCsvReport";

interface Props {
  close: () => void;
  packages: LongHeldPackage[];
  numberOfSelectedPackages: number;
}

export const LongHeldPackagesTableToolbar = ({
  close,
  numberOfSelectedPackages,
  packages,
}: Props) => {
  return (
    <TableToolbar close={close} numberOfSelectedItems={numberOfSelectedPackages}>
      <SettlePackage packages={packages} />
      <UnsettlePackage packages={packages} />
      <LongHeldPackagesCsvReport packages={packages} />
    </TableToolbar>
  );
};
