import { useScrollPanel } from "hooks/useScrollPanel";
import { PropsWithChildren, createContext, useContext } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";

interface ScrollableContextContent {
  drawerPanelRef: React.RefObject<HTMLDivElement>;
  handlePanelScroll: () => void;
  isTriggerElementHidden: boolean;
  triggerElementRef: React.RefObject<HTMLDivElement>;
}

export const scrollableContext = createContext<ScrollableContextContent | null>(null);

export const useScrollableContext = () => {
  const context = useContext(scrollableContext);
  assertIsDefined(context);
  return context;
};

export const TriggerScrollContext = ({ children }: PropsWithChildren<{}>) => {
  const {
    drawerPanelRef,
    handlePanelScroll,
    isTriggerElementHidden,
    triggerElementRef,
  } = useScrollPanel();

  return (
    <scrollableContext.Provider
      value={{ drawerPanelRef, handlePanelScroll, isTriggerElementHidden, triggerElementRef }}
    >
      {children}
    </scrollableContext.Provider>
  );
};
