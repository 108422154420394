import { FinancesAmountSummaryCurrency, InvoiceType } from "api/trading-documents/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { RightPanelTableRow } from "components/utils/drawer";
import { countryCurrenciesFlags, CURRENCY_TYPE } from "CONSTANTS";
import { financesAmountSummaryListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";

interface Props {
  currencyItem: [string, FinancesAmountSummaryCurrency];
  invoiceType?: InvoiceType;
}

export const AmountSummaryListItem = ({ currencyItem, invoiceType }: Props) => {
  const [currency, summary] = currencyItem;

  return (
    <RightPanelTableRow grid={financesAmountSummaryListConfig.grid}>
      {countryCurrenciesFlags[currency] ? (
        <img alt={currency} src={countryCurrenciesFlags[currency]} />
      ) : (
        <Typography fontSize="10" fontWeight="700" noWrap>
          {currency}
        </Typography>
      )}
      <AmountDisplay
        amount={String(
          invoiceType === "ADVANCE"
            ? Math.abs(summary.amountWithoutTaxValue)
            : summary.amountWithoutTaxValue,
        )}
        currency={currency as CURRENCY_TYPE}
      />
      <AmountDisplay
        amount={String(
          invoiceType === "ADVANCE"
            ? Math.abs(summary.amountWithoutTaxPln)
            : summary.amountWithoutTaxPln,
        )}
        currency={currency as CURRENCY_TYPE}
      />
      <AmountDisplay
        amount={String(invoiceType === "ADVANCE" ? Math.abs(summary.taxValue) : summary.taxValue)}
        currency={currency as CURRENCY_TYPE}
      />
      <AmountDisplay
        amount={String(invoiceType === "ADVANCE" ? Math.abs(summary.taxPln) : summary.taxPln)}
        currency={currency as CURRENCY_TYPE}
      />
    </RightPanelTableRow>
  );
};
