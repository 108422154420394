import { Product, ProductAttribute } from "api/products/models";
import { AddProductToDraftInvoicePayload } from "api/trading-documents/models";
import { AttributesState } from "api/wms/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AttributeAccordion } from "components/utils/attributeAccordion/AttributeAccordion";
import cuid from "cuid";
import { AdaptedProduct } from "pages/orders/shared/rightPanel/productsSection/productForm";
import { getProductIndexBasedOnAttributesState } from "pages/orders/shared/rightPanel/productsSection/productForm/utils/getProductIndexBasedOnAttributesState";
import { useEffect, useMemo } from "react";
import styles from "../../AddProductToDraft.module.css";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { cx } from "utilities";

interface Props {
  changeAttribute: (attributeId: number, value: number | null, cuid: string) => void;
  product: Product | null;
  setFieldValue: (
    name: keyof AddProductToDraftInvoicePayload["productElements"][number],
    value: string | number | null,
  ) => void;
  values: AddProductToDraftInvoicePayload["productElements"][number];
}

export const AttributesForm = ({ changeAttribute, product, setFieldValue, values }: Props) => {
  useEffect(() => {
    const index = getProductIndexBasedOnAttributesState(product, values.attributesState);
    setFieldValue("index", index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, product]);

  const attributesToDisplay = useMemo(
    () => product?.attributes.map(attribute => ({ ...attribute, cuid: cuid() })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div>
      <AttributeSection
        productIndexes={product?.indexes || null}
        attributesState={values.attributesState}
        attributesToDisplay={attributesToDisplay || []}
        changeAttribute={(attributeId, value) =>
          product && changeAttribute(attributeId, value, values.cuid)
        }
      />
    </div>
  );
};

interface AttributeSectionProps {
  productIndexes: AdaptedProduct["indexes"] | null;
  attributesToDisplay: (ProductAttribute & { cuid: string })[] | null;
  attributesState: AttributesState;
  changeAttribute: (
    attributeId: number,
    value: number | null,
    attributesState: AttributesState,
  ) => void;
}

const AttributeSection = ({
  attributesState,
  attributesToDisplay,
  changeAttribute,
  productIndexes,
}: AttributeSectionProps) => {
  const possibleValues = useMemo(() => {
    const indexes = Object.keys(productIndexes || {});
    const values: string[][] = [];
    indexes.forEach(index => {
      const addedIndexes = attributesState.map(el => el.valueId).filter(Boolean);
      const indexArray = index.split("-");

      if (addedIndexes.every(el => indexArray.includes(String(el)))) {
        values.push(indexArray);
      }
    });
    return [...new Set(values.flat())].map(Number);
  }, [attributesState, productIndexes]);

  function isValueDisabled(value: number) {
    if (!possibleValues.includes(value)) return true;
    return false;
  }

  if (!attributesToDisplay) return null;

  return (
    <div>
      {attributesToDisplay.map((attribute, index) => {
        const stateValue = attributesState.find(
          _attribute => _attribute.attributeId === attribute.id,
        );
        if (attribute.kind === "PICTURE") {
          return (
            <div key={attribute.cuid}>
              <div className="pb-1">
                <Typography color="neutralBlack48" fontSize="10" fontWeight="700" noWrap>
                  {attribute.name.toUpperCase()}
                </Typography>
              </div>
              <div className="d-flex align-items-center gap-1 flex-wrap">
                {attribute.values
                  .filter(el => el.isAssignableToIndex)
                  .map((value, _, arr) => (
                    <div
                      className={cx(styles.tagContainer, {
                        [styles.disabledTagContainer]: isValueDisabled(value.id),
                      })}
                      key={`${value.id}-${attribute.cuid}`}
                      onClick={() => {
                        if (!isValueDisabled(value.id) && arr.length > 1) {
                          if (!attributesState.some(state => state.valueId === value.id)) {
                            changeAttribute(attribute.id, value.id, attributesState);
                          } else {
                            changeAttribute(attribute.id, null, attributesState);
                          }
                        }
                      }}
                    >
                      <Tag
                        disabled={isValueDisabled(value.id)}
                        label={value.name}
                        startIcon={
                          value.picture ? (
                            <img alt="" src={value.picture} style={{ height: 14, width: 14 }} />
                          ) : (
                            undefined
                          )
                        }
                        type={stateValue && stateValue.valueId === value.id ? "filled" : "outlined"}
                        variant={
                          stateValue && stateValue.valueId === value.id
                            ? "deepPurple50"
                            : "quaternary"
                        }
                      />
                    </div>
                  ))}
              </div>
            </div>
          );
        }

        if (attribute.kind === "FABRIC") {
          return (
            <div key={attribute.cuid}>
              <div className="pb-1">
                <Typography color="neutralBlack48" fontSize="10" fontWeight="700" noWrap>
                  {attribute.name.toUpperCase()}
                </Typography>
              </div>
              {attribute.categories
                .filter(category => category.values.some(value => value.isAssignableToIndex))
                .map(category => {
                  const hasSelectedValue = category.values.some(
                    value => value.id === stateValue?.valueId,
                  );
                  return (
                    <div key={`${category.name}-${attribute.cuid}`}>
                      <AttributeAccordion
                        className={styles.attributeAccordion}
                        enabled
                        isSelected={hasSelectedValue}
                        label={category.name}
                      >
                        <div className="d-flex align-items-center gap-1 mt-1 flex-wrap">
                          {category.values
                            .filter(value => value.isAssignableToIndex)
                            .map(value => (
                              <div
                                className={cx(styles.tagContainer, {
                                  [styles.disabledTagContainer]: isValueDisabled(value.id),
                                })}
                                key={`${value.id}-${attribute.cuid}`}
                                onClick={() => {
                                  if (!isValueDisabled(value.id)) {
                                    if (
                                      !attributesState.some(state => state.valueId === value.id)
                                    ) {
                                      changeAttribute(attribute.id, value.id, attributesState);
                                    } else {
                                      changeAttribute(attribute.id, null, attributesState);
                                    }
                                  }
                                }}
                              >
                                <Tag
                                  disabled={isValueDisabled(value.id)}
                                  label={value.name}
                                  startIcon={
                                    value.picture ? (
                                      <img
                                        alt=""
                                        src={value.picture}
                                        style={{ height: 14, width: 14 }}
                                      />
                                    ) : (
                                      undefined
                                    )
                                  }
                                  type={
                                    stateValue && stateValue.valueId === value.id
                                      ? "filled"
                                      : "outlined"
                                  }
                                  variant={
                                    stateValue && stateValue.valueId === value.id
                                      ? "deepPurple50"
                                      : "quaternary"
                                  }
                                />
                              </div>
                            ))}
                        </div>
                      </AttributeAccordion>
                    </div>
                  );
                })}
            </div>
          );
        }

        return (
          <div key={attribute.cuid}>
            <div className="pb-1">
              <Typography color="neutralBlack48" fontSize="10" fontWeight="700" noWrap>
                {attribute.name.toUpperCase()}
              </Typography>
            </div>
            <div className="d-flex align-items-center gap-1 flex-wrap">
              {attribute.values
                .filter(value => value.isAssignableToIndex)
                .map((value, _, arr) => {
                  return (
                    <div
                      className={cx(styles.tagContainer, {
                        [styles.disabledTagContainer]: isValueDisabled(value.id),
                      })}
                      key={`${value.id}-${attribute.cuid}`}
                      onClick={() => {
                        if (!isValueDisabled(value.id) && arr.length > 1) {
                          if (!attributesState.some(state => state.valueId === value.id)) {
                            changeAttribute(attribute.id, value.id, attributesState);
                          } else {
                            changeAttribute(attribute.id, null, attributesState);
                          }
                        }
                      }}
                    >
                      <Tag
                        disabled={isValueDisabled(value.id)}
                        label={value.name}
                        startIcon={value.picture ? <img alt="" src={value.picture} /> : undefined}
                        type={stateValue && stateValue.valueId === value.id ? "filled" : "outlined"}
                        variant={
                          stateValue && stateValue.valueId === value.id
                            ? "deepPurple50"
                            : "quaternary"
                        }
                      />
                    </div>
                  );
                })}
            </div>
            {index < attributesToDisplay.length - 1 && <div className={styles.border} />}
          </div>
        );
      })}
    </div>
  );
};
