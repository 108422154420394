import styles from "./Icon.module.css";
import cx from "classnames";

interface Props {
  type: "round";
  img: string;
  alt?: string;
  className?: string;
  width?: string;
}

/**
 * @deprecated
 */
export const Icon = ({ type, img, alt = "", className = "", width }: Props) => {
  const imgStyles = width ? { width } : undefined;
  return (
    <div className={cx(className, { [styles.round]: type === "round" })}>
      <img src={img} alt={alt} width={width} style={imgStyles} />
    </div>
  );
};
