import {
  AwaitingTransportListItem,
  AwaitingTransportStatus,
} from "api/wms/awaiting-transports/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";

export const getAwaitingTransportStatusTag = (status: AwaitingTransportListItem["status"]) => {
  switch (status) {
    case AwaitingTransportStatus.FINISHED: {
      return <Tag label="zakończono" variant="success" />;
    }
    case AwaitingTransportStatus.NOT_STARTED: {
      return <Tag label="nie rozpoczęto" variant="quaternary" type="outlined" />;
    }
    case AwaitingTransportStatus.IN_PROGRESS: {
      return <Tag label="w trakcie" variant="warning" />;
    }
    default: {
      const exhaustiveCheck: never = status;
      console.error(`Unhandled awaiting transport status: ${exhaustiveCheck}`);
      return null;
    }
  }
};
