import { IndexDetails } from "api/manufacturing/indexes/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Attributes } from "components/miloDesignSystem/molecules/attributes/Attributes";
import { RightPanelSection } from "components/utils/drawer";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  indexDetails: IndexDetails;
}

export const TitleSection = ({ indexDetails }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection padding="px-3 pb-0 pt-1">
      <div ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {indexDetails.name || EMPTY_VALUE}
        </Typography>
      </div>
      <div className="pb-2">
        <Attributes
          attributes={indexDetails.attributesValues.map((attribute, index) => ({
            id: index,
            label: attribute.attribute.name,
            value: attribute.value.name,
          }))}
        />
      </div>
    </RightPanelSection>
  );
};
