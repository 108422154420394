import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { ServiceProvidersRouting } from "routes/manufacturingNew/ServiceProvidersRouting";
import { MdiContractor } from "components/miloDesignSystem/atoms/icons/MdiContractor";

export const serviceProvider: ModuleLink = {
  url: "service-providers",
  label: "Podwykonawcy",
  icon: MdiContractor,
  routing: ServiceProvidersRouting,
};
