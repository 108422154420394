import { countryCodes, currenciesToPick } from "CONSTANTS";
import { filterFactory } from "components/utils/withFilters";
import { tradingDocumentConstants } from "constants/tradingDocuments";

export const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.DateRange,
    label: "data sprzedaży",
    name: ["soldAfter", "soldBefore"],
    showMonths: true,
  },
  {
    type: FilterType.DateRange,
    label: "data wystawienia",
    name: ["fromInvoiceIssueDate", "toInvoiceIssueDate"],
    showMonths: true,
  },
  {
    type: FilterType.Select,
    label: "państwo",
    name: "countryCode",
    multiple: true,
    kind: "label",
    options: countryCodes.map(({ icon, value }) => ({
      label: value,
      value,
      icon,
    })),
  },
  {
    type: FilterType.Search,
    label: "konto sprzedażowe",
    name: "salesAccount",
    searchBy: "salesAccounts",
  },
  {
    type: FilterType.Select,
    label: "status płatności",
    name: "isPaid",
    options: [
      { label: "Opłacono", value: "true" },
      { label: "Nie opłacono", value: "false" },
    ],
  },
  {
    type: FilterType.Select,
    label: "waluta",
    name: "currency",
    kind: "label",
    options: currenciesToPick,
    multiple: true,
  },
  {
    type: FilterType.Select,
    label: "sposób płatności",
    name: "paymentType",
    options: tradingDocumentConstants.paymentMethodsOptions,
  },
  {
    type: FilterType.SelectUser,
    label: "kto się zajmuje?",
    multiple: true,
    kind: "user",
    name: "isAssigned",
    searchBy: "standardUsers",
  },
  {
    type: FilterType.Search,
    label: "kontrahent",
    name: "customers",
    multiple: true,
    searchBy: "customers",
  },
  {
    type: FilterType.Date,
    label: "data przelewu",
    name: "transferDate",
  },
  {
    type: FilterType.Select,
    label: "zaległe faktury",
    name: "isPaymentOverdue",
    options: [
      { label: "Tak", value: "true" },
      { label: "Nie", value: "false" },
    ],
  },
  {
    type: FilterType.Text,
    label: "nr przelewu",
    placeholder: "Szukaj nr przelewu",
    name: "transferNumber",
  },
]);
