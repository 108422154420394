import { useRef, useState } from "react";

export const useScrollPanel = () => {
  const triggerElementRef = useRef<HTMLDivElement>(null);
  const drawerPanelRef = useRef<HTMLDivElement>(null);
  const [isTriggerElementHidden, setIsTriggerElementHidden] = useState(false);

  const hasTriggerElement = (): boolean => Boolean(triggerElementRef?.current);

  const hasDrawerElement = (): boolean => Boolean(drawerPanelRef?.current);

  const handlePanelScroll = () => {
    if (!hasTriggerElement || !hasDrawerElement) return;
    const scrollPosition = drawerPanelRef!.current!.scrollTop;
    const triggerElementOffsetTop = triggerElementRef!.current!.offsetTop;
    const triggerElementHeight = triggerElementRef!.current!.getBoundingClientRect().height;
    const drawerPanelOffsetTop = drawerPanelRef!.current!.offsetTop;

    if (scrollPosition > triggerElementOffsetTop - drawerPanelOffsetTop + triggerElementHeight) {
      setIsTriggerElementHidden(true);
    } else {
      setIsTriggerElementHidden(false);
    }
  };

  return { drawerPanelRef, triggerElementRef, handlePanelScroll, isTriggerElementHidden };
};
