import { manufacturingApi } from "api/manufacturing/api";
import { PostOrderManufacturingMaterialForStageList } from "api/manufacturing/materials/models";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import {
  CustomOrderedMaterialColumn,
  ManufacturingUnitListViewItem,
} from "api/manufacturing/units/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Radio } from "components/miloDesignSystem/atoms/radio/Radio";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { Search } from "components/miloDesignSystem/molecules/search/Search";
import { CommonError } from "components/utils";
import { Formik } from "formik";
import { cx, dateFns, queryString } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { validationSchema } from "./validationSchema";
import { manufacturingActions } from "api/manufacturing/actions";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useOrderMaterialColumns } from "./useOrderMaterialsColumns";
import { MdiFabric } from "components/miloDesignSystem/atoms/icons/MdiFabric";
import styles from "./OrderMaterial.module.css";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";

interface Props {
  close: () => void;
  units: ManufacturingUnitListViewItem[];
}

export const OrderMaterial = ({ close, units }: Props) => {
  const search = queryString.stringify({
    ids: units.map(unit => unit.id),
  });
  const {
    data: customOrderedMaterials,
    error,
    isLoading,
  } = manufacturingUnitsActions.useCustomOrderedMaterials(search);
  const columns = useOrderMaterialColumns();
  const handleSubmit = manufacturingActions.useBulkPostManufacturingOrders(close);

  if (isLoading)
    return (
      <Modal
        close={close}
        isOpen
        width={912}
        title={
          <Typography fontSize="20" fontWeight="700">
            Zamów materiały
          </Typography>
        }
      >
        <div className="p-3 d-flex align-items-center justify-content-center">
          <Spinner size={26} />
        </div>
      </Modal>
    );

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        width={912}
        title={
          <Typography fontSize="20" fontWeight="700">
            Zamów materiały
          </Typography>
        }
      >
        <div className="p-3 d-flex align-items-center justify-content-center">
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  if (!customOrderedMaterials) return null;

  if (!Boolean(customOrderedMaterials.length))
    return (
      <Modal
        close={close}
        isOpen
        width={912}
        title={
          <Typography fontSize="20" fontWeight="700">
            Zamów materiały
          </Typography>
        }
      >
        <div className="py-3">
          <EmptySection icon={<MdiFabric size="26" />} label="Brak materiałów do zamówienia" />
        </div>
      </Modal>
    );

  const initialValues: PostOrderManufacturingMaterialForStageList = {
    areEmailLabelsRequired: false,
    defaultScheduledDeadline: true,
    delivery: null,
    orders: customOrderedMaterials.map(customOrderedMaterial => ({
      averageDeliveryDays: customOrderedMaterial.material.averageDeliveryDays,
      category: null,
      id: customOrderedMaterial.material.id,
      isAlreadyOrdered: customOrderedMaterial.isAlreadyOrdered,
      manufacturer: customOrderedMaterial.material.manufacturers[0]?.id || null,
      manufacturers: customOrderedMaterial.material.manufacturers,
      manufacturingWorkingUnit: customOrderedMaterial.manufacturingWorkingUnit.id,
      manufacturingWorkingUnitSignature: customOrderedMaterial.manufacturingWorkingUnit.signature,
      material: customOrderedMaterial.material.id,
      name: customOrderedMaterial.material.name,
      quantity: String(customOrderedMaterial.material.quantity),
      scheduledDeadline: customOrderedMaterial.material.averageDeliveryDays
        ? dateFns.format(
            dateFns.addDays(new Date(), customOrderedMaterial.material.averageDeliveryDays),
            "yyyy-MM-dd",
          )
        : null,
      unit: {
        id: customOrderedMaterial.material.unit.id,
        shortName: customOrderedMaterial.material.unit.shortName,
      },
    })),
  };

  return (
    <Modal
      close={close}
      isOpen
      width={912}
      title={
        <Typography fontSize="20" fontWeight="700">
          Zamów materiały
        </Typography>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, setValues, values }) => (
          <>
            <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
              <div className="mx-3">
                <div className="mb-4">
                  <Search.Form
                    fetcherFn={manufacturingApi.getManufacturingOrdersDeliveries}
                    isNullable
                    name="delivery"
                    textFieldProps={{ label: "Dostarczyć do", placeholder: "Wyszukaj" }}
                  />
                </div>
              </div>
              <div className="mx-3">
                <Table<CustomOrderedMaterialColumn>
                  columns={columns}
                  error={null}
                  isLoading={false}
                  overrides={() => ({
                    row: row => {
                      return {
                        className: cx({
                          [styles.alreadyOrdered]: row.isAlreadyOrdered,
                        }),
                      };
                    },
                    table: { className: "overflow-visible" },
                    tableInnerWrapper: { className: "overflow-visible" },
                  })}
                  rows={initialValues.orders}
                  uiSchema={{
                    header: {
                      backgroundColor: "neutralWhite88",
                    },
                    cell: {
                      height: "36",
                    },
                  }}
                />
              </div>
              <div className="mx-3 mb-5 mt-3">
                <Checkbox.Form
                  name="areEmailLabelsRequired"
                  size="sm"
                  label="Dołącz etykiety do email"
                />
              </div>
              <Typography
                className="mb-2 text-uppercase mx-3"
                color="neutralBlack64"
                fontSize="12"
                fontWeight="700"
              >
                Termin dostawy
              </Typography>
              <div className="d-flex flex-column mx-3">
                <div className="mb-2">
                  <Radio
                    checked={values.defaultScheduledDeadline}
                    label="Najszybciej jak to możliwe"
                    helperText="Następny dzień roboczy"
                    onClick={() => {
                      setValues({
                        ...values,
                        defaultScheduledDeadline: true,
                        orders: values.orders.map(order => ({
                          ...order,
                          scheduledDeadline: dateFns.format(
                            dateFns.addDays(new Date(), order.averageDeliveryDays),
                            "yyyy-MM-dd",
                          ),
                        })),
                      });
                    }}
                    size="sm"
                  />
                </div>
                <div className="mb-1">
                  <Radio
                    checked={!values.defaultScheduledDeadline}
                    label="Wybieram datę"
                    onClick={() => {
                      setFieldValue("defaultScheduledDeadline", false);
                    }}
                    size="sm"
                  />
                </div>
                <div className="ml-4 mb-4">
                  <DatePicker
                    isNullable={false}
                    onChange={date => {
                      assertIsDefined(date);
                      setValues({
                        ...values,
                        orders: values.orders.map(order => ({
                          ...order,
                          scheduledDeadline: dateFns.format(date, "yyyy-MM-dd"),
                        })),
                      });
                    }}
                    value={values.orders[0].scheduledDeadline || ""}
                    disabled={values.defaultScheduledDeadline}
                  />
                </div>
                <div className="d-flex align-items-center gap-2 p-3">
                  <Button
                    className="text-uppercase"
                    onClick={close}
                    size="medium"
                    variant="transparent"
                  >
                    Anuluj
                  </Button>
                  <Button
                    className="text-uppercase"
                    isLoading={isSubmitting}
                    size="medium"
                    type="submit"
                    variant="deepPurple"
                  >
                    Zamów
                  </Button>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
};
