import { UUID } from "api/types";
import { useQuery } from "hooks";
import { useParams } from "react-router";
import { manufacturingActions } from "api/manufacturing/actions";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { Table } from "components/miloDesignSystem/molecules/table";
import { ManufacturerSingleOrderListItem } from "api/manufacturing/models";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { queryString } from "utilities";
import { useManufacturerSingleOrdersColumns } from "./useManufacturerSingleOrdersColumns";

export const ManufacturerSingleOrdersList = () => {
  const { order } = useParams<{ order: UUID }>();
  const { query } = useQuery();
  const search = queryString.stringify({
    materialOrder: order,
    ...query,
  });
  const {
    data: singleOrders,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = manufacturingActions.useManufacturingOrdersMaterials(search);

  const columns = useManufacturerSingleOrdersColumns();
  const tableProps = useTableFeatureConnector({
    rows: singleOrders,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        viewLabel="MANUFACTURING_MANUFACTURER_SINGLE_ORDERS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<ManufacturerSingleOrderListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
      </div>
    </PageWrapper>
  );
};
