import { IndexesListSearch } from "api/manufacturing/indexes/models";
import { queryString } from "./utilities";

const getIndexesListSearch = ({ query, tab }: IndexesListSearch) => {
  const tabsQuery = {
    productKind: tab,
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

const getIndexPackagesListSearch = (indexId: string) => {
  return queryString.stringify({
    index: indexId,
  });
};

export const indexesUtils = {
  getIndexesListSearch,
  getIndexPackagesListSearch,
};
