import { Modal } from "components/miloDesignSystem/atoms/modal/Modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import folder from "assets/images/folder.svg";
import styles from "./EmptyHistory.module.css";
import { cx } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";

interface Props {
  close: () => void;
  signature?: string;
}

export const EmptyHistory = ({ close, signature }: Props) => {
  return (
    <Modal
      close={close}
      isOpen
      width={630}
      title={
        <Typography fontSize="20" fontWeight="700">
          Historia zlecenia: {signature}
        </Typography>
      }
    >
      <div className="py-2 px-3">
        <div className={cx(styles.emptyHistory, "d-flex flex-column align-items-center gap-2")}>
          <img alt="brak zleceń" src={folder} className={styles.emptyIcon} />
          <Typography fontSize="16" fontWeight="700">
            To zlecenie jeszcze nie ma swojej historii
          </Typography>
          <Typography color="neutralBlack48" fontSize="14" fontWeight="500">
            Tu pojawi się zapis zdarzeń związanych ze zleceniem
          </Typography>
        </div>
        <div className="pt-3 pb-1">
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
