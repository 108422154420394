import { LogKind } from "api/logbook/models";
import { Avatar } from "../avatar";
import { Log } from "./types";
import ReactMarkdown from "react-markdown";
import { MdiBox } from "components/miloDesignSystem/atoms/icons/MdiBox";
import { MdiCancel } from "components/miloDesignSystem/atoms/icons/MdiCancel";
import { MdiEmergencyHome } from "components/miloDesignSystem/atoms/icons/MdiEmergencyHome";
import { MdiPackageSend } from "components/miloDesignSystem/atoms/icons/MdiPackageSend";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./HistoryLog.module.css";
import { dateFns } from "utilities";

export const HistoryLog = ({ content, created, id, kind, responsible }: Log) => {
  return (
    <div className="d-flex gap-3 my-2 align-items-start" key={id}>
      <div className="position-relative d-inline">
        <Avatar size="md" theme="light" user={responsible} />
        <div className={styles.historyStatus}>{kindToIconDict[kind]}</div>
      </div>
      <div className="d-flex flex-column">
        <Typography color="neutralBlack88" fontSize="16" fontWeight="700" className="my-1" noWrap>
          {responsible?.firstName} {responsible?.lastName}
        </Typography>
        <Typography fontSize="14" fontWeight="600">
          <ReactMarkdown className={styles.markdown} source={content} />
        </Typography>
        <Typography fontSize="12" fontWeight="700" color="neutralBlack48">
          {dateFns.formatRelative(new Date(created))}
        </Typography>
      </div>
    </div>
  );
};

// TODO: add icons - waiting for backend
export const kindToIconDict: Record<LogKind, JSX.Element> = {
  [LogKind.ADDED]: <MdiBox size="14" color="info400" />,
  [LogKind.AUTO_UPDATED]: <MdiBox size="14" color="info400" />,
  [LogKind.CREATED]: <MdiBox size="14" color="info400" />,
  [LogKind.DELETED]: <MdiCancel size="14" color="warning400" />,
  [LogKind.DELIVERED]: <MdiBox size="14" color="info400" />,
  [LogKind.DOWNLOADED]: <MdiBox size="14" color="info400" />,
  [LogKind.FAILED]: <MdiEmergencyHome size="14" color="danger400" />,
  [LogKind.REMOVED]: <MdiCancel size="14" color="warning400" />,
  [LogKind.UPDATED]: <MdiPackageSend size="14" color="success400" />,
};
