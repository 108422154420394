import styles from "./FilterText.module.css";
import { useQuery } from "hooks";
import { FilterTextType } from "../../types";
import { useEffect, useState } from "react";

export const FilterText = <T extends string>({ name, placeholder }: FilterTextType<T>) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const [value, setValue] = useState(query[name] || "");
  const queryValue = query[name];

  useEffect(() => {
    setValue(queryValue || "");
  }, [queryValue]);

  const handleChange = (value: string) => {
    updateQuery({ [name]: value });
  };

  return (
    <div>
      <input
        className={styles.input}
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder={placeholder || "Szukaj"}
        onBlur={() => handleChange(value)}
      />
    </div>
  );
};
