import { PackageExchangeStatus } from "api/order-packages-exchange/enums";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDetach } from "components/miloDesignSystem/atoms/icons/MdiDetach";
import { orderPackageExchangeActions } from "api/order-packages-exchange/actions";
import { NormalizePackagesListItem } from "./ProductListItem";

export const UnlinkPackage = ({ row }: { row: NormalizePackagesListItem }) => {
  const patchPackageExchangeMutation = orderPackageExchangeActions.usePatchPackageExchange();
  if (!row.linkedCode || row.exchangeStatus === PackageExchangeStatus.FINISHED) return null;

  return (
    <IconButton
      isLoading={patchPackageExchangeMutation.isLoading}
      icon={MdiDetach}
      variant="transparent"
      onClick={() => {
        patchPackageExchangeMutation.mutate({ id: row.id, toUpdate: { linkedCode: null } });
      }}
    />
  );
};
