import { CURRENCY_TYPE } from "CONSTANTS";
import { useExchangeRates } from "api/exchange-rates/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { ISODate } from "api/types";
import { useFormikContext } from "formik";
import { useFilters } from "hooks/useFilters";
import { ExchangeRateCorrectionDTO } from "./ExchangeRateCorrection";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { cx, dateUtils } from "utilities/utilities";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import styles from "./ExchangeRateCorrection.module.css";
import { useToastr } from "hooks";
import { CommonError } from "components/utils";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useSummaryColumns } from "./useSummaryColumns";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import cuid from "cuid";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useMemo } from "react";

interface Filters {
  code: CURRENCY_TYPE;
  date: ISODate | null;
}

interface Props {
  close: () => void;
  tradingDocument: TradingDocument;
}

export interface ConvertionSummary {
  id: string;
  convertedTotalTax: number | null;
  convertedTotalWithTax: number | null;
  convertedTotalWithoutTax: number | null;
  totalWithTax: number;
  totalWithoutTax: number;
  totalTax: number;
  vatRate: number;
}

export const ExchangeRateForm = ({ close, tradingDocument }: Props) => {
  const { initialValues, isSubmitting, setFieldValue, values } = useFormikContext<
    ExchangeRateCorrectionDTO
  >();
  const { searchParams, filters, setFilter } = useFilters<Filters>({
    code: tradingDocument.currency as CURRENCY_TYPE,
    date: tradingDocument.lastValidExchangeRateDate,
  });
  const toastr = useToastr();
  const { error, isLoading, isPreviousData } = useExchangeRates(searchParams, {
    onSuccess: payload => {
      if (Boolean(payload.length)) {
        setFieldValue("lastValidExchangeRateDate", payload[0].date);
        setFieldValue("lastValidExchangeRate", payload[0].rate);
        setFieldValue("exchangeRateId", payload[0].id);
        return;
      }
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: `Brak kursu ${filters.code} dla wybranej daty ${filters.date &&
          dateUtils.formatDateToDisplay(filters.date)}`,
      });
    },
  });

  // TODO: Make it work instead of onSuccess above
  // useEffect(() => {
  //   if (isLoading) return;
  //   if (Boolean(exchangeRates.length)) {
  //     console.log({ exchangeRates });
  //     setFieldValue("lastVali dExchangeRateDate", exchangeRates[0].date);
  //     setFieldValue("lastValidExchangeRate", exchangeRates[0].rate);
  //     setFieldValue("exchangeRateId", exchangeRates[0].id);
  //     return;
  //   } else {
  //     toastr.open({
  //       type: "warning",
  //       title: "Wymagane działanie",
  //       text: `Brak kursu ${filters.code} dla wybranej daty ${filters.date &&
  //         dateUtils.formatDateToDisplay(filters.date)}`,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [exchangeRates, isLoading]);

  const columns = useSummaryColumns(values.lastValidExchangeRate || null);
  const rows = useMemo(() => {
    return tradingDocument.amountSummaryPerVatRate.map(summaryPerVatRate => {
      const convertedSummary = tradingDocument.amountSummaryPerVatRateCurrencyConvert.find(
        convertedSummaryPerVatRate =>
          convertedSummaryPerVatRate.vatRate === summaryPerVatRate.vatRate,
      );
      assertIsDefined(convertedSummary);
      return {
        ...summaryPerVatRate,
        id: cuid(),
        convertedTotalTax: convertedSummary.totalTax,
        convertedTotalWithTax: convertedSummary.totalWithTax,
        convertedTotalWithoutTax: convertedSummary.totalWithoutTax,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableProps = useTableFeatureConnector({
    rows,
  });

  return (
    <>
      <div className="d-flex px-3 py-2 gap-3">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex align-items-center gap-3">
            <DatePicker
              isNullable={false}
              label="Data wymiany"
              onChange={date => {
                setFilter("date", dateUtils.formatDateToIso(date!));
              }}
              placeholder="Wybierz datę"
              value={values.lastValidExchangeRateDate}
            />
            {error && (
              <div className="pt-2">
                <CommonError size="small" status={error._httpStatus_} text="Błąd ładowania kursu" />
              </div>
            )}
            {!error && (
              <Typography fontSize="12" fontWeight="400">
                1 {filters.code} = {values.lastValidExchangeRate} PLN
              </Typography>
            )}
            {(isLoading || isPreviousData) && <Spinner size={20} />}
          </div>
          <div>
            <Table<ConvertionSummary>
              columns={columns}
              isLoading={false}
              error={null}
              uiSchema={comfortableListUiSchema}
              {...tableProps}
            />
          </div>
        </div>
        <div className={cx(styles.correctionDetails, "d-flex flex-column pl-3 gap-3 w-100")}>
          <DatePicker.Form<ExchangeRateCorrectionDTO>
            isNullable={false}
            label="Data wystawienia"
            name="invoiceIssueDate"
          />
          <DatePicker.Form<ExchangeRateCorrectionDTO>
            isNullable={false}
            label="Data korekty"
            name="invoiceDeliveryDate"
          />
          <div className="w-100">
            <TextField.Form<ExchangeRateCorrectionDTO> label="Korekta z tytułu" name="reason" />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center px-3 py-3 gap-2">
        <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
          Anuluj
        </Button>
        <Button
          className="text-uppercase"
          disabled={initialValues.lastValidExchangeRateDate === values.lastValidExchangeRateDate}
          isLoading={isSubmitting}
          size="medium"
          type="submit"
          variant="deepPurple"
        >
          Zatwierdź zmiany
        </Button>
      </div>
    </>
  );
};
