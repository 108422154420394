import axios, { AxiosError } from "axios";
import { DOMAIN } from "ENDPOINTS";
import { SearchParams } from "hooks/createPaginatedQuery";
import { flattenErrors } from "utilities";
import { Assign } from "utility-types";
import { getLang, removeSlash } from "./utils";
import { authService } from "./authService";

interface Params {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  headers?: { [key: string]: string };
  data?: unknown;
  origin?: string;
  tokenMiddleware?: boolean;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

export function queryFetch<P>({
  url,
  method = "GET",
  headers = {},
  params = {},
  data,
  origin,
  tokenMiddleware = true,
  onUploadProgress,
}: Assign<Params, { params?: SearchParams }>): Promise<P> {
  const allHeaders: { [key: string]: string } = {
    "Accept-Language": getLang(),
    ...headers,
  };
  const token = authService.getAccessToken();
  const endpoint = (origin || DOMAIN) + removeSlash(url);
  if (token && tokenMiddleware) {
    // if user is logged in - add token to request headers
    allHeaders.Authorization = "Bearer " + token;
  }
  if (data instanceof FormData === false) {
    allHeaders["Content-Type"] = "application/json";
  }

  const source = axios.CancelToken.source();

  const promise = axios(endpoint, {
    cancelToken: source.token,
    data: (() => {
      if (!data) return undefined;
      if (data instanceof FormData) return data;
      return JSON.stringify(data);
    })(),
    headers: allHeaders,
    method,
    params,
    onUploadProgress,
  })
    .then(el => el.data)
    .catch(async (error: AxiosError) => {
      const status = error.response ? error.response.status : null;
      const isAccessTokenExpired = await authService.isAccessTokenExpired();
      if (status === 401 && isAccessTokenExpired) {
        const hasNewToken = await authService.refreshToken();
        if (hasNewToken)
          return queryFetch({
            url,
            method,
            headers,
            params,
            data,
            origin,
            tokenMiddleware,
            onUploadProgress,
          });

        return error.response;
      }
      if (error.response?.headers["content-type"]?.includes("application/json")) {
        // eslint-disable-next-line no-throw-literal
        throw { ...flattenErrors({ ...error.response?.data }), response: error.response };
      } else {
        // eslint-disable-next-line no-throw-literal
        throw { message: "Wystąpił błąd", response: error.response };
      }
    });

  //@ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };

  return promise;
}
