import { PickingsTabs, UnloadingStatus } from "api/wms/models";
import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  tab: PickingsTabs;
};

const statusDict: Record<PickingsTabs, UnloadingStatus> = {
  [PickingsTabs["not-started"]]: UnloadingStatus.NOT_STARTED,
  [PickingsTabs["in-progress"]]: UnloadingStatus.IN_PROGRESS,
  [PickingsTabs["finished"]]: UnloadingStatus.FINISHED,
  [PickingsTabs["all"]]: "" as UnloadingStatus,
};

export const getSearch = ({ query, tab }: GetSearchProps) => {
  const tabsQuery = {
    status: statusDict[tab],
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};
