import { queryString } from "./utilities";

const getPickupPointsSearch = (startingPointId: number) => {
  return queryString.stringify({
    pickupPoints: startingPointId,
    pageSize: 999,
  });
};

export const startingPointsUtils = {
  getPickupPointsSearch,
};
