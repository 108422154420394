import { filterFactory } from "components/utils/withFilters";

export const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Select,
    label: "Czy ma nieodczytane wiadomości?",
    name: "hasUnreadMessages",
    options: [
      { label: "Tak", value: "true" },
      { label: "Nie", value: "false" },
    ],
  },
]);
