import { ReturnDetails } from "api/wms/returns/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { wmsReturnConstants } from "constants/returns";

interface Props {
  returnDetails: ReturnDetails;
}

export const TitleSection = ({ returnDetails }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection padding="px-3 pt-1">
      <div className="d-flex align-items-center gap-2" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {returnDetails.signature}
        </Typography>
        <Tag
          label={wmsReturnConstants.statusOptions[returnDetails.status].label}
          startIcon={MdiPoint}
          variant={wmsReturnConstants.statusOptions[returnDetails.status].variant}
        />
        {Boolean(returnDetails.ramp) && (
          <Tag label={`Rampa ${returnDetails.ramp?.name}`} variant="warning" />
        )}
      </div>
    </RightPanelSection>
  );
};
