import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { ServiceProviderSummary } from "./serviceProviderSummary/ServiceProviderSummary";
import { ServiceProviderTabSection } from "./serviceProviderTabSection/ServiceProviderTabSection";

export const ServiceProviderDetails = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Panel podwykonawcy"
        searchBar={false}
        viewLabel="SERVICE_PROVIDER_DETAILS"
      />
      <div className="d-flex overflow-hidden my-2 mx-3">
        <ServiceProviderSummary />
        <ServiceProviderTabSection />
      </div>
    </PageWrapper>
  );
};
