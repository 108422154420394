import { RightPanelSection } from "components/utils/drawer";
import { OrderList } from "./orderList/OrderList";
import { Typography } from "components/miloDesignSystem/atoms/typography";

export const OrderSection = () => {
  return (
    <>
      <RightPanelSection>
        <div className="d-flex align-items-center justify-content-between">
          <Typography fontSize="14" fontWeight="600" color="neutralBlack48">
            Poprzednie zamówienia
          </Typography>
        </div>
      </RightPanelSection>
      <OrderList />
    </>
  );
};
