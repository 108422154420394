import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button } from "components/miloDesignSystem/atoms/button";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useDrawer } from "hooks/useDrawer";
import { MdiArrowForward } from "../../../../../components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { MdiChairAlt } from "components/miloDesignSystem/atoms/icons/MdiChairAlt";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { IndexDetails } from "api/manufacturing/indexes/models";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { useNavigate } from "hooks/useNavigate";

interface Props {
  indexDetails: IndexDetails;
}

export const PanelHeader = ({ indexDetails }: Props) => {
  const { close } = useDrawer("manufacturingIndexes");
  const { isTriggerElementHidden } = useScrollableContext();
  const navigate = useNavigate();

  return (
    <RightPanelHeader>
      {isTriggerElementHidden && (
        <SlidingHeaderWrapper className="noWrap">
          {indexDetails.name || EMPTY_VALUE}
        </SlidingHeaderWrapper>
      )}
      <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
        <Button
          className="text-uppercase"
          endIcon={MdiArrowForward}
          onClick={() => navigate(`/manufacturing/indexes/${indexDetails.id}/details`)}
          size="small"
          startIcon={MdiChairAlt}
          variant="gray"
        >
          Szczegóły wariantu
        </Button>
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};
