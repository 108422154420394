import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { getExchangeStatusTag, packageColumnFactory } from "../shared/packageColumnFactory";
import { NormalizePackagesListItem } from "../shared/ProductListItem";
import { assertIsDefined } from "utilities/assertIsDefined";
import { UnlinkPackage } from "../shared/UnlinkPackage";

export const useExchangeColumns = () => {
  return useCreateTableColumns<NormalizePackagesListItem>(({ columnHelper }) => {
    const columns = packageColumnFactory(columnHelper);
    return [
      columns.packageName(280),
      columns.sku(),
      columnHelper.accessor(row => row, {
        header: "status",
        size: 100,
        cell: info => {
          const row = info.getValue();
          assertIsDefined(row.exchangeStatus);
          return getExchangeStatusTag(row.exchangeStatus, row.packageWarehouseStatus);
        },
      }),
      columns.createdBy(),
      columns.location(),
      columnHelper.accessor(row => row, {
        header: " ",
        id: "unlink",
        size: 32,
        cell: info => {
          const row = info.getValue();
          return <UnlinkPackage row={row} />;
        },
      }),
    ];
  });
};
