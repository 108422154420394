import styled from "@emotion/styled";
import {
  CURRENCY_TYPE,
  CountryCode,
  countryCodes as countryCodeIconList,
  countryCurrenciesFlags,
} from "CONSTANTS";
import { Typography } from "components/miloDesignSystem/atoms/typography";

type Props =
  | {
      countryCode: CountryCode;
      currency?: never;
    }
  | {
      countryCode?: never;
      currency: CURRENCY_TYPE;
    };

const StyledImage = styled.img`
  width: 16px;
  height: 16px;
`;

export const CountryFlag = ({ countryCode, currency }: Props) => {
  const icon = (() => {
    if (countryCode) {
      return countryCodeIconList.find(
        countryCodeObject => countryCodeObject.value.toUpperCase() === countryCode.toUpperCase(),
      )?.icon;
    }
    return countryCurrenciesFlags[currency];
  })();

  const displayLabel = countryCode?.toUpperCase() || currency?.toUpperCase();

  return (
    <div className="d-flex align-items-center gap-1">
      <StyledImage alt="Ikona kraju" src={icon} />
      <Typography fontSize="10" fontWeight="700">
        {displayLabel}
      </Typography>
    </div>
  );
};
