import { PickupPointListItem } from "api/shipping/pickup-points/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "../PickupPoints.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { ShippingService, shippingServiceConstants } from "constants/shippingServiceConstants";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { pickupPointsActions } from "api/shipping/pickup-points/actions";
import { StartingPoint } from "api/other/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "../../../../components/miloDesignSystem/atoms/icons/MdiDelete";

export const useCouriersColumns = (startingPoint: StartingPoint) => {
  const deleteMutation = pickupPointsActions.useDeletePickupPoint();

  return useCreateTableColumns<PickupPointListItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "kurier",
        size: 235,
        cell: info => {
          const pickupPoint = info.getValue();
          return (
            <div className="d-flex align-items-center gap-2">
              {shippingServiceConstants[pickupPoint.shippingService.provider as ShippingService]
                .logo && (
                <div className={styles.listCourierLogoWrapper}>
                  <img
                    alt={pickupPoint.shippingService.name}
                    className={styles.courierLogo}
                    src={
                      shippingServiceConstants[
                        pickupPoint.shippingService.provider as ShippingService
                      ].logo
                    }
                  />
                </div>
              )}
              <Typography fontSize="12" fontWeight="600" noWrap>
                {pickupPoint.shippingService.name}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "domyślny adres",
        size: 100,
        cell: info => {
          const pickupPoint = info.getValue();
          return (
            <Checkbox.Async
              checked={pickupPoint.isDefault}
              label=""
              mutationHook={() => pickupPointsActions.usePatchPickupPoint(startingPoint)}
              transformQueryData={isDefault => ({
                id: pickupPoint.id,
                isDefault,
              })}
              size="sx"
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "usuń",
        size: 30,
        cell: info => {
          const pickupPoint = info.getValue();
          return (
            <IconButton
              icon={MdiDelete}
              isLoading={deleteMutation.isLoading && pickupPoint.id === deleteMutation.variables}
              onClick={() => deleteMutation.mutate(pickupPoint.id)}
              variant="transparent"
            />
          );
        },
      }),
    ];
  });
};
