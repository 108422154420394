import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel } from "components/utils/drawer";
import { useQuery } from "hooks";
import { PanelHeader } from "./components/PanelHeader";
import { useProductQuery } from "hooks/apiPrimitives";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { TitleSection } from "./components/TitleSection";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: product, error, isLoading } = useProductQuery(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!product) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={product.id}>
        <PanelHeader product={product} close={close} />
        <DisabledOpacity disabled={isLoading}>
          <TitleSection product={product} />
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
