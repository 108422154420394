import { Header } from "./components/Header";
import styles from "./WarehouseFinancialState.module.css";
import cx from "classnames";
import { PackagesInList } from "./components/packagesInList/PackagesInList";
import { PackagesOutList } from "./components/packagesOutList/PackagesOutList";
import { useFilterContext, withFilters } from "components/utils/withFilters";
import { filterList } from "./filterList";
import { FilterBar } from "components/common/pageHeader/components/filterBar/FilterBar";

export const WarehouseFinancialState = withFilters(filterList, () => {
  const filterContext = useFilterContext();
  return (
    <div className="position-relative">
      <Header />
      <FilterBar />
      <div className="d-flex">
        <div className="flex-wrap">
          <div
            className={cx(styles.contentScroll, "d-flex flex-wrap ", {
              [styles.filterBarOpened]: filterContext?.appliedFilters.length,
            })}
          >
            <PackagesInList />
            <PackagesOutList />
          </div>
        </div>
      </div>
    </div>
  );
});
