import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useAsyncModal } from "hooks";
import { useState } from "react";

interface Props {
  id: Symbol;
}

export const ToolbarStringAsyncModal = ({ id }: Props) => {
  const asyncModal = useAsyncModal();
  const isOpen = asyncModal.opened === id;
  const [value, setValue] = useState("");

  return (
    <Modal
      close={asyncModal.close}
      isOpen={isOpen}
      title={
        <Typography fontFamily="cabin" fontSize="20" fontWeight="700">
          Wskaż wartość
        </Typography>
      }
      width={300}
    >
      <div className="p-3">
        <TextField onChange={event => setValue(event.target.value)} type="number" value={value} />
        <div className="pt-3">
          <Button
            className="text-uppercase"
            onClick={() => asyncModal.resolve(value)}
            size="small"
            variant="deepPurple"
          >
            Szukaj
          </Button>
        </div>
      </div>
    </Modal>
  );
};
