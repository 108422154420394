import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { PropsWithChildren, createContext, useContext, useState } from "react";
import { noop } from "utilities";

interface TabContext {
  currentTab: number;
  setCurrentTab: (tab: number) => void;
}

const context = createContext<TabContext>({
  currentTab: 1,
  setCurrentTab: noop,
});

export const useTabContext = () => {
  const tabContext = useContext(context);
  return tabContext;
};

export const Tabs = ({ children }: PropsWithChildren<{ mode: "state" | "searchParams" }>) => {
  const [currentTab, setCurrentTab] = useState(1);
  return (
    <context.Provider value={{ currentTab, setCurrentTab: tab => setCurrentTab(tab) }}>
      {children}
    </context.Provider>
  );
};

interface TabItemProps {
  label: string;
  value: number;
}
const StyledTabItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  height: 32px;
  border-bottom: 2px solid transparent;
  color: var(--neutralBlack32);
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    border-bottom: 2px solid var(--neutralBlack64);
    color: var(--neutralBlack64);
  }
  ${props =>
    props.isActive &&
    css`
      border-bottom: 2px solid var(--neutralBlack100);
      color: var(--neutralBlack100);
    `};
`;
const TabItem = ({ label, value }: TabItemProps) => {
  const { currentTab, setCurrentTab } = useTabContext();
  const isActive = value === currentTab;
  return (
    <StyledTabItem isActive={isActive} onClick={() => setCurrentTab(value)}>
      <Typography fontSize="14" fontWeight="500" color="inherit">
        {label}
      </Typography>
    </StyledTabItem>
  );
};
Tabs.TabItem = TabItem;

interface TabContentRendererProps {
  value: number;
}
const TabContentRenderer = ({ value, children }: PropsWithChildren<TabContentRendererProps>) => {
  const { currentTab } = useTabContext();
  const isActive = value === currentTab;

  if (isActive) return <>{children}</>;

  return null;
};

Tabs.TabContentRenderer = TabContentRenderer;
