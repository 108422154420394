import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import chairIcon from "assets/images/chairAlt.svg";
import { IndexesRouting } from "routes/manufacturingNew/IndexesRouting";

export const indexes: ModuleLink = {
  url: "indexes",
  label: "Warianty",
  icon: chairIcon,
  routing: IndexesRouting,
};
