import { AttributeCategory, BoardFormat } from "api/manufacturing/schemas/models";
import {
  ImplementedBy,
  ManufacturingUnitGroupPriority,
  MaterialStageStatus,
  StageStatus,
  UnitStatus,
} from "api/manufacturing/units/models";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { MdiArrowRange } from "components/miloDesignSystem/atoms/icons/MdiArrowRange";
import { MdiCheckCircle } from "components/miloDesignSystem/atoms/icons/MdiCheckCircle";
import { MdiFabric } from "components/miloDesignSystem/atoms/icons/MdiFabric";
import { MdiFlame } from "components/miloDesignSystem/atoms/icons/MdiFlame";
import { MdiKingBed } from "components/miloDesignSystem/atoms/icons/MdiKingBed";
import { MdiLowPriority } from "components/miloDesignSystem/atoms/icons/MdiLowPriority";
import { MdiReady } from "components/miloDesignSystem/atoms/icons/MdiReady";
import { MdiSchedule } from "components/miloDesignSystem/atoms/icons/MdiSchedule";
import { MdiSettings } from "components/miloDesignSystem/atoms/icons/MdiSettings";
import { MdiSide } from "components/miloDesignSystem/atoms/icons/MdiSide";
import { MdiSkull } from "components/miloDesignSystem/atoms/icons/MdiSkull";
import { TagProps } from "components/miloDesignSystem/atoms/tag";

const IN_PROGRESS_DROPPABLE = "inProgressSectionDroppable";
const READY_DROPPABLE = "readySectionDroppable";

const manufacturingUnitGroupPriorityDict: Record<
  ManufacturingUnitGroupPriority,
  Record<
    "dark" | "light",
    {
      color: ColorPalette;
      icon: JSX.Element | null;
      label: string;
    }
  >
> = {
  A: {
    dark: {
      color: "danger600",
      icon: <MdiSkull color="danger600" size="16" />,
      label: "Krytyczny",
    },
    light: {
      color: "danger600",
      icon: <MdiSkull color="danger600" size="16" />,
      label: "Krytyczny",
    },
  },
  B: {
    dark: { color: "orange200", icon: <MdiFlame color="orange200" size="16" />, label: "Pilny" },
    light: { color: "orange200", icon: <MdiFlame color="orange200" size="16" />, label: "Pilny" },
  },
  C: {
    dark: {
      color: "neutralWhite100",
      icon: <MdiLowPriority color="neutralWhite100" size="16" />,
      label: "Zwykły",
    },
    light: { color: "neutralBlack100", icon: <MdiLowPriority size="16" />, label: "Zwykły" },
  },
};

const attributeCategoryIconsDict: Record<AttributeCategory, JSX.Element> = {
  FABRIC: <MdiFabric size="14" />,
  PRODUCT: <MdiKingBed size="14" />,
  SIDE: <MdiSide size="14" />,
  SIZE: <MdiArrowRange size="14" />,
};

const groupByModelDict: Record<BoardFormat, string> = {
  GROUP: "true",
  SINGLE: "false",
};

const materialsStatusOptions: Record<
  MaterialStageStatus,
  { label: string; variant: TagProps<string>["variant"] }
> = {
  AT_CONTRACTOR: { label: "u kontrahenta", variant: "warning" },
  NOT_ORDERED: { label: "nie zamówiono", variant: "danger" },
  ORDERED: { label: "zamówiono", variant: "info" },
  RECEIVED: { label: "odebrano", variant: "success" },
};

const stageStatusOptions: Record<
  StageStatus,
  { label: string; type: TagProps<string>["type"]; variant: TagProps<string>["variant"] }
> = {
  IN_PROGRESS: { label: "w trakcie", type: "filled", variant: "warning" },
  NOT_STARTED: { label: "nierozpoczęty", type: "outlined", variant: "quaternary" },
  READY: { label: "gotowy", type: "outlined", variant: "info" },
  FINISHED: { label: "zakończony", type: "outlined", variant: "success" },
};

const implementedByOptions: Record<
  ImplementedBy,
  { label: string; variant: TagProps<string>["variant"] }
> = {
  CONTRACTOR: { label: "kontrahent", variant: "warning" },
  IN_HOUSE: { label: "na miejscu", variant: "deepPurple400" },
};

const unitStatusOptions: Record<
  UnitStatus,
  { label: string; type: TagProps<string>["type"]; variant: TagProps<string>["variant"] }
> = {
  FINISHED: { label: "gotowe", type: "filled", variant: "success" },
  IN_PROGRESS: { label: "w trakcie", type: "filled", variant: "warning" },
  NOT_STARTED: { label: "nierozpoczęte", type: "outlined", variant: "quaternary" },
};

const stageStatusIconDict: Record<StageStatus, JSX.Element> = {
  IN_PROGRESS: <MdiSettings color="orange200" size="12" />,
  NOT_STARTED: <MdiSchedule color="deepPurple400" size="12" />,
  READY: <MdiReady color="info400" size="12" />,
  FINISHED: <MdiCheckCircle color="success500" size="12" />,
};

export const manufacturingStagesConstants = {
  attributeCategoryIconsDict,
  groupByModelDict,
  implementedByOptions,
  IN_PROGRESS_DROPPABLE,
  manufacturingUnitGroupPriorityDict,
  materialsStatusOptions,
  READY_DROPPABLE,
  stageStatusIconDict,
  stageStatusOptions,
  unitStatusOptions,
};
