import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Formik } from "formik";
import { cx } from "utilities";
import { validationSchema } from "./validationSchema";
import { Button } from "components/miloDesignSystem/atoms/button";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { materialsActions } from "api/manufacturing/materials/actions";
import {
  ManufacturingMaterialKind,
  PostManufacturingMaterial,
} from "api/manufacturing/materials/models";
import { Search } from "components/miloDesignSystem/molecules/search";
import { materialsApi } from "api/manufacturing/materials/api";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { manufacturersApi } from "api/manufacturers/calls";
import { ManufacturerKind } from "api/manufacturers/models";

interface Props {
  close: () => void;
  kind: ManufacturingMaterialKind;
}

export const CreateMaterial = ({ close, kind }: Props) => {
  const initialValues: PostManufacturingMaterial = {
    averageDeliveryDays: null,
    externalId: "",
    category: null,
    kind,
    manufacturer: null,
    name: "",
    unit: "",
  };

  const handleSubmit = materialsActions.usePostManufacturingMaterial(close);

  return (
    <Modal
      close={close}
      isOpen
      width={480}
      title={
        <Typography fontSize="20" fontWeight="700">
          {kind === ManufacturingMaterialKind.RAW ? "Dodaj surowiec" : "Dodaj półprodukt"}
        </Typography>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
          <>
            <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
              <div className="mx-3">
                <div className="mb-4">
                  <TextField.Form<PostManufacturingMaterial>
                    label="Nazwa"
                    name="name"
                    placeholder="Nazwa"
                  />
                </div>

                <div className="mb-4">
                  <Search.Form
                    fetcherFn={materialsApi.getMaterialCategories}
                    isNullable
                    name="category"
                    textFieldProps={{ label: "Kategoria", placeholder: "Wyszukaj kategorię" }}
                  />
                </div>

                <div className="mb-4">
                  <TextField.Form<PostManufacturingMaterial>
                    label="Numer referencyjny"
                    name="externalId"
                    placeholder="Numer referencyjny"
                  />
                </div>

                <div className="mb-4">
                  <Search.Form
                    fetcherFn={materialsApi.getMaterialUnits}
                    isNullable
                    name="unit"
                    textFieldProps={{ label: "Jednostka", placeholder: "Wyszukaj jednostki" }}
                  />
                </div>

                <div className="mb-4 d-flex gap-2 align-items-center">
                  <Search.Form
                    fetcherFn={manufacturersApi.getManufacturersQuery}
                    transformQuery={{ kind: ManufacturerKind.SUPPLIER }}
                    isNullable
                    name="manufacturer.id"
                    customOnChange={value => {
                      setFieldValue("manufacturer", { id: value.id, isMain: false });
                    }}
                    textFieldProps={{
                      label: "Dostawca",
                      placeholder: "Wyszukaj dostawcy",
                      containerClassName: "d-flex flex-1",
                    }}
                  />

                  <Checkbox.Form
                    disabled={!values.manufacturer}
                    name="manufacturer.isMain"
                    size="sm"
                    label="oznacz jako domyślny"
                  />
                </div>
                <div className="mb-4 col-6 p-0">
                  <TextField.Form<PostManufacturingMaterial>
                    label="Czas oczekiwania na dostawę (dni)"
                    name="averageDeliveryDays"
                    placeholder="Wpisz czas oczekiwania na dostawę"
                  />
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 p-3 borderTop">
                <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                  Anuluj
                </Button>
                <Button
                  className="text-uppercase"
                  isLoading={isSubmitting}
                  size="medium"
                  type="submit"
                  variant="deepPurple"
                >
                  Dodaj
                </Button>
              </div>
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
};
