import { filterFactory } from "components/utils/withFilters";
import { createDateRanges } from "utilities";

const dateRanges = createDateRanges();

export const longHeldPackagesFilters = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Search,
    label: "kontrahent",
    name: "supplierId",
    searchBy: "customers",
  },
  {
    type: FilterType.Number,
    label: "l. przeterminowanych dni",
    placeholder: "0",
    name: "days",
  },
  {
    type: FilterType.DateRangeWithOptions,
    label: "data przyjęcia",
    name: ["setAsReceivedAtFrom", "setAsReceivedAtTo"],
    options: [
      { label: "Ten tydzień", value: dateRanges.thisWeek },
      { label: "Ten miesiąc", value: dateRanges.thisMonth },
      { label: "Ostatni miesiąc", value: dateRanges.lastMonth },
      { label: "Ostatni pełny miesiąc", value: dateRanges.lastFullMonth },
      { label: "Ostatnie 6 miesięcy", value: dateRanges.lastSixMonths },
      { label: "Ostatnie 6 pełnych miesięcy", value: dateRanges.lastSixFullMonths },
    ],
  },
  {
    type: FilterType.Select,
    label: "Status rozliczenia",
    name: "isSettled",
    options: [
      { label: "Rozliczone", value: "true" },
      { label: "Nierozliczone", value: "false" },
    ],
  },
]);
