import { useCarrierPatchMutation } from "api/logistics/carriers/hooks";
import { Carrier, kindDict } from "api/logistics/carriers/models";
import { InfoLabel } from "components/common/infoLabel";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AsyncInput } from "components/utils";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../RightPanel.module.css";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";

interface Props {
  carrier: Carrier;
}

export const GeneralInfoSection = ({ carrier }: Props) => {
  const nameMutation = useCarrierPatchMutation();
  const companyNameMutation = useCarrierPatchMutation();
  const taxIdMutation = useCarrierPatchMutation();
  const phoneMutation = useCarrierPatchMutation();
  const emailMutation = useCarrierPatchMutation();
  const addressMutation = useCarrierPatchMutation();
  const postCodeMutation = useCarrierPatchMutation();
  const cityMutation = useCarrierPatchMutation();
  const kindMutation = useCarrierPatchMutation();
  const context = useScrollableContext();

  return (
    <RightPanelSection>
      <div className="d-flex align-items-center gap-2 mb-2" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {carrier.name}
        </Typography>
        <Tag label={kindDict[carrier.kind].label} variant={kindDict[carrier.kind].variant} />
      </div>
      <InfoLabel title="nazwa">
        <AsyncInput
          disabled={nameMutation.isLoading}
          inProgress={nameMutation.isLoading}
          onChange={value =>
            nameMutation.mutate({
              id: carrier.id,
              toUpdate: {
                name: value,
              },
            })
          }
          overwrites={{ container: { className: styles.input } }}
          placeholder="nazwa"
          value={carrier.name}
        />
      </InfoLabel>
      <InfoLabel title="nazwa firmy">
        <AsyncInput
          disabled={companyNameMutation.isLoading}
          inProgress={companyNameMutation.isLoading}
          onChange={value =>
            companyNameMutation.mutate({
              id: carrier.id,
              toUpdate: {
                companyName: value,
              },
            })
          }
          overwrites={{ container: { className: styles.input } }}
          placeholder="nazwa firmy"
          value={carrier.companyName}
        />
      </InfoLabel>
      <InfoLabel title="NIP">
        <AsyncInput
          disabled={taxIdMutation.isLoading}
          inProgress={taxIdMutation.isLoading}
          onChange={value =>
            taxIdMutation.mutate({
              id: carrier.id,
              toUpdate: {
                taxId: value,
              },
            })
          }
          overwrites={{ container: { className: styles.input } }}
          placeholder="NIP"
          value={carrier.taxId}
        />
      </InfoLabel>
      <InfoLabel title="numer telefonu">
        <AsyncInput
          disabled={phoneMutation.isLoading}
          inProgress={phoneMutation.isLoading}
          onChange={value =>
            phoneMutation.mutate({
              id: carrier.id,
              toUpdate: {
                phone: value,
              },
            })
          }
          overwrites={{ container: { className: styles.input } }}
          placeholder="numer telefonu"
          value={carrier.phone}
        />
      </InfoLabel>
      <InfoLabel title="e-mail">
        <AsyncInput
          disabled={emailMutation.isLoading}
          inProgress={emailMutation.isLoading}
          onChange={value =>
            emailMutation.mutate({
              id: carrier.id,
              toUpdate: {
                email: value,
              },
            })
          }
          overwrites={{ container: { className: styles.input } }}
          placeholder="e-mail"
          value={carrier.email}
        />
      </InfoLabel>
      <InfoLabel title="adres">
        <AsyncInput
          disabled={addressMutation.isLoading}
          inProgress={addressMutation.isLoading}
          onChange={value =>
            addressMutation.mutate({
              id: carrier.id,
              toUpdate: {
                street: value,
              },
            })
          }
          overwrites={{ container: { className: styles.input } }}
          placeholder="adres"
          value={carrier.street}
        />
      </InfoLabel>
      <InfoLabel title="kod pocztowy">
        <AsyncInput
          disabled={postCodeMutation.isLoading}
          inProgress={postCodeMutation.isLoading}
          onChange={value =>
            postCodeMutation.mutate({
              id: carrier.id,
              toUpdate: {
                postCode: value,
              },
            })
          }
          overwrites={{ container: { className: styles.input } }}
          placeholder="kod pocztowy"
          value={carrier.postCode}
        />
      </InfoLabel>
      <InfoLabel title="miasto">
        <AsyncInput
          disabled={cityMutation.isLoading}
          inProgress={cityMutation.isLoading}
          onChange={value =>
            cityMutation.mutate({
              id: carrier.id,
              toUpdate: {
                city: value,
              },
            })
          }
          overwrites={{ container: { className: styles.input } }}
          placeholder="miasto"
          value={carrier.city}
        />
      </InfoLabel>
      <InfoLabel title="rodzaj">
        <Select
          items={Object.entries(kindDict).map(([kind, { label }]) => ({
            value: kind,
            text: label,
            type: MenuItemType.TEXT,
          }))}
          onChange={kind => {
            if (kind) {
              kindMutation.mutate({
                id: carrier.id,
                toUpdate: {
                  // @ts-ignore
                  kind,
                },
              });
            }
          }}
          selected={carrier.kind}
        />
      </InfoLabel>
    </RightPanelSection>
  );
};
