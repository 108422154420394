import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { customerApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { useRedux, useSelector } from "hooks";
import { useMutation } from "hooks/useMutation";
import { assertIsDefined } from "utilities/assertIsDefined";
import produce from "immer";
import { customerKeys } from "./keys";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { getAnyErrorKey } from "utilities";
import { Customer } from "./models";
import { deepMerge } from "utilities/deepMerge";

const useCustomerGusDetails = createApiQuery(customerApi.getCustomerGusDetails);
const useCustomers = createPaginatedApiQuery(customerApi.getCustomers);
const useCustomer = createApiQuery(customerApi.getCustomer);
const useCustomerEmployees = createPaginatedApiQuery(customerApi.getCustomerEmployees);

const usePatchCustomer = () => {
  const [dispatch, { partials }] = useRedux();
  const partialsState = useSelector(state => state.partials);

  return useMutation(customerApi.patchCustomer, ({ queryUtils }) => ({
    onMutate: ({ id, toUpdate }) => {
      const prevPanel = queryUtils.handleMutate(customerKeys.customerDetails(id), toUpdate);
      return { prevPanel };
    },
    onSuccess: payload => {
      const newPartials = produce(partialsState, draft => {
        const customerToUpdate = draft.customers.find(customer => customer.id === payload.id);
        assertIsDefined(customerToUpdate);
        Object.assign(customerToUpdate, payload);
      });
      dispatch(partials.setPartials(newPartials));
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(customerKeys.customerDetails(id), onMutateReturn.prevPanel, error);
    },
  }));
};

const useRemoveCustomerEmployee = () => {
  return withDeleteConfirmation(
    useMutation(customerApi.deleteCustomerEmployee, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(customerKeys.customerEmployees());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto użytkownika`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć tego użytkownika?",
  )();
};

const usePatchCustomerAddress = () => {
  return useMutation(customerApi.patchCustomerAddress, ({ queryUtils }) => ({
    onMutate: args => {
      const prevCustomerDetails = queryUtils.handleMutate<Customer>(
        customerKeys.customerDetails(args.customerId),
        draft => {
          const editedAddress = draft.addresses.find(address => address.id === args.id);
          if (editedAddress) {
            Object.assign(editedAddress, deepMerge(editedAddress, args.toUpdate));
          }
          if (args.toUpdate.isDefault && editedAddress) {
            draft.addresses = draft.addresses.map(address => {
              address.isDefault = address.id === editedAddress.id;
              return address;
            });
          }
        },
      );

      return { prevCustomerDetails };
    },
    onError: (error, args, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(
        customerKeys.customerDetails(args.customerId),
        onMutateReturn.prevCustomerDetails,
        error,
      );
    },
  }));
};

const useRemoveCustomerAddress = (customer: Customer) => {
  return withDeleteConfirmation(
    useMutation(customerApi.deleteCustomerAddress, ({ queryUtils }) => ({
      onMutate: addressId => {
        const prevCustomerDetails = queryUtils.handleMutate<Customer>(
          customerKeys.customerDetails(customer.id),
          draft => {
            draft.addresses = draft.addresses.filter(address => address.id !== addressId);
          },
        );

        return { prevCustomerDetails };
      },
      onError: (error, _, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(
          customerKeys.customerDetails(customer.id),
          onMutateReturn.prevCustomerDetails,
          error,
        );
      },
    })),
    "Czy na pewno chcesz usunąć ten adres?",
  )();
};

const useAddCustomerAddress = (close: () => void) => {
  return useMutation(customerApi.postCustomerDeliveryAddress, ({ queryClient, toastr }) => ({
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      close();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostCustomer = () => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  return useMutation(customerApi.postCustomer, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      refetchPartials();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostCustomerEmployee = (close: () => void) => {
  return useMutation(customerApi.postCustomerEmployee, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      close();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

export const customerActions = {
  useAddCustomerAddress,
  useCustomerEmployees,
  useCustomerGusDetails,
  useCustomers,
  useCustomer,
  usePatchCustomer,
  usePatchCustomerAddress,
  usePostCustomer,
  usePostCustomerEmployee,
  useRemoveCustomerAddress,
  useRemoveCustomerEmployee,
};
