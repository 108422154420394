import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { useFormikContext } from "formik";
import { Material } from "api/manufacturing/materials/models";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { SetProviderAsDefaultFormValues } from "./SetProviderAsDefaultModal";

export const useAddProviderColumns = () => {
  return useCreateTableColumns<Material["providers"][number]>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.manufacturer.name, {
        header: "nazwa skrócona",
        size: 200,
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.text(row => row.manufacturer.email, {
        header: "email",
        size: 200,
        typographyProps: {
          fontSize: "14",
          fontWeight: "400",
        },
      }),
      columnHelper.text(row => row.manufacturer.taxId, {
        header: "NIP",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row, {
        header: "domyślny dostawca",
        size: 130,
        cell: info => {
          const row = info.getValue();
          return <SelectIsMain row={row} />;
        },
      }),
    ];
  });
};

const SelectIsMain = ({ row }: { row: Material["providers"][number] }) => {
  const context = useFormikContext<SetProviderAsDefaultFormValues>();
  return (
    <Radio
      size="sm"
      checked={row.isMain}
      onClick={() => {
        context.setValues({
          ...context.values,
          manufacturers: context.values.manufacturers.map(manufacturer =>
            manufacturer.id === row.id
              ? { ...manufacturer, isMain: true }
              : { ...manufacturer, isMain: false },
          ),
        });
      }}
    />
  );
};
