import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useToggle } from "hooks";
import { AddPackageExchangeModal } from "./addPackageExchangeModal/AddPackageExchangeModal";
import { Table } from "components/miloDesignSystem/molecules/table";
import { usePackageExchangeColumns } from "./usePackageExchangeColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { orderPackageExchangeActions } from "api/order-packages-exchange/actions";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { OrderPackageExchangeListItem } from "api/order-packages-exchange/models";
import { MdiBoxEdit } from "components/miloDesignSystem/atoms/icons/MdiBoxEdit";
import { RightPanel } from "./rightPanel/RightPanel";
import { queryString } from "utilities";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { labelsFactory } from "api/labels/calls";

export const PackageExchange = () => {
  const addPackageExchangeModal = useToggle();

  const {
    data,
    error,
    isPreviousData,
    isLoading,
    pagination,
  } = orderPackageExchangeActions.useOrderPackageExchangeList("");
  const columns = usePackageExchangeColumns();
  const tableMultiSelect = useTableMultiSelect({ rows: data });
  const tableProps = useTableFeatureConnector({
    rows: data,
    withPagination: { pagination, defaultPaginationVisibility: true },
    withDrawer: "exchangeOrder",
    withMultiSelect: tableMultiSelect,
  });

  return (
    <PageWrapper>
      <PageHeader
        createButton={{
          alt: "Wymień paczki",
          img: MdiBoxEdit,
          label: "Wymień paczki",
          onClick: addPackageExchangeModal.open,
        }}
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        viewLabel="WMS_PACKAGE_EXCHANGE"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<OrderPackageExchangeListItem>
          columns={columns}
          isLoading={isLoading || isPreviousData}
          error={error}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
        <TableToolbar
          close={tableMultiSelect.clear}
          numberOfSelectedItems={tableMultiSelect.selectedRows.length}
        >
          <FileDownloadHandler
            mode="preview"
            factoryFn={() =>
              labelsFactory.labels(
                queryString.stringify({
                  orders: tableMultiSelect.selectedRows.map(row => row.baseOrderId),
                  addExchangedCodes: true,
                }),
                "Wymiana-paczek-",
              )
            }
            type="pdf"
          >
            {({ download, isLoading }) => (
              <div className="d-flex flex-1 align-items-center justify-content-center">
                <IconButton
                  icon={MdiQrCode}
                  theme="dark"
                  isLoading={isLoading}
                  variant="transparent"
                  onClick={download}
                />
              </div>
            )}
          </FileDownloadHandler>
        </TableToolbar>
      </div>
      {addPackageExchangeModal.isOpen && (
        <AddPackageExchangeModal close={addPackageExchangeModal.close} />
      )}
    </PageWrapper>
  );
};
