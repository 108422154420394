import { carrierActions } from "api/logistics/carriers/actions";
import { Carrier } from "api/logistics/carriers/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";

interface Props {
  carrier: Carrier;
  close: () => void;
}

export const PanelHeader = ({ carrier, close }: Props) => {
  const deleteMutation = carrierActions.useDeleteCarrier(carrier, close);
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden && (
          <SlidingHeaderWrapper className="nowrap">{carrier.name}</SlidingHeaderWrapper>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Tooltip title="Usuń przewoźnika">
            <IconButton
              icon={MdiDelete}
              onClick={() => deleteMutation.mutate(carrier.id)}
              variant="transparent"
            />
          </Tooltip>
          <span className="divider line-divider" />
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </RightPanelHeader>
    </>
  );
};
