import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { indexesApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { useMutation } from "hooks/useMutation";
import { PartialOf } from "typeUtilities";
import { IndexCustomMadePackagesPayload, IndexDetails, PostIndexAssignmentPayload } from "./models";
import { indexesKeys } from "./keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { getAnyErrorKey } from "utilities";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { FormikHelpers } from "formik";

const useIndexes = createPaginatedApiQuery(indexesApi.getIndexes);
const useIndex = createApiQuery(indexesApi.getIndex);
const useIndexAssignments = createApiQuery(indexesApi.getIndexAssignments);
const useIndexStatistics = createApiQuery(indexesApi.getIndexStatistics);
const useIndexPackages = createPaginatedApiQuery(indexesApi.getIndexPackages);

const usePatchIndex = () => {
  return useMutation(
    ({ id, toUpdate }: { id: number; toUpdate: PartialOf<IndexDetails> }) => {
      return indexesApi.patchIndex(toUpdate, String(id));
    },

    ({ queryUtils }) => ({
      onMutate: args => {
        const prevDetails = queryUtils.handleMutate(
          indexesKeys.indexes.details(String(args.id)),
          args.toUpdate,
        );
        const prevList = queryUtils.handlePaginatedListUpdate(
          indexesKeys.indexes.list(),
          args.id,
          args.toUpdate,
        );

        return { prevDetails, prevList };
      },
      onError: (error, args, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(
          indexesKeys.indexes.details(String(args.id)),
          onMutateReturn.prevDetails,
          error,
        );
        queryUtils.rollbackList(indexesKeys.indexes.list(), onMutateReturn.prevList, args.id);
      },
    }),
  );
};

const usePatchIndexCustomMadePackages = () => {
  return useMutation(
    ({ data, search }: { data: IndexCustomMadePackagesPayload; search: string }) => {
      return indexesApi.patchIndexCustomMadePackages(data, search);
    },
    ({ queryClient, toastr }) => ({
      onSuccess: (_, variables) => {
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `${
            variables.data.isCustomMade ? "Związano" : "Odwiązano"
          } wszystkie paczki należące do indeksu`,
        });
        queryClient.invalidateQueries();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useGeneratePackagesForIndexes = () => {
  return useMutation(indexesApi.postGeneratePackagesForIndexes, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Wygenerowano i przypisano paczki",
      });
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useRemovePackageFromIndex = () => {
  return withDeleteConfirmation(
    useMutation(indexesApi.removePackageFromIndex, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Odpięto paczkę od indeksu`,
        });
        queryClient.invalidateQueries();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
  )();
};

const useRemoveIndexAssignment = () => {
  return withDeleteConfirmation(
    useMutation(indexesApi.deleteIndexAssignment, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto numer obcy wariantu`,
        });
        queryClient.invalidateQueries();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć ten numer obcy?",
  )();
};

const useBulkUpdateIndexes = () => {
  return useMutation(indexesApi.bulkUpdateIndexes, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: `Zmodyfikowano wybrane warianty`,
      });
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useBulkUpddateCustomMadePackages = () => {
  return useMutation(indexesApi.bulkUpdatePackagesInIndexes, ({ queryClient, toastr }) => ({
    onSuccess: (_, args) => {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: args.isCustomMade
          ? "Połączono paczki wybranych indeksów z zamówieniem"
          : "Rozłączono paczki wybranych indeksów od zamówienia",
      });
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostIndexAssignment = (close: () => void) => {
  const postMutation = useMutation(indexesApi.postIndexAssignment, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      close();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  const handleSubmit = (
    values: PostIndexAssignmentPayload,
    actions: FormikHelpers<PostIndexAssignmentPayload>,
  ) => {
    postMutation.mutate(values, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

export const indexesActions = {
  useBulkUpddateCustomMadePackages,
  useBulkUpdateIndexes,
  useGeneratePackagesForIndexes,
  useIndex,
  useIndexAssignments,
  useIndexStatistics,
  useIndexes,
  useIndexPackages,
  usePatchIndex,
  usePatchIndexCustomMadePackages,
  usePostIndexAssignment,
  useRemoveIndexAssignment,
  useRemovePackageFromIndex,
};
