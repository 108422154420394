import { Table } from "components/miloDesignSystem/molecules/table";
import { useManufacturerLineItemColumns } from "./useManufacturerLineItemColumns";
import { useFilters } from "hooks/useFilters";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import {
  ExternalManufacturerLineItem,
  OrderLineItemDetails,
} from "api/external-manufacturing/models";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import styles from "pages/externalManufacturerPanel/shared/ExternalManufacturerPanel.module.css";
import { cx } from "utilities";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";

interface Props {
  lineItemDetails: OrderLineItemDetails;
}
export const RemainingOrders = ({ lineItemDetails }: Props) => {
  const { searchParams, setFilter } = useFilters({
    page: 1,
    order: lineItemDetails.order.id,
    excludeManufacturingItem: lineItemDetails.id,
  });
  const {
    data,
    error,
    isLoading,
    pagination,
  } = externalManufacturingActions.useManufacturerLineItems(searchParams);
  const columns = useManufacturerLineItemColumns();

  return (
    <div className="my-2">
      <RightPanelSection padding="px-3 pt-2 pb-2">
        <Typography fontSize="14" fontWeight="600" color="neutralBlack48">
          Pozostałe produkty z tego zamówienia
        </Typography>
      </RightPanelSection>

      <Table<ExternalManufacturerLineItem>
        rows={data}
        columns={columns}
        onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
        isLoading={isLoading}
        error={error}
        overrides={() => {
          return {
            hideHeader: !data.length,
            noResultComponent: <EmptySection label="Brak innych produktów w zamówieniu" />,
            row: row => {
              return {
                className: cx({
                  [styles.cancelledRow]: row.isCancelled,
                }),
              };
            },
          };
        }}
        pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
        uiSchema={comfortableListUiSchema}
      />
    </div>
  );
};
