import { manufacturingActions } from "api/manufacturing/actions";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MdiRestartAlt } from "components/miloDesignSystem/atoms/icons/MdiRestartAlt";
import { MdiSend } from "components/miloDesignSystem/atoms/icons/MdiSend";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { RightPanelSection } from "components/utils/drawer";
import { useQuery, useToggle } from "hooks";
import { queryString } from "utilities";
import { useEmailColumns } from "./useEmailColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import { ManufacturingEmail } from "api/manufacturing/models";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { SelectEmailAddressAndSend } from "./selectEmailAddressAndSend/SelectEmailAddressAndSend";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";

export const EmailSection = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: order } = manufacturingActions.useManufacturingOrder(panelId);
  const search = queryString.stringify({
    materialOrder: order!.id,
  });
  const {
    data: emails,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = manufacturingActions.useManufacturerOrderEmails(search);
  const postEmailMutation = manufacturingActions.usePostManufacturingEmails();
  const selectEmailAddressAndSendModal = useToggle();

  const columns = useEmailColumns();
  const tableProps = useTableFeatureConnector({
    rows: emails,
    withPagination: { pagination, defaultPaginationVisibility: false },
  });

  return (
    <RightPanelSection
      title={
        <div className="w-100 d-flex align-items-center justify-content-between px-3 ">
          <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
            Wysyłka
          </Typography>
        </div>
      }
      padding="0"
    >
      <Table<ManufacturingEmail>
        columns={columns}
        error={error}
        isLoading={isLoading || isPreviousData}
        uiSchema={comfortableListUiSchema}
        {...tableProps}
        overrides={() => ({
          ...tableProps?.overrides?.(),
          hideHeader: !emails.length,
          noResultComponent: <EmptySection label="Brak maili" />,
        })}
      />
      <div className="d-flex align-items-center gap-1 py-2 px-3">
        <Button
          className="text-uppercase"
          isLoading={postEmailMutation.isLoading}
          onClick={() =>
            postEmailMutation.mutate({
              orders: [order!.id],
            })
          }
          size="medium"
          startIcon={Boolean(emails.length) ? MdiRestartAlt : MdiSend}
          variant="gray"
        >
          {Boolean(emails.length) ? "Wyślij ponownie" : "Wyślij"}
        </Button>
        <Button
          className="text-uppercase"
          onClick={selectEmailAddressAndSendModal.open}
          size="medium"
          startIcon={MdiSend}
          variant="gray"
        >
          Wskaż adres i wyślij
        </Button>
        {selectEmailAddressAndSendModal.isOpen && (
          <SelectEmailAddressAndSend
            close={selectEmailAddressAndSendModal.close}
            orders={[order!.id]}
          />
        )}
      </div>
    </RightPanelSection>
  );
};
