import { CommentType } from "components/common";
import { omit } from "utilities";
import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import {
  ProductionOrder,
  ProductionOrderDetails,
  CommentPost,
  ProductionOrderDetailsBulkUpdate,
} from "./models";
import { Pagination } from "../types";
import { queryFetch } from "apiConnectors/queryFetch";

const api = tokenRefreshMiddleware(apiFetch);
export const getProductionOrders = (search: string = "", abortToken?: string) =>
  api<Pagination<ProductionOrder>>({
    method: "GET",
    url: "/production-orders/for-self" + search,
    abortToken,
  });

export const deleteProductionOrder = ({ id, signature }: { id: number; signature: string }) =>
  queryFetch<void>({
    method: "DELETE",
    url: `/production-orders/for-self/${id}?signature=${signature}`,
  });

export const getProductionOrder = (id: string | number, abortToken?: string) =>
  api<ProductionOrderDetails>({
    method: "GET",
    url: "/production-orders/for-self/" + id,
    abortToken,
  });

export const patchProductionOrder = (id: number | string, data: Partial<ProductionOrderDetails>) =>
  api<ProductionOrderDetails>({
    method: "PATCH",
    url: "/production-orders/for-self/" + id,
    data,
  });

export const productionOrderBulkUpdate = (
  id: number | string,
  data: ProductionOrderDetailsBulkUpdate,
) => {
  function extractDelegationsFromData(data: ProductionOrderDetailsBulkUpdate) {
    if (data.delegations) {
      return data.delegations;
    }
    return data;
  }

  return api<ProductionOrderDetails>({
    method: "PATCH",
    url: "/production-orders/for-self/" + id + "/bulk-update",
    data: extractDelegationsFromData(data),
  });
};

export const postComment = (data: CommentPost) =>
  api<CommentType>({
    method: "POST",
    url: "/production-orders/comments",
    data,
  });

export const deleteComment = (id: number) =>
  api<void>({
    method: "DELETE",
    url: "/production-orders/comments/" + id,
  });

export const patchComment = (comment: Partial<CommentType> & { id: number }) =>
  api<CommentType>({
    method: "PATCH",
    url: "/production-orders/comments/" + comment.id,
    data: omit(comment, ["id"]),
  });
