import { TradingDocument } from "api/trading-documents/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./AddProductToDraft.module.css";
import { GeneralInfoSection } from "../addOrdersToDraft/subcomponents/generalInfoSection/GeneralInfoSection";
import { useState } from "react";
import { Product } from "api/products/models";
import { ProductSection } from "./subcomponents/productSection/ProductSection";
import { Button } from "components/miloDesignSystem/atoms/button";
import { noop } from "utilities";
import { Line } from "components/miloDesignSystem/atoms/line/Line";

interface Props {
  close: () => void;
  draftInvoice: TradingDocument;
}

export const AddProductToDraft = ({ close, draftInvoice }: Props) => {
  const [product, setProduct] = useState<Product | null>(null);

  return (
    <Modal
      close={close}
      isOpen
      width={640}
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj produkt do faktury na podmiot własny
        </Typography>
      }
    >
      <div>
        <div className="d-flex flex-column gap-3 px-3 py-2">
          <GeneralInfoSection draftInvoice={draftInvoice} />
          <Line lineStyle="dashed" />
          <ProductSection
            close={close}
            draftInvoice={draftInvoice}
            product={product}
            setProduct={setProduct}
          />
        </div>
        {!product && (
          <div className={styles.buttons}>
            <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
              Anuluj
            </Button>
            <Button
              className="text-uppercase"
              disabled={!product}
              onClick={noop}
              size="medium"
              variant="deepPurple"
            >
              Dodaj do faktury
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
