import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { noop } from "utilities";

export interface Summary {
  id: number;
  hardItemsWeight: string;
  softItemsWeight: string;
}
export const useOrderSummaryColumns = () => {
  return useCreateTableColumns<Summary>(({ columnHelper }) => {
    return [
      columnHelper.stretchContent,
      columnHelper.accessor(row => row.hardItemsWeight, {
        id: "hardItemsWeight",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            waga - meble twarde
          </Typography>
        ),
        size: 294,
        cell: info => {
          return (
            <div className="d-flex align-items-center gap-2">
              <Typography
                fontSize="14"
                fontWeight="700"
                className="text-uppercase"
                color="neutralBlack48"
                noWrap
              >
                Suma wag zamówień:
              </Typography>
              <div className="d-flex flex-1 align-items-center gap-1">
                <TextField size="small" value={String(info.getValue())} disabled onChange={noop} />
                <Typography fontSize="12" fontWeight="600" color="neutralBlack64">
                  KG
                </Typography>
              </div>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.softItemsWeight, {
        id: "softItemsWeight",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            waga - meble tapicerowane
          </Typography>
        ),
        size: 150,
        cell: info => {
          return (
            <div className="d-flex flex-1 align-items-center gap-1">
              <TextField size="small" value={String(info.getValue())} disabled onChange={noop} />
              <Typography fontSize="12" fontWeight="600" color="neutralBlack64">
                KG
              </Typography>
            </div>
          );
        },
      }),
    ];
  });
};
