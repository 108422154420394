import { dateFns, dateUtils } from "utilities";
import { ToggleHookState, useStateModal, useToastr } from "hooks";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select";
import { useState } from "react";
import { Button } from "components/miloDesignSystem/atoms/button";
import { shippingActions } from "api/shipping/actions";
import { useFilters } from "hooks/useFilters";
import { PostShipmentErrorPreview, ShipmentStatus } from "api/shipping/models";
import { assertIsDefined } from "utilities/assertIsDefined";
import { NoResults } from "components/utils/noResults";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { MissingSection } from "./MissingSection";
import { FailedResponseModal } from "components/miloDesignSystem/organisms/failedResponseModal/FailedResponseModal";

interface Props {
  modal: ToggleHookState;
}

export const SendShipmentToExternalModal = ({ modal }: Props) => {
  const [period, setPeriod] = useState<Period>("today");
  const [omitNotReadyShipments, setOmitNotReadyShipments] = useState(false);
  const toastr = useToastr();
  const sendShipmentToExternalMutation = shippingActions.useSendShipmentsToExternal();
  const { searchParams, setFilter } = useFilters({
    createdAtFrom: options[period].createdAtFrom,
    createdAtTo: options[period].createdAtTo,
    statuses: ShipmentStatus.NO_REGISTERED,
  });

  const onSetPeriod = (period: Period) => {
    setPeriod(period);
    setFilter("createdAtFrom", options[period].createdAtFrom);
    setFilter("createdAtTo", options[period].createdAtTo);
  };

  const { data, isLoading, error } = shippingActions.useSendToExternal(searchParams);
  const sendShipmentErrorModal = useStateModal<{
    preview: PostShipmentErrorPreview;
  }>();

  const reset = () => {
    modal.close();
  };

  const noResults =
    !data?.completed.length &&
    !data?.missingPackages.length &&
    !data?.missingPlannedPickupAt.length &&
    !data?.missingShippingService.length &&
    !isLoading &&
    !error;

  if (noResults) {
    return (
      <Modal
        close={reset}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Nadaj przesyłkę i zamów kuriera
          </Typography>
        }
        width={741}
      >
        <div className="px-3 pt-0 pb-3">
          <Select
            label="Nadaj przesyłkę i zamów dla paczek"
            items={Object.values(options).map(({ value, text }) => ({
              value,
              text,
              type: MenuItemType.TEXT,
            }))}
            onChange={value => {
              onSetPeriod(value as Period);
            }}
            selected={period}
          />
          <NoResults
            header="Nadano wszystkie przesyłki na wybrany dzień"
            overwrites={{
              textWrapper: { className: "mb-0" },
              subHeader: { className: "mb-0", fullText: " " },
              background: { className: "h-100 mt-3" },
              img: { style: { height: "120px" } },
            }}
          />
        </div>
      </Modal>
    );
  }

  if (error)
    return (
      <Modal
        close={reset}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Nadaj przesyłkę i zamów kuriera
          </Typography>
        }
        width={741}
      >
        <div className="p-3 d-flex align-items-center flex-1 justify-content-center">
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  if (isLoading)
    return (
      <Modal
        close={reset}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Nadaj przesyłkę i zamów kuriera
          </Typography>
        }
        width={741}
      >
        <div className="p-3 d-flex align-items-center flex-1 justify-content-center">
          <Spinner size={30} />
        </div>
      </Modal>
    );

  assertIsDefined(data);

  return (
    <>
      <Modal
        close={reset}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Nadaj przesyłkę i zamów kuriera
          </Typography>
        }
        width={741}
      >
        <div className="px-3 pt-0 pb-3">
          <Select
            label="Nadaj przesyłkę i zamów dla paczek"
            items={Object.values(options).map(({ value, text }) => ({
              value,
              text,
              type: MenuItemType.TEXT,
            }))}
            onChange={value => {
              onSetPeriod(value as Period);
            }}
            selected={period}
          />

          <MissingSection
            data={data}
            omitNotReadyShipments={omitNotReadyShipments}
            setOmitNotReadyShipments={setOmitNotReadyShipments}
          />
          <div className="d-flex align-items-center gap-3 mt-4">
            <Button onClick={reset} size="medium" variant="transparent" className="text-uppercase">
              Anuluj
            </Button>
            <Button
              isLoading={sendShipmentToExternalMutation.isLoading}
              disabled={
                sendShipmentToExternalMutation.isLoading ||
                (!omitNotReadyShipments &&
                  Boolean(
                    data.missingPlannedPickupAt.length ||
                      data.missingShippingService.length ||
                      data.missingPackages.length,
                  ))
              }
              onClick={() => {
                if (omitNotReadyShipments && Boolean(!data.completed.length)) {
                  toastr.open({
                    text: "",
                    title: "Nadano wszystkie możliwe przesyłki na dany dzień",
                    type: "success",
                  });
                  return;
                }
                sendShipmentToExternalMutation.mutate(
                  { shipments: data.completed.map(e => e.id) },
                  {
                    onSuccess: modal.close,
                    onError: error => {
                      if (error.response?.status === 400) {
                        sendShipmentErrorModal.open({
                          preview: error.response.data as PostShipmentErrorPreview,
                        });
                      }
                    },
                  },
                );
              }}
              size="medium"
              variant="deepPurple"
              className="text-uppercase"
            >
              Nadaj przesyłkę i zamów kuriera
            </Button>
          </div>
        </div>
      </Modal>
      {sendShipmentErrorModal.isOpen && sendShipmentErrorModal.state && (
        <FailedResponseModal
          close={() => {
            sendShipmentErrorModal.close();
            modal.close();
          }}
          data={{
            success: null,
            failed: {
              message: "Przesyłki wyłączone z nadania:",
              reasons: [
                {
                  message: "Brak paczek",
                  signatures: Boolean(
                    Object.values(sendShipmentErrorModal.state.preview.missingPackages).length,
                  )
                    ? Object.values(sendShipmentErrorModal.state.preview.missingPackages).map(
                        reason => reason.signature,
                      )
                    : [],
                },
                {
                  message: "Brak daty odbioru przez kuriera",
                  signatures: Boolean(
                    Object.values(sendShipmentErrorModal.state.preview.missingPlannedPickupAt)
                      .length,
                  )
                    ? Object.values(
                        sendShipmentErrorModal.state.preview.missingPlannedPickupAt,
                      ).map(reason => reason.signature)
                    : [],
                },
                {
                  message: "Brak kuriera",
                  signatures: Boolean(
                    Object.values(sendShipmentErrorModal.state.preview.missingShippingService)
                      .length,
                  )
                    ? Object.values(
                        sendShipmentErrorModal.state.preview.missingShippingService,
                      ).map(reason => reason.signature)
                    : [],
                },
              ],
            },
          }}
          title="Status nadania paczek"
        />
      )}
    </>
  );
};

type Period = "today" | "yesterday" | "2DaysAgo";

const options: Record<
  Period,
  { createdAtFrom: string; createdAtTo: string; text: string; value: Period }
> = {
  today: {
    value: "today",
    createdAtFrom: dateUtils.formatDateToIso(new Date()),
    createdAtTo: dateUtils.formatDateToIso(new Date()),
    text:
      "Nadaj przesyłkę i zamów kuriera dla wszystkich dzisiejszych paczek, do których nie był jeszcze zamówiony",
  },
  yesterday: {
    value: "yesterday",
    text:
      "Nadaj przesyłkę i zamów kuriera dla wszystkich wczorajszych paczek, do których nie był jeszcze zamówiony",
    createdAtFrom: dateUtils.formatDateToIso(dateFns.subDays(new Date(), 1)),
    createdAtTo: dateUtils.formatDateToIso(dateFns.subDays(new Date(), 1)),
  },
  "2DaysAgo": {
    value: "2DaysAgo",
    createdAtFrom: dateUtils.formatDateToIso(dateFns.subDays(new Date(), 2)),
    createdAtTo: dateUtils.formatDateToIso(dateFns.subDays(new Date(), 2)),
    text:
      "Nadaj przesyłkę i zamów kuriera dla wszystkich paczek sprzed 2 dni, do których nie był jeszcze zamówiony",
  },
};
