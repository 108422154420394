import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import shipmentIcon from "assets/images/package_2.svg";
import { ShippingShipmentsRouting } from "routes/logistics/ShippingShipments";

export const shippingShipments: ModuleLink = {
  url: "shipping-shipments",
  label: "Przesyłki",
  icon: shipmentIcon,
  routing: ShippingShipmentsRouting,
};
