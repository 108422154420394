import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiSearch } from "components/miloDesignSystem/atoms/icons/MdiSearch";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import styles from "../StageBoard.module.css";
import { ClickOutsideHandler, StateProxy } from "components/utils";
import { useState } from "react";
import { noop } from "utilities";
import { MdiClose } from "../../../../../components/miloDesignSystem/atoms/icons/MdiClose";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";

interface Props {
  isLoading: boolean;
  queryKey: string;
  onChange: (searchValue: string | null) => void;
  value: string | null;
}

export const ColumnSearch = ({ isLoading, queryKey, onChange, value }: Props) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const ignoreClickOutsideClassName = `manufacturing-stage-section-${queryKey}-ignore-class-name`;

  if (!isSearchVisible)
    return (
      <IconButton icon={MdiSearch} onClick={() => setIsSearchVisible(true)} variant="transparent" />
    );

  return (
    <StateProxy state={value} onChange={onChange} debounce={400}>
      {({ state, setState }) => (
        <ClickOutsideHandler
          excludeScrollbar
          onClickOutside={() => {
            if (state) return noop();
            return setIsSearchVisible(false);
          }}
          outsideClickIgnoreClass={ignoreClickOutsideClassName}
        >
          <TextField
            autoFocus
            containerClassName={styles.columnSearch}
            endIcon={<MdiSearch color="neutralBlack48" size="18" />}
            EndInputSection={
              <div className="d-flex align-items-center gap-2">
                {isLoading && <Spinner size={16} />}
                {state && (
                  <IconButton
                    icon={MdiClose}
                    onClick={() => setState("")}
                    size="small"
                    variant="transparent"
                  />
                )}
              </div>
            }
            onChange={event => setState(event.target.value)}
            placeholder="Szukaj..."
            size="small"
            value={state ?? ""}
          />
        </ClickOutsideHandler>
      )}
    </StateProxy>
  );
};
