import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  name: string;
}

export const TitleSection = ({ name }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <div className="d-flex align-items-center" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {name}
        </Typography>
      </div>
    </RightPanelSection>
  );
};
