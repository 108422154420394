import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { ManufacturerListItem } from "api/manufacturers/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { useFormikContext } from "formik";
import { PostMaterialManufacturer } from "api/manufacturing/materials/models";

export const useAddProviderColumns = () => {
  return useCreateTableColumns<ManufacturerListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "nazwa skrócona",
        size: 200,
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.text(row => row.email, {
        header: "email",
        size: 200,
        typographyProps: {
          fontSize: "14",
          fontWeight: "400",
        },
      }),
      columnHelper.text(row => row.taxId, {
        header: "NIP",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row, {
        id: "delete",
        header: "",
        size: 30,
        cell: info => {
          const row = info.getValue();
          return <DeleteRow row={row} />;
        },
      }),
    ];
  });
};

const DeleteRow = ({ row }: { row: ManufacturerListItem }) => {
  const context = useFormikContext<PostMaterialManufacturer>();
  return (
    <IconButton
      icon={MdiDelete}
      variant="transparent"
      onClick={() => {
        context.setValues({
          ...context.values,
          manufacturers: context.values.manufacturers.filter(
            manufacturer => manufacturer.id !== row.id,
          ),
        });
      }}
    />
  );
};
