import { yup } from "utilities";

export const validationSchema = yup.object().shape({
  delivery: yup
    .string()
    .nullable()
    .required("Adres jest wymagany"),
  orders: yup.array().of(
    yup.object().shape({
      manufacturer: yup
        .string()
        .nullable()
        .required("Dostawca jest wymagany"),
    }),
  ),
});
