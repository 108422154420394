import { wmsLayoutActions } from "api/wms/layout/actions";
import { useQuery, useSelector } from "hooks";

const DEFAULT_CLIENT_ZOOM = 13;
export const useWarehouseZoom = () => {
  const { query } = useQuery();
  const { hallId } = query;
  const { data: hall } = wmsLayoutActions.useLayoutSchema(Number(hallId), {
    enabled: Boolean(hallId),
  });
  const zoom = useSelector(state => state.ui.warehouseSchemaZoom);
  if (hall?.schema?.defaultZoom && !zoom) {
    return hall?.schema?.defaultZoom;
  }

  return zoom || DEFAULT_CLIENT_ZOOM;
};
