import filtersIcon from "assets/images/38.svg";
import commentIcon from "assets/images/45g.svg";
import callCenterIcon from "assets/images/bok.svg";
import homePointIcon from "assets/images/homePin.svg";
import styles from "./RightTopButtons.module.css";
import cx from "classnames";
import { useRouteViewState } from "../routeCreatorState";
import { Button } from "components/common";
import { Link } from "react-router-dom";
import { Route } from "api/routes/models";

interface Props {
  route: Route;
}

export const RightTopButtons = ({ route }: Props) => {
  const isSettingCenterPoint = useRouteViewState("slave", state => state.isSettingCenterPoint);
  const actions = useRouteViewState("slave", state => state.actions);

  return (
    <div className={styles.container}>
      <button
        className={cx(styles.toggleButton, styles.toggleButtonMargin, {
          [styles.toggleBtnSelected]: isSettingCenterPoint,
        })}
        onClick={() => actions.setIsSettingCenterPoint()}
        title="Ustaw centralny punkt"
      >
        <img src={homePointIcon} alt="" style={{ height: "16px", width: "16px" }} />
      </button>
      <Button
        as={Link}
        className={cx(styles.toggleButton, styles.toggleButtonMargin)}
        kind="secondary-stroke"
        to={`/call-center/routes/${route.id}/orders`}
        title="Wróć do BOK"
      >
        <img src={callCenterIcon} alt="cofnij do BOK" style={{ height: "16px", width: "16px" }} />
      </Button>
      <button
        className={cx(styles.toggleButton, styles.toggleButtonMargin)}
        onClick={() => actions.toggle("isOrderCommentsDrawerOpen")}
      >
        <img src={commentIcon} alt="" />
      </button>
      <button className={styles.toggleButton} onClick={() => actions.toggle("isFiltersDrawerOpen")}>
        <img src={filtersIcon} alt="" />
      </button>
    </div>
  );
};
