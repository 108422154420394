import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useManufacturingStagesColumns = () => {
  return useCreateTableColumns<ManufacturingStage>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "nazwa",
        size: 500,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
    ];
  });
};
