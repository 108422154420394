import { IndexDetails } from "api/manufacturing/indexes/models";
import { InfoLabel } from "components/common/infoLabel";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Link } from "components/miloDesignSystem/atoms/link/Link";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { dateUtils } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { MdiShoppingCart } from "../../../../../components/miloDesignSystem/atoms/icons/MdiShoppingCart";
import { MdiSaleWithdrawn } from "../../../../../components/miloDesignSystem/atoms/icons/MdiSaleWithdrawn";
import { Button } from "components/miloDesignSystem/atoms/button";
import { indexesActions } from "api/manufacturing/indexes/actions";
import { MdiSchemeAlt } from "components/miloDesignSystem/atoms/icons/MdiSchemeAlt";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { indexesConstants } from "constants/indexes";
import styles from "../RightPanel.module.css";
import { AssignSchema } from "pages/manufacturingNew/shared/assignSchema/AssignSchema";

interface Props {
  indexDetails: IndexDetails;
}

export const GeneralInfoSection = ({ indexDetails }: Props) => {
  const isDeletedMutation = indexesActions.usePatchIndex();
  const schemaMutation = indexesActions.usePatchIndex();

  return (
    <RightPanelSection padding="px-3 pt-0 pb-2">
      <InfoLabel className="pb-1" title="kategoria">
        {!indexDetails.product?.kind ? (
          <EmptyValue fontSize="16" />
        ) : (
          <Tag
            label={indexesConstants.productKindVariants[indexDetails.product.kind].label}
            variant={indexesConstants.productKindVariants[indexDetails.product.kind].variant}
          />
        )}
      </InfoLabel>
      <InfoLabel className="pb-1" title="jest wariantem produktu">
        {!indexDetails.product ? (
          <EmptyValue fontSize="16" />
        ) : (
          <Link
            className={styles.productLink}
            fontSize="16"
            fontWeight="500"
            to={`/products/list/all?panelId=${indexDetails.product.id}`}
          >
            {indexDetails.product.name}
          </Link>
        )}
      </InfoLabel>

      <InfoLabel className="pb-2" title="dodano">
        <Typography fontSize="14" fontWeight="400">
          {dateUtils.formatDateToDisplay(indexDetails.created)}
        </Typography>
      </InfoLabel>
      <InfoLabel className="pb-2" title="oferta">
        {indexDetails.isDeleted ? (
          <div className="d-flex align-items-center gap-1">
            <Typography color="danger300" fontSize="14" fontWeight="700">
              wycofana
            </Typography>
            <IconButton
              icon={MdiShoppingCart}
              isLoading={isDeletedMutation.isLoading}
              onClick={() =>
                isDeletedMutation.mutate({
                  id: indexDetails.id,
                  toUpdate: {
                    isDeleted: false,
                  },
                })
              }
              variant="transparent"
            />
          </div>
        ) : (
          <div className="d-flex align-items-center gap-1">
            <Typography color="info300" fontSize="14" fontWeight="700">
              w sprzedaży
            </Typography>
            <IconButton
              icon={MdiSaleWithdrawn}
              isLoading={isDeletedMutation.isLoading}
              onClick={() =>
                isDeletedMutation.mutate({
                  id: indexDetails.id,
                  toUpdate: {
                    isDeleted: true,
                  },
                })
              }
              variant="transparent"
            />
          </div>
        )}
      </InfoLabel>
      <InfoLabel className="pb-1" title="kod (sygnatura)">
        <Typography color="neutralBlack88" fontSize="14" fontWeight="500">
          {indexDetails.signature}
        </Typography>
      </InfoLabel>
      <InfoLabel className="pb-1" title="EAN">
        <Typography color="neutralBlack88" fontSize="14" fontWeight="600">
          {indexDetails.ean || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel className="pb-1" title="EAN 2">
        <Typography color="neutralBlack88" fontSize="14" fontWeight="600">
          {indexDetails.eanSecond || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel className="pb-2" title="SKU">
        <Typography color="neutralBlack88" fontSize="14" fontWeight="600">
          {indexDetails.sku || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="schemat produkcji">
        <div className="d-flex align-items-center gap-2">
          {indexDetails.manufacturingSchema && (
            <Link
              fontSize="14"
              fontWeight="500"
              to={`/manufacturing/manufacturing-schemas/list/all?panelId=${indexDetails.manufacturingSchema.id}`}
            >
              {indexDetails.manufacturingSchema.signature}
            </Link>
          )}
          {indexDetails.manufacturingSchema && (
            <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
              {indexDetails.manufacturingSchema.name}
            </Typography>
          )}
          <AssignSchema
            indexDetails={{
              initialSchema: indexDetails.manufacturingSchema || null,
              name: indexDetails.name,
              signature: indexDetails.signature,
            }}
          >
            {({ close, schema }) => (
              <Button
                className="text-uppercase"
                endIcon={MdiSchemeAlt}
                isLoading={schemaMutation.isLoading}
                onClick={() =>
                  schemaMutation.mutate(
                    {
                      id: indexDetails.id,
                      toUpdate: {
                        manufacturingSchema: schema,
                      },
                    },
                    {
                      onSuccess: close,
                    },
                  )
                }
                size="small"
                variant="deepPurple"
              >
                {indexDetails.manufacturingSchema ? "Zmień" : "Przypisz"} schemat
              </Button>
            )}
          </AssignSchema>
        </div>
      </InfoLabel>
    </RightPanelSection>
  );
};
