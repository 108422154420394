import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { assertIsDefined } from "utilities/assertIsDefined";
import { PanelHeader } from "./components/PanelHeader";
import { GeneralInfoSection } from "./components/generalInfoSection/GeneralInfoSection";
import { TitleSection } from "./components/titleSection/TitleSection";
import { useDrawer } from "hooks/useDrawer";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { wmsAwaitingTransportsActions } from "api/wms/awaiting-transports/actions";
import { OrdersSection } from "./components/ordersSection/OrdersSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { close } = useDrawer("wmsAwaitingTransport");
  const { panelId } = query;
  const {
    data: awaitingTransport,
    error,
    isLoading,
  } = wmsAwaitingTransportsActions.useGetAwaitingTransport(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(awaitingTransport);

  return (
    <TriggerScrollContext>
      <DrawerRightPanel className="position-relative" key={panelId}>
        <PanelHeader close={close} awaitingTransport={awaitingTransport} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <TitleSection awaitingTransport={awaitingTransport} />
            <GeneralInfoSection awaitingTransport={awaitingTransport} />
            <OrdersSection awaitingTransport={awaitingTransport} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
