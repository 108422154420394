import { InpostDefaults, InpostDetails } from "./models";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { inpostKeys } from "./keys";
import { PartialOf } from "typeUtilities";
import { Courier, Shipment } from "../models";
import { ShippingService } from "constants/shippingServiceConstants";
import { Assign } from "utility-types";

const getDefaults = (id: Courier["id"]): ApiFetcher<InpostDefaults> => ({
  key: inpostKeys.defaults(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/shipping/shipping-services-provider/${ShippingService.INPOST}/${id}`,
    }),
});

const patchDefaults = (data: { toUpdate: PartialOf<InpostDefaults>; id: Courier["id"] }) =>
  queryFetch<Courier>({
    method: "PATCH",
    url: `/shipping/shipping-services-provider/${ShippingService.INPOST}/${data.id}`,
    data: data.toUpdate,
  });

const getDetails = (id: Shipment["id"]): ApiFetcher<InpostDetails> => ({
  key: inpostKeys.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/shipping/shipments-provider/${ShippingService.INPOST}?shipment=${id}`,
    }),
});

const patchDetails = (data: Assign<PartialOf<InpostDetails>, { shipments: Shipment["id"][] }>) =>
  queryFetch<Courier>({
    method: "PATCH",
    url: `/shipping/shipments-provider/${ShippingService.INPOST}`,
    data,
  });

export const inpostApi = {
  patchDefaults,
  patchDetails,
  getDefaults,
  getDetails,
};
