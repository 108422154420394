import { smsCountryDict } from "CONSTANTS";
import { manufacturersActions } from "api/manufacturers/actions";
import { MdiAccountCircle } from "components/miloDesignSystem/atoms/icons/MdiAccountCircle";
import { MdiCall } from "components/miloDesignSystem/atoms/icons/MdiCall";
import { MdiCopyright } from "components/miloDesignSystem/atoms/icons/MdiCopyright";
import { MdiMail } from "components/miloDesignSystem/atoms/icons/MdiMail";
import { MdiPinDrop } from "components/miloDesignSystem/atoms/icons/MdiPinDrop";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { CommonError } from "components/utils";
import { Title } from "pages/crm/customers/customerDetails/components/Title";
import { SectionWrapper } from "pages/crm/customers/customerDetails/components/sectionWrapper/SectionWrapper";
import { useParams } from "react-router";
import { getAnyErrorKey, dateUtils } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const ServiceProviderSummary = () => {
  const { serviceProviderId } = useParams<{ serviceProviderId: string }>();
  const { data: serviceProvider, error, isLoading } = manufacturersActions.useContractorDetails(
    serviceProviderId,
    {
      enabled: Boolean(serviceProviderId),
    },
  );

  if (isLoading)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4 align-self-start"
        width="516px"
        minHeight={516}
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4 align-self-start"
        width="516px"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  assertIsDefined(serviceProvider);

  return (
    <SectionWrapper className="mr-3 mb-3 p-3 pb-4 align-self-start" width="516px">
      <div className="d-flex flex-column">
        <Typography fontSize="26" fontWeight="700">
          {serviceProvider.name || EMPTY_VALUE}
        </Typography>
        <Typography fontSize="10" fontWeight="300">
          dodano: {dateUtils.formatDateToDisplay(serviceProvider.createdAt)}
        </Typography>
      </div>
      <div className="d-flex flex-column gap-2 pt-3 pb-4">
        <Title>Dane do faktur i oficjalnej korespondencji</Title>
        <div className="d-flex align-items-center">
          <MdiCopyright className="mr-2" />
          <Typography fontSize="16" fontWeight="600">
            {serviceProvider.fullName || EMPTY_VALUE}
          </Typography>
        </div>
        <div className="d-flex align-items-center">
          <MdiAccountCircle className="mr-2" />
          <Typography fontSize="16" fontWeight="600">
            {!serviceProvider.ownerFirstName && !serviceProvider.ownerLastName && EMPTY_VALUE}
            {serviceProvider.ownerFirstName} {serviceProvider.ownerLastName}
          </Typography>
        </div>
        <div className="d-flex align-items-center">
          <MdiCall className="mr-2" />
          <Typography fontSize="16" fontWeight="600">
            {serviceProvider.phone || EMPTY_VALUE}
          </Typography>
        </div>
        <div className="d-flex align-items-center">
          <MdiMail className="mr-2" />
          <Typography fontSize="16" fontWeight="600">
            {serviceProvider.email || EMPTY_VALUE}
          </Typography>
        </div>
        <div className="d-flex align-items-start">
          <MdiPinDrop className="mr-2" />
          <div>
            <Typography fontSize="16" fontWeight="600">
              {serviceProvider.street}
            </Typography>
            <Typography fontSize="16" fontWeight="600">
              {serviceProvider.postCode} {serviceProvider.city}
            </Typography>
            <Typography fontSize="16" fontWeight="600">
              {smsCountryDict[serviceProvider.countryCode] || serviceProvider.countryCode}
            </Typography>
          </div>
        </div>
        <div className="ml-4">
          <Title fontWeight="600" className="mb-0">
            REGON
          </Title>
          <Typography fontSize="16" fontWeight="400">
            {serviceProvider.businessRegisterNumber || EMPTY_VALUE}
          </Typography>
        </div>
        <div className="ml-4">
          <Title fontWeight="600" className="mb-0">
            NIP
          </Title>
          <Typography fontSize="16" fontWeight="400">
            {serviceProvider.taxId || EMPTY_VALUE}
          </Typography>
        </div>
        <div className="ml-4">
          <Title fontWeight="600" className="mb-0">
            Bank
          </Title>
          <Typography fontSize="16" fontWeight="400">
            {serviceProvider.bankAccountName || EMPTY_VALUE}
          </Typography>
        </div>
        <div className="ml-4">
          <Title fontWeight="600" className="mb-0">
            Nr konta bankowego
          </Title>
          <Typography fontSize="16" fontWeight="400">
            {serviceProvider.bankAccountNumber || EMPTY_VALUE}
          </Typography>
        </div>
      </div>
    </SectionWrapper>
  );
};
