import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiBusinessCenter } from "components/miloDesignSystem/atoms/icons/MdiBusinessCenter";
import { CustomersRouting } from "routes/crm/CustomersRouting";

export const customers: ModuleLink = {
  url: "customers",
  label: "Lista kontrahentów",
  icon: MdiBusinessCenter,
  routing: CustomersRouting,
  isAccessible: true,
};
