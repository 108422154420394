import { NavLink } from "react-router-dom";
import { cx } from "utilities";
import { IconRenderer } from "components/miloDesignSystem/atoms/shared/IconRenderer";
import styles from "./ModuleLinkRenderer.module.css";
import { ModuleLink } from "../../moduleTypes/moduleTypes";
import { useThresholdToggle } from "hooks";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardArrowUp } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowUp";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { useState } from "react";
import { MdiKeyboardArrowDown } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowDown";

interface Props {
  moduleLink: ModuleLink;
  currentModuleUrl: string;
}
export const ModuleLinkRenderer = (props: Props) => {
  const { isOpen } = useThresholdToggle();
  if (!isOpen) return <CollapsedModuleLink {...props} />;
  return <ExpandedModuleLink {...props} />;
};

const ExpandedModuleLink = ({ moduleLink, currentModuleUrl }: Props) => {
  const url = `${currentModuleUrl}/${moduleLink.url}`;
  const { isSubSectionOpen, toggleSubSectionVisibility } = useSubSectionVisibility(
    url,
    moduleLink.subSection?.isExpandedByDefault || false,
  );
  return (
    <div className="w-100">
      <NavLink
        tabIndex={-1}
        className={cx(styles.link, moduleLink.isAccessible === false ? styles.linkDisabled : "")}
        key={moduleLink.url}
        onClick={evt => {
          if (moduleLink.isAccessible === false) {
            evt.preventDefault();
            return;
          }
        }}
        to={url}
        activeClassName={isSubSectionOpen ? "" : styles.linkActive}
      >
        <div className="d-flex align-items-center gap-2">
          <div className={styles.icon}>
            <IconRenderer color="neutralWhite48" icon={moduleLink.icon} size={17} />
          </div>
          <Typography fontSize="14" fontWeight="700" className={styles.linkName}>
            {moduleLink.label}
          </Typography>
        </div>
        {moduleLink.subSection && (
          <IconButton
            className="flex-1"
            size="small"
            icon={isSubSectionOpen ? MdiKeyboardArrowUp : MdiKeyboardArrowDown}
            onClick={e => {
              e.preventDefault();
              toggleSubSectionVisibility();
            }}
            variant="transparent"
            theme="dark"
            color={colorPalette.neutralWhite48}
          />
        )}
      </NavLink>
      {isSubSectionOpen && moduleLink.subSection?.renderer}
    </div>
  );
};

const CollapsedModuleLink = ({ moduleLink, currentModuleUrl }: Props) => {
  const url = `${currentModuleUrl}/${moduleLink.url}`;

  return (
    <div className="w-100">
      <NavLink
        tabIndex={-1}
        className={cx(
          styles.link,
          moduleLink.isAccessible === false ? styles.linkDisabled : "",
          "justify-content-center p-0 py-2",
        )}
        key={moduleLink.url}
        onClick={evt => {
          if (moduleLink.isAccessible === false) {
            evt.preventDefault();
            return;
          }
        }}
        to={url}
        activeClassName={styles.linkActive}
      >
        <div className={styles.icon}>
          <IconRenderer color="neutralWhite48" icon={moduleLink.icon} size={17} />
        </div>
      </NavLink>
    </div>
  );
};

const useSubSectionVisibility = (moduleUrl: string, isExpandedByDefault: boolean) => {
  const key = `${moduleUrl}-is-sub-navigation-visible`;
  const isOpenState: boolean = (() => {
    const baseValue = localStorage.getItem(key);
    if (!baseValue) return isExpandedByDefault;
    return baseValue === "true" ? true : false;
  })();
  const [isOpen, setIsOpen] = useState(isOpenState);

  const toggle = () => {
    setIsOpen(prev => !prev);
    localStorage.setItem(key, String(!isOpen));
    return;
  };

  return { isSubSectionOpen: isOpen, toggleSubSectionVisibility: toggle };
};
