import advanceIcon from "assets/images/cashAdvance.svg";
import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { tradingDocumentsTabDict } from "pages/tradingDocuments/shared/utils/getTabs";
import { AdvanceInvoices } from "routes/AdvanceInvoices";

export const advances: ModuleLink = {
  url: "advances",
  label: "Faktury Zaliczkowe",
  icon: advanceIcon,
  subSection: {
    renderer: <TabSubSection baseUrl="/finances/advances/list" tabs={tradingDocumentsTabDict} />,
    isExpandedByDefault: false,
  },
  routing: AdvanceInvoices,
};
