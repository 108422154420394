import { createApiQuery } from "hooks/createApiQuery";
import { wmsUtilsApi } from "./api";

const useGetPackageInfo = createApiQuery(wmsUtilsApi.getPackageInfo);
const useGetPackageOrderInfo = createApiQuery(wmsUtilsApi.getPackageOrderInfo);

export const wmsUtilsActions = {
  useGetPackageInfo,
  useGetPackageOrderInfo,
};
