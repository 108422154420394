import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import badge from "assets/images/moduleNavigationIcons/badge.svg";
import { Employees } from "routes/manufacturingNew/Employees";

export const manufacturingEmployees: ModuleLink = {
  url: "employees",
  label: "Pracownicy",
  icon: badge,
  routing: Employees,
};
