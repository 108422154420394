import { StatusHandlerHelpers } from "components/utils";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useErrorToastr } from "hooks/useErrorToastr";
import { wmsFileFactory } from "../calls";
import { fileDownloader } from "fileDownloader";
import { WarehouseWorkerDetails } from "../models";
import { ISODate } from "api/types";

const useDownloadWarehousemanCsv = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (
    helpers: StatusHandlerHelpers,
    warehouseWorker: WarehouseWorkerDetails,
    dateFrom: ISODate,
    dateTo: ISODate,
  ) => {
    helpers.startFetching();
    const tstr = downloadFeedbackToastr.open({ type: "csv", calculateProgress: false });
    const { url, name } = wmsFileFactory.releasedAndReceivedCsv(warehouseWorker, dateFrom, dateTo);
    const response = await fileDownloader({
      url,
      name,
      type: "csv",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
    helpers.stopFetching();
  };
};

export const wmsWarehousemanActions = {
  useDownloadWarehousemanCsv,
};
