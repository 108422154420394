import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";

export const wmsReturnsKeys = {
  list: createPaginatedKey("returns"),
  details: (returnId: UUID) => ["return", returnId],
  lineItems: createPaginatedKey("returnLineItems"),
  lineItem: (returnLineItemId: UUID) => ["returnLineItem", returnLineItemId],
  workspace: (userId: string) => ["returnWorkspace", userId],
};
