import { DOMAIN } from "ENDPOINTS";
import { InferArgsType } from "typeUtilities";
import { flattenErrors } from "utilities";
import { storeConnector } from "./storeConnector";
import { tokenMiddlewareController } from "./tokenMiddlewareController";
import { tokenMeta, tokenRefresher } from "./tokenRefresher";
import { getLang, removeSlash } from "./utils";

interface Statuses {
  status: number;
  isCanceled: boolean;
  rawError?: any;
}

export interface Error {
  [key: string]: string;
}

interface Params {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  headers?: { [key: string]: string };
  data?: unknown;
  origin?: string;
  abortToken?: string;
  mode?: "navigate" | "same-origin" | "no-cors" | "cors";
  responseType?: "arraybuffer" | "blob";
  tokenMiddleware?: boolean;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

/** A controller object that allows you to abort one or more DOM requests as and when desired. */
interface AbortController {
  /**
   * Returns the AbortSignal object associated with this object.
   */
  readonly signal: AbortSignal;
  /**
   * Invoking this method will set this object's AbortSignal's aborted flag and signal to any observers that the associated activity is to be aborted.
   */
  abort(): void;
}

const abortControllers: { [key: string]: AbortController } = {};

export type ApiMiddlewareResult<P> = Promise<[P, null, Statuses] | [null, Error | null, Statuses]>;

/**
 * Fetch middleware wrapper function.
 * Returns a promise with shape: [ result: ?any, error: ?object, {status: number} ]
 */
async function fetchMiddleware<P>({
  url,
  method = "GET",
  headers = {},
  data,
  origin,
  abortToken,
  responseType,
  mode,
  tokenMiddleware = false,
}: Params): ApiMiddlewareResult<P> {
  if (["get", "post", "put", "delete", "patch"].indexOf(method.toLowerCase()) === -1) {
    throw new Error("invalid method " + method);
  }
  if (
    url
      .split("?")[0]
      .split("/")
      .some(el => el === "undefined")
  ) {
    throw new Error(`You passed undefined as api url parameter! url: ${url}`);
  }
  const allHeaders: { [key: string]: string } = {
    "Accept-Language": getLang(),
    ...headers,
  };
  const token = localStorage && localStorage.token;
  const endpoint = (origin || DOMAIN) + removeSlash(url);

  if (token && tokenMiddleware) {
    // if user is logged in - add token to request headers
    allHeaders.Authorization = "Bearer " + tokenMeta.accessToken.token;
  }
  if (data instanceof FormData === false) {
    allHeaders["Content-Type"] = "application/json";
  }

  // define abort controller for duplicate request canceling
  const abortName = abortToken || "";
  if (abortControllers[abortName]) {
    abortControllers[abortName].abort();
  }
  const signal = (() => {
    if (!abortToken) return undefined;
    abortControllers[abortName] = new window.AbortController();
    return abortControllers[abortName].signal;
  })();

  try {
    const res = await fetch(endpoint, {
      body: (() => {
        if (!data) return undefined;
        if (data instanceof FormData) return data;
        return JSON.stringify(data);
      })(),
      headers: new Headers(allHeaders),
      method,
      signal,
      mode,
    });
    return new Promise(async resolve => {
      delete abortControllers[abortName];

      if (res.ok) {
        let result_2;
        if (responseType === "arraybuffer") {
          result_2 = await res.arrayBuffer();
          return resolve([result_2 as any, null, { status: res.status, isCanceled: false }]);
        }
        if (responseType === "blob") {
          result_2 = await res.blob();
          console.error("blobs are not handled yet");
          // const reader = new FileReader();
          // reader.readAsDataURL(result_2);
          // reader.onloadend = function onloadend() {
          //   return resolve([reader.result, null, { status: res.status, isCanceled: false }]);
          // };
        } else {
          if (res.headers.get("content-type")?.includes("application/json")) {
            result_2 = await res.json();
          }
          return resolve([result_2, null, { status: res.status, isCanceled: false }]);
        }
      } else {
        let error = {};
        let rawError = {};
        // this block will fail if server response is no json, eg. HTML
        try {
          const result_3 = await res.json();
          error = flattenErrors(result_3);
          rawError = result_3;
        } catch (err) {
          return resolve([null, error, { status: res.status, isCanceled: false, rawError }]);
        }
        return resolve([null, error, { status: res.status, isCanceled: false, rawError }]);
      }
    });
  } catch (err_1) {
    const isCanceled = (err_1 as Error).name === "AbortError";
    if (isCanceled) {
      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line
        console.log("Duplicated request canceled");
      }
    } else {
      delete abortControllers[abortName];
    }
    return Promise.resolve([
      null,
      isCanceled ? null : { details: "Serwer nie odpowiada" },
      { status: 0, isCanceled },
    ]);
  }
}

export default fetchMiddleware;

type CanceledOrExpired = [null, null, Statuses];

// export const middlewareExample = (fetchApiFunc:typeof fetchMiddleware) => (params:ArgumentTypes<typeof fetchMiddleware>) => {
//   return fetchApiFunc({ ...params, someMiddlewareProp: true });
// };

export const connectStoreToApiMiddleware = (dispatch: any) => {
  storeConnector.dispatch = dispatch;
};

export const tokenRefreshMiddleware = (fetchApi: typeof fetchMiddleware) => {
  return async function<T>(params: InferArgsType<typeof fetchMiddleware>[0]) {
    if (tokenMiddlewareController.promise) {
      await tokenMiddlewareController.promise;
    }
    tokenMiddlewareController.promise = new Promise<void>(resolve => {
      tokenMiddlewareController.resolve = resolve;
    });
    const currentToken = localStorage.getItem("token");
    if (currentToken) {
      const tokenRefreshStatus = await tokenRefresher(currentToken);
      tokenMiddlewareController.resolve?.();
      if (tokenRefreshStatus === "failure") {
        return Promise.resolve([
          null,
          null,
          { status: -1, isCanceled: false },
        ] as CanceledOrExpired);
      }
    } else {
      tokenMiddlewareController.resolve?.();
      return Promise.resolve([null, null, { status: -1, isCanceled: false }] as CanceledOrExpired);
    }
    return fetchApi<T>({ ...params, tokenMiddleware: true });
  };
};
