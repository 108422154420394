import { WmsReturnGroupPackage } from "api/wms/returns/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { wmsReturnConstants } from "constants/returns";
import { dateFns } from "utilities";
import { parseLocation } from "utilities/parseLocation";
import {
  EMPTY_VALUE,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useReturnWorkspaceColumns = () => {
  return useCreateTableColumns<WmsReturnGroupPackage>(({ columnHelper }) => {
    return [
      columnHelper.text(row => (Boolean(row.name.length) ? row.name : EMPTY_VALUE), {
        header: "nazwa paczki",
        size: 570,
      }),
      columnHelper.text(row => parseLocation(row.location).name, {
        header: "lokalizacja",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "700",
          color: "success500",
        },
      }),
      columnHelper.accessor(row => row.stockStatus, {
        header: "status",
        size: 120,
        cell: info => {
          const status = info.getValue();
          return (
            <Tag
              label={wmsReturnConstants.packageGroupStatusObject[status].label}
              variant={wmsReturnConstants.packageGroupStatusObject[status].variant}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.addedToStockAt, {
        id: "when",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            kiedy?
          </Typography>
        ),
        size: 110,
        cell: info => {
          const addedToStockAt = info.getValue();
          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {addedToStockAt
                ? dateFns.formatRelative(new Date(addedToStockAt), "dd.MM.yyyy, H:mm")
                : EMPTY_VALUE}
            </Typography>
          );
        },
      }),
    ];
  });
};
