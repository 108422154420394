import { createPaginatedKey } from "api/keys";
import { MaterialListItem } from "./models";

export const materialsKeys = {
  materials: {
    list: createPaginatedKey("materials"),
    details: (id: MaterialListItem["id"]) => ["material", id],
    demands: (search: string) => ["stageMaterialsDemands", search],
  },
  materialUnits: createPaginatedKey("materialUnits"),
  materialCategories: createPaginatedKey("materialCategories"),
};
