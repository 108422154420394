export const blue = {
  blue12: "#5EE4F71F",
  blue25: "#DBFBFF",
  blue32: "#5EE4F752",
  blue50: "#C1DDF8",
  blue75: "#9BC9F5",
  blue100: "#53E3F8",
  blue200: "#2B8EFA",
  blue300: "#2A83E1",
  blue400: "#1B74D2",
  blue500: "#0E60B7",
  blue600: "#003F82",
};
