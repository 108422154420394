import { CommonError } from "components/utils";
import { useLocation, useParams } from "react-router";
import { ListHeader } from "./components/ListHeader";
import { Table } from "components/miloDesignSystem/molecules/table";
import { UUID } from "api/types";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useProductionProgressColumns } from "./useProductionProgressColumns";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import styles from "./RouteProgress.module.css";
import { cx, dictToList, queryString } from "utilities";
import { orderActions } from "api/orders/actions";
import { ManufacturingItemProgress } from "api/orders/models";
import { filterFactory, withFilters } from "components/utils/withFilters";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { productionOrdersActions } from "api/production-orders/hooks";
import { MdiFabric } from "components/miloDesignSystem/atoms/icons/MdiFabric";
import { ManufacturingStatus } from "api/routes/models";
import { useQuery } from "hooks";

const manufacturingStatusToTagDict: Record<ManufacturingStatus, string> = {
  [ManufacturingStatus.FINISHED]: "Wyprodukowano",
  [ManufacturingStatus.IN_PROGRESS]: "W trakcie",
  [ManufacturingStatus.PENDING]: "Nie rozpoczęto",
};

const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Select,
    label: "Postęp produkcji",
    name: "manufacturingItemStatus",
    options: dictToList(manufacturingStatusToTagDict).map(({ key, value }) => ({
      label: value,
      value: key,
    })),
  },
  {
    type: FilterType.Select,
    kind: "checkbox",
    label: "Czy jest zlecone do produkcji?",
    name: "hasManufacturingItem",
    options: [
      { label: "Tak", value: "true" },
      { label: "Nie", value: "false" },
    ],
  },
]);

export const useProductionProgressResourceType = () => {
  const location = useLocation();
  if (location.pathname.includes("orderGroup")) return "orderGroup";

  if (location.pathname.includes("order")) return "order";

  if (location.pathname.includes("route")) return "route";

  return "";
};

export const ProductionProgress = withFilters(filterList, () => {
  const resourceType = useProductionProgressResourceType();
  const { resourceId } = useParams<{ resourceId: UUID }>();
  const { query } = useQuery();
  const search = queryString.stringify({ [resourceType]: resourceId, ...query });
  const {
    data,
    error,
    isLoading,
    pagination,
    isPreviousData,
  } = orderActions.useGetOrderManufacturingSingleItemQuantities(search);
  const createManufacturerOrdersMutation = productionOrdersActions.useCreateProductionOrders();

  const tableMultiSelect = useTableMultiSelect({ rows: data });

  const tableProps = useTableFeatureConnector({
    rows: data,
    withMultiSelect: tableMultiSelect,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  const columns = useProductionProgressColumns();

  if (error) {
    return (
      <div>
        <ListHeader clearSelected={tableMultiSelect.clear} />
        <div className="mt-4">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );
  }

  return (
    <PageWrapper>
      <ListHeader clearSelected={tableMultiSelect.clear} />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<ManufacturingItemProgress>
          columns={columns}
          isLoading={isLoading || isPreviousData}
          error={error}
          uiSchema={{
            header: { backgroundColor: "grey25" },
            cell: { height: "74" },
          }}
          {...tableProps}
          overrides={() => ({
            ...tableProps.overrides?.(),
            row: row => ({
              className: cx(styles.row, tableProps.overrides?.().row?.(row).className),
            }),
          })}
        />
        <TableToolbar
          close={tableMultiSelect.clear}
          numberOfSelectedItems={tableMultiSelect.selectedRows.length}
        >
          <Tooltip title="Zleć produkcję">
            <IconButton
              icon={MdiFabric}
              isLoading={createManufacturerOrdersMutation.isLoading}
              variant="transparent"
              theme="dark"
              onClick={() =>
                createManufacturerOrdersMutation.mutate(
                  {
                    items: tableMultiSelect.selectedRows.map(row => ({
                      id: row.item.id,
                      indexId: row.index.id,
                      isSelfProduced: true,
                      manufacturer: null,
                      number: 1,
                      order: row.order!.id,
                    })),
                  },
                  {
                    onSuccess: () => tableMultiSelect.clear(),
                  },
                )
              }
            />
          </Tooltip>
        </TableToolbar>
      </div>
    </PageWrapper>
  );
});
