import { wmsLayoutActions } from "api/wms/layout/actions";
import { FieldDetails } from "api/wms/layout/models";
import { UnloadingSection } from "./components/UnloadingSection";
import { LoadingSection } from "./components/LoadingSection";
import { PickingSection } from "./components/PickingSection";

interface Props {
  field: FieldDetails;
  selectedHall: number;
}

export const RampDetailsSection = ({ field, selectedHall }: Props) => {
  const { data: rampStatuses } = wmsLayoutActions.useRampStatus(selectedHall, {
    enabled: Boolean(selectedHall),
  });
  const ramp = rampStatuses?.find(rampStatus => rampStatus.id === field.wmsId);

  return (
    <div>
      {ramp?.unloading && <UnloadingSection unloadingId={ramp.unloading.id} />}
      {ramp?.picking && <PickingSection pickingId={ramp.picking.id} />}
      {ramp?.loading && <LoadingSection loadingId={ramp.loading.id} />}
    </div>
  );
};
