import { useMemo, useState } from "react";
import { useMenuButton, useMenuState } from "reakit";
import v4 from "cuid";
import { useFilters } from "hooks/useFilters";
import { EndInputSectionProps, Filters, NormalizedItem, SchemaSearchProps } from "./types";
import { ClickOutsideHandler } from "components/utils";
import { useDebounce, useQuery } from "hooks";
import { cx, queryString } from "utilities";
import { wmsLayoutActions } from "api/wms/layout/actions";
import { MdiSearch } from "components/miloDesignSystem/atoms/icons/MdiSearch";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import styles from "./SchemaSearch.module.css";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import {
  SchemaSearchCollection,
  SchemaSearchRecentSearch,
  SearchCollectionOverview,
} from "api/wms/layout/models";
import { assertIsDefined } from "utilities/assertIsDefined";
import { SearchList } from "./SearchList";

export const SchemaSearch = ({ textFieldProps }: SchemaSearchProps) => {
  const { setQuery, query } = useQuery();
  const menu = useMenuState({});
  const menuButton = useMenuButton(menu);
  const clickOutsideIgnoreClass = useMemo(() => `click-outside-select-omit-${v4()}`, []);
  const { filters, setFilter } = useFilters<Filters>({ search: query.collectionName || "" });
  const search = useDebounce(queryString.stringify({ ...filters }), 400);
  const [isVisible, setIsVisible] = useState(false);
  const {
    data: searchedCollections,
    isFetching,
    isPreviousData,
  } = wmsLayoutActions.useSchemaSearchCollections(search, {
    enabled: Boolean(isVisible),
  });

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = event => {
    event.stopPropagation();
    setIsVisible(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter("search", event.currentTarget.value);
  };

  const clearSearchValue = () => {
    setFilter("search", "");
  };

  const customOnChange = (item: NormalizedItem | null) => {
    if (!item) {
      setQuery({ ...query, searchCollectionId: "", kind: "", collectionName: "" });
      setIsVisible(false);
      return;
    }
    if (searchedCollections) {
      const selectedFoundItem = getSingleCollectionList(searchedCollections).find(
        _item => _item.value === item.value && _item.kind === item.kind,
      );
      assertIsDefined(selectedFoundItem);
      setQuery({
        ...query,
        searchCollectionId: selectedFoundItem.value,
        kind: selectedFoundItem.kind,
        collectionName: selectedFoundItem.text,
      });
      setIsVisible(false);
    }
  };

  const handleMenuItemClick = (item: NormalizedItem | null) => {
    if (item) customOnChange(item);
    else customOnChange(null);
    clearSearchValue();
    setIsVisible(false);
  };

  return (
    <ClickOutsideHandler
      onClickOutside={() => {
        setIsVisible(false);
        clearSearchValue();
      }}
      outsideClickIgnoreClass={clickOutsideIgnoreClass}
    >
      <div
        className="position-relative"
        ref={menuButton.ref}
        onClick={() => {
          if (!isVisible) setIsVisible(true);
        }}
      >
        <TextField
          EndInputSection={
            <EndInputSection
              clearInput={() => {
                clearSearchValue();
                customOnChange(null);
              }}
              hasSelectedValue={Boolean(query.kind && query.searchCollectionId)}
              showLoader={isFetching && isVisible}
            />
          }
          inputClassName={cx(
            { [styles.selectedPlaceholder]: Boolean(query.kind && query.searchCollectionId) },
            clickOutsideIgnoreClass,
          )}
          containerClassName={clickOutsideIgnoreClass}
          endIcon={<MdiSearch color="neutralWhite48" size="18" />}
          value={filters.search}
          placeholder={query.collectionName || "Szukaj..."}
          onChange={handleChange}
          size="default"
          onFocus={handleFocus}
          theme="dark"
          {...textFieldProps}
        />
        {(isPreviousData || isVisible) && (
          <SearchList
            data={searchedCollections}
            handleMenuItemClick={handleMenuItemClick}
            isLoading={isFetching || isPreviousData}
          />
        )}
      </div>
    </ClickOutsideHandler>
  );
};

const EndInputSection = ({ clearInput, hasSelectedValue, showLoader }: EndInputSectionProps) => {
  return (
    <div className="d-flex align-items-center gap-1">
      <div className={styles.showLoader}>{showLoader && <Spinner size={18} />}</div>
      {hasSelectedValue && (
        <IconButton
          icon={MdiClose}
          theme="dark"
          variant="transparent"
          size="small"
          onClick={event => {
            event.stopPropagation();
            clearInput();
          }}
        />
      )}
    </div>
  );
};

export const normalizeItem = (
  itemToNormalize: SchemaSearchCollection | SchemaSearchRecentSearch,
): NormalizedItem => {
  return {
    color: itemToNormalize.color,
    kind: itemToNormalize.kind,
    text: itemToNormalize.name,
    value: String(itemToNormalize.id),
  };
};

const getSingleCollectionList = (searchedCollections: SearchCollectionOverview) => {
  const collections = Object.values(searchedCollections).flat();
  return collections.map(item => normalizeItem(item));
};
