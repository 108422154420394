import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { stagesSchemasListConfig } from "../../utils/panelTablesConfig";
import { Schema } from "./Schema";
import { ManufacturingStage } from "api/manufacturing/schemas/models";

interface Props {
  manufacturingStage: ManufacturingStage;
}

export const SchemasSection = ({ manufacturingStage }: Props) => {
  return (
    <RightPanelSection title="Schematy, w których znajduje się etap">
      <div className="pb-3">
        <RightPanelTableRow
          className="tableHeaderGreyBg"
          grid={stagesSchemasListConfig.grid}
          hover={false}
        >
          <div>sygnatura</div>
          <div>nazwa</div>
          <div>opis</div>
          <div>dodano</div>
          <div>przez</div>
        </RightPanelTableRow>
        {manufacturingStage.schemas.map(schema => (
          <Schema key={schema.id} schema={schema} />
        ))}
      </div>
    </RightPanelSection>
  );
};
