import { Button } from "components/miloDesignSystem/atoms/button";
import styles from "../../CreateDraftDocument.module.css";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiMonetizationOn } from "components/miloDesignSystem/atoms/icons/MdiMonetizationOn";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { TradingDocument } from "api/trading-documents/models";
import { useSelector, useToggle } from "hooks";
import { AddOrdersToDraft } from "../../addOrdersToDraft/AddOrdersToDraft";
import { AddProductToDraft } from "../../addProductToDraft/AddProductToDraft";
import { Pricing } from "../../pricing/Pricing";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { createDraftDocumentUtils } from "../../utils";
import { AddRouteToDraft } from "../../addRouteToDraft/AddRouteToDraft";

interface Props {
  draftInvoice: TradingDocument;
}

export const ActionButtonsSection = ({ draftInvoice }: Props) => {
  const priceLists = useSelector(store => store.partials.priceLists);
  const createDraftDocumentPositionMutation = tradingDocumentsActions.useCreateDraftDocumentPosition();
  const addOrdersToDraft = useToggle();
  const addProductToDraft = useToggle();
  const addRouteToDraft = useToggle();
  const pricingModal = useToggle();

  return (
    <div className={styles.actionButtonsContainer}>
      <Button
        className="text-uppercase"
        onClick={() =>
          createDraftDocumentPositionMutation.mutate({
            tradingDocumentId: draftInvoice.id,
            positions: [
              {
                amountWithTax: 0,
                discount: 0,
                name: "",
                orderId: null,
                quantity: 1,
                vatRate: 0,
              },
            ],
          })
        }
        size="small"
        startIcon={MdiAdd}
        variant="gray"
      >
        Dodaj pozycję
      </Button>
      <Button
        className="text-uppercase"
        onClick={addProductToDraft.open}
        size="small"
        startIcon={MdiAdd}
        variant="gray"
      >
        Dodaj produkt
      </Button>
      <Button
        className="text-uppercase"
        onClick={addOrdersToDraft.open}
        size="small"
        startIcon={MdiAdd}
        variant="gray"
      >
        Dodaj zamówienia
      </Button>
      <Button
        className="text-uppercase"
        onClick={addRouteToDraft.open}
        size="small"
        startIcon={MdiAdd}
        variant="gray"
      >
        Dodaj pozycje na podstawie trasy
      </Button>
      {draftInvoice.priceModifier && Object.keys(draftInvoice.priceModifier).length > 0 ? (
        <div className="d-flex align-items-center gap-2">
          {createDraftDocumentUtils.getPricingMethod(
            draftInvoice.priceModifier.priceList?.id
              ? { id: draftInvoice.priceModifier.priceList?.id }
              : undefined,
            Number(draftInvoice.priceModifier.amountModifiers?.discount),
            Number(draftInvoice.priceModifier.amountModifiers?.margin),
            priceLists,
          )}
          <div className="cursor-pointer" onClick={pricingModal.open}>
            <Typography className="text-uppercase" color="blue300" fontSize="10" fontWeight="700">
              Zmień cenę
            </Typography>
          </div>
        </div>
      ) : (
        <Button
          className="text-uppercase"
          onClick={pricingModal.open}
          size="small"
          startIcon={MdiMonetizationOn}
          variant="gray"
        >
          Ustal cenę
        </Button>
      )}
      {addOrdersToDraft.isOpen && (
        <AddOrdersToDraft close={addOrdersToDraft.close} draftInvoice={draftInvoice} />
      )}
      {addProductToDraft.isOpen && (
        <AddProductToDraft close={addProductToDraft.close} draftInvoice={draftInvoice} />
      )}
      {addRouteToDraft.isOpen && (
        <AddRouteToDraft close={addRouteToDraft.close} draftInvoice={draftInvoice} />
      )}
      {pricingModal.isOpen && <Pricing close={pricingModal.close} draftInvoice={draftInvoice} />}
    </div>
  );
};
