import { TradingDocument } from "api/trading-documents/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Formik, FormikHelpers } from "formik";
import { cx, dateUtils } from "utilities";
import { ISODate } from "api/types";
import { ExchangeRateForm } from "./ExchangeRateForm";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { handleSubmitResponse } from "utilities/handleSubmitResponse";
import { validationSchema } from "./validationSchema";

interface Props {
  close: () => void;
  tradingDocument: TradingDocument;
}

export interface ExchangeRateCorrectionDTO {
  exchangeRateId: string | null;
  invoiceDeliveryDate: ISODate;
  invoiceIssueDate: TradingDocument["invoiceIssueDate"];
  lastValidExchangeRate: string | null;
  lastValidExchangeRateDate: ISODate | null;
  masterTradingDocumentId: TradingDocument["id"];
  reason: string;
}

export const ExchangeRateCorrection = ({ close, tradingDocument }: Props) => {
  const initialValues: ExchangeRateCorrectionDTO = {
    exchangeRateId: null,
    invoiceDeliveryDate: dateUtils.formatDateToIso(new Date()),
    invoiceIssueDate: dateUtils.formatDateToIso(new Date()),
    lastValidExchangeRate: tradingDocument.lastValidExchangeRate,
    lastValidExchangeRateDate: tradingDocument.lastValidExchangeRateDate,
    masterTradingDocumentId: tradingDocument.id,
    reason: "",
  };
  const postMutation = tradingDocumentsActions.useCreateCurrencyCorrection();

  const handleSubmit = (
    values: ExchangeRateCorrectionDTO,
    actions: FormikHelpers<ExchangeRateCorrectionDTO>,
  ) => {
    postMutation.mutate(
      values,
      handleSubmitResponse(actions, {
        onSuccess: close,
      }),
    );
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Skoryguj datę i kurs wymiany
        </Typography>
      }
      width={974}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isValid }) => (
          <form
            className={cx({
              "was-validated": !isValid,
            })}
            onSubmit={handleSubmit}
          >
            <ExchangeRateForm close={close} tradingDocument={tradingDocument} />
          </form>
        )}
      </Formik>
    </Modal>
  );
};
