import { useEffect } from "react";
import { useQuery } from "./useQuery";

export const useDefaultFilters = (params: { [key: string]: string | number }) => {
  const { query, updateQuery } = useQuery();

  const isParamAlreadyInQuery = (key: string) => {
    return query[key];
  };

  useEffect(() => {
    const validKeys = Object.fromEntries(
      Object.entries(params).filter(([key]) => !isParamAlreadyInQuery(key)),
    );
    updateQuery({
      ...validKeys,
      ...query,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
