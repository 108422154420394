import { createApiQuery } from "hooks/createApiQuery";
import { developerApi } from "./api";
import { useRedux, useSelector } from "hooks";
import { useMutation } from "hooks/useMutation";
import { deepMerge } from "utilities/deepMerge";

const useDeveloperSettings = createApiQuery(developerApi.getDeveloperSettings);

const usePatchDeveloperSettings = () => {
  const developerSettingsId = useSelector(store => store.partials.developers.settings[0].id);
  const { handleMutate } = developerActions.useDeveloperSettings({ id: developerSettingsId });
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  return useMutation(developerApi.patchDeveloperSettings, {
    onMutate: args =>
      handleMutate(draft => {
        Object.assign(draft, deepMerge(draft, args));
      }),
    onSuccess: () => {
      refetchPartials();
    },
    onError: (error, _, onMutateReturn) => {
      onMutateReturn?.(error);
    },
  });
};

export const developerActions = {
  useDeveloperSettings,
  usePatchDeveloperSettings,
};
