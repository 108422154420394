import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Formik, FormikHelpers } from "formik";
import { cx, noop } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Material, PostMaterialManufacturer } from "api/manufacturing/materials/models";
import { Search } from "components/miloDesignSystem/molecules/search";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useAddProviderColumns } from "./useAddProviderColumns";
import { ToggleHookState } from "hooks";
import { UUID } from "api/types";
import { materialsActions } from "api/manufacturing/materials/actions";
import { ManufacturerKind, ManufacturerListItem } from "api/manufacturers/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiRadioButtonChecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonChecked";
import { MdiRadioButtonUnchecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonUnchecked";
import { useState } from "react";
import { manufacturersApi } from "api/manufacturers/calls";
import { CreateManufacturerForm } from "pages/manufacturingNew/manufacturers/createManufacturer/CreateManufacturer";
import { manufacturersActions } from "api/manufacturers/actions";

interface Props {
  modal: ToggleHookState;
  materialId: UUID;
  manufacturers: Material["providers"];
}

export const AddProviderModal = (props: Props) => {
  const [createManufacturerForm, setCreateManufacturerForm] = useState(false);

  return (
    <Modal
      close={props.modal.close}
      isOpen
      width={640}
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj dostawców
        </Typography>
      }
    >
      <div>
        <div className="d-flex align-items-center gap-2 mx-3">
          <div onClick={() => setCreateManufacturerForm(false)} className="cursor-pointer">
            <Tag
              label="Już istniejący"
              startIcon={!createManufacturerForm ? MdiRadioButtonChecked : MdiRadioButtonUnchecked}
              type={!createManufacturerForm ? "filled" : "outlined"}
              variant={!createManufacturerForm ? "deepPurple50" : "quaternary"}
            />
          </div>
          <div onClick={() => setCreateManufacturerForm(true)} className="cursor-pointer">
            <Tag
              label="Nowy dostawca"
              startIcon={createManufacturerForm ? MdiRadioButtonChecked : MdiRadioButtonUnchecked}
              type={createManufacturerForm ? "filled" : "outlined"}
              variant={createManufacturerForm ? "deepPurple50" : "quaternary"}
            />
          </div>
        </div>
        {createManufacturerForm ? (
          <CreateProviderForm {...props} />
        ) : (
          <AddProviderForm {...props} />
        )}
      </div>
    </Modal>
  );
};

const CreateProviderForm = ({ manufacturers, materialId, modal }: Props) => {
  const postMutation = materialsActions.usePostMaterialManufacturers(modal.close);
  const handleSubmit = manufacturersActions.usePostManufacturer(noop, manufacturer => {
    postMutation.mutate({
      manufacturers: [(manufacturer as unknown) as ManufacturerListItem],
      materials: [materialId],
    });
  });

  return (
    <div className="mt-3">
      <CreateManufacturerForm close={modal.close} onSubmit={handleSubmit} />
    </div>
  );
};

const AddProviderForm = ({ manufacturers, materialId, modal }: Props) => {
  const columns = useAddProviderColumns();
  const postMutation = materialsActions.usePostMaterialManufacturers(modal.close);

  const initialValues: PostMaterialManufacturer = {
    manufacturers: [],
    materials: [materialId],
  };

  const handleSubmit = (
    values: PostMaterialManufacturer,
    actions: FormikHelpers<PostMaterialManufacturer>,
  ) => {
    postMutation.mutate(values, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, helpers) =>
        handleSubmit(
          {
            ...values,
            manufacturers: values.manufacturers.filter(
              manufacturer => !manufacturers.find(m => m.manufacturer.id === manufacturer.id),
            ),
          },
          helpers,
        )
      }
    >
      {({ handleSubmit, isSubmitting, isValid, values, setFieldValue, setValues }) => {
        return (
          <>
            <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
              <div className="mx-3 mt-3">
                <div className="mb-4">
                  <Search
                    fetcherFn={manufacturersApi.getManufacturersQuery}
                    transformQuery={{ kind: ManufacturerKind.SUPPLIER }}
                    externalSelectedItem={null}
                    isNullable={false}
                    onChange={manufacturer => {
                      if (
                        values.manufacturers.find(
                          addedManufacturer => addedManufacturer.id === manufacturer.id,
                        )
                      )
                        return;
                      setValues({
                        ...values,
                        manufacturers: [...values.manufacturers, manufacturer],
                      });
                    }}
                    textFieldProps={{ placeholder: "Szukaj dostawcy" }}
                  />
                </div>
              </div>
              {Boolean(values.manufacturers.length) && (
                <div className="mx-3">
                  <Table<ManufacturerListItem>
                    overrides={() => ({
                      table: { className: "overflow-visible" },
                      tableInnerWrapper: { className: "overflow-visible" },
                      row(row) {
                        return {
                          className: manufacturers.find(
                            manufacturer => manufacturer.manufacturer.id === row.id,
                          )
                            ? "dashedTableRow"
                            : "",
                        };
                      },
                    })}
                    rows={values.manufacturers}
                    columns={columns}
                    error={null}
                    isLoading={false}
                    uiSchema={{
                      header: {
                        backgroundColor: "neutralWhite88",
                      },
                      cell: {
                        height: "34",
                      },
                    }}
                  />
                </div>
              )}

              <div className="d-flex align-items-center gap-2 p-3">
                <Button
                  className="text-uppercase"
                  onClick={modal.close}
                  size="medium"
                  variant="transparent"
                >
                  Anuluj
                </Button>
                <Button
                  className="text-uppercase"
                  isLoading={isSubmitting}
                  size="medium"
                  type="submit"
                  variant="deepPurple"
                >
                  Dodaj
                </Button>
              </div>
            </form>
          </>
        );
      }}
    </Formik>
  );
};
