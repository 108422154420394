import { MaterialOrderListItem } from "api/manufacturing/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { dateFns } from "utilities";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useOrderListColumns = () => {
  return useCreateTableColumns<MaterialOrderListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.manufacturer.name, {
        header: "dostawca",
        size: 190,
        typographyProps: { fontSize: "14" },
      }),
      columnHelper.text(row => `${row.quantity} ${row.material.unit?.shortName || ""}`, {
        header: "ilość",
        textAlign: "right",
        size: 140,
        typographyProps: { fontSize: "12" },
      }),
      columnHelper.date(row => row.orderedAt, {
        header: "data zam.",
        size: 90,
        typographyProps: { fontSize: "12", fontWeight: "400" },
      }),
      columnHelper.text(row => row.delivery.name, {
        header: "dostarczyć do",
        size: 140,
      }),
      columnHelper.accessor(row => row.receivedAt, {
        header: "dostarczono",
        size: 120,
        cell: info => {
          const receivedAt = info.getValue();

          if (receivedAt) {
            return (
              <Typography fontSize="12" fontWeight="400">
                {dateFns.format(new Date(receivedAt), "dd.MM.yyyy")}
              </Typography>
            );
          }
          return (
            <Typography fontSize="12" fontWeight="600" color="info500">
              Oczekuje
            </Typography>
          );
        },
      }),
    ];
  });
};
