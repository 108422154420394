import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../Wms.module.css";
import {
  WmsAction,
  WorkspaceInventoryHeader as WorkspaceInventoryHeaderType,
  statusDict,
} from "api/wms/models";
import unloadingIcon from "assets/images/forkliftBlue.svg";
import pickingIcon from "assets/images/picking.svg";
import loadingIcon from "assets/images/trackS.svg";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { dateFns } from "utilities";
import { MdiReturn } from "components/miloDesignSystem/atoms/icons/MdiReturn";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";

const wmsActionDict: Record<
  WmsAction,
  {
    color: ColorPalette;
    icon: JSX.Element;
    label: string;
  }
> = {
  LOADING: {
    color: "teal200",
    icon: <img alt="" src={loadingIcon} />,
    label: "Załadunek",
  },
  PICKING: {
    color: "yellow300",
    icon: <img alt="" src={pickingIcon} />,
    label: "Picking",
  },
  RETURN: {
    color: "red300",
    icon: <MdiReturn size="24" color="red300" />,
    label: "Zwrot",
  },
  UNLOADING: {
    color: "blue300",
    icon: <img alt="" src={unloadingIcon} />,
    label: "Rozładunek",
  },
};

export const WorkspaceInventoryHeader = ({
  signature,
  ramp,
  source,
  status,
  joinedAt,
  totalProgress,
  participation,
}: WorkspaceInventoryHeaderType) => {
  return (
    <div>
      <div className={styles.unloadingHeader}>
        <div className={styles.signature}>
          <Typography color="neutralBlack48" fontSize="12" fontWeight="400">
            typ i sygnatura
          </Typography>
        </div>
        <div className={styles.ramp}>
          <Typography color="neutralBlack48" fontSize="12" fontWeight="400">
            rampa
          </Typography>
        </div>
        <div className={styles.progress}>
          <Typography
            className="w-100 text-right"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            rozpoczął
          </Typography>
        </div>
        <div className={styles.progress}>
          <Typography
            className="w-100 text-right"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            postęp ogółem
          </Typography>
        </div>
        <div className={styles.progress}>
          <Typography
            className="w-100 text-right"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            udział w zadaniu
          </Typography>
        </div>
      </div>
      <div className={styles.workspaceHeaderBody}>
        <div className={styles.signature}>
          <Typography fontSize="26" fontWeight="700">
            {signature}
          </Typography>
          <div className="d-flex align-items-center gap-1">
            {wmsActionDict[source].icon}
            <Typography color={wmsActionDict[source].color} fontSize="14" fontWeight="700">
              {wmsActionDict[source].label}
            </Typography>
          </div>
          <Tag label={statusDict[status].label} variant={statusDict[status].variant} />
        </div>
        <div className={styles.ramp}>
          {ramp ? <Tag label={ramp.name} variant="info" /> : <EmptyValue />}
        </div>
        <div className={styles.progress}>
          <Typography fontSize="16" fontWeight="500">
            {joinedAt
              ? dateFns.formatRelative(new Date(joinedAt), "dd.MM.yyyy, H:mm")
              : EMPTY_VALUE}
          </Typography>
        </div>
        <div className={styles.progress}>
          <Typography fontSize="16" fontWeight="700">
            {totalProgress}%
          </Typography>
        </div>
        <div className={styles.progress}>
          <Typography fontSize="16" fontWeight="700">
            {participation}%
          </Typography>
        </div>
      </div>
    </div>
  );
};
