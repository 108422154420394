import { StartingPoint } from "api/other/models";
import { StartingPointDTO } from "api/starting-points/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MdiLocationOn } from "components/miloDesignSystem/atoms/icons/MdiLocationOn";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { TextField } from "components/miloDesignSystem/atoms/textField/TextField";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Formik, FormikHelpers } from "formik";
import { GoogleGeocodeResult, GoogleGeocodeStatus, useGeocoder, useToastr } from "hooks";
import { useState } from "react";
import { cx } from "utilities";
import styles from "../PickupPoints.module.css";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import locationMarker from "assets/images/locationOnMarker.svg";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { CountryCode, POLAND_CENTER } from "CONSTANTS";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { startingPointsActions } from "api/starting-points/actions";
import { handleSubmitResponse } from "utilities/handleSubmitResponse";
import { validationSchema } from "./validationSchema";

interface Props {
  close: () => void;
  startingPoint?: StartingPoint;
}

interface AddressToConfirm {
  city: string;
  country: string;
  street: string;
}

export const AddOrEditStartingPoint = ({ close, startingPoint }: Props) => {
  const [requiresGeolocalization, setRequiresGeolocalization] = useState(
    startingPoint ? false : true,
  );
  const [isLocalizing, setIsLocalizing] = useState(false);
  const [addressToConfirm, setAddressToConfirm] = useState<AddressToConfirm | null>(null);
  const [isAddressCorrect, setIsAddressCorrect] = useState(startingPoint ? true : false);
  const geocoder = useGeocoder();
  const toastr = useToastr();
  const postMutation = startingPointsActions.usePostStartingPoint();
  const patchMutation = startingPointsActions.usePatchStartingPoint();

  const initialValues: StartingPointDTO = {
    city: startingPoint?.city || "",
    countryCode: startingPoint?.countryCode || "",
    name: startingPoint?.name || "",
    point: startingPoint?.point || undefined,
    postCode: startingPoint?.postCode || "",
    street: startingPoint?.street || "",
  };

  const handleSubmit = (values: StartingPointDTO, actions: FormikHelpers<StartingPointDTO>) => {
    if (startingPoint) {
      return patchMutation.mutate(
        {
          id: startingPoint.id,
          toUpdate: {
            city: values.city,
            countryCode: values.countryCode as CountryCode,
            name: values.name,
            point: values.point,
            postCode: values.postCode,
            street: values.street,
          },
        },
        {
          onSuccess: close,
        },
      );
    }
    return postMutation.mutate(
      values,
      handleSubmitResponse(actions, {
        onSuccess: close,
      }),
    );
  };

  const geoCode = (
    address: string,
    setFieldValue: (field: string, value: any) => void,
    setLocalizing: (status: boolean) => void,
  ) => {
    setIsLocalizing(true);
    geocoder.geocode({ address }, (payload: GoogleGeocodeResult[], status: GoogleGeocodeStatus) => {
      setLocalizing(false);
      if (status === "OK") {
        setRequiresGeolocalization(false);
        const location = payload?.[0]?.geometry.location;
        const countryCode = (payload?.[0]?.address_components || [])
          .filter(addressComponent => addressComponent.types.includes("country"))
          .map(addressComponent => addressComponent.short_name)[0];
        const point = { lat: location.lat(), lng: location.lng() };
        setFieldValue("point", point);
        setFieldValue("countryCode", countryCode);
        if (Boolean(payload?.[0].formatted_address.length)) {
          const [street, city, country] = payload[0].formatted_address.split(",");
          setAddressToConfirm({
            city,
            country,
            street,
          });
        }
      } else {
        toastr.open({
          type: "warning",
          title: "Niepoprawna lokalizacja",
          text: "Nie możemy znaleźć podanego adresu.",
        });
      }
    });
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          {startingPoint ? "Edytuj punkt startowy" : "Dodaj punkt startowy"}
        </Typography>
      }
      width={470}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
          <form
            className={cx({ "was-validated": !isValid })}
            onSubmit={handleSubmit}
            onChange={() => {
              setRequiresGeolocalization(true);
              setIsAddressCorrect(false);
              setAddressToConfirm(null);
            }}
          >
            <div className="d-flex flex-column px-3 gap-3">
              <TextField.Form<StartingPointDTO>
                label="Nazwa"
                name="name"
                placeholder="Wpisz nazwę"
              />
              <TextField.Form<StartingPointDTO>
                label="Adres"
                name="street"
                placeholder="Wpisz adres"
              />
              <div className="d-flex align-items-center gap-2 w-100">
                <div className="w-20">
                  <TextField.Form<StartingPointDTO>
                    label="Kod pocztowy"
                    name="postCode"
                    placeholder="Wpisz kod pocztowy"
                  />
                </div>
                <div className="w-100">
                  <TextField.Form<StartingPointDTO>
                    label="Miejscowość"
                    name="city"
                    placeholder="Wpisz miejscowość"
                  />
                </div>
              </div>
              <div>
                <Button
                  className="text-uppercase"
                  disabled={
                    !values.street || !values.city || !values.postCode || !requiresGeolocalization
                  }
                  isLoading={isLocalizing}
                  onClick={() =>
                    geoCode(
                      `${values.street} ${values.postCode} ${values.city}`,
                      setFieldValue,
                      setIsLocalizing,
                    )
                  }
                  size="small"
                  startIcon={MdiLocationOn}
                  variant="deepPurple"
                >
                  Geolokalizuj
                </Button>
              </div>
            </div>
            {addressToConfirm && (
              <div className="d-flex flex-column px-3 gap-2">
                <Typography
                  className="pt-3 text-uppercase"
                  color="neutralBlack48"
                  fontSize="10"
                  fontWeight="700"
                >
                  Znaleziony adres - potwierdź jeśli się zgadza
                </Typography>
                <div className={cx("d-flex py-2 gap-2", styles.addressToConfirm)}>
                  <div className="d-flex align-items-center justify-content-between gap-2 pr-2 pl-3 w-100">
                    <div className="d-flex flex-column">
                      <Typography fontSize="16" fontWeight="600">
                        {values.name || EMPTY_VALUE}
                      </Typography>
                      <Typography fontSize="16" fontWeight="600">
                        {addressToConfirm.street || EMPTY_VALUE}
                      </Typography>
                      <Typography fontSize="16" fontWeight="600">
                        {addressToConfirm.city || EMPTY_VALUE}
                      </Typography>
                      <Typography fontSize="16" fontWeight="600">
                        {addressToConfirm.country || EMPTY_VALUE}
                      </Typography>
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="text-uppercase"
                        endIcon={MdiCheck}
                        onClick={() => setIsAddressCorrect(prev => !prev)}
                        size="small"
                        variant={isAddressCorrect ? "success" : "outline"}
                      >
                        {isAddressCorrect ? "Potwierdzony" : "Potwierdź"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex-1 position-relative w-100 p-3">
              <GoogleMap
                center={POLAND_CENTER}
                mapContainerStyle={{
                  height: "15vh",
                  width: "100%",
                }}
                options={{
                  mapTypeControl: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                  draggableCursor: "default",
                }}
                zoom={6}
              >
                {values.point && <Marker position={values.point} icon={locationMarker} />}
              </GoogleMap>
            </div>
            <div className="d-flex align-items-center gap-3 p-3">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                disabled={!isAddressCorrect || requiresGeolocalization}
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Gotowe
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
