import reportsIcon from "assets/images/reports.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { dashboard } from "./routes/dashboard";
import { sales } from "./routes/sales";
import { products } from "./routes/products";
import { manufacturing } from "./routes/manufacturing";
import { logistics } from "./routes/logistics";
import { warehouse } from "./routes/warehouse";
import favicon from "assets/images/favicons/fv_reports.svg";

export const reports: ModuleNavigation = {
  favicon,
  color: "#01C9D5",
  navigationConfig: {
    label: "Raporty",
    icon: { src: favicon, background: "#00646A" },
  },
  menuDropdownIcon: reportsIcon,
  name: "REPORTS",
  url: "/reports",
  dashboard,
  navigationSections: [sales, products, manufacturing, logistics, warehouse],
};
