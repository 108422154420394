import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import localShipping from "assets/images/moduleNavigationIcons/local_shipping.svg";
import { Dashboard } from "routes/reports/Dashboard";

export const logistics: ModuleLink = {
  url: "logistics",
  label: "Transport",
  icon: localShipping,
  routing: Dashboard,
  isAccessible: false,
};
