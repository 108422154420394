import { patchDelivery, getDelivery, bulkUpdateDeliveries } from "api/deliveries/calls";
import { createAsyncUpdateConnector, getAnyErrorKey } from "utilities";
import { createPrimitiveHook } from "hooks/createPrimitiveHook";
import { useMutation } from "hooks/useMutation";

export const useUpdateDelivery = createAsyncUpdateConnector(patchDelivery);
export const useDelivery = createPrimitiveHook(getDelivery);

export const useBulkUpdateDeliveries = () => {
  return useMutation(bulkUpdateDeliveries, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        text: getAnyErrorKey(error),
        title: "Wymagane działanie",
        type: "warning",
      });
    },
  }));
};
