import { colorPalette } from "../colorsPalette";
import { BaseIcon } from "./types";

export const MdiArrowLongForward = ({ className, color, customColor }: BaseIcon) => (
  <div className="d-flex align-items-center" style={{ height: `24px`, width: `50px` }}>
    <svg
      className={className}
      width={50}
      height={24}
      fill={customColor ? customColor : colorPalette[color || "neutralBlack100"]}
      viewBox="0 0 50 24"
    >
      <path d="M36.0635 19L34.8351 17.6L38.8006 13H7.61523V11H38.8006L34.8351 6.4L36.0635 5L42.098 12L36.0635 19Z" />
    </svg>
  </div>
);
