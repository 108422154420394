import { Address, LoadingLineItem, LoadingStatus } from "api/wms/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { ListAvatar } from "pages/wms/shared/components/ListAvatar";
import { cx, dateFns } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "../../RightPanel.module.css";
import { useDownloadLoadingLineItemPdf } from "../../hooks/useDownloadLoadingLineItemPdf";
import { UserWithInitials } from "api/users/models";
import { countryToFlagDict } from "constants/countriesFlags";

export const useLineItemsColumns = () => {
  const handleDownloadLoadingLineItemPdf = useDownloadLoadingLineItemPdf();

  return useCreateTableColumns<LoadingLineItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.accessor(row => row, {
          header: "sygnatura",
          size: 115,
          cell: info => {
            const lineItem: LoadingLineItem = info.getValue();
            return (
              <Typography
                className={cx({
                  [styles.cancelledSignature]: lineItem.isCancelled,
                })}
                fontSize="12"
                fontWeight="700"
                noWrap
              >
                {Boolean(lineItem.signature) ? `${lineItem.signature}` : EMPTY_VALUE}
              </Typography>
            );
          },
        }),
        columnHelper.text(row => row.sourceExternalId, {
          header: "nr zewnętrzny",
          size: 80,
        }),
        columnHelper.accessor(row => row.status, {
          header: "",
          id: "status",
          size: 50,
          cell: info => {
            const status: LoadingStatus = info.getValue();
            if (status === LoadingStatus.FINISHED) {
              return <Tag startIcon={MdiCheck} label="OK" variant="success" />;
            }
            if (status === LoadingStatus.IN_PROGRESS) {
              return <Tag label="trwa" variant="warning" />;
            }
            return null;
          },
        }),
        columnHelper.text(
          row =>
            row.buyer.firstName || row.buyer.lastName
              ? `${row.buyer.firstName} ${row.buyer.lastName}`
              : null,
          {
            header: "klient",
            size: 105,
          },
        ),
        columnHelper.accessor(row => row.loadingPackagesCounters, {
          header: "towar",
          size: 40,
          cell: info => {
            const loadingPackagesCounters: LoadingLineItem["loadingPackagesCounters"] = info.getValue();
            return (
              <div className="d-flex align-items-center">
                <Typography color="success500" fontSize="14" fontWeight="700">
                  {loadingPackagesCounters.loaded}
                </Typography>
                <Typography color="neutralBlack48" fontSize="14" fontWeight="700">
                  /{loadingPackagesCounters.total}
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row.address, {
          header: "kraj",
          size: 40,
          cell: info => {
            const address: Address = info.getValue();
            return (
              <div className="d-flex align-items-center gap-1">
                {address.countryCode && (
                  <img alt="kraj" src={countryToFlagDict[address.countryCode]} />
                )}
                <Typography fontSize="10" fontWeight="700">
                  {address.countryCode ?? EMPTY_VALUE}
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row.loadedAt, {
          id: "loadedAt",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              załadowano
            </Typography>
          ),
          size: 97,
          cell: info => {
            const loadedAt = info.getValue();
            if (!loadedAt) return <EmptyValue className="text-right w-100" fontWeight="700" />;
            return (
              <Typography className="text-right w-100" fontSize="12" fontWeight="700">
                {dateFns.formatRelative(new Date(loadedAt), "dd.MM.yyyy, H:mm")}
              </Typography>
            );
          },
        }),
        columnHelper.accessor(row => row.collaborators, {
          header: "kto?",
          size: 30,
          cell: info => {
            const collaborators: UserWithInitials[] = info.getValue();
            return <ListAvatar collaborators={collaborators} />;
          },
        }),
        columnHelper.accessor(row => row, {
          header: "",
          id: "labels",
          size: 70,
          cell: info => {
            const lineItem: LoadingLineItem = info.getValue();
            return (
              <div className="d-flex align-items-center gap-1">
                <IconButton
                  icon={<MdiQrCode size="16" />}
                  onClick={event => {
                    event.stopPropagation();
                    handleDownloadLoadingLineItemPdf(
                      lineItem.id,
                      lineItem.loading,
                      lineItem.signature,
                    );
                  }}
                  variant="transparent"
                />
                {lineItem.isCancelled ? (
                  <Button
                    onClick={event => event.stopPropagation()}
                    size="small"
                    variant="transparent"
                  >
                    Przywróć
                  </Button>
                ) : (
                  <Button
                    onClick={event => event.stopPropagation()}
                    size="small"
                    variant="transparent"
                  >
                    Anuluj
                  </Button>
                )}
              </div>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: row => `${row.position}.` },
  );
};
