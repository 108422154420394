import { TradingDocument } from "api/trading-documents/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../../CreateDraftDocument.module.css";
import { CURRENCY_TYPE, currenciesToPick } from "CONSTANTS";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiRadioButtonChecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonChecked";
import { MdiRadioButtonUnchecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonUnchecked";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";

interface Props {
  draftInvoice: TradingDocument;
}

export const CurrencySelector = ({ draftInvoice }: Props) => {
  const isSelected = (currency: string): boolean => draftInvoice.currency === currency;
  const setCurrencyMutation = tradingDocumentsActions.useSetCurrencyForDraftDocument();

  return (
    <div className="d-flex align-items-center gap-2">
      <div className="d-flex align-items-center">
        <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
          Waluta:
        </Typography>
      </div>
      <div className={styles.currencySelector}>
        {currenciesToPick
          .filter(currency => currency.value !== "DKK" && currency.value !== "RON")
          .map(currency => (
            <div
              className={styles.activeTag}
              onClick={() =>
                setCurrencyMutation.mutate({
                  currency: currency.value as CURRENCY_TYPE,
                  tradingDocumentId: draftInvoice.id,
                })
              }
            >
              <Tag
                label={currency.label}
                startIcon={
                  isSelected(currency.value) ? MdiRadioButtonChecked : MdiRadioButtonUnchecked
                }
                type={isSelected(currency.value) ? "filled" : "outlined"}
                variant={isSelected(currency.value) ? "deepPurple50" : "quaternary"}
              />
            </div>
          ))}
      </div>
      {setCurrencyMutation.isLoading && <Spinner size={16} />}
    </div>
  );
};
