import { DeclinedNoteState } from "api/manufacturing/units/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";

interface Props {
  close: () => void;
  state: DeclinedNoteState | null;
}

export const DeclinedNote = ({ close, state }: Props) => {
  if (!state) return null;

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Zgłoszono poprawki do: {state.signature}
        </Typography>
      }
      width={630}
    >
      <div className="px-3">
        <Typography className="py-2" fontSize="14" fontWeight="500">
          {state.note}
        </Typography>
        <div className="py-3">
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
