import requestQuoteIcon from "assets/images/requestQuoteLight.svg";
import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { tradingDocumentsTabDict } from "pages/tradingDocuments/shared/utils/getTabs";
import { SalesInvoices } from "routes/SalesInvoices";

export const salesInvoices: ModuleLink = {
  url: "sales",
  label: "Faktury Sprzedażowe",
  icon: requestQuoteIcon,
  subSection: {
    renderer: <TabSubSection baseUrl="/finances/sales/list" tabs={tradingDocumentsTabDict} />,
    isExpandedByDefault: true,
  },
  routing: SalesInvoices,
};
