import { createListenerMiddleware, TypedStartListening } from "@reduxjs/toolkit";
import { Store } from "ducks";
import { actions as ui } from "ducks/ui";
import { AppDispatch } from "./storeConfig";

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<Store, AppDispatch>;

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const startAppListeners = () => {
  startAppListening({
    predicate: (_action, currentState) => {
      // return true when the listener should run
      if (
        currentState.ui.isAppLoading === true &&
        currentState.auth.isAuthenticated &&
        currentState.partials.pristine === false &&
        currentState.settings.isFetching === false &&
        currentState.settings.settings !== null
      ) {
        return true;
      }
      return false;
    },
    effect: (_state, listenerApi) => {
      listenerApi.dispatch(ui.setAppLoading(false));
    },
  });
};
