import { ManufacturingSchema } from "api/manufacturingNew/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { StateLabel } from "components/common/stateLabel";
import styles from "./ManufacturingSchemasList.module.css";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { MoreDataCounter } from "components/common/moreDataCounter";
import {
  attributeManager,
  AttributeObject,
} from "pages/callCenter/shared/attributesList/AttributesList";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface StageForManager extends AttributeObject {
  textColor?: string;
  backgroundColor?: string;
}

export const useManufacturingSchemasColumns = () => {
  return useCreateTableColumns<ManufacturingSchema>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 130,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.text(row => row.name, {
        header: "nazwa",
        size: 200,
        typographyProps: {
          fontSize: "12",
          fontWeight: "700",
        },
      }),
      columnHelper.text(row => row.description, {
        header: "opis",
        size: 200,
        typographyProps: {
          fontSize: "12",
          fontWeight: "700",
        },
      }),
      columnHelper.accessor(row => row.createdBy, {
        header: "tworzony przez",
        size: 200,
        cell: info => {
          const user = info.getValue();
          if (!user) return <EmptyValue fontSize="14" fontWeight="600" />;
          return (
            <div className="d-flex align-items-center gap-2">
              <AvatarOrInitials
                avatarSrc={user.avatar}
                firstName={user.firstName}
                lastName={user.lastName}
                className={styles.avatar}
              />
              <Typography fontSize="12" fontWeight="700">
                {user.firstName} {user.lastName}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.number(row => row.stages.length, {
        textAlign: "right",
        header: "liczba etapów",
        size: 100,
        typographyProps: {
          fontSize: "12",
          fontWeight: "700",
        },
      }),
      columnHelper.accessor(row => row.stages, {
        header: "etapy",
        size: 350,
        cell: info => {
          let stagesShown: StageForManager[] = [];
          let stagesWrapped: StageForManager[] = [];
          const stages = info.getValue();

          if (stages) {
            const stageAccumulator: StageForManager[] = [];
            stages.forEach(stage => {
              stageAccumulator.push({
                attribute: stage.name,
                value: "",
                color: stage.textColor,
                backgroundColor: stage.backgroundColor,
              });
            });
            [stagesShown, stagesWrapped] = attributeManager(stageAccumulator, 44);
          }

          return (
            <div className="overflow position-relative d-flex align-items-center py-2 pr-2 gap-1">
              {stagesShown.map((stage, index) => {
                return (
                  <StateLabel
                    key={stage.value + index}
                    kind="none"
                    style={{
                      backgroundColor: stage.backgroundColor,
                      color: stage.textColor,
                    }}
                    className="overflow uppercase fw-700 "
                  >
                    {stage.attribute}
                  </StateLabel>
                );
              })}
              {Boolean(stagesWrapped.length) && (
                <MoreDataCounter className="ml-2" counter={stagesWrapped.length} />
              )}
            </div>
          );
        },
      }),
      columnHelper.date(row => row.lastUpdatedAt, {
        header: "ostatnia edycja",
        size: 110,
        typographyProps: {
          fontSize: "12",
          fontWeight: "700",
        },
      }),
    ];
  });
};
