import {
  EMPTY_VALUE,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { NormalizedTradingDocumentItem } from "../salesInvoiceItems/SalesInvoiceItems";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";

export const useItemsColumns = () => {
  return useCreateTableColumns<NormalizedTradingDocumentItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.accessor(row => row, {
          header: "nazwa towaru",
          size: 250,
          cell: info => {
            const item = info.getValue();
            return (
              <ItemDetailsWrapper>
                <>
                  <Typography fontSize="12" fontWeight="700" noWrap>
                    {item.name}
                  </Typography>
                  <div className="d-flex flex-column pt-1">
                    <Typography fontSize="12" fontWeight="700">
                      Korekta
                    </Typography>
                    <Typography fontSize="12" fontWeight="700">
                      Po korekcie
                    </Typography>
                  </div>
                </>
              </ItemDetailsWrapper>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          id: "quantities",
          header: () => <RightAlignedHeader header="liczba" />,
          size: 50,
          cell: info => {
            const item = info.getValue();
            return (
              <ItemDetailsWrapper>
                <>
                  <Typography className="text-right w-100" fontSize="12" fontWeight="700" noWrap>
                    {item.oldQuantity ?? EMPTY_VALUE}
                  </Typography>
                  <div className="d-flex flex-column pt-1 w-100">
                    <Typography className="text-right w-100" fontSize="12" fontWeight="700" noWrap>
                      {item.quantity ?? EMPTY_VALUE}
                    </Typography>
                    <Typography className="text-right w-100" fontSize="12" fontWeight="700" noWrap>
                      {item.newQuantity ?? EMPTY_VALUE}
                    </Typography>
                  </div>
                </>
              </ItemDetailsWrapper>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          header: "jednostka",
          size: 50,
          cell: info => {
            const item = info.getValue();
            return (
              <ItemDetailsWrapper>
                <>
                  <Typography fontSize="12" fontWeight="700">
                    {item.unit}
                  </Typography>
                  <div className="d-flex flex-column pt-1">
                    <Typography fontSize="12" fontWeight="700">
                      {item.unit}
                    </Typography>
                    <Typography fontSize="12" fontWeight="700">
                      {item.unit}
                    </Typography>
                  </div>
                </>
              </ItemDetailsWrapper>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          id: "amountsWithTax",
          header: () => <RightAlignedHeader header="cena brutto" />,
          size: 95,
          cell: info => {
            const item = info.getValue();
            return (
              <ItemDetailsWrapper>
                <>
                  <AmountDisplayWrapper amount={item.oldAmountWithTaxAfterDiscount} />
                  <div className="d-flex flex-column pt-1 w-100">
                    <AmountDisplayWrapper amount={item.amountWithTaxAfterDiscount} />
                    <AmountDisplayWrapper amount={item.newAmountWithTaxAfterDiscount} />
                  </div>
                </>
              </ItemDetailsWrapper>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          id: "vatRates",
          header: () => <RightAlignedHeader header="VAT" />,
          size: 85,
          cell: info => {
            const item = info.getValue();
            return (
              <ItemDetailsWrapper>
                <>
                  <Typography className="text-right w-100" fontSize="12" fontWeight="700">
                    {item.oldVatRate ? `${item.oldVatRate}%` : EMPTY_VALUE}
                  </Typography>
                  <div className="d-flex flex-column pt-1 w-100">
                    <Typography className="text-right w-100" fontSize="12" fontWeight="700">
                      {item.vatRate ? `${item.vatRate}%` : EMPTY_VALUE}
                    </Typography>
                    <Typography className="text-right w-100" fontSize="12" fontWeight="700">
                      {item.newVatRate ? `${item.newVatRate}%` : EMPTY_VALUE}
                    </Typography>
                  </div>
                </>
              </ItemDetailsWrapper>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          id: "totalAmountsWithTax",
          header: () => <RightAlignedHeader header="wartość brutto" />,
          size: 95,
          cell: info => {
            const item = info.getValue();
            return (
              <ItemDetailsWrapper>
                <>
                  <AmountDisplayWrapper amount={item.oldTotalAmountWithTaxAfterDiscount} />
                  <div className="d-flex flex-column pt-1 w-100">
                    <AmountDisplayWrapper amount={item.totalAmountWithTaxAfterDiscount} />
                    <AmountDisplayWrapper amount={item.newTotalAmountWithTaxAfterDiscount} />
                  </div>
                </>
              </ItemDetailsWrapper>
            );
          },
        }),
      ];
    },
    {
      shouldDisplayIndexColumn: row => `${row.displayPosition}.`,
    },
  );
};

const RightAlignedHeader = ({ header }: { header: string }) => {
  return (
    <Typography className="text-right w-100" color="neutralBlack48" fontSize="12" fontWeight="400">
      {header}
    </Typography>
  );
};

const ItemDetailsWrapper = ({ children }: { children: JSX.Element }) => {
  return <div className="d-flex flex-column align-items-start gap-2 w-100">{children}</div>;
};

const AmountDisplayWrapper = ({ amount }: { amount: number }) => {
  return (
    <AmountDisplay
      amount={String(amount) || "0.00"}
      className="w-100 text-right justify-content-end"
      decimalTypographyProps={{
        fontSize: "10",
        fontWeight: "700",
      }}
      integerTypographyProps={{
        fontSize: "12",
        fontWeight: "700",
      }}
    />
  );
};
