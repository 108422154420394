import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { RightPanelHeader } from "components/utils/drawer";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { orderPackageExchangeActions } from "api/order-packages-exchange/actions";
import { OrderPackageExchange } from "api/order-packages-exchange/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { OrderExchangeStatus } from "api/order-packages-exchange/enums";
import { MdiEdit } from "components/miloDesignSystem/atoms/icons/MdiEdit";
import { useNavigate } from "hooks/useNavigate";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { labelsFactory } from "api/labels/calls";
import { queryString } from "utilities";
import { useDrawer } from "hooks/useDrawer";

interface Props {
  orderExchange: OrderPackageExchange;
}

export const PanelHeader = ({ orderExchange }: Props) => {
  const patchOrderExchangeMutation = orderPackageExchangeActions.usePatchOrderExchange();
  const { close } = useDrawer("exchangeOrder");

  const navigate = useNavigate();
  return (
    <RightPanelHeader>
      <div className="d-flex align-items-center gap-2">
        <Button
          className="text-uppercase"
          isLoading={patchOrderExchangeMutation.isLoading}
          onClick={() => {
            const isFinished = orderExchange.status === OrderExchangeStatus.FINISHED;
            patchOrderExchangeMutation.mutate({
              id: orderExchange.id,
              toUpdate: {
                finishedAt: isFinished ? null : new Date().toISOString(),
                status: isFinished ? OrderExchangeStatus.IN_PROGRESS : OrderExchangeStatus.FINISHED,
              },
            });
          }}
          size="small"
          startIcon={MdiCheck}
          variant={orderExchange.status === OrderExchangeStatus.FINISHED ? "success" : "outline"}
        >
          Zrealizowane
        </Button>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
        <FileDownloadHandler
          mode="preview"
          factoryFn={() =>
            labelsFactory.labels(
              queryString.stringify({
                orders: [orderExchange.baseOrderId],
                addExchangedCodes: true,
              }),
              "Wymiana-paczek-",
            )
          }
          type="pdf"
        >
          {({ download, isLoading }) => (
            <Tooltip title="Pobierz etykiety">
              <IconButton
                icon={MdiQrCode}
                isLoading={isLoading}
                variant="transparent"
                onClick={download}
              />
            </Tooltip>
          )}
        </FileDownloadHandler>
        <IconButton
          icon={MdiEdit}
          variant="transparent"
          onClick={() => navigate(`/wms/package-exchange/swap-packages/${orderExchange.id}`)}
        />

        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};
