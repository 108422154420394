import { RightPanelSection } from "components/utils/drawer";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { UUID } from "api/types";
import { useLoading } from "api/wms/hooks";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import { GeneralInfoSection } from "pages/wms/loadingsList/rightPanel/components/GeneralInfoSection";
import { ProgressSection } from "pages/wms/loadingsList/rightPanel/components/ProgressSection";
import { LineItemsSection } from "pages/wms/loadingsList/rightPanel/components/lineItemsSection/LineItemsSection";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Link } from "components/miloDesignSystem/atoms/link";

export const LoadingSection = ({ loadingId }: { loadingId: UUID }) => {
  const { data: loading, error, isLoading } = useLoading(loadingId, {
    enabled: Boolean(loadingId),
  });

  if (error) {
    return (
      <RightPanelSection className="px-3 pt-2 pb-0">
        <div className="d-flex align-items-center flex-1 justify-content-center">
          <CommonError status={error._httpStatus_} />
        </div>
      </RightPanelSection>
    );
  }

  if (isLoading) {
    return (
      <RightPanelSection className="px-3 pt-2 pb-0">
        <div className="d-flex align-items-center flex-1 justify-content-center">
          <Spinner size={34} />
        </div>
      </RightPanelSection>
    );
  }
  assertIsDefined(loading);

  return (
    <div className="mt-3">
      <RightPanelSection className="px-3 pt-2 pb-0">
        <div className="d-flex align-items-center gap-2">
          <Typography fontSize="16" fontWeight="500">
            Załadunek:
          </Typography>

          <Link fontSize="18" fontWeight="700" to={`/wms/loadings/list/all?panelId=${loadingId}`}>
            {loading.signature}
          </Link>
        </div>
      </RightPanelSection>
      <GeneralInfoSection loading={loading} readOnly visibility={{ ramp: false }} />
      <ProgressSection loading={loading} readOnly />
      <LineItemsSection loading={loading} />
    </div>
  );
};
