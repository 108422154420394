import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { ManufacturingOrder, ManufacturingOrderStatus } from "api/manufacturing/models";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { manufacturingConstants } from "constants/manufacturingConstants";
import { manufacturingActions } from "api/manufacturing/actions";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { EmailStatus } from "api/messages/models";

export const useLatestOrdersColumns = () => {
  const postEmailMutation = manufacturingActions.usePostManufacturingEmails();

  return useCreateTableColumns<ManufacturingOrder>(({ columnHelper }) => {
    return [
      columnHelper.link({
        header: "sygnatura",
        size: 110,
        to: row => `/manufacturing/manufacturer-orders/list/all?panelId=${row?.id}`,
        textRenderer: row => row?.signature,
        typographyProps: {
          fontSize: "12",
        },
      }),
      columnHelper.text(row => row.materialsOrdersItems.length, {
        header: "l. pozycji",
        size: 50,
        textAlign: "right",
        typographyProps: {
          color: "neutralBlack88",
        },
      }),
      columnHelper.date(row => row.scheduledEmailSendAt, {
        header: "planowana wysyłka",
        size: 100,
        format: "formatRelative",
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "12",
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row, {
        header: "status zlecenia",
        size: 80,
        cell: info => {
          const row = info.getValue();
          if (row.isCancelled) {
            return <Tag label="Anulowano" variant="warning" type="outlined" />;
          }
          return <Tag {...manufacturingConstants.manufacturingOrderStatusToTag[row.status]} />;
        },
      }),
      columnHelper.accessor(row => row.materialsOrdersItems, {
        header: "realizacja",
        size: 100,
        cell: info => {
          const materialsOrdersItems = info.getValue();
          const areAllReceived = materialsOrdersItems.every(
            material => material.status === ManufacturingOrderStatus.RECEIVED,
          );
          if (areAllReceived) return <Tag label="zrealizowano" variant="success" />;
          return <Tag label="niezrealizowano" variant="warning" />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "status email",
        size: 160,
        cell: info => {
          const order = info.getValue();
          if (!order.emailStatus) return <EmptyValue fontSize="14" fontWeight="600" />;

          return (
            <div className="d-flex align-items-center gap-2">
              <Tag {...manufacturingConstants.emailStatusDict[order.emailStatus.status]} />
              {order.emailStatus.status === EmailStatus.DECLINED && (
                <Button
                  className="text-uppercase"
                  isLoading={postEmailMutation.isLoading}
                  onClick={event => {
                    event.stopPropagation();
                    postEmailMutation.mutate({
                      orders: [order.id],
                    });
                  }}
                  size="small"
                  variant="gray"
                >
                  Wyślij teraz
                </Button>
              )}
            </div>
          );
        },
      }),
    ];
  });
};
