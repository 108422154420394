import { marketplaceStatuses } from "./api";

import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";

const useGetMarketplaceStatusesAttempts = createPaginatedApiQuery(
  marketplaceStatuses.getMarketplaceStatusesAttempts,
);

const useGetMarketplaceStatusesItems = createPaginatedApiQuery(
  marketplaceStatuses.getMarketplaceStatusesItems,
);
const usePostMarketplaceStatusesRetry = () =>
  useMutation(marketplaceStatuses.postMarketplaceStatusesRetry, ({ toastr, queryClient }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

export const marketplaceStatusesActions = {
  useGetMarketplaceStatusesAttempts,
  usePostMarketplaceStatusesRetry,
  useGetMarketplaceStatusesItems,
};
