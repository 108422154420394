import { PageHeader } from "components/common";
import styles from "../StageBoard.module.css";
import { cx, dictToList, queryString } from "utilities";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useQuery, useRedux, useSelector } from "hooks";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiBoard } from "components/miloDesignSystem/atoms/icons/MdiBoard";
import { MdiTable } from "components/miloDesignSystem/atoms/icons/MdiTable";
import { useManufacturingStage } from "api/manufacturingNew/hooks";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { MdiKingBed } from "components/miloDesignSystem/atoms/icons/MdiKingBed";
import { MdiArrowRange } from "components/miloDesignSystem/atoms/icons/MdiArrowRange";
import { MdiFabric } from "components/miloDesignSystem/atoms/icons/MdiFabric";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { CommonError } from "components/utils";
import { BoardFormat } from "api/manufacturing/schemas/models";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import { manufacturingActions } from "api/manufacturing/actions";
import { productionOrders } from "components/common/moduleNavigation/moduleConfig/manufacturing/routes/productionOrders";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { useNavigate } from "hooks/useNavigate";
import { MdiSide } from "components/miloDesignSystem/atoms/icons/MdiSide";
import { useStageId } from "../../hooks/useStageId";

interface Props {
  isListOfAllUnits?: boolean;
}

export const Header = ({ isListOfAllUnits }: Props) => {
  const activeViewType = useSelector(
    store => store.ui.viewTypes.manufacturingStageBoard ?? "board",
  );
  const stages = useSelector(store => store.partials.stages);
  const stagesTabDict = stages.reduce(
    (acc, stage) => {
      return {
        ...acc,
        [stage.id]: stage.name,
      };
    },
    { all: "Wszystkie" },
  );
  const { query, setQuery } = useQuery();
  const [dispatch, { ui }] = useRedux();
  const navigate = useNavigate();
  const stageId = useStageId();
  const {
    data: manufacturingStage,
    error: stageError,
    isLoading: stageIsLoading,
  } = useManufacturingStage(stageId, {
    enabled: Boolean(stageId && stageId !== "all"),
  });
  const {
    data: manufacturingItemCount,
    error: countError,
    isLoading: countIsLoading,
  } = manufacturingUnitsActions.useManufacturingItemCount(
    {
      stageId,
      search: queryString.stringify({
        finishedAtFrom: query.finishedAtFrom,
        finishedAtTo: query.finishedAtTo,
      }),
    },
    {
      enabled: Boolean(stageId) && Boolean(query.finishedAtFrom && query.finishedTo),
    },
  );
  const { data: defaultFilters } = manufacturingActions.useStageBoardDefaultAttributesKind(
    queryString.stringify({
      schemaStage: stageId === "all" ? "" : stageId,
    }),
  );

  return (
    <PageHeader
      bottomBarTitle={
        activeViewType === "board" && !isListOfAllUnits ? (
          <div
            className={cx(
              styles.subtoolbar,
              "d-flex align-items-center justify-content-between w-100",
            )}
          >
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="16" fontWeight="600">
                Pula zleceń:
              </Typography>
              {countIsLoading && <Spinner size={16} />}
              {countError && <CommonError status={countError._httpStatus_} />}
              {manufacturingItemCount && (
                <Typography color="deepPurple400" fontSize="18" fontWeight="700">
                  {manufacturingItemCount.counts.notStarted +
                    manufacturingItemCount.counts.inProgress}
                </Typography>
              )}
            </div>
            <div className="d-flex align-items-center gap-2">
              <Button
                className="text-uppercase"
                endIcon={MdiArrowForward}
                onClick={() => navigate(`/manufacturing/production-orders/demands/${stageId}`)}
                size="small"
                variant="gray"
              >
                Pokaż potrzebne materiały
              </Button>
              {activeViewType === "board" && manufacturingStage?.boardFormat === BoardFormat.GROUP && (
                <div className="d-flex align-items-center gap-2">
                  <span className="line-divider" style={{ height: 26 }} />
                  <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
                    Grupuj:
                  </Typography>
                  <div className="d-flex align-items-center gap-1">
                    <div className="cursor-not-allowed d-flex align-items-center">
                      <Tag
                        label="Model"
                        startIcon={<MdiKingBed size="14" />}
                        type={
                          manufacturingStage?.boardFormat === BoardFormat.GROUP
                            ? "filled"
                            : "outlined"
                        }
                        variant={
                          manufacturingStage?.boardFormat === BoardFormat.GROUP
                            ? "deepPurple400"
                            : "quaternary"
                        }
                      />
                    </div>
                    <Tag
                      label="Rozmiar"
                      startIcon={<MdiArrowRange size="14" />}
                      type={
                        manufacturingStagesUtils.hasSize(defaultFilters!) ? "filled" : "outlined"
                      }
                      variant={
                        manufacturingStagesUtils.hasSize(defaultFilters!)
                          ? "deepPurple400"
                          : "quaternary"
                      }
                    />
                    <Tag
                      label="materiały"
                      startIcon={<MdiFabric size="14" />}
                      type={
                        manufacturingStagesUtils.hasFabric(defaultFilters!) ? "filled" : "outlined"
                      }
                      variant={
                        manufacturingStagesUtils.hasFabric(defaultFilters!)
                          ? "deepPurple400"
                          : "quaternary"
                      }
                    />
                    <Tag
                      label="strona"
                      startIcon={<MdiSide size="14" />}
                      type={
                        manufacturingStagesUtils.hasSide(defaultFilters!) ? "filled" : "outlined"
                      }
                      variant={
                        manufacturingStagesUtils.hasSide(defaultFilters!)
                          ? "deepPurple400"
                          : "quaternary"
                      }
                    />
                  </div>
                </div>
              )}
              <span className="line-divider" style={{ height: 26 }} />
              <div className="d-flex align-items-center gap-1 pt-1">
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(ui.toggleViewType({ type: "manufacturingStageBoard", value: "board" }))
                  }
                >
                  <Tag
                    label=""
                    startIcon={<MdiBoard size="16" />}
                    type={activeViewType === "board" ? "filled" : "outlined"}
                    variant={activeViewType === "board" ? "deepPurple400" : "quaternary"}
                  />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(ui.toggleViewType({ type: "manufacturingStageBoard", value: "list" }));
                    setQuery({
                      stageStatus: "READY",
                    });
                  }}
                >
                  <Tag
                    label=""
                    startIcon={<MdiTable size="16" />}
                    type="outlined"
                    variant="quaternary"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          undefined
        )
      }
      bottomBarButtons={
        activeViewType === "list" && !isListOfAllUnits ? (
          <div className="d-flex align-items-center gap-2">
            <Button
              className="text-uppercase"
              endIcon={MdiArrowForward}
              onClick={() => navigate(`/manufacturing/production-orders/demands/${stageId}`)}
              size="small"
              variant="gray"
            >
              Pokaż potrzebne materiały
            </Button>
            <span className="line-divider" style={{ height: 26 }} />
            <div className="d-flex align-items-center gap-1 pt-1">
              <div
                className="cursor-pointer"
                onClick={() =>
                  dispatch(ui.toggleViewType({ type: "manufacturingStageBoard", value: "board" }))
                }
              >
                <Tag
                  label=""
                  startIcon={<MdiBoard size="16" />}
                  type="outlined"
                  variant="quaternary"
                />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  dispatch(ui.toggleViewType({ type: "manufacturingStageBoard", value: "list" }));
                  setQuery({
                    stageStatus: "READY",
                  });
                }}
              >
                <Tag
                  label=""
                  startIcon={<MdiTable size="16" />}
                  type="filled"
                  variant="deepPurple400"
                />
              </div>
            </div>
          </div>
        ) : (
          undefined
        )
      }
      searchInput={
        isListOfAllUnits || activeViewType === "list"
          ? {
              label: "Szukaj wśród zleceń produkcyjnych",
              tags: [],
            }
          : undefined
      }
      searchBar={isListOfAllUnits || activeViewType === "list"}
      tabs={{
        list: dictToList(stagesTabDict).map(({ key, value }) => ({
          name: key,
          label: value,
          path:
            key === "all"
              ? `/manufacturing/production-orders/all`
              : `/manufacturing/production-orders/stage-orders/${key}`,
          exactPath: true,
        })),
        routesRoot: `manufacturing/${productionOrders.url}`,
      }}
      title={
        <div>
          {!isListOfAllUnits && manufacturingStage && <div>{manufacturingStage.name}</div>}
          {!isListOfAllUnits && stageIsLoading && <Spinner size={20} />}
          {!isListOfAllUnits && stageError && <CommonError status={stageError._httpStatus_} />}
        </div>
      }
      viewLabel="MANUFACTURING_STAGE_BOARD"
    />
  );
};
