import styles from "../moduleNavigation/ModuleNavigation.module.css";
import { useQuery } from "hooks";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiCleaningService } from "components/miloDesignSystem/atoms/icons/MdiCleaningService";
import { FilterList } from "./filterList/FilterList";
import { useFilterContext } from "components/utils/withFilters";
import { ClickOutsideHandler } from "components/utils";
import { cx } from "utilities";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";

export const outsideClickIgnoreClassFilterDrawer = "outsideClickIgnoreClassFilterDrawer";

export const FilterDrawerRenderer = () => {
  const { setQuery } = useQuery();
  const filterContext = useFilterContext();

  if (!filterContext?.filters.length || !filterContext?.isFilterDrawerOpen) return null;

  return (
    <ClickOutsideHandler
      onClickOutside={() => {
        filterContext.closeFilterDrawer();
      }}
      outsideClickIgnoreClass={outsideClickIgnoreClassFilterDrawer}
    >
      <div
        className={cx(
          styles.filterDrawer,
          "d-flex flex-1 flex-column",
          outsideClickIgnoreClassFilterDrawer,
        )}
      >
        <div className={styles.filtersBodyTitle}>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <Typography color="neutralWhite100" fontSize="14" fontWeight="700">
              Filtry
            </Typography>
            <IconButton
              icon={MdiClose}
              variant="transparent"
              theme="dark"
              onClick={filterContext.closeFilterDrawer}
            />
          </div>
          <div className="d-flex justify-content-end">
            <Button
              className="text-uppercase"
              onClick={() => setQuery({})}
              size="small"
              startIcon={MdiCleaningService}
              theme="dark"
              variant="gray"
            >
              Wyczyść filtry
            </Button>
          </div>
        </div>

        <FilterList filters={filterContext.filters} />
      </div>
    </ClickOutsideHandler>
  );
};
