import { filterFactory } from "components/utils/withFilters";
import { createDateRanges } from "utilities";
import { getLastFiveDaysForFilters } from "utilities/getLastFiveDaysForFilters";

const dateRanges = createDateRanges();

export const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Select,
    label: "sortuj",
    name: "ordering",
    options: [
      { label: "Od ostatnio edytowanych", value: "-lastModified" },
      { label: "Od najnowszych", value: "-created" },
    ],
  },
  {
    type: FilterType.Date,
    label: "utworzono",
    //@ts-ignore
    name: "created",
    options: getLastFiveDaysForFilters(),
  },
  {
    type: FilterType.Date,
    label: "data wyjazdu",
    //@ts-ignore
    name: "departureDate",
    options: getLastFiveDaysForFilters(),
  },
  {
    type: FilterType.DateRangeWithOptions,
    label: "zakres dat utworzenia",
    name: ["minCreatedDate", "maxCreatedDate"],
    options: [
      { label: "Ten miesiąc", value: dateRanges.thisMonth },
      { label: "Ostatni miesiąc", value: dateRanges.lastMonth },
      { label: "Ostatni pełny miesiąc", value: dateRanges.lastFullMonth },
      { label: "Ostatnie 6 miesięcy", value: dateRanges.lastSixMonths },
      { label: "Ostatnie 6 pełnych miesięcy", value: dateRanges.lastSixFullMonths },
    ],
  },
  {
    type: FilterType.SelectUser,
    label: "kierowca",
    multiple: true,
    //@ts-ignore
    name: "drivers",
    kind: "user",
    searchBy: "drivers",
  },
]);
