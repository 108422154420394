import {
  getManufacturingPlan,
  getManufacturingPlans,
  getManufacturingSchema,
  getManufacturingSchemas,
  getManufacturingStage,
  getManufacturingStages,
  patchManufacturingPlanOnList,
} from "./calls";

import { useQueryUtils } from "hooks";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { useMutation } from "hooks/useMutation";
import { PartialOf } from "typeUtilities";
import { parsePatchData } from "utilities/parsePatchData";
import { manufacturingNewKeys } from "./keys";
import { ManufacturingPlan, ManufacturingPlanListItem } from "./models";
import { getSchemaStages } from "api/new-production-plans/calls";

export const useManufacturingSchemas = createPaginatedApiQuery(getManufacturingSchemas);

export const useManufacturingSchema = createApiQuery(getManufacturingSchema);

export const useManufacturingStages = createPaginatedApiQuery(getManufacturingStages);

export const useManufacturingStage = createApiQuery(getManufacturingStage);

export const useManufacturingPlans = createPaginatedApiQuery(getManufacturingPlans);

export const useManufacturingPlan = createApiQuery(getManufacturingPlan);

export const useSchemaStages = createPaginatedApiQuery(getSchemaStages);

export const useManufacturingPlanOnListPatchMutation = (parseData: boolean = false) => {
  const { rollback, handlePaginatedListUpdate, rollbackList, handleMutate } = useQueryUtils();

  return useMutation(
    ({ id, toUpdate }: { id: string; toUpdate: PartialOf<ManufacturingPlanListItem> }) => {
      if (parseData) {
        return patchManufacturingPlanOnList(parsePatchData(toUpdate), id);
      }
      return patchManufacturingPlanOnList(toUpdate, id);
    },
    {
      onMutate: ({ id, toUpdate }) => {
        const prevList = handlePaginatedListUpdate(
          manufacturingNewKeys.manufacturingPlans(),
          id,
          toUpdate,
        );
        const prevPanel = handleMutate(manufacturingNewKeys.manufacturingPlan(id), toUpdate);
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        const { prevList } = onMutateReturn as { prevList: any };
        rollback(manufacturingNewKeys.manufacturingPlan(String(id)), error);
        rollbackList(manufacturingNewKeys.manufacturingPlans(), prevList, id);
      },
    },
  );
};

export const useManufacturingPlanDetailsPatchMutation = (
  setShowLoader?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { handleMutate, rollback } = useQueryUtils();

  return useMutation(
    ({ id, toUpdate }: { id: string | number; toUpdate: PartialOf<ManufacturingPlan> }) =>
      patchManufacturingPlanOnList(parsePatchData(toUpdate), String(id)),
    {
      onMutate: ({ id, toUpdate }) => {
        if (setShowLoader) {
          setShowLoader(true);
        }
        const prevDetails = handleMutate(
          manufacturingNewKeys.manufacturingPlan(String(id)),
          toUpdate,
        );
        return { prevDetails };
      },
      onError: (error, { id }, onMutateReturn) => {
        const { prevDetails } = onMutateReturn as { prevDetails: ManufacturingPlan };
        rollback(manufacturingNewKeys.manufacturingPlan(String(id)), prevDetails, error);
      },
    },
  );
};
