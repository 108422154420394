import { MdiPayments } from "components/miloDesignSystem/atoms/icons/MdiPayments";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { MdiShoppingCart } from "components/miloDesignSystem/atoms/icons/MdiShoppingCart";
import { MdiSync } from "components/miloDesignSystem/atoms/icons/MdiSync";
import { TreeMenuProps } from "components/miloDesignSystem/molecules/treeMenu/types";
import { DefaultTransactionDetails } from "./paymentsSection/defaultTransactionDetails/DefaultTransactionDetails";
import { Invoicing } from "./paymentsSection/Invoicing";
import { Permissions } from "./ordersSection/Permissions";
import { Marketplace } from "./ordersSection/Marketplace";
import { ProductSets } from "./ordersSection/ProductSets";
import { OtherOrdersSettings } from "./ordersSection/OtherOrdersSettings";
import { PriceLists } from "./ordersSection/PriceLists";
import { OrderLabel } from "./labelsSection/OrderLabel";
import { DeliveryAddresses } from "./ordersSection/deliveryAddresses/DeliveryAddresses";
import { MiloIntegration } from "./integrationsSections/miloIntegration/MiloIntegration";

export const configurationSections: TreeMenuProps["sections"] = [
  {
    content: null,
    icon: MdiPayments,
    id: "payments",
    menuLabel: "Płatności",
    subsections: [
      {
        content: <DefaultTransactionDetails />,
        id: "defaultTransactionDetails",
        menuLabel: "Domyślne dane transakcyjne",
      },
      {
        content: <Invoicing />,
        id: "invoiceIssue",
        menuLabel: "Wystawianie faktur",
      },
    ],
  },
  {
    content: null,
    id: "orders",
    icon: MdiShoppingCart,
    menuLabel: "Zamówienia",
    subsections: [
      // TODO: Move to sales accounts
      // {
      //   content: <div></div>,
      //   id: "ordersProcessingRules",
      //   label: "Reguły przetwarzania zamówień",
      // },
      {
        content: <Permissions />,
        id: "permissions",
        menuLabel: "Uprawnienia",
      },
      {
        content: <Marketplace />,
        id: "marketplaces",
        menuLabel: "Marketplace",
      },
      {
        content: <ProductSets />,
        id: "productSet",
        menuLabel: "Zestaw produktów",
      },
      {
        content: <PriceLists />,
        id: "priceList",
        menuLabel: "Cennik",
      },
      {
        content: <DeliveryAddresses />,
        id: "deliveryAddresses",
        menuLabel: "Adresy dostawy",
      },
      {
        content: <OtherOrdersSettings />,
        id: "additionalOrderSettings",
        menuLabel: "Dodatkowe",
      },
    ],
  },
  {
    content: null,
    id: "integrations",
    icon: MdiSync,
    menuLabel: "Integracje",
    subsections: [
      {
        content: <MiloIntegration />,
        id: "miloIntegration",
        menuLabel: "Łączenie aplikacji z klientem",
      },
      // TODO: Move to salesAccounts
      // {
      //   content: <div></div>,
      //   id: "baselinkerSettings",
      //   label: "Baselinker",
      // },
    ],
  },
  {
    content: null,
    id: "labels",
    icon: MdiQrCode,
    menuLabel: "Etykiety",
    subsections: [
      {
        content: <OrderLabel />,
        id: "orderLabel",
        menuLabel: "Etykieta zamówienia",
      },
    ],
  },
];
