import { DOMAIN } from "ENDPOINTS";
import { wmsLayoutActions } from "api/wms/layout/actions";
import { wmsLayoutConstants } from "api/wms/layout/constants";
import { FieldDetails, FieldKind } from "api/wms/layout/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelHeader } from "components/utils/drawer";

interface Props {
  close: () => void;
  field: FieldDetails;
}

export const PanelHeader = ({ close, field }: Props) => {
  const handleDownloadRackPdf = wmsLayoutActions.useDownloadWarehouseSchemaRackPdf();

  return (
    <RightPanelHeader>
      <div className="d-flex align-items-center gap-3">
        <Typography fontSize="18" fontWeight="700">
          {wmsLayoutConstants.fieldKindDict[field.wmsKind]} {field.name}
        </Typography>
        <div className="d-flex align-items-center gap-1">
          <Typography fontSize="14" fontWeight="600">
            Strefa:
          </Typography>
          <Typography fontSize="14" fontWeight="700">
            {field.area && Boolean(field.area.name) ? `${field.area.name}` : "---"}
          </Typography>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end flex-1">
        {field.wmsKind === FieldKind.RACK ? (
          <Tooltip title="Pobierz etykietę">
            <IconButton
              icon={MdiQrCode}
              onClick={() => handleDownloadRackPdf(field.wmsId, field.name)}
              variant="transparent"
            />
          </Tooltip>
        ) : (
          <Tooltip
            title={`Podgląd etykiety dla ${
              field.wmsKind === FieldKind.FIELD
                ? "miejsca"
                : field.wmsKind === FieldKind.COLUMN
                ? "kolumny"
                : "rampy"
            }`}
          >
            <IconButton
              disabled={!field.wmsId}
              icon={MdiQrCode}
              onClick={() => {
                if (field.wmsKind === FieldKind.FIELD)
                  window.open(`${DOMAIN}wh-cells/labels/${field.wmsId}`, "_blank");
                if (field.wmsKind === FieldKind.RAMP)
                  window.open(`${DOMAIN}wms/ramps/get-labels/${field.wmsId}`, "_blank");
                if (field.wmsKind === FieldKind.COLUMN)
                  window.open(
                    `${DOMAIN}wh-columns/download-cells-labels?whColumns=${field.wmsId}`,
                    "_blank",
                  );
              }}
              variant="transparent"
            />
          </Tooltip>
        )}
        <span className="divider line-divider" />
        <Tooltip title="Zamknij panel">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};
