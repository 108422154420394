import { ManufacturingUnitListViewDetails } from "api/manufacturing/units/models";
import { orderActions } from "api/orders/actions";
import { CreatePickingOrLoadingSource } from "api/orders/models";
import { InfoLabel } from "components/common/infoLabel";
import { Link } from "components/miloDesignSystem/atoms/link/Link";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { ErrorMessage } from "components/utils";
import { getAnyErrorKey, queryString } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  unitDetails: ManufacturingUnitListViewDetails;
}

export const CollectionLink = ({ unitDetails }: Props) => {
  const { data: ordersCollectionAssignments, error, isLoading } = orderActions.useOrdersGroupByInfo(
    queryString.stringify({
      ids: [unitDetails.manufacturingItem.orderId],
    }),
    {
      enabled: Boolean(unitDetails.manufacturingItem && unitDetails.manufacturingItem.orderId),
    },
  );

  if (isLoading)
    return (
      <InfoLabel title="trasa/grupa">
        <Spinner size={16} />
      </InfoLabel>
    );

  if (error)
    return (
      <InfoLabel title="trasa/grupa">
        <Typography className="pb-1" color="danger600" fontSize="14" fontWeight="500" noWrap>
          <ErrorMessage text={getAnyErrorKey(error)} type="text" />
        </Typography>
      </InfoLabel>
    );

  const searchedUnit = ordersCollectionAssignments?.find(
    assignment => assignment.id === unitDetails.manufacturingItem.orderId,
  );

  if (!searchedUnit || !searchedUnit.group)
    return (
      <InfoLabel title="trasa/grupa">
        <EmptyValue fontSize="14" fontWeight="500" />
      </InfoLabel>
    );

  return (
    <InfoLabel title="trasa/grupa">
      <Link
        fontSize="14"
        fontWeight="600"
        to={
          searchedUnit.group.type === CreatePickingOrLoadingSource.ROUTE
            ? `/routes/list/active?panelId=${searchedUnit.group.id}`
            : `/order-groups/list/orders?panelId=${searchedUnit.group.id}`
        }
      >
        {searchedUnit.group.signature}
      </Link>
    </InfoLabel>
  );
};
