import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import packageIcon from "assets/images/package_2.svg";
import { SoldPackagesRouting } from "routes/finances/SoldPackages";

export const soldPackages: ModuleLink = {
  url: "sold-packages",
  label: "Sprzedane paczki",
  icon: packageIcon,
  routing: SoldPackagesRouting,
};
