import { customerActions } from "api/customers/actions";
import { useParams } from "react-router";
import { DefaultTransactionContent } from "./DefaultTransactionContent";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";

export const DefaultTransactionDetails = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = customerActions.useCustomer(Number(customerId));
  assertIsDefined(customer);

  if (!customer.businessEntity)
    return (
      <div className="d-flex flex-column gap-3 px-1 py-2">
        <Typography
          className="text-uppercase"
          color="neutralBlack48"
          fontSize="10"
          fontWeight="700"
        >
          Domyślne dane transakcyjne
        </Typography>
      </div>
    );

  return <DefaultTransactionContent customer={customer} />;
};
