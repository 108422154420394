import { CarrierOrder } from "api/logistics/carrierOrders/models";
import { InfoLabel } from "components/common/infoLabel";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { dateFns } from "utilities";
import { paymentMethodDict } from "../../hooks/useCarrierOrdersListColumns";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  carrierOrder: CarrierOrder;
}

export const GeneralInfoSection = ({ carrierOrder }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection>
      <div className="d-flex align-items-center mb-2" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {carrierOrder.route.signature}
        </Typography>
      </div>
      <InfoLabel title="przewoźnik">
        <div>
          <Typography fontSize="14" fontWeight="700">
            {carrierOrder.carrier.companyName}
          </Typography>
          <div className="d-flex align-items-center gap-1">
            <Typography fontSize="14" fontWeight="600">
              {carrierOrder.carrier.street},
            </Typography>
            <Typography fontSize="14" fontWeight="600">
              {carrierOrder.carrier.postCode}
            </Typography>
            <Typography fontSize="14" fontWeight="600">
              {carrierOrder.carrier.city}
            </Typography>
          </div>
        </div>
      </InfoLabel>
      <InfoLabel title="data zlecenia">
        <Typography fontSize="14" fontWeight="400">
          {dateFns.format(new Date(carrierOrder.createdAt), "dd.MM.yyyy, H:mm")}
        </Typography>
      </InfoLabel>
      <InfoLabel title="zlecono przez">
        <>
          <Avatar size="sm" user={carrierOrder.createdBy} theme="light" />
          <Typography className="overflow" fontSize="12" fontWeight="700">
            {carrierOrder.createdBy.firstName} {carrierOrder.createdBy.lastName}
          </Typography>
        </>
      </InfoLabel>
      <InfoLabel title="nr rejestracyjny">
        <Typography fontSize="14" fontWeight="700">
          {carrierOrder.car.registrationNumber}
        </Typography>
      </InfoLabel>
      <InfoLabel title="kierowcy">
        {Boolean(carrierOrder.drivers.length) ? (
          carrierOrder.drivers.map(driver => (
            <Typography fontSize="14" fontWeight="600" key={driver.id}>
              {driver.firstName} {driver.lastName}
            </Typography>
          ))
        ) : (
          <EmptyValue fontSize="14" fontWeight="700" />
        )}
      </InfoLabel>
      <InfoLabel title="data załadunku">
        <Typography fontSize="14" fontWeight="700">
          ---
        </Typography>
      </InfoLabel>
      <InfoLabel title="data wyjazdu">
        <Typography fontSize="14" fontWeight="700">
          ---
        </Typography>
      </InfoLabel>
      <InfoLabel title="sposób zapłaty">
        {Boolean(carrierOrder.paymentMethod) ? (
          <Tag
            label={paymentMethodDict[carrierOrder.paymentMethod].label}
            variant={paymentMethodDict[carrierOrder.paymentMethod].variant}
          />
        ) : (
          <EmptyValue fontSize="14" fontWeight="700" />
        )}
      </InfoLabel>
    </RightPanelSection>
  );
};
