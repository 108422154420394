import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiHalfProduct } from "components/miloDesignSystem/atoms/icons/MdiHalfProduct";
import { SemiFinishedMaterialsRouting } from "routes/manufacturingNew/SemiFinishedMaterialsRouting";

export const semiFinishedMaterials: ModuleLink = {
  url: "semi-finished-materials",
  label: "Półprodukty - zapotrzebowanie i stan",
  icon: MdiHalfProduct,
  routing: SemiFinishedMaterialsRouting,
};
