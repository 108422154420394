export enum StandardAttributeKind {
  NUMBER = "NUMBER",
  PICTURE = "PICTURE",
  SIDE = "SIDE",
  SIZE = "SIZE",
  TEXT = "TEXT",
}

export enum FabricAttributeKind {
  FABRIC = "FABRIC",
}

export interface AttributeStandard {
  id: number;
  name: string;
  description: string;
  kind: StandardAttributeKind;
  position: number;
  values: Value[];
}

export interface AttributeFabric {
  id: number;
  name: string;
  description: string;
  position: number;
  kind: FabricAttributeKind;
  categories: {
    id: number;
    name: string;
    values: Value[];
  }[];
}
export type Attribute = AttributeStandard | AttributeFabric;

export interface Value {
  id: number;
  name: string;
  isAssignableToIndex: boolean;
  position: number;
  attribute: number;
  picture: null | string;
}
