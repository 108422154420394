import { ZadbanoDefaults, ZadbanoDetails } from "./models";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { zadbanoKeys } from "./keys";
import { PartialOf } from "typeUtilities";
import { Courier, Shipment } from "../models";
import { ShippingService } from "constants/shippingServiceConstants";
import { Assign } from "utility-types";

const getZadbanoDefaults = (id: Courier["id"]): ApiFetcher<ZadbanoDefaults> => ({
  key: zadbanoKeys.defaults(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/shipping/shipping-services-provider/${ShippingService.ZADBANO}/${id}`,
    }),
});

const patchZadbanoDefaults = (data: { toUpdate: PartialOf<ZadbanoDefaults>; id: Courier["id"] }) =>
  queryFetch<Courier>({
    method: "PATCH",
    url: `/shipping/shipping-services-provider/${ShippingService.ZADBANO}/${data.id}`,
    data: data.toUpdate,
  });

const getZadbanoDetails = (id: Shipment["id"]): ApiFetcher<ZadbanoDetails> => ({
  key: zadbanoKeys.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/shipping/shipments-provider/${ShippingService.ZADBANO}?shipment=${id}`,
    }),
});

const patchZadbanoDetails = (
  data: Assign<PartialOf<ZadbanoDetails>, { shipments: Shipment["id"][] }>,
) =>
  queryFetch<Courier>({
    method: "PATCH",
    url: `/shipping/shipments-provider/${ShippingService.ZADBANO}`,
    data,
  });

export const zadbanoApi = {
  patchZadbanoDefaults,
  patchZadbanoDetails,
  getZadbanoDefaults,
  getZadbanoDetails,
};
