import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import drawCollage from "assets/images/moduleNavigationIcons/draw_collage.svg";
import { Dashboard } from "routes/inventory/Dashboard";

export const attributes: ModuleLink = {
  url: "attributes",
  label: "Atrybuty",
  icon: drawCollage,
  routing: Dashboard,
  isAccessible: false,
};
