import bankIcon from "assets/images/accountBalance.svg";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { BankAccounts } from "routes/BankAccounts";

export const bankAccounts: ModuleLink = {
  url: "bank-accounts",
  label: "Konta bankowe",
  icon: bankIcon,
  routing: BankAccounts,
};
