import {
  CorrespondingDocument as CorrespondingDocumentI,
  TradingDocument,
} from "api/trading-documents/models";
import { RightPanelSection } from "components/utils/drawer";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useCorrespondingDocumentColumns } from "./useCorrespondingDocumentColumns";

interface Props {
  tradingDocument: TradingDocument;
}

export const CorrespondingDocumentSection = ({ tradingDocument }: Props) => {
  const columns = useCorrespondingDocumentColumns();
  const tableProps = useTableFeatureConnector({
    rows: tradingDocument.correspondingDocuments,
  });

  return (
    <RightPanelSection title="Dokumenty powiązane">
      <div className="pb-3">
        <Table<CorrespondingDocumentI>
          columns={columns}
          error={null}
          isLoading={false}
          uiSchema={{
            header: {
              backgroundColor: "neutralWhite100",
            },
            cell: {
              height: "25",
            },
          }}
          {...tableProps}
        />
      </div>
    </RightPanelSection>
  );
};
