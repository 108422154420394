import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery, useStateModal } from "hooks";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { CreateCorrectionManuallyModal } from "./createCorrectionManuallyModal/CreateCorrectionManuallyModal";
import {
  BulkSalesInvoiceConfirmPreview,
  CreateCorrectionModalState,
} from "api/trading-documents/models";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { ReplyModal } from "pages/tradingDocuments/shared/components/actionToolbar/ReplyModal";
import { MdiDownloadXml } from "components/miloDesignSystem/atoms/icons/MdiDownloadXml";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { DownloadRevisor } from "pages/tradingDocuments/shared/components/DownloadRevisor";
import { DownloadTradingDocumentXml } from "pages/tradingDocuments/shared/components/downloadTradingDocumentXml/DownloadTradingDocumentXml";
import { DownloadFakir } from "pages/tradingDocuments/shared/components/DownloadFakir";
import { useDrawer } from "hooks/useDrawer";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { DownloadTradingDocumentPdf } from "pages/tradingDocuments/shared/components/downloadTradingDocumentPdf/DownloadTradingDocumentPdf";
import { DownloadWdtPdf } from "pages/tradingDocuments/shared/components/DownloadWdfPdf";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { MdiViewPdf } from "components/miloDesignSystem/atoms/icons/MdiViewPdf";
import { DOMAIN } from "ENDPOINTS";

export const PanelHeader = () => {
  const { close } = useDrawer("salesInvoices");
  const { query } = useQuery();
  const { panelId } = query;
  const { data: tradingDocument } = useTradingDocument({ id: panelId });
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();
  const createCorrectionManuallyModal = useStateModal<CreateCorrectionModalState | null>();
  const previewCorrectionMutation = tradingDocumentsActions.useCorrectionPreview(
    createCorrectionManuallyModal.open,
  );
  const { isTriggerElementHidden } = useScrollableContext();

  if (!tradingDocument) return null;

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2">
            <ConfirmCheckbox tradingDocument={tradingDocument} replyModal={replyModal} />
            <div>Faktura VAT&nbsp;{tradingDocument.signature}</div>
          </SlidingHeaderWrapper>
        ) : (
          <ConfirmTradingDocument tradingDocument={tradingDocument} />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          {tradingDocument.status === "CONFIRMED" && (
            <Button
              className="mr-1 text-uppercase"
              isLoading={previewCorrectionMutation.isLoading}
              onClick={() =>
                previewCorrectionMutation.mutate({
                  id: tradingDocument.id,
                  expectedPaymentForm: tradingDocument.expectedPaymentForm,
                })
              }
              size="small"
              startIcon={MdiAdd}
              variant="gray"
            >
              Utwórz korektę
            </Button>
          )}
          <Tooltip title="Podgląd CMR">
            <IconButton
              icon={<MdiViewPdf size="18" />}
              onClick={() => {
                window.open(
                  `${DOMAIN}finances/trading-documents/cmr-pdf/${tradingDocument.id}`,
                  "_blank",
                );
              }}
              variant="transparent"
            />
          </Tooltip>

          <FileDownloadHandler
            factoryFn={() => tradingDocumentFileFactory.specification(tradingDocument)}
            type="pdf"
          >
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz specyfikację">
                <IconButton
                  icon={<MdiDownloadPdf size="18" />}
                  isLoading={isLoading}
                  onClick={download}
                  variant="transparent"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>
          <DownloadWdtPdf tradingDocument={tradingDocument} />
          <DownloadTradingDocumentXml tradingDocument={tradingDocument} />
          <CreateCorrectionManuallyModal
            close={createCorrectionManuallyModal.close}
            isOpen={createCorrectionManuallyModal.isOpen}
            state={createCorrectionManuallyModal.state}
            type="SALES"
          />
          <FileDownloadHandler
            factoryFn={() =>
              tradingDocumentFileFactory.salesInvoiceOptimaTradeModuleXml([tradingDocument.id])
            }
            type="xml"
          >
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz XML moduł handel [optima]">
                <IconButton
                  icon={<MdiDownloadXml size="18" />}
                  isLoading={isLoading}
                  onClick={download}
                  variant="transparent"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>
          <DownloadTradingDocumentPdf tradingDocument={tradingDocument} />
          <DownloadRevisor tradingDocumentIds={[tradingDocument.id]} />
          <DownloadFakir tradingDocumentIds={[tradingDocument.id]} />

          <MoreInfoButton close={close} invoice={tradingDocument} panelId={panelId} />
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      {replyModal.isOpen && (
        <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
      )}
    </>
  );
};
