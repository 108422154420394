import { TradingDocument, TradingDocumentPaymentStatus } from "api/trading-documents/models";
import { RightPanelSection } from "components/utils/drawer/Drawer";
import { SummarySection } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/summarySection/SummarySection";
import { PaymentDetails } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/tradingDocumentItems/PaymentDetails";
import { ImportedInvoiceItems } from "./ImportedInvoiceItems";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { useToggle } from "hooks";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { AddPurchaseInvoicePosition } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/components/AddPurchaseInvoicePosition";
import { getMaximumDisplayPosition } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/utils/getMaximumDisplayPosition";
import { PurchaseInvoiceImportPaymentForm } from "pages/tradingDocuments/shared/components/rightPanel/itemsSummarySection/components/PurchaseInvoiceImportPaymentForm";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { tradingDocumentsActions } from "api/trading-documents/actions";

interface Props {
  emptyCurrencyWarning: boolean;
  setEmptyCurrencyWarning: React.Dispatch<React.SetStateAction<boolean>>;
  tradingDocument: TradingDocument;
}

export const ItemsSection = ({
  emptyCurrencyWarning,
  tradingDocument,
  setEmptyCurrencyWarning,
}: Props) => {
  const addPurchaseInvoicePosition = useToggle();

  if (!Boolean(tradingDocument.items.length)) return null;

  return (
    <RightPanelSection>
      <div className="pb-3">
        <ImportedInvoiceItems tradingDocument={tradingDocument} />
        <>
          <div className="py-2 d-flex align-items-center justify-content-end gap-2">
            {emptyCurrencyWarning && (
              <Typography color="warning500" fontSize="12" fontWeight="600">
                Najpierw wybierz walutę
              </Typography>
            )}
            <Button
              className="text-uppercase"
              onClick={() => {
                if (!Boolean(tradingDocument.currency)) {
                  setEmptyCurrencyWarning(true);
                  return;
                }
                addPurchaseInvoicePosition.open();
              }}
              size="small"
              startIcon={MdiAdd}
              variant="gray"
            >
              Dodaj pozycję
            </Button>
          </div>
          {addPurchaseInvoicePosition.isOpen && (
            <AddPurchaseInvoicePosition
              close={addPurchaseInvoicePosition.close}
              maxDisplayPosition={getMaximumDisplayPosition(
                tradingDocument.items[0].tradingDocumentItems,
              )}
              tradingDocumentOrder={tradingDocument.items[0].id}
            />
          )}
        </>
        <SummarySection tradingDocument={tradingDocument} />
        <PaymentDetails
          customPaymentForm={
            <PurchaseInvoiceImportPaymentForm
              expectedPaymentForm={tradingDocument.expectedPaymentForm}
              id={tradingDocument.id}
              status={tradingDocument.status}
            />
          }
          customPaymentStatus={
            <Select.Async
              items={tradingDocumentConstants.invoicePaymentStatusItems}
              mutationHook={tradingDocumentsActions.usePatchImportedPurchaseInvoice}
              selected={tradingDocument.paymentStatus ?? null}
              transformQueryData={paymentStatus => ({
                id: tradingDocument.id,
                paymentStatus: paymentStatus as TradingDocumentPaymentStatus,
              })}
            />
          }
          tradingDocument={tradingDocument}
        />
      </div>
    </RightPanelSection>
  );
};
