import {
  getIndexes,
  getIndex,
  getPackages,
  getPackage,
  getEntries,
  getPackageLocations,
  getPackageIndexes,
  getEntry,
  getEntryPackages,
  getPackagesFromSource,
  getWhEntriesOrders,
  getWhEntriesPackages,
  getWhEntryReturnedPackages,
  getPackageDemandsForItem,
  getWarehouses,
  getWarehouse,
  getRow,
  getRows,
  getHall,
  getHalls,
  getLevel,
  getLevels,
  getColumns,
  getColumn,
  getCell,
  getCells,
  getCellsFillingStatus,
  warehouseFileFactory,
} from "api/warehouse/calls";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createPrimitiveHook, createPrimitivePaginatedHook } from "hooks/createPrimitiveHook";
import { useErrorToastr } from "hooks/useErrorToastr";

export const useIndexes = createPrimitivePaginatedHook(getIndexes);
export const useIndex = createPrimitiveHook(getIndex);

export const usePackages = createPrimitivePaginatedHook(getPackages);
export const usePackage = createPrimitiveHook(getPackage);
export const usePackageLocations = createPrimitiveHook(getPackageLocations);
export const usePackageIndexes = createPrimitiveHook(getPackageIndexes);

export const useEntries = createPrimitivePaginatedHook(getEntries);
export const useEntry = createPrimitiveHook(getEntry);
export const useEntryPackages = createPrimitiveHook(getEntryPackages);

export const usePackageDemandsForItem = createApiQuery(getPackageDemandsForItem);
export const usePackagesFromSource = createApiQuery(getPackagesFromSource);
export const useWhEntriesOrders = createApiQuery(getWhEntriesOrders);
export const useWhEntryOrderPackages = createApiQuery(getWhEntriesPackages);
export const useWhEntryReturnedPackages = createApiQuery(getWhEntryReturnedPackages);
export const useWarehouses = createPaginatedApiQuery(getWarehouses);
export const useWarehouse = createApiQuery(getWarehouse);

export const useRows = createPaginatedApiQuery(getRows);
export const useRow = createApiQuery(getRow);

export const useHalls = createPaginatedApiQuery(getHalls);
export const useHall = createApiQuery(getHall);

export const useLevels = createPaginatedApiQuery(getLevels);
export const useLevel = createApiQuery(getLevel);

export const useColumns = createPaginatedApiQuery(getColumns);
export const useColumn = createApiQuery(getColumn);

export const useCells = createPaginatedApiQuery(getCells);
export const useCell = createApiQuery(getCell);

export const useCellFillingStatus = createApiQuery(getCellsFillingStatus);

export const useDownloadColumnLabel = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (id: number, columnName: string) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = warehouseFileFactory.columnLabel({
      id,
      name: columnName,
    });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};
