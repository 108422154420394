import { RightPanel } from "./rightPanel/RightPanel";
import { useQuery } from "hooks";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { dictToList, queryString } from "utilities";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useRouteColumns } from "./useRouteColumns";
import { routeActions } from "api/routes/actions";
import { ManufacturingRouteListItem, ManufacturingStatus } from "api/routes/models";
import { routeList } from "components/common/moduleNavigation/moduleConfig/manufacturing/routes/routeList";
import { RouteComponentProps } from "react-router";

export const RouteList = ({ match }: RouteComponentProps<{ tab: Tabs }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const {
    data: manufacturingStages,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = routeActions.useManufacturingRoutes(search);

  const tableProps = useTableFeatureConnector({
    rows: manufacturingStages,
    withDrawer: "manufacturingRoute",
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  const columns = useRouteColumns();

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        tabs={{
          list: dictToList(tabToDict).map(({ key, value }) => ({
            label: value,
            name: key,
          })),
          routesRoot: `manufacturing/${routeList.url}`,
          urlSpan: "list",
        }}
        viewLabel="ROUTE_MANUFACTURING"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<ManufacturingRouteListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
      </div>
    </PageWrapper>
  );
};

type Tabs = "all" | ManufacturingStatus.FINISHED | ManufacturingStatus.IN_PROGRESS;

type Search = {
  tab: Tabs;
  query: {
    [x: string]: string;
  };
};

const tabToDict: Record<Tabs, string> = {
  all: "Wszystkie",
  FINISHED: "Przygotowane",
  IN_PROGRESS: "W przygotowaniu",
};

const getSearch = ({ query, tab }: Search) => {
  const tabsQuery = {
    manufacturingStatus: tab === "all" ? "" : tab,
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};
