import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { noop } from "utilities";

export interface Summary {
  id: number;
  weight: string;
}
export const useOrderSummaryColumns = () => {
  return useCreateTableColumns<Summary>(({ columnHelper }) => {
    return [
      columnHelper.stretchContent,
      columnHelper.accessor(row => row.weight, {
        id: "weight",
        header: "waga",
        size: 294,
        cell: info => {
          return (
            <div className="d-flex align-items-center gap-2">
              <Typography
                fontSize="14"
                fontWeight="700"
                className="text-uppercase"
                color="neutralBlack48"
                noWrap
              >
                Suma wag zamówień:
              </Typography>
              <div className="d-flex flex-1 align-items-center gap-1">
                <TextField size="small" value={info.getValue()} disabled onChange={noop} />
                <Typography fontSize="12" fontWeight="600" color="neutralBlack64">
                  KG
                </Typography>
              </div>
            </div>
          );
        },
      }),
    ];
  });
};
