import { Typography } from "components/miloDesignSystem/atoms/typography";
import { NavLink } from "react-router-dom";
import styles from "./TabSubSection.module.css";

export const TabSubSection = ({
  tabs,
  baseUrl,
}: {
  tabs: Record<string, string>;
  baseUrl: string;
}) => {
  return (
    <div>
      {Object.entries(tabs).map(([tab, label]) => (
        <NavLink
          tabIndex={-1}
          key={label + tab}
          className={styles.link}
          to={`${baseUrl}/${tab}`}
          activeClassName={styles.linkActive}
        >
          <div className={styles.border} />
          <Typography fontSize="12" fontWeight="700" className={styles.sectionName}>
            {label}
          </Typography>
        </NavLink>
      ))}
    </div>
  );
};
