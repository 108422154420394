import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import routeIcon from "assets/images/route.svg";
import { CallCenterRoutes } from "routes/CallCenterRoutes";

export const routes: ModuleLink = {
  url: "routes",
  label: "Trasy",
  icon: routeIcon,
  routing: CallCenterRoutes,
};
