import { tradingDocumentsActions } from "api/trading-documents/actions";
import { patchPurchaseInvoicePosition } from "api/trading-documents/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { UUID } from "api/types";
import { useMutation } from "hooks/useMutation";
import { assertIsDefined } from "utilities/assertIsDefined";

export const useEditPurchaseInvoicePosition = (
  itemId: string | number,
  tradingDocumentId: UUID,
  tradingDocumentItemId: UUID,
) => {
  const { handleMutate } = tradingDocumentsActions.useImportedPurchaseInvoice(
    { id: tradingDocumentId },
    { enabled: false },
  );

  return useMutation(patchPurchaseInvoicePosition, ({ queryClient, toastr }) => ({
    onMutate: toUpdate => {
      return handleMutate(draft => {
        const itemToUpdate = draft.items.find(item => item.id === itemId);
        assertIsDefined(itemToUpdate);
        const tradingDocumentItemToUpdate = itemToUpdate.tradingDocumentItems.find(
          tradingDocumentItem => tradingDocumentItem.id === tradingDocumentItemId,
        );
        assertIsDefined(tradingDocumentItemToUpdate);
        Object.assign(tradingDocumentItemToUpdate, toUpdate);
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        tradingDocumentsKeys.importedPurchaseInvoices.details(tradingDocumentId),
      );
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Edytowano pozycję",
      });
    },
    onError: (error, _, rollback) => {
      // @ts-ignore
      rollback(error);
    },
  }));
};
