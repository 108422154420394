import { ExecutionStatus as ExecutionStatusType } from "api/external-manufacturing/models";
import { Tag, TagProps } from "components/miloDesignSystem/atoms/tag";

export const executionStatusDict: Record<
  ExecutionStatusType,
  { label: string; variant: TagProps<string>["variant"] }
> = {
  NOT_STARTED: { label: "NIE ROZPOCZĘTO", variant: "info" },
  IN_PROGRESS: { label: "W PRODUKCJI", variant: "warning" },
  READY_TO_DELIVER: { label: "GOTOWE DO ODBIORU", variant: "success" },
};

interface Props {
  status: ExecutionStatusType;
}

export const ExecutionStatus = ({ status }: Props) => {
  return (
    <Tag
      label={executionStatusDict[status].label}
      type="outlined"
      variant={executionStatusDict[status].variant}
    />
  );
};
