import { queryFetch } from "apiConnectors/queryFetch";
import {
  OrderPackageExchange,
  OrderPackageExchangeListItem,
  PackageExchange,
  PostOrderPackageExchangeDTO,
  ProductExchange,
  SourceOrder,
} from "./models";
import { orderPackageExchangeKeys } from "./keys";
import { Pagination, UUID } from "api/types";
import { ApiFetcher } from "hooks/createApiQuery";
import { parsePatchData } from "utilities/parsePatchData";
import { PartialOf } from "typeUtilities";
import { Order } from "api/orders/models";
import { Assign } from "utility-types";

const baseAppUrl = "/order-packages-exchange";

const getOrderPackagesExchangeList = (
  search: string = "",
): ApiFetcher<Pagination<OrderPackageExchangeListItem>> => ({
  key: orderPackageExchangeKeys.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `${baseAppUrl}/order-packages-exchanges` + search,
    }),
});

const getOrderPackagesExchange = (
  id: OrderPackageExchange["id"],
): ApiFetcher<OrderPackageExchange> => ({
  key: orderPackageExchangeKeys.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `${baseAppUrl}/order-packages-exchanges/` + id,
    }),
});

const getProductExchanges = (search: string = ""): ApiFetcher<Pagination<ProductExchange>> => ({
  key: orderPackageExchangeKeys.productExchanges(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `${baseAppUrl}/product-exchanges` + search,
    }),
});

const postOrderPackagesExchange = (data: PostOrderPackageExchangeDTO) =>
  queryFetch<{ id: UUID }>({
    method: "POST",
    url: `${baseAppUrl}/order-packages-exchanges`,
    data,
  });

const patchPackageExchange = ({
  id,
  toUpdate,
}: {
  id: OrderPackageExchange["id"];
  toUpdate: PartialOf<PackageExchange>;
}) =>
  queryFetch<void>({
    method: "PATCH",
    url: `${baseAppUrl}/package-exchanges/${id}`,
    data: parsePatchData(toUpdate),
  });

const patchOrderPackagesExchanges = ({
  id,
  toUpdate,
}: {
  id: OrderPackageExchange["id"];
  toUpdate: PartialOf<Assign<OrderPackageExchange, { sourceOrdersIds: Order["id"][] }>>;
}) =>
  queryFetch<{ id: UUID }>({
    method: "PATCH",
    url: `${baseAppUrl}/order-packages-exchanges/${id}`,
    data: parsePatchData(toUpdate),
  });

const postLinkPackages = (data: {
  orderPackagesExchangeId: OrderPackageExchange["id"];
  sourceOrdersIds?: string[];
  singleItemQuantityIds?: string[];
  packagesUniqueCodes?: string[];
  packagesInternalIds?: string[];
}) =>
  queryFetch<{ id: UUID }>({
    method: "POST",
    url: `${baseAppUrl}/link-packages`,
    data,
  });

const getSourceOrders = (id: OrderPackageExchange["id"]): ApiFetcher<SourceOrder[]> => ({
  key: orderPackageExchangeKeys.sourceOrders(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/view-modules${baseAppUrl}/source-orders/` + id,
    }),
});

export const orderPackageExchangeApi = {
  postOrderPackagesExchange,
  getOrderPackagesExchange,
  getProductExchanges,
  patchOrderPackagesExchanges,
  getSourceOrders,
  postLinkPackages,
  patchPackageExchange,
  getOrderPackagesExchangeList,
};
