import styles from "../../../../shared/CallCenter.module.css";
import localStyles from "../../../Dashboard.module.css";
import cx from "classnames";
import timelineIcon from "assets/images/timeline.svg";
import {
  CallCenterDashboardDepartedRoute,
  trackingStatusDict,
} from "api/call-center/routes/models";
import { PingLabel } from "components/common/pingLabel";
import { Button } from "components/common";
import { useToggle } from "hooks";
import { RouteTrackingModal } from "pages/callCenter/routesList/routeTracking/RouteTrackingModal";
import { convertMinutesToHoursAndMinutes } from "utilities/convertMinutesToHoursAndMinutes";
import { LinkToPage } from "components/utils/linkToPage";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  departedRoute: CallCenterDashboardDepartedRoute;
}

export const DepartedRoute = ({ departedRoute }: Props) => {
  const routeTrackingModal = useToggle();

  return (
    <>
      <div className={cx(localStyles.tableRow, localStyles.tableRowBokDepartedRoutes)}>
        <div className={styles.overflow}>
          <LinkToPage
            content={departedRoute.signature}
            url={`/call-center/routes/${departedRoute.id}/orders`}
          />
        </div>
        <Typography fontSize="12" fontWeight="600" noWrap>
          {departedRoute.name.length > 0 ? departedRoute.name : "---"}
        </Typography>
        {departedRoute.driver ? (
          <div className={styles.overflow}>
            {departedRoute.driver.firstName} {departedRoute.driver.lastName}
          </div>
        ) : (
          <div>---</div>
        )}
        {departedRoute.car ? (
          <div>
            {departedRoute.car.registrationNumber}&nbsp;
            {departedRoute.car.ownership === "INTERNAL" && (
              <span className="body-12 text-deep-purple-5">WEW</span>
            )}
            {departedRoute.car.ownership === "EXTERNAL" && (
              <span className="body-12 text-deep-orange-4">ZEW</span>
            )}
          </div>
        ) : (
          <div>---</div>
        )}
        <div>
          <PingLabel
            className={localStyles.labelNoWrap}
            kind={departedRoute.trackingStatus}
            label={trackingStatusDict[departedRoute.trackingStatus]}
          />
        </div>
        <div className="d-flex justify-content-end">
          {departedRoute.delay === 0 ? (
            <div className="fw-400">---</div>
          ) : (
            <div className="fw-400">{convertMinutesToHoursAndMinutes(departedRoute.delay)}</div>
          )}
        </div>
        <Button
          kind="create-medium-transparent"
          onClick={e => {
            e.stopPropagation();
            routeTrackingModal.open();
          }}
          style={{ height: "26px" }}
        >
          <div className="btnBase btnBaseSmall">
            <img alt="" src={timelineIcon} style={{ height: "16px", width: "16px" }} />
            <div className={styles.routeTrackingBtn}>{String("Podgląd").toUpperCase()}</div>
          </div>
        </Button>
      </div>
      {routeTrackingModal.isOpen && (
        <RouteTrackingModal close={routeTrackingModal.close} route={departedRoute} />
      )}
    </>
  );
};
