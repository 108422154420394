import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { AwaitingTransportRouting } from "routes/wms/AwaitingTransportRouting";
import { MdiDepartureBoard } from "components/miloDesignSystem/atoms/icons/MdiDepartureBoard";

export const awaitingTransportModuleLink: ModuleLink = {
  url: "awaiting-transports",
  label: "Awizacje",
  icon: MdiDepartureBoard,
  routing: AwaitingTransportRouting,
};
