import { ManufacturingEmail } from "api/manufacturing/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { manufacturingConstants } from "constants/manufacturingConstants";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useEmailColumns = () => {
  return useCreateTableColumns<ManufacturingEmail>(
    ({ columnHelper }) => {
      return [
        columnHelper.date(row => row.createdAt, {
          header: "data",
          size: 80,
          typographyProps: {
            fontSize: "12",
            fontWeight: "400",
          },
        }),
        columnHelper.text(row => row.message.emailTo, {
          header: "na adres",
          size: 280,
        }),
        columnHelper.accessor(row => row.message.status, {
          header: "status email",
          size: 80,
          cell: info => {
            const emailStatus = info.getValue();
            if (!emailStatus) return <EmptyValue fontSize="14" fontWeight="600" />;
            return <Tag {...manufacturingConstants.emailStatusDict[emailStatus]} />;
          },
        }),
        columnHelper.accessor(row => row.message.createdBy, {
          header: "przez kogo",
          size: 100,
          cell: info => {
            const createdBy = info.getValue();
            if (!createdBy) return <EmptyValue fontSize="12" />;
            return (
              <div className="d-flex align-items-center gap-1">
                <Avatar size="sm" user={createdBy} />
                <Typography fontSize="12" fontWeight="700" noWrap>
                  {createdBy.firstName} {createdBy.lastName}
                </Typography>
              </div>
            );
          },
        }),
      ];
    },
    {
      shouldDisplayIndexColumn: true,
    },
  );
};
