import { wmsLayoutActions } from "api/wms/layout/actions";
import { wmsLayoutConstants } from "api/wms/layout/constants";
import { FieldDetails, FieldKind } from "api/wms/layout/models";
import { Search } from "components/miloDesignSystem/molecules/search";
import { Select } from "components/miloDesignSystem/molecules/select";
import { RightPanelSection } from "components/utils/drawer";
import styles from "./AssignRealFieldSection.module.css";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";

interface Props {
  field: FieldDetails;
  selectedHall: number;
}

export const AssignRealFieldSection = ({ field, selectedHall }: Props) => {
  const assignFieldMutation = wmsLayoutActions.useFieldPatch(selectedHall);

  return (
    <RightPanelSection title="Przypisanie rzeczywistego miejsca w magazynie">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex align-items-center">
          <Select
            items={wmsLayoutConstants.fieldKindOptions}
            onChange={fieldKind => {
              assignFieldMutation.mutate({
                id: field.id,
                toUpdate: {
                  wmsId: null,
                  wmsKind: fieldKind as FieldKind,
                  name: "",
                },
              });
            }}
            selected={field.wmsKind || null}
          />
        </div>
        <div className="d-flex align-items-center gap-3">
          <Search
            key={field.wmsKind}
            normalizeItem={item => {
              if (field.wmsKind === FieldKind.COLUMN)
                return {
                  value: item.id,
                  text: `${item.name}, regał: ${item.whRow.name}`,
                };
              return {
                value: item.id,
                text: item.name,
              };
            }}
            isNullable={false}
            textFieldProps={{ containerClassName: styles.input }}
            fetcherFn={wmsLayoutConstants.assignFieldFetchSource[field.wmsKind]}
            transformQuery={
              field.wmsKind === FieldKind.FIELD || field.wmsKind === FieldKind.COLUMN
                ? {
                    isAssignableToSchema: "true",
                  }
                : { rowType: field.wmsKind }
            }
            externalSelectedItem={
              field.wmsId
                ? {
                    value: String(field.wmsId),
                    text: field.name,
                  }
                : null
            }
            onChange={searchedField => {
              if (searchedField) {
                assignFieldMutation.mutate({
                  id: field.id,
                  toUpdate: {
                    wmsId: searchedField.id,
                    name: searchedField.name,
                  },
                });
              }
            }}
          />

          {assignFieldMutation.isLoading && <Spinner size={20} />}
        </div>
      </div>
    </RightPanelSection>
  );
};
