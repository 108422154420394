import styled from "@emotion/styled";
import { MdiInfo } from "components/miloDesignSystem/atoms/icons/MdiInfo";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { ClickOutsideHandler } from "components/utils";

interface Props {
  setEmptyCurrencyWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EmptyCurrencyWarning = ({ setEmptyCurrencyWarning }: Props) => {
  return (
    <ClickOutsideHandler
      onClickOutside={() => {
        setEmptyCurrencyWarning(false);
      }}
      outsideClickIgnoreClass={ignoreClickOutsideClassName}
    >
      <StyledCurrencyWarning>
        <div className="d-flex align-items-center gap-2">
          <MdiInfo color="warning400" size="20" />
          <Typography color="warning400" fontSize="14" fontWeight="700">
            Wybierz walutę aby móc dodać pozycję do faktury
          </Typography>
        </div>
      </StyledCurrencyWarning>
    </ClickOutsideHandler>
  );
};

const StyledCurrencyWarning = styled.div`
  background-color: var(--neutralWhite100);
  border: 1px solid var(--warning400);
  border-radius: 4px;
  box-shadow: 0px 20px 24px -10px #110c221a;
  padding: 12px;
`;

const ignoreClickOutsideClassName = "empty-currency-ignore-class-name";
