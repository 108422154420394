import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { NormalizedOrder } from "./ViewOrdersCmrModal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TextField } from "components/miloDesignSystem/atoms/textField";

export const useOrderColumns = () => {
  return useCreateTableColumns<NormalizedOrder>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.signature, {
          header: "zamówienie",
          size: 200,
          typographyProps: {
            fontSize: "16",
            fontWeight: "700",
          },
        }),
        columnHelper.text(row => row.buyer, {
          header: "klient",
          size: 200,
          typographyProps: {
            fontSize: "16",
            fontWeight: "400",
          },
        }),
        columnHelper.text(row => row.address, {
          header: "adres",
          size: 338,
          typographyProps: {
            fontSize: "16",
            fontWeight: "400",
          },
        }),
        columnHelper.accessor(row => row.hardItemsWeight, {
          id: "hardItemsWeight",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              waga - meble twarde
            </Typography>
          ),
          size: 150,
          cell: info => {
            return (
              <div className="d-flex flex-1 align-items-center gap-1">
                <TextField.Form
                  size="small"
                  name={`orders.[${info.row.index}].hardItemsWeight`}
                  type="number"
                />
                <Typography fontSize="12" fontWeight="600" color="neutralBlack64">
                  KG
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row.softItemsWeight, {
          id: "softItemsWeight",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              waga - meble tapicerowane
            </Typography>
          ),
          size: 150,
          cell: info => {
            return (
              <div className="d-flex flex-1 align-items-center gap-1">
                <TextField.Form
                  size="small"
                  name={`orders.[${info.row.index}].softItemsWeight`}
                  type="number"
                />
                <Typography fontSize="12" fontWeight="600" color="neutralBlack64">
                  KG
                </Typography>
              </div>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};
