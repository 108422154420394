import { CountryCode } from "CONSTANTS";
import { ISODateTime, UUID } from "api/types";
import { UserWithInitials } from "api/users/models";

export enum AwaitingTransportStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}

export interface AwaitingTransportListItem {
  id: UUID;
  signature: string;
  description: string;
  customer: { id: number; name: string } | null;
  status: AwaitingTransportStatus;
  startedAt: ISODateTime | null;
  finishedAt: ISODateTime | null;
  collaborators: UserWithInitials[];
  unloading: { result: UUID; signature: string } | null;
  createdAt: ISODateTime;
  numberOfPackages: number;
  numberOfScannedPackages: number;
  createdBy: UserWithInitials | null;
  updatedAt: ISODateTime | null;
}

export interface AwaitingTransportDetails extends AwaitingTransportListItem {}

export enum GroupStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}
export interface AwaitingTransportGroupListItem {
  id: UUID;
  signature: string;
  awaitingTransport: AwaitingTransportDetails["id"];
  name: string;
  position: number;
  status: GroupStatus;
  sourceId: string;
  sourceKind: string;
  sourceExternalId: string;
  address: { countryCode: CountryCode } | null;
  collaborators: UserWithInitials[];
  loadedAt: ISODateTime | null;
  createdAt: ISODateTime;
  updatedAt: ISODateTime | null;
  numberOfPackages: number;
  loadedPackages: number;
}

export interface AddAwaitingTransportToUnloadingResponse {
  createdUnloading: {
    id: UUID;
    signature: string;
  } | null;
  inDifferentUnloading: {
    order: { id: number; signature: string; externalId: string };
    unloading: { id: UUID; signature: string } | null;
  }[];
}

export interface AwaitingTransportPackageGroup {
  id: UUID;
  awaitingTransportGroup: UUID;
  name: string;
  status: GroupStatus;
  sourceId: UUID;
  collaborators: UserWithInitials[];
  createdAt: ISODateTime;
  updatedAt: ISODateTime | null;
  packages: {
    id: UUID;
    collaborator: UserWithInitials | null;
    name: string;
    loadedAt: ISODateTime | null;
  }[];
}

export interface AwaitingTransportPackageGroup {
  id: UUID;
  awaitingTransportGroup: UUID;
  name: string;
  status: GroupStatus;
  sourceId: UUID;
  collaborators: UserWithInitials[];
  createdAt: ISODateTime;
  updatedAt: ISODateTime | null;
  packages: {
    id: UUID;
    collaborator: UserWithInitials | null;
    name: string;
    loadedAt: ISODateTime | null;
  }[];
}
