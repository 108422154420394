import { useWarehouseWorkers } from "api/wms/hooks";
import { WarehouseWorkerListItem } from "api/wms/models";
import { CargoUnloadTab } from "api/wms/models";
import { useQuery, useToggle } from "hooks";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { RouteComponentProps } from "react-router";
import { getSearch } from "./utils/getSearch";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Table } from "components/miloDesignSystem/molecules/table";
import { PageHeader } from "components/common/pageHeader/PageHeader";
import { AddWarehousemanModal } from "./addWarehousemanModal/AddWarehousemanModal";
import { useWarehousemanListColumns } from "./useWarehousemanListColumns";
import { getTabs } from "./utils/getTabs";
import { warehousemen } from "components/common/moduleNavigation/moduleConfig/wms/routes/warehousemen";
import { RightPanel } from "./rightPanel/RightPanel";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";

const useSearchParams = () => {
  const { query, setQuery, updateQuery } = useQuery();
  return { searchParams: query, setQuery, updateQuery };
};

export const WarehousemanList = ({ match }: RouteComponentProps<{ tab: CargoUnloadTab }>) => {
  const { tab } = match.params;
  const { searchParams } = useSearchParams();
  const search = getSearch({ query: searchParams, tab });
  const addWarehousemanModal = useToggle();
  const { data, error, isLoading, pagination } = useWarehouseWorkers(search);
  const columns = useWarehousemanListColumns();
  const tableProps = useTableFeatureConnector({
    rows: data,
    withDrawer: "wmsWarehousemen",
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  return (
    <>
      <PageWrapper>
        <PageHeader
          searchInput={{
            label: "Szukaj wśród magazynierów",
            tags: [],
          }}
          createButton={{
            alt: "utwórz",
            img: darkPlusIcon,
            label: "Utwórz magazyniera",
            onClick: addWarehousemanModal.open,
          }}
          tabs={{
            list: getTabs(),
            routesRoot: `wms/${warehousemen.url}`,
            urlSpan: "list",
          }}
          viewLabel="WAREHOUSEMAN"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<WarehouseWorkerListItem>
            columns={columns}
            isLoading={isLoading}
            error={error}
            uiSchema={mainListUiSchema}
            {...tableProps}
          />
          <RightPanel />
        </div>
      </PageWrapper>
      {addWarehousemanModal.isOpen && <AddWarehousemanModal close={addWarehousemanModal.close} />}
    </>
  );
};
