import { Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { StageBoard } from "pages/manufacturingNew/manufacturingStages/stageBoard/StageBoard";
import { AllManufacturingUnitsList } from "pages/manufacturingNew/manufacturingStages/stageBoard/listView/AllManufacturingUnitsList";
import { MaterialDemands } from "pages/manufacturingNew/manufacturingStages/stageBoard/materialDemands/MaterialDemands";

export const ProductionOrdersRouting = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/:tab`}
        component={AllManufacturingUnitsList}
        title="Milo - zlecenia produkcyjne"
      />
      <Route
        exact={true}
        path={`${match.path}/stage-orders/:tab`}
        component={StageBoard}
        title="Milo - zlecenia produkcyjne"
      />
      <Route
        exact={true}
        path={`${match.path}/demands/:stageId`}
        component={MaterialDemands}
        title="Milo - zapotrzebowanie materiałów"
      />
      <Route exact={true} path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};
