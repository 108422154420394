import { InvoiceType, TradingDocumentType } from "api/trading-documents/models";
import { UUID } from "api/types";
import { tradingDocumentConstants } from "constants/tradingDocuments";

export const getTradingDocumentRedirectUrl = (
  type: TradingDocumentType,
  invoiceType: InvoiceType,
  id: UUID,
): string => {
  const tab = (() => {
    if (type === "RECEIPT") return "receipts";
    return tradingDocumentConstants.tradingDocumentTypeToTabDict[invoiceType];
  })();
  return `/finances/${tab}/list/all?panelId=${id}`;
};
