import { ColumnDef } from "@tanstack/react-table";
import { PackageExchangeStatus, SourceOrderPackageStatus } from "api/order-packages-exchange/enums";
import { PackageExchange, SourceOrderPackage } from "api/order-packages-exchange/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import styles from "../shared/packageExchange.module.css";

export interface NormalizePackagesListItem
  extends Pick<
    PackageExchange,
    | "linkedCode"
    | "linkedOrder"
    | "confirmedAt"
    | "location"
    | "confirmedBy"
    | "id"
    | "uniqueCode"
    | "packageWarehouseStatus"
  > {
  packageName: string;
  exchangeStatus: PackageExchange["status"] | null;
  sourceStatus: SourceOrderPackage["status"] | null;
  internalId: string;
  isAvailable: boolean;
}

export const ProductListItem = ({
  productName,
  index,
  columns,
  packages,
}: {
  productName: string;
  index: number;
  columns: ColumnDef<NormalizePackagesListItem>[];
  packages: NormalizePackagesListItem[];
}) => {
  return (
    <div className="d-flex flex-column overflow-auto mt-3">
      <div className="d-flex align-items-center gap-3 mb-2">
        <Typography fontSize="12" fontWeight="400">
          {index + 1}.
        </Typography>
        <Typography fontSize="16" fontWeight="700">
          {productName}
        </Typography>
      </div>

      <div className="overflow-auto pb-2">
        <Table<NormalizePackagesListItem>
          rows={packages}
          columns={columns}
          isLoading={false}
          error={null}
          overrides={() => ({
            row: row => {
              if (row.exchangeStatus === PackageExchangeStatus.FINISHED) {
                return { className: "success-border" };
              }

              if (!row.isAvailable || row.sourceStatus === SourceOrderPackageStatus.RELEASED) {
                return { className: styles.disabledRow };
              }

              if (row.packageWarehouseStatus?.isReleased) {
                return { className: styles.disabledRow };
              }

              if (row.sourceStatus === SourceOrderPackageStatus.CONFIRMED) {
                return { className: "success-border" };
              }

              if (
                row.sourceStatus === SourceOrderPackageStatus.LINKED ||
                row.exchangeStatus === PackageExchangeStatus.IN_PROGRESS
              ) {
                return { className: "info-border" };
              }

              if (
                row.exchangeStatus === PackageExchangeStatus.NOT_STARTED &&
                row.packageWarehouseStatus?.isReceived
              ) {
                return { className: "deepPurple-border" };
              }

              if (row.sourceStatus === SourceOrderPackageStatus.WAITING_FOR_LINK) {
                return { className: "deepPurple-border" };
              }

              return { className: styles.disabledRow };
            },
          })}
          uiSchema={{
            header: {
              backgroundColor: "neutralWhite100",
            },
            cell: {
              height: "34",
            },
          }}
        />
      </div>
    </div>
  );
};
