import { FreeSingleItemQuantitiesDetails } from "api/orders/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { usePackageColumns } from "./usePackageColumns";
import { detailsListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";

export const PackageSection = ({
  packages,
}: {
  packages: FreeSingleItemQuantitiesDetails["packages"];
}) => {
  const columns = usePackageColumns();
  const tableProps = useTableFeatureConnector({
    rows: packages,
  });

  return (
    <div className="d-flex flex-1 overflow-hidden justify-content-between">
      <Table<FreeSingleItemQuantitiesDetails["packages"][number]>
        columns={columns}
        error={null}
        isLoading={false}
        uiSchema={detailsListUiSchema}
        {...tableProps}
      />
    </div>
  );
};
