import { PropsWithChildren } from "react";
import { PaperProps } from "./types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { colorPalette } from "../colorsPalette";

export const Paper = ({
  backgroundColor,
  children,
  className,
  maxWidth,
  minHeight,
  minWidth,
  width,
}: PropsWithChildren<PaperProps>) => {
  return (
    <StyledWrapper
      backgroundColor={backgroundColor}
      className={className}
      maxWidth={maxWidth}
      minHeight={minHeight}
      minWidth={minWidth}
      width={width}
    >
      {children}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<
  Pick<PaperProps, "minHeight" | "width" | "minWidth" | "maxWidth" | "backgroundColor">
>`
  box-shadow: 0px 6px 16px -6px var(--neutralBlack12);
  border-radius: 4px;
  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};
  width: ${props => props.width};
  ${props => css`
    background-color: ${props.backgroundColor === "transparent"
      ? "transparent"
      : colorPalette[props.backgroundColor || "neutralWhite100"]};
  `}
  ${props =>
    Boolean(props.minHeight) &&
    css`
      min-height: ${props.minHeight}px;
    `}
`;
