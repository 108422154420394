import { TradingDocument } from "api/trading-documents/models";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { RightPanelSection, RightPanelTableRow } from "components/utils/drawer";
import { currencyConvertSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { CurrencyConvert } from "./components/CurrencyConvert";

interface Props {
  tradingDocument: TradingDocument;
}

export const CurrencyConvertSection = ({ tradingDocument }: Props) => {
  return (
    <RightPanelSection>
      <div className="pt-4">
        <RightPanelTableRow grid={currencyConvertSectionListConfig.grid} hover={false}>
          <div>kurs</div>
          <div>z dnia</div>
          <div></div>
          <div>VAT</div>
          <div className="d-flex align-items-center justify-content-end">wartość netto</div>
          <div className="d-flex align-items-center justify-content-end">kwota VAT</div>
          <div className="d-flex align-items-center justify-content-end">wartość brutto</div>
        </RightPanelTableRow>
        {tradingDocument.amountSummaryPerVatRateCurrencyConvert.map((currencyConvert, index) => (
          <CurrencyConvert currencyConvert={currencyConvert} key={index} />
        ))}
        <RightPanelTableRow grid={currencyConvertSectionListConfig.grid}>
          <div />
          <div />
          <div className="d-flex align-items-center justify-content-end text-grey-35">suma:</div>
          <div />
          <div className="d-flex align-items-center justify-content-end gap-1">
            {tradingDocument.amountSummaryCurrencyConvert.totalWithoutTax ? (
              <>
                <strong className="body-14 fw-800">
                  <FinanceAmountFormat
                    value={tradingDocument.amountSummaryCurrencyConvert.totalWithoutTax}
                  />
                </strong>
                <div className="fw-700">PLN</div>
              </>
            ) : (
              "--"
            )}
          </div>
          <div className="d-flex align-items-center justify-content-end gap-1">
            {tradingDocument.amountSummaryCurrencyConvert.totalTax ? (
              <>
                <strong className="body-14 fw-800">
                  <FinanceAmountFormat
                    value={tradingDocument.amountSummaryCurrencyConvert.totalTax}
                  />
                </strong>
                <div className="fw-700">PLN</div>
              </>
            ) : (
              "--"
            )}
          </div>
          <div className="d-flex align-items-center justify-content-end gap-1">
            {tradingDocument.amountSummaryCurrencyConvert.totalWithTax ? (
              <>
                <strong className="body-14 fw-800">
                  <FinanceAmountFormat
                    value={tradingDocument.amountSummaryCurrencyConvert.totalWithTax}
                  />
                </strong>
                <div className="fw-700">PLN</div>
              </>
            ) : (
              "--"
            )}
          </div>
        </RightPanelTableRow>
      </div>
      <div>
        <div className="d-flex align-items-center justify-content-end gap-3">
          <div className="d-flex align-items-center justify-content-end body-14-600">
            razem do zapłaty:
          </div>
          <div className="d-flex align-items-center justify-content-end">
            {tradingDocument.amountSummaryCurrencyConvert.totalWithTax ? (
              <strong className="body-18-800">
                <div className="d-flex">
                  <FinanceAmountFormat
                    value={tradingDocument.amountSummaryCurrencyConvert.totalWithTax}
                  />
                  &nbsp;PLN
                </div>
              </strong>
            ) : (
              <strong className="body-18-800">--</strong>
            )}
          </div>
        </div>
      </div>
    </RightPanelSection>
  );
};
