import { Typography } from "components/miloDesignSystem/atoms/typography";
import { differenceInHours, differenceInMinutes } from "date-fns";
import { dateUtils } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  date: string;
}

export const EmailScheduleDate = ({ date }: Props) => {
  const currentDate = new Date();
  const targetDate = new Date(date);

  const minutesDifference = differenceInMinutes(targetDate, currentDate);
  const hoursDiff = differenceInHours(targetDate, currentDate);

  if (minutesDifference <= 0) {
    return <EmptyValue color="neutralWhite100" />;
  } else if (minutesDifference > 0 && minutesDifference < 60) {
    return (
      <div className="d-flex align-items-center gap-1">
        <Typography
          color={minutesDifference <= 10 ? "danger500" : "neutralWhite100"}
          fontSize="14"
          fontWeight="700"
        >
          {minutesDifference > 0 && minutesDifference <= 1
            ? `<${minutesDifference}`
            : `${minutesDifference}`}
        </Typography>
        <Typography color="neutralWhite64" fontSize="14" fontWeight="700">
          min.
        </Typography>
      </div>
    );
  } else if (minutesDifference >= 60 && hoursDiff < 24) {
    const remainingMinutes = minutesDifference % 60;
    return (
      <div className="d-flex align-items-center">
        <Typography color="neutralWhite100" fontSize="14" fontWeight="700">
          {hoursDiff}
        </Typography>
        <Typography className="mr-1" color="neutralWhite64" fontSize="14" fontWeight="700">
          h
        </Typography>
        <Typography color="neutralWhite100" fontSize="14" fontWeight="700">
          {remainingMinutes}
        </Typography>
        <Typography color="neutralWhite64" fontSize="14" fontWeight="700">
          min.
        </Typography>
      </div>
    );
  } else {
    return (
      <Typography color="neutralWhite100" fontSize="14" fontWeight="700">
        {dateUtils.formatDateToDisplay(date)}
      </Typography>
    );
  }
};
