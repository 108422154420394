import { FLAVOR } from "CONSTANTS";
import { dictToList } from "utilities";

export enum AwaitingTransportTab {
  "ALL" = "all",
  "IN_PROGRESS" = "in-progress",
  "FINISHED" = "finished",
}

export const tabDict: Record<AwaitingTransportTab, string> = {
  [AwaitingTransportTab.ALL]: "Wszystkie",
  [AwaitingTransportTab.IN_PROGRESS]: FLAVOR === "b2b" ? "Otwarte" : "W trakcie",
  [AwaitingTransportTab.FINISHED]: "Zakończone",
};

export const getTabs = (): { label: string; name: string }[] =>
  dictToList(tabDict).map(({ key, value }) => ({ label: value, name: key }));
