import { TransactionType } from "api/manufacturing/wallet/models";
import { TagProps } from "components/miloDesignSystem/atoms/tag";

const transactionTypeOptions: Record<
  TransactionType,
  { label: string; variant: TagProps<string>["variant"] }
> = {
  ADVANCE_PAYMENT: { label: "zaliczka", variant: "deepPurple400" },
  DEPOSIT: { label: "depozyt", variant: "info" },
  PIECEWORK: { label: "akord", variant: "warning" },
  WITHDRAWAL: { label: "wypłata", variant: "success" },
};

export const manufacturingWalletsConstants = {
  transactionTypeOptions,
};
