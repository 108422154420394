import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Formik } from "formik";
import { cx } from "utilities";
import { validationSchema } from "./validationSchema";
import { Button } from "components/miloDesignSystem/atoms/button";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Search } from "components/miloDesignSystem/molecules/search";
import { materialsApi } from "api/manufacturing/materials/api";
import { manufacturingActions } from "api/manufacturing/actions";
import { PostRecipe } from "api/manufacturing/models";
import { useParams } from "react-router";

interface Props {
  close: () => void;
  position: number;
}

export const AddMaterialModal = ({ close, position }: Props) => {
  const handleSubmit = manufacturingActions.usePostRecipes(close);
  const { indexId } = useParams<{ indexId: string }>();

  const initialValues: PostRecipe = {
    index: Number(indexId),
    position,
    material: null,
    quantity: 1,
  };

  return (
    <Modal
      close={close}
      isOpen
      width={480}
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj materiał do receptury
        </Typography>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <>
            <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
              <div className="mx-3">
                <div className="mb-4">
                  <Search.Form
                    fetcherFn={materialsApi.getMaterials}
                    isNullable
                    name="material"
                    textFieldProps={{ placeholder: "Szukaj materiału" }}
                  />
                </div>

                <div className="mb-4 col-6 p-0">
                  <TextField.Form<PostRecipe>
                    label="Ilość/Liczba"
                    name="quantity"
                    placeholder="Wpisz ilość/liczbę"
                  />
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 p-3 borderTop">
                <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                  Anuluj
                </Button>
                <Button
                  className="text-uppercase"
                  isLoading={isSubmitting}
                  size="medium"
                  type="submit"
                  variant="deepPurple"
                >
                  Dodaj
                </Button>
              </div>
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
};
