import { TradingDocument } from "api/trading-documents/models";
import { RightPanelSection } from "components/utils/drawer";
import { SalesInvoiceItems } from "./SalesInvoiceItems";
import { SummarySection } from "../summarySection/SummarySection";
import { PaymentDetails } from "./PaymentDetails";

interface Props {
  tradingDocument: TradingDocument;
}

export const ItemsSection = ({ tradingDocument }: Props) => {
  if (!Boolean(tradingDocument.items.length)) return null;

  return (
    <RightPanelSection>
      <div className="pb-3">
        <SalesInvoiceItems tradingDocument={tradingDocument} />
        <SummarySection tradingDocument={tradingDocument} />
        <PaymentDetails tradingDocument={tradingDocument} />
      </div>
    </RightPanelSection>
  );
};
