export enum OrderExchangeStatus {
  /**
   * @description Exchange didn't start yet
   */
  NOT_STARTED = "NOT_STARTED",
  /**
   * @description Started exchange for at least one product
   */
  IN_PROGRESS = "IN_PROGRESS",
  /**
   * @description Finished exchange for all products
   */
  FINISHED = "FINISHED",
}

export enum PackageExchangeStatus {
  /**
   * @description Exchange didn't start yet
   */
  NOT_STARTED = "NOT_STARTED",
  /**
   * @description Exchange initiated
   */
  IN_PROGRESS = "IN_PROGRESS",
  /**
   * @description Exchange confirmed
   */
  FINISHED = "FINISHED",
}

export enum ProductExchangeStatus {
  /**
   * @description Exchange didn't start yet
   */
  NOT_STARTED = "NOT_STARTED",
  /**
   * @description Started exchange for at least one package
   */
  IN_PROGRESS = "IN_PROGRESS",
  /**
   * @description Finished exchange for all packages
   */
  FINISHED = "FINISHED",
}

export enum SourceOrderPackageStatus {
  /**
   * @description Exchange didn't start yet
   */
  WAITING_FOR_LINK = "WAITING_FOR_LINK",
  /**
   * @description Exchange initiated
   */
  LINKED = "LINKED",
  /**
   * @description Exchange confirmed
   */
  CONFIRMED = "CONFIRMED",
  /**
   * @description Exchange confirmed
   */
  RELEASED = "RELEASED",
}
