import { CustomerEmployee } from "api/customers/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { MdiDelete } from "../../../../../../../../components/miloDesignSystem/atoms/icons/MdiDelete";
import { customerActions } from "../../../../../../../../api/customers/actions";

export const useUsersColumns = () => {
  const removeCustomerEmployeeMutation = customerActions.useRemoveCustomerEmployee();

  return useCreateTableColumns<CustomerEmployee>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "imię nazwisko",
        size: 280,
        cell: info => {
          const employee = info.getValue();
          return (
            <div className="d-flex align-items-center gap-2">
              <Avatar size="sm" user={employee.user} />
              <Typography fontSize="16" fontWeight="500" noWrap>
                {employee.user.firstName} {employee.user.lastName}
              </Typography>
              {employee.user.isActive && (
                <Typography
                  className="text-uppercase"
                  color="deepPurple500"
                  fontSize="10"
                  fontWeight="700"
                >
                  Aktywny
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.text(row => row.user.phone, {
        header: "telefon",
        size: 100,
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.text(row => row.email, {
        header: "email",
        size: 200,
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.date(row => row.createdAt, {
        header: "dodano",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "400",
        },
      }),
      columnHelper.date(row => row.user.lastLogin, {
        header: "ostatnie logowanie",
        size: 130,
        format: "formatRelative",
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row, {
        id: "removeEmployee",
        header: " ",
        size: 30,
        cell: info => {
          const employee = info.getValue();
          return (
            <IconButton
              icon={MdiDelete}
              isLoading={removeCustomerEmployeeMutation.isLoading}
              onClick={() => removeCustomerEmployeeMutation.mutate(employee.id)}
              variant="transparent"
            />
          );
        },
      }),
    ];
  });
};
