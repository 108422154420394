import { PostManufacturingStage } from "api/manufacturing/schemas/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Formik } from "formik";
import { validationSchema } from "./validationSchema";
import { getRandomColor } from "utilities/getRandomColor";
import { cx } from "utilities";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Button } from "components/miloDesignSystem/atoms/button";
import { manufacturingSchemaActions } from "api/manufacturing/schemas/actions";
import { useSelector } from "hooks";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";

interface Props {
  close: () => void;
}

export const AddManufacturingStage = ({ close }: Props) => {
  const stageColumnSets = useSelector(store => store.partials.stageColumnSets);
  const stageColumnSetsOptions = stageColumnSets.map(stageColumnSet => ({
    text: stageColumnSet.name,
    type: MenuItemType.TEXT,
    value: stageColumnSet.id,
  }));
  const { backgroundColor, textColor } = getRandomColor();
  const initialValues: PostManufacturingStage = {
    backgroundColor,
    name: "",
    stageColumnSet: "",
    textColor,
  };
  const handleSubmit = manufacturingSchemaActions.usePostManufacturingStage(close);

  return (
    <Modal
      close={close}
      isOpen
      width={480}
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj etap produkcyjny
        </Typography>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="px-3 pb-3">
              <TextField.Form<PostManufacturingStage>
                containerClassName="mb-4"
                label="Nazwa etapu"
                name="name"
                placeholder="Nazwa etapu"
              />
              <Select.Form<PostManufacturingStage>
                className="mb-2"
                items={stageColumnSetsOptions}
                label="Schemat kolumn"
                name="stageColumnSet"
                textFieldProps={{ size: "default" }}
              />
            </div>
            <div className="d-flex align-items-center gap-2 p-3 borderTop">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Dodaj
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
