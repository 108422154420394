import { wmsAwaitingTransportsActions } from "api/wms/awaiting-transports/actions";
import { Unloading } from "api/wms/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { CommonError } from "components/utils/commonError/CommonError";
import { useFilters } from "hooks/useFilters";
import { AddAwaitingTransportsToUnloadingContent } from "./AddAwaitingTransportsToUnloadingContent";
import { useStateModal } from "hooks";
import { AddAwaitingTransportToUnloadingResponse } from "api/wms/awaiting-transports/models";
import { ResponseModal } from "./ResponseModal";

interface Props {
  close: () => void;
  unloading: Unloading;
}

export interface AddAwaitingTransportsFilters {
  page: number;
  status: string;
}

export const AddAwaitingTransportsToUnloading = ({ close, unloading }: Props) => {
  const { searchParams, setFilter } = useFilters<AddAwaitingTransportsFilters>({
    page: 1,
    status: "FINISHED",
  });

  const responseModal = useStateModal<AddAwaitingTransportToUnloadingResponse>();

  const {
    data: awaitingTransports,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = wmsAwaitingTransportsActions.useGetAwaitingTransports(searchParams);

  if (isLoading)
    return (
      <Modal
        close={close}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Dodaj awizacje do rozładunku {unloading.signature}
          </Typography>
        }
        width={1200}
      >
        <div className="pt-3 pb-5 px-3">
          <Spinner size={26} />
        </div>
      </Modal>
    );

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Dodaj awizacje do rozładunku {unloading.signature}
          </Typography>
        }
        width={1200}
      >
        <div>
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  return (
    <>
      <Modal
        close={close}
        isOpen
        title={
          <Typography fontSize="20" fontWeight="700">
            Dodaj awizacje do rozładunku {unloading.signature}
          </Typography>
        }
        width={1200}
      >
        <AddAwaitingTransportsToUnloadingContent
          responseModal={responseModal}
          unloading={unloading}
          awaitingTransports={awaitingTransports}
          close={close}
          isLoading={isLoading}
          isPreviousData={isPreviousData}
          pagination={pagination}
          setFilter={setFilter}
        />
      </Modal>
      {responseModal.isOpen && responseModal.state && (
        <ResponseModal close={responseModal.close} state={responseModal.state} />
      )}
    </>
  );
};
