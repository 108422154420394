import { filterFactory } from "components/utils/withFilters";
import { RoutePriority, routeStatusesOptions } from "api/routes/models";
import { ColorLabel } from "components/common/colorLabel";
import { TrackingStatus } from "api/call-center/routes/models";
import { createDepartureDateFilterOptions } from "utilities/createDepartureDateFilterOptions";

const trackingStatusOptions: Record<
  TrackingStatus,
  {
    label: string;
    content: JSX.Element;
  }
> = {
  AT_RISK: {
    label: "at risk",
    content: <ColorLabel className="mr-1" color="#e4b600" height={10} width={10} />,
  },
  OFF_TRACK: {
    label: "off track",
    content: <ColorLabel className="mr-1" color="#e26b77" height={10} width={10} />,
  },
  ON_TRACK: {
    label: "on track",
    content: <ColorLabel className="mr-1" color="#6de5b9" height={10} width={10} />,
  },
};

const routePriorityOptions: Record<
  RoutePriority,
  {
    label: string;
    content: JSX.Element;
  }
> = {
  HIGH: {
    label: "A",
    content: <ColorLabel className="mr-1" color="#e26b77" height={10} width={10} />,
  },
  MEDIUM: {
    label: "B",
    content: <ColorLabel className="mr-1" color="#ee9e4b" height={10} width={10} />,
  },
  LOW: {
    label: "C",
    content: <ColorLabel className="mr-1" color="#e4b700" height={10} width={10} />,
  },
};

export const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Select,
    label: "sortuj",
    name: "ordering",
    options: [
      { label: "Ostatnio edytowana", value: "-editedAt" },
      { label: "Ostatnio utworzona", value: "-created" },
    ],
  },
  {
    type: FilterType.SelectUser,
    kind: "user",
    label: "kierowca",
    multiple: true,
    name: "drivers",
    searchBy: "drivers",
  },
  {
    type: FilterType.SelectUser,
    kind: "car",
    label: "samochód",
    multiple: true,
    name: "cars",
    searchBy: "users",
  },
  {
    type: FilterType.Select,
    kind: "label",
    label: "wyjazd",
    multiple: true,
    name: "departureDate",
    options: createDepartureDateFilterOptions(),
  },
  {
    type: FilterType.Select,
    kind: "label",
    label: "status śledzenia",
    multiple: true,
    name: "trackingStatuses",
    options: Object.entries(trackingStatusOptions).map(([value, args]) => ({ value, ...args })),
  },
  {
    type: FilterType.Select,
    kind: "label",
    label: "status trasy",
    multiple: true,
    name: "statuses",
    options: routeStatusesOptions,
  },
  {
    type: FilterType.Select,
    kind: "label",
    label: "priorytet",
    multiple: true,
    name: "priorities",
    options: Object.entries(routePriorityOptions).map(([value, args]) => ({ value, ...args })),
  },
  {
    type: FilterType.SelectUser,
    label: "odpowiedzialny",
    kind: "user",
    multiple: true,
    name: "responsible",
    searchBy: "users",
  },
]);
