import { UnloadingTab } from "api/wms/models";

export const tabDict: Record<UnloadingTab, string> = {
  [UnloadingTab["all"]]: "Wszystkie",
  [UnloadingTab["in-progress"]]: "W trakcie",
  [UnloadingTab["not-started"]]: "Spodziewane",
  [UnloadingTab["finished"]]: "Zakończone",
};

export const getTabs = (): { label: string; name: string }[] => {
  return Object.entries(tabDict).map(([name, label]) => ({ label, name }));
};
