import { Picking, PickingStatus } from "api/wms/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { isTomorrow } from "date-fns";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { calculateTimeDifference } from "pages/wms/unloadingsList/utils/calculateTimeDifference";
import { dateFns } from "utilities";

interface Props {
  fontSize: TypographyProps["fontSize"];
  fontWeight: TypographyProps["fontWeight"];
  picking: Picking;
}

const statusDict: Record<
  PickingStatus,
  { label: string; variant: "success" | "outline" | "info" }
> = {
  NOT_STARTED: { label: "nie rozpoczęto", variant: "outline" },
  IN_PROGRESS: { label: "w trakcie", variant: "info" },
  FINISHED: { label: "zakończono", variant: "success" },
};

export const PickingStartDate = ({ fontSize, fontWeight, picking }: Props) => {
  if (picking.startedAt) {
    if (picking.status === PickingStatus.IN_PROGRESS || picking.status === PickingStatus.FINISHED) {
      <Tag label={statusDict[picking.status].label} variant={statusDict[picking.status].variant} />;
    }
    return (
      <Typography fontSize="12" fontWeight="700">
        {picking.startedAt ? dateFns.formatRelative(new Date(picking.startedAt)) : "---"}
      </Typography>
    );
  }

  if (!picking.estimatedTimeOfLoading)
    return (
      <Typography fontSize={fontSize} fontWeight={fontWeight}>
        ---
      </Typography>
    );

  if (isTomorrow(new Date(picking.estimatedTimeOfLoading)))
    return <Tag label="jutro" variant="outline" />;

  return (
    <Tag
      label={calculateTimeDifference(picking.estimatedTimeOfLoading).label}
      variant={calculateTimeDifference(picking.estimatedTimeOfLoading).variant}
    />
  );
};
