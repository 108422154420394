import { useShelfItems } from "api/new-production-plans/hooks";
import { ProductionPlan, SelectedSource, ShelfFilters } from "api/new-production-plans/models";
import { MINIMUM_PRODUCTION_PLAN_SHELF_HEIGHT } from "CONSTANTS";
import { useRedux, useSelector } from "hooks";
import React, { useEffect, useState } from "react";
import { queryString } from "utilities";
import localStyles from "./Shelf.module.css";
import { FiltersBar } from "./subcomponents/filtersBar/FiltersBar";
import { ShelfContent } from "./subcomponents/shelfContent/ShelfContent";
import { EmptyShelfContent } from "./subcomponents/shelfContent/subcomponents/emptyShelfContent/EmptyShelfContent";
import { SourcesBar } from "./subcomponents/sourcesBar/SourcesBar";

interface Props {
  productionPlan: ProductionPlan;
  selectedSources: SelectedSource[];
  shelfFilters: ShelfFilters;
  setKeyStrings: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  setSelectedSources: React.Dispatch<React.SetStateAction<SelectedSource[]>>;
}

export interface Filters {
  search: string;
  groupBy: "collection" | "departureDate" | "productCategories";
}

const defaultShelfHeight = 400;

function useShelfResize() {
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState(0);
  const storedHeight = useSelector(store => store.ui.shelfHeight);
  const [currentShelfHeight, setCurrentShelfHeight] = useState(storedHeight);
  const [dispatch, { ui }] = useRedux();

  const handleMouseUp = () => {
    dispatch(ui.setShelfHeight(currentShelfHeight));
    setIsDragging(false);
  };

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (isDragging) {
        const newHeight = storedHeight + (dragOffset - event.clientY);
        setCurrentShelfHeight(newHeight);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging, currentShelfHeight, dragOffset]);

  const toggleShelf = (): void => {
    if (currentShelfHeight > MINIMUM_PRODUCTION_PLAN_SHELF_HEIGHT) {
      setCurrentShelfHeight(MINIMUM_PRODUCTION_PLAN_SHELF_HEIGHT);
      dispatch(ui.setShelfHeight(MINIMUM_PRODUCTION_PLAN_SHELF_HEIGHT));
    } else {
      setCurrentShelfHeight(defaultShelfHeight);
      dispatch(ui.setShelfHeight(defaultShelfHeight));
    }
  };

  return {
    toggleShelf,
    setIsDragging,
    storedHeight,
    setDragOffset,
    currentShelfHeight,
  };
}

export const Shelf = ({
  productionPlan,
  selectedSources,
  setKeyStrings,
  shelfFilters,
  setSelectedSources,
}: Props) => {
  const [dispatch, { ui }] = useRedux();
  const { toggleShelf, setIsDragging, setDragOffset, currentShelfHeight } = useShelfResize();
  const [filters, setFilters] = useState<Filters>({
    search: "",
    groupBy: "collection",
  });

  const shelfFiltersParam = queryString.stringify({
    orders: JSON.stringify(shelfFilters.orders),
    orderGroups: JSON.stringify(shelfFilters.orderGroups),
    products: JSON.stringify(shelfFilters.products),
    routes: JSON.stringify(shelfFilters.routes),
  });
  const extensionFilters = queryString.stringify({
    groupBy: filters.groupBy,
    search: filters.search,
  });
  const search = queryString.merge([shelfFiltersParam, extensionFilters]);
  const areFiltersFilled = (): boolean => {
    return (
      Object.keys(shelfFilters.orders).length > 0 ||
      Object.keys(shelfFilters.orderGroups).length > 0 ||
      Object.keys(shelfFilters.products).length > 0 ||
      Object.keys(shelfFilters.routes).length > 0
    );
  };
  const { data: shelfItems, isLoading, error } = useShelfItems(
    {
      productionPlanId: productionPlan.id,
      search: search,
    },
    {
      enabled: areFiltersFilled(),
    },
  );

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsDragging(true);
    setDragOffset(event.clientY);
    dispatch(ui.setShelfHeight(currentShelfHeight));
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(ui.setShelfHeight(currentShelfHeight));
    setIsDragging(false);
  };

  return (
    <div className={localStyles.shelfContainer} style={{ height: `${currentShelfHeight}px` }}>
      <div className={localStyles.resize} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} />
      <div className="w-100">
        <SourcesBar
          currentShelfHeight={currentShelfHeight}
          selectedSources={selectedSources}
          setSelectedSources={setSelectedSources}
          slotsSummary={productionPlan.slotsSummary}
          sourcesSummary={productionPlan.sourcesSummary}
          toggleShelf={toggleShelf}
        />

        <FiltersBar
          filters={filters}
          productionPlanId={productionPlan.id}
          search={search}
          setFilters={setFilters}
          shelfFilters={shelfFilters}
        />

        {!shelfItems && !areFiltersFilled() && <EmptyShelfContent />}

        {shelfItems && (
          <ShelfContent
            error={error}
            isLoading={isLoading}
            productionPlan={productionPlan}
            search={search}
            selectedSources={selectedSources}
            shelfHeight={currentShelfHeight}
            shelfItems={shelfItems}
            shelfFilters={shelfFilters}
            setKeyStrings={setKeyStrings}
          />
        )}
      </div>
    </div>
  );
};
