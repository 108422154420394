import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import warehouseIcon from "assets/images/warehouseAlt2.svg";

export const warehouse: ModuleLink = {
  url: "warehouse",
  label: "Magazyn",
  icon: warehouseIcon,
  routing: () => <div></div>,
  isAccessible: false,
};
