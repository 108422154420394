import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiHat = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <g mask="url(#mask0_16763_308227)">
      <path
        d="M20.6667 19.6005H6.26667C6.26667 19.6005 6.75035 17.6005 6.26667 13.0005C5.75495 8.13379 4 4.13379 4 4.13379C4 4.13379 12.6667 4.13379 18 10.9338C20.4595 14.0696 20.6667 19.6005 20.6667 19.6005Z"
        fill="#B72D25"
        stroke="black"
        stroke-width="1.2"
      />
      <rect x="3.53359" y="19.267" width="19.8667" height="3.06667" rx="1.53333" fill="white" />
      <rect
        x="3.53359"
        y="19.267"
        width="19.8667"
        height="3.06667"
        rx="1.53333"
        fill="white"
        stroke="black"
        stroke-width="1.2"
      />
      <path
        d="M17.5133 14.5123C17.5133 14.5123 16.4852 11.2736 13.1533 9.08691"
        stroke="white"
        stroke-width="1.2"
        stroke-linecap="round"
        fill="white"
        stroke-linejoin="round"
      />
      <rect x="0.6" y="1.66641" width="4.93333" height="4.93333" rx="2.46667" fill="white" />
      <rect
        x="0.6"
        y="1.66641"
        width="4.93333"
        fill="white"
        height="4.93333"
        rx="2.46667"
        stroke="black"
        stroke-width="1.2"
      />
    </g>
  </SvgWrapper>
);
