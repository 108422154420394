import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { FieldKind } from "./models";
import { ApiFetcher } from "hooks/createApiQuery";
import { Pagination } from "api/types";
import { getCells, getColumns, getRows } from "api/warehouse/calls";
import { wmsRampApi } from "../ramps/api";

const fieldKindDict: Record<FieldKind, string> = {
  FIELD: "Miejsce",
  RACK: "Regał",
  RAMP: "Rampa",
  COLUMN: "Kolumna",
};

const fieldKindOptions: {
  value: FieldKind;
  text: string;
  type: MenuItemType;
}[] = Object.entries(fieldKindDict).map(([value, text]) => ({
  value: value as FieldKind,
  text,
  type: MenuItemType.TEXT,
}));

const assignFieldFetchSource: Record<FieldKind, (search: string) => ApiFetcher<Pagination<any>>> = {
  FIELD: getCells,
  RACK: getRows,
  RAMP: wmsRampApi.getRamps,
  COLUMN: getColumns,
};

export const wmsLayoutConstants = {
  fieldKindDict,
  fieldKindOptions,
  assignFieldFetchSource,
};
