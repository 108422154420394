import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiMapAlt = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <g mask="url(#mask0_22_15577)">
      <path d="M15 21L9 18.9L4.35 20.7C4.01667 20.8333 3.70833 20.7958 3.425 20.5875C3.14167 20.3792 3 20.1 3 19.75V5.75C3 5.53333 3.0625 5.34167 3.1875 5.175C3.3125 5.00833 3.48333 4.88333 3.7 4.8L9 3L15 5.1L19.65 3.3C19.9833 3.16667 20.2917 3.20417 20.575 3.4125C20.8583 3.62083 21 3.9 21 4.25V18.25C21 18.4667 20.9375 18.6583 20.8125 18.825C20.6875 18.9917 20.5167 19.1167 20.3 19.2L15 21ZM14 18.55V6.85L10 5.45V17.15L14 18.55ZM16 18.55L19 17.55V5.7L16 6.85V18.55ZM5 18.3L8 17.15V5.45L5 6.45V18.3Z" />
      <path d="M4 15L9 16L13.5 14L17.5 11.5H19.5" />
      <path d="M12.9998 14.0004C12.9998 14.0004 13.02 11.2462 12 10.0004C10.8678 8.61746 7.99037 10.1882 6 10.5003C4.93821 10.6669 4 9.50034 4 9.50034" />
      <path d="M15 12.5C15 12.5 16.6469 10.1874 18 9C18.7338 8.35604 20 7.5 20 7.5" />
      <path d="M10 8L11 7L12 8" />
      <path d="M12 9L13 8L14 9" />
      <path d="M16 16L17 15L18 16" />
      <path d="M12 17L13 16L14 17" />
      <path d="M17 17L18 16L19 17" />
    </g>
  </SvgWrapper>
);
