import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import homeIcon from "assets/images/homeLight.svg";
import { Dashboard } from "routes/inventory/Dashboard";

export const dashboard: ModuleLink = {
  url: "dashboard",
  label: "Pulpit",
  icon: homeIcon,
  routing: Dashboard,
};
