import { IndexDetails } from "api/manufacturing/indexes/models";
import styles from "./GeneralInformationTab.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { indexesActions } from "api/manufacturing/indexes/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { cx, getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Button } from "components/miloDesignSystem/atoms/button";

interface Props {
  indexDetails: IndexDetails;
}

interface StatLineProps {
  label: string;
  orderAction?: JSX.Element;
  typographyProps?: Partial<TypographyProps>;
  value: string | number;
}

export const IndexManufacturingStatistics = ({ indexDetails }: Props) => {
  const { data: indexStats, error, isLoading } = indexesActions.useIndexStatistics(
    String(indexDetails.id),
  );

  if (isLoading)
    return (
      <div className={styles.statistics}>
        <div className="d-flex align-items-center justify-content-center">
          <Spinner size={30} />
        </div>
      </div>
    );

  if (error)
    return (
      <div className={styles.statistics}>
        <div className="d-flex align-items-center justify-content-center">
          <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
        </div>
      </div>
    );

  assertIsDefined(indexStats);

  return (
    <div className={styles.statistics}>
      <div className="d-flex flex-column gap-2 pb-2">
        <StatLine
          label="dostępne"
          value={indexStats.available}
          typographyProps={{ fontSize: "20" }}
        />
        <StatLine label="zapotrzebowanie" value={indexStats.demand} />
        <StatLine label="zapotrzebowanie (trasy/grupy)" value={indexStats.demandForCollections} />
      </div>
      <div className="d-flex flex-column gap-2 pb-2">
        <StatLine label="sprzedane (ostatnie 7 dni)" value={indexStats.sold.last7Days} />
        <StatLine label="sprzedane (ostatnie 14 dni)" value={indexStats.sold.last14Days} />
        <StatLine
          label="zlecone do produkcji"
          orderAction={
            <Button className="text-uppercase" disabled size="small" variant="gray">
              Zleć
            </Button>
          }
          value={`${indexStats.production.inProduction}/${indexStats.production.requested}`}
        />
      </div>
    </div>
  );
};

const StatLine = ({ label, orderAction, typographyProps, value }: StatLineProps) => {
  return (
    <div className={cx(styles.statLine, "d-flex align-items-center pb-2 gap-2")}>
      <div className="d-flex align-items-center gap-2">
        <div className={styles.statsLabel}>
          <Typography color="neutralBlack64" fontSize="14" fontWeight="400" noWrap>
            {label}:
          </Typography>
        </div>
        <Typography
          color="neutralBlack88"
          fontSize={typographyProps?.fontSize || "16"}
          fontWeight={typographyProps?.fontWeight || "700"}
        >
          {value}
        </Typography>
      </div>
      {orderAction || <div className={styles.noBtnInStatLine} />}
    </div>
  );
};
