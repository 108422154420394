import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiScheme } from "components/miloDesignSystem/atoms/icons/MdiScheme";
import { WarehouseSchemaRouting } from "routes/wms/WarehouseSchemaRouting";

export const warehouseSchemaModule: ModuleLink = {
  url: "warehouse-schema",
  label: "Schemat magazynu",
  icon: MdiScheme,
  routing: WarehouseSchemaRouting,
  isNavigationDefaultCollapsed: true,
};
