import { Shipment, ShipmentStatus } from "api/shipping/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useToastr } from "hooks";
import { shipmentStatusToTagDict } from "../../useShippingShipmentsColumns";
import { shippingActions } from "api/shipping/actions";
import { MdiCancel } from "components/miloDesignSystem/atoms/icons/MdiCancel";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Link } from "components/miloDesignSystem/atoms/link/Link";

interface Props {
  close: () => void;
  shipment: Shipment;
}

export const PanelHeader = ({ close, shipment }: Props) => {
  const downloadLabel = shippingActions.useDownloadShippingLabel();
  const deleteMutation = shippingActions.useDeleteShippingShipment();
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <RightPanelHeader>
      <div className="d-flex align-items-center gap-2">
        <HeaderButton shipment={shipment} />
        <Tag {...shipmentStatusToTagDict[shipment.status]} />
        {isTriggerElementHidden && (
          <SlidingHeaderWrapper className="noWrap">
            <Link
              fontSize="14"
              fontWeight="700"
              theme="dark"
              to={`/orders/list/active/all?panelId=${shipment.order.id}`}
            >
              {shipment.order.signature}
            </Link>
          </SlidingHeaderWrapper>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end flex-1">
        <Tooltip title="Pobierz etykietę przesyłki">
          <IconButton
            icon={MdiQrCode}
            disabled={!shipment.shippingService || !shipment.trackingNumber}
            onClick={() => downloadLabel(shipment)}
            variant="transparent"
          />
        </Tooltip>
        <Menu
          disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
          menuItems={[
            {
              type: MenuItemType.TEXT,
              options: {
                color: "red100",
                disabled:
                  shipment.status === ShipmentStatus.CALLED_PICKUP_SUCCESS ||
                  deleteMutation.isLoading,
                iconStart: MdiDelete,
              },
              onClick: () => deleteMutation.mutate(shipment.id, { onSuccess: close }),
              text: "Usuń",
            },
          ]}
        />
        <span className="divider line-divider" />
        <Tooltip title="Zamknij panel">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};

const HeaderButton = ({ shipment }: { shipment: Shipment }) => {
  const toastr = useToastr();
  const { handleMutate } = shippingActions.useShippingShipment(shipment.id);

  const sendShipmentToExternalMutation = shippingActions.useSendShipmentsToExternal();
  const cancelShipments = shippingActions.useCancelShipments();

  const isShipmentOrdered = [
    ShipmentStatus.REGISTRATION_IN_PROGRESS,
    ShipmentStatus.CALLED_PICKUP_SUCCESS,
  ].includes(shipment.status);
  const isOrderingButtonDisabled = [
    ShipmentStatus.REGISTRATION_IN_PROGRESS,
    ShipmentStatus.CALLED_PICKUP_SUCCESS,
    ShipmentStatus.CANCELLATION_IN_PROGRESS,
    ShipmentStatus.CANCELED_SHIPMENT_FAILED,
  ].includes(shipment.status);
  const isCancellingButtonDisabled = [
    ShipmentStatus.CANCELED_SHIPMENT_SUCCESS,
    ShipmentStatus.CANCELLATION_IN_PROGRESS,
  ].includes(shipment.status);

  if (shipment.status === ShipmentStatus.CANCELED_SHIPMENT_SUCCESS) return null;

  return (
    <div className="d-flex align-items-center gap-2">
      <Button
        className="text-uppercase"
        disabled={
          isOrderingButtonDisabled ||
          (!shipment.shippingService?.isCalledPickupRequired &&
            shipment.status === ShipmentStatus.CREATE_SHIPMENT_SUCCESS)
        }
        isLoading={sendShipmentToExternalMutation.isLoading}
        onClick={() => {
          if (!shipment.shippingService) {
            toastr.open({
              type: "warning",
              title: "Przesyłka nie ma wybranego kuriera",
              text: "Przed zleceniem wybierz kuriera",
            });
            return;
          }
          if (!shipment.plannedPickupArrivalTime || !shipment.plannedPickupArrivalDate) {
            toastr.open({
              type: "warning",
              title: "Przesyłka nie ma wybranej daty odbioru przez kuriera",
              text: "Przed zleceniem wybierz datę odbioru przez kuriera",
            });
            return;
          }
          sendShipmentToExternalMutation.mutate({
            shipments: [shipment.id],
          });
        }}
        size="small"
        startIcon={MdiCheck}
        variant={isShipmentOrdered ? "success" : "outline"}
      >
        {isShipmentOrdered ? "Nadano przesyłkę" : "Nadaj przesyłkę"}
      </Button>
      <span className="divider line-divider" />
      <Button
        className="text-uppercase"
        disabled={isCancellingButtonDisabled}
        isLoading={cancelShipments.isLoading}
        onClick={() => {
          cancelShipments.mutate(
            {
              shipments: [shipment.id],
            },
            {
              onSuccess: () => {
                handleMutate({ status: ShipmentStatus.CANCELLATION_IN_PROGRESS });
              },
            },
          );
        }}
        size="small"
        startIcon={MdiCancel}
        variant="gray"
      >
        Anuluj przesyłkę
      </Button>
    </div>
  );
};
