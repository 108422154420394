import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiFabric = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      fillRule="evenodd"
      d="M6.6 6.6H3V3H6.6V6.6ZM10.2 6.6H6.6V10.2H3V13.8H6.6V17.4H3V21H6.6V17.4H10.2V21H13.8V17.4H17.4V21H21V17.4H17.4V13.8H21V10.2H17.4V6.6H21V3H17.4V6.6H13.8V3H10.2V6.6ZM10.2 10.2H6.6V13.8H10.2V17.4H13.8V13.8H17.4V10.2H13.8V6.6H10.2V10.2ZM10.2 10.2H13.8V13.8H10.2V10.2Z"
    />
  </SvgWrapper>
);
