import { ToggleHookState } from "hooks";
import { FinancesAmountSummary } from "api/trading-documents/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiPrintStatus } from "components/miloDesignSystem/atoms/icons/MdiPrintStatus";
import { MdiPrintConnect } from "components/miloDesignSystem/atoms/icons/MdiPrintConnect";
import { ErrorType } from "hooks/createApiQuery";
import { AmountSummaryBottomBar } from "pages/tradingDocuments/shared/components/amountSummaryBottomBar/AmountSummaryBottomBar";

interface Props {
  amountSummary: FinancesAmountSummary | null;
  amountSummaryModal?: ToggleHookState;
  currentlyPrintedReceiptsModal?: ToggleHookState;
  isLoading: boolean;
  error: ErrorType | null;
  lastPrintedReceiptsModal?: ToggleHookState;
}

export const BottomBarOptions = ({
  amountSummary,
  amountSummaryModal,
  currentlyPrintedReceiptsModal,
  isLoading,
  error,
  lastPrintedReceiptsModal,
}: Props) => {
  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <AmountSummaryBottomBar
            amountSummary={amountSummary}
            amountSummaryIsLoading={isLoading}
            error={error}
          />
          {amountSummaryModal && (
            <Button
              className="text-uppercase"
              disabled={isLoading || Boolean(error)}
              endIcon={MdiArrowForward}
              onClick={amountSummaryModal.open}
              size="small"
              variant="gray"
            >
              Szczegóły
            </Button>
          )}
        </div>
      </div>
      {lastPrintedReceiptsModal && currentlyPrintedReceiptsModal && (
        <div className="d-flex align-items-center gap-2">
          <span className="line-divider lineDividerMargin" />
          <IconButton
            icon={MdiPrintConnect}
            onClick={lastPrintedReceiptsModal.open}
            title="Ostatnio wydrukowane"
            variant="transparent"
          />
          <span className="line-divider lineDividerMargin" />
          <IconButton
            icon={MdiPrintStatus}
            onClick={currentlyPrintedReceiptsModal.open}
            title="Paragony w druku"
            variant="transparent"
          />
        </div>
      )}
    </>
  );
};
