import styled from "@emotion/styled";
import { ColorLabelProps, ColorPickerProps } from "./types";
import { colorPaletteLabels } from "CONSTANTS";
import { useState } from "react";
import { getAnyErrorKey, omit, yup } from "utilities";
import { AxiosError } from "axios";
import { useField, useFormikContext } from "formik";
import { Typography } from "../typography";
import { ColorPalette, colorPalette } from "../colorsPalette";
import { css } from "@emotion/core";
import { MDSAsyncType, MDSFormType } from "typeUtilities";
import { Assign } from "utility-types";

export const ColorPicker = ({
  selectedColor,
  onChange,
  disabled = false,
  error,
  width,
}: ColorPickerProps) => {
  return (
    <StyledColorPicker width={width}>
      {Object.entries(colorPaletteLabels).map(([backgroundColor, textColor]) => (
        <ColorLabel
          backgroundColor={backgroundColor}
          disabled={disabled}
          key={`${backgroundColor}-${textColor}`}
          isSelected={selectedColor === backgroundColor}
          onClick={() => {
            if (disabled) return;
            onChange({ backgroundColor, textColor });
          }}
          textColor={textColor}
        />
      ))}
      {error && typeof error === "string" && (
        <Typography className="mt-1" color="danger500" fontSize="10" fontWeight="500" noWrap>
          {error}
        </Typography>
      )}
    </StyledColorPicker>
  );
};

function AsyncColorPicker<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
>(
  props: MDSAsyncType<
    ColorPickerProps,
    { backgroundColor: string; textColor: string },
    TData,
    TError,
    TVariables,
    TContext
  >,
) {
  const [validationError, setValidationError] = useState("");
  const mutation = props.mutationHook();

  const mutate = (value: { backgroundColor: string; textColor: ColorPalette }) => {
    try {
      if (props.validation) {
        const validation = yup.object({
          temp: props.validation,
        });
        validation.validateSync({ temp: value });
        setValidationError("");
      }
      const data = props.transformQueryData({
        backgroundColor: value.backgroundColor,
        textColor: colorPalette[value.textColor],
      });
      mutation.mutate(data);
    } catch (error) {
      setValidationError(getAnyErrorKey(error as AxiosError));
    }
  };

  const propsToForward = omit(props, ["transformQueryData", "mutationHook"]);

  return (
    <ColorPicker
      {...propsToForward}
      disabled={mutation.isLoading || props.disabled}
      error={validationError || getAnyErrorKey(mutation.error as AxiosError)}
      onChange={e => mutate(e)}
    />
  );
}

function FormColorPicker<TForm>(
  props: Assign<
    Omit<MDSFormType<ColorPickerProps, TForm>, "name">,
    {
      backgroundColorName: string;
      textColorName: string;
      selectedColor?: never;
    }
  >,
) {
  const [backgroundColorField, backgroundColorMeta] = useField(props.backgroundColorName as string);
  const [textColorField, textColorMeta] = useField(props.textColorName as string);
  const propsToForward = omit(props, ["backgroundColorName", "textColorName"]);
  const { setFieldValue } = useFormikContext<TForm>();

  return (
    <ColorPicker
      {...propsToForward}
      {...backgroundColorField}
      {...textColorField}
      selectedColor={backgroundColorField.value}
      error={
        (backgroundColorMeta.touched && backgroundColorMeta.error) ||
        (textColorMeta.touched && textColorMeta.error)
      }
      onChange={(value: { backgroundColor: string; textColor: ColorPalette }) => {
        setFieldValue(props.backgroundColorName as string, value.backgroundColor);
        setFieldValue(props.textColorName as string, colorPalette[value.textColor]);
      }}
    />
  );
}

ColorPicker.Async = AsyncColorPicker;
ColorPicker.Form = FormColorPicker;

const StyledColorPicker = styled.div<Pick<ColorPickerProps, "width">>`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  width: ${props => `${props.width}px`};
`;

const ColorLabel = ({
  backgroundColor,
  disabled,
  isSelected,
  onClick,
  textColor,
}: ColorLabelProps) => {
  return (
    <StyledColorLabel disabled={disabled} isSelected={isSelected}>
      <StyledLabelContent backgroundColor={backgroundColor} disabled={disabled} onClick={onClick}>
        <Typography color={textColor} fontSize="10" fontWeight="700">
          M
        </Typography>
      </StyledLabelContent>
    </StyledColorLabel>
  );
};

const StyledColorLabel = styled.div<Pick<ColorLabelProps, "disabled" | "isSelected">>`
  border-radius: 4px;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  ${props =>
    props.isSelected &&
    css`
      background-color: var(--deepPurple100);
    `}
  ${props =>
    props.disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}
`;

const StyledLabelContent = styled.div<Pick<ColorLabelProps, "disabled" | "backgroundColor">>`
  background-color: ${props => props.backgroundColor};
  border: 1px solid var(--neutralWhite48);
  border-radius: 4px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.7;
    `}
`;
