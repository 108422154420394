import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import chairAlt from "assets/images/moduleNavigationIcons/chair_alt.svg";
import { Dashboard } from "routes/inventory/Dashboard";

export const indexes: ModuleLink = {
  url: "indexes",
  label: "Indeksy",
  icon: chairAlt,
  routing: Dashboard,
  isAccessible: false,
};
