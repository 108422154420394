import { externalManufacturingApi } from "api/external-manufacturing/api";
import { CreateManufacturingOrderItem } from "api/external-manufacturing/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { DrawerSection } from "components/utils/drawer";
import { useQuery, useStateModal } from "hooks";
import { useMutation } from "hooks/useMutation";
import { useNavigate } from "hooks/useNavigate";
import { CreateExternalManufacturingOrderModal } from "pages/orders/ordersList/createExternalManufacturingOrderModal/CreateExternalManufacturingOrderModal";
import { getAnyErrorKey } from "utilities";

interface Props {
  collection: "route" | "orderGroup";
  orders: number[];
}

export const CreateManufacturingOrderSection = ({ collection, orders }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const navigate = useNavigate();
  const createExternalManufacturingOrderModal = useStateModal<{
    manufacturingItems: CreateManufacturingOrderItem[];
  }>();

  const createExternalManufacturingOrderMutation = useMutation(
    externalManufacturingApi.postCreateManufacturingOrders,
    ({ toastr }) => ({
      onSuccess: payload => {
        createExternalManufacturingOrderModal.open({ manufacturingItems: payload });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  return (
    <DrawerSection className="pb-3" title="Zlecenia do producenta">
      <div className="d-flex align-items-center justify-content-between">
        <Button
          className="text-uppercase"
          onClick={() => {
            navigate(
              `/external-manufacturing/manufacturer-line-items/list/all?${collection}=${panelId}`,
            );
          }}
          size="small"
          endIcon={MdiArrowForward}
          variant="gray"
        >
          Przejdź do listy zleceń
        </Button>
        <Button
          className="text-uppercase"
          isLoading={createExternalManufacturingOrderMutation.isLoading}
          onClick={() => {
            createExternalManufacturingOrderMutation.mutate({ ids: orders });
          }}
          size="small"
          startIcon={MdiAdd}
          variant="gray"
        >
          Utwórz zlecenia do producenta
        </Button>
      </div>
      {createExternalManufacturingOrderModal.isOpen && (
        <CreateExternalManufacturingOrderModal
          close={createExternalManufacturingOrderModal.close}
          state={createExternalManufacturingOrderModal.state!}
        />
      )}
    </DrawerSection>
  );
};
