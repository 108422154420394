import { ApiFetcher } from "hooks/createApiQuery";
import { pickupPointsKeys } from "./keys";
import { Pagination } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { AddCourierToPickupPointDTO, PickupPointListItem } from "./models";
import { parsePatchData } from "utilities/parsePatchData";
import { Assign } from "utility-types";

const getPickupPoints = (search: string = ""): ApiFetcher<Pagination<PickupPointListItem>> => ({
  key: pickupPointsKeys.pickupPoint.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/shipping/pickup-points" + search,
    }),
});

const patchPickupPoint = (
  data: Assign<Partial<PickupPointListItem>, { id: PickupPointListItem["id"] }>,
) =>
  queryFetch<PickupPointListItem>({
    method: "PATCH",
    url: "/shipping/pickup-points/" + data.id,
    data: parsePatchData(data),
  });

const postPickupPoint = (data: AddCourierToPickupPointDTO) =>
  queryFetch<PickupPointListItem>({
    method: "POST",
    url: "/shipping/pickup-points",
    data,
  });

const deletePickupPoint = (id: PickupPointListItem["id"]) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/shipping/pickup-points/" + id,
  });

export const pickupPointsApi = {
  deletePickupPoint,
  getPickupPoints,
  patchPickupPoint,
  postPickupPoint,
};
