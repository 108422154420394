import { tradingDocumentsActions } from "api/trading-documents/actions";
import { MyPurchaseInvoiceToReview, TradingDocument } from "api/trading-documents/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiMail } from "components/miloDesignSystem/atoms/icons/MdiMail";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";

interface Props {
  selectedRows: TradingDocument[] | MyPurchaseInvoiceToReview[];
}

export const EmailNotifications = ({ selectedRows }: Props) => {
  const sendBulkEmailNotification = tradingDocumentsActions.useSendMultipleEmailNotifications();

  return (
    <Tooltip title="Wyślij e-mail z dokumentem handlowym">
      <IconButton
        icon={MdiMail}
        isLoading={sendBulkEmailNotification.isLoading}
        onClick={() => {
          sendBulkEmailNotification.mutate({
            tradingDocumentsIds: selectedRows.map(tradingDocument => tradingDocument.id),
          });
        }}
        variant="transparent"
        theme="dark"
      />
    </Tooltip>
  );
};
