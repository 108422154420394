import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { useUpdateTradingDocumentOptionsForPurchaseImport } from "api/trading-documents/hooks";
import { ExpectedPaymentForm, TradingDocumentStatus } from "api/trading-documents/models";
import { UUID } from "api/types";
import { cx } from "utilities";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  expectedPaymentForm: ExpectedPaymentForm;
  id: UUID;
  status: TradingDocumentStatus;
}

export const PurchaseInvoiceImportPaymentForm = ({ expectedPaymentForm, id, status }: Props) => {
  if (status === "CONFIRMED") {
    if (!Boolean(expectedPaymentForm))
      return <EmptyValue className="italic" fontSize="14" fontWeight="500" />;
    return (
      <Typography
        className={cx({
          italic: expectedPaymentForm !== "CASH" && expectedPaymentForm !== "ONLINE",
        })}
        fontSize="14"
        fontWeight="500"
        noWrap
      >
        {expectedPaymentForm === "CASH" || expectedPaymentForm === "ONLINE"
          ? tradingDocumentConstants.expectedPaymentFormDict[expectedPaymentForm]
          : expectedPaymentForm}
      </Typography>
    );
  }

  return (
    <TextField.Async
      mutationHook={useUpdateTradingDocumentOptionsForPurchaseImport}
      placeholder="Wpisz formę płatności"
      size="small"
      transformQueryData={expectedPaymentForm => ({
        id,
        expectedPaymentForm,
      })}
      value={
        expectedPaymentForm === "CASH" || expectedPaymentForm === "ONLINE"
          ? tradingDocumentConstants.expectedPaymentFormDict[expectedPaymentForm]
          : expectedPaymentForm
      }
    />
  );
};
