import { useEffect, useMemo, useRef } from "react";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import styles from "./LineChart.module.css";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { LinePath } from "@visx/shape";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";

interface DataType {
  label: string;
  value: number;
}

export interface LineChartProps {
  width: number;
  height: number;
  yAxisPadding?: number;
  data: { data: DataType[]; color: string }[];
  showLeftAxis?: boolean;
  showBottomAxis?: boolean;
  showRowGrid?: boolean;
  showColumnGrid?: boolean;
  rowGridStroke?: string;
  columnGridStroke?: string;
  maxXTicks?: number;
  defaultYScale?: number;
}

// accessors
const getValue = (d: { label: string; value: number }) => d.value;
const getLabel = (d: { label: string; value: number }) => d.label;

const verticalMargin = 30;
const xPadding = 0.3;

function LineChartRaw({
  width,
  height,
  yAxisPadding = 0,
  data,
  showLeftAxis = true,
  showBottomAxis = true,
  showRowGrid = true,
  showColumnGrid = false,
  rowGridStroke = "#e0e0e0",
  columnGridStroke = "#e0e0e0",
  defaultYScale = 0,
  maxXTicks = Infinity,
}: LineChartProps) {
  // bounds
  const rect = useRef<DOMRect>();

  const maxYValueLength = useMemo(
    () =>
      getTheLongestStringLength(
        data.flatMap(item => item.data).map(item => String(item.value || defaultYScale)),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );
  const letterWidth = 6;
  const constant = 20;
  const leftMargin = constant + letterWidth * maxYValueLength;
  const xMax = width - leftMargin;
  const yMax = height - verticalMargin;

  const containerRef = useRef<SVGSVGElement | null>();

  useEffect(() => {
    rect.current = containerRef.current?.getBoundingClientRect();
  }, []);

  const maxValue = useMemo(
    () => Math.max(...data.flatMap(item => item.data).map(getValue)) || defaultYScale,
    [data, defaultYScale],
  );

  const minValue = useMemo(() => {
    const min = Math.min(...data.flatMap(e => e.data).map(getValue));
    return min > 0 ? 0 : min;
  }, [data]);

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        domain: data[0].data.map(getLabel),
        padding: xPadding,
        paddingInner: 0.7,
      }),
    [xMax, data],
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [minValue, yAxisPadding + maxValue],
      }),
    [yMax, minValue, yAxisPadding, maxValue],
  );

  const xAxisScale = scaleBand<string>({
    domain: data[0].data.map(el => el.label),
    padding: xPadding,
  });

  const yAxisScale = scaleLinear<number>({
    domain: [minValue, maxValue],
  });

  xAxisScale.range([0, xMax]);
  yAxisScale.range([yMax, 0]);

  return (
    <>
      <svg
        ref={node => {
          containerRef.current = node;
        }}
        width={width}
        height={height}
      >
        <rect width={width} height={height} fill="url(#teal)" rx={14} />
        <Group top={verticalMargin / 4} left={leftMargin}>
          {showRowGrid && (
            <GridRows scale={yAxisScale} width={xMax} height={yMax} stroke={rowGridStroke} />
          )}

          {showLeftAxis && (
            <AxisLeft
              numTicks={6}
              scale={yAxisScale}
              stroke="#e0e0e0"
              tickClassName={styles.axisText}
            />
          )}
          {showBottomAxis && (
            <AxisBottom
              stroke="#e0e0e0"
              top={yMax}
              scale={xAxisScale}
              numTicks={data[0].data.length < maxXTicks ? data[0].data.length : maxXTicks}
              tickClassName={styles.axisText}
            />
          )}

          {data.map((serie, index) => (
            <LinePath<DataType>
              key={index}
              data={serie.data}
              x={d => xScale(getLabel(d)) ?? 0}
              y={d => yScale(getValue(d)) ?? 0}
              stroke={serie.color}
              strokeWidth={3}
              strokeOpacity={0.9}
              shapeRendering="geometricPrecision"
            />
          ))}
        </Group>
      </svg>
    </>
  );
}

const ChartSizeGuard = (props: Omit<LineChartProps, "width" | "height">) => (
  <ParentSize>
    {({ width, height }) => {
      if (width >= 10) {
        return <LineChartRaw width={width} height={height} {...props} />;
      }
      return null;
    }}
  </ParentSize>
);

export const LineChart = ChartSizeGuard;

function getTheLongestStringLength(valuesToMeasure: string[]) {
  return valuesToMeasure.reduce((acc, str) => {
    if (str.length > acc) {
      acc = str.length;
    }
    return acc;
  }, 0);
}
