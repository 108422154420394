import { ExternalManufacturerPartials, Partials, SearchHistory } from "./models";
import apiFetch, { tokenRefreshMiddleware } from "../../apiConnectors/fetchConnector";
import { utilsKeys } from "api/keys";
import { ApiFetcher } from "hooks/createApiQuery";
import { queryFetch } from "apiConnectors/queryFetch";
import { Pagination } from "api/types";
import { otherKeys } from "./keys";
import { DeepPartial } from "utility-types";

const api = tokenRefreshMiddleware(apiFetch);

export const getPartials = (search = "") =>
  api<Partials>({
    method: "GET",
    url: `/utils/partials${search}`,
  });

export const getCustomerPartials = (search = "") =>
  api<Partials>({
    method: "GET",
    url: `/utils/partials/customer-details${search}`,
  });

export const getExternalManufacturerPartials = (search: string = "") =>
  api<ExternalManufacturerPartials>({
    method: "GET",
    url: "/utils/partials/external-manufacturing" + search,
  });

export const getSearchHistory = (search = "") =>
  api<Pagination<SearchHistory>>({
    method: "GET",
    url: `/search-history/my${search}`,
  });

export const postSearchHistory = (data: unknown) =>
  queryFetch<void>({
    method: "POST",
    url: "/search-history/save-search",
    data,
  });

export const getConfiguration = (id: string | number): ApiFetcher<Partials["configuration"]> => ({
  key: otherKeys.configuration(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/owners/configurations/" + id,
    }),
});

export const patchConfiguration = (
  data: Partial<Omit<Partials["configuration"], "id">>,
  configId: number | string,
) =>
  api<Partials["configuration"]>({
    method: "PATCH",
    url: `/owners/configurations/${configId}`,
    data,
  });

export const patchConfigurationQuery = (
  data: DeepPartial<Omit<Partials["configuration"], "id">>,
  configId: number | string,
) =>
  queryFetch<Partials["configuration"]>({
    method: "PATCH",
    url: `/owners/configurations/${configId}`,
    data,
  });

export const getRegisterSalesAccount = (host: string, salesAccountId: number) =>
  api<{ verificationUri: string; interval: number; deviceCode: string }>({
    method: "GET",
    url: `/${host}/${salesAccountId}/register-app`,
  });

export const getModeDetails = (): ApiFetcher<{
  mode: string;
  instance: string;
  debug: boolean;
}> => ({
  key: utilsKeys.modeDetails(),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/utils/mode/details",
    }),
  globalOptions: {
    staleTime: Infinity,
    cacheTime: Infinity,
  },
});

export const getModeConfiguration = (): ApiFetcher<Record<string, any>> => ({
  key: utilsKeys.modeConfiguration(),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/utils/mode/configuration",
    }),
});
