import { withFilters } from "components/utils/withFilters";
import { filterList } from "./filterList";
import { RouteComponentProps } from "react-router";
import { TradingDocument, TradingDocumentTab } from "api/trading-documents/models";
import { useQuery } from "hooks";
import { getSearch } from "../shared/utils/getSearch";
import { useFinancesAmountSummary, useTradingDocuments } from "api/trading-documents/hooks";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common/pageHeader/PageHeader";
import { BottomBarOptions } from "../salesInvoicesLIst/components/BottomBarOptions";
import { getTabs } from "../shared/utils/getTabs";
import { correctionInvoices } from "components/common/moduleNavigation/moduleConfig/finances/routes/correctionInvoices";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useCorrectionsColumns } from "./useCorrectionsColumns";
import { RightPanel } from "./rightPanel/RightPanel";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { LedgerAccountSelector } from "../shared/tableToolbar/ledgerAccountSelector/LedgerAccountSelector";
import { WaproFakirXml } from "../shared/tableToolbar/waproFakirXml/WaproFakirXml";
import { TradingDocumentPdf } from "../shared/tableToolbar/tradingDocumentPdf/TradingDocumentPdf";
import { TradingDocumentXml } from "../shared/tableToolbar/tradingDocumentXml/TradingDocumentXml";
import { DownloadRevisor } from "../shared/tableToolbar/downloadRevisor/DownloadRevisor";
import { dateUtils } from "utilities";
import { startOfDay, subDays } from "date-fns";

interface HeaderProps {
  tab: TradingDocumentTab;
}

export const CorrectionsList = withFilters(
  filterList,
  ({ match }: RouteComponentProps<{ tab: TradingDocumentTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const search = getSearch({ invoiceType: "CORRECTION", query, tab, type: "INVOICE" });
    const {
      data: tradingDocuments,
      error,
      isLoading,
      isPreviousData,
      pagination,
    } = useTradingDocuments(search);
    const columns = useCorrectionsColumns();
    const tableMultiSelect = useTableMultiSelect({ rows: tradingDocuments });

    const tableProps = useTableFeatureConnector({
      rows: tradingDocuments,
      withDrawer: "correctionInvoices",
      withMultiSelect: tableMultiSelect,
      withPagination: { pagination, defaultPaginationVisibility: true },
    });

    return (
      <PageWrapper>
        <Header tab={tab} />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<TradingDocument>
            columns={columns}
            error={error}
            isLoading={isLoading || isPreviousData}
            uiSchema={{
              header: {
                backgroundColor: "grey25",
              },
              cell: {
                height: "27",
              },
            }}
            {...tableProps}
          />
          <RightPanel />
          <TableToolbar
            close={tableMultiSelect.clear}
            numberOfSelectedItems={tableMultiSelect.selectedRows.length}
          >
            <LedgerAccountSelector selectedRows={tableMultiSelect.selectedRows} />
            <WaproFakirXml selectedRows={tableMultiSelect.selectedRows} />
            <TradingDocumentPdf
              invoiceType="CORRECTION"
              selectedRows={tableMultiSelect.selectedRows}
            />
            <DownloadRevisor selectedRows={tableMultiSelect.selectedRows} />
            <TradingDocumentXml
              invoiceType="CORRECTION"
              selectedRows={tableMultiSelect.selectedRows}
              type="INVOICE"
            />
          </TableToolbar>
        </div>
      </PageWrapper>
    );
  },
);

const Header = ({ tab }: HeaderProps) => {
  const { query } = useQuery({ exclude: ["panelId", "page"] });
  const search = getSearch({ invoiceType: "CORRECTION", query, tab, type: "INVOICE" });
  const { data: amountSummary, isLoading, error } = useFinancesAmountSummary(search);

  return (
    <PageHeader
      additionalListParams={{
        type: "INVOICE",
        invoiceType: "CORRECTION",
      }}
      bottomBarButtons={
        <BottomBarOptions amountSummary={amountSummary} isLoading={isLoading} error={error} />
      }
      searchInput={{
        label: "Szukaj...",
        tags: [
          {
            name: "issueDateFrom",
            label: "data wystawienia (od)",
            value: dateUtils.formatDateToIso(startOfDay(subDays(new Date(), 7))),
          },
          {
            name: "issueDateTo",
            label: "data wystawienia (do)",
            value: dateUtils.formatDateToIso(new Date()),
          },
        ],
      }}
      tabs={{
        list: getTabs(),
        routesRoot: `finances/${correctionInvoices.url}`,
        urlSpan: "list",
      }}
      viewLabel="CORRECTION_INVOICES"
    />
  );
};
