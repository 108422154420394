import { RightPanelSection } from "components/utils/drawer";
import { NotificationsPanel } from "./components/NotificationsPanel";
import { OrderGroup } from "api/order-groups/models";
import { OrderList } from "pages/manufacturingNew/shared/orderList/OrderList";

interface Props {
  orderGroup: OrderGroup;
}

export const DeliverySection = ({ orderGroup }: Props) => {
  return (
    <>
      <RightPanelSection title="">
        <NotificationsPanel orderGroup={orderGroup} />
      </RightPanelSection>
      <OrderList resourceId={orderGroup.id} resourceType="orderGroup" />
    </>
  );
};
