import { indexesActions } from "api/manufacturing/indexes/actions";
import { IndexListItem } from "api/manufacturing/indexes/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiBoxRemove } from "components/miloDesignSystem/atoms/icons/MdiBoxRemove";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";

interface Props {
  indexes: IndexListItem[];
}

export const DetachPackages = ({ indexes }: Props) => {
  const detachPackagesMutation = indexesActions.useBulkUpddateCustomMadePackages();

  return (
    <div>
      <Tooltip title="Odłącz paczki indeksów od zamówienia">
        <IconButton
          icon={MdiBoxRemove}
          isLoading={detachPackagesMutation.isLoading}
          onClick={() =>
            detachPackagesMutation.mutate({
              indexesIds: indexes.map(index => index.id),
              isCustomMade: false,
            })
          }
          theme="dark"
          variant="transparent"
        />
      </Tooltip>
    </div>
  );
};
