import {
  getOrderGroup,
  getOrderGroupQuery,
  getOrderGroups,
  getOrderGroupsQuery,
  getRampLoadingStatusForOrderGroup,
  getSettledOrderGroup,
  getSettledOrderGroupQuery,
  orderGroupApi,
} from "api/order-groups/calls";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createPrimitiveHook, createPrimitivePaginatedHook } from "hooks/createPrimitiveHook";
import { useMutation } from "hooks/useMutation";
import { orderGroupsKeys } from "./keys";
import { assertIsDefined } from "utilities/assertIsDefined";

export const useOrderGroup = createPrimitiveHook(getOrderGroup);
export const useOrderGroups = createPrimitivePaginatedHook(getOrderGroups);
export const useSettledOrderGroup = createPrimitiveHook(getSettledOrderGroup);

export const useOrderGroupsQuery = createPaginatedApiQuery(getOrderGroupsQuery);
export const useOrderGroupQuery = createApiQuery(getOrderGroupQuery);
const useOrderGroupPositions = createApiQuery(orderGroupApi.getOrderGroupPositions);
export const useSettledOrderGroupQuery = createApiQuery(getSettledOrderGroupQuery);

export const useRampLoadingStatusForOrderGroups = createApiQuery(getRampLoadingStatusForOrderGroup);

const useOrderGroupPatchMutation = () => {
  return useMutation(orderGroupApi.patchOrderGroupQuery, ({ queryUtils }) => ({
    onMutate: args => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        orderGroupsKeys.orderGroups(),
        args.id,
        args,
      );
      const prevPanel = queryUtils.handleMutate(orderGroupsKeys.orderGroup(String(args.id)), args);
      return { prevList, prevPanel };
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollbackList(orderGroupsKeys.orderGroups(), onMutateReturn.prevList, id);
      queryUtils.rollback(orderGroupsKeys.orderGroup(String(id)), onMutateReturn.prevPanel, error);
    },
  }));
};

export const orderGroupActions = {
  useOrderGroupPatchMutation,
  useOrderGroupPositions,
};
