import { Table } from "components/miloDesignSystem/molecules/table";
import { useLabelListColumns } from "./useLabelListColumns";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { OrderLineItemDetails } from "api/external-manufacturing/models";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";

export const LabelList = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data, error, isLoading } = externalManufacturingActions.useManufacturerLineItem(panelId, {
    enabled: Boolean(panelId),
  });
  const columns = useLabelListColumns();

  return (
    <div className="my-2">
      <RightPanelSection padding="px-3 pt-2 pb-2">
        <Typography fontSize="14" fontWeight="600" color="neutralBlack48">
          Paczki
        </Typography>
      </RightPanelSection>

      <Table<OrderLineItemDetails["labels"][number]>
        rows={data?.labels || []}
        columns={columns}
        isLoading={isLoading}
        error={error}
        overrides={() => {
          return {
            hideHeader: !data?.labels.length,
            noResultComponent: <EmptySection label="Brak paczek" />,
          };
        }}
        uiSchema={comfortableListUiSchema}
      />
    </div>
  );
};
