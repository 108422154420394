import { InvoiceType, TradingDocument } from "api/trading-documents/models";
import customersIcon from "assets/images/copyrightWhite.svg";
import packageIcon from "assets/images/package.svg";
import { InfoLabel } from "components/common/infoLabel";
import retailIcon from "assets/images/accountBox.svg";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { MdiAccountCircle } from "components/miloDesignSystem/atoms/icons/MdiAccountCircle";
import { Tag } from "components/miloDesignSystem/atoms/tag";

export const BuyerSection = ({ tradingDocument }: { tradingDocument: TradingDocument }) => {
  if (!isSectionVisible(tradingDocument.invoiceType)) return null;

  return (
    <InfoLabel title="nabywca">
      <div className="d-flex align-items-center gap-2">
        {getKindTag(tradingDocument)}

        {tradingDocument.canBeFiscalized && tradingDocument.kind === "RETAIL" ? (
          <Typography color="grey400" fontSize="14" fontWeight="600">
            Potrzebny paragon
          </Typography>
        ) : (
          <TaxId tradingDocument={tradingDocument} />
        )}
      </div>
    </InfoLabel>
  );
};

const TaxId = ({ tradingDocument }: { tradingDocument: TradingDocument }) => {
  const taxId = (() => {
    if (tradingDocument.kind === "OWN_ENTITY") {
      return tradingDocument.recipientTaxId;
    }
    return tradingDocument.buyerTaxId;
  })();

  return (
    <div className="d-flex align-items-center gap-1">
      <Typography color="grey400" fontSize="14" fontWeight="600">
        NIP:
      </Typography>
      <Typography fontSize="14" fontWeight="600">
        {taxId || EMPTY_VALUE}
      </Typography>
    </div>
  );
};

function getKindTag(tradingDocument: TradingDocument) {
  const buyerLabel = getLabel(
    tradingDocument.buyerCompanyName,
    tradingDocument.buyerFirstName,
    tradingDocument.buyerLastName,
  );

  switch (tradingDocument.kind) {
    case "DROPSHIPPING": {
      return (
        <Tooltip title="Dropshipping">
          <Tag.WithCustomColor
            backgroundColor="turquoise200"
            textColor="neutralBlack100"
            startIcon={packageIcon}
            label={buyerLabel}
          />
        </Tooltip>
      );
    }
    case "OWN_ENTITY": {
      return (
        <Tooltip title="Podmiot własny">
          <Tag.WithCustomColor
            backgroundColor="magenta75"
            textColor="neutralBlack100"
            startIcon={MdiAccountCircle}
            label={getLabel(
              tradingDocument.recipientCompanyName,
              tradingDocument.recipientFirstName,
              tradingDocument.recipientLastName,
            )}
          />
        </Tooltip>
      );
    }
    case "RETAIL": {
      return (
        <Tooltip title="Klient detaliczny">
          <Tag.WithCustomColor
            backgroundColor="lime100"
            textColor="neutralBlack100"
            startIcon={retailIcon}
            label={buyerLabel}
          />
        </Tooltip>
      );
    }
    case "WHOLESALE": {
      return (
        <Tooltip title="Kontrahent">
          <Tag.WithCustomColor
            backgroundColor="deepPurple500"
            textColor="neutralWhite100"
            startIcon={customersIcon}
            label={buyerLabel}
          />
        </Tooltip>
      );
    }
    default:
      const exhaustiveCheck: never = tradingDocument.kind;
      console.error(`Unhandled trading document kind: ${exhaustiveCheck}`);
      return EMPTY_VALUE;
  }
}

function getLabel(companyName: string, firstName: string, lastName: string) {
  if (companyName) return companyName;

  if (firstName || lastName) return `${firstName ? `${firstName} ` : ""}${lastName}`;

  return EMPTY_VALUE;
}

function isSectionVisible(invoiceType: InvoiceType) {
  const shouldBeVisibleOn: InvoiceType[] = ["ADVANCE", "SALES", "PROFORMA"];

  return shouldBeVisibleOn.includes(invoiceType);
}
