import { customerActions } from "api/customers/actions";
import { GusDetails } from "api/customers/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { Modal } from "components/miloDesignSystem/atoms/modal/Modal";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { CommonError } from "components/utils";
import { Formik } from "formik";
import { PropsWithChildren } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";
import { getAnyErrorKey } from "utilities/utilities";

interface Props {
  close: () => void;
  onConfirmClick: (data: GusDetails) => void;
  taxId: string;
}

export const GusDataModal = ({ close, onConfirmClick, taxId }: Props) => {
  const { data, isLoading, error } = customerActions.useCustomerGusDetails(taxId);

  if (error)
    return (
      <ModalLayout close={close}>
        <div className="p-5 d-flex align-items-center flex-1 justify-content-center">
          <CommonError text={getAnyErrorKey(error)} status={error._httpStatus_} />
        </div>
      </ModalLayout>
    );

  if (isLoading)
    return (
      <ModalLayout close={close}>
        <div className="p-5 d-flex align-items-center flex-1 justify-content-center">
          <Spinner size={30} />
        </div>
      </ModalLayout>
    );

  assertIsDefined(data);

  const onConfirm = (values: GusDetails) => {
    onConfirmClick(values);
    close();
  };

  const initialValues: GusDetails = {
    fullName: data.fullName,
    name: data.name,
    taxId: data.taxId,
    postCode: data.postCode,
    city: data.city,
    street: data.street,
    businessRegisterNumber: data.businessRegisterNumber,
    ownerFirstName: data.ownerFirstName,
    ownerLastName: data.ownerLastName,
    phone: data.phone,
    email: data.email,
  };

  return (
    <ModalLayout close={close} taxId={data.taxId}>
      <Formik
        initialValues={initialValues}
        onSubmit={values => onConfirm(values)}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="px-3 py-2 d-flex flex-column gap-2">
              <TextField.Form<GusDetails>
                label="Pełna nazwa"
                name="fullName"
                placeholder="np. Milo sp. z o.o."
                size="small"
              />
              <TextField.Form<GusDetails>
                label="Nazwa skrócona"
                name="name"
                placeholder="np. Milo"
                size="small"
              />
              <TextField.Form<GusDetails>
                label="Imię właściciela lub przedstawiciela"
                name="ownerFirstName"
                size="small"
              />
              <TextField.Form<GusDetails>
                label="Nazwisko właściciela lub przedstawiciela"
                name="ownerLastName"
                size="small"
              />
              <TextField.Form<GusDetails>
                label="REGON"
                name="businessRegisterNumber"
                placeholder="000 000 000"
                size="small"
              />
              <TextField.Form<GusDetails>
                label="Adres siedziby"
                name="street"
                placeholder="np. ul. Wielka 25"
                size="small"
              />
              <div className="d-flex gap-2 w-100">
                <div className="w-10">
                  <TextField.Form<GusDetails>
                    label="Kod pocztowy"
                    name="postCode"
                    placeholder="00-000"
                    size="small"
                  />
                </div>
                <div className="w-100">
                  <TextField.Form<GusDetails>
                    label="Miasto"
                    name="city"
                    placeholder="np. Poznań"
                    size="small"
                  />
                </div>
              </div>
              <TextField.Form<GusDetails>
                label="Telefon"
                name="phone"
                placeholder="000000000"
                size="small"
              />
              <TextField.Form<GusDetails>
                label="Email [opcjonalnie]"
                name="email"
                placeholder="przyklad@przyklad.pl"
                size="small"
              />
              <div className="d-flex align-items-center gap-3 mt-3 pt-3 pb-2 borderTop">
                <Button
                  className="text-uppercase"
                  onClick={close}
                  size="small"
                  variant="transparent"
                >
                  Anuluj
                </Button>
                <Button className="text-uppercase" size="small" type="submit" variant="deepPurple">
                  Przenieś na formularz
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </ModalLayout>
  );
};

const ModalLayout = ({
  children,
  close,
  taxId,
}: PropsWithChildren<{ close: () => void; taxId?: string }>) => {
  return (
    <Modal
      close={close}
      isOpen
      title={
        <div className="d-flex align-items-baseline gap-3">
          <Typography fontSize="20" fontWeight="700">
            Pobieranie danych z GUS
          </Typography>
          <Typography fontSize="20" fontWeight="700">
            {taxId}
          </Typography>
        </div>
      }
      width={740}
    >
      <div>{children}</div>
    </Modal>
  );
};
