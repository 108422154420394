import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError } from "components/utils";
import { useFilters } from "hooks/useFilters";
import { Search } from "components/miloDesignSystem/molecules/search";
import { UUID } from "api/types";
import styles from "./OrderPackagesModal.module.css";
import { cx } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { orderActions } from "api/orders/actions";
import { NoResults } from "components/utils/noResults";
import { parseLocation } from "utilities/parseLocation";
import { orderApi } from "api/orders/api";

interface Props {
  close: () => void;
}

interface Filters {
  order: UUID | null;
  pageSize: string;
}

export const OrderPackagesModal = ({ close }: Props) => {
  const { searchParams, filters, setFilter } = useFilters<Filters>({
    order: null,
    pageSize: "999",
  });

  return (
    <Modal
      close={close}
      isOpen
      width={1020}
      title={
        <Typography fontSize="20" fontWeight="700">
          Znajdź zamówienie
        </Typography>
      }
    >
      <div className="mx-3 mb-3 mt-2 d-flex flex-column align-items-start">
        <div className="w-50 mb-3">
          <Search
            isNullable={false}
            onChange={location => setFilter("order", String(location.id))}
            fetcherFn={orderApi.getOrderWarehouseLocations}
          />
        </div>

        {Boolean(filters.order) && <Content filters={filters} searchParams={searchParams} />}

        <Button size="medium" variant="transparent" className="text-uppercase my-3" onClick={close}>
          Zamknij
        </Button>
      </div>
    </Modal>
  );
};

const Content = ({ filters, searchParams }: { filters: Filters; searchParams: string }) => {
  const {
    data,
    isLoading,
    error,
    isFetching,
    isPreviousData,
  } = orderActions.useSingleItemQuantityWarehouseLocations(searchParams, {
    enabled: Boolean(filters.order),
  });

  if (isLoading || (isFetching && isPreviousData)) {
    return (
      <div className="my-5 ml-3">
        <Spinner size={26} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="mt-3 py-5">
        <CommonError status={error._httpStatus_} />
      </div>
    );
  }

  if (!data.flatMap(packageGroup => packageGroup.packages).length) {
    return (
      <NoResults
        overwrites={{
          textWrapper: { className: "mb-0" },
          subHeader: { className: "mb-0" },
          background: { className: "h-100 mt-1 ml-3 my-2" },
          img: { style: { height: "90px" } },
        }}
      />
    );
  }

  return (
    <div className={cx("d-flex flex-wrap gap-2", styles.wrapper)}>
      {data.map(packageGroup => (
        <>
          {packageGroup.packages.map(_package => (
            <div key={_package.id} className={cx(styles.package, "p-3")}>
              <Typography fontSize="14" fontWeight="400" color="neutralBlack64" className="mb-1">
                paczka {_package.position} / {packageGroup.packages.length}
              </Typography>
              <Typography fontSize="16" fontWeight="700" className="mb-2" noWrap>
                {_package.name}
              </Typography>
              <Typography fontSize="64" fontWeight="700" className="mb-2">
                {parseLocation(_package.location).name || "---"}
              </Typography>
            </div>
          ))}
        </>
      ))}
    </div>
  );
};
