import { manufacturingActions } from "api/manufacturing/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { useToggle } from "hooks";
import { useFilters } from "hooks/useFilters";
import { SectionWrapper } from "pages/crm/customers/customerDetails/components/sectionWrapper/SectionWrapper";
import { useParams } from "react-router";
import { cx, getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { AddMaterialModal } from "./addMaterialModal/AddMaterialModal";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useRecipesColumns } from "./useRecipesColumns";
import folder from "assets/images/folder.svg";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../../../IndexDetails.module.css";
import { Recipe } from "api/manufacturing/models";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";

export const RecipesTab = () => {
  const { indexId } = useParams<{ indexId: string }>();
  const { searchParams } = useFilters<{ indexId: string }>({ indexId });
  const { data, error, isLoading } = manufacturingActions.useRecipes(searchParams);
  const addMaterialModal = useToggle();
  const columns = useRecipesColumns();
  const tableProps = useTableFeatureConnector({
    rows: data,
  });

  if (isLoading)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  if (!data.length) {
    return (
      <div className="d-flex flex-column align-items-center mt-4">
        <img alt="brak zleceń" src={folder} className={styles.emptyIcon} />

        <Typography fontSize="16" fontWeight="700" className="my-2">
          Jeszcze nie utworzono receptury
        </Typography>
        <Typography color="neutralBlack48" fontSize="14" fontWeight="500">
          Dodaj pierwszy materiał klikając w przycisk poniżej
        </Typography>
        <div className="pt-4">
          <Button
            onClick={addMaterialModal.open}
            size="medium"
            startIcon={MdiAdd}
            variant="deepPurple"
          >
            Dodaj materiał do receptury
          </Button>
        </div>
        {addMaterialModal.isOpen && (
          <AddMaterialModal close={addMaterialModal.close} position={data.length} />
        )}
      </div>
    );
  }

  assertIsDefined(data);

  return (
    <div className={cx("d-flex flex-column pb-3 flex-1")}>
      <div className="mt-2">
        <Table<Recipe>
          columns={columns}
          isLoading={false}
          error={null}
          uiSchema={{
            header: {
              backgroundColor: "neutralWhite100",
            },
            cell: {
              height: "34",
            },
          }}
          {...tableProps}
          overrides={() => {
            return {
              ...tableProps?.overrides?.(),
              row: row => {
                return {
                  className: cx(
                    {
                      dashedTableRow: row.isDeleted,
                    },
                    tableProps?.overrides?.().row?.(row).className,
                  ),
                };
              },
            };
          }}
        />
      </div>
      <Button
        size="small"
        variant="gray"
        onClick={addMaterialModal.open}
        className="ml-3 mt-2 text-uppercase"
        startIcon={MdiAdd}
      >
        Dodaj materiał do receptury
      </Button>
      {addMaterialModal.isOpen && (
        <AddMaterialModal close={addMaterialModal.close} position={data.length} />
      )}
    </div>
  );
};
