import { LongHeldPackageTab } from "api/unique-packages/models";
import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  tab: LongHeldPackageTab;
};

export const getSearch = ({ query, tab }: GetSearchProps) => {
  const tabsQuery = {
    isInWarehouse: tab === LongHeldPackageTab["in-warehouse"] ? "true" : "false",
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};
