import { TradingDocument } from "api/trading-documents/models";
import styles from "../../CreateDraftDocument.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { DownloadTradingDocumentPdf } from "pages/tradingDocuments/shared/components/downloadTradingDocumentPdf/DownloadTradingDocumentPdf";

interface Props {
  draftInvoice: TradingDocument;
}

export const HeaderSection = ({ draftInvoice }: Props) => {
  return (
    <div className={styles.contentHeader}>
      <div className="d-flex align-items-center gap-2 w-100">
        <Typography fontSize="20" fontWeight="700">
          {draftInvoice.signature}
        </Typography>
        <ConfirmTradingDocument tradingDocument={draftInvoice} />
        <DownloadTradingDocumentPdf tradingDocument={draftInvoice} />
      </div>
    </div>
  );
};
