import { TagProps } from "components/miloDesignSystem/atoms/tag";
import { differenceInHours, differenceInMinutes } from "date-fns";

type PickVariant<T extends string> = TagProps<T> & {
  variant: "outline" | "success" | "warning" | "danger";
};

export const calculateTimeDifference = (
  date: string | Date,
): { label: string; variant: PickVariant<string>["variant"] } => {
  const today = new Date();
  const minutesDiff = differenceInMinutes(new Date(date), today);
  const hoursDiff = differenceInHours(new Date(date), today);

  if (hoursDiff < 0) {
    return {
      label: `opóźnienie ${Math.abs(hoursDiff)}h ${Math.abs(minutesDiff) % 60}m`,
      variant: "danger",
    };
  } else if (minutesDiff < 0) {
    return {
      label: `opóźnienie ${Math.abs(minutesDiff) % 60}m`,
      variant: "danger",
    };
  } else if (hoursDiff < 1) {
    return { label: `pozostało ${minutesDiff}m`, variant: "warning" };
  } else if (hoursDiff <= 2.5) {
    return { label: `pozostało ${hoursDiff}h ${minutesDiff % 60}m`, variant: "outline" };
  } else {
    return { label: `pozostało ${hoursDiff}h ${minutesDiff % 60}m`, variant: "success" };
  }
};
