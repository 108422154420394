import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { isPast, parseISO } from "date-fns";
import { calculateDaysDifference, dateUtils } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  fontSize: TypographyProps["fontSize"];
  fontWeight: TypographyProps["fontWeight"];
  fulfillmentDate: string | null;
}

export const FulFillmentDate = ({ fulfillmentDate, fontSize, fontWeight }: Props) => {
  if (!fulfillmentDate) return <EmptyValue fontSize={fontSize} fontWeight={fontWeight} />;

  const daysDifference = calculateDaysDifference(parseISO(fulfillmentDate));

  if (daysDifference >= -3 && daysDifference < -1) {
    return (
      <Typography color="danger500" fontSize={fontSize} fontWeight={fontWeight}>
        {daysDifference} dni temu
      </Typography>
    );
  }

  if (daysDifference >= -1 && daysDifference <= 1) {
    return (
      <Typography
        color={daysDifference === -1 || daysDifference === 0 ? "danger500" : "warning500"}
        fontSize={fontSize}
        fontWeight={fontWeight}
      >
        {daysDifference === -1 ? "wczoraj" : daysDifference === 0 ? "dzisiaj" : "jutro"}
      </Typography>
    );
  }

  if (daysDifference > 1 && daysDifference <= 3) {
    return (
      <Typography color="warning500" fontSize={fontSize} fontWeight={fontWeight}>
        pozostało {daysDifference} dni
      </Typography>
    );
  }

  return (
    <Typography
      color={isPast(parseISO(fulfillmentDate)) ? "danger500" : "neutralBlack100"}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {dateUtils.formatDateToDisplay(fulfillmentDate)}
    </Typography>
  );
};
