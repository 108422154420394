import { UUID } from "api/types";
import { wmsReturnsActions } from "api/wms/returns/actions";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "./PackageToReturnModal.module.css";
import { MdiReturn } from "components/miloDesignSystem/atoms/icons/MdiReturn";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Button } from "components/miloDesignSystem/atoms/button";
import { wmsReturnConstants } from "constants/returns";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { usePackageToReturnDetailsColumns } from "./usePackageToReturnDetailsColumns";
import { NormalizedReturnPackageGroup } from "api/wms/returns/models";
import { useMemo } from "react";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";

interface Props {
  close: () => void;
  lineItemId: UUID;
}

export const PackageToReturnModal = ({ close, lineItemId }: Props) => {
  const { data: lineItem, error, isLoading, isFetching } = wmsReturnsActions.useGetPackageToReturn(
    lineItemId,
  );
  const handleDownloadReturnLineItemPdf = wmsReturnsActions.useDownloadReturnLineItemPdf();
  const columns = usePackageToReturnDetailsColumns();
  const normalizedRows: NormalizedReturnPackageGroup[] = useMemo(() => {
    if (!lineItem) return [];
    return lineItem.packagesGroups.map(packageGroup => {
      const subRows: Omit<NormalizedReturnPackageGroup, "subRows">[] = (
        packageGroup.packages || []
      ).map(packageRow => ({
        addedToStockAt: packageRow.addedToStockAt,
        collaborator: packageRow.collaborator,
        id: packageRow.id,
        location: packageRow.location,
        name: packageRow.name,
        stockStatus: packageRow.stockStatus,
      }));
      return {
        addedToStockAt: null,
        collaborator: null,
        id: packageGroup.id,
        location: null,
        name: packageGroup.name,
        stockStatus: null,
        subRows,
      } as NormalizedReturnPackageGroup;
    });
  }, [lineItem]);

  if (isLoading)
    return (
      <Modal
        close={close}
        isOpen
        width={750}
        title={
          <Typography fontSize="20" fontWeight="700">
            Szczegóły zamówienia
          </Typography>
        }
      >
        <div className="d-flex align-items-center justify-content-center py-5">
          <Spinner size={32} />
        </div>
      </Modal>
    );

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        width={750}
        title={
          <Typography fontSize="20" fontWeight="700">
            Szczegóły zamówienia
          </Typography>
        }
      >
        <div className="mt-3 py-5">
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  assertIsDefined(lineItem);

  return (
    <Modal
      close={close}
      isOpen
      width={750}
      title={
        <Typography fontSize="20" fontWeight="700">
          Szczegóły zamówienia {lineItem.signature}
        </Typography>
      }
    >
      <div className={styles.lineItemContainer}>
        <div className={styles.lineItemTitle}>
          <Typography fontSize="26" fontWeight="700">
            {lineItem.wmsReturn.signature}
          </Typography>
          <div className="d-flex align-items-center gap-1">
            <MdiReturn color="red300" size="24" />
            <Typography color="red300" fontSize="14" fontWeight="700">
              Zwrot
            </Typography>
          </div>
          {lineItem.wmsReturn.ramp && (
            <Tag label={lineItem.wmsReturn.ramp.name} variant="warning" />
          )}
        </div>
        <div className={styles.lineItemSection}>
          <div className={styles.lineItemHeader}>
            <div className={styles.lineItemHeaderGeneral}>
              <Typography fontSize="16" fontWeight="700">
                {lineItem.signature}
              </Typography>
              <Tag
                label={wmsReturnConstants.statusOptions[lineItem.status].label}
                variant={wmsReturnConstants.statusOptions[lineItem.status].variant}
              />
              {lineItem.address.countryCode && (
                <CountryFlag countryCode={lineItem.address.countryCode} />
              )}
            </div>
            <div className={styles.lineItemHeaderBuyer}>
              <Typography fontSize="12" fontWeight="700">
                {lineItem.buyer?.firstName || lineItem.buyer?.lastName
                  ? `${lineItem.buyer.firstName} ${lineItem.buyer.lastName}`
                  : EMPTY_VALUE}
              </Typography>
            </div>
            <Tooltip title="Pobierz etykiety">
              <IconButton
                icon={<MdiQrCode size="16" />}
                onClick={event => {
                  event.stopPropagation();
                  handleDownloadReturnLineItemPdf(
                    lineItem.id,
                    lineItem.signature,
                    lineItem.wmsReturn.id,
                  );
                }}
                variant="transparent"
              />
            </Tooltip>
          </div>
          <div className={styles.tableContainer}>
            <Table<NormalizedReturnPackageGroup>
              rows={normalizedRows}
              columns={columns}
              isLoading={isLoading || isFetching}
              error={error}
              uiSchema={comfortableListUiSchema}
            />
          </div>
        </div>
        <div>
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
