import { indexesActions } from "api/manufacturing/indexes/actions";
import { IndexListItem } from "api/manufacturing/indexes/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiBoxAdd } from "components/miloDesignSystem/atoms/icons/MdiBoxAdd";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";

interface Props {
  indexes: IndexListItem[];
}

export const BindPackages = ({ indexes }: Props) => {
  const bindPackagesMutation = indexesActions.useBulkUpddateCustomMadePackages();

  return (
    <div>
      <Tooltip title="Połącz paczki indeksów z zamówieniem">
        <IconButton
          icon={MdiBoxAdd}
          isLoading={bindPackagesMutation.isLoading}
          onClick={() =>
            bindPackagesMutation.mutate({
              indexesIds: indexes.map(index => index.id),
              isCustomMade: true,
            })
          }
          theme="dark"
          variant="transparent"
        />
      </Tooltip>
    </div>
  );
};
