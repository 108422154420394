import { IndexPackageListItem } from "api/manufacturing/indexes/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { MdiDelete } from "../../../../../components/miloDesignSystem/atoms/icons/MdiDelete";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { indexesActions } from "api/manufacturing/indexes/actions";
import { useQuery } from "hooks";

export const usePackagesColumns = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const removePackageMutation = indexesActions.useRemovePackageFromIndex();

  return useCreateTableColumns<IndexPackageListItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.name, {
          header: "nazwa",
          size: 400,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "14",
          },
        }),
        columnHelper.text(row => row.internalId, {
          header: "nazwa",
          size: 90,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "14",
            fontWeight: "600",
          },
        }),
        columnHelper.stretchContent,
        columnHelper.accessor(row => row, {
          id: "removePackage",
          header: " ",
          size: 30,
          cell: info => {
            const packageDetails = info.getValue();
            return (
              <Tooltip title="Odepnij paczkę">
                <IconButton
                  icon={MdiDelete}
                  onClick={event => {
                    event.stopPropagation();
                    removePackageMutation.mutate({
                      index: panelId,
                      package: packageDetails.id,
                    });
                  }}
                  variant="transparent"
                />
              </Tooltip>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};
