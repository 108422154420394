import descriptionIcon from "assets/images/descriptionLight.svg";
import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { tradingDocumentsTabDict } from "pages/tradingDocuments/shared/utils/getTabs";
import { PurchaseInvoices } from "routes/PurchaseInvoices";

export const purchaseInvoices: ModuleLink = {
  url: "purchases",
  label: "Faktury Zakupowe",
  icon: descriptionIcon,
  subSection: {
    renderer: <TabSubSection baseUrl="/finances/purchases/list" tabs={tradingDocumentsTabDict} />,
    isExpandedByDefault: false,
  },
  routing: PurchaseInvoices,
};
