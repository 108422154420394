import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { PanelHeader } from "./panelHeader/PanelHeader";
import { TitleSection } from "./titleSection/TitleSection";
import { GeneralInfoSection } from "./generalInfoSection/GeneralInfoSection";
import { manufacturingEmployeesActions } from "api/manufacturing/employees/actions";
import { TransactionsSection } from "./transactionsSection/TransactionsSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const {
    data: employee,
    error,
    isLoading,
  } = manufacturingEmployeesActions.useManufacturingEmployee(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!employee) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={employee.id}>
        <PanelHeader />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <TitleSection employee={employee} />
            <GeneralInfoSection employee={employee} />
            <TransactionsSection employee={employee} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
