import { TradingDocumentItem } from "api/trading-documents/models";
import { UUID } from "api/types";
import { Button } from "components/common";
import { AsyncInput } from "components/utils";
import styles from "../RightPanel.module.css";
import { useEditTradingDocumentItemValue } from "./hooks/useEditTradingDocumentItemValue";

interface Props {
  tradingDocumentId: UUID;
  tradingDocumentItem: TradingDocumentItem;
}

export const EditDiscountSection = ({ tradingDocumentId, tradingDocumentItem }: Props) => {
  const { editItemValueMutation, setShowInput, showInput } = useEditTradingDocumentItemValue(
    "discount",
    tradingDocumentId,
  );

  return (
    <div className="fw-700">
      {!showInput ? (
        <Button
          kind="transparent-black"
          onClick={event => {
            event.stopPropagation();
            setShowInput(true);
          }}
        >
          <div className="btnBase btnBaseSmall body-12">{tradingDocumentItem.discount}%</div>
        </Button>
      ) : (
        <div className="d-flex align-items-center gap-1">
          <AsyncInput
            disabled={editItemValueMutation.isLoading}
            onChange={value => {
              editItemValueMutation.mutate({
                id: tradingDocumentItem.id,
                discount: Number(value),
              });
            }}
            overwrites={{
              container: { className: styles.editVatRateInput },
              input: { className: styles.editVatRateInput },
            }}
            placeholder="rabat"
            type="number"
            value={tradingDocumentItem.discount}
          />
        </div>
      )}
    </div>
  );
};
