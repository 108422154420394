import { RoutePriority } from "api/routes/models";
import { BaseUser, User, UserWithInitials } from "api/users/models";
import { Status } from "api/routes/models";
import { StatusType } from "CONSTANTS";
import { ManufacturingSmsStatus } from "api/manufacturing/models";
import { ISODateTime } from "api/types";

export type TrackingStatus = "AT_RISK" | "OFF_TRACK" | "ON_TRACK";

export const trackingStatusDict: Record<TrackingStatus, string> = {
  AT_RISK: "At risk",
  OFF_TRACK: "Off track",
  ON_TRACK: "On track",
};

interface CallCenterRouteDriver {
  avatar: string | null;
  firstName: string;
  lastName: string;
}

type CallCenterRouteResponsible = Pick<User, "firstName" | "id" | "lastName"> & {
  avatar: string | null;
};

export interface CallCenterRouteListItem {
  color: string;
  delay: number;
  departureDate: string | null;
  departureTime: string | null;
  driver: CallCenterRouteDriver | null;
  editedAt: string | null;
  id: number;
  isPristine: boolean;
  isRecentlyEdited: boolean;
  lastModified: string | null;
  name: string;
  notReadMessages: number;
  priority: RoutePriority;
  priorityOrder: number | null;
  registrationNumber: string;
  responsible: CallCenterRouteResponsible | null;
  signature: string;
  status: Status;
  trackingStatus: TrackingStatus;
}

export interface CallCenterRouteDetails extends CallCenterRouteListItem {
  carBrand: string;
  editedAt: string | null;
  orders: any[];
  status: Status;
}

export interface CallCenterRouteOrderListItemDelivery {
  city: string;
  fistName: string;
  lastName: string;
  maximumCollectionHour: string | null;
  minimumCollectionHour: string | null;
  phone: string;
  plannedDeliveryTime: string | null;
  postCode: string;
  street: string;
}

type PaymentForm = "CASH" | "ONLINE" | "ON_DELIVERY" | string;

export type CallStatus = "NO_CALL_YET" | "ON_A_CALL" | "CALLED";

export interface OrderDriver extends BaseUser {
  isDeleted: boolean;
  phone: string;
  userId: number;
}

export const expectedPaymentFormDict: Record<PaymentForm, string> = {
  CASH: "Gotówka",
  ONLINE: "Przelew",
  ON_DELIVERY: "Gotówka",
};

export const callStatusDict: Record<CallStatus, string> = {
  NO_CALL_YET: "Nie dzwoniono",
  ON_A_CALL: "W trakcie rozmowy",
  CALLED: "Dzwoniono",
};

export interface CallCenterRouteOrderListItem {
  callStatus: CallStatus;
  calledDate: string | null;
  collectionDate: string | null;
  calledAt: string | null;
  customer: {
    color: string;
    name: string;
    textColor: string;
    id: number;
  };
  collectionDateAtConfirmation: string | null;
  customerConfirmationStatus: ManufacturingSmsStatus;
  customerConfirmationStatusModifiedAt: ISODateTime | null;
  customerConfirmationStatusModifiedBy: UserWithInitials | null;
  delaySmsSentTimestamp: string | null;
  delivery: CallCenterRouteOrderListItemDelivery;
  deliveryConfirmationDeadline: string | null;
  deliveryDateConfirmedBy: {
    id: number;
    avatar: string | null;
    firstName: string;
    lastName: string;
  } | null;
  deliveryDateConfirmationSource: "INTERNAL" | "EXTERNAL" | "";
  deliveryDateRejectedByFirstName: string;
  deliveryDateRejectedByLastName: string;
  deliveryDateRejectedByAvatar: string | null;
  deliveryDateRejectedTimestamp: string | null;
  deliveryDateConfirmTimestamp: string | null;
  callBeforeDelivery: boolean;
  timeOfCallBeforeDelivery: string | null;
  driver: OrderDriver | null;
  externalId: string;
  hasCalled: boolean;
  hasSmsSent: boolean;
  hasUnreadMessages: boolean;
  id: number;
  items: {
    id: number;
    amount: number;
    quantity: number;
    attributes: { attribute: string; value: string }[];
    product: { id: number; name: string; category: number };
  }[];
  isCallRequired: boolean;
  isDeliveryDateConfirm: boolean;
  isDeliveryDateRejected: boolean;
  maximumCollectionHourAtConfirmation: string;
  minimumCollectionHourAtConfirmation: string;
  name: string;
  onCall: boolean;
  payment: {
    id: number;
    source: PaymentForm | string;
    status: string;
    type: string;
  };
  pinnedToRouteDate: string | null;
  responsible: CallCenterRouteResponsible | null;
  route: {
    id: number;
    signature: string;
  } | null;
  routePosition: number;
  signature: string;
  smsSentTimestamp: string | null;
}

export interface RouteTrackingDayPosition {
  address: {
    city: string;
    postCode: string;
    street: string;
  };
  calculatedDeliveryTime: {
    min: string;
    max: string;
  };
  deliveryTime: {
    min: string;
    max: string;
  };
  confirmedDeliveryTime: {
    min: string;
    max: string;
  };
  delay: string;
  delayOfDelay: string;
  delaySms: {
    sent: boolean;
    sentDate: string | null;
  };
  deliveredDate: string | null;
  distance: number;
  id: number;
  isCurrent: boolean;
  isDelivered: boolean;
  newDeliveryTime: {
    min: string;
    max: string;
  } | null;
  phone: string;
  plannedDeliveryTime: string | null;
  plannedDeliveryTimeAfterDelay: string | null;
  routePosition: number;
  signature: string;
  status: StatusType;
  stopoverTime: number;
  trackingStatus: TrackingStatus;
}

export interface RouteTrackingDay {
  deliveryDate: string | null;
  positions: RouteTrackingDayPosition[];
}

export interface RouteTracking {
  car: {
    brand: string;
    id: number;
    registrationNumber: string;
  } | null;
  days: {
    current: RouteTrackingDay[];
    finished: RouteTrackingDay[];
    future: RouteTrackingDay[];
  };
  departureDate: string | null;
  departureDelay: number;
  departureTime: string | null;
  id: number;
  name: string;
  realDepartureDate: string | null;
  realDepartureTime: string | null;
  signature: string;
  status: Status;
  trackingStatus: TrackingStatus;
}

// DASHBOARD

export interface CallCenterDashboardDepartedRoute {
  car: {
    id: number;
    ownership: "INTERNAL" | "EXTERNAL";
    registrationNumber: string;
  };
  delay: number;
  driver: {
    avatar: string | null;
    firstName: string;
    lastName: string;
  } | null;
  id: number;
  name: string;
  signature: string;
  trackingStatus: TrackingStatus;
}

export const trackingStatusOnDashboardToPick: {
  label: string;
  value: TrackingStatus;
  icon?: string;
}[] = [
  { label: "pokaż zagrożone", value: "AT_RISK" },
  { label: "pokaż opóźnione", value: "OFF_TRACK" },
];

export type Tab = "departed" | "active" | "all";

export interface CallCenterDashboardIncompleteRoute {
  departureDate: string | null;
  id: number;
  ordersConfirmedCount: number;
  ordersNotConfirmedCount: number;
  signature: string;
}

export interface CallCenterDashboardLatestSmsMessages {
  body: string;
  id: number;
  isRead: boolean;
  order: {
    firstName: string;
    id: number;
    lastName: string;
    routePosition: number | null;
  };
  route: {
    id: number;
    signature: string;
  };
}
