import { statusStyleDictionary } from "api/manufacturingNew/models";
import { ManufacturingPlan, manufacturingPlanStatusDict } from "api/manufacturingNew/models";
import { InfoLabel } from "components/common/infoLabel";
import { StateLabel } from "components/common/stateLabel";
import { RightPanelSection } from "components/utils/drawer";
import { dateFns } from "utilities";
import styles from "../../ManufacturingPlansList.module.css";
import { statusIconDictionary } from "CONSTANTS";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";

export const GeneralInfoSection = ({
  manufacturingPlan,
}: {
  manufacturingPlan: ManufacturingPlan;
}) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection>
      <div className="d-flex align-items-center gap-2 mb-2" ref={context.triggerElementRef}>
        <div className="fs-24">{manufacturingPlan.signature}</div>
        <div>
          {manufacturingPlan.masterStage && (
            <StateLabel
              overwrites={{
                container: { className: styles.stageStatusLabel },
              }}
              kind="none"
              style={{
                backgroundColor: manufacturingPlan.masterStage.backgroundColor,
                color: manufacturingPlan.masterStage.textColor,
              }}
            >
              {manufacturingPlan.masterStage.name}
              <img src={statusIconDictionary[manufacturingPlan.status]} alt="Status" />
            </StateLabel>
          )}
        </div>
        <StateLabel className="lowercase" kind={statusStyleDictionary[manufacturingPlan.status]}>
          {manufacturingPlanStatusDict[manufacturingPlan.status]}
        </StateLabel>
        {manufacturingPlan.slotsSummary?.taken}/{manufacturingPlan.slotsSummary?.total}
      </div>
      <InfoLabel className="body-14 text-black-6" title="początek">
        {manufacturingPlan?.rangeOfDates?.firstDate.length > 0
          ? dateFns.format(new Date(manufacturingPlan.rangeOfDates?.firstDate), "dd.MM.yyyy")
          : "--"}
      </InfoLabel>
      <InfoLabel className="body-14 text-black-6" title="koniec">
        {manufacturingPlan?.rangeOfDates?.lastDate.length > 0
          ? dateFns.format(new Date(manufacturingPlan.rangeOfDates?.lastDate), "dd.MM.yyyy")
          : "--"}
      </InfoLabel>
    </RightPanelSection>
  );
};
