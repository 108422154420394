import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import inventoryIcon from "assets/images/inventory.svg";
import { InventoryChecks } from "routes/wms/InventoryChecks";

export const inventoryChecks: ModuleLink = {
  url: "inventory-checks",
  label: "Inwentaryzacje",
  icon: inventoryIcon,
  routing: InventoryChecks,
  isAccessible: false,
};
