import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { ConvertionSummary } from "./ExchangeRateForm";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";

export const useSummaryColumns = (rate: string | null) => {
  return useCreateTableColumns<ConvertionSummary>(({ columnHelper }) => {
    return [
      columnHelper.text(row => `${row.vatRate}%`, {
        header: "stawka VAT",
        size: 65,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row, {
        id: "totalWithoutTax",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            netto
          </Typography>
        ),
        size: 100,
        cell: info => {
          if (!rate) return <EmptyValue fontSize="14" />;

          const summaryPerVatRate = info.getValue();
          const convertionDiff = calculateConvertion(Number(rate), summaryPerVatRate)
            .totalWithoutTax;

          return (
            <AmountDisplay
              amount={convertionDiff ? String(convertionDiff) : "0.00"}
              className="w-100 text-right justify-content-end"
              currency="PLN"
              decimalTypographyProps={{
                color: "neutralBlack88",
                fontSize: "10",
                fontWeight: "500",
              }}
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "totalTax",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            kwota VAT
          </Typography>
        ),
        size: 100,
        cell: info => {
          if (!rate) return <EmptyValue fontSize="14" />;

          const summaryPerVatRate = info.getValue();
          const convertionDiff = calculateConvertion(Number(rate), summaryPerVatRate).totalTax;

          return (
            <AmountDisplay
              amount={convertionDiff ? String(convertionDiff) : "0.00"}
              className="w-100 text-right justify-content-end"
              currency="PLN"
              decimalTypographyProps={{
                color: "neutralBlack88",
                fontSize: "10",
                fontWeight: "500",
              }}
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "totalWithTax",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            brutto
          </Typography>
        ),
        size: 100,
        cell: info => {
          if (!rate) return <EmptyValue fontSize="14" />;

          const summaryPerVatRate = info.getValue();
          const convertionDiff = calculateConvertion(Number(rate), summaryPerVatRate).totalWithTax;

          return (
            <AmountDisplay
              amount={convertionDiff ? String(convertionDiff) : "0.00"}
              className="w-100 text-right justify-content-end"
              currency="PLN"
              decimalTypographyProps={{
                color: "neutralBlack88",
                fontSize: "10",
                fontWeight: "500",
              }}
            />
          );
        },
      }),
    ];
  });
};

const calculateConvertion = (rate: number, summary: ConvertionSummary) => {
  const vatRatePercentage = summary.vatRate / 100;
  const oldConvertedTotalWithTax = summary.convertedTotalWithTax
    ? Number(summary.convertedTotalWithTax)
    : 0;
  const totalAmountWithTaxToConvert = Number(summary.totalWithTax);

  const convertedTotalAmountWithTax = (
    Number((Number(totalAmountWithTaxToConvert.toFixed(2)) * rate).toFixed(2)) -
    Number(oldConvertedTotalWithTax.toFixed(2))
  ).toFixed(2);

  const convertedTotalTax = (
    (Number(convertedTotalAmountWithTax) * vatRatePercentage * 100) /
    (100 + vatRatePercentage * 100)
  ).toFixed(2);

  const convertedTotalAmountWithoutTax =
    Number(convertedTotalAmountWithTax) - Number(Number(convertedTotalTax).toFixed(2));

  return {
    totalTax: convertedTotalTax,
    totalWithoutTax: convertedTotalAmountWithoutTax,
    totalWithTax: convertedTotalAmountWithTax,
  };
};
