import { yup } from "utilities";

export const validationSchema = yup.object().shape({
  fullName: yup.string().required("Pełna nazwa jest wymagana"),
  name: yup.string().required("Nazwa skrócona jest wymagana"),
  taxId: yup.string().required("NIP jest wymagany"),
  postCode: yup.string().required("Kod pocztowy jest wymagany"),
  countryCode: yup.string().required("Kod kraju jest wymagany"),
  city: yup.string().required("Miasto jest wymagane"),
  street: yup.string().required("Adres siedziby jest wymagany"),
  phone: yup.string().required("Telefon jest wymagany"),
  salesAccount: yup
    .number()
    .typeError("Wybierz konto sprzedażowe")
    .required("Wybierz konto sprzedażowe"),
});
