import {
  MaterialAvailabilityStatus,
  MaterialStockUpdateDirection,
  MaterialsTab,
} from "api/manufacturing/materials/models";
import { TagProps } from "components/miloDesignSystem/atoms/tag";

const materialsListTabDict: Record<MaterialsTab, string> = {
  "in-use": "W użyciu",
  withdrawn: "Wycofane",
};

const materialAvailabilityStatusDict: Record<MaterialAvailabilityStatus, string> = {
  HIGH: "wystarczający",
  LOW: "niski",
  NA: "nie dotyczy",
  NONE: "brak",
  VERY_HIGH: "bardzo wysoki",
  VERY_LOW: "bardzo niski",
};

const materialAvailabilityStatusOptions: Record<
  MaterialAvailabilityStatus,
  {
    label: string;
    variant: TagProps<string>["variant"];
  }
> = {
  HIGH: { label: materialAvailabilityStatusDict.HIGH, variant: "success" },
  LOW: { label: materialAvailabilityStatusDict.LOW, variant: "info" },
  NA: { label: materialAvailabilityStatusDict.NA, variant: "deepPurple50" },
  NONE: { label: materialAvailabilityStatusDict.NONE, variant: "outline" },
  VERY_HIGH: { label: materialAvailabilityStatusDict.VERY_HIGH, variant: "warning" },
  VERY_LOW: { label: materialAvailabilityStatusDict.VERY_LOW, variant: "danger" },
};

const materialStockUpdateDirectionDict: Record<MaterialStockUpdateDirection, string> = {
  IN: "przyjęcie",
  OUT: "wydanie",
};

export const materialsConstants = {
  materialAvailabilityStatusDict,
  materialAvailabilityStatusOptions,
  materialsListTabDict,
  materialStockUpdateDirectionDict,
};
