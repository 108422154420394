import { createContext, useContext, memo, useState, useCallback, useMemo, useRef } from "react";
import * as React from "react";
import { DownloadFeedbackToastr } from "./DownloadFeedbackToastr";
import cuid from "cuid";
import styles from "./DownloadFeedbackToastr.module.css";
import { FileExtension } from "components/miloDesignSystem/atoms/fileDownloadHandler/types";

interface Props {
  children: React.ReactNode;
}

export interface ToastSubscription {
  id: string;
  type: FileExtension;
  progress: number;
  calculateProgress?: boolean;
}

export interface Toastr {
  open: (
    arg: Omit<ToastSubscription, "id">,
  ) => {
    updateProgress: (progress: number) => void;
    close: () => void;
    lazyClose: () => void;
    getProgress: () => number;
    setError: (toUpdate: Partial<Omit<ToastSubscription, "id">>) => void;
  };
}

export const context = createContext<Toastr>({
  open: arg => ({
    updateProgress: () => {},
    close: () => {},
    lazyClose: () => {},
    getProgress: () => 0,
    setError: () => {},
  }),
});

export const DownloadFeedbackController = memo(({ children }: Props) => {
  const progress = useRef(0);
  const [subscriptions, setSubscriptions] = useState<Record<string, ToastSubscription>>({});

  const updateToast = useCallback(
    (id: string, toUpdate: Partial<ToastSubscription>) =>
      setSubscriptions(s => {
        if (!s[id]) return s;
        return {
          ...s,
          [id]: { ...s[id], ...toUpdate },
        };
      }),
    [],
  );

  const timeouts = useRef<Record<string, NodeJS.Timeout>>({});
  const unsubscribe = useCallback((id: string) => {
    clearTimeout(timeouts.current[id]);
    return setSubscriptions(s => {
      const newState = { ...s };
      delete newState[id];
      return newState;
    });
  }, []);

  const lazyUnsubscribe = useCallback(
    (id: string) => {
      clearTimeout(timeouts.current[id]);
      timeouts.current[id] = setTimeout(() => {
        unsubscribe(id);
      }, 2000);
    },
    [unsubscribe],
  );

  const subscribe = useCallback(
    (subscription: Omit<ToastSubscription, "id">) => {
      const id = cuid();
      setSubscriptions(s => {
        return {
          ...s,
          [id]: { ...subscription, id },
        };
      });
      return {
        updateProgress: (newProgress: number) => {
          progress.current = newProgress;
          setSubscriptions(s => {
            return {
              ...s,
              [id]: { ...s[id], progress: newProgress },
            };
          });
        },
        close: () => unsubscribe(id),
        lazyClose: () => lazyUnsubscribe(id),
        getProgress: () => progress.current,
        setError: (toUpdate: Partial<ToastSubscription>) => updateToast(id, toUpdate),
      };
    },
    [lazyUnsubscribe, unsubscribe, updateToast],
  );

  const clearHideTimeout = useCallback((id: string) => {
    clearTimeout(timeouts.current[id]);
  }, []);

  const contextValue = useMemo(() => ({ open: subscribe }), [subscribe]);

  return (
    <>
      <context.Provider value={contextValue}>{children}</context.Provider>
      <div className={styles.container}>
        {Object.values(subscriptions).map(subscription => (
          <DownloadFeedbackToastr
            className="mb-2"
            key={subscription.id}
            type={subscription.type}
            progress={subscription.progress}
            clearHideTimeout={() => clearHideTimeout(subscription.id)}
            lazyUnsubscribe={() => lazyUnsubscribe(subscription.id)}
            close={() => unsubscribe(subscription.id)}
            calculateProgress={subscription.calculateProgress === true}
          />
        ))}
      </div>
    </>
  );
});

export const useDownloadFeedbackToastr = () => {
  const toastr = useContext(context);
  return useMemo(
    () => ({
      open: ({ type, calculateProgress = true }: Omit<ToastSubscription, "id" | "progress">) => {
        return toastr.open({ type, progress: 0, calculateProgress });
      },
    }),
    [toastr],
  );
};
