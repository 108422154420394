import { withFilters } from "components/utils/withFilters";
import { filterList } from "./filterList";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { RouteComponentProps, useLocation } from "react-router";
import { MyImportInvoicesTab, MyPurchaseInvoiceToReview } from "api/trading-documents/models";
import { useQuery } from "hooks";
import { getSearch } from "./utils/getSearch";
import { useMyPurchaseInvoicesToReview } from "api/trading-documents/hooks";
import { useMyInvoicesColumns } from "./useMyInvoicesColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useNavigate } from "hooks/useNavigate";
import { PageHeader } from "components/common";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { dateUtils } from "utilities";
import { startOfDay, subDays } from "date-fns";
import { getMyImportTabs } from "pages/tradingDocuments/shared/utils/getTabs";
import { myInvoicesToConfirm } from "components/common/moduleNavigation/moduleConfig/finances/routes/myInvoicesToConfirm";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { RightPanel } from "./rightPanel/RightPanel";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { LedgerAccountSelector } from "pages/tradingDocuments/shared/tableToolbar/ledgerAccountSelector/LedgerAccountSelector";
import { WaproFakirXml } from "pages/tradingDocuments/shared/tableToolbar/waproFakirXml/WaproFakirXml";
import { DownloadRevisor } from "pages/tradingDocuments/shared/tableToolbar/downloadRevisor/DownloadRevisor";

export const MyInvoicesToConfirm = withFilters(
  filterList,
  ({ match }: RouteComponentProps<{ tab: MyImportInvoicesTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const search = getSearch({ query, tab });
    const {
      data: tradingDocuments,
      error,
      isLoading,
      isPreviousData,
      pagination,
    } = useMyPurchaseInvoicesToReview(search);
    const columns = useMyInvoicesColumns();
    const tableMultiSelect = useTableMultiSelect({ rows: tradingDocuments });
    const tableProps = useTableFeatureConnector({
      rows: tradingDocuments,
      withDrawer: "myInvoices",
      withMultiSelect: tableMultiSelect,
      withPagination: { pagination, defaultPaginationVisibility: true },
    });

    return (
      <PageWrapper>
        <Header />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<MyPurchaseInvoiceToReview>
            columns={columns}
            error={error}
            isLoading={isLoading || isPreviousData}
            uiSchema={{
              header: {
                backgroundColor: "grey25",
              },
              cell: {
                height: "27",
              },
            }}
            {...tableProps}
          />
          <RightPanel />
          <TableToolbar
            close={tableMultiSelect.clear}
            numberOfSelectedItems={tableMultiSelect.selectedRows.length}
          >
            <LedgerAccountSelector selectedRows={tableMultiSelect.selectedRows} />
            <WaproFakirXml selectedRows={tableMultiSelect.selectedRows} />
            <DownloadRevisor selectedRows={tableMultiSelect.selectedRows} />
          </TableToolbar>
        </div>
      </PageWrapper>
    );
  },
);

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <PageHeader
      bottomBarButtons={
        <Button
          className="text-uppercase"
          onClick={() => {
            navigate(`/finances/import-invoices/check`, {
              fromMyInvoices: `${location.pathname}${location.search}`,
            });
          }}
          size="small"
          startIcon={MdiCheck}
          variant="gray"
        >
          Żądanie zatwierdzenia
        </Button>
      }
      searchInput={{
        label: "Szukaj...",
        tags: [
          {
            name: "issueDateFrom",
            label: "data wystawienia (od)",
            value: dateUtils.formatDateToIso(startOfDay(subDays(new Date(), 7))),
          },
          {
            name: "issueDateTo",
            label: "data wystawienia (do)",
            value: dateUtils.formatDateToIso(new Date()),
          },
        ],
      }}
      tabs={{
        list: getMyImportTabs(),
        routesRoot: `finances/${myInvoicesToConfirm.url}`,
        urlSpan: "list",
      }}
      viewLabel="MY_IMPORT_PURCHASE_INVOICES_LIST"
    />
  );
};
