import { PropsWithChildren } from "react";
import styled from "@emotion/styled";

interface Props {
  className?: string;
  width?: string;
  minHeight?: number;
}
const StyledWrapper = styled.div<Pick<Props, "minHeight" | "width">>`
  background-color: var(--neutralWhite100);
  box-shadow: 0px 6px 16px -6px var(--neutralBlack12);
  border-radius: 4px;
  width: ${props => props.width};
  min-height: ${props => props.minHeight}px;
  height: auto;
`;

export const SectionWrapper = ({
  children,
  className,
  width,
  minHeight,
}: PropsWithChildren<Props>) => {
  return (
    <StyledWrapper width={width} minHeight={minHeight} className={className}>
      {children}
    </StyledWrapper>
  );
};
