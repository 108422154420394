import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiRouteBlack } from "components/miloDesignSystem/atoms/icons/MdiRouteBlack";
import { RouteListRouting } from "routes/manufacturingNew/RouteListRouting";

export const routeList: ModuleLink = {
  url: "routes",
  label: "Trasy",
  icon: MdiRouteBlack,
  routing: RouteListRouting,
};
