import { indexesActions } from "api/manufacturing/indexes/actions";
import { PostIndexAssignmentPayload } from "api/manufacturing/indexes/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Select } from "components/miloDesignSystem/molecules/select";
import { Formik } from "formik";
import { useSelector } from "hooks";
import { useParams } from "react-router";
import { cx } from "utilities";
import { validationSchema } from "./validationSchema";

interface Props {
  close: () => void;
}

export const AddExternalIndex = ({ close }: Props) => {
  const handleSubmit = indexesActions.usePostIndexAssignment(close);
  const { indexId } = useParams<{ indexId: string }>();
  const customers = useSelector(store => store.partials.customers);
  const customersOptions = customers.map(customer => ({
    text: customer.name,
    type: MenuItemType.TEXT,
    value: customer.id,
  }));

  const initialValues: PostIndexAssignmentPayload = {
    customer: null,
    externalCode: "",
    externalName: "",
    index: Number(indexId),
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj numer obcy wariantu
        </Typography>
      }
      width={630}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="mx-3">
              <div className="mb-4">
                <Select.Form<PostIndexAssignmentPayload>
                  items={customersOptions}
                  label="Kontrahent"
                  name="customer"
                  textFieldProps={{ size: "default" }}
                />
              </div>
              <div className="mb-4">
                <TextField.Form<PostIndexAssignmentPayload>
                  label="Nazwa"
                  name="externalName"
                  placeholder="Wpisz nazwę"
                />
              </div>
              <div className="mb-4">
                <TextField.Form<PostIndexAssignmentPayload>
                  label="Numer obcy"
                  name="externalCode"
                  placeholder="Wpisz numer obcy"
                />
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 p-3 borderTop">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Dodaj
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
