import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { marketplaceStatusesKeys } from "./keys";
import { MarketplaceStatusAttempt, MarketplaceStatusItem } from "./models";
import { ManufacturingStage } from "api/manufacturing/schemas/models";

const getMarketplaceStatusesAttempts = (
  search: string = "",
): ApiFetcher<Pagination<MarketplaceStatusAttempt>> => ({
  key: marketplaceStatusesKeys.attempts(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/marketplace-statuses/attempts" + search,
    }),
});

const getMarketplaceStatusesItems = (
  search: string = "",
): ApiFetcher<Pagination<MarketplaceStatusItem>> => ({
  key: marketplaceStatusesKeys.attempts(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/marketplace-statuses/items" + search,
    }),
});

const postMarketplaceStatusesRetry = (data: { statusEventId: UUID }) =>
  queryFetch<ManufacturingStage>({
    method: "POST",
    url: `/marketplace-statuses/items/${data.statusEventId}/retry`,
  });

export const marketplaceStatuses = {
  getMarketplaceStatusesAttempts,
  postMarketplaceStatusesRetry,
  getMarketplaceStatusesItems,
};
