import { Route } from "api/routes/models";
import { RightPanelSection } from "components/utils/drawer";
import CopyToClipboardComponent from "react-copy-to-clipboard";
import { useRoutePatchMutation } from "api/logistics/routes/hooks";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { MdiCopyAll } from "components/miloDesignSystem/atoms/icons/MdiCopyAll";
import { MdiLink } from "components/miloDesignSystem/atoms/icons/MdiLink";

interface Props {
  route: Route;
}

export const TitleSection = ({ route }: Props) => (
  <RightPanelSection>
    <div className="d-flex align-items-center gap-2">
      <Typography fontSize="26" fontWeight="700" noWrap>
        {route.signature}
      </Typography>

      <Tooltip title="Skopiuj sygnaturę">
        <CopyToClipboardComponent text={route.signature}>
          <IconButton variant="transparent" icon={MdiCopyAll} />
        </CopyToClipboardComponent>
      </Tooltip>

      <Tooltip title="Skopiuj link do strony">
        <CopyToClipboardComponent text={window.location.href}>
          <IconButton variant="transparent" icon={MdiLink} />
        </CopyToClipboardComponent>
      </Tooltip>

      <TextField.Async
        label="Nazwa trasy"
        placeholder="Wpisz nazwę trasy"
        size="small"
        value={route.name}
        mutationHook={useRoutePatchMutation}
        transformQueryData={name => ({ id: route.id, toUpdate: { name } })}
        containerClassName="flex-1"
      />
    </div>
  </RightPanelSection>
);
