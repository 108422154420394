import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { ManufacturingUnitGroupPriority } from "api/manufacturing/units/models";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { useQuery } from "hooks";
import { useDrawer } from "hooks/useDrawer";

export const PanelHeader = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { close } = useDrawer("manufacturingStageNormalList");
  const { data: unitDetails } = manufacturingUnitsActions.useListUnitDetails(panelId);
  const { isTriggerElementHidden } = useScrollableContext();
  const priorityOptions = Object.entries(
    manufacturingStagesConstants.manufacturingUnitGroupPriorityDict,
  ).map(([value, details]) => ({
    icon: details.light.icon,
    text: details.light.label,
    type: MenuItemType.ICON,
    value,
  }));

  if (!unitDetails) return null;

  return (
    <RightPanelHeader>
      {isTriggerElementHidden ? (
        <SlidingHeaderWrapper className="gap-2">
          <Typography
            color={
              manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[unitDetails.priority]
                .light.color
            }
            fontSize="16"
            fontWeight="700"
            noWrap
          >
            {unitDetails.manufacturingItem.signature}
          </Typography>
          <Typography color="neutralBlack48" fontSize="14" fontWeight="700">
            {unitDetails.manufacturingWorkingUnitGroup?.signature}
          </Typography>
        </SlidingHeaderWrapper>
      ) : (
        <Typography color="neutralBlack48" fontSize="14" fontWeight="700">
          {unitDetails.manufacturingWorkingUnitGroup?.signature}
        </Typography>
      )}
      <div className="d-flex align-items-center justify-content-end gap-1">
        <Select.Async
          items={priorityOptions}
          mutationHook={manufacturingUnitsActions.useListUnitPatch}
          transformQueryData={priority => ({
            id: unitDetails.id,
            toUpdate: {
              priority: priority as ManufacturingUnitGroupPriority,
            },
          })}
          selected={unitDetails.priority}
        />
        <FileDownloadHandler
          factoryFn={() =>
            manufacturingFileFactory.manufacturingItemPdf(
              [unitDetails.manufacturingItem.id],
              unitDetails.manufacturingItem.signature,
            )
          }
          type="pdf"
        >
          {({ download, isLoading }) => (
            <Tooltip title="Pobierz etykietę">
              <IconButton
                icon={MdiQrCode}
                isLoading={isLoading}
                onClick={event => {
                  event.stopPropagation();
                  download();
                }}
                variant="transparent"
              />
            </Tooltip>
          )}
        </FileDownloadHandler>
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};
