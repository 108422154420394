import { ManufacturingEmployeeDetails } from "api/manufacturing/employees/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  employee: ManufacturingEmployeeDetails;
}

export const TitleSection = ({ employee }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection>
      <div ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {employee.firstName} {employee.lastName}
        </Typography>
      </div>
    </RightPanelSection>
  );
};
