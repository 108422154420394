import { PropsWithChildren } from "react";
import styles from "./PageWrapper.module.css";
import { FilterBar } from "../pageHeader/components/filterBar/FilterBar";

export const PageWrapper = ({ children }: PropsWithChildren<{}>) => (
  <div className={styles.pageWrapper}>
    {children}
    <FilterBar />
  </div>
);
