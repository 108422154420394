import { CountryCode } from "CONSTANTS";
import { ManufacturingUnitListViewDetails } from "api/manufacturing/units/models";
import { orderActions } from "api/orders/actions";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";
import { InfoLabel } from "components/common/infoLabel";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError } from "components/utils";
import { RightPanelSection } from "components/utils/drawer";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  unitDetails: ManufacturingUnitListViewDetails;
}

export const BuyerSection = ({ unitDetails }: Props) => {
  const { data: order, error, isLoading } = orderActions.useManufacturingOrderDetails(
    unitDetails.manufacturingItem.orderId,
    {
      enabled: Boolean(unitDetails.manufacturingItem && unitDetails.manufacturingItem.orderId),
    },
  );

  if (isLoading)
    return (
      <RightPanelSection title="Nabywca">
        <Spinner size={26} />
      </RightPanelSection>
    );

  if (error)
    return (
      <RightPanelSection title="Nabywca">
        <CommonError status={error._httpStatus_} />
      </RightPanelSection>
    );

  if (!order) return null;

  return (
    <RightPanelSection title="Nabywca">
      <div className="d-flex flex-column gap-2 pb-2">
        <Typography fontSize="20" fontWeight="700" noWrap>
          {`${order.delivery.firstName} ${order.delivery.lastName}`}
        </Typography>
        <Typography color="neutralBlack88" fontSize="14" fontWeight="600" noWrap>
          {order.delivery.street}
        </Typography>
        <Typography color="neutralBlack88" fontSize="14" fontWeight="600" noWrap>
          {order.delivery.postCode} {order.delivery.city}
        </Typography>
        <Typography color="neutralBlack88" fontSize="14" fontWeight="600" noWrap>
          {order.delivery.phone}
        </Typography>
        <Typography color="neutralBlack88" fontSize="14" fontWeight="600" noWrap>
          NIP: {order.invoice?.taxId || EMPTY_VALUE}
        </Typography>
      </div>
      <div className="pb-2">
        <InfoLabel title="kraj wysyłki">
          {order.delivery.countryCode ? (
            <CountryFlag countryCode={order.delivery.countryCode as CountryCode} />
          ) : (
            <EmptyValue fontSize="16" fontWeight="700" />
          )}
        </InfoLabel>
      </div>
      <div className="pb-2">
        <InfoLabel title="konto sprzedażowe">
          <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
            {order.salesAccount?.name}
          </Typography>
        </InfoLabel>
      </div>
    </RightPanelSection>
  );
};
