import { queryString } from "utilities";
import { AwaitingTransportTab } from "./getTabs";
import { AwaitingTransportStatus } from "api/wms/awaiting-transports/models";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  tab: AwaitingTransportTab;
};

const tabToStatusDict: Record<AwaitingTransportTab, AwaitingTransportStatus> = {
  [AwaitingTransportTab.ALL]: "" as AwaitingTransportStatus,
  [AwaitingTransportTab.FINISHED]: AwaitingTransportStatus.FINISHED,
  [AwaitingTransportTab.IN_PROGRESS]: AwaitingTransportStatus.IN_PROGRESS,
};

export const getSearch = ({ query, tab }: GetSearchProps) => {
  const tabsQuery = {
    status: tabToStatusDict[tab],
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};
