import { manufacturersActions } from "api/manufacturers/actions";
import { CustomOrderedMaterialColumn } from "api/manufacturing/units/models";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { cx, queryString } from "utilities";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "./OrderMaterial.module.css";

export const useOrderMaterialColumns = () => {
  const { data: manufacturers } = manufacturersActions.useManufacturers(
    queryString.stringify({
      kind: "SUPPLIER",
    }),
  );
  const manufacturersOptions = manufacturers?.map(manufacturer => ({
    text: manufacturer.name,
    type: MenuItemType.TEXT,
    value: manufacturer.id,
  }));

  return useCreateTableColumns<CustomOrderedMaterialColumn>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.manufacturingWorkingUnitSignature, {
          header: "sygnatura zlecenia",
          size: 200,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "16",
          },
        }),
        columnHelper.text(row => row.name, {
          header: "nazwa",
          size: 200,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "16",
          },
        }),
        columnHelper.text(row => `${row.quantity} ${row.unit.shortName}`, {
          header: "ilość materiału",
          size: 150,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "14",
            fontWeight: "500",
          },
        }),
        columnHelper.accessor(row => row, {
          header: "dostawca",
          size: 250,
          cell: info => {
            const customOrderedMaterial = info.getValue();
            return (
              <div className={cx("position-relative", styles.disabledManufacturerSelect)}>
                <Select.Form
                  disabled={customOrderedMaterial.isAlreadyOrdered}
                  items={
                    Boolean(customOrderedMaterial.manufacturers.length)
                      ? customOrderedMaterial.manufacturers.map(manufacturer => ({
                          text: `${manufacturer.name} ${manufacturer.isMain && "(domyślny)"}`,
                          type: MenuItemType.TEXT,
                          value: manufacturer.id,
                        }))
                      : manufacturersOptions
                  }
                  textFieldProps={{ size: "small" }}
                  name={`orders[${info.row.index}].manufacturer`}
                />
              </div>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};
