import { customerActions } from "api/customers/actions";
import { useFilters } from "hooks/useFilters";
import { Title } from "pages/crm/customers/customerDetails/components/Title";
import { FiltersSection } from "./subcomponents/FiltersSection";
import { Table } from "components/miloDesignSystem/molecules/table";
import { CustomerEmployee } from "api/customers/models";
import { useUsersColumns } from "./useUsersColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useParams } from "react-router";

export interface UserFilters {
  customer: string;
  ordering: string;
  page: number;
  search: string;
}

export const UsersTab = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { searchParams, filters, setFilter } = useFilters<UserFilters>({
    customer: customerId,
    ordering: "",
    page: 1,
    search: "",
  });
  const {
    data: employees,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = customerActions.useCustomerEmployees(searchParams);
  const columns = useUsersColumns();
  const tableProps = useTableFeatureConnector({
    rows: employees,
  });

  return (
    <div className="pb-3 p-3 d-flex flex-column flex-1 overflow-hidden">
      <Title className="pb-2">Lista użytkowników</Title>
      <FiltersSection filters={filters} setFilter={setFilter} />
      <Table<CustomerEmployee>
        columns={columns}
        error={error}
        isLoading={isPreviousData || isLoading}
        pagination={(pagination?.count || 0) >= 30 ? pagination : undefined}
        onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
        uiSchema={{
          header: {
            backgroundColor: "neutralWhite100",
          },
          cell: {
            height: "36",
          },
        }}
        {...tableProps}
      />
    </div>
  );
};
