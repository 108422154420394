import { useQuery, useSelector } from "hooks";
import { WarehouseSchema } from "./WarehouseSchema";
import { useDefaultFilters } from "hooks/useDefaultFilters";

export const WarehouseSchemaView = () => {
  const halls = useSelector(store => store.partials.halls);
  const { query } = useQuery();

  useDefaultFilters({
    hallId: halls?.[0]?.id,
  });

  if (!Boolean(query["hallId"])) return null;

  return <WarehouseSchema />;
};
