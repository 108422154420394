import { Picking, PickingsTabs } from "api/wms/models";
import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { getSearch } from "./utils/getSearch";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { usePickings } from "api/wms/hooks";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { pickings as pickingsApi } from "components/common/moduleNavigation/moduleConfig/wms/routes/pickings";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { getTabs } from "./utils/getTabs";
import { usePickingsColumns } from "./hooks/usePickingsColumns";
import { RightPanel } from "./rightPanel/RightPanel";
import { WarehouseFillingStatus } from "../shared/components/WarehouseFillingStatus";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";

export const PickingsList = ({ match }: RouteComponentProps<{ tab: PickingsTabs }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const { data: pickings, error, isPreviousData, isLoading, pagination } = usePickings(search);
  const columns = usePickingsColumns();
  const tableProps = useTableFeatureConnector({
    rows: pickings,
    withDrawer: "wmsPickings",
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj wśród pickingów",
          tags: [],
        }}
        createButton={{
          alt: "utwórz",
          img: darkPlusIcon,
          label: "Utwórz picking",
          onClick: () => {},
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `wms/${pickingsApi.url}`,
          urlSpan: "list",
        }}
        bottomBarButtons={<WarehouseFillingStatus />}
        viewLabel="WMS_PICKINGS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<Picking>
          columns={columns}
          isLoading={isLoading || isPreviousData}
          error={error}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
      </div>
    </PageWrapper>
  );
};
