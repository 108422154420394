import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiSaleWithdrawn = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path d="M20.1228 6.86105L14.3069 13H15.55C15.9167 13 16.2542 12.9083 16.5625 12.725C16.8708 12.5417 17.1167 12.2833 17.3 11.95L20.1228 6.86105Z" />
    <path d="M8.79693 13H8.1L7.13538 14.7539L5.57816 16.3976C5.47665 16.285 5.38393 16.1566 5.3 16.0125C4.91667 15.3542 4.9 14.7 5.25 14.05L6.6 11.6L3 4H1V2H4.25L5.2 4H17.3232L15.4285 6H6.15L8.55 11H10.6917L8.79693 13Z" />
    <path d="M10.5175 17L12.4122 15H19V17H10.5175Z" />
    <path d="M6.02001 21.7473L8.69086 18.9281C8.89695 19.2437 9 19.601 9 20C9 20.55 8.80417 21.0208 8.4125 21.4125C8.02083 21.8042 7.55 22 7 22C6.63929 22 6.31262 21.9158 6.02001 21.7473Z" />
    <path d="M15.5875 21.4125C15.9792 21.8042 16.45 22 17 22C17.55 22 18.0208 21.8042 18.4125 21.4125C18.8042 21.0208 19 20.55 19 20C19 19.45 18.8042 18.9792 18.4125 18.5875C18.0208 18.1958 17.55 18 17 18C16.45 18 15.9792 18.1958 15.5875 18.5875C15.1958 18.9792 15 19.45 15 20C15 20.55 15.1958 21.0208 15.5875 21.4125Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2263 3.18799L4.22632 22.188L2.77441 20.8125L20.7744 1.8125L22.2263 3.18799Z"
    />
  </SvgWrapper>
);
