import { Partials } from "api/other/models";
import { Shipment } from "api/shipping/models";

export function getDefaultShippingServiceFieldsFromPartials(
  shippingService: Partials["shippingShippingServices"][number],
): Partial<Shipment> {
  return {
    ambroService: shippingService.ambroDefaultService,
    ambroAdditionalServices: shippingService.ambroDefaultAdditionalServices,
    glsAdditionalServices: shippingService.glsDefaultAdditionalServices,
  };
}
