import { queryFetch } from "apiConnectors/queryFetch";
import { StartingPointDTO } from "./models";
import { PartialOf } from "../../typeUtilities";
import { StartingPoint } from "api/other/models";

const postStartingPoint = (data: StartingPointDTO) =>
  queryFetch<void>({
    method: "POST",
    url: "/starting-points/items",
    data,
  });

const patchStartingPoint = ({
  id,
  toUpdate,
}: {
  toUpdate: PartialOf<StartingPoint>;
  id: StartingPoint["id"];
}) =>
  queryFetch<StartingPoint>({
    method: "PATCH",
    url: "/starting-points/items/" + id,
    data: toUpdate,
  });

export const startingPointsApi = {
  postStartingPoint,
  patchStartingPoint,
};
