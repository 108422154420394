import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiEmergencyHome } from "components/miloDesignSystem/atoms/icons/MdiEmergencyHome";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { MdiTaskAlt } from "components/miloDesignSystem/atoms/icons/MdiTaskAlt";

export interface FailedResponse {
  success: { message: string } | null;
  failed: { message: string; reasons: { message: string; signatures: string[] }[] } | null;
}

interface Props {
  title: string;
  data: FailedResponse | null;
  close: () => void;
}

export const FailedResponseModal = ({ data, close, title }: Props) => {
  if (!data) return null;

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          {title}
        </Typography>
      }
      width={580}
    >
      <div className="d-flex flex-column p-3">
        <div className="mb-3 py-2 d-flex flex-column gap-3">
          {data.success && (
            <div className="d-flex align-items-center gap-2">
              <MdiTaskAlt color="success400" size="20" />
              <Typography fontSize="14" fontWeight="600" noWrap>
                {data.success?.message}
              </Typography>
            </div>
          )}
          {data.failed && (
            <div>
              <div className="d-flex align-items-center gap-2">
                <MdiEmergencyHome color="danger400" size="20" />
                <Typography fontSize="14" fontWeight="600" noWrap>
                  {data.failed.message}
                </Typography>
              </div>
              <div className="ml-4 pl-1">
                {data.failed.reasons.map(reason => (
                  <>
                    {Boolean(reason.signatures.length) ? (
                      <div className="mt-1">
                        <Typography fontSize="14" fontWeight="700" noWrap>
                          {reason.message}:
                        </Typography>
                        <Typography fontSize="14" fontWeight="600">
                          {reason.signatures.join(", ")}
                        </Typography>
                      </div>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="pt-3 gap-3 d-flex align-items-center borderTop">
          <Button
            className="text-uppercase"
            onClick={event => {
              event.stopPropagation();
              close();
            }}
            size="small"
            theme="light"
            variant="gray"
          >
            zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
