import { useRoutePatchMutation } from "api/logistics/routes/hooks";
import { Route } from "api/routes/models";
import { RightPanelSection } from "components/utils/drawer";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  route: Route;
}

export const NoteSection = ({ route }: Props) => (
  <RightPanelSection className="pb-5">
    <Typography fontSize="14" fontWeight="600" color="neutralBlack48" className="mb-2">
      Ogólne uwagi do trasy
    </Typography>
    <TextField.Async
      value={route.note}
      placeholder="Wpisz uwagi"
      mutationHook={useRoutePatchMutation}
      transformQueryData={note => ({
        id: route.id,
        toUpdate: { note },
      })}
    />
  </RightPanelSection>
);
