import { PieChart, PieChartData } from "components/utils/charts/pieChart/PieChart";
import { ReportDetails } from "api/finances/models";
import { CURRENCY_TYPE } from "CONSTANTS";
import { ColorPalette, colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import styles from "./styles.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styled from "@emotion/styled";
import { omit } from "utilities";

interface Props {
  data: ReportDetails[];
  label: string;
}

const currencyToColorDict: Record<CURRENCY_TYPE, ColorPalette> = {
  CZK: "indigo500",
  DKK: "orange200",
  EUR: "cyan200",
  GBP: "indigo200",
  HUF: "info200",
  PLN: "deepPurple300",
  RON: "green200",
  USD: "blue200",
};

export const BalancePieChart = ({ data, label }: Props) => {
  const formattedData: PieChartData[] = (() => {
    const aggregatedData = data.reduce((acc, reportDetails) => {
      Object.keys(omit(reportDetails, ["grossPln", "netPln", "vatOssPln", "vatPln"])).forEach(k => {
        const key = k as keyof ReportDetails;
        if (key === "currency") return;
        if (reportDetails[key]) {
          const currency: CURRENCY_TYPE = reportDetails[key]!.currency || "PLN";

          Object.assign(acc, {
            [currency]:
              (acc?.[currency] || 0) +
              Number(reportDetails[`${key as "gross" | "net" | "vatOss" | "vat"}Pln`]!.amount),
          });
        }
      });
      return acc;
    }, {} as Record<CURRENCY_TYPE, number>);

    return Object.entries(aggregatedData).map(([currency, value]) => ({
      color: colorPalette[currencyToColorDict[currency as CURRENCY_TYPE]],
      label: currency,
      value,
    }));
  })();

  const sum = formattedData.reduce((acc, e) => {
    return acc + e.value;
  }, 0);

  const formattedDataToDisplay = (() => {
    if (!formattedData.length || sum === 0) {
      return [
        {
          color: colorPalette.grey50,
          label: "",
          value: 100,
        },
      ];
    }
    return formattedData;
  })();
  return (
    <div className={styles.card}>
      <Typography fontSize="16" fontWeight="600" className="mb-2 align-self-start">
        {label}
      </Typography>

      <div className="d-flex align-items-center gap-3 justify-content-center mb-2">
        {formattedData.map(data => (
          <div className="d-flex align-items-center gap-1" key={data.label}>
            <CurrencyColor backgroundColor={data.color} />
            <Typography fontSize="10" fontWeight="500">
              {data.label}
            </Typography>
            <Typography fontSize="10" fontWeight="700">
              {((data.value * 100) / (sum || 1)).toFixed(2)}%
            </Typography>
          </div>
        ))}
      </div>
      <PieChart width={260} height={260} data={formattedDataToDisplay} />
    </div>
  );
};

const CurrencyColor = styled.div<{
  backgroundColor: string;
}>`
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: ${props => props.backgroundColor};
`;
