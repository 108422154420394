import { Pagination } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { manufacturingWalletsKeys } from "./keys";
import { WalletEmployeeTransaction } from "./models";

const getManufacturingWalletsEmployeeTransactions = (
  search: string = "",
): ApiFetcher<Pagination<WalletEmployeeTransaction>> => ({
  key: manufacturingWalletsKeys.employeeTransaction.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/wallets/employee-transactions" + search,
    }),
});

export const walletsApi = {
  getManufacturingWalletsEmployeeTransactions,
};
