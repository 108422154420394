import { MdiSearch } from "components/miloDesignSystem/atoms/icons/MdiSearch";
import { TextField } from "components/miloDesignSystem/atoms/textField/TextField";
import { UserFilters } from "../UsersTab";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { cx } from "utilities";
import styles from "../UsersTab.module.css";
import { useToggle } from "hooks";
import { AddCustomerEmployee } from "../addCustomerEmployee/AddCustomerEmployee";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiSwapVert } from "components/miloDesignSystem/atoms/icons/MdiSwapVert";

interface Props {
  filters: UserFilters;
  setFilter: <T extends keyof UserFilters, U extends UserFilters[T]>(name: T, value: U) => void;
}

export const FiltersSection = ({ filters, setFilter }: Props) => {
  const addCustomerEmployeeModal = useToggle();
  const sortingOptions = [
    {
      icon: <MdiSwapVert size="18" />,
      text: "Ostatnio dodani",
      type: MenuItemType.ICON,
      value: "",
    },
    {
      icon: <MdiSwapVert size="18" />,
      text: "Najdawniej dodani",
      type: MenuItemType.ICON,
      value: "id",
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-1 gap-2">
        <TextField
          containerClassName={cx(styles.usersSearchField, "d-flex flex-1")}
          endIcon={<MdiSearch color="neutralBlack48" size="18" />}
          onChange={event => {
            setFilter("page", 1);
            setFilter("search", event.target.value);
          }}
          placeholder="Szukaj..."
          value={filters.search ?? ""}
        />
        <div className="d-flex align-items-baseline gap-1">
          <Select
            items={sortingOptions}
            label="Sortuj"
            onChange={id => {
              setFilter("page", 1);
              setFilter("ordering", id as string);
            }}
            selected={filters.ordering}
          />
          <span className="divider line-divider" />
          <Button
            className="text-uppercase"
            onClick={addCustomerEmployeeModal.open}
            size="small"
            startIcon={MdiAdd}
            variant="gray"
          >
            Dodaj użytkownika
          </Button>
        </div>
      </div>
      {addCustomerEmployeeModal.isOpen && (
        <AddCustomerEmployee close={addCustomerEmployeeModal.close} />
      )}
    </>
  );
};
