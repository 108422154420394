import { customerActions } from "api/customers/actions";
import { PostCustomerEmployee } from "api/customers/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Formik, FormikHelpers } from "formik";
import { useParams } from "react-router";
import { cx } from "utilities";
import { validationSchema } from "./validationSchema";
import { handleSubmitResponse } from "utilities/handleSubmitResponse";

interface Props {
  close: () => void;
}

export const AddCustomerEmployee = ({ close }: Props) => {
  const { customerId } = useParams<{ customerId: string }>();
  const initialValues: PostCustomerEmployee = {
    avatar: null,
    confirmPassword: "",
    customer: Number(customerId),
    email: "",
    firstName: "",
    isActive: true,
    lastName: "",
    password: "",
    phone: "",
  };
  const postMutation = customerActions.usePostCustomerEmployee(close);

  const handleSubmit = (
    values: PostCustomerEmployee,
    actions: FormikHelpers<PostCustomerEmployee>,
  ) => {
    postMutation.mutate(values, handleSubmitResponse(actions));
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj użytkownika
        </Typography>
      }
      width={480}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="py-2 px-3 d-flex flex-column gap-2">
              <div className="mb-2">
                <TextField.Form<PostCustomerEmployee>
                  label="Imię"
                  name="firstName"
                  placeholder="Uzupełnij"
                />
              </div>
              <div className="mb-2">
                <TextField.Form<PostCustomerEmployee>
                  label="Nazwisko"
                  name="lastName"
                  placeholder="Uzupełnij"
                />
              </div>
              <div className="mb-2">
                <TextField.Form<PostCustomerEmployee>
                  label="Email"
                  name="email"
                  placeholder="np. nazwa@poczta.pl"
                />
              </div>
              <div className="mb-2">
                <TextField.Form<PostCustomerEmployee>
                  label="Telefon"
                  name="phone"
                  placeholder="000 000 000"
                />
              </div>
              <div className="mb-2">
                <TextField.Form<PostCustomerEmployee>
                  label="Hasło"
                  name="password"
                  type="password"
                />
              </div>
              <div className="mb-2">
                <TextField.Form<PostCustomerEmployee>
                  label="Powtórz hasło"
                  name="confirmPassword"
                  type="password"
                />
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 p-3">
              <Button
                className="text-uppercase"
                onClick={close}
                size="medium"
                variant="transparent"
              >
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Dodaj
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
