import { ButtonSize, ButtonTheme, ButtonVariant } from "../button/types";
import { ColorPalette } from "../colorsPalette";

export type ButtonVariantBasedSpinner = Record<ButtonVariant, ColorPalette>;

export const buttonLoaderColors: Record<ButtonTheme, ButtonVariantBasedSpinner> = {
  dark: {
    black: "neutralBlack100",
    danger: "neutralWhite100",
    deepPurple: "neutralWhite100",
    gray: "neutralWhite100",
    outline: "success400",
    success: "neutralWhite100",
    transparent: "neutralWhite100",
  },
  light: {
    black: "neutralWhite100",
    danger: "neutralWhite100",
    deepPurple: "neutralWhite100",
    gray: "neutralBlack100",
    outline: "success500",
    success: "neutralBlack100",
    transparent: "neutralBlack100",
  },
};

export const buttonLoaderSize: Record<ButtonSize, number> = {
  large: 20,
  medium: 20,
  small: 14,
};
