import { customerActions } from "api/customers/actions";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { useParams } from "react-router";
import { assertIsDefined } from "utilities/assertIsDefined";

export const Permissions = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer } = customerActions.useCustomer(Number(customerId));
  assertIsDefined(customer);

  return (
    <div className="d-flex flex-column gap-3 px-1 pb-3">
      <Typography
        className="text-uppercase pt-2"
        color="neutralBlack48"
        fontSize="10"
        fontWeight="700"
      >
        Uprawnienia
      </Typography>
      <Checkbox.Async
        checked={customer.canSendSms}
        helperText="Kontrahent może samodzielnie wysyłać SMSy do kupującego"
        label="Wysyłanie SMS"
        mutationHook={customerActions.usePatchCustomer}
        size="sm"
        transformQueryData={canSendSms => ({
          id: customer.id,
          toUpdate: {
            canSendSms,
          },
        })}
      />
      <Checkbox.Async
        checked={customer.canAddNewIndexes}
        helperText="Kontrahent może tworzyć warianty produktów"
        label="Tworzenie wariantów"
        mutationHook={customerActions.usePatchCustomer}
        size="sm"
        transformQueryData={canAddNewIndexes => ({
          id: customer.id,
          toUpdate: {
            canAddNewIndexes,
          },
        })}
      />
      <Checkbox.Async
        checked={customer.hasPermissionsToCreateProducts}
        helperText="Kontrahent może dodawać produkty i paczki"
        label="Tworzenie produktów i paczek"
        mutationHook={customerActions.usePatchCustomer}
        size="sm"
        transformQueryData={hasPermissionsToCreateProducts => ({
          id: customer.id,
          toUpdate: {
            hasPermissionsToCreateProducts,
          },
        })}
      />
    </div>
  );
};
