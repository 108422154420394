import styles from "./Checkbox.module.css";
import cuid from "cuid";
import { useMemo, useState } from "react";
import { cx, getAnyErrorKey, noop, omit, yup } from "utilities";
import { CheckboxProps } from "./types";
import { Typography } from "../typography";
import { TypographyProps } from "../typography/types";
import { ColorPalette } from "../colorsPalette";
import { Assign } from "utility-types";
import { UseMutationResult } from "react-query";
import { StringSchema } from "yup";
import { AxiosError } from "axios";
import { useField, useFormikContext } from "formik";
import { MDSFormType } from "typeUtilities";

const fontSizeSelector: Record<CheckboxProps["size"], TypographyProps["fontSize"]> = {
  sx: "10",
  sm: "12",
  md: "14",
};

export const Checkbox = ({
  checked,
  disabled,
  error,
  helperText,
  indeterminate,
  label,
  onChange,
  size,
  theme = "light",
}: CheckboxProps) => {
  const id = useMemo(() => cuid(), []);

  const captionColor: Record<typeof theme, ColorPalette> = {
    dark: disabled ? "neutralWhite24" : "neutralWhite48",
    light: disabled ? "neutralBlack24" : "neutralBlack48",
  };

  const labelColor: Record<typeof theme, ColorPalette> = {
    dark: disabled ? "neutralWhite48" : "neutralWhite100",
    light: disabled ? "neutralBlack48" : "neutralBlack88",
  };

  return (
    <div
      className={cx(styles.container, {
        "cursor-not-allowed": disabled,
        "align-items-center": !Boolean(helperText),
      })}
      onClick={() => onChange(!checked)}
    >
      <label
        className={cx(styles.checkContainer, styles[`${size}`], {
          [styles[`${theme}-unchecked`]]: !checked && !indeterminate,
          [styles[`${theme}-checkedOrIndeterminate`]]: checked || indeterminate,
          [styles[`${size}-check`]]: checked,
          [styles[`${size}-dash`]]: indeterminate,
          [styles.fill]: checked || indeterminate,
          [styles.disabled]: disabled,
        })}
        htmlFor={id}
        onClick={event => event.stopPropagation()}
      />
      <input
        id={id}
        type="checkbox"
        className={styles.input}
        checked={checked}
        disabled={disabled}
        onChange={noop}
      />
      <div className={styles.text}>
        {label && (
          <Typography
            className="ml-2"
            color={labelColor[theme]}
            fontSize={fontSizeSelector[size]}
            fontWeight="700"
            noWrap
          >
            {label}
          </Typography>
        )}
        {helperText && (
          <Typography
            className={cx(styles.helperText, "ml-2")}
            color={captionColor[theme]}
            fontSize="10"
            fontWeight="600"
          >
            {helperText}
          </Typography>
        )}

        {error && typeof error === "string" && (
          <Typography className="mt-1 ml-2" color="danger500" fontSize="10" fontWeight="500" noWrap>
            {error}
          </Typography>
        )}
      </div>
    </div>
  );
};

function AsyncCheckbox<TData = unknown, TError = unknown, TVariables = unknown, TContext = unknown>(
  props: Assign<
    CheckboxProps,
    {
      mutationHook: () => UseMutationResult<TData, TError, TVariables, TContext>;
      transformQueryData: (value: CheckboxProps["checked"]) => TVariables;
      validation?: StringSchema<string>;
      onChange?: never;
    }
  >,
) {
  const [validationError, setValidationError] = useState("");
  const mutation = props.mutationHook();

  const mutate = (value: CheckboxProps["checked"]) => {
    try {
      if (props.validation) {
        const validation = yup.object({
          temp: props.validation,
        });
        validation.validateSync({ temp: value });
        setValidationError("");
      }
      const data = props.transformQueryData(value);
      mutation.mutate(data);
    } catch (error) {
      setValidationError(getAnyErrorKey(error as AxiosError));
    }
  };

  const propsToForward = omit(props, ["transformQueryData", "mutationHook"]);

  return (
    <Checkbox
      {...propsToForward}
      disabled={mutation.isLoading || props.disabled}
      error={validationError || getAnyErrorKey(mutation.error as AxiosError)}
      onChange={e => mutate(e)}
    />
  );
}

function FormCheckbox<TForm>(props: MDSFormType<CheckboxProps, TForm>) {
  const [field, meta] = useField(props.name as string);
  const propsToForward = omit(props, ["name"]);
  const { setFieldValue } = useFormikContext<TForm>();

  return (
    <Checkbox
      {...propsToForward}
      {...field}
      checked={Boolean(field.value)}
      onChange={(value: any) => setFieldValue(props.name as string, value)}
      error={meta.touched && meta.error}
    />
  );
}

Checkbox.Form = FormCheckbox;
Checkbox.Async = AsyncCheckbox;
