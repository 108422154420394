import { shippingActions } from "api/shipping/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  FailedResponse,
  FailedResponseModal,
} from "components/miloDesignSystem/organisms/failedResponseModal/FailedResponseModal";
import { StateModalHandler } from "hooks";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { useFilters } from "hooks/useFilters";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useShipmentsColumns } from "pages/orders/ordersList/rightPanel/transportSection/ShippingTable";
import { Order } from "api/orders/models";
import { DrawerSection } from "components/utils/drawer";
import { ShipmentListItem } from "api/shipping/models";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";

interface Props {
  ordersIds: number[];
  source: "ROUTE" | "ORDER_GROUP";
  sourceId: number;
}

interface ShipmentFilters {
  page: number;
  sourceId: Props["sourceId"];
  sourceKind: Props["source"] | "NONE";
}

export const ShipmentsSection = ({ ordersIds, source, sourceId }: Props) => {
  const { searchParams } = useFilters<ShipmentFilters>({
    page: 1,
    sourceId,
    sourceKind: source,
  });
  const {
    data: shipments,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = shippingActions.useShippingShipments(searchParams);
  const createShipmentsMutation = shippingActions.useCreateShipments();
  const columns = useShipmentsColumns();

  const tableProps = useTableFeatureConnector({
    rows: getNormalizedShipments(shipments),
    withPagination: { pagination, defaultPaginationVisibility: false },
  });

  return (
    <StateModalHandler<FailedResponse>>
      {({ open, state, close }) => (
        <>
          <DrawerSection
            title={
              <div className="d-flex align-items-center justify-content-between">
                <Typography fontSize="16" fontWeight="600" color="neutralBlack48" className="mb-2">
                  Przesyłki
                </Typography>
                <Button
                  className="text-uppercase"
                  disabled={isLoading}
                  isLoading={createShipmentsMutation.isLoading}
                  onClick={() => {
                    createShipmentsMutation.mutate({
                      orders: ordersIds,
                      open,
                    });
                  }}
                  size="medium"
                  startIcon={MdiAdd}
                  variant="gray"
                >
                  Utwórz przesyłki dla {source === "ORDER_GROUP" ? "grupy" : "trasy"}
                </Button>
              </div>
            }
          >
            <Table<Order["shipments"][number]>
              columns={columns}
              isLoading={isLoading || isPreviousData}
              error={error}
              uiSchema={comfortableListUiSchema}
              {...tableProps}
              overrides={() => ({
                ...tableProps?.overrides?.(),
                hideHeader: !shipments.length,
                noResultComponent: <EmptySection label="Brak przesyłek" />,
              })}
            />
          </DrawerSection>
          <FailedResponseModal title="Status tworzenia przesyłek" data={state} close={close} />
        </>
      )}
    </StateModalHandler>
  );
};

const getNormalizedShipments = (shipments: ShipmentListItem[]): Order["shipments"] => {
  return shipments.map(shipment => ({
    courierDeliveredDate: shipment.courierDeliveredDate,
    id: shipment.id,
    shippingService: shipment.shippingService
      ? {
          id: shipment.shippingService.id,
          provider: shipment.shippingService.provider,
          name: shipment.shippingService.name,
        }
      : null,
    signature: "",
  }));
};
