import { manufacturingActions } from "api/manufacturing/actions";
import { PostManufacturingEmailPayload } from "api/manufacturing/models";
import { UUID } from "api/types";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Formik } from "formik";
import { cx } from "utilities";
import { validationSchema } from "./validationSchema";

interface Props {
  close: () => void;
  orders: UUID[];
}

export const SelectEmailAddressAndSend = ({ close, orders }: Props) => {
  const handleSubmit = manufacturingActions.usePostManufacturingEmailsToSelectedAddress(close);
  const initialValues: PostManufacturingEmailPayload = {
    orders,
    recipientEmail: "",
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="18" fontWeight="700">
          Wyślij email na wskazany adres
        </Typography>
      }
      width={400}
    >
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, isValid, isSubmitting }) => (
            <form onSubmit={handleSubmit} className={cx({ "was-validated": !isValid })}>
              <div className="pb-3 px-3">
                <TextField.Form<PostManufacturingEmailPayload>
                  label="adres email"
                  name="recipientEmail"
                  placeholder="adres email"
                />
              </div>
              <div className="d-flex align-items-center gap-2 p-3 borderTop">
                <Button
                  className="text-uppercase"
                  onClick={close}
                  size="small"
                  variant="transparent"
                >
                  Anuluj
                </Button>
                <Button
                  className="text-uppercase"
                  isLoading={isSubmitting}
                  size="medium"
                  type="submit"
                  variant="deepPurple"
                >
                  Wyślij
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
