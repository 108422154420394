import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import warehouseIcon from "assets/images/warehouseAlt2.svg";
import { Dashboard } from "routes/reports/Dashboard";

export const warehouse: ModuleLink = {
  url: "warehouse",
  label: "Magazyn",
  icon: warehouseIcon,
  routing: Dashboard,
  isAccessible: false,
};
