import { Address, Customer } from "api/customers/models";
import styles from "../OrdersSection.module.css";
import { cx } from "utilities";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";
import { CountryCode } from "CONSTANTS";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MdiMoreHoriz } from "../../../../../../../../../../../components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiEdit } from "components/miloDesignSystem/atoms/icons/MdiEdit";
import { customerActions } from "api/customers/actions";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { useToggle } from "hooks";
import { AddOrEditDeliveryAddress } from "./addOrEditDeliveryAddress/AddOrEditDeliveryAddress";

interface Props {
  address: Address;
  customer: Customer;
}

export const AddressCard = ({ address, customer }: Props) => {
  const removeAddressMutation = customerActions.useRemoveCustomerAddress(customer);
  const addOrEditAddressModal = useToggle();

  return (
    <>
      <div
        className={cx(styles.addressCard, {
          [styles.defaultAddressCard]: address.isDefault,
        })}
      >
        <div className="d-flex align-items-center gap-2 pl-3 pr-2">
          <div className={cx(styles.cardDetails, "d-flex flex-column gap-1")}>
            <div className="d-flex flex-column">
              <Typography fontSize="14" fontWeight="600" noWrap>
                {address.name || EMPTY_VALUE}
              </Typography>
              <Typography fontSize="14" fontWeight="600" noWrap>
                {address.street || EMPTY_VALUE}
              </Typography>
              <Typography fontSize="14" fontWeight="600" noWrap>
                {address.postCode} {address.city}
              </Typography>
            </div>
            <div className="d-flex flex-column">
              <Typography color="neutralBlack48" fontSize="10" fontWeight="600">
                Kod magazynu
              </Typography>
              <Typography fontSize="14" fontWeight="600" noWrap>
                {address.externalAddressNumber}
              </Typography>
            </div>
            <div className="d-flex align-items-center gap-1">
              <CountryFlag countryCode={address.countryCode as CountryCode} />
            </div>
          </div>
          <div className="d-flex justify-content-start h-100">
            <Menu
              disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
              menuItems={[
                {
                  type: MenuItemType.TEXT,
                  options: {
                    iconStart: MdiEdit,
                  },
                  onClick: addOrEditAddressModal.open,
                  text: "Edytuj",
                },
                {
                  type: MenuItemType.TEXT,
                  options: {
                    color: "red100",
                    iconStart: MdiDelete,
                  },
                  onClick: () => removeAddressMutation.mutate(address.id),
                  text: "Usuń",
                },
              ]}
            />
          </div>
        </div>
        <div className={cx(styles.defaultCheckbox, "pt-2 pl-3")}>
          <Radio.Async
            checked={address.isDefault}
            label="Domyślny"
            mutationHook={customerActions.usePatchCustomerAddress}
            size="sm"
            transformQueryData={isDefault => ({
              customerId: customer.id,
              id: address.id,
              toUpdate: {
                isDefault,
              },
            })}
          />
        </div>
      </div>
      {addOrEditAddressModal.isOpen && (
        <AddOrEditDeliveryAddress
          addressDetails={address}
          close={addOrEditAddressModal.close}
          customer={customer}
        />
      )}
    </>
  );
};
