import { ISODateTime, UUID } from "api/types";
import { PartialOf } from "typeUtilities";
import { Assign } from "utility-types";

export enum LongHeldPackageTab {
  "in-warehouse" = "in-warehouse",
  "not-in-warehouse" = "not-in-warehouse",
}

export type LongHeldPackage = {
  created: ISODateTime;
  daysInWarehouse: number;
  description: string;
  id: UUID;
  index: number;
  isSettled: boolean;
  location: string | number | null;
  name: string;
  order: {
    signature: string;
    name: string;
    id: number;
  };
  position: number;
  setAsReceivedAt: ISODateTime | null;
  supplier: {
    id: UUID;
    kind: string;
    name: string;
  } | null;
  totalPackagesInCollection: number;
  whCell: unknown | null;
};

export type BulkUpdateLongHeldPackage = Assign<
  PartialOf<LongHeldPackage>,
  {
    objects: UUID[];
  }
>;
