import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { BasketList } from "pages/wms/basketList/BasketList";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const BasketsRouting = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        component={BasketList}
        exact
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}`} />}
        title="Milo - WMS - kosze"
      />
      <Route component={NotFound} exact path="*" title="Milo" />
    </Switch>
  );
};
