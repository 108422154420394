import { manufacturingActions } from "api/manufacturing/actions";
import { ManufacturingEmail } from "api/manufacturing/models";
import { EmailStatus } from "api/messages/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar/Avatar";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiRestartAlt } from "components/miloDesignSystem/atoms/icons/MdiRestartAlt";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { manufacturingConstants } from "constants/manufacturingConstants";
import { MdiSend } from "../../../../../components/miloDesignSystem/atoms/icons/MdiSend";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";

export const useLastEmailsColumns = () => {
  const postEmailMutation = manufacturingActions.usePostManufacturingEmails();
  const repostEmailMutation = manufacturingActions.usePostManufacturingEmails();

  return useCreateTableColumns<ManufacturingEmail>(({ columnHelper }) => {
    return [
      columnHelper.date(row => row.createdAt, {
        header: "data",
        size: 80,
        typographyProps: {
          fontSize: "12",
          fontWeight: "400",
        },
      }),
      columnHelper.text(row => row.message.emailTo, {
        header: "na adres",
        size: 135,
      }),
      columnHelper.link({
        header: "zlecenie",
        size: 110,
        to: row => `/manufacturing/manufacturer-orders/list/all?panelId=${row.materialOrder.id}`,
        textRenderer: row => row.materialOrder.signature,
        typographyProps: {
          fontSize: "12",
        },
      }),
      columnHelper.accessor(row => row.message.status, {
        header: "wysyłka",
        size: 80,
        cell: info => {
          const emailStatus = info.getValue();
          if (!emailStatus) return <EmptyValue fontSize="14" fontWeight="600" />;
          return <Tag {...manufacturingConstants.emailStatusDict[emailStatus]} />;
        },
      }),
      columnHelper.accessor(row => row.message.createdBy, {
        header: "przez kogo",
        size: 120,
        cell: info => {
          const createdBy = info.getValue();
          if (!createdBy) return <EmptyValue fontSize="12" />;
          return (
            <div className="d-flex align-items-center gap-1 cut-text">
              <Avatar size="sm" user={createdBy} />
              <Typography fontSize="12" fontWeight="700" noWrap>
                {createdBy.firstName} {createdBy.lastName}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "emailActions",
        header: " ",
        size: 100,
        cell: info => {
          const email = info.getValue();
          return (
            <div className="d-flex align-items-center gap-2">
              <Tooltip title="Ponów">
                <IconButton
                  disabled={email.message.status === EmailStatus.DECLINED}
                  icon={MdiRestartAlt}
                  isLoading={repostEmailMutation.isLoading}
                  onClick={() =>
                    repostEmailMutation.mutate({
                      orders: [email.materialOrder.id],
                    })
                  }
                  variant="transparent"
                />
              </Tooltip>
              <Tooltip title="Wyślij teraz">
                <IconButton
                  icon={MdiSend}
                  isLoading={postEmailMutation.isLoading}
                  onClick={() =>
                    postEmailMutation.mutate({
                      orders: [email.materialOrder.id],
                    })
                  }
                  variant="transparent"
                />
              </Tooltip>
            </div>
          );
        },
      }),
    ];
  });
};
