import { Typography } from "components/miloDesignSystem/atoms/typography";
import { LayoutSchema, RampStatus } from "api/wms/layout/models";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const RampTooltipBody = ({
  field,
  ramp,
}: {
  field: LayoutSchema["areas"][number]["fields"][number];
  ramp?: RampStatus;
}) => {
  return (
    <div>
      <Typography fontSize="14" fontWeight="700">
        {field.verboseName}
      </Typography>
      {ramp?.unloading ? (
        <UnloadingBody unloading={ramp.unloading} />
      ) : (
        <LoadingPickingBody loading={ramp?.loading} picking={ramp?.picking} />
      )}
    </div>
  );
};

const LoadingPickingBody = ({
  loading,
  picking,
}: {
  loading?: RampStatus["loading"];
  picking?: RampStatus["picking"];
}) => {
  return (
    <div className="d-flex flex-column">
      {picking && (
        <div className="mb-2">
          <StatusDetails label="picking" signature={picking.signature} />
        </div>
      )}
      {loading && (
        <div>
          <StatusDetails label="załadunek" signature={loading.signature} />

          {loading.car && (
            <div className="d-flex align-items-center gap-1">
              <Typography fontSize="14" fontWeight="700">
                samochód:
              </Typography>
              <Typography fontSize="14" fontWeight="500">
                {loading.car.registrationNumber}
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
const StatusDetails = ({ label, signature }: { label: string; signature: string }) => {
  return (
    <div>
      <Typography fontSize="14" fontWeight="700" color="success500">
        Trwa {label}
      </Typography>
      <div className="d-flex align-items-center gap-1">
        <Typography fontSize="14" fontWeight="700" color="grey500">
          trasa:
        </Typography>
        <Typography fontSize="14" fontWeight="500">
          {signature}
        </Typography>
      </div>
    </div>
  );
};
const UnloadingBody = ({ unloading }: { unloading?: RampStatus["unloading"] }) => {
  return (
    <div>
      <Typography fontSize="14" fontWeight="700" color="success500">
        Trwa rozładunek
      </Typography>
      <div className="d-flex align-items-center gap-1">
        <Typography fontSize="14" fontWeight="500">
          {unloading?.signature || EMPTY_VALUE}
        </Typography>
      </div>
    </div>
  );
};
