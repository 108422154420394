import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { ManufacturerOrders } from "routes/manufacturingNew/ManufacturerOrders";
import { MdiGrid4x4 } from "components/miloDesignSystem/atoms/icons/MdiGrid4x4";

export const manufacturerOrders: ModuleLink = {
  url: "manufacturer-orders",
  label: "Zlecenia do dostawców",
  icon: MdiGrid4x4,
  routing: ManufacturerOrders,
  isAccessible: true,
};
