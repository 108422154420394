import { manufacturingActions } from "api/manufacturing/actions";
import { ManufacturingOrderStatus } from "api/manufacturing/models";
import { InfoLabel } from "components/common/infoLabel";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const ReceiveSection = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: order } = manufacturingActions.useManufacturingOrder(panelId);
  const orderStatusMutation = manufacturingActions.usePostManufacturingOrdersReceive();

  if (!order) return null;

  return (
    <RightPanelSection>
      <div className="pb-4 d-flex align-items-baseline gap-2">
        <Checkbox
          checked={order.status === ManufacturingOrderStatus.RECEIVED}
          disabled={order.status === ManufacturingOrderStatus.RECEIVED}
          label="Otrzymane / Dostarczone"
          onChange={() =>
            order.status !== ManufacturingOrderStatus.RECEIVED &&
            orderStatusMutation.mutate({
              materialsOrders: [order!.id],
            })
          }
          size="sm"
        />
        <div className="pb-1">{orderStatusMutation.isLoading && <Spinner size={16} />}</div>
      </div>
      <InfoLabel title="otrzymane / odebrane przez">
        {order.receivedBy ? (
          <div className="d-flex align-items-center gap-2">
            <Avatar size="sm" user={order.receivedBy} />
            <Typography color="neutralBlack88" fontSize="12" fontWeight="700" noWrap>
              {order.receivedBy.firstName} {order.receivedBy.lastName}
            </Typography>
          </div>
        ) : (
          <EmptyValue color="neutralBlack88" fontWeight="700" />
        )}
      </InfoLabel>
    </RightPanelSection>
  );
};
