import { manufacturingActions } from "api/manufacturing/actions";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { RightPanelSection } from "components/utils/drawer";
import { manufacturingConstants } from "constants/manufacturingConstants";
import { useQuery } from "hooks";

export const TitleSection = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: order } = manufacturingActions.useManufacturingOrder(panelId);
  const context = useScrollableContext();

  if (!order) return null;

  return (
    <RightPanelSection>
      <div className="d-flex align-items-center gap-2" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {order.signature}
        </Typography>
        {order?.isCancelled ? (
          <Tag label="Anulowano" variant="warning" type="outlined" />
        ) : (
          <Tag {...manufacturingConstants.manufacturingOrderStatusToTag[order.status]} />
        )}
      </div>
    </RightPanelSection>
  );
};
