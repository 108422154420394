import { UUID } from "api/types";
import { useQueryUtils } from "hooks";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { useMutation } from "hooks/useMutation";
import { PartialOf } from "typeUtilities";
import { parsePatchData } from "utilities/parsePatchData";
import {
  getAssignedWhEntries,
  getCurrentlyPrintedReceipts,
  getFinancesAmountSummary,
  getInvoiceAdmission,
  getInvoiceAdmissionLinkLines,
  getLastPrintedReceipts,
  getMyInvoicesToReview,
  getPurchaseInvoicesToReview,
  getSummaryPerInPackage,
  getSummaryPerOutPackage,
  getTradingDocument,
  getTradingDocuments,
  getWarehousePackagesInOrOutSummary,
  getWarehouseTotalValueSummary,
  getWhEntriesAggregates,
  patchInvoiceAdmission,
  patchTradingDocument,
} from "./calls";
import { tradingDocumentsKeys } from "./keys";
import { ModifyInvoiceAdmission, ModifyTradingDocument } from "./models";
import { Assign } from "utility-types";
import { assertIsDefined } from "utilities/assertIsDefined";

export const useTradingDocuments = createPaginatedApiQuery(getTradingDocuments);
export const useTradingDocument = createApiQuery(getTradingDocument);

export const usePurchaseInvoicesToReview = createPaginatedApiQuery(getPurchaseInvoicesToReview);
export const useMyPurchaseInvoicesToReview = createPaginatedApiQuery(getMyInvoicesToReview);

export const useInvoiceAdmission = createApiQuery(getInvoiceAdmission);
export const useInvoiceAdmissionLinkLines = createPaginatedApiQuery(getInvoiceAdmissionLinkLines);

export const useWhEntryAggregates = createPaginatedApiQuery(getWhEntriesAggregates);
export const useAssignedWhEntries = createPaginatedApiQuery(getAssignedWhEntries);

export const useCurrentlyPrintedReceipts = createPaginatedApiQuery(getCurrentlyPrintedReceipts);
export const useLastPrintedReceipts = createPaginatedApiQuery(getLastPrintedReceipts);

export const useWarehouseTotalValueSummary = createApiQuery(getWarehouseTotalValueSummary);
export const usePackagesInOrOutSummary = createPaginatedApiQuery(
  getWarehousePackagesInOrOutSummary,
);
export const useSummaryPerInPackage = createApiQuery(getSummaryPerInPackage);
export const useSummaryPerOutPackage = createApiQuery(getSummaryPerOutPackage);

export const useFinancesAmountSummary = createApiQuery(getFinancesAmountSummary);

export const useUpdateTradingDocumentOptionsForPurchaseImport = () => {
  return useMutation(patchTradingDocument, ({ queryUtils }) => ({
    onMutate: toUpdate => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        tradingDocumentsKeys.tradingDocument.list(),
        toUpdate.id,
        toUpdate,
      );

      const purchaseImportList = queryUtils.handlePaginatedListUpdate(
        tradingDocumentsKeys.purchaseInvoicesToReview.list(),
        toUpdate.id,
        toUpdate,
      );

      return { prevList, purchaseImportList };
    },
    onError: (error, { id }, context) => {
      const { prevList, purchaseImportList } = context as {
        prevList: any;
        purchaseImportList: any;
      };
      queryUtils.rollbackList(tradingDocumentsKeys.tradingDocument.list(), prevList, id);

      queryUtils.rollbackList(
        tradingDocumentsKeys.purchaseInvoicesToReview.list(),
        purchaseImportList,
        id,
        error,
      );
    },
  }));
};

export const useInvoiceAdmissionPatchMutation = (parseData: boolean = false) => {
  const { handleMutate, rollback } = useQueryUtils();

  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: PartialOf<ModifyInvoiceAdmission> }) => {
      if (parseData) {
        return patchInvoiceAdmission(parsePatchData(toUpdate), id);
      }
      return patchInvoiceAdmission(toUpdate, id);
    },
    {
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = handleMutate(
          tradingDocumentsKeys.invoiceAdmission.details(String(id)),
          toUpdate,
        );
        return { prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        const { prevPanel } = onMutateReturn as { prevPanel: any };
        rollback(tradingDocumentsKeys.invoiceAdmission.details(String(id)), prevPanel, error);
      },
    },
  );
};

export const usePatchTradingDocumentMutation = () => {
  return useMutation(
    (data: Assign<Partial<ModifyTradingDocument>, { id: UUID }>) => {
      return patchTradingDocument(data);
    },
    ({ queryClient, queryUtils }) => ({
      onMutate: toUpdate => {
        const prevDetails = queryUtils.handleMutate(
          tradingDocumentsKeys.tradingDocument.details(String(toUpdate.id)),
          toUpdate,
        );

        const prevList = queryUtils.handlePaginatedListUpdate(
          tradingDocumentsKeys.tradingDocument.list(),
          toUpdate.id,
          toUpdate,
        );

        return { prevDetails, prevList };
      },
      onSuccess: (_, args) => {
        if (args.invoiceDeliveryDate)
          queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.details(args.id));
      },
      onError: (error, toUpdate, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(
          tradingDocumentsKeys.tradingDocument.details(String(toUpdate.id)),
          onMutateReturn.prevDetails,
          error,
        );
        queryUtils.rollbackList(
          tradingDocumentsKeys.tradingDocument.list(),
          onMutateReturn.prevList,
          toUpdate.id,
        );
      },
    }),
  );
};
