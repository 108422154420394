import { useMutation } from "hooks/useMutation";
import { orderPackageExchangeApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";

const useOrderPackageExchange = createApiQuery(orderPackageExchangeApi.getOrderPackagesExchange);
const useSourceOrders = createApiQuery(orderPackageExchangeApi.getSourceOrders);

const useProductExchanges = createPaginatedApiQuery(orderPackageExchangeApi.getProductExchanges);
const useOrderPackageExchangeList = createPaginatedApiQuery(
  orderPackageExchangeApi.getOrderPackagesExchangeList,
);

const usePostLinkPackages = () =>
  useMutation(
    orderPackageExchangeApi.postLinkPackages,
    ({ queryUtils }) => queryUtils.invalidateOnSuccessAndHandleError,
  );

const usePatchOrderExchange = () =>
  useMutation(
    orderPackageExchangeApi.patchOrderPackagesExchanges,
    ({ queryUtils }) => queryUtils.invalidateOnSuccessAndHandleError,
  );

const usePatchPackageExchange = () =>
  useMutation(
    orderPackageExchangeApi.patchPackageExchange,
    ({ queryUtils }) => queryUtils.invalidateOnSuccessAndHandleError,
  );

const usePostOrderPackagesExchange = () =>
  useMutation(
    orderPackageExchangeApi.postOrderPackagesExchange,
    ({ queryUtils }) => queryUtils.invalidateOnSuccessAndHandleError,
  );

export const orderPackageExchangeActions = {
  usePostOrderPackagesExchange,
  useOrderPackageExchange,
  useProductExchanges,
  useSourceOrders,
  usePatchOrderExchange,
  usePostLinkPackages,
  usePatchPackageExchange,
  useOrderPackageExchangeList,
};
