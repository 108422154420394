import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../Field.module.css";
import { LayoutSchema, RampActivity, RampStatus } from "api/wms/layout/models";
import { wmsLayoutActions } from "api/wms/layout/actions";
import { useQuery } from "hooks";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RampTooltipBody } from "./RampTooltipBody";
import { UnloadingStatus } from "api/wms/models";
import { cx } from "utilities";

export const Ramp = ({
  field,
  area,
  viewScale,
  color,
}: {
  field: LayoutSchema["areas"][number]["fields"][number];
  area: LayoutSchema["areas"][number];
  viewScale: number;
  color?: string;
}) => {
  const { query } = useQuery();
  const { data: rampStatuses } = wmsLayoutActions.useRampStatus(Number(query["hallId"]), {
    enabled: Boolean(query["hallId"]),
  });
  const ramp = rampStatuses?.find(rampStatus => rampStatus.id === field.wmsId);

  return (
    <Tooltip
      disabled={ramp?.activity !== RampActivity.ACTIVE}
      variant="DARK"
      title={<RampTooltipBody field={field} ramp={ramp} />}
    >
      <div
        className={styles.field}
        style={{
          top: field.coords.y - area.coords.y + viewScale,
          left: field.coords.x - area.coords.x + viewScale,
          width: field.width + viewScale,
          height: field.height + viewScale,
          backgroundColor: color,
        }}
      >
        <RampActivityAnimation field={field} rampStatus={ramp} />
        <div className="d-flex align-items-center justify-content-center flex-1 h-100">
          <Typography fontSize="64" fontWeight="400" color="neutralWhite100" noWrap>
            {field.verboseName}
          </Typography>
        </div>
      </div>
    </Tooltip>
  );
};

export const RampActivityAnimation = ({
  rampStatus,
  field,
}: {
  rampStatus?: RampStatus;
  field: LayoutSchema["areas"][number]["fields"][number];
}) => {
  const isRampActive = rampStatus?.activity === RampActivity.ACTIVE;
  if (!rampStatus || !isRampActive) return null;

  const directionStyles = (() => {
    if (field.width > field.height) {
      return rampStatus.unloading?.status === UnloadingStatus.IN_PROGRESS
        ? styles.waveLeftToRight
        : styles.waveRightToLeft;
    }
    return rampStatus.unloading?.status === UnloadingStatus.IN_PROGRESS
      ? styles.waveBottomToTop
      : styles.waveTopToBottom;
  })();

  return (
    <div className={styles.wave}>
      <div className={cx(styles.waveItem, directionStyles)} />;
    </div>
  );
};
