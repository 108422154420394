import receiptIcon from "assets/images/receiptLong.svg";
import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { tradingDocumentsTabDict } from "pages/tradingDocuments/shared/utils/getTabs";
import { Receipts } from "routes/Receipts";

export const receipts: ModuleLink = {
  url: "receipts",
  label: "Paragony",
  icon: receiptIcon,
  subSection: {
    renderer: <TabSubSection baseUrl="/finances/receipts/list" tabs={tradingDocumentsTabDict} />,
    isExpandedByDefault: false,
  },
  routing: Receipts,
};
