import { CallCenterRouteOrderListItem } from "api/call-center/routes/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  routeOrder: CallCenterRouteOrderListItem;
}

export const OrderSignature = ({ routeOrder }: Props) => {
  const context = useScrollableContext();

  return (
    <div className="d-flex align-items-center mb-2 gap-2" ref={context.triggerElementRef}>
      <div className="fs-24">{routeOrder.signature}</div>
      {Boolean(routeOrder.externalId.length) && (
        <Typography color="neutralBlack48" fontSize="20" fontWeight="700">
          ({routeOrder.externalId})
        </Typography>
      )}
    </div>
  );
};
