import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { Pagination } from "../types";
import { usersKeys } from "./keys";
import {
  AvailablePermissions,
  PermissionGroup,
  PermissionGroupPost,
  User,
  UserInfo,
} from "./models";

const api = tokenRefreshMiddleware(apiFetch);

interface ObtainTokenData {
  phone: string;
  password: string;
}

export const obtainToken = (data: ObtainTokenData) =>
  apiFetch<{ access: string; refresh: string; id: number }>({
    method: "POST",
    url: "/users/token/obtain",
    data,
  });

export const getUserInfo = () =>
  api<UserInfo>({
    method: "GET",
    url: "/users/info",
  });

export const postUserPassword = (data: {
  newPassword: string;
  confirmNewPassword: string;
  currentPassword: string;
}) =>
  api<{ message: string }>({
    method: "POST",
    url: "/users/password/change",
    data,
  });

export const getUsers = (search: string = "", abortToken?: string) =>
  api<Pagination<User>>({
    method: "GET",
    url: "/users/items" + search,
    abortToken,
  });

export const getUsersQuery = (search: string = ""): ApiFetcher<Pagination<User>> => ({
  key: usersKeys.user.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/users/items" + search,
    }),
});

export const getUser = (id: number | string) =>
  api<User>({
    method: "GET",
    url: "/users/items/" + id,
  });

export const patchUser = (id: number | string, data: Partial<User>) =>
  api<User>({
    method: "PATCH",
    url: "/users/items/" + id,
    data,
  });

export const deleteUser = (id: number | string) =>
  api<void>({
    method: "DELETE",
    url: "/users/items/" + id,
  });

export const patchPermissionGroupPermissions = (
  id: PermissionGroup["semiGroups"][number]["id"],
  data: { canAccess: boolean; canControl: boolean },
) =>
  api<{
    canAccess: boolean;
    canControl: boolean;
    id: number;
    mainGroup: number;
    miloPermissionGroup: number;
    name: string;
  }>({
    method: "PATCH",
    url: "/users/semi-groups/" + id,
    data,
  });

export const addUserToPermissionGroup = (data: { group: number; user: number }) =>
  api<{
    message: string;
  }>({
    method: "POST",
    url: "/users/assign-to-milo-group",
    data,
  });

export const removeUserFromPermissionGroup = (data: { group: number; user: number }) =>
  api<{
    message: string;
  }>({
    method: "POST",
    url: "/users/unassign-from-milo-group",
    data,
  });

export const postPermissionGroup = (data: PermissionGroupPost) =>
  api<{ name: string }>({
    method: "POST",
    url: "/users/create-milo-group",
    data,
  });

export const getPermissionGroups = (search: string = "", abortToken?: string) =>
  api<Pagination<PermissionGroup>>({
    method: "GET",
    url: "/users/group-permissions" + search,
    abortToken,
  });

export const getPermissionGroup = (id: number | string) =>
  api<PermissionGroup>({
    method: "GET",
    url: "/users/group-permissions/" + id,
  });

export const deletePermissionGroup = (id: number | string) =>
  api<void>({
    method: "DELETE",
    url: "/users/group-permissions/" + id,
  });

export const getAvailablePermissions = () =>
  api<AvailablePermissions>({
    method: "GET",
    url: "/utils/semi-groups-main",
  });

export const passwordReset = (data: { phone: string }) =>
  apiFetch<{
    uid: string;
    token: string;
    message: string;
  }>({
    method: "POST",
    url: "/users/password/reset",
    data,
  });

export const passwordResetConfirm = (data: {
  uid: string;
  token: string;
  newPassword: string;
  code: number;
}) =>
  apiFetch<{ message: string }>({
    method: "POST",
    url: "/users/password/reset/confirm",
    data,
  });
