import styles from "./MoreDataCounter.module.css";
import cx from "classnames";

interface Props {
  className?: string;
  counter: number | string;
  counterStyle?: string;
  href?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export const MoreDataCounter = ({
  className,
  counter,
  counterStyle,
  onClick,
  href,
  style,
}: Props) => {
  return (
    <a
      className={cx(styles.moreDataCounter, className)}
      href={href ? href : "#"}
      onClick={onClick}
      style={style}
    >
      <div className={counterStyle}>+{counter}</div>
    </a>
  );
};

type PropsToolTip = {
  toolTipString?: string | null;
} & Props;

export const MoreDataCounterWithToolTip = ({
  className,
  counter,
  counterStyle,
  style,
  toolTipString,
}: PropsToolTip) => {
  return (
    <div className={cx(styles.moreDataCounterWithTooltip, className)} style={style}>
      <div className={counterStyle}>+{counter}</div>
      <div className={styles.tooltip}>{toolTipString}</div>
    </div>
  );
};
