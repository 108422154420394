import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useRef } from "react";
import { PanelHeader } from "./panelHeader/PanelHeader";
import { TitleSection } from "./titleSection/TitleSection";
import { GeneralInfoSection } from "./generalInfoSection/GeneralInfoSection";
import { ImplementationSection } from "./implementationSection/ImplementationSection";
import { BuyerSection } from "./buyerSection/BuyerSection";
import { HistorySection } from "./historySection/HistorySection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data: unitDetails, error, isLoading } = manufacturingUnitsActions.useListUnitDetails(
    panelId,
    {
      enabled: Boolean(panelId),
    },
  );

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!unitDetails) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={unitDetails.id}>
        <PanelHeader />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody myRef={clearCommentsRef}>
            <TitleSection unitDetails={unitDetails} />
            <GeneralInfoSection unitDetails={unitDetails} />
            <ImplementationSection unitDetails={unitDetails} />
            <BuyerSection unitDetails={unitDetails} />
            <HistorySection unitDetails={unitDetails} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
