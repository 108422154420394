import { NavLink } from "react-router-dom";
import styles from "../Navbar.module.css";
import dashboardImg from "assets/images/82g.svg";
import ordersImg from "assets/images/31g.svg";
import routesImg from "assets/images/32g.svg";
import shipmentImg from "assets/images/23.svg";
import offersImg from "assets/images/79g.svg";
import productsImg from "assets/images/33g.svg";
import attributesImg from "assets/images/75g.svg";
import usersImg from "assets/images/30g.svg";
import manufacturersImg from "assets/images/140g.svg";
import salesAccountImg from "assets/images/86g.svg";
import customersImg from "assets/images/34g.svg";
import orderGroupsImg from "assets/images/49g.svg";
import warehouseIcon from "assets/images/172g.svg";
import { FLAVOR } from "CONSTANTS";
import { useSelector } from "hooks";
import cx from "classnames";
import { MdiDepartureBoard } from "components/miloDesignSystem/atoms/icons/MdiDepartureBoard";

function B2bNavigation() {
  const hasPermissionsToCreateProducts = useSelector(
    state => state.partials.customer?.hasPermissionsToCreateProducts,
  );
  return (
    <>
      <Item
        alt="Zamówienia"
        title="Zamówienia"
        img={ordersImg}
        url="/client/orders/list/active/all"
      />
      {hasPermissionsToCreateProducts && (
        <Item alt="Produkty" title="Produkty" img={productsImg} url="/client/products" />
      )}
      <Item
        alt="Konta sprzedażowe"
        title="Konta sprzedażowe"
        img={salesAccountImg}
        url="/client/sales-accounts"
      />
      <Item
        alt="Awizacje"
        title="Awizacje"
        img={<MdiDepartureBoard color="neutralWhite24" />}
        url="/client/awaiting-transports"
      />
    </>
  );
}

const ManufacturingNavigation = () => {
  return (
    <>
      <Item alt="Trasy" img={routesImg} title="Trasy" url="/manufacturing/routes" />
      <Item
        alt="Grupy zamówień"
        img={orderGroupsImg}
        title="Grupy zamówień"
        url="/manufacturing/order-groups/list/orders"
      />
    </>
  );
};

function MainNavigation() {
  const isWarehouseInUse = useSelector(state => state.partials.configuration.isWarehouseInUse);
  if (FLAVOR === "b2b") return null;
  if (FLAVOR === "manufacturing") return null;
  if (FLAVOR === "externalManufacturing") return null;

  return (
    <>
      <Item alt="Zamówienia" title="Zamówienia" img={ordersImg} url="/orders/list/active/all" />
      <Item alt="Wysyłka" title="Wysyłka" img={shipmentImg} url="/shipment" />
      <Item
        alt="Grupy zamówień"
        title="Grupy zamówień"
        img={orderGroupsImg}
        url="/order-groups/list/orders"
      />
      <Item alt="Trasy" title="Trasy" img={routesImg} url="/routes" />
      <Item alt="Oferty" title="Oferty" img={offersImg} url="/offers" />
      <Item alt="Produkty" title="Produkty" img={productsImg} url="/products" />
      <Item alt="Kontrahenci" title="Kontrahenci" img={customersImg} url="/customers" />
      <Item
        alt="Konta sprzedażowe"
        title="Konta sprzedażowe"
        img={salesAccountImg}
        url="/sales-accounts"
      />
      <Item alt="Atrybuty" title="Atrybuty" img={attributesImg} url="/attributes" />
      <Item alt="Użytkownicy" title="Użytkownicy" img={usersImg} url="/users" />
      {isWarehouseInUse && (
        <Item alt="Magazyn" title="Magazyn" img={warehouseIcon} url="/warehouse" />
      )}
      <Item alt="Producenci" title="Producenci" img={manufacturersImg} url="/manufacturers" />
    </>
  );
}

export const CollapseNavigation = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <div className={cx(styles.navLeftNarrow, { "d-none": !isOpen })}>
      <div className={styles.narrowContentWrapper}>
        <Item alt="Pulpit" title="Pulpit" img={dashboardImg} url="/" exact />
        {FLAVOR === "externalManufacturing" && null}
        <MainNavigation />
        {FLAVOR === "b2b" && <B2bNavigation />}
        {FLAVOR === "manufacturing" && <ManufacturingNavigation />}
      </div>
    </div>
  );
};

type ItemProps = {
  alt: string;
  img: React.ReactNode;
  url: string;
  exact?: boolean;
  title: string;
};

const Item = ({ alt, img, url, exact = false, title }: ItemProps) => (
  <div className={styles.navLeftLink}>
    <NavLink className="pl-3 pr-3" to={url} activeClassName={styles.active} exact={exact}>
      <span className={styles.linkIcon} title={title}>
        {typeof img === "string" ? <img src={img} alt={alt} /> : img}
      </span>
    </NavLink>
  </div>
);
