import { CreatePickingOrLoadingSource } from "api/orders/models";
import { useLoadings } from "api/wms/hooks";
import { wmsLoadingActions } from "api/wms/loading/actions";
import { InfoLabel } from "components/common/infoLabel/InfoLabel";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { Link } from "components/miloDesignSystem/atoms/link/Link";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { CommonError } from "components/utils";
import { useQuery } from "hooks";
import { queryString } from "utilities";

interface Props {
  signature: string;
  source: CreatePickingOrLoadingSource;
}

export const LoadingLink = ({ signature, source }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const search = queryString.stringify({
    source,
    sourceId: panelId,
  });
  const { data: loadings, error, isLoading } = useLoadings(search);
  const createLoadingMutation = wmsLoadingActions.useCreateLoading(search);

  if (error)
    return (
      <InfoLabel title="Załadunek">
        <CommonError status={error._httpStatus_} />
      </InfoLabel>
    );

  if (isLoading)
    return (
      <InfoLabel title="Załadunek">
        <Spinner size={20} />
      </InfoLabel>
    );

  if (!loadings || !Boolean(loadings.length))
    return (
      <InfoLabel title="Załadunek">
        <Button
          className="text-uppercase"
          isLoading={createLoadingMutation.isLoading}
          onClick={() =>
            createLoadingMutation.mutate({
              id: panelId,
              signature,
              kind: source,
            })
          }
          size="small"
          startIcon={MdiAdd}
          variant="gray"
        >
          Utwórz załadunek
        </Button>
      </InfoLabel>
    );

  return (
    <InfoLabel title="Załadunek">
      <Link
        fontSize="14"
        fontWeight="700"
        key={loadings[0].id}
        to={`/wms/loadings/list/all?panelId=${loadings[0].id}`}
      >
        {loadings[0].signature}
      </Link>
    </InfoLabel>
  );
};
