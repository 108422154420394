import { PackageListItem } from "api/warehouse/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { MdiDelete } from "../../../../../../../components/miloDesignSystem/atoms/icons/MdiDelete";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { warehouseActions } from "api/warehouse/actions";
import { useParams } from "react-router";

export const usePackagesColumns = () => {
  const { indexId } = useParams<{ indexId: string }>();
  const removePackageMutation = warehouseActions.useRemovePackageFromIndex();

  return useCreateTableColumns<PackageListItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.name, {
          header: "nazwa",
          size: 300,
          typographyProps: {
            fontSize: "14",
          },
        }),
        columnHelper.text(row => row.internalId, {
          header: "kod",
          size: 90,
          typographyProps: {
            fontSize: "14",
            fontWeight: "600",
          },
        }),
        columnHelper.accessor(row => row.weight, {
          id: "packageWeight",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              waga
            </Typography>
          ),
          size: 60,
          cell: info => {
            const weight = info.getValue();
            if (!weight) return <EmptyValue fontSize="14" />;
            return (
              <div className="d-flex cut-text gap-1 align-items-baseline justify-content-end w-100">
                <Typography className="text-right" fontSize="14" fontWeight="700" noWrap>
                  {Number(weight)}
                </Typography>
                <Typography
                  className="text-right"
                  color="neutralBlack48"
                  fontSize="10"
                  fontWeight="500"
                  noWrap
                >
                  kg
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row.height, {
          id: "packageHeight",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              wys.
            </Typography>
          ),
          size: 60,
          cell: info => {
            const height = info.getValue();
            if (!height) return <EmptyValue fontSize="14" />;
            return (
              <div className="d-flex cut-text gap-1 align-items-baseline justify-content-end w-100">
                <Typography className="text-right" fontSize="14" fontWeight="700" noWrap>
                  {Number(height)}
                </Typography>
                <Typography
                  className="text-right"
                  color="neutralBlack48"
                  fontSize="10"
                  fontWeight="500"
                  noWrap
                >
                  cm
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row.width, {
          id: "packageWidth",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              szer.
            </Typography>
          ),
          size: 60,
          cell: info => {
            const width = info.getValue();
            if (!width) return <EmptyValue fontSize="14" />;
            return (
              <div className="d-flex cut-text gap-1 align-items-baseline justify-content-end w-100">
                <Typography className="text-right" fontSize="14" fontWeight="700" noWrap>
                  {Number(width)}
                </Typography>
                <Typography
                  className="text-right"
                  color="neutralBlack48"
                  fontSize="10"
                  fontWeight="500"
                  noWrap
                >
                  cm
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row.depth, {
          id: "packageDepth",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              gł.
            </Typography>
          ),
          size: 60,
          cell: info => {
            const depth = info.getValue();
            if (!depth) return <EmptyValue fontSize="14" />;
            return (
              <div className="d-flex cut-text gap-1 align-items-baseline justify-content-end w-100">
                <Typography className="text-right" fontSize="14" fontWeight="700" noWrap>
                  {Number(depth)}
                </Typography>
                <Typography
                  className="text-right"
                  color="neutralBlack48"
                  fontSize="10"
                  fontWeight="500"
                  noWrap
                >
                  cm
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          id: "removePackage",
          header: " ",
          size: 30,
          cell: info => {
            const _package = info.getValue();
            return (
              <IconButton
                icon={MdiDelete}
                onClick={() =>
                  removePackageMutation.mutate({
                    index: Number(indexId),
                    package: _package.id,
                  })
                }
                variant="transparent"
              />
            );
          },
        }),
      ];
    },
    {
      shouldDisplayIndexColumn: true,
    },
  );
};
