import { useRef, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import cx from "classnames";
import styles from "./TabsCarousel.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { PageHeaderProps } from "components/common/pageHeader/PageHeader";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiChevronLeft } from "../../atoms/icons/MdiChevronLeft";
import { MdiChevronRight } from "components/miloDesignSystem/atoms/icons/MdiChevronRight";

const AMOUNT_OF_SCROLL = 150 as const;
const CAROUSEL_SCROLL_DELAY = 300 as const;

interface Props {
  getPath: (tab: {
    label: string;
    name: string;
    path?: string | undefined;
    exactPath?: boolean | undefined;
  }) => string;
  tabs: PageHeaderProps["tabs"];
}

export const TabsCarousel = ({ tabs, getPath }: Props) => {
  const params = useParams<{ tab: string; navbarTab: string }>();
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const container = tabContainerRef.current;
      if (container) {
        const scrollWidth = container.scrollWidth;
        const clientWidth = container.clientWidth;
        if (scrollWidth > clientWidth) {
          setShowRightButton(true);
        } else {
          setShowRightButton(false);
        }
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [tabs]);

  const handleScroll = (direction: "left" | "right") => {
    const container = tabContainerRef.current;

    if (container) {
      container.scrollBy({
        left: direction === "left" ? -AMOUNT_OF_SCROLL : AMOUNT_OF_SCROLL,
        behavior: "smooth",
      });

      setTimeout(() => {
        if (container.scrollLeft > 0) {
          setShowLeftButton(true);
        } else {
          setShowLeftButton(false);
        }

        if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
          setShowRightButton(false);
        } else {
          setShowRightButton(true);
        }
      }, CAROUSEL_SCROLL_DELAY);
    }
  };

  return (
    <div className={styles.carouselContainer}>
      {showLeftButton && (
        <IconButton
          className={cx(styles.carouselButton, styles.leftButton)}
          icon={MdiChevronLeft}
          onClick={() => handleScroll("left")}
          variant="gray"
        />
      )}
      <div className={styles.tabWrapper} ref={tabContainerRef}>
        <div className={styles.tabContainer}>
          {tabs && !tabs.title ? (
            tabs.list.map(tab => (
              <Link
                className={cx(styles.tab, {
                  [styles.tabActive]: params.tab === tab.name,
                })}
                key={tab.name}
                to={getPath(tab)}
              >
                {tab.label}
              </Link>
            ))
          ) : tabs && tabs.title ? (
            <div className="d-flex align-items-center gap-3">
              {typeof tabs.title === "string" ? (
                <Typography fontSize="18" fontWeight="700">
                  {tabs.title}
                </Typography>
              ) : (
                tabs.title
              )}
              <span className="divider line-divider" />
              <div className="d-flex align-items-center gap-1">
                {tabs.list.map(tab => (
                  <Link
                    className={cx(styles.tab, {
                      [styles.tabActive]: params.tab === tab.name,
                    })}
                    key={tab.name}
                    to={getPath(tab)}
                  >
                    {tab.label}
                  </Link>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {showRightButton && (
        <IconButton
          className={cx(styles.carouselButton, styles.rightButton)}
          icon={MdiChevronRight}
          onClick={() => handleScroll("right")}
          variant="gray"
        />
      )}
    </div>
  );
};
