import { queryString } from "utilities";
import { ProductTab } from "../ProductList";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  tab: ProductTab;
};

export const getSearch = ({ query, tab }: GetSearchProps) => {
  const hasOrder = (() => {
    if (tab === ProductTab["with-order"]) {
      return true;
    }
    if (tab === ProductTab["without-order"]) {
      return false;
    }
    return "";
  })();

  const tabsQuery = {
    hasOrder,
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};
