import { tradingDocumentsActions } from "api/trading-documents/actions";
import { TradingDocument } from "api/trading-documents/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { createDraftDocumentUtils } from "../utils";
import { RouteForInvoicing } from "../../../../api/routes/models";
import { useState } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";
import { DraftInvoiceRouteFilters } from "./AddRouteToDraft";
import { Pagination } from "hooks/createPaginatedQuery";
import { FiltersSection } from "./subcomponents/filtersSection/FiltersSection";
import { RoutesList } from "./subcomponents/routesList/RoutesList";

interface Props {
  close: () => void;
  draftInvoice: TradingDocument;
  filters: DraftInvoiceRouteFilters;
  isLoading: boolean;
  isPreviousData: boolean;
  pagination: Pagination;
  routes: RouteForInvoicing[];
  setFilter: <T extends keyof DraftInvoiceRouteFilters, U extends DraftInvoiceRouteFilters[T]>(
    name: T,
    value: U,
  ) => void;
}

export const AddRouteToDraftContent = ({
  close,
  draftInvoice,
  filters,
  isLoading,
  isPreviousData,
  pagination,
  routes,
  setFilter,
}: Props) => {
  const addDraftDocumentPositionsMutation = tradingDocumentsActions.useCreateDraftDocumentPosition();
  const [selectedRoutes, setSelectedRoutes] = useState<RouteForInvoicing[]>([]);

  const isRouteSelected = (id: number): boolean => selectedRoutes.some(route => route.id === id);

  const setRoute = (id: number): void => {
    const routeToAdd = routes.find(route => route.id === id);
    assertIsDefined(routeToAdd);
    setSelectedRoutes([routeToAdd]);
  };

  return (
    <div>
      <div className="py-2 px-3 d-flex flex-column gap-2">
        <FiltersSection filters={filters} setFilter={setFilter} />
        <RoutesList
          isLoading={isLoading}
          isPreviousData={isPreviousData}
          isRouteSelected={isRouteSelected}
          pagination={pagination}
          routes={routes}
          setFilter={setFilter}
          setRoute={setRoute}
        />
      </div>
      <div className="py-3 px-3 d-flex align-items-center gap-2">
        <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
          Anuluj
        </Button>
        <Button
          className="text-uppercase"
          isLoading={addDraftDocumentPositionsMutation.isLoading}
          onClick={() => {
            addDraftDocumentPositionsMutation.mutate(
              {
                tradingDocumentId: draftInvoice.id,
                positions: createDraftDocumentUtils.normalizePositionsForRoute(selectedRoutes),
              },
              {
                onSuccess: () => close(),
              },
            );
          }}
          size="medium"
          variant="deepPurple"
        >
          Dodaj
        </Button>
      </div>
    </div>
  );
};
