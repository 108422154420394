import { useEffect, useRef } from "react";

const HIGHLIGHT_POINT = 150;

export const useMenuScroll = (
  observerDisabled: boolean,
  setActiveSubsection: (value: React.SetStateAction<string>) => void,
) => {
  const sectionRefs = useRef<{ [key: string]: HTMLElement | null }>({});
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (observerDisabled) return;

    const content = contentRef.current;
    if (!content) return;

    const handleScroll = () => {
      let activeSubsectionId: string | null = null;

      Object.values(sectionRefs.current).forEach(section => {
        if (section) {
          const sectionRect = section.getBoundingClientRect();
          if (sectionRect.top <= HIGHLIGHT_POINT && sectionRect.bottom > HIGHLIGHT_POINT) {
            activeSubsectionId = section.id;
          }
        }
      });

      if (activeSubsectionId) {
        setActiveSubsection(activeSubsectionId);
      }
    };

    content.addEventListener("scroll", handleScroll);

    return () => {
      content.removeEventListener("scroll", handleScroll);
    };
  }, [setActiveSubsection, observerDisabled]);

  return { contentRef, sectionRefs };
};
