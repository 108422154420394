import { Route } from "components/utils";
import { useSelector } from "hooks";
import { Dashboard as DeveloperDashboard } from "pages/developer/dashboard/Dashboard";
import { NotFound } from "pages/notFound";
import { RouteComponentProps, Switch } from "react-router-dom";

export const Dashboard = ({ match }: RouteComponentProps): JSX.Element => {
  const me = useSelector(store => store.auth.user!);

  if (me.type !== "developer") return <NotFound />;

  return (
    <Switch>
      <Route
        component={DeveloperDashboard}
        exact={true}
        path={`${match.path}`}
        title="Milo - panel developera"
      />
      <Route component={NotFound} exact={true} path="*" title="Milo" />
    </Switch>
  );
};
