import { CreateManufacturingOrderItem } from "api/external-manufacturing/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useState } from "react";
import { useExternalManufacturingOrdersColumns } from "./useExternalManufacturingOrdersColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import styles from "../OrderList.module.css";
import { useMutation } from "hooks/useMutation";
import { externalManufacturingApi } from "api/external-manufacturing/api";
import { cx, getAnyErrorKey } from "utilities";

interface Props {
  close: () => void;
  state: {
    manufacturingItems: CreateManufacturingOrderItem[];
  };
}

export const CreateExternalManufacturingOrderModal = ({ close, state }: Props) => {
  const [allLineItems, setLineItems] = useState<CreateManufacturingOrderItem[]>(
    state.manufacturingItems,
  );

  const [selectedLineItems, setSelectedLineItems] = useState<CreateManufacturingOrderItem[]>(
    state.manufacturingItems,
  );

  const isLineItemSelected = (id: string | number): boolean => {
    const foundLineItem = selectedLineItems.find(lineItem => lineItem.id === id);
    if (foundLineItem && foundLineItem !== undefined) return true;
    return false;
  };

  const setLineItemSelectionStatus = (id: string | number): void => {
    if (isLineItemSelected(id)) {
      setSelectedLineItems(prevList => prevList.filter(lineItem => lineItem.id !== id));
    } else {
      const lineItemToAdd = allLineItems.find(lineItem => lineItem.id === id);
      if (lineItemToAdd && lineItemToAdd !== undefined) {
        setSelectedLineItems(prevList => {
          return [...prevList, lineItemToAdd];
        });
      }
    }
  };

  const areAllLineItemsPreviouslyAdded =
    selectedLineItems.filter(lineItem => lineItem.details.addedToManufacturingOrder).length ===
    allLineItems.length;

  const areSomeLineItemsSelected =
    selectedLineItems.filter(lineItem => !lineItem.details.addedToManufacturingOrder).length > 0 &&
    selectedLineItems.filter(lineItem => !lineItem.details.addedToManufacturingOrder).length !==
      allLineItems.filter(lineItem => !lineItem.details.addedToManufacturingOrder).length;

  const areAllSelected =
    allLineItems.filter(lineItem => !lineItem.details.addedToManufacturingOrder).length ===
    selectedLineItems.filter(lineItem => !lineItem.details.addedToManufacturingOrder).length;

  const setAllLineItems = (): void => {
    if (areAllSelected) {
      setSelectedLineItems([]);
    } else {
      const notSelected = allLineItems.filter(
        lineItem =>
          !selectedLineItems.some(selectedLineItem => selectedLineItem.id === lineItem.id),
      );

      setSelectedLineItems(prevList => [...prevList, ...notSelected]);
    }
  };

  const columns = useExternalManufacturingOrdersColumns(
    isLineItemSelected,
    setLineItemSelectionStatus,
    areSomeLineItemsSelected,
    areAllSelected,
    areAllLineItemsPreviouslyAdded,
    setAllLineItems,
    setLineItems,
  );

  const postCreateManufacturingOrderFromItems = useMutation(
    externalManufacturingApi.postCreateManufacturingOrdersFromItems,
    ({ toastr, queryClient }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        close();
        toastr.open({
          type: "success",
          title: "Udało się",
          text: "Utworzono zlecenie do producenta",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Tworzenie zlecenia do producenta
        </Typography>
      }
      width={1400}
    >
      <div
        className={cx(
          styles.createManufacturingModalBody,
          "p-3 d-flex flex-column flex-1 overflow-hidden",
        )}
      >
        <Table<CreateManufacturingOrderItem>
          rows={allLineItems}
          columns={columns}
          isLoading={false}
          error={null}
          uiSchema={comfortableListUiSchema}
          overrides={() => {
            return {
              row: row => {
                if (row.details.addedToManufacturingOrder) {
                  return {
                    className: styles.createManufacturingOrderOpacity,
                  };
                }
                return {};
              },
            };
          }}
        />

        <div className="pt-4">
          <Button
            className="text-uppercase"
            disabled={
              !Boolean(
                selectedLineItems.filter(lineItem => !lineItem.details.addedToManufacturingOrder)
                  .length,
              )
            }
            isLoading={postCreateManufacturingOrderFromItems.isLoading}
            onClick={() =>
              postCreateManufacturingOrderFromItems.mutate(
                selectedLineItems.filter(lineItem => !lineItem.details.addedToManufacturingOrder),
              )
            }
            size="medium"
            variant="deepPurple"
          >
            Utwórz zlecenie
          </Button>
        </div>
      </div>
    </Modal>
  );
};
