import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { TradingDocument } from "api/trading-documents/models";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadFile } from "components/miloDesignSystem/atoms/icons/MdiDownloadFile";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";

export const DownloadRevisor = ({
  tradingDocumentIds,
}: {
  tradingDocumentIds: TradingDocument["id"][];
}) => {
  return (
    <FileDownloadHandler
      factoryFn={() => tradingDocumentFileFactory.revisorPdf(tradingDocumentIds)}
      type="epp"
    >
      {({ download, isLoading }) => (
        <Tooltip title="Pobierz plik Revisor">
          <IconButton
            icon={<MdiDownloadFile size="18" />}
            isLoading={isLoading}
            onClick={download}
            variant="transparent"
          />
        </Tooltip>
      )}
    </FileDownloadHandler>
  );
};
