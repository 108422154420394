import { manufacturingActions } from "api/manufacturing/actions";
import { Material } from "api/manufacturing/materials/models";
import { InfoLabel } from "components/common/infoLabel";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { useQuery } from "hooks";
import { dateFns, queryString } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  alreadyOrdered: number;
  unit: Material["unit"];
}

export const LastOrderSection = ({ alreadyOrdered, unit }: Props) => {
  const { query } = useQuery();

  const { data: orders } = manufacturingActions.useGetManufacturingMaterialOrders(
    queryString.stringify({
      page: 1,
      pageSize: "999",
      materials: query.panelId,
    }),
  );

  const lastOrder = orders?.filter(order => !order.receivedAt)[0];

  return (
    <>
      <InfoLabel title="zamówiono">
        <div className="d-flex align-items-center gap-2">
          {Boolean(lastOrder) ? (
            <>
              <Typography fontSize="16" fontWeight="700">
                {alreadyOrdered} {unit.shortName}
              </Typography>
              {lastOrder.scheduledDeadline && (
                <Typography fontSize="12" fontWeight="600" noWrap className="d-flex ">
                  (dostawa
                  {dateFns.differenceInDays(new Date(lastOrder.scheduledDeadline), new Date()) > 0
                    ? " za:"
                    : ":"}
                  <Typography
                    fontSize="12"
                    fontWeight="700"
                    color="info300"
                    noWrap
                    className="ml-1"
                  >
                    {dateFns.differenceInDays(new Date(lastOrder.scheduledDeadline), new Date()) !==
                      0 &&
                      dateFns.differenceInDays(
                        new Date(lastOrder.scheduledDeadline),
                        new Date(),
                      )}{" "}
                    {dateFns.differenceInDays(new Date(lastOrder.scheduledDeadline), new Date()) ===
                    0
                      ? "dzisiaj"
                      : dateFns.differenceInDays(
                          new Date(lastOrder.scheduledDeadline),
                          new Date(),
                        ) < 0
                      ? "dni temu"
                      : "dni"}
                  </Typography>
                  )
                </Typography>
              )}
            </>
          ) : (
            <EmptyValue fontSize="14" fontWeight="600" />
          )}
        </div>
      </InfoLabel>
      <InfoLabel title="zamówiono przez">
        <div className="d-flex align-items-center gap-1">
          {lastOrder ? (
            <>
              <Avatar size="sm" user={lastOrder.createdBy} />
              <Typography fontSize="14" fontWeight="700">
                {lastOrder.createdBy.firstName} {lastOrder.createdBy.lastName}
              </Typography>
            </>
          ) : (
            <EmptyValue fontSize="14" fontWeight="600" />
          )}
        </div>
      </InfoLabel>
    </>
  );
};
