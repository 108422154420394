import { InvoiceType, TradingDocumentTab, TradingDocumentType } from "api/trading-documents/models";
import { queryString } from "utilities";

type GetSearchProps = {
  tab: TradingDocumentTab;
  type: TradingDocumentType;
  invoiceType?: InvoiceType;
  query: {
    [x: string]: string;
  };
};

export const getSearch = ({ tab, type, invoiceType, query }: GetSearchProps): string => {
  const tabsQuery = {
    type: type,
    invoiceType: (invoiceType ?? "").toUpperCase(),
    paymentStatus: tab === "paid" ? "PAID" : tab === "not-paid" ? "NOT_PAID" : "",
  };
  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

export const getAmountSummarySearch = ({
  tab,
  type,
  invoiceType,
  query,
}: GetSearchProps): string => {
  const invoiceFilters: { invoiceTypeIn: string } | { invoiceType: string } =
    invoiceType === "SALES"
      ? {
          invoiceTypeIn: "SALES",
        }
      : {
          invoiceType: (invoiceType ?? "").toUpperCase(),
        };

  const paymentStatusFilter = tab !== "all" ? tab.replace("-", "_").toUpperCase() : "";

  return queryString.stringify({
    ...query,
    type,
    paymentStatus: paymentStatusFilter,
    ...invoiceFilters,
  });
};
