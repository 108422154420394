import { UUID } from "api/types";
import { UpdateStatusPayload } from "../models";
import { queryFetch } from "apiConnectors/queryFetch";

const patchAdvanceStatus = (data: { tradingDocument: UUID }) =>
  queryFetch<UpdateStatusPayload>({
    method: "PATCH",
    url: "/finances/trading-documents/confirm-advance-invoice",
    data,
  });

export const advanceApi = {
  patchAdvanceStatus,
};
