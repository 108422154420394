import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { CustomerSummary } from "./sections/customerSummary/CustomerSummary";
import { CustomerTabSection } from "./sections/customerTabSection/CustomerTabSection";

export const CustomerDetails = () => {
  return (
    <PageWrapper>
      <PageHeader title="Profil kontrahenta" searchBar={false} viewLabel="CUSTOMER_LIST" />
      <div className="d-flex my-2 mx-3 overflow-hidden">
        <CustomerSummary />

        <CustomerTabSection />
      </div>
    </PageWrapper>
  );
};
