import { manufacturingActions } from "api/manufacturing/actions";
import { CommissionListItem } from "api/manufacturing/models";
import { MdiSearch } from "components/miloDesignSystem/atoms/icons/MdiSearch";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { TextField } from "components/miloDesignSystem/atoms/textField/TextField";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { CommonError } from "components/utils";
import { useFilters } from "hooks/useFilters";
import { SectionWrapper } from "pages/crm/customers/customerDetails/components/sectionWrapper/SectionWrapper";
import { useManufacturerOrderColumns } from "pages/manufacturingNew/serviceProviderOrders/useServiceProviderOrdersColumns";
import { useParams } from "react-router";
import { getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "./GroupedOrdersTab.module.css";

interface Filters {
  contractorId: string;
  pageSize: number;
  search: string;
}

export const GroupedOrdersTab = () => {
  const { serviceProviderId } = useParams<{ serviceProviderId: string }>();
  const { filters, searchParams, setFilter } = useFilters<Filters>({
    contractorId: serviceProviderId,
    pageSize: 7,
    search: "",
  });
  const {
    data: groupedOrders,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = manufacturingActions.useServiceProviderCommissions(searchParams);

  const tableProps = useTableFeatureConnector({
    rows: groupedOrders,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });
  const columns = useManufacturerOrderColumns();

  if (isLoading && !isPreviousData)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error && !isPreviousData)
    return (
      <SectionWrapper
        className="mr-3 mb-3 d-flex flex-1 justify-content-center align-items-center p-3 pb-4"
        width="100%"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  assertIsDefined(groupedOrders);

  return (
    <div className="d-flex flex-column pb-3 flex-1">
      <div className="p-3 borderBottom">
        <TextField
          containerClassName={styles.searchField}
          endIcon={<MdiSearch size="18" />}
          onChange={event => setFilter("search", event.target.value)}
          placeholder="Szukaj..."
          value={filters.search}
        />
      </div>
      <div className="mt-2">
        <Table<CommissionListItem>
          columns={columns}
          error={isPreviousData ? error : null}
          isLoading={isLoading && isPreviousData}
          {...tableProps}
          uiSchema={{
            header: {
              backgroundColor: "neutralWhite100",
            },
            cell: {
              height: "36",
            },
          }}
        />
      </div>
    </div>
  );
};
