import { ButtonTheme, ButtonVariant } from "../button/types";
import { ColorPalette, colorPalette } from "../colorsPalette";

export type ButtonStyle = {
  backgroundColor?: string;
  color?: string;
  cursor?: string;
  borderColor?: string;
  borderWidth?: number;
  borderStyle?: string;
  icon?: ColorPalette;
};

export type ButtonThemeBasedStyle = Record<
  ButtonTheme,
  {
    default: ButtonStyle;
    hover: ButtonStyle;
    active: ButtonStyle;
  }
>;

export type ButtonVariants = Record<ButtonVariant, ButtonThemeBasedStyle>;

const black: ButtonThemeBasedStyle = {
  light: {
    active: {
      backgroundColor: colorPalette.neutralBlack48,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
    default: {
      backgroundColor: colorPalette.neutralBlack88,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
    hover: {
      backgroundColor: colorPalette.neutralBlack64,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
  },
  dark: {
    active: {
      backgroundColor: colorPalette.neutralWhite64,
      color: colorPalette.neutralBlack100,
      icon: "neutralBlack100",
    },
    default: {
      backgroundColor: colorPalette.neutralWhite100,
      color: colorPalette.neutralBlack100,
      icon: "neutralBlack100",
    },
    hover: {
      backgroundColor: colorPalette.neutralWhite76,
      color: colorPalette.neutralBlack100,
      icon: "neutralBlack100",
    },
  },
};

const danger: ButtonThemeBasedStyle = {
  light: {
    active: {
      backgroundColor: colorPalette.danger400,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
    default: {
      backgroundColor: colorPalette.danger600,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
    hover: {
      backgroundColor: colorPalette.danger500,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
  },
  dark: {
    active: {
      backgroundColor: colorPalette.danger400,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
    default: {
      backgroundColor: colorPalette.danger32,
      color: colorPalette.danger400,
      icon: "danger400",
    },
    hover: {
      backgroundColor: colorPalette.danger500,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
  },
};

const deepPurple: ButtonThemeBasedStyle = {
  light: {
    active: {
      backgroundColor: colorPalette.deepPurple300,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
    default: {
      backgroundColor: colorPalette.deepPurple500,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
    hover: {
      backgroundColor: colorPalette.deepPurple400,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
  },
  dark: {
    active: {
      backgroundColor: colorPalette.deepPurple300,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
    default: {
      backgroundColor: colorPalette.deepPurple32,
      color: colorPalette.deepPurple300,
      icon: "deepPurple300",
    },
    hover: {
      backgroundColor: colorPalette.deepPurple400,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
  },
};

const gray: ButtonThemeBasedStyle = {
  light: {
    active: {
      backgroundColor: colorPalette.neutralBlack12,
      color: colorPalette.neutralBlack88,
      icon: "neutralBlack88",
    },
    default: {
      backgroundColor: colorPalette.neutralBlack4,
      color: colorPalette.neutralBlack88,
      icon: "neutralBlack88",
    },
    hover: {
      backgroundColor: colorPalette.neutralBlack7,
      color: colorPalette.neutralBlack88,
      icon: "neutralBlack88",
    },
  },
  dark: {
    active: {
      backgroundColor: colorPalette.neutralWhite32,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
    default: {
      backgroundColor: colorPalette.neutralWhite16,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
    hover: {
      backgroundColor: colorPalette.neutralWhite24,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
  },
};

const outline: ButtonThemeBasedStyle = {
  light: {
    active: {
      color: colorPalette.success500,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: colorPalette.success12,
      backgroundColor: colorPalette.success32,
      icon: "success500",
    },
    default: {
      color: colorPalette.neutralBlack88,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: colorPalette.neutralBlack20,
      backgroundColor: "transparent",
      icon: "neutralBlack88",
    },
    hover: {
      backgroundColor: colorPalette.success12,
      color: colorPalette.success500,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: colorPalette.success12,
      icon: "success500",
    },
  },
  dark: {
    active: {
      color: colorPalette.success400,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: colorPalette.success12,
      backgroundColor: colorPalette.success32,
      icon: "success400",
    },
    default: {
      color: colorPalette.neutralWhite100,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: colorPalette.neutralWhite32,
      backgroundColor: colorPalette.neutralWhite20,
      icon: "neutralWhite100",
    },
    hover: {
      backgroundColor: colorPalette.success12,
      color: colorPalette.success400,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: colorPalette.success12,
      icon: "success400",
    },
  },
};

const success: ButtonThemeBasedStyle = {
  light: {
    active: {
      color: colorPalette.neutralBlack88,
      backgroundColor: "transparent",
      borderColor: colorPalette.neutralBlack20,
      borderWidth: 1,
      borderStyle: "solid",
      icon: "neutralBlack88",
    },
    default: {
      color: colorPalette.success500,
      backgroundColor: colorPalette.success32,
      borderColor: colorPalette.success12,
      borderWidth: 1,
      borderStyle: "solid",
      icon: "success500",
    },
    hover: {
      color: colorPalette.success500,
      backgroundColor: colorPalette.success12,
      borderColor: colorPalette.success12,
      borderWidth: 1,
      borderStyle: "solid",
      icon: "success500",
    },
  },
  dark: {
    active: {
      color: colorPalette.neutralWhite32,
      backgroundColor: "transparent",
      borderColor: colorPalette.neutralWhite20,
      borderWidth: 1,
      borderStyle: "solid",
      icon: "neutralWhite100",
    },
    default: {
      color: colorPalette.success400,
      backgroundColor: colorPalette.success32,
      borderColor: colorPalette.success12,
      borderWidth: 1,
      borderStyle: "solid",
      icon: "success400",
    },
    hover: {
      color: colorPalette.success400,
      backgroundColor: colorPalette.success12,
      borderColor: colorPalette.success12,
      borderWidth: 1,
      borderStyle: "solid",
      icon: "success400",
    },
  },
};

const transparent: ButtonThemeBasedStyle = {
  light: {
    active: {
      backgroundColor: colorPalette.neutralBlack7,
      color: colorPalette.neutralBlack88,
      icon: "neutralBlack88",
    },
    default: {
      backgroundColor: "transparent",
      color: colorPalette.neutralBlack88,
      icon: "neutralBlack88",
    },
    hover: {
      backgroundColor: colorPalette.neutralBlack4,
      color: colorPalette.neutralBlack88,
      icon: "neutralBlack88",
    },
  },
  dark: {
    active: {
      backgroundColor: colorPalette.neutralWhite12,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
    default: {
      backgroundColor: "transparent",
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
    hover: {
      backgroundColor: colorPalette.neutralWhite7,
      color: colorPalette.neutralWhite100,
      icon: "neutralWhite100",
    },
  },
};

export const buttonVariants: ButtonVariants = {
  black,
  danger,
  deepPurple,
  gray,
  outline,
  success,
  transparent,
};

const baseLightDisabled: ButtonStyle = {
  backgroundColor: colorPalette.neutralBlack4,
  color: colorPalette.neutralBlack32,
  cursor: "not-allowed !important",
  icon: "neutralBlack32",
};

const baseDarkDisabled: ButtonStyle = {
  backgroundColor: colorPalette.neutralWhite4,
  color: colorPalette.neutralWhite32,
  cursor: "not-allowed !important",
  icon: "neutralWhite32",
};

const baseDisabled: ButtonThemeBasedStyle = {
  light: {
    default: baseLightDisabled,
    hover: baseLightDisabled,
    active: baseLightDisabled,
  },
  dark: {
    default: baseDarkDisabled,
    hover: baseDarkDisabled,
    active: baseDarkDisabled,
  },
};

const transparentLightDisabled: ButtonStyle = {
  ...baseLightDisabled,
  backgroundColor: "transparent",
};

const transparentDarkDisabled: ButtonStyle = {
  ...baseDarkDisabled,
  backgroundColor: "transparent",
};

const transparentDisabled: ButtonThemeBasedStyle = {
  light: {
    default: transparentLightDisabled,
    hover: transparentLightDisabled,
    active: transparentLightDisabled,
  },
  dark: {
    default: transparentDarkDisabled,
    hover: transparentDarkDisabled,
    active: transparentDarkDisabled,
  },
};

export const disabledButtonVariants: Record<ButtonVariant, ButtonThemeBasedStyle> = {
  black: baseDisabled,
  danger: baseDisabled,
  deepPurple: baseDisabled,
  gray: baseDisabled,
  outline: transparentDisabled,
  success: transparentDisabled,
  transparent: transparentDisabled,
};
