import { RoutesTab } from "api/logistics/routes/models";
import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { InvoicePagination } from "components/common/invoicePagination/InvoicePagination";
import { CommonError, RightPanelHandler } from "components/utils";
import { ListDrawerWrapper } from "components/utils/drawer";
import { Loader } from "components/utils/loader";
import { ModuleList } from "components/utils/moduleList";
import { ModuleListBody } from "components/utils/moduleList/components/ModuleListBody";
import { ModuleListHeader } from "components/utils/moduleList/components/ModuleListHeader";
import { logisticsRoutesListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { NoResults } from "components/utils/noResults";
import { useQuery } from "hooks";
import { useRoutes } from "hooks/apiPrimitives";
import { useState } from "react";
import { RouteComponentProps } from "react-router";
import { handleMultipleRowsHighlight } from "utilities/handleMultipleRowsHighlight";
import { ActionToolbar } from "./components/ActionToolbar";
import { ListHeader } from "./components/ListHeader";
import { ListItem } from "./components/ListItem";
import { RightPanel } from "./rightPanel/RightPanel";
import { getSearch } from "./utils/getSearch";
import { withFilters } from "components/utils/withFilters";
import { filterList } from "./filterList";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";

export const RoutesList = withFilters(
  filterList,
  ({ match }: RouteComponentProps<{ tab: RoutesTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const search = getSearch({ query, tab });
    const { data: routes, error, isFetching, isLoading, pagination } = useRoutes(search);

    const [showLoader, setShowLoader] = useState(false);

    const [highlightedRows, setHighlightedRows] = useState<HighlightedRow[]>([]);

    const noResults = !routes.length && !isLoading && !error;

    if (error) {
      return (
        <div>
          <ListHeader />
          <div className="mt-4">
            <CommonError status={error._httpStatus_} />
          </div>
        </div>
      );
    }

    if (isLoading) {
      return (
        <div>
          <Loader module="logistics" />
          <ListHeader />
        </div>
      );
    }

    const selectMutlipleItems = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      id: UUID | number,
      index: number,
      togglePanel: () => void,
    ) => {
      handleMultipleRowsHighlight(
        event,
        highlightedRows,
        id,
        index,
        setHighlightedRows,
        togglePanel,
        routes,
      );
    };

    return (
      <PageWrapper>
        {(isFetching || showLoader) && <Loader module="logistics" />}
        <ListHeader />
        <RightPanelHandler name="logisticsRoutes">
          {({ close, isActive, isHighlighted, togglePanel }) => (
            <ListDrawerWrapper innerClassName="flex-column">
              {noResults ? (
                <NoResults on={noResults} subHeader="trasy" />
              ) : (
                <ModuleList>
                  <ModuleListHeader
                    gridStyle={logisticsRoutesListConfig.grid}
                    labels={logisticsRoutesListConfig.labels}
                  />
                  <ModuleListBody>
                    {routes.map((route, index) => (
                      <ListItem
                        highlightedRows={highlightedRows}
                        index={index}
                        isActive={isActive}
                        isHighlighted={isHighlighted}
                        key={route.id}
                        route={route}
                        selectMutlipleItems={selectMutlipleItems}
                        setShowLoader={setShowLoader}
                        togglePanel={togglePanel}
                      />
                    ))}
                  </ModuleListBody>
                </ModuleList>
              )}
              <RightPanel close={close} setShowLoader={setShowLoader} />
              <InvoicePagination position="relative" pagination={pagination} />
              {highlightedRows.length > 0 && (
                <ActionToolbar
                  actualPage={query.page}
                  close={() => {
                    setHighlightedRows([]);
                  }}
                  quantity={highlightedRows.length}
                  highlightedRows={highlightedRows}
                  setHighlightedRows={setHighlightedRows}
                />
              )}
            </ListDrawerWrapper>
          )}
        </RightPanelHandler>
      </PageWrapper>
    );
  },
);
