import { Typography } from "../typography";
import styles from "./Attribute.module.css";
import { AttributeProps } from "./types";

const DEFAULT_ICON_SIZE = "12";

export const Attribute = ({ endIcon: EndIcon, label, value }: AttributeProps) => {
  return (
    <div className={styles.attribute}>
      <Typography color="neutralBlack88" fontSize="12" fontWeight="400" noWrap>
        {label.toLowerCase()}:
      </Typography>
      <Typography color="neutralBlack88" fontSize="12" fontWeight="700" noWrap>
        {value}
      </Typography>
      {Boolean(EndIcon) && EndIcon !== undefined && (
        <EndIcon color="blue100" size={DEFAULT_ICON_SIZE} />
      )}
    </div>
  );
};
