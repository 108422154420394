import { createPicking } from "api/orders/calls";
import { useMutation } from "hooks/useMutation";
import { wmsKeys } from "../keys";
import { getAnyErrorKey } from "utilities";

const useCreatePicking = (search: string) => {
  return useMutation(createPicking, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(wmsKeys.picking.list(search));
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono picking",
      });
    },
    onError: error =>
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      }),
  }));
};

export const wmsPickingActions = {
  useCreatePicking,
};
