import { yup } from "./utilities";

const passwordValidation = () =>
  yup
    .string()
    .min(8, "Hasło powinno mieć co najmniej 8 znaków")
    .required("Wpisz hasło, jakim użytkownik będzie się logował do aplikacji")
    .matches(/[a-z]/, "Hasło musi zawierać co najmniej 1 małą literę")
    .matches(/[A-Z]/, "Hasło musi zawierać co najmniej 1 wielką literę")
    .matches(/\d/, "Hasło musi zawierać co najmniej 1 cyfrę")
    .matches(
      /[@$!%*?&#^\-=&"%'()+,/:;<>_\\.`{}|~[\]]/,
      "Hasło musi zawierać co najmniej 1 znak specjalny",
    );

export const validationUtils = {
  password: passwordValidation(),
};
