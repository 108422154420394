import { TableProps } from "components/miloDesignSystem/molecules/table/types";
import { AvailableViewKeys } from "ducks/ui";
import { useDrawer } from "hooks/useDrawer";
import rowStyles from "components/miloDesignSystem/molecules/table/components/body/components/row/Row.module.css";
import { UUID } from "api/types";

type DrawerHelpersType = {
  isActive: (id: number | string) => boolean;
  isLastOpened: (id: number | string) => boolean;
  togglePanel: (id: number | string, additionalId?: number | string) => void;
};

interface Props {
  children: (drawerHelpers: DrawerHelpersType) => JSX.Element;
  name: AvailableViewKeys;
  overrittenPanelKey?: string;
}

export const DrawerHelpers = ({ children, name, overrittenPanelKey }: Props) => {
  const { isActive, isLastOpened, togglePanel } = useDrawer(name, overrittenPanelKey);
  return children({ isActive, isLastOpened, togglePanel });
};

export const getTablePropsBasedOnDrawerHelpers = <T extends { id: UUID | number }>(
  drawerHelpers: DrawerHelpersType,
): Pick<TableProps<T>, "onRowClick" | "overrides"> => {
  return {
    onRowClick: rowId => drawerHelpers.togglePanel(rowId),
    overrides: () => {
      return {
        row: row => {
          if (drawerHelpers.isActive(row.id ?? "")) {
            return {
              className: rowStyles.active,
            };
          }

          if (drawerHelpers.isLastOpened(row.id ?? "")) {
            return {
              className: rowStyles.lastOpened,
            };
          }
          return {};
        },
      };
    },
  };
};
