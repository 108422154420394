import { ReturnDetails, ReturnWorkspaceState } from "api/wms/returns/models";
import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../RightPanel.module.css";
import { cx, dateUtils } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import QRCode from "qrcode.react";
import { Select } from "components/miloDesignSystem/molecules/select";
import { useSelector, useStateModal } from "hooks";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { wmsReturnsActions } from "api/wms/returns/actions";
import { TimePicker } from "components/utils/timePicker";
import { ReturnStart } from "../../subcomponents/ReturnStart";
import { ReturnDate } from "../../subcomponents/ReturnDate";
import { getParsedDuration } from "pages/wms/shared/utils/getParsedDuration";
import { ReturnWorkspaceModal } from "../returnWorkspaceModal/ReturnWorkspaceModal";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";

interface Props {
  returnDetails: ReturnDetails;
}

export const GeneralInfoSection = ({ returnDetails }: Props) => {
  const ramps = useSelector(store => store.partials.wmsRamps);
  const estimatedDateOfArrivalTimeMutation = wmsReturnsActions.usePatchReturn();
  const returnWorkspaceModal = useStateModal<ReturnWorkspaceState>();

  return (
    <>
      <RightPanelSection padding="px-3 pt-0 pb-2">
        <div className="d-flex align-items-start justify-content-between gap-2">
          <div>
            <InfoLabel title="rampa">
              <div>
                <Select.Async
                  items={ramps.map(ramp => ({
                    text: ramp.name,
                    type: MenuItemType.TEXT,
                    value: ramp.id,
                  }))}
                  mutationHook={wmsReturnsActions.usePatchReturn}
                  selected={returnDetails.ramp?.id ?? ""}
                  transformQueryData={ramp => {
                    const foundRamp = ramps.find(_ramp => _ramp.id === ramp);
                    return {
                      id: returnDetails.id,
                      ramp: foundRamp
                        ? {
                            id: foundRamp.id,
                            name: foundRamp.name,
                          }
                        : null,
                    };
                  }}
                />
              </div>
            </InfoLabel>
            <InfoLabel title="planowany czas zwrotu">
              <div className="d-flex align-items-center gap-2">
                <DatePicker.Async
                  isNullable
                  mutationHook={wmsReturnsActions.usePatchReturn}
                  transformQueryData={value => ({
                    id: returnDetails.id,
                    estimatedDateOfArrivalDate: value ? dateUtils.formatDateToIso(value) : value,
                  })}
                  value={returnDetails.estimatedDateOfArrivalDate}
                />
                <TimePicker
                  disabled={estimatedDateOfArrivalTimeMutation.isLoading}
                  inProgress={estimatedDateOfArrivalTimeMutation.isLoading}
                  look="common"
                  onBlur={time =>
                    estimatedDateOfArrivalTimeMutation.mutate({
                      id: returnDetails.id,
                      estimatedDateOfArrivalTime: time || null,
                    })
                  }
                  value={returnDetails.estimatedDateOfArrivalTime}
                />
              </div>
            </InfoLabel>
            <InfoLabel title="przyjazd">
              <Typography fontSize="14" fontWeight="600">
                {returnDetails.actualTimeOfArrival
                  ? dateUtils.formatDateToTime(returnDetails.actualTimeOfArrival)
                  : EMPTY_VALUE}
              </Typography>
            </InfoLabel>
            <div className="d-flex align-items-center gap-2 mb-1">
              <div className={styles.timeLabelContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  start:
                </Typography>
                <ReturnStart fontSize="14" fontWeight="500" returnDetails={returnDetails} />
              </div>
              <div className={styles.timeLabelContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  zakończenie:
                </Typography>
                <ReturnDate date={returnDetails.finishedAt} fontSize="14" fontWeight="500" />
              </div>
              <div className={styles.timeLabelContainer}>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  czas przyjęcia zwrotu:
                </Typography>
                <Typography fontSize="14" fontWeight="600">
                  {Boolean(returnDetails.duration)
                    ? getParsedDuration(returnDetails.duration)
                    : EMPTY_VALUE}
                </Typography>
              </div>
            </div>
            <InfoLabel title="kontrahenci">
              <div className={styles.suppliers}>
                <Typography fontSize="14" fontWeight="700">
                  {Boolean(returnDetails.suppliers.length)
                    ? returnDetails.suppliers.map(supplier => supplier.name).join(", ")
                    : EMPTY_VALUE}
                </Typography>
              </div>
            </InfoLabel>
            <InfoLabel
              className={cx({
                [styles.collaborators]: Boolean(returnDetails.collaborators.length),
              })}
              title="osoby zaangażowane"
            >
              <div className="d-flex flex-column gap-1">
                {Boolean(returnDetails.collaborators.length) ? (
                  returnDetails.collaborators.map(collaborator => (
                    <div
                      className="d-flex align-items-center gap-1 cursor-pointer"
                      key={collaborator.id}
                      onClick={() =>
                        returnWorkspaceModal.open({
                          user: collaborator,
                          wmsReturnId: returnDetails.id,
                        })
                      }
                    >
                      <Avatar size="sm" user={collaborator} theme="light" />
                      <Typography className="ml-1" fontSize="12" fontWeight="700" noWrap>
                        {collaborator.firstName} {collaborator.lastName}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <div className="d-flex align-items-start">
                    <EmptyValue fontSize="14" fontWeight="700" />
                  </div>
                )}
              </div>
            </InfoLabel>
          </div>
          <div className="d-flex flex-column gap-1">
            <div>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                QR kod przyjęcia
              </Typography>
            </div>
            <QRCode value={returnDetails.qrCode} />
          </div>
        </div>
      </RightPanelSection>
      {returnWorkspaceModal.isOpen &&
        returnWorkspaceModal.state?.wmsReturnId &&
        returnWorkspaceModal.state.user && (
          <ReturnWorkspaceModal
            close={returnWorkspaceModal.close}
            state={returnWorkspaceModal.state}
          />
        )}
    </>
  );
};
