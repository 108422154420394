import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../StageBoard.module.css";
import { cx } from "utilities";

interface Props {
  label: string;
}

export const DashedLabel = ({ label }: Props) => {
  return (
    <div className={cx(styles.dashedLabelWrapper, "py-2")}>
      <div className={styles.dashedLabelBorder} />
      <div className={cx(styles.dashedLabelText, "px-2")}>
        <Typography color="neutralBlack88" fontSize="14" fontWeight="600">
          {label}
        </Typography>
      </div>
    </div>
  );
};
