import { TradingDocument } from "api/trading-documents/models";
import { RightPanelSection } from "components/utils/drawer";
import { useFinancesPaymentsColumns } from "./useFinancesPaymentsColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { Table } from "components/miloDesignSystem/molecules/table";
import styles from "./PaymentSection.module.css";
import { cx } from "utilities";
import { useToggle } from "hooks";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { AddPaymentModal } from "./addPaymentModal/AddPaymentModal";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection";

interface Props {
  tradingDocument: TradingDocument;
}

export const FinancesPayments = ({ tradingDocument }: Props) => {
  const columns = useFinancesPaymentsColumns();
  const tableProps = useTableFeatureConnector({
    rows: tradingDocument.payments,
  });
  const addFinancesPaymentModal = useToggle();

  if (!Boolean(tradingDocument.payments.length))
    return (
      <RightPanelSection title="Płatności">
        <EmptySection
          AdditionalInfoRenderer={
            <div className="py-2">
              <Button
                className="text-uppercase"
                onClick={addFinancesPaymentModal.open}
                size="small"
                startIcon={MdiAdd}
                variant="gray"
              >
                Dodaj pierwszą płatność
              </Button>
            </div>
          }
          className={styles.emptySection}
          label="Dokument jeszcze nie zawiera żadnych płatności"
        />
        {addFinancesPaymentModal.isOpen && (
          <AddPaymentModal
            close={addFinancesPaymentModal.close}
            tradingDocument={tradingDocument}
          />
        )}
      </RightPanelSection>
    );

  return (
    <RightPanelSection title="Płatności">
      <div className="pb-3 d-flex flex-column gap-2">
        <Table<TradingDocument["payments"][number]>
          columns={columns}
          error={null}
          isLoading={false}
          uiSchema={{
            header: {
              backgroundColor: "neutralWhite100",
            },
            cell: {
              height: "43",
            },
          }}
          {...tableProps}
          overrides={() => ({
            ...tableProps.overrides,
            row: row => {
              return {
                className: cx(
                  { [styles.tableRowPaymentsReturn]: row.kind === "REFUND" },
                  tableProps.overrides?.().row?.(row).className,
                ),
              };
            },
          })}
        />
        <div>
          <Button
            className="text-uppercase"
            onClick={addFinancesPaymentModal.open}
            size="small"
            startIcon={MdiAdd}
            variant="gray"
          >
            Dodaj kolejną płatność
          </Button>
        </div>
        {addFinancesPaymentModal.isOpen && (
          <AddPaymentModal
            close={addFinancesPaymentModal.close}
            tradingDocument={tradingDocument}
          />
        )}
      </div>
    </RightPanelSection>
  );
};
