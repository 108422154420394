import { RightPanel } from "./rightPanel/RightPanel";
import { useQuery } from "hooks";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { dictToList, queryString } from "utilities";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useOrderGroupColumns } from "./useOrderGroupColumns";
import { ManufacturingStatus } from "api/routes/models";
import { RouteComponentProps } from "react-router";
import { useOrderGroupsQuery } from "hooks/apiPrimitives";
import { ListOrderGroup } from "api/order-groups/models";
import { orderGroupList } from "components/common/moduleNavigation/moduleConfig/manufacturing/routes/orderGroupList";

export const OrderGroupList = ({ match }: RouteComponentProps<{ tab: Tabs }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const { data, error, isLoading, isPreviousData, pagination } = useOrderGroupsQuery(search);

  const tableProps = useTableFeatureConnector({
    rows: data,
    withDrawer: "manufacturingOrderGroup",
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  const columns = useOrderGroupColumns();

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        tabs={{
          list: dictToList(tabToDict).map(({ key, value }) => ({
            label: value,
            name: key,
          })),
          routesRoot: `manufacturing/${orderGroupList.url}`,
          urlSpan: "list",
        }}
        viewLabel="ORDER_GROUP_MANUFACTURING"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<ListOrderGroup>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
      </div>
    </PageWrapper>
  );
};

type Tabs = "all" | ManufacturingStatus.FINISHED | ManufacturingStatus.IN_PROGRESS;

type Search = {
  tab: Tabs;
  query: {
    [x: string]: string;
  };
};

const tabToDict: Record<Tabs, string> = {
  all: "Wszystkie",
  FINISHED: "Przygotowane",
  IN_PROGRESS: "W przygotowaniu",
};

const getSearch = ({ query, tab }: Search) => {
  const tabsQuery = {
    manufacturingStatus: tab === "all" ? "" : tab,
  };

  return queryString.stringify({
    ...query,
    deliveryMethod: "external-shipment",
    ...tabsQuery,
  });
};
