import { TradingDocument, TradingDocumentPaymentStatus } from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel/InfoLabel";
import { PaymentForm } from "../components/PaymentForm";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { noop } from "utilities";
import { TotalAmountSection } from "../TotalAmountSection";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { Select } from "components/miloDesignSystem/molecules/select";

interface Props {
  tradingDocument: TradingDocument;
}

export const PaymentDetails = ({ tradingDocument }: Props) => {
  return (
    <div>
      <div className="d-flex justify-content-between pt-3 row">
        <div className="pt-1 col-5">
          <div className="pb-3">
            <InfoLabel title="forma płatności">
              <PaymentForm
                expectedPaymentForm={tradingDocument.expectedPaymentForm}
                id={tradingDocument.id}
                invoiceType={tradingDocument.invoiceType}
              />
            </InfoLabel>
            <InfoLabel title="wystawił(/a)">
              <Typography fontSize="14" fontWeight="700" noWrap>
                {tradingDocument.signatureOfAnAuthorizedPerson || EMPTY_VALUE}
              </Typography>
            </InfoLabel>
            <div className="d-flex align-items-center gap-2 pb-2">
              <Checkbox
                disabled
                checked={tradingDocument.isManagedManually}
                label="Manualnie edytowana faktura"
                onChange={noop}
                size="md"
              />
            </div>
          </div>
        </div>
        <div className="col-7 pl-4 overflow-hidden">
          <TotalAmountSection tradingDocument={tradingDocument} />
          <div className="d-flex flex-column justify-content-center align-items-end pt-2">
            <div className="d-flex align-items-center justify-content-end">
              <Typography fontSize="14" fontWeight="500">
                Kwota słownie:
              </Typography>
            </div>
            <div className="d-flex justify-content-end text-right w-100">
              {Boolean(tradingDocument.amountSummary.totalWithTaxInWords) ? (
                <div className="d-flex flex-column align-items-end w-100">
                  <Typography className="w-100" fontSize="14" fontWeight="700" noWrap>
                    {tradingDocument.amountSummary.totalWithTaxInWords}
                  </Typography>
                  <Typography color="neutralBlack48" fontSize="12" fontWeight="700">
                    {tradingDocument.currency}
                  </Typography>
                </div>
              ) : (
                <Typography className="italic" fontSize="16" fontWeight="500">
                  Nie udało się odczytać kwoty
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-2">
        <InfoLabel title="zapłacono">
          <Select.Async
            items={tradingDocumentConstants.invoicePaymentStatusItems}
            mutationHook={usePatchTradingDocumentMutation}
            selected={tradingDocument.paymentStatus ?? null}
            transformQueryData={paymentStatus => ({
              id: tradingDocument.id,
              paymentStatus: paymentStatus as TradingDocumentPaymentStatus,
            })}
          />
        </InfoLabel>
      </div>
    </div>
  );
};
