import { CorrespondingDocument } from "api/trading-documents/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { LinkToPage } from "components/utils/linkToPage/LinkToPage";
import { getTradingDocumentRedirectUrl } from "pages/tradingDocuments/shared/utils/getTradingDocumentRedirectUrl";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { tradingDocumentUtils } from "utilities/tradingDocuments";

export const useCorrespondingDocumentColumns = () => {
  return useCreateTableColumns<CorrespondingDocument>(
    ({ columnHelper }) => {
      return [
        columnHelper.accessor(row => row, {
          header: "nr dokumentu",
          size: 240,
          cell: info => {
            const correspondingDocument = info.getValue();
            return (
              <div className="d-flex align-items-center gap-2">
                {correspondingDocument.signature ? (
                  <LinkToPage
                    content={correspondingDocument.signature}
                    url={getTradingDocumentRedirectUrl(
                      correspondingDocument.type,
                      correspondingDocument.invoiceType,
                      correspondingDocument.id,
                    )}
                  />
                ) : (
                  <EmptyValue fontSize="14" />
                )}
                {correspondingDocument.isDraft && (
                  <Tag label="oczekuje na zatwierdzenie" type="outlined" variant="success" />
                )}
              </div>
            );
          },
        }),
        columnHelper.text(
          row => tradingDocumentUtils.getTradingDocumentType(row.type, row.invoiceType),
          {
            header: "typ dokumentu",
            size: 120,
            typographyProps: {
              fontSize: "14",
              fontWeight: "600",
            },
          },
        ),
        columnHelper.date(row => row.invoiceIssueDate, {
          header: "data wystawienia",
          size: 120,
          typographyProps: { fontSize: "14", fontWeight: "500" },
        }),
        columnHelper.date(row => row.invoiceDeliveryDate, {
          header: "data dostarczenia",
          size: 120,
          typographyProps: { fontSize: "14", fontWeight: "500" },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};
