import { TradingDocument } from "api/trading-documents/models";
import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { columnFactory } from "../shared/columnsFactory/columnFactory";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../shared/TradingDocumentsLists.module.css";
import {
  isDateOlderThan24Hours,
  isDateOlderThan48Hours,
} from "../shared/utils/isDateOlderThan24Hours";
import { cx } from "utilities";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";

export const useReceiptsColumns = () => {
  return useCreateTableColumns<TradingDocument>(({ columnHelper }) => {
    const columns = columnFactory(columnHelper);
    return [
      columns.invoiceConfirmation(),
      columnHelper.accessor(row => row, {
        header: "nr paragonu",
        size: 120,
        cell: info => {
          const tradingDocument = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1 justify-content-between w-100">
              <Typography className={styles.invoiceSpacing} fontSize="12" fontWeight="500" noWrap>
                {tradingDocument.signature || EMPTY_VALUE}
              </Typography>
              {!isDateOlderThan48Hours(tradingDocument.createdAt) && (
                <div
                  className={cx("d-flex align-items-center", {
                    [styles.highOpacity]: isDateOlderThan24Hours(tradingDocument.createdAt),
                  })}
                >
                  <Tag
                    label={
                      tradingDocumentConstants.tradingDocumentStatusDict[tradingDocument.status]
                    }
                    type="outlined"
                    variant="success"
                  />
                </div>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.printingStatus, {
        header: "status",
        size: 110,
        cell: info => {
          const printingStatus = info.getValue();
          return <PrintingStatus status={printingStatus} />;
        },
      }),
      columns.invoiceOrders(),
      columns.amount(),
      columns.invoicePaymentDate(),
      columns.invoiceIssueDate({ hasHighlight: false }),
      columnHelper.accessor(row => row.printingRequestedBy, {
        header: "zlecił wydruk",
        size: 80,
        cell: info => {
          const printingRequestedBy = info.getValue();
          if (!printingRequestedBy) return <EmptyValue fontSize="12" fontWeight="700" />;
          return (
            <div className="d-flex align-items-center gap-1 w-100">
              <Avatar user={printingRequestedBy} size="xs" />
              <Typography className={styles.invoiceSpacing} fontSize="12" fontWeight="700" noWrap>
                {printingRequestedBy.firstName} {printingRequestedBy.lastName}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.date(row => row.printingRequestedAt, {
        header: "data wydruku",
        size: 100,
        typographyProps: {
          fontSize: "12",
          fontWeight: "500",
        },
      }),
      columns.assignedTo(),
      columnHelper.text(
        row => row.sellerCompanyName ?? `${row.sellerFirstName} ${row.sellerLastName}`,
        {
          header: "sprzedawca",
          size: 250,
          typographyProps: {
            className: styles.invoiceSpacing,
            color: "neutralBlack48",
            fontSize: "12",
            fontWeight: "500",
          },
        },
      ),
    ];
  });
};

const PrintingStatus = ({ status }: { status: TradingDocument["printingStatus"] }) => {
  switch (status) {
    case "FAILED":
      return (
        <Tag
          label={tradingDocumentConstants.receiptPrintingStatusDict[status].label}
          variant="danger"
        />
      );
    case "FINISHED":
      return (
        <Tag
          label={tradingDocumentConstants.receiptPrintingStatusDict[status].label}
          variant="info"
        />
      );
    case "IN_PROGRESS":
      return (
        <Tag
          label={tradingDocumentConstants.receiptPrintingStatusDict[status].label}
          variant="deepPurple400"
        />
      );
    case "NOT_STARTED":
      return (
        <Tag
          label={tradingDocumentConstants.receiptPrintingStatusDict[status].label}
          variant="success"
        />
      );
    default: {
      const exhaustiveCheck = status;
      console.error(`Unhandled printing status: ${exhaustiveCheck}`);
      return <EmptyValue fontSize="14" />;
    }
  }
};

// "20px 1fr 90px 110px 100px 100px 100px 80px 100px 80px 1fr"
