import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "pages/wms/shared/Wms.module.css";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { useMemo } from "react";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useLineItemsDetailsColumns } from "./useLineItemsDetailsColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import {
  AwaitingTransportDetails,
  AwaitingTransportGroupListItem,
  AwaitingTransportPackageGroup,
} from "api/wms/awaiting-transports/models";
import { MdiDepartureBoard } from "components/miloDesignSystem/atoms/icons/MdiDepartureBoard";
import { wmsAwaitingTransportsActions } from "api/wms/awaiting-transports/actions";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { wmsAwaitingTransportsApi } from "api/wms/awaiting-transports/api";
import { queryString } from "utilities";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { getGroupStatus } from "../useGroupColumns";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";

interface Props {
  close: () => void;
  awaitingTransportGroup: AwaitingTransportGroupListItem;
  awaitingTransport: AwaitingTransportDetails;
}

export type NormalizedPackageGroup = Pick<
  AwaitingTransportPackageGroup["packages"][number],
  "id" | "collaborator" | "loadedAt" | "name"
> & {
  subRows?: NormalizedPackageGroup[];
};

export const LineItemsDetailsModal = ({
  close,
  awaitingTransportGroup,
  awaitingTransport,
}: Props) => {
  const {
    data: lineItem,
    error,
    isLoading,
    isFetching,
  } = wmsAwaitingTransportsActions.useGetAwaitingTransportPackagesGroups(
    queryString.stringify({ awaitingTransportGroup: awaitingTransportGroup.id, pageSize: "999" }),
  );
  const columns = useLineItemsDetailsColumns();
  const normalizedData: NormalizedPackageGroup[] = useMemo(() => {
    if (!lineItem) return [];
    return lineItem.map(packageGroup => {
      const subRows: NormalizedPackageGroup[] = (packageGroup.packages || []).map(packageRow => {
        return {
          id: packageRow.id,
          name: packageRow.name,
          collaborator: packageRow.collaborator,
          loadedAt: packageRow.loadedAt,
        } as NormalizedPackageGroup;
      });

      return {
        id: packageGroup.id,
        name: packageGroup.name,
        collaborator: null,
        loadedAt: null,
        subRows,
      } as NormalizedPackageGroup;
    });
  }, [lineItem]);

  if (isLoading)
    return (
      <Modal
        close={close}
        isOpen
        width={750}
        title={
          <Typography fontSize="20" fontWeight="700">
            Szczegóły zamówienia
          </Typography>
        }
      >
        <div className="d-flex align-items-center justify-content-center py-5">
          <Spinner size={32} />
        </div>
      </Modal>
    );

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        width={750}
        title={
          <Typography fontSize="20" fontWeight="700">
            Szczegóły zamówienia
          </Typography>
        }
      >
        <div className="mt-3 py-5">
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  assertIsDefined(lineItem);

  return (
    <Modal
      close={close}
      isOpen
      width={750}
      title={
        <Typography fontSize="20" fontWeight="700">
          Szczegóły zamówienia {awaitingTransportGroup.signature}
        </Typography>
      }
    >
      <div className={styles.lineItemContainer}>
        <div className={styles.lineItemTitle}>
          <Typography fontSize="26" fontWeight="700">
            {awaitingTransport.signature}
          </Typography>
          <div className="d-flex align-items-center gap-1">
            <MdiDepartureBoard size="24" />
            <Typography color="blue300" fontSize="20" fontWeight="700">
              Awizacja
            </Typography>
          </div>
        </div>
        <div className={styles.lineItemSection}>
          <div className={styles.lineItemHeader}>
            <div className={styles.lineItemHeaderGeneral}>
              <Typography fontSize="16" fontWeight="700">
                {awaitingTransportGroup.signature}
              </Typography>
              {getGroupStatus(awaitingTransportGroup.status)}
              {awaitingTransportGroup?.address?.countryCode && (
                <CountryFlag countryCode={awaitingTransportGroup?.address?.countryCode} />
              )}
            </div>

            <FileDownloadHandler
              factoryFn={() =>
                wmsAwaitingTransportsApi.awaitingTransportFileFactory.packagesLabels(
                  queryString.stringify({ packageGroupId: awaitingTransportGroup.id }),
                )
              }
              type="pdf"
            >
              {({ download, isLoading }) => (
                <Tooltip title="Pobierz etykiety">
                  <IconButton
                    icon={MdiQrCode}
                    isLoading={isLoading}
                    onClick={event => {
                      event.stopPropagation();
                      download();
                    }}
                    variant="transparent"
                  />
                </Tooltip>
              )}
            </FileDownloadHandler>
          </div>
          <div className={styles.tableContainer}>
            <Table<NormalizedPackageGroup>
              rows={normalizedData}
              columns={columns}
              isLoading={isLoading || isFetching}
              error={error}
              uiSchema={comfortableListUiSchema}
            />
          </div>
        </div>
        <div>
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
