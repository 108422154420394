import { yup } from "utilities";
import { validationUtils } from "utilities/validationUtils";

export const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("Imię użytkownika jest wymagane")
    .matches(
      /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+(\s[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+)*$/,
      "Imię powinno składać się jedynie z liter",
    ),
  lastName: yup
    .string()
    .required("Nazwisko użytkownika jest wymagane")
    .matches(
      /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+(\s[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+)*$/,
      "Nazwisko powinno składać się jedynie z liter",
    ),
  email: yup.string().required("Email użytkownika jest wymagany"),
  phone: yup
    .string()
    .min(9, "Wprowadź poprawny numer telefonu")
    .matches(/^\+?[0-9]+$/, "Numer telefonu powinien zawierać jedynie cyfry")
    .required("Numer telefonu jest wymagany"),
  password: validationUtils.password,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Wpisane hasła nie pasują do siebie!")
    .required("Wpisz hasło ponownie, by je potwierdzić"),
});
