import { Product } from "api/products/models";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Select } from "components/miloDesignSystem/molecules/select";
import { useProductColumns } from "./useProductsColumns";
import { useState } from "react";
import { useSelector } from "hooks";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import styles from "./BuyingPriceModal.module.css";
import { pluralize, queryString } from "utilities";
import { Pagination } from "hooks/createPaginatedQuery";
import { ErrorType } from "hooks/createApiQuery";
import { Button } from "components/miloDesignSystem/atoms/button";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { indexFileFactory } from "api/indexes/calls";

interface Props {
  close: () => void;
  error: ErrorType | null;
  isPreviousData: boolean;
  isLoading: boolean;
  pagination: Pagination;
  products: Product[];
  selectedProducts: Product[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  setFilter: <
    T extends "page" | "search",
    U extends {
      page: number;
      search: string;
    }[T]
  >(
    name: T,
    value: U,
  ) => void;
}

export const BuyingPriceModalContent = ({
  close,
  error,
  isPreviousData,
  isLoading,
  pagination,
  products,
  selectedProducts,
  setFilter,
  setSelectedProducts,
}: Props) => {
  const priceLists = useSelector(store => store.partials.priceLists);
  const salesAccounts = useSelector(store => store.partials.salesAccounts);
  const [priceList, setPriceList] = useState("");
  const [salesAccount, setSalesAccount] = useState("");
  const priceListsSelector = priceLists.map(priceList => ({
    value: priceList.id,
    text: priceList.name,
    type: MenuItemType.TEXT,
  }));
  const salesAccountSelector = salesAccounts.map(salesAccount => ({
    value: salesAccount.id,
    text: salesAccount.name,
    type: MenuItemType.TEXT,
  }));

  const isProductSelected = (id: string | number): boolean => {
    const foundProduct = selectedProducts.find(product => product.id === id);
    if (foundProduct && foundProduct !== undefined) return true;
    return false;
  };

  const setProductSelectionStatus = (id: string | number): void => {
    if (isProductSelected(id)) {
      setSelectedProducts(prevList => prevList.filter(product => product.id !== id));
    } else {
      const productToAdd = products.find(product => product.id === id);
      if (productToAdd && productToAdd !== undefined) {
        setSelectedProducts(prevList => {
          return [...prevList, productToAdd];
        });
      }
    }
  };

  const areSomeProductsSelected =
    selectedProducts.length > 0 &&
    !products.every(product =>
      selectedProducts.some(selectedProduct => selectedProduct.id === product.id),
    );

  const areAllSelected = products.every(product =>
    selectedProducts.some(selectedProduct => selectedProduct.id === product.id),
  );

  const setAllProductsAsSelected = (): void => {
    if (areAllSelected) {
      setSelectedProducts([]);
    } else {
      const notSelected = products.filter(
        product => !selectedProducts.some(selectedProduct => selectedProduct.id === product.id),
      );

      setSelectedProducts(prevList => [...prevList, ...notSelected]);
    }
  };

  const columns = useProductColumns(
    isProductSelected,
    setProductSelectionStatus,
    areSomeProductsSelected,
    setAllProductsAsSelected,
    areAllSelected,
  );

  const getSearch = () => {
    return queryString.stringify({
      priceList,
      salesAccount,
      productIds: selectedProducts.map(selectedProduct => selectedProduct.id).join(","),
    });
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Pobieranie pliku z cenami zakupowymi
        </Typography>
      }
      width={600}
    >
      <div className="p-3">
        <div className="pb-3">
          <Select
            items={priceListsSelector}
            onChange={id => id && setPriceList(String(id))}
            label="Wybierz cennik"
            selected={priceList}
          />
        </div>
        <div className="pb-3">
          <Select
            items={salesAccountSelector}
            onChange={id => id && setSalesAccount(String(id))}
            label="Wybierz konto sprzedażowe"
            selected={salesAccount}
          />
        </div>
        <div className="pb-3">
          <div className="d-flex align-items-center gap-3">
            <div className={styles.search} style={{ maxWidth: "600px", minWidth: "120px" }}>
              <input
                type="search"
                onChange={event => {
                  setFilter("search", event.target.value);
                  setFilter("page", 1);
                }}
                placeholder="Szukaj produktów..."
              />
            </div>
            <Typography className="mb-3" color="neutralBlack48" fontSize="12" fontWeight="400">
              wybrano {selectedProducts.length}{" "}
              {pluralize.pl(selectedProducts.length, {
                singular: "produkt",
                plural: "produkty",
                other: "produktów",
              })}
            </Typography>
          </div>
          <div className={styles.tableContainer}>
            <Table<Product>
              rows={products}
              columns={columns}
              onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
              isLoading={isLoading || isPreviousData}
              error={error}
              uiSchema={comfortableListUiSchema}
              pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap-3 pt-3 borderTop">
          <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
            Anuluj
          </Button>
          <div className="d-flex align-items-center w-100 gap-3 pr-3">
            <FileDownloadHandler
              factoryFn={() => indexFileFactory.indexesWithPackagesCsv(getSearch())}
              type="csv"
            >
              {({ download, isLoading }) => (
                <Button
                  className="text-uppercase"
                  isLoading={isLoading}
                  onClick={() => {
                    download();
                    close();
                  }}
                  size="medium"
                  variant="deepPurple"
                >
                  Pobierz zestawienie indeksów
                </Button>
              )}
            </FileDownloadHandler>
            <FileDownloadHandler
              factoryFn={() => indexFileFactory.packagesWithPricesAndStateCsv(getSearch())}
              type="csv"
            >
              {({ download, isLoading }) => (
                <Button
                  className="text-uppercase"
                  isLoading={isLoading}
                  onClick={() => {
                    download();
                    close();
                  }}
                  size="medium"
                  variant="deepPurple"
                >
                  Pobierz zestawienie paczek
                </Button>
              )}
            </FileDownloadHandler>
          </div>
        </div>
      </div>
    </Modal>
  );
};
