import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import {
  BulkUpdateCustomMadePackagesPayload,
  BulkUpdateIndexesPackagesNumberPayload,
  BulkUpdateIndexesPayload,
  GeneratePackagesForIndexesPayload,
  IndexCustomMadePackagesPayload,
  IndexDetails,
  IndexListItem,
  IndexPackageListItem,
  IndexStatistics,
  PostIndexAssignmentPayload,
  RemovePackageFromIndexPayload,
} from "./models";
import { indexesKeys } from "./keys";
import { PartialOf } from "typeUtilities";
import { parsePatchData } from "utilities/parsePatchData";
import { Assign } from "utility-types";
import { IndexAssignmentPayload } from "api/indexes/models";

const getIndexes = (search: string): ApiFetcher<Pagination<IndexListItem>> => ({
  key: indexesKeys.indexes.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/indexes/manufacturing" + search,
    }),
});

const getIndex = (indexId: string): ApiFetcher<IndexDetails> => ({
  key: indexesKeys.indexes.details(indexId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/indexes/manufacturing/" + indexId,
    }),
});

const getIndexAssignments = (indexId: number): ApiFetcher<IndexAssignmentPayload> => ({
  key: indexesKeys.indexes.assignments(String(indexId)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/indexes/items/${indexId}/assignments`,
    }),
});

const getIndexStatistics = (indexId: string): ApiFetcher<IndexStatistics> => ({
  key: indexesKeys.indexes.indexStatistics(indexId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/indexes/manufacturing/${indexId}/statistics`,
    }),
});

const patchIndex = (data: PartialOf<IndexDetails>, indexId: UUID) =>
  queryFetch<IndexDetails>({
    method: "PATCH",
    url: "/indexes/manufacturing/" + indexId,
    data: parsePatchData(data),
  });

const getIndexPackages = (search: string): ApiFetcher<Pagination<IndexPackageListItem>> => ({
  key: indexesKeys.packages.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/packages/manufacturing-index" + search,
    }),
});

const patchIndexCustomMadePackages = (data: IndexCustomMadePackagesPayload, search: string) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/packages/bulk-update-custom-made" + search,
    data,
  });

const postGeneratePackagesForIndexes = (data: GeneratePackagesForIndexesPayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/packages/create-from-indexes",
    data,
  });

const postIndexAssignment = (data: PostIndexAssignmentPayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/indexes/assignments",
    data,
  });

const removePackageFromIndex = (data: RemovePackageFromIndexPayload) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/packages/assign",
    data,
  });

const deleteIndexAssignment = (assignmentId: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/indexes/assignments/" + assignmentId,
  });

const bulkUpdateIndexes = (
  data: Assign<PartialOf<BulkUpdateIndexesPayload>, { indexesIds: number[] }>,
) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: "/indexes/bulk-update",
    data,
  });

const bulkUpdateIndexesPackagesNumber = (
  data: Assign<BulkUpdateIndexesPackagesNumberPayload, { indexesIds: number[] }>,
) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: "/indexes/update-number-of-packages",
    data,
  });

const bulkUpdatePackagesInIndexes = (
  data: Assign<BulkUpdateCustomMadePackagesPayload, { indexesIds: number[] }>,
) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: "/packages/bulk-update-packages-in-indexes",
    data,
  });

export const indexesApi = {
  bulkUpdateIndexes,
  bulkUpdatePackagesInIndexes,
  bulkUpdateIndexesPackagesNumber,
  deleteIndexAssignment,
  getIndex,
  getIndexAssignments,
  getIndexStatistics,
  getIndexes,
  getIndexPackages,
  patchIndex,
  patchIndexCustomMadePackages,
  postGeneratePackagesForIndexes,
  postIndexAssignment,
  removePackageFromIndex,
};
