import { materialsActions } from "api/manufacturing/materials/actions";
import { InfoLabel } from "components/common/infoLabel";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiEdit } from "components/miloDesignSystem/atoms/icons/MdiEdit";
import { MdiKeyboardArrowUp } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowUp";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { useQuery, useToggle } from "hooks";
import { AddProviderModal } from "./addProviderModal/AddProviderModal";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { useState } from "react";
import { MdiKeyboardArrowDown } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowDown";
import { SetProviderAsDefaultModal } from "./setProviderAsDefault/SetProviderAsDefaultModal";

export const Provider = () => {
  const { query } = useQuery();
  const [showAll, setShowAll] = useState(false);

  const { data: material } = materialsActions.useMaterial(query.panelId, {
    enabled: Boolean(query.panelId),
  });

  const addProviderModal = useToggle();
  const setProviderAsDefaultModal = useToggle();
  const mainProvider =
    material?.providers.find(provider => provider.isMain) || material?.providers[0];

  return (
    <InfoLabel title="dostawca" className="align-items-start" titleStyle="mt-2">
      <div>
        {mainProvider ? (
          <div className="d-flex align-items-center">
            <Typography fontSize="14" fontWeight="700" className="mr-2">
              {mainProvider?.manufacturer.name}
            </Typography>

            <Tag label="domyślny" variant="deepPurple50" />

            <IconButton
              variant="transparent"
              icon={MdiEdit}
              className="ml-1"
              onClick={setProviderAsDefaultModal.open}
              disabled={
                !Boolean(
                  material?.providers.filter(provider => provider.id !== mainProvider?.id).length,
                )
              }
            />
          </div>
        ) : (
          <Typography fontSize="14" fontWeight="700" className="mr-2">
            {EMPTY_VALUE}
          </Typography>
        )}

        {showAll && (
          <div className="mt-1">
            {material?.providers
              .filter(provider => provider.id !== mainProvider?.id)
              .map(provider => (
                <Typography fontSize="14" fontWeight="400" className="mb-1">
                  {provider.manufacturer.name}
                </Typography>
              ))}
          </div>
        )}

        <div className="mt-1 d-flex align-items-center">
          <IconButton
            variant="gray"
            icon={MdiAdd}
            className="mr-1"
            onClick={addProviderModal.open}
          />
          {Boolean(
            material?.providers.filter(provider => provider.id !== mainProvider?.id).length,
          ) && (
            <Button
              size="small"
              variant="gray"
              endIcon={showAll ? MdiKeyboardArrowUp : MdiKeyboardArrowDown}
              onClick={() => setShowAll(p => !p)}
            >
              Pokaż wszystkich
            </Button>
          )}
        </div>
      </div>
      {addProviderModal.isOpen && (
        <AddProviderModal
          materialId={material!.id}
          manufacturers={material!.providers}
          modal={addProviderModal}
        />
      )}
      {setProviderAsDefaultModal.isOpen && (
        <SetProviderAsDefaultModal
          materialId={material!.id}
          manufacturers={material!.providers}
          modal={setProviderAsDefaultModal}
        />
      )}
    </InfoLabel>
  );
};
