import { shippingActions } from "api/shipping/actions";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody, RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { PanelHeader } from "./components/PanelHeader";
import { GeneralInfoSection } from "./components/generalInfoSection/GeneralInfoSection";
import { PickupSection } from "./components/PickupSection";
import { PiecesSection } from "./components/piecesSection/PiecesSection";
import { DeliverySection } from "./components/DeliverySection";
import { HistorySection } from "./components/HistorySection";
import { CustomShippingServiceOptionsSection } from "./components/CustomShippingServiceOptionsSection";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { StartingPoint } from "./components/generalInfoSection/StartingPoint";

export const RightPanel = () => {
  const { query } = useQuery();
  const { close } = useDrawer("shippingShipments");
  const { panelId } = query;
  const { data: shipment, error, isLoading } = shippingActions.useShippingShipment(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(shipment);

  return (
    <TriggerScrollContext>
      <DrawerRightPanel className="position-relative" key={panelId}>
        <PanelHeader close={close} shipment={shipment} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <GeneralInfoSection shipment={shipment} />
            <CustomShippingServiceOptionsSection shipment={shipment} />
            <PiecesSection shipment={shipment} />
            <RightPanelSection padding="px-3 py-2">
              {shipment.shippingService && <StartingPoint shipment={shipment} />}
            </RightPanelSection>
            <div className="d-flex justify-content-between">
              <PickupSection shipment={shipment} />
              <DeliverySection shipment={shipment} />
            </div>
            <HistorySection shipment={shipment} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
