import { RouteForInvoicing } from "api/routes/models";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { createDraftDocumentUtils } from "pages/tradingDocuments/createDraftDocument/utils";
import {
  EMPTY_VALUE,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useRoutesColumns = (
  isRouteSelected: (id: number) => boolean,
  setRoute: (id: number) => void,
) => {
  return useCreateTableColumns<RouteForInvoicing>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "selectRoute",
        header: " ",
        size: 30,
        cell: info => {
          const route = info.getValue();
          return (
            <Radio
              checked={isRouteSelected(route.id)}
              disabled={createDraftDocumentUtils.areAllPositionsAlreadyInvoiced(route)}
              onClick={() => setRoute(route.id)}
              size="sm"
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "routeDetails",
        header: " ",
        size: 500,
        cell: info => {
          const route = info.getValue();
          return (
            <div>
              <Typography
                color={isRouteSelected(route.id) ? "neutralBlack88" : "neutralBlack64"}
                fontSize="14"
                fontWeight="700"
              >
                {route.signature}
              </Typography>
              <Typography className="italic" color="neutralBlack48" fontSize="10" fontWeight="600">
                {route.name || EMPTY_VALUE}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.stretchContent,
    ];
  });
};
