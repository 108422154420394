import { wmsReturnsActions } from "api/wms/returns/actions";
import { ReturnDetails } from "api/wms/returns/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { noop } from "utilities";

interface Props {
  returnDetails: ReturnDetails;
}

export const StartOrFinishReturnButton = ({ returnDetails }: Props) => {
  const startReturnMutation = wmsReturnsActions.usePostStartReturn();
  const finishReturnMutation = wmsReturnsActions.usePostFinishReturn();

  if (!returnDetails.startedAt && !returnDetails.finishedAt)
    return (
      <Button
        className="text-uppercase"
        isLoading={startReturnMutation.isLoading}
        onClick={() =>
          startReturnMutation.mutate({
            wmsReturnId: returnDetails.id,
          })
        }
        size="small"
        startIcon={MdiCheck}
        variant="outline"
      >
        Rozpocznij zwrot
      </Button>
    );

  if (returnDetails.startedAt && !returnDetails.finishedAt)
    return (
      <Button
        className="text-uppercase"
        isLoading={finishReturnMutation.isLoading}
        onClick={() =>
          finishReturnMutation.mutate({
            wmsReturnId: returnDetails.id,
          })
        }
        size="small"
        startIcon={MdiCheck}
        variant="outline"
      >
        Zakończ zwrot
      </Button>
    );

  return (
    <Button
      className="text-uppercase"
      disabled
      onClick={noop}
      size="small"
      startIcon={MdiCheck}
      variant="success"
    >
      Zakończono zwrot
    </Button>
  );
};
