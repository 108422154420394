import { RightPanelSection } from "components/utils/drawer";
import { useSelector } from "hooks";
import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { manufacturingSchemaActions } from "api/manufacturing/schemas/actions";

interface Props {
  manufacturingStage: ManufacturingStage;
}

export const StageColumnSets = ({ manufacturingStage }: Props) => {
  const stageColumnSets = useSelector(state => state.partials.stageColumnSets);

  return (
    <RightPanelSection>
      <Select.Async
        mutationHook={manufacturingSchemaActions.usePatchManufacturingStage}
        items={stageColumnSets.map(stageColumnSet => ({
          text: stageColumnSet.name,
          type: MenuItemType.TEXT,
          value: stageColumnSet.id,
        }))}
        label="Schemat kolumn"
        selected={manufacturingStage.stageColumnSet?.id ?? ""}
        transformQueryData={columnSetId => {
          const selectedStageColumnSet = stageColumnSets.find(
            columnSet => columnSet.id === columnSetId,
          );
          return {
            id: manufacturingStage.id,
            toUpdate: {
              stageColumnSet: {
                code: "",
                id: selectedStageColumnSet?.id,
                name: selectedStageColumnSet?.name,
              },
            },
          };
        }}
      />
    </RightPanelSection>
  );
};
