import styles from "./Loader.module.css";
import { cx } from "utilities";

export type LoaderTheme = "dark" | "light";

interface Props {
  height: number;
  width: number;
  theme?: LoaderTheme;
}

export const Loader = ({ height, width, theme = "light" }: Props) => {
  return (
    <div
      className={cx(styles.loader, "pt-1 pb-1", {
        [styles.darkLoader]: theme === "dark",
        [styles.lightLoader]: theme === "light",
      })}
      style={{
        height,
        width,
      }}
    />
  );
};
