import { cx } from "utilities";
import styles from "./Attributes.module.css";

/**
 * @deprecated
 */
export const Attributes = ({
  attributes,
  noWrap = false,
}: {
  attributes: { name: string; value: string; id: number | string }[];
  noWrap?: boolean;
}) => {
  return (
    <div
      className={cx(styles.attributesContainer, {
        [styles.noWrap]: noWrap,
      })}
    >
      {attributes.map((attr, index) => (
        <div className={styles.attribute} key={`${attr}${index}`}>
          <span>{attr.name}: </span>
          <strong>{attr.value}</strong>
        </div>
      ))}
    </div>
  );
};
