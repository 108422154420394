import { ReturnDetails, ReturnLineItem, ReturnStatus } from "api/wms/returns/models";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "../RightPanel.module.css";
import { cx, dateFns } from "utilities";
import { ListAvatar } from "pages/wms/shared/components/ListAvatar";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { wmsReturnsActions } from "api/wms/returns/actions";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";

export const usePackagesToReturnColumns = (returnDetails: ReturnDetails) => {
  const handleDownloadLineItemPdf = wmsReturnsActions.useDownloadReturnLineItemPdf();

  return useCreateTableColumns<ReturnLineItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "zamówienie",
        size: 200,
        cell: info => {
          const lineItem = info.getValue();
          const isInProgress =
            lineItem.status === ReturnStatus.IN_PROGRESS &&
            returnDetails.status !== ReturnStatus.FINISHED;
          const isFinished = lineItem.status === ReturnStatus.FINISHED;
          const isNotFinished =
            lineItem.status !== ReturnStatus.FINISHED &&
            returnDetails.status === ReturnStatus.FINISHED;

          return (
            <div
              className={cx(
                "d-flex align-items-center justify-content-between",
                styles.packageOrderCell,
              )}
            >
              <Typography fontSize="12" fontWeight="700" noWrap>
                {lineItem.signature}
              </Typography>
              {isFinished ? (
                <Tag label="OK" startIcon={MdiCheck} variant="success" />
              ) : isInProgress ? (
                <Tag label="trwa" variant="warning" />
              ) : isNotFinished ? (
                <Tag label="BRAK" variant="danger" />
              ) : null}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.suppliers, {
        header: "klient",
        size: 85,
        cell: info => {
          const suppliers = info.getValue();
          if (!Boolean(suppliers.length)) return <EmptyValue />;
          return (
            <div className="d-flex align-items-center gap-1">
              <Typography fontSize="12" fontWeight="700" noWrap>
                {suppliers[0].name}
              </Typography>
              {suppliers.length - 1 > 0 && (
                <Typography color="deepPurple400" fontSize="12" fontWeight="600">
                  +{suppliers.length - 1}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.numberOfPackageGroups, {
        header: "towar",
        size: 40,
        cell: info => {
          const numberOfPackageGroups = info.getValue();
          return (
            <div className="d-flex align-items-center">
              <Typography color="success500" fontSize="14" fontWeight="700">
                {numberOfPackageGroups.inStock}
              </Typography>
              <Typography color="neutralBlack48" fontSize="14" fontWeight="700">
                /{numberOfPackageGroups.total}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.inStockAt, {
        header: "skan",
        size: 85,
        cell: info => {
          const date = info.getValue();
          if (!date) return <EmptyValue />;
          return (
            <Typography fontSize="12" fontWeight="600">
              {dateFns.formatRelative(new Date(date))}
            </Typography>
          );
        },
      }),
      columnHelper.text(
        row => {
          if (Boolean(row.locations.length)) return row.locations.join(", ");
          return null;
        },
        {
          header: "lokalizacja",
          size: 80,
        },
      ),
      columnHelper.accessor(row => row.collaborators, {
        header: "kto?",
        size: 45,
        cell: info => {
          const collaborators = info.getValue();
          return <ListAvatar collaborators={collaborators} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "",
        id: "labels",
        size: 56,
        cell: info => {
          const lineItem = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              <Tooltip title="Pobierz etykiety">
                <IconButton
                  icon={<MdiQrCode size="16" />}
                  onClick={event => {
                    event.stopPropagation();
                    handleDownloadLineItemPdf(lineItem.id, lineItem.signature, lineItem.wmsReturn);
                  }}
                  variant="transparent"
                />
              </Tooltip>
            </div>
          );
        },
      }),
    ];
  });
};
