import { Route } from "api/routes/models";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useNavigate } from "hooks/useNavigate";
import { MdiProgress } from "components/miloDesignSystem/atoms/icons/MdiProgress";
import { useQuery } from "hooks";
import { queryString } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button/Button";

interface Props {
  route: Route;
}

export const NotificationsPanel = ({ route }: Props) => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);
  const navigate = useNavigate();
  return (
    <div className="d-flex align-items-center justify-content-between">
      <Typography fontSize="14" fontWeight="600" color="neutralBlack48">
        Miejsca dostawy
      </Typography>
      <div className="d-flex align-items-center justify-content-end gap-1">
        <Tooltip title="Pokaż postęp produkcji">
          <Button
            className="text-uppercase"
            onClick={() => navigate(`/manufacturing/routes/progress/${route.id}${search}`)}
            size="small"
            startIcon={MdiProgress}
            variant="gray"
          >
            Pokaż postęp produkcji
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
