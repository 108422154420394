import { OwnerConfig } from "./types";
import { config as defaultConfig } from "./default";
import { configMerger } from "./utils";

export const config: OwnerConfig = {
  main: configMerger(defaultConfig.main, {
    orderPanel: {
      manufacturerOrderSection: { _show: false },
      productionOrderSection: {
        _show: false,
      },
    },
  }),
  manufacturing: defaultConfig.manufacturing,
  externalManufacturing: defaultConfig.externalManufacturing,
  b2b: defaultConfig.b2b,
  transport: defaultConfig.transport,
};
