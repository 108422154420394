import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiAddLocationAlt } from "components/miloDesignSystem/atoms/icons/MdiAddLocationAlt";
import { PickupPoints } from "routes/logistics/PickupPoints";

export const pickupPoints: ModuleLink = {
  url: "pickup-points",
  label: "Punkty startowe",
  icon: MdiAddLocationAlt,
  routing: PickupPoints,
};
