import { RouteForInvoicing } from "api/routes/models";
import { DraftInvoiceRouteFilters } from "../../AddRouteToDraft";
import { Table } from "components/miloDesignSystem/molecules/table";
import { Pagination } from "hooks/createPaginatedQuery";
import { createDraftDocumentUtils } from "pages/tradingDocuments/createDraftDocument/utils";
import { useRoutesColumns } from "./useRoutesColumns";
import styles from "../../AddRouteToDraft.module.css";
import { cx } from "utilities";

interface Props {
  isLoading: boolean;
  isPreviousData: boolean;
  isRouteSelected: (id: number) => boolean;
  pagination: Pagination;
  routes: RouteForInvoicing[];
  setFilter: <T extends keyof DraftInvoiceRouteFilters, U extends DraftInvoiceRouteFilters[T]>(
    name: T,
    value: U,
  ) => void;
  setRoute: (id: number) => void;
}

export const RoutesList = ({
  isLoading,
  isPreviousData,
  isRouteSelected,
  pagination,
  routes,
  setFilter,
  setRoute,
}: Props) => {
  const columns = useRoutesColumns(isRouteSelected, setRoute);

  return (
    <Table
      columns={columns}
      rows={routes}
      isLoading={isPreviousData || isLoading}
      error={null}
      pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
      onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
      uiSchema={{
        header: {
          backgroundColor: "neutralWhite88",
        },
        cell: {
          height: "30",
        },
      }}
      overrides={() => {
        return {
          hideHeader: true,
          row: row => {
            if (createDraftDocumentUtils.areAllPositionsAlreadyInvoiced(row)) {
              return {
                className: cx("dashedTableRow", styles.routeRow),
              };
            }
            return {
              className: styles.routeRow,
            };
          },
        };
      }}
    />
  );
};
