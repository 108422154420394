import { manufacturingSchemaActions } from "api/manufacturing/schemas/actions";
import { PostManufacturingSchema } from "api/manufacturing/schemas/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Formik } from "formik";
import { cx } from "utilities";
import { validationSchema } from "./validationSchema";
import { Button } from "components/miloDesignSystem/atoms/button";
import { TextField } from "components/miloDesignSystem/atoms/textField";

interface Props {
  close: () => void;
}

const initialValues: PostManufacturingSchema = {
  name: "",
};

export const AddManufacturingSchema = ({ close }: Props) => {
  const handleSubmit = manufacturingSchemaActions.usePostManufacturingSchema(close);

  return (
    <Modal
      close={close}
      isOpen
      width={480}
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj schemat produkcyjny
        </Typography>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="px-3 pb-3">
              <TextField.Form<PostManufacturingSchema>
                containerClassName="mb-2"
                label="Nazwa schematu"
                name="name"
                placeholder="Nazwa schematu"
              />
            </div>
            <div className="d-flex align-items-center gap-2 p-3 borderTop">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Dodaj
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
