import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery } from "hooks";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useDrawer } from "hooks/useDrawer";
import { manufacturersActions } from "api/manufacturers/actions";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { ManufacturerKind } from "api/manufacturers/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";

export const PanelHeader = () => {
  const { close } = useDrawer("manufacturer");
  const { query } = useQuery();
  const { data: manufacturer } = manufacturersActions.useManufacturer(Number(query.panelId), {
    enabled: Boolean(query.panelId),
  });
  const deleteMutation = manufacturersActions.useDeleteManufacturer(
    close,
    manufacturer!,
    ManufacturerKind.SUPPLIER,
  );
  const { isTriggerElementHidden } = useScrollableContext();

  if (!manufacturer) return null;

  return (
    <RightPanelHeader>
      {isTriggerElementHidden ? (
        <SlidingHeaderWrapper className="noWrap">
          <Typography fontSize="16" fontWeight="600">
            {manufacturer.name}
          </Typography>
        </SlidingHeaderWrapper>
      ) : (
        <div />
      )}
      <div className="d-flex align-items-center justify-content-end gap-1 position-relative">
        <Menu
          disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
          menuItems={[
            {
              type: MenuItemType.TEXT,
              options: {
                color: "red100",
                iconStart: MdiDelete,
              },
              onClick: () => deleteMutation.mutate(manufacturer.id),
              text: "Usuń",
            },
          ]}
        />
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};
