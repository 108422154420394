import { TradingDocument } from "api/trading-documents/models";
import { Button } from "components/common";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { AsyncInput } from "components/utils";
import styles from "../RightPanel.module.css";
import { useEditTradingDocumentTotalValue } from "./hooks/useEditTradingDocumentTotalValue";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { useQueryClient } from "react-query";
import { MdiRedo } from "components/miloDesignSystem/atoms/icons/MdiRedo";
import { restoreTradingDocumentSummaryAmount } from "api/trading-documents/calls";
import { useMutation } from "hooks/useMutation";
interface Props {
  tradingDocument: TradingDocument;
}

export const TotalAmountSection = ({ tradingDocument }: Props) => {
  const { editItemTotalValueMutation, setShowInput, showInput } = useEditTradingDocumentTotalValue(
    tradingDocument.id,
  );
  const queryClient = useQueryClient();
  const restoreAmountMutation = useMutation(restoreTradingDocumentSummaryAmount, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  return (
    <div className="d-flex align-items-center justify-content-end gap-3">
      <IconButton
        disabled={tradingDocument.status === "CONFIRMED" || restoreAmountMutation.isLoading}
        variant="transparent"
        icon={MdiRedo}
        onClick={() => {
          restoreAmountMutation.mutate(tradingDocument.id);
        }}
      />
      <Typography fontSize="14" fontWeight="500">
        razem do zapłaty:
      </Typography>
      {(tradingDocument.invoiceType !== "PURCHASE" && tradingDocument.invoiceType !== "SALES") ||
      tradingDocument.status === "CONFIRMED" ? (
        <div className="btnBase btnBaseSmall">
          <div className="d-flex align-items-center">
            <Typography fontSize="18" fontWeight="700">
              <FinanceAmountFormat value={tradingDocument.amountSummary.totalWithTax} />
            </Typography>
            <div className="fs-12 text-color-grey">{tradingDocument.currency}</div>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-end">
          {!showInput ? (
            <Button
              kind="transparent-black"
              onClick={e => {
                e.stopPropagation();
                setShowInput(true);
              }}
            >
              <div className="btnBase btnBaseSmall">
                <div className="d-flex align-items-baseline">
                  <Typography fontSize="18" fontWeight="700">
                    {Boolean(tradingDocument.amountSummary) ? (
                      <FinanceAmountFormat value={tradingDocument.amountSummary.totalWithTax} />
                    ) : (
                      "brak"
                    )}
                  </Typography>
                  {Boolean(tradingDocument.amountSummary) && (
                    <Typography color="neutralBlack64" fontSize="12" fontWeight="600">
                      {tradingDocument.currency}
                    </Typography>
                  )}
                </div>
              </div>
            </Button>
          ) : (
            <div>
              <AsyncInput
                disabled={editItemTotalValueMutation.isLoading}
                onChange={value => {
                  editItemTotalValueMutation.mutate({
                    id: tradingDocument.id,
                    amountSummary: {
                      ...tradingDocument.amountSummary,
                      totalWithTax: Number(value),
                    },
                  });
                }}
                overwrites={{
                  container: { className: styles.editAmountWithoutTaxInput },
                  input: { className: styles.editAmountWithoutTaxInput },
                }}
                placeholder="cena brutto"
                type="number"
                value={Number(tradingDocument.amountSummary.totalWithTax).toFixed(2)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
