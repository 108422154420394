import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiCut } from "components/miloDesignSystem/atoms/icons/MdiCut";
import { ServiceProviderOrders } from "routes/manufacturingNew/ServiceProviderOrders";

export const serviceProviderOrders: ModuleLink = {
  url: "service-providers-orders",
  label: "Zlecenia do podwykonawców",
  icon: MdiCut,
  routing: ServiceProviderOrders,
  isAccessible: true,
};
