import { RightPanelSection } from "components/utils/drawer";
import CopyToClipboardComponent from "react-copy-to-clipboard";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { MdiCopyAll } from "components/miloDesignSystem/atoms/icons/MdiCopyAll";
import { MdiLink } from "components/miloDesignSystem/atoms/icons/MdiLink";
import { OrderGroup } from "api/order-groups/models";
import { orderGroupActions } from "hooks/apiPrimitives";

interface Props {
  orderGroup: OrderGroup;
}

export const TitleSection = ({ orderGroup }: Props) => (
  <RightPanelSection>
    <div className="d-flex align-items-center gap-2">
      <Typography fontSize="26" fontWeight="700" noWrap>
        {orderGroup.signature}
      </Typography>

      <Tooltip title="Skopiuj sygnaturę">
        <CopyToClipboardComponent text={orderGroup.signature}>
          <IconButton variant="transparent" icon={MdiCopyAll} />
        </CopyToClipboardComponent>
      </Tooltip>

      <Tooltip title="Skopiuj link do strony">
        <CopyToClipboardComponent text={window.location.href}>
          <IconButton variant="transparent" icon={MdiLink} />
        </CopyToClipboardComponent>
      </Tooltip>

      <TextField.Async
        label="Nazwa grupy zamówień"
        placeholder="Wpisz nazwę grupy zamówień"
        size="small"
        value={orderGroup.name}
        mutationHook={orderGroupActions.useOrderGroupPatchMutation}
        transformQueryData={name => ({ id: orderGroup.id, toUpdate: { name } })}
        containerClassName="flex-1"
      />
    </div>
  </RightPanelSection>
);
