import { queryString } from "utilities";
import { OrderPackageExchange } from "api/order-packages-exchange/models";
import { orderPackageExchangeActions } from "api/order-packages-exchange/actions";
import { useExchangeColumns } from "./useExchangeColumns";
import { ProductListItem } from "pages/wms/packageExchange/shared/ProductListItem";
import { normalizeProductExchangePackage } from "pages/wms/packageExchange/shared/normalizeProductExchangePackage";
import { RightPanelSection } from "components/utils/drawer";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";

interface Props {
  orderExchange: OrderPackageExchange;
}

export const PackagesToExchange = ({ orderExchange }: Props) => {
  const { data, isLoading, error } = orderPackageExchangeActions.useProductExchanges(
    queryString.stringify({ orderPackagesExchangeId: orderExchange.id, pageSize: "999" }),
  );
  const columns = useExchangeColumns();

  if (isLoading) {
    return (
      <RightPanelSection title="Potrzebne produkty:">
        <div className="mt-3 flex-1 p-3">
          <Spinner size={40} />
        </div>
      </RightPanelSection>
    );
  }

  if (error)
    return (
      <RightPanelSection title="Potrzebne produkty:">
        <div className="mt-3">
          <CommonError status={error._httpStatus_} />
        </div>
      </RightPanelSection>
    );

  return (
    <RightPanelSection title="Potrzebne produkty:">
      <div className="d-flex flex-1 flex-column overflow-auto ">
        {data.map((productExchange, index) => (
          <div key={productExchange.id}>
            <ProductListItem
              columns={columns}
              index={index}
              productName={productExchange.product.name}
              packages={normalizeProductExchangePackage(productExchange)}
            />
          </div>
        ))}
      </div>
    </RightPanelSection>
  );
};
