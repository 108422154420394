import { InfoLabel } from "components/common/infoLabel";
import { TradingDocument } from "api/trading-documents/models";
import { useSelector } from "hooks";
import { RightPanelSection } from "components/utils/drawer";
import { Select } from "components/utils";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";

interface Props {
  tradingDocument: TradingDocument;
}

export const AssignAccountancyAccount = ({ tradingDocument }: Props) => {
  const changeAccountancyAccountMutation = usePatchTradingDocumentMutation();
  const ledgerAccounts = useSelector(store => store.partials.ledgerAccounts);

  return (
    <RightPanelSection title="">
      <div className="pb-3 w-100">
        <InfoLabel title="konto księgowe">
          <Select
            width="full"
            size="small"
            items={ledgerAccounts}
            onChange={value => {
              if (value) {
                changeAccountancyAccountMutation.mutate({
                  id: tradingDocument.id,
                  ledgerAccount: value,
                });
              }
            }}
            selectedItem={tradingDocument.ledgerAccount ? tradingDocument.ledgerAccount.name : ""}
            itemToDisplaySelected={() => (
              <div className="d-flex align-items-center">
                <span className="fs-16">
                  {tradingDocument.ledgerAccount
                    ? tradingDocument.ledgerAccount.name
                    : "Wybierz konto"}
                </span>
              </div>
            )}
          />
        </InfoLabel>
      </div>
    </RightPanelSection>
  );
};
