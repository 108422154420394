import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { ManufacturerKind } from "api/manufacturers/models";
import { Search } from "components/miloDesignSystem/molecules/search";
import { manufacturersApi } from "api/manufacturers/calls";
import { PostOrderManufacturingMaterialFromValues } from "./OrderMaterialModal";
import { useFormikContext } from "formik";
import { NormalizedItem } from "components/miloDesignSystem/molecules/search/types";
import { ErrorMessage } from "components/utils";

export const useOrderMaterialColumns = () => {
  return useCreateTableColumns<PostOrderManufacturingMaterialFromValues["orders"][number]>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.name, {
          header: "nazwa",
          size: 200,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "16",
          },
        }),
        columnHelper.text(row => row.category?.name ?? null, {
          header: "kategoria",
          size: 200,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "16",
            fontWeight: "500",
          },
        }),
        columnHelper.accessor(row => row, {
          header: "ilość materiału",
          size: 150,
          cell: info => {
            const row = info.getValue();
            return (
              <div className="d-flex align-items-center gap-1">
                <TextField.Form
                  size="small"
                  name={`orders[${info.row.index}].quantity`}
                  containerClassName="w-50"
                />

                <Typography fontSize="14" fontWeight="500" color="neutralBlack48">
                  {row.unit.shortName}
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          header: "dostawca",
          size: 183,
          cell: info => {
            const row = info.getValue();
            return <SelectManufacturerColumn row={row} index={info.row.index} />;
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};

const SelectManufacturerColumn = ({
  row,
  index,
}: {
  row: PostOrderManufacturingMaterialFromValues["orders"][number];
  index: number;
}) => {
  const context = useFormikContext<PostOrderManufacturingMaterialFromValues>();
  return (
    <div className="position-relative">
      <Search
        textFieldProps={{ size: "small" }}
        externalSelectedItem={row.manufacturer}
        fetcherFn={manufacturersApi.getManufacturersQuery}
        transformQuery={{ kind: ManufacturerKind.SUPPLIER }}
        isNullable={false}
        onChange={manufacturer => {
          const normalizedManufacturer: NormalizedItem = {
            text: manufacturer.name,
            value: String(manufacturer.id),
          };
          context.setFieldValue(`orders[${index}].manufacturer`, normalizedManufacturer);
        }}
      />
      <ErrorMessage name={`orders[${index}].manufacturer`} />
    </div>
  );
};
