import { TradingDocument } from "api/trading-documents/models";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { columnFactory } from "../shared/columnsFactory/columnFactory";
import styles from "../shared/TradingDocumentsLists.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import {
  isDateOlderThan24Hours,
  isDateOlderThan48Hours,
} from "../shared/utils/isDateOlderThan24Hours";
import { cx } from "utilities";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { MoreDataCounter } from "components/common/moreDataCounter/MoreDataCounter";
import { ReplyModal } from "../shared/components/confirmCheckbox/ConfirmCheckbox";

export const useSalesInvoicesColumns = (replyModal: ReplyModal) => {
  return useCreateTableColumns<TradingDocument>(({ columnHelper }) => {
    const columns = columnFactory(columnHelper);
    return [
      columns.invoiceConfirmation(),
      columnHelper.accessor(row => row, {
        header: "nabywca",
        size: 200,
        cell: info => {
          const tradingDocument = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1 justify-content-between w-100">
              <Typography className={styles.invoiceSpacing} fontSize="14" fontWeight="700" noWrap>
                {tradingDocument.buyerCompanyName ||
                  `${tradingDocument.buyerFirstName} ${tradingDocument.buyerLastName}`}
              </Typography>
              {!isDateOlderThan48Hours(tradingDocument.createdAt) && (
                <div
                  className={cx("d-flex align-items-center", {
                    [styles.highOpacity]: isDateOlderThan24Hours(tradingDocument.createdAt),
                  })}
                >
                  <Tag label="Nowa" type="outlined" variant="success" />
                </div>
              )}
            </div>
          );
        },
      }),
      columns.signature(140),
      columns.amount(),
      columnHelper.accessor(
        row =>
          row.correspondingDocuments.filter(
            tradingDocument => tradingDocument.invoiceType === "ADVANCE",
          ),
        {
          header: "f.zaliczkowe",
          size: 200,
          cell: info => {
            const advances = info.getValue();
            if (!Boolean(advances.length)) return <EmptyValue fontSize="14" />;
            return (
              <div className="d-flex align-items-center gap-3">
                {advances.slice(0, 2).map(tradingDocument => (
                  <div className="d-flex align-items-center gap-1" key={tradingDocument.id}>
                    <Typography
                      color={
                        tradingDocument.status === "CONFIRMED" ? "success500" : "neutralBlack100"
                      }
                      fontSize="12"
                      fontWeight="700"
                      noWrap
                    >
                      {tradingDocument.signature}
                    </Typography>
                    {tradingDocument.status === "CONFIRMED" && <MdiCheck size="12" />}
                  </div>
                ))}
                {Boolean(advances.length - 2 > 0) && (
                  <MoreDataCounter counter={advances.length - 2} style={{ width: "max-content" }} />
                )}
              </div>
            );
          },
        },
      ),
      columns.countryCode(),
      columns.invoiceIssueDate({ hasHighlight: false }),
      columns.paymentDeadline(),
      columns.invoiceOrders(),
      columns.isDelivered(),
      columns.deliveredAt(),
      columns.paymentStatus(130),
      columns.paymentDeadlineDelta(100),
      columns.notificationStatus(),
      columns.assignedTo(),
      columnHelper.text(
        row => row.sellerCompanyName ?? `${row.sellerFirstName} ${row.sellerLastName}`,
        {
          header: "sprzedawca",
          size: 250,
          typographyProps: {
            className: styles.invoiceSpacing,
            color: "neutralBlack48",
            fontSize: "12",
            fontWeight: "500",
          },
        },
      ),
    ];
  });
};
