import { Switch, RouteComponentProps } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { CustomerList } from "pages/crm/customers/customerList";
import { CustomerDetails } from "pages/crm/customers/customerDetails";
import { CreateCustomer } from "pages/crm/customers/createCustomer/CreateCustomer";

export const CustomersRouting = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={CustomerList} title="Milo - kontrahenci" />
      <Route
        exact
        path={`${match.path}/create`}
        component={CreateCustomer}
        title="Milo - dodaj kontrahenta"
      />
      <Route
        exact
        path={`${match.path}/:customerId`}
        component={CustomerDetails}
        title="Milo - profil kontrahenta"
      />
      <Route exact path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};
