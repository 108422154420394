import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { assertIsDefined } from "utilities/assertIsDefined";
import { PanelHeader } from "./panelHeader/PanelHeader";
import { GeneralInfoSection } from "./generalInfoSection/GeneralInfoSection";
import { TitleSection } from "./titleSection/TitleSection";
import { PackagesToExchange } from "./packagesToExchange/PackagesToExchange";
import { orderPackageExchangeActions } from "api/order-packages-exchange/actions";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const {
    data: orderExchange,
    error,
    isLoading,
  } = orderPackageExchangeActions.useOrderPackageExchange(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(orderExchange);

  return (
    <DrawerRightPanel className="position-relative" key={panelId}>
      <PanelHeader orderExchange={orderExchange} />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody>
          <TitleSection orderExchange={orderExchange} />
          <GeneralInfoSection orderExchange={orderExchange} />
          <PackagesToExchange orderExchange={orderExchange} />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};
