import styles from "./FilterDate.module.css";
import { useQuery } from "hooks";
import { FilterDateType } from "../../types";
import { DatePicker } from "components/utils/datePicker";
import { Options } from "./components/Options";
import { formatDate } from "../shared/utils/formatDate";
import { outsideClickIgnoreClassFilterDrawer } from "components/common/moduleNavigation/components/filtersSection/FilterDrawerRenderer";

export const FilterDate = <T extends string>({ name, options }: FilterDateType<T>) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const value = query[name];

  const handleChange = (date: Date | null) => {
    updateQuery({ [name]: formatDate(date) });
  };

  return (
    <div>
      <div className="d-flex">
        <DatePicker
          overwrites={{
            container: { className: styles.container },
            popup: { className: outsideClickIgnoreClassFilterDrawer },
          }}
          placeholder="Wybierz"
          className={styles.date}
          value={value}
          onChange={handleChange}
        />
      </div>
      {options && options.length > 0 && <Options name={name} options={options} />}
    </div>
  );
};
