import { useRef, useState, useEffect } from "react";
import * as React from "react";
import styles from "./AsyncInput.module.css";
import cx from "classnames";
import { Spinner } from "../spinner";
import { Assign } from "utility-types";
import { usePrevious } from "hooks";
import { ErrorMessage } from "../errorMessage";

type SharedProps<T> = {
  /**
   * disables input interactions; It is required, because we shouldn't let users
   * to edit during async update, because it causes server request race-condition issues.
   */
  disabled: boolean;
  value: T;
  onChange: (value: T) => void;
  type?: "string" | "number" | "time" | "date" | "password";
  ignoreFocus?: boolean;
  inProgress?: boolean;
  error?: string | null;
  colorVariant?: "dark" | "light";
  overwrites?: {
    button?: { children?: React.ReactNode };
    container?: { className?: string };
    floatingLabel?: { className?: string; style?: React.CSSProperties };
    input?: { className?: string };
  };
  /**
   * A mode - errors are handled internally;
   * B mode - errors are handled externally;
   * In A mode changed data should be updated after success;
   * In B mode changed data should be updated immediately;
   * A is default.
   */
  mode?: "A" | "B";
};
type PropsCommon = {
  look?: "common";
  label: string;
};
type PropsEditable = {
  look: "editable";
  label?: undefined;
};

type Props<T> = Assign<
  React.HTMLAttributes<HTMLInputElement> & React.HTMLAttributes<HTMLTextAreaElement>,
  SharedProps<T> & (PropsCommon | PropsEditable)
>;

const spinnerStyle: React.CSSProperties = { position: "absolute", right: -22, top: -12 };

/**
 * @deprecated
 */
export function AsyncInput<T extends number | string>({
  value: initialValue,
  onChange,
  label,
  look = "common",
  overwrites = {},
  type = "string",
  ignoreFocus = false,
  inProgress = false,
  error: errorText = "",
  colorVariant = "dark",
  mode = "A",
  ...props
}: Assign<React.InputHTMLAttributes<HTMLInputElement>, Props<T>>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<T>(initialValue);
  const [error, setError] = useState(errorText);
  const [isOpen, setOpen] = useState(false);
  const [isFocused, setFocus] = useState(false);
  // const [isRendered, setRendered] = useState(false); // this is needed to hide the input node but keep the animation. Without it the input node will react on keyboard focus.
  // const initialRender = useRef(true);
  const previousOpen = usePrevious(isOpen);
  const previousFocused = usePrevious(isFocused);
  const previousInitialValue = usePrevious(initialValue);
  const isMounted = useRef(true);

  useEffect(() => {
    function handleEnter(e: KeyboardEvent) {
      if (e.key === "Enter") {
        inputRef.current?.blur();
      }
    }
    if (isFocused && !previousFocused) {
      window.addEventListener("keypress", handleEnter);
    }
    return () => window.removeEventListener("keypress", handleEnter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  useEffect(() => {
    if (isOpen || (!previousOpen && !isOpen)) {
      setValue(initialValue);
      setError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setError(errorText);
    }
    if (ignoreFocus) {
      setValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorText, isOpen, ignoreFocus]);

  useEffect(() => {
    if (previousInitialValue !== initialValue) {
      setValue(initialValue);
      setError("");
      setOpen(false);
      setFocus(false);
    }
  }, [initialValue, previousInitialValue, isOpen]);

  useEffect(() => {
    if (isOpen && previousOpen === false && initialValue === value) {
      setError("");
    }
  }, [initialValue, isOpen, previousOpen, value]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // useEffect(() => {
  //   if (initialRender.current) return;
  //   if (isOpen) {
  //     setRendered(true);
  //   } else {
  //     setTimeout(() => {
  //       setRendered(false);
  //     }, 200); // this timeout value is related with css transition value
  //   }
  // }, [isOpen]);

  // useEffect(() => {
  //   initialRender.current = false;
  // }, []);

  function handleClose() {
    if (!isMounted.current) return;

    if (type === "number" && isNaN(Number(value))) {
      setError("Wpisz poprawną liczbę");
    } else {
      if (value !== initialValue && !ignoreFocus) {
        onChange(value);
      } else if (value !== initialValue && ignoreFocus) {
        onChange(value);
        setFocus(false);
      } else {
        setOpen(false);
        setFocus(false);
      }
    }
  }

  function handleSetValue(e: React.ChangeEvent<HTMLInputElement>) {
    if (!isMounted.current) return;

    // It is required for time inputs, because they can change input value without focusing it
    if (!isFocused) {
      setFocus(true);
      return;
    }
    const val = e.target.value as T;
    setValue(val);
    if (type === "number" && isNaN(Number(val))) {
      setError("Wpisz poprawną liczbę");
    } else {
      setError("");
    }
  }

  const inputClassName = styles[look];

  const inputType = type === "string" ? "text" : type;
  return (
    <div
      className={cx(
        styles.inputWrapper,
        { "was-validated": Boolean(error) },
        overwrites.container?.className ?? "",
      )}
    >
      <label className={styles.inputBox}>
        <input
          onClick={() => {
            inputRef.current?.focus();
            setOpen(true);
          }}
          className={cx(overwrites.input?.className || "", styles.input, inputClassName, {
            [styles.focused]: isFocused,
            [styles.lightVariant]: colorVariant === "light",
          })}
          onFocus={() => setFocus(true)}
          autoComplete="off"
          onBlur={handleClose}
          ref={inputRef}
          value={value}
          onChange={handleSetValue}
          type={inputType}
          title={look === "editable" ? props.placeholder : undefined}
          {...props}
        />
        {/* <div style={{ minHeight: 35 }}>
          <AutoTextarea
            onClick={() => {
              inputRef.current?.focus();
              setOpen(true);
            }}
            className={cx(overwrites.input?.className || "", styles.input, inputClassName, {
              [styles.focused]: isFocused,
            })}
            onFocus={() => setFocus(true)}
            autoComplete="off"
            onBlur={handleClose}
            ref={inputRef}
            value={value}
            onChange={handleSetValue}
            type={inputType}
            title={look === "editable" ? props.placeholder : undefined}
            {...props}
          />
        </div> */}

        {label && (
          <span
            className={cx(overwrites.floatingLabel?.className || "", styles.labelFloating)}
            style={overwrites.floatingLabel?.style}
          >
            {label}
          </span>
        )}
      </label>
      <Spinner
        on={inProgress}
        color={{ dark: "blue", light: "white" }[colorVariant] as "blue" | "white"}
        style={spinnerStyle}
      />
      <ErrorMessage
        type="text"
        text={mode === "A" ? error : errorText}
        style={{
          display: (mode === "A" && error) || (mode === "B" && errorText) ? "block" : "none",
        }}
      />
    </div>
  );
}
