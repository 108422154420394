import styled from "@emotion/styled";
import { indexesActions } from "api/manufacturing/indexes/actions";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { useParams } from "react-router";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const FinishedRecipe = () => {
  const { indexId } = useParams<{ indexId: string }>();
  const { data: indexDetails } = indexesActions.useIndex(indexId);

  if (!indexDetails) return null;

  return (
    <StyledFinishedRecipe>
      <div className="d-flex flex-column flex-1">
        <Typography fontSize="16" fontWeight="700" className="mb-1" noWrap>
          {indexDetails.name || EMPTY_VALUE}
        </Typography>
        <div className="d-flex align-items-center justify-content-between flex-1">
          <div className="d-flex align-items-center gap-2">
            <Tag label="gotowy produkt" variant="success" />
            <Typography fontSize="14" fontWeight="700">
              1 szt.
            </Typography>
          </div>
        </div>
      </div>
    </StyledFinishedRecipe>
  );
};

const StyledFinishedRecipe = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  flex: 1;
  gap: 4px;
  border: 1px solid var(--success500);
  border-radius: 4px;
  background-color: var(--success200);
`;
