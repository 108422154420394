import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { TradingDocument } from "api/trading-documents/models";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadXml } from "components/miloDesignSystem/atoms/icons/MdiDownloadXml";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";

export const DownloadFakir = ({
  tradingDocumentIds,
}: {
  tradingDocumentIds: TradingDocument["id"][];
}) => {
  return (
    <FileDownloadHandler
      factoryFn={() => tradingDocumentFileFactory.fakirXml(tradingDocumentIds)}
      type="xml"
    >
      {({ download, isLoading }) => (
        <Tooltip title="Pobierz plik Wapro fakir">
          <IconButton
            icon={<MdiDownloadXml size="18" />}
            isLoading={isLoading}
            onClick={download}
            variant="transparent"
          />
        </Tooltip>
      )}
    </FileDownloadHandler>
  );
};
