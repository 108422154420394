import { zadbanoApi } from "./api";
import { useMutation } from "hooks/useMutation";
import { zadbanoKeys } from "./keys";
import { createApiQuery } from "hooks/createApiQuery";
import { assertIsDefined } from "utilities/assertIsDefined";

const useDefaults = createApiQuery(zadbanoApi.getZadbanoDefaults);
const useDetails = createApiQuery(zadbanoApi.getZadbanoDetails);

const usePatchDefaults = () => {
  return useMutation(zadbanoApi.patchZadbanoDefaults, ({ queryUtils }) => ({
    onMutate: ({ id, toUpdate }) => {
      const prevDetails = queryUtils.handleMutate(zadbanoKeys.defaults(id), toUpdate);
      return { prevDetails };
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(zadbanoKeys.defaults(id), onMutateReturn.prevDetails, error);
    },
  }));
};

const usePatchDetails = () => {
  return useMutation(zadbanoApi.patchZadbanoDetails, ({ queryUtils }) => ({
    onMutate: ({ shipments, ...toUpdate }) => {
      const prevDetails = queryUtils.handleMutate(zadbanoKeys.details(shipments[0]), toUpdate);
      return { prevDetails };
    },
    onError: (error, { shipments }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(zadbanoKeys.details(shipments[0]), onMutateReturn.prevDetails, error);
    },
  }));
};

export const zadbanoActions = {
  usePatchDefaults,
  useDefaults,
  useDetails,
  usePatchDetails,
};
