import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { ManufacturingGroup } from "api/manufacturing/units/models";
import { UUID } from "api/types";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { Modal } from "components/miloDesignSystem/atoms/modal/Modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";

interface Props {
  close: () => void;
  ids: UUID[];
  setGroupDetails?: React.Dispatch<React.SetStateAction<ManufacturingGroup | null>>;
}

export const CancelManufacturingGroupItems = ({ close, ids, setGroupDetails }: Props) => {
  const cancelUnitsMutation = manufacturingUnitsActions.useBulkCancelManufacturingUnits(
    close,
    setGroupDetails,
  );

  return (
    <Modal
      close={close}
      isOpen
      width={480}
      title={
        <Typography fontSize="20" fontWeight="700">
          Oznacz wybrane zlecenia jako anulowane
        </Typography>
      }
    >
      <div className="px-3">
        <Typography fontSize="16" fontWeight="600">
          Proces produkcji już się rozpoczął, czy na pewno chcesz anulować wybrane zlecenia?
        </Typography>
        <div className="d-flex align-items-center gap-2 py-3">
          <Button className="text-uppercase" onClick={close} size="large" variant="transparent">
            Nie
          </Button>
          <Button
            className="text-uppercase"
            isLoading={cancelUnitsMutation.isLoading}
            onClick={() =>
              cancelUnitsMutation.mutate({
                ids,
                isCancelled: true,
              })
            }
            size="large"
            variant="deepPurple"
          >
            Tak
          </Button>
        </div>
      </div>
    </Modal>
  );
};
