import { developerActions } from "api/developer/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Tabs } from "components/miloDesignSystem/molecules/tabs";
import { CommonError } from "components/utils";
import { useSelector } from "hooks";
import { SectionWrapper } from "pages/crm/customers/customerDetails/components/sectionWrapper/SectionWrapper";
import { getAnyErrorKey } from "utilities";
import styled from "@emotion/styled";
import styles from "./SettingsTabSection.module.css";
import { assertIsDefined } from "utilities/assertIsDefined";
import { PropsWithChildren } from "react";
import { PaymentTab } from "./tabs/PaymentTab";

export const SettingsTabSection = () => {
  const developerSettingsId = useSelector(store => store.partials.developers.settings[0].id);
  assertIsDefined(developerSettingsId);
  const { data: settings, error, isLoading } = developerActions.useDeveloperSettings({
    id: developerSettingsId,
  });

  if (isLoading)
    return (
      <SectionWrapper
        className="mb-3 d-flex flex-1 justify-content-center align-items-center"
        minHeight={516}
        width="100%"
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error)
    return (
      <SectionWrapper
        className="mb-3 d-flex flex-1 justify-content-center align-items-center"
        minHeight={516}
        width="100%"
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  assertIsDefined(settings);

  return (
    <SectionWrapper className="mb-3 pt-3 d-flex flex-column flex-1">
      <Tabs mode="state">
        <div className="d-flex gap-2 px-3">
          <Tabs.TabItem label="Płatności" value={1} />
        </div>
        <StyledLine />
        <Tabs.TabContentRenderer value={1}>
          <PaymentTab settings={settings} />
        </Tabs.TabContentRenderer>
      </Tabs>
    </SectionWrapper>
  );
};

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--neutralBlack16);
  margin-top: -1px;
`;

export const TabSection = ({ children }: PropsWithChildren<{}>) => {
  return <div className={styles.tabSection}>{children}</div>;
};
