import { useRedux } from "hooks";
import { useMutation } from "hooks/useMutation";
import { PartialOf } from "typeUtilities";
import { UUID } from "api/types";
import { parsePatchData } from "utilities/parsePatchData";
import { assertIsDefined } from "utilities/assertIsDefined";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createApiQuery } from "hooks/createApiQuery";
import { getAnyErrorKey } from "utilities";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { wmsBasketsApi } from "./api";
import { wmsBasketsKeys } from "./keys";
import { BasketDetails } from "./models";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useErrorToastr } from "hooks/useErrorToastr";
import { fileDownloader } from "fileDownloader";

const useGetBaskets = createPaginatedApiQuery(wmsBasketsApi.getBaskets);
const useGetPackageInBasket = createPaginatedApiQuery(wmsBasketsApi.getPackageInBasket);
const useGetBasket = createApiQuery(wmsBasketsApi.getBasket);

const usePatchBasket = () => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  return useMutation(
    ({ id, toUpdate }: { id: UUID | number; toUpdate: PartialOf<BasketDetails> }) => {
      return wmsBasketsApi.patchBasket(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(wmsBasketsKeys.details(String(id)), toUpdate);
        const prevList = queryUtils.handlePaginatedListUpdate(wmsBasketsKeys.list(), id, toUpdate);
        return { prevList, prevPanel };
      },
      onSuccess: () => {
        refetchPartials();
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(wmsBasketsKeys.details(String(id)), onMutateReturn.prevPanel, error);
        queryUtils.rollbackList(wmsBasketsKeys.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

const usePostBasket = () =>
  useMutation(wmsBasketsApi.postBasket, ({ toastr, queryClient }) => ({
    onSuccess: payload => {
      queryClient.invalidateQueries(wmsBasketsKeys.list());
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: `Dodano kosz: ${payload.code}`,
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

const useDeleteBasket = (close: () => void, basket: BasketDetails) => {
  return withDeleteConfirmation(
    useMutation(wmsBasketsApi.deleteBasket, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(wmsBasketsKeys.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto kosz "${basket.code}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć ten kosz?",
  )();
};

const useDownloadBasketLabel = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (id: UUID, code: string) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = wmsBasketsApi.basketFileFactory.label({
      code,
      id,
    });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });

    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useDownloadPackageLabel = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (uniqueCode: string, internalId: string) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = wmsBasketsApi.basketFileFactory.packageLabel({
      code: uniqueCode,
      internalId,
    });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });

    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useDownloadSmallBasketLabel = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (id: UUID, code: string) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = wmsBasketsApi.basketFileFactory.smallBasketLabel({
      code,
      id,
    });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });

    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

export const wmsBasketsActions = {
  useGetBaskets,
  useGetBasket,
  usePostBasket,
  usePatchBasket,
  useDeleteBasket,
  useDownloadBasketLabel,
  useDownloadPackageLabel,
  useGetPackageInBasket,
  useDownloadSmallBasketLabel,
};
