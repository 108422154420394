import { MaterialsListSearch } from "api/manufacturing/materials/models";
import { queryString } from "./utilities";

const getCategoriesSearch = () =>
  queryString.stringify({
    pageSize: 999,
  });

const getMaterialsSearch = ({ kind, query, tab }: MaterialsListSearch) =>
  queryString.stringify({
    ...query,
    categoryId: tab === "all" ? "" : tab,
    kind,
  });

export const materialsUtils = {
  getCategoriesSearch,
  getMaterialsSearch,
};
