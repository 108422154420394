import { createColumnHelper, AccessorFn, ColumnDef } from "@tanstack/react-table";
import { AvatarColumnDef } from "./types";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { UserWithInitials } from "api/users/models";

export const avatarColumn = <T,>(
  accessorFn: AccessorFn<T>,
  columnDef: AvatarColumnDef<T>,
): ColumnDef<T> => {
  const baseColumnHelper = createColumnHelper<T>();
  return baseColumnHelper.accessor(accessorFn, {
    id: columnDef.header,
    header: columnDef.header,
    size: columnDef.size,
    cell: info => <Avatar {...columnDef.avatarProps} user={info.getValue() as UserWithInitials} />,
  });
};
