import { uniquePackagesFileFactory } from "api/unique-packages/calls";
import { LongHeldPackage } from "api/unique-packages/models";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { MdiDownloadCsv } from "../../../../../components/miloDesignSystem/atoms/icons/MdiDownloadCsv";

interface Props {
  packages: LongHeldPackage[];
}

export const LongHeldPackagesCsvReport = ({ packages }: Props) => {
  return (
    <FileDownloadHandler
      factoryFn={() =>
        uniquePackagesFileFactory.longHeldPackagesCsv(packages.map(_package => _package.id))
      }
      type="csv"
    >
      {({ download, isLoading }) => (
        <Tooltip title="Pobierz raport długo trzymanych paczek">
          <IconButton
            isLoading={isLoading}
            icon={MdiDownloadCsv}
            onClick={download}
            theme="dark"
            variant="transparent"
          />
        </Tooltip>
      )}
    </FileDownloadHandler>
  );
};
