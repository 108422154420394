import { yup } from "utilities";

export const validationSchema = yup.object({
  firstName: yup
    .string()
    .required("Imię jest wymagane")
    .max(150, "Maksymalna długość imienia pracownika to 150 znaków"),
  lastName: yup
    .string()
    .required("Nazwisko jest wymagane")
    .max(150, "Maksymalna długość nazwiska pracownika to 150 znaków"),
  employedPlace: yup.string().required("Miejsce zatrudnienia jest wymagane"),
  number: yup.string().max(100, "Maksymalna długość numeru pracownika to 100 znaków"),
  email: yup
    .string()
    .email("Niepoprawny format adresu e-mail")
    .required("Email jest wymagany"),
  kind: yup.string().required("Typ pracownika jest wymagany"),
  dateOfEmployment: yup.string().required("Data zatrudnienia jest wymagana"),
  phone: yup
    .string()
    .required("Numer telefonu jest wymagany")
    .matches(/^[0-9]+$/, "Numer musi składać się z cyfr")
    .min(9, "Numer telefonu jest zbyt krótki")
    .max(12, "Numer telefonu jest zbyt długi"),
});
