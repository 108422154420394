import styles from "./ResetPassword.module.css";
import { Field, FormikHelpers } from "formik";
import { passwordReset, passwordResetConfirm } from "api/users/calls";
import { useHistory } from "react-router";
import { useQuery, useToastr } from "hooks";
import cx from "classnames";
import { Button } from "components/common";
import { Link } from "react-router-dom";
import bgImg from "assets/images/route.jpeg";
import logoImg from "assets/images/logo.svg";
import kivyImg from "assets/images/kivy.png";
import { ErrorMessage, FormWizard, FormWizardHelpers } from "components/utils";
import arrowImg from "assets/images/50.svg";
import { CodeInput } from "./CodeInput";
import { yup } from "utilities";

export interface Values {
  phone: string;
  newPassword: string;
  codeValue: string[];
  payloadData: {
    uid: string;
    token: string;
    code: number;
    newPassword: string;
  } | null;
}

/**
 * Validators for each form page
 */
const validators = {
  0: yup.object().shape({
    phone: yup.string().required("Wpisz numer telefonu"),
  }),
  1: yup.object().shape({
    codeValue: yup
      .array()
      .of(yup.string())
      .test("len", "Wpisz poprawny kod", val => val.length === 6 && !val.includes("·")),
  }),
  2: yup.object().shape({
    newPassword: yup.string().required("Wpisz nowe hasło"),
  }),
};

const SubmitButton = ({ message, isSubmitting }: { message: string; isSubmitting: boolean }) => (
  <div className="mt-4 d-flex align-items-center justify-content-between">
    <Button kind="primary" size="large" type="submit" disabled={isSubmitting}>
      <span>{message}</span>
    </Button>
  </div>
);

const PreviousButton = ({ previous }: { previous: () => void }) => (
  <div className="mt-5 mb-5">
    <Button kind="secondary-stroke" size="round-s" onClick={previous}>
      <img src={arrowImg} alt="" />
    </Button>
  </div>
);

export const ResetPassword = () => {
  const numberOfCodeDigits = 6;
  const codePlaceholder = "·";
  const history = useHistory();
  const toastr = useToastr();
  const { query } = useQuery();
  const phone = query.phone;
  const initialValues = {
    phone: phone || "",
    newPassword: "",
    codeValue: new Array(numberOfCodeDigits).fill(codePlaceholder),
    payloadData: null,
  };

  const handleResetPassword = async (
    { phone }: Values,
    { setFieldValue, setFieldError }: FormikHelpers<Values>,
  ) => {
    const [payload, error] = await passwordReset({ phone: phone.replace(/ /g, "") });
    if (payload) {
      setFieldValue("payloadData", payload);
      return true;
    } else if (error) {
      setFieldError("phone", error.message);
    }
    return false;
  };

  const handleSubmit = async (
    { codeValue, newPassword, payloadData }: Values,
    { setErrors }: FormikHelpers<Values>,
    { previous }: FormWizardHelpers,
  ) => {
    if (!payloadData) {
      throw new Error("Something went wrong. You don't have any payload Data!");
    }

    const [payload, error] = await passwordResetConfirm({
      code: Number(codeValue.join("")),
      newPassword: newPassword.trim(),
      token: payloadData.token,
      uid: payloadData.uid,
    });

    if (payload) {
      history.push("/login");
    } else if (error) {
      if (error.message === "Niepoprawny kod.") {
        toastr.open({
          type: "failure",
          title: error.message,
          text: "Wpisz kod ponownie",
        });
        previous();
      }
      setErrors({ codeValue: error.message });
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={cx("d-flex justify-content-between p-4", styles.nav)}>
        <div className="app-logo">
          <img src={logoImg} alt="Milo" />
        </div>
      </div>
      <div className={styles.imageBox}>
        <img src={bgImg} alt="" />
      </div>
      <div className={cx("d-flex align-items-center justify-content-center", styles.formBox)}>
        <div className={styles.container}>
          <h1>Odzyskiwanie konta</h1>
          <br />
          <FormWizard initialValues={initialValues} onSubmit={handleSubmit} pages={3}>
            {({ values, previous, errors, isSubmitting }) => (
              <>
                <FormWizard.Page
                  page={0}
                  validate={validators[0]}
                  onPageSubmit={handleResetPassword}
                >
                  <Button
                    kind="secondary-stroke"
                    size="round-s"
                    as={Link}
                    to="/login"
                    className="mt-5 mb-5"
                  >
                    <img src={arrowImg} alt="" />
                  </Button>
                  <h4 className={cx(styles.header, " mb-3")}>Wpisz swój numer telefonu</h4>
                  <Field
                    id="phone"
                    name="phone"
                    autoFocus
                    placeholder="+01 234567890"
                    type="text"
                    className={styles.input}
                  />
                  <ErrorMessage name="phone" />
                  <SubmitButton message="Kontynuuj" isSubmitting={isSubmitting} />
                </FormWizard.Page>
                <FormWizard.Page page={1} validate={validators[1]}>
                  <PreviousButton previous={previous} />
                  <h4 className={cx(styles.header, "mt-5 mb-3")}>
                    Wysłaliśmy 6-cyfrowy kod na Twój telefon. Wprowadź kod aby zmienić hasło.
                  </h4>
                  <div className="d-flex mt-1">
                    <CodeInput
                      numberOfCodeDigits={numberOfCodeDigits}
                      placeholder={codePlaceholder}
                    />
                  </div>
                  <ErrorMessage name="codeValue" />
                  <SubmitButton message="Kontynuuj" isSubmitting={isSubmitting} />
                </FormWizard.Page>

                <FormWizard.Page page={2} validate={validators[2]}>
                  <div className="mt-5">
                    <div className="mb-2">{values.phone}</div>
                    <PreviousButton previous={previous} />
                  </div>
                  <h4 className={cx(styles.header, "mt-5 mb-3")}>Podaj nowe hasło</h4>
                  <Field
                    autoFocus
                    name="newPassword"
                    placeholder="Nowe hasło"
                    type="password"
                    className={styles.input}
                  />
                  <ErrorMessage name="newPassword" />
                  <ErrorMessage type="plain" name="message" error={errors} />
                  <SubmitButton message="Zapisz" isSubmitting={isSubmitting} />
                </FormWizard.Page>
              </>
            )}
          </FormWizard>
        </div>
      </div>
      <div className={cx("p-4", styles.footer)}>
        <span>Crafted by</span>
        <div>
          <a href="https://kivy.pl/pl/">
            <img src={kivyImg} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};
