import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { RightPanelSection } from "components/utils/drawer";
import { TextField } from "components/miloDesignSystem/atoms/textField";

interface Props {
  tradingDocument: TradingDocument;
}

export const RecipientInfoSection = ({ tradingDocument }: Props) => {
  return (
    <RightPanelSection title="Odbiorca">
      <div className="pb-2 flex-1 w-50 d-flex flex-column gap-1">
        <TextField.Async
          label="Nazwa firmy"
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz nazwę firmy"
          size="small"
          transformQueryData={recipientCompanyName => ({
            id: tradingDocument.id,
            recipientCompanyName,
          })}
          value={tradingDocument.recipientCompanyName}
        />
        <TextField.Async
          label="Imię"
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz imię"
          size="small"
          transformQueryData={recipientFirstName => ({
            id: tradingDocument.id,
            recipientFirstName,
          })}
          value={tradingDocument.recipientFirstName}
        />
        <TextField.Async
          label="Nazwisko"
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz nazwisko"
          size="small"
          transformQueryData={recipientLastName => ({
            id: tradingDocument.id,
            recipientLastName,
          })}
          value={tradingDocument.recipientLastName}
        />
        <TextField.Async
          label="Adres"
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz adres (ulicę)"
          size="small"
          transformQueryData={recipientStreet => ({
            id: tradingDocument.id,
            recipientStreet,
          })}
          value={tradingDocument.recipientStreet}
        />
        <div className="d-flex gap-2 justify-content-between">
          <TextField.Async
            label="Kod pocztowy"
            mutationHook={usePatchTradingDocumentMutation}
            placeholder="Wpisz kod pocztowy"
            size="small"
            transformQueryData={recipientPostCode => ({
              id: tradingDocument.id,
              recipientPostCode,
            })}
            value={tradingDocument.recipientPostCode}
          />
          <TextField.Async
            label="Miasto"
            mutationHook={usePatchTradingDocumentMutation}
            placeholder="Wpisz miasto"
            size="small"
            transformQueryData={recipientCity => ({
              id: tradingDocument.id,
              recipientCity,
            })}
            value={tradingDocument.recipientCity}
          />
        </div>
        <TextField.Async
          label="Nr telefonu"
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz numer telefonu"
          size="small"
          transformQueryData={recipientPhone => ({
            id: tradingDocument.id,
            recipientPhone,
          })}
          value={tradingDocument.recipientPhone}
        />
        <TextField.Async
          label="Email"
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz email"
          size="small"
          transformQueryData={recipientEmail => ({
            id: tradingDocument.id,
            recipientEmail,
          })}
          value={tradingDocument.recipientEmail}
        />
        <TextField.Async
          label="NIP"
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz NIP"
          size="small"
          transformQueryData={recipientTaxId => ({
            id: tradingDocument.id,
            recipientTaxId,
          })}
          value={tradingDocument.recipientTaxId}
        />
        <TextField.Async
          label="Nr konta bankowego"
          mutationHook={usePatchTradingDocumentMutation}
          placeholder="Wpisz numer konta bankowego"
          size="small"
          transformQueryData={recipientBankAccountNumber => ({
            id: tradingDocument.id,
            recipientBankAccountNumber,
          })}
          value={tradingDocument.recipientBankAccountNumber}
        />
      </div>
    </RightPanelSection>
  );
};
