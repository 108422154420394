import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { PackageExchange } from "pages/wms/packageExchange/packageExchangeList/PackageExchange";
import { SwapPackageView } from "pages/wms/packageExchange/swapPackageView/SwapPackageView";
import { RouteComponentProps } from "react-router";
import { Redirect, Switch } from "react-router-dom";

export const PackageExchangeRouting = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        component={PackageExchange}
        exact
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}`} />}
        title="Milo - Wymiana paczek"
      />
      <Route
        exact={true}
        path={`${match.path}/swap-packages/:id`}
        component={SwapPackageView}
        title="Milo - Wymiana paczek"
      />
      <Route component={NotFound} exact path="*" title="Milo" />
    </Switch>
  );
};
