import {
  DesiredStatusChoices,
  MarketplaceStatusAttempt,
  ProcessingStatusChoices,
} from "api/marketplace-statuses/models";
import { MdiCheckSmall } from "components/miloDesignSystem/atoms/icons/MdiCheckSmall";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

function getDesiredStatusTag(
  desiredStatus: MarketplaceStatusAttempt["statusEvent"]["desiredStatus"],
) {
  switch (desiredStatus) {
    case DesiredStatusChoices.PROCESSING:
      return (
        <Tag label="Rozpoczęto procesowanie" variant="deepPurple50" startIcon={MdiCheckSmall} />
      );

    case DesiredStatusChoices.SENT:
      return <Tag label="Wysłano zamówienie" variant="success" startIcon={MdiCheckSmall} />;

    case DesiredStatusChoices.FINISHED:
      return <Tag label="Zrealizowano" variant="success" startIcon={MdiCheckSmall} />;

    case DesiredStatusChoices.CANCELLED:
      return <Tag label="Anulowano" variant="warning" />;

    default:
      const exhaustiveCheck: never = desiredStatus;
      console.error(`Unhandled field kind: ${exhaustiveCheck}`);
      return EMPTY_VALUE;
  }
}

function getMarketplaceStatusTag(marketplaceStatus: MarketplaceStatusAttempt) {
  if (marketplaceStatus.statusEvent.processingStatus === ProcessingStatusChoices.NOT_IMPLEMENTED) {
    return <Tag label="Brak połączenia z serwisem" variant="deepPurple50" />;
  }

  if (
    marketplaceStatus.error ||
    marketplaceStatus.statusEvent.processingStatus === ProcessingStatusChoices.FAILED
  ) {
    return <Tag label="Wystąpił błąd" variant="danger" />;
  }

  return getDesiredStatusTag(marketplaceStatus.statusEvent.desiredStatus);
}

function getProcessingStatusTag(
  processingStatus: MarketplaceStatusAttempt["statusEvent"]["processingStatus"],
) {
  switch (processingStatus) {
    case ProcessingStatusChoices.FAILED:
      return <Tag label="Wystąpił błąd" variant="danger" />;

    case ProcessingStatusChoices.NOT_IMPLEMENTED:
      return <Tag label="Brak połączenia z serwisem" variant="deepPurple50" />;

    case ProcessingStatusChoices.FINISHED:
      return <Tag label="Zsynchronizowano" variant="success" startIcon={MdiCheckSmall} />;

    case ProcessingStatusChoices.IN_PROGRESS:
      return <Tag label="W trakcie synchronizacji" variant="deepPurple400" type="outlined" />;

    case ProcessingStatusChoices.NOT_STARTED:
      return <Tag label="Nie synchronizowano" variant="warning" type="outlined" />;

    default:
      const exhaustiveCheck: never = processingStatus;
      console.error(`Unhandled field kind: ${exhaustiveCheck}`);
      return EMPTY_VALUE;
  }
}

export const marketplaceStatusesUtilities = {
  getProcessingStatusTag,
  getMarketplaceStatusTag,
};
