import styles from "../ReviewerLabel.module.css";
import cx from "classnames";
import { InvoiceReviewerStatus } from "api/trading-documents/models";
import { tradingDocumentConstants } from "constants/tradingDocuments";

interface Props {
  status: InvoiceReviewerStatus;
}

export const ReviewerLabelIcon = ({ status }: Props) => {
  return (
    <div
      key={status}
      className={cx(styles.approvalStatus, {
        "bg-deep-orange-10": status === "CHANGES_REQUESTED",
        "bg-deep-purple-10": status === "AWAITING",
        "bg-teal-10": status === "ACCEPTED",
      })}
    >
      <img alt="Status recenzji" src={tradingDocumentConstants.invoiceReviewerStatusDict[status]} />
    </div>
  );
};
