import { queryString } from "utilities";
import { Tab } from "./getTabs";
import { ManufacturingOrderStatus } from "api/manufacturing/models";

type GetSearchProps = {
  tab: Tab;
  query: {
    [x: string]: string;
  };
};

export const getSearch = ({ query, tab }: GetSearchProps): string => {
  const tabsQuery = {
    status:
      tab === "not-ordered"
        ? ManufacturingOrderStatus.NOT_ORDERED
        : tab === "ordered"
        ? ManufacturingOrderStatus.ORDERED
        : tab === "received"
        ? ManufacturingOrderStatus.RECEIVED
        : "",
    isCancelled: tab === "cancelled" ? "true" : "",
  };
  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};
