import warehouseAltIcon from "assets/images/warehouseAlt2.svg";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { WarehouseFinancialState } from "routes/WarehouseFinancialState";

export const warehouseFinancialState: ModuleLink = {
  url: "warehouse-financial-state",
  label: "Finansowy stan magazynu",
  icon: warehouseAltIcon,
  routing: WarehouseFinancialState,
};
