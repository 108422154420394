import { Courier } from "api/shipping/models";
import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { shippingActions } from "api/shipping/actions";
import { ShippingService } from "constants/shippingServiceConstants";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { TextField } from "components/miloDesignSystem/atoms/textField";

interface Props {
  courier: Courier;
}

export const GeneralInfoSection = ({ courier }: Props) => {
  return (
    <>
      <RightPanelSection padding="px-3 pt-0 pb-0">
        <div className="d-flex align-items-start justify-content-between gap-2 ">
          <div className="flex-1">
            <InfoLabel title="kurier" className="mt-1">
              <Typography fontSize="14" fontWeight="700">
                {courier.provider === ShippingService["NOT CONNECTED"]
                  ? EMPTY_VALUE
                  : courier.provider}
              </Typography>
            </InfoLabel>
            <InfoLabel title="nazwa" className="mt-1">
              <TextField.Async
                mutationHook={shippingActions.useCourierPatchMutation}
                placeholder="wpisz nazwę"
                transformQueryData={name => ({
                  id: courier.id,
                  toUpdate: {
                    name,
                  },
                })}
                value={courier.name}
              />
            </InfoLabel>
            {courier.provider === ShippingService.INPOST ? (
              <InfoLabel title="klucz autoryzacyjny" className="mt-1">
                <TextField.Async
                  mutationHook={shippingActions.useCourierPatchMutation}
                  placeholder="wpisz klucz"
                  transformQueryData={token => ({
                    id: courier.id,
                    toUpdate: {
                      token,
                    },
                  })}
                  value={courier.token}
                />
              </InfoLabel>
            ) : (
              <>
                <InfoLabel title="nazwa użytkownika" className="mt-1">
                  <TextField.Async
                    mutationHook={shippingActions.useCourierPatchMutation}
                    placeholder="wpisz nazwę użytkownika"
                    transformQueryData={username => ({
                      id: courier.id,
                      toUpdate: {
                        username,
                      },
                    })}
                    value={courier.username}
                  />
                </InfoLabel>
                <InfoLabel title="hasło użytkownika" className="mt-1">
                  <TextField.Async
                    mutationHook={shippingActions.useCourierPatchMutation}
                    placeholder="wpisz hasło użytkownika"
                    transformQueryData={password => ({
                      id: courier.id,
                      toUpdate: {
                        password,
                      },
                    })}
                    value={courier.password}
                  />
                </InfoLabel>
              </>
            )}

            <InfoLabel title="czy zamawianie kuriera jest wymagane" className="mt-2">
              <Checkbox.Async
                checked={courier.isCalledPickupRequired}
                label=""
                mutationHook={shippingActions.useCourierPatchMutation}
                size="sm"
                transformQueryData={isCalledPickupRequired => ({
                  id: courier.id,
                  toUpdate: { isCalledPickupRequired },
                })}
              />
            </InfoLabel>
          </div>
        </div>
      </RightPanelSection>
    </>
  );
};
