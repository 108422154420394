import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Paper } from "components/miloDesignSystem/atoms/paper";
import styles from "./PickupPoints.module.css";
import { cx } from "utilities";
import { MdiAddLocationAlt } from "components/miloDesignSystem/atoms/icons/MdiAddLocationAlt";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { startingPointsUtils } from "utilities/startingPoints";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { useSelector, useToggle } from "hooks";
import { AddCourier } from "./addCourier/AddCourier";
import { ShippingService, shippingServiceConstants } from "constants/shippingServiceConstants";
import { pickupPointsActions } from "api/shipping/pickup-points/actions";
import { PickupPointListItem } from "api/shipping/pickup-points/models";
import { StartingPoint as StartingPointListItem } from "api/other/models";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiEdit } from "components/miloDesignSystem/atoms/icons/MdiEdit";
import { EditOrRemoveCourier } from "./editOrRemoveCourier/EditOrRemoveCourier";
import { AddOrEditStartingPoint } from "./addOrEditStartingPoint/AddOrEditStartingPoint";

interface StartingPointProps {
  startingPoint: StartingPointListItem;
}

interface PickupPointProps {
  pickupPoint: PickupPointListItem;
}

export const StartingPoints = () => {
  const startingPoints = useSelector(store => store.partials.startingPoints);
  const addStartingPointModal = useToggle();

  return (
    <PageWrapper>
      <PageHeader
        createButton={{
          alt: "utwórz",
          label: "Dodaj punkt startowy",
          onClick: addStartingPointModal.open,
        }}
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        title="Punkty startowe"
        viewLabel="STARTING_POINTS"
      />
      <div className="d-flex flex-wrap p-3 gap-3">
        <div className="cursor-pointer" onClick={addStartingPointModal.open}>
          <Paper
            backgroundColor="transparent"
            className={cx(
              styles.addStartingPointPaper,
              "d-flex align-items-center justify-content-center",
            )}
            minHeight={340}
            minWidth="302px"
          >
            <div className="d-flex flex-column align-items-center justify-content-center gap-3">
              <MdiAddLocationAlt color="deepPurple300" size="40" />
              <Typography
                className="text-uppercase"
                color="neutralBlack48"
                fontSize="12"
                fontWeight="700"
              >
                Dodaj punkt startowy
              </Typography>
            </div>
          </Paper>
        </div>
        {startingPoints.map(startingPoint => (
          <StartingPoint key={startingPoint.id} startingPoint={startingPoint} />
        ))}
      </div>
      {addStartingPointModal.isOpen && (
        <AddOrEditStartingPoint close={addStartingPointModal.close} />
      )}
    </PageWrapper>
  );
};

const StartingPoint = ({ startingPoint }: StartingPointProps) => {
  const editOrRemoveCouriersModal = useToggle();
  const editStartingPoint = useToggle();

  return (
    <Paper
      className="d-flex flex-column gap-4 py-3 pr-2 pl-3"
      minHeight={340}
      minWidth="200px"
      width="302px"
    >
      <div className="d-flex p-2 gap-2 justify-content-between">
        <div className="d-flex flex-column gap-3">
          <Typography fontSize="18" fontWeight="400" noWrap>
            {startingPoint.name || EMPTY_VALUE}
          </Typography>
          <div className="d-flex flex-column gap-1">
            <Typography fontSize="18" fontWeight="700" noWrap>
              {startingPoint.street}
            </Typography>
            <Typography fontSize="18" fontWeight="700" noWrap>
              {startingPoint.postCode} {startingPoint.city}
            </Typography>
          </div>
        </div>
        <Menu
          disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
          menuItems={[
            {
              type: MenuItemType.TEXT,
              onClick: editOrRemoveCouriersModal.open,
              options: {
                iconStart: MdiEdit,
              },
              text: "Edytuj lub usuń kurierów",
            },
            {
              type: MenuItemType.TEXT,
              onClick: editStartingPoint.open,
              options: {
                iconStart: MdiEdit,
              },
              text: "Edytuj punkt startowy",
            },
          ]}
        />
      </div>
      <PickupPoints startingPoint={startingPoint} />
      {editOrRemoveCouriersModal.isOpen && (
        <EditOrRemoveCourier
          close={editOrRemoveCouriersModal.close}
          startingPoint={startingPoint}
        />
      )}
      {editStartingPoint.isOpen && (
        <AddOrEditStartingPoint close={editStartingPoint.close} startingPoint={startingPoint} />
      )}
    </Paper>
  );
};

const PickupPoints = ({ startingPoint }: StartingPointProps) => {
  const { data: pickupPoints, error, isLoading } = pickupPointsActions.usePickupPoints(
    startingPointsUtils.getPickupPointsSearch(startingPoint.id),
  );
  const addCourierModal = useToggle();
  const shippingServices = useSelector(store => store.partials.shippingShippingServices);

  if (isLoading)
    return (
      <div className="d-flex flex-column p-2 gap-3">
        <Typography
          className="text-uppercase"
          color="neutralBlack48"
          fontSize="12"
          fontWeight="700"
        >
          używany dla:
        </Typography>
        <div className="d-flex align-items-center justify-content-center">
          <Spinner size={26} />
        </div>
      </div>
    );

  if (error)
    return (
      <div className="d-flex flex-column p-2 gap-3">
        <Typography
          className="text-uppercase"
          color="neutralBlack48"
          fontSize="12"
          fontWeight="700"
        >
          używany dla:
        </Typography>
        <CommonError status={error._httpStatus_} />
      </div>
    );

  return (
    <>
      <div className="d-flex flex-column p-2 gap-3">
        <Typography
          className="text-uppercase"
          color="neutralBlack48"
          fontSize="12"
          fontWeight="700"
        >
          używany dla:
        </Typography>
        <div className={cx(styles.avatarBox, "pr-3")}>
          {pickupPoints.map(pickupPoint => (
            <PickupPoint key={pickupPoint.id} pickupPoint={pickupPoint} />
          ))}
          <div className={styles.addPickupPoint}>
            <IconButton
              className="mr-n3"
              disabled={Boolean(shippingServices.length === pickupPoints.length)}
              icon={MdiAdd}
              onClick={addCourierModal.open}
              shape="round"
              size="large"
              variant="gray"
            />
          </div>
        </div>
      </div>
      {addCourierModal.isOpen && !Boolean(shippingServices.length === pickupPoints.length) && (
        <AddCourier
          close={addCourierModal.close}
          pickupPoints={pickupPoints}
          startingPoint={startingPoint}
        />
      )}
    </>
  );
};

const PickupPoint = ({ pickupPoint }: PickupPointProps) => {
  if (pickupPoint.isDefault)
    return (
      <div className={cx(styles.defaultPickupPointWrapper, "mr-n3")}>
        <div className={styles.courierLogoWrapper}>
          <img
            alt={pickupPoint.shippingService.name}
            className={styles.courierLogo}
            src={
              shippingServiceConstants[pickupPoint.shippingService.provider as ShippingService].logo
            }
          />
        </div>
        <DefaultBadge />
      </div>
    );
  return (
    <div className={cx(styles.courierLogoWrapper, "mr-n3")}>
      <img
        alt={pickupPoint.shippingService.name}
        className={styles.courierLogo}
        src={shippingServiceConstants[pickupPoint.shippingService.provider as ShippingService].logo}
      />
    </div>
  );
};

const DefaultBadge = () => {
  return (
    <div className={styles.defaultBadge}>
      <Typography color="neutralWhite100" fontSize="10" fontWeight="600">
        D
      </Typography>
    </div>
  );
};
