import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody, RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { TitleSection } from "./titleSection/TitleSection";
import { PanelHeader } from "./panelHeader/PanelHeader";
import { wmsBasketsActions } from "api/wms/baskets/actions";
import { PackageInBasketSection } from "./packageInBasketSection/PackageInBasketSection";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const RightPanel = () => {
  const { query } = useQuery();
  const { close } = useDrawer("wmsBaskets");
  const { panelId } = query;
  const { data: basket, error, isLoading } = wmsBasketsActions.useGetBasket(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(basket);

  return (
    <TriggerScrollContext>
      <DrawerRightPanel className="position-relative" key={panelId}>
        <PanelHeader close={close} basket={basket} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <TitleSection basket={basket} />

            <RightPanelSection padding="px-3 pt-2 pb-0">
              <InfoLabel title="lokalizacja">
                <Typography fontSize="14" fontWeight="500">
                  {basket.locations?.length ? basket.locations.toString() : EMPTY_VALUE}
                </Typography>
              </InfoLabel>
            </RightPanelSection>

            <PackageInBasketSection basket={basket} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
