import { indexesActions } from "api/manufacturing/indexes/actions";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { assertIsDefined } from "utilities/assertIsDefined";
import { PanelHeader } from "./panelHeader/PanelHeader";
import { TitleSection } from "./titleSection/TitleSection";
import { GeneralInfoSection } from "./generalInfoSection/GeneralInfoSection";
import { PackagesSection } from "./packagesSection/PackagesSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: index, error, isLoading } = indexesActions.useIndex(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel className="position-static">
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel className="position-static">
        <div className="overflow-hidden h-100 position-relative">
          <MockupLoader type="drawer" />
        </div>
      </DrawerRightPanel>
    );
  }

  if (!panelId) return null;

  assertIsDefined(index);

  return (
    <TriggerScrollContext>
      <DrawerRightPanel className="position-relative" key={panelId}>
        <PanelHeader indexDetails={index} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <TitleSection indexDetails={index} />
            <GeneralInfoSection indexDetails={index} />
            <PackagesSection indexDetails={index} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
