import { Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { ManufacturerOrderList } from "pages/manufacturingNew/manufacturerOrders/ManufacturerOrderList";
import { ManufacturerSingleOrdersList } from "pages/manufacturingNew/manufacturerOrders/manufacturerSingleOrdersList/ManufacturerSingleOrdersList";

export const ManufacturerOrders = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list/:tab`}
        component={ManufacturerOrderList}
        title="Milo - Zlecenia do dostawców"
      />
      <Route
        component={ManufacturerSingleOrdersList}
        exact={true}
        path={`${match.path}/single-orders/:order`}
        title="Milo - pojedyncze zlecenia do dostawców"
      />
      <Route exact={true} path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};
