import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { TabSection } from "../SettingsTabSection";
import styles from "./PaymentTab.module.css";
import { Developer } from "api/other/models";
import { developerActions } from "api/developer/actions";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DatePicker } from "components/utils/datePicker";
import { dateUtils } from "utilities";
import { useEffect, useState } from "react";
import apiFetch from "apiConnectors/fetchConnector";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  settings: Developer["settings"][number];
}

export const PaymentTab = ({ settings }: Props) => {
  const turnOffMiloDateMutation = developerActions.usePatchDeveloperSettings();
  const [meta, setMeta] = useState<any>();

  useEffect(() => {
    async function handleVersionLock() {
      const [meta] = await apiFetch<JSON>({
        method: "GET",
        url: "/deployment_metadata.json",
        origin: "/",
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
        },
      });
      if (meta) {
        const parsedMeta = JSON.parse(JSON.stringify(meta));
        setMeta(parsedMeta);
      }
    }
    handleVersionLock();
  }, []);

  return (
    <div className={styles.tabBody}>
      <TabSection>
        <div className={styles.unpaidInvoiceSection}>
          <Checkbox.Async
            checked={settings.isInfoAboutTurnOffMiloVisible}
            helperText="W przypadku gdy ma nieopłacone faktury"
            label="Wyświetl informację dla klienta o wyłączeniu usługi"
            mutationHook={developerActions.usePatchDeveloperSettings}
            transformQueryData={isInfoAboutTurnOffMiloVisible => ({
              id: settings.id,
              isInfoAboutTurnOffMiloVisible,
            })}
            size="sm"
          />
          <div className={styles.turnOffMiloDateContent}>
            <Typography color="neutralBlack88" fontSize="10" fontWeight="600">
              Wskaż datę wyłączenia usługi
            </Typography>
            <DatePicker
              disabled={turnOffMiloDateMutation.isLoading}
              look="common"
              overwrites={{
                popup: { className: styles.datePickerPopup },
                input: { className: styles.datePickerInput },
                container: { className: styles.datePickerInput },
              }}
              value={settings.turnOffMiloDate || ""}
              onChange={date => {
                if (!date) {
                  return turnOffMiloDateMutation.mutate({
                    id: settings.id,
                    turnOffMiloDate: null,
                  });
                }
                return turnOffMiloDateMutation.mutate({
                  id: settings.id,
                  turnOffMiloDate: dateUtils.formatDateToIso(date),
                });
              }}
            />
          </div>
        </div>
        <div>
          <Typography color="neutralBlack88" fontSize="12" fontWeight="800">
            Metadata:
          </Typography>
          <div>{!meta ? EMPTY_VALUE : Object.keys(meta).map(key => <Typography color="neutralBlack88" fontSize="12" fontWeight="400" key={key}>{`${key}: ${meta[key]}`}</Typography>)
            }</div>
        </div>
      </TabSection>
    </div>
  );
};
