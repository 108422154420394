import { ManufacturerListItem } from "api/manufacturers/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useManufacturerColumns = () => {
  return useCreateTableColumns<ManufacturerListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.fullName, {
        header: "pełna nazwa",
        size: 425,
        typographyProps: {
          fontSize: "16",
        },
      }),
      columnHelper.text(row => row.name, {
        header: "nazwa skrócona",
        size: 200,
        typographyProps: {
          fontSize: "16",
        },
      }),
      columnHelper.text(row => row.email, {
        header: "email",
        size: 200,
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.text(row => row.phone, {
        header: "telefon",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "400",
        },
      }),
      columnHelper.text(
        row => `${row.street ? `ul. ${row.street},` : ""} ${row.postCode} ${row.city}`,
        {
          header: "adres",
          size: 300,
          typographyProps: {
            fontSize: "14",
            fontWeight: "400",
          },
        },
      ),
      columnHelper.text(row => row.taxId, {
        header: "NIP",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.text(row => row.businessRegisterNumber, {
        header: "REGON",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
    ];
  });
};
