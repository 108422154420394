import { countryCodes } from "CONSTANTS";
import { StartingPoint as StartingPointInterface } from "api/other/models";
import { shippingActions } from "api/shipping/actions";
import { Shipment } from "api/shipping/models";
import { pickupPointsActions } from "api/shipping/pickup-points/actions";
import { PickupPointListItem } from "api/shipping/pickup-points/models";
import { InfoLabel } from "components/common/infoLabel";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Select } from "components/miloDesignSystem/molecules/select";
import { CommonError } from "components/utils";
import { useSelector } from "hooks";
import { queryString } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  shipment: Shipment;
}

export const StartingPoint = ({ shipment }: Props) => {
  const search = queryString.stringify({
    shippingServices: shipment.shippingService!.id,
    pageSize: 999,
  });
  const { data: pickupPoints, error, isLoading } = pickupPointsActions.usePickupPoints(search);
  const countries = Object.fromEntries(countryCodes.map(country => [country.value, country.name]));
  const startingPoints = useSelector(store => store.partials.startingPoints);

  const getCouriersStartingPoints = (
    pickupPoints: PickupPointListItem[],
  ): StartingPointInterface[] => {
    return startingPoints.filter(startingPoint =>
      pickupPoints.some(pickupPoint => pickupPoint.pickupPoint === startingPoint.id),
    );
  };

  const getSelectedStartingPoint = (
    pickupPoints: PickupPointListItem[],
  ): StartingPointInterface["id"] | null => {
    const searchedPoint = startingPoints
      .filter(startingPoint =>
        pickupPoints.some(pickupPoint => pickupPoint.pickupPoint === startingPoint.id),
      )
      .find(
        startingPoint =>
          startingPoint.city === shipment.pickupCity &&
          startingPoint.countryCode === shipment.pickupCountryCode &&
          startingPoint.postCode === shipment.pickupPostCode &&
          startingPoint.street === shipment.pickupStreet,
      );
    if (searchedPoint) return searchedPoint.id;
    return null;
  };

  if (isLoading)
    return (
      <InfoLabel title="punkt startowy">
        <Spinner size={16} />
      </InfoLabel>
    );

  if (error)
    return (
      <InfoLabel title="punkt startowy">
        <CommonError status={error._httpStatus_} />
      </InfoLabel>
    );

  if (!pickupPoints)
    return (
      <InfoLabel title="punkt startowy">
        <EmptyValue fontSize="16" />
      </InfoLabel>
    );

  return (
    <InfoLabel title="punkt startowy">
      <div className="w-50">
        <Select.Async
          items={getCouriersStartingPoints(pickupPoints).map(startingPoint => ({
            text: [
              startingPoint.name || EMPTY_VALUE,
              startingPoint.street,
              `${startingPoint.postCode} ${startingPoint.city}`,
              countries[startingPoint.countryCode] || EMPTY_VALUE,
            ].join(", "),
            type: MenuItemType.TEXT,
            value: startingPoint.id,
          }))}
          mutationHook={shippingActions.usePatchShipping}
          selected={getSelectedStartingPoint(pickupPoints)}
          transformQueryData={id => {
            const selectedStartingPoint = startingPoints.find(
              startingPoint => startingPoint.id === id,
            );
            assertIsDefined(selectedStartingPoint);
            return {
              id: shipment.id,
              toUpdate: {
                pickupCity: selectedStartingPoint.city,
                pickupCountryCode: selectedStartingPoint.countryCode,
                pickupPostCode: selectedStartingPoint.postCode,
                pickupStreet: selectedStartingPoint.street,
              },
            };
          }}
        />
      </div>
    </InfoLabel>
  );
};
