import { Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { ManufacturerList } from "pages/manufacturingNew/manufacturers/ManufacturerList";
import { ManufacturerEmailsList } from "pages/manufacturingNew/manufacturers/manufacturerEmailsList/ManufacturerEmailsList";

export const ManufacturersRouting = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list/all`}
        component={ManufacturerList}
        title="Milo - Dostawcy"
      />
      <Route
        exact={true}
        path={`${match.path}/:manufacturerId/emails/:tab`}
        component={ManufacturerEmailsList}
        title="Milo - maile dostawcy"
      />
      <Route exact={true} path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};
