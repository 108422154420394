import { manufacturingActions } from "api/manufacturing/actions";
import { ManufacturerSingleOrderListItem } from "api/manufacturing/models";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { manufacturingConstants } from "constants/manufacturingConstants";
import { dateUtils } from "utilities";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useManufacturerSingleOrdersColumns = () => {
  const orderReceivedMutation = manufacturingActions.usePostManufacturingOrdersReceive();
  return useCreateTableColumns<ManufacturerSingleOrderListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.manufacturingItem?.signature, {
        header: "sygnatura",
        size: 100,
        typographyProps: {
          color: "neutralBlack88",
        },
      }),
      columnHelper.text(row => row.material.name, {
        header: "materiał",
        size: 132,
        typographyProps: {
          color: "neutralBlack88",
        },
      }),
      columnHelper.accessor(row => row, {
        id: "materialCount",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            ilość/liczba
          </Typography>
        ),
        size: 70,
        cell: info => {
          const singleOrder = info.getValue();
          return (
            <Typography
              className="w-100 text-right"
              color="neutralBlack88"
              fontSize="12"
              fontWeight="700"
              noWrap
            >
              {singleOrder.quantity} {singleOrder.material.unit.shortName}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.status, {
        header: "status realizacji",
        size: 120,
        cell: info => {
          const status = info.getValue();
          return <Tag {...manufacturingConstants.manufacturingOrderStatusToTag[status]} />;
        },
      }),
      columnHelper.accessor(row => row.scheduledDeadline, {
        header: "dostawa",
        size: 70,
        cell: info => {
          const deliveryDeadline = info.getValue();
          if (!deliveryDeadline) return <EmptyValue />;

          return (
            <Tag
              label={dateUtils.formatDateToDisplay(deliveryDeadline)}
              type="outlined"
              variant="quaternary"
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "receivedAt",
        header: () => (
          <Typography className="text-center" color="neutralBlack48" fontSize="12" fontWeight="400">
            odebrano
          </Typography>
        ),
        size: 60,
        cell: info => {
          const row = info.getValue();
          return (
            <div className="d-flex align-items-center justify-content-center flex-1">
              <Checkbox
                label=""
                checked={Boolean(row.receivedAt)}
                disabled={Boolean(row.receivedAt) || orderReceivedMutation.isLoading}
                onChange={() => {
                  orderReceivedMutation.mutate({ materialsOrdersItems: [row.id] });
                }}
                size="sx"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "receivedAt",
        header: "data odbioru",
        size: 80,
        cell: info => {
          const row = info.getValue();
          if (!row.receivedAt) return <EmptyValue />;
          return (
            <Tag
              label={dateUtils.formatDateToDisplay(row.receivedAt)}
              type="outlined"
              variant="quaternary"
            />
          );
        },
      }),
    ];
  });
};
