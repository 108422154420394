import { RouteOrder } from "api/routes/models";
import { Assign, Diff, Intersection } from "utility-types";
import styles from "../PointsList.module.css";
import routeIcon from "assets/images/route.svg";
import likeIcon from "assets/images/greenThumbUp.svg";
import cx from "classnames";
import { areConfirmedHoursInCollectionRange } from "../utils/areConfirmedHoursInCollectionRange";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  order: Assign<
    RouteOrder,
    {
      order: number | null;
    },
    Diff<
      RouteOrder,
      {
        order: number | null;
      }
    > &
      Intersection<
        {
          order: number | null;
        },
        RouteOrder
      > &
      Diff<
        {
          order: number | null;
        },
        RouteOrder
      >
  >;
}

export const CollectionHours = ({ order }: Props) => {
  const isConfirmedWithinRange = areConfirmedHoursInCollectionRange(
    order.maximumCollectionHour,
    order.minimumCollectionHour,
    order.maximumCollectionHourAtConfirmation,
    order.minimumCollectionHourAtConfirmation,
  );

  if (isConfirmedWithinRange === false) {
    return (
      <>
        <Tooltip
          title={
            <Typography color="neutralWhite100" fontSize="10" fontWeight="400">
              kierowca będzie
              <Typography color="neutralWhite100" fontSize="10" fontWeight="700">
                &nbsp;w innym&nbsp;
              </Typography>
              niż potwierdzony przedział
            </Typography>
          }
        >
          <div className={styles.collectionHours}>
            <img alt="" src={likeIcon} />
            <div className={styles.negativeCollectionHours}>
              {order.minimumCollectionHourAtConfirmation}&nbsp;-&nbsp;
              {order.maximumCollectionHourAtConfirmation}&nbsp;
            </div>
            <img alt="" src={routeIcon} />
            <div className={styles.routeHoursInvalid}>{order.minimumCollectionHour}</div>
            <div className={styles.routeHoursInvalid}>-</div>
            <div className={styles.routeHoursInvalid}>{order.maximumCollectionHour}</div>
          </div>
        </Tooltip>
      </>
    );
  }

  if (isConfirmedWithinRange === true) {
    return (
      <>
        <Tooltip
          title={
            <Typography color="neutralWhite100" fontSize="10" fontWeight="400">
              godziny są
              <Typography color="neutralWhite100" fontSize="10" fontWeight="700">
                &nbsp;zgodne&nbsp;
              </Typography>
              z dostarczeniem
            </Typography>
          }
        >
          <div className={cx(styles.collectionHours, styles.positiveCollectionHours)}>
            <img alt="" src={likeIcon} />
            <div>{order.minimumCollectionHourAtConfirmation}</div>
            <div>-</div>
            <div>{order.maximumCollectionHourAtConfirmation}</div>
          </div>
        </Tooltip>
      </>
    );
  }

  return (
    <>
      <Tooltip
        title={
          <Typography color="neutralWhite100" fontSize="10" fontWeight="400">
            kierowca będzie
            <Typography color="neutralWhite100" fontSize="10" fontWeight="700">
              &nbsp;w wyznaczonym przedziale,&nbsp;
            </Typography>
            ale przedział nie został potwierdzony
          </Typography>
        }
      >
        <div className={styles.collectionHours}>
          <div>{order.minimumCollectionHour}</div>
          <div>-</div>
          <div>{order.maximumCollectionHour}</div>
        </div>
      </Tooltip>
    </>
  );
};
