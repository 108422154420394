import { EmployeeKindExtended, employeeKindConfigDict } from "CONSTANTS";
import { manufacturingEmployeesActions } from "api/manufacturing/employees/actions";
import {
  ManufacturingEmployeeDetails,
  MethodOfSettlement,
} from "api/manufacturing/employees/models";
import { EmployedPlace } from "api/manufacturingNew/models";
import { InfoLabel } from "components/common/infoLabel";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { Select } from "components/miloDesignSystem/molecules/select";
import { RightPanelSection } from "components/utils/drawer";
import { dictToList, dateUtils } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { manufacturingEmployeesUtils } from "utilities/manufacturingEmployees";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  employee: ManufacturingEmployeeDetails;
}

export const GeneralInfoSection = ({ employee }: Props) => {
  const employeeKindOptions = dictToList(employeeKindConfigDict).map(({ key, value }) => ({
    text: value.name.toLowerCase(),
    type: MenuItemType.TEXT,
    value: key,
  }));
  const dateOfEmploymentMutation = manufacturingEmployeesActions.usePatchManufacturingEmployee();
  const methodOfSettlementMutation = manufacturingEmployeesActions.usePatchManufacturingEmployee();
  const employeePlaceMutation = manufacturingEmployeesActions.usePatchManufacturingEmployee();

  return (
    <RightPanelSection padding="px-3 pt-0">
      <InfoLabel title="aktualne saldo">
        <div className="d-flex align-items-center gap-2">
          {employee.wallet ? (
            <AmountDisplay
              amount={
                manufacturingEmployeesUtils.transfromCentsToPln(employee.wallet.balance) || "0.00"
              }
              currency={employee.wallet.currency}
            />
          ) : (
            <EmptyValue fontSize="14" fontWeight="700" />
          )}
          <Button
            className="text-uppercase"
            disabled
            size="small"
            startIcon={MdiCheck}
            variant="gray"
          >
            Rozlicz
          </Button>
        </div>
      </InfoLabel>
      <InfoLabel title="liczba nierozliczonych">
        <Typography fontSize="14" fontWeight="700">
          {employee.wallet?.unconfirmedTransactions || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="sposób naliczania płatności">
        <div className="d-flex align-items-center gap-2">
          <div
            className="cursor-pointer"
            onClick={() => {
              methodOfSettlementMutation.mutate({
                id: Number(employee.id),
                data: {
                  methodOfSettlement: MethodOfSettlement.PIECEWORK,
                },
              });
            }}
          >
            <Tag
              label="akord"
              variant={
                employee.methodOfSettlement === MethodOfSettlement.PIECEWORK
                  ? "deepPurple50"
                  : "quaternary"
              }
              type={
                employee.methodOfSettlement === MethodOfSettlement.PIECEWORK ? "filled" : "outlined"
              }
            />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              methodOfSettlementMutation.mutate({
                id: Number(employee.id),
                data: {
                  methodOfSettlement: MethodOfSettlement.FIXED_SALARY,
                },
              });
            }}
          >
            <Tag
              label="stałe wynagrodzenie"
              variant={
                employee.methodOfSettlement === MethodOfSettlement.FIXED_SALARY
                  ? "deepPurple50"
                  : "quaternary"
              }
              type={
                employee.methodOfSettlement === MethodOfSettlement.FIXED_SALARY
                  ? "filled"
                  : "outlined"
              }
            />
          </div>
        </div>
      </InfoLabel>
      <InfoLabel title="typ pracownika">
        <Select.Async
          items={employeeKindOptions}
          mutationHook={manufacturingEmployeesActions.usePatchManufacturingEmployee}
          transformQueryData={kind => ({
            id: Number(employee.id),
            data: {
              kind: kind as EmployeeKindExtended,
            },
          })}
          selected={employee.kind}
        />
      </InfoLabel>
      <InfoLabel title="imię">
        <TextField.Async
          mutationHook={manufacturingEmployeesActions.usePatchManufacturingEmployee}
          placeholder="Wpisz imię"
          transformQueryData={firstName => ({
            id: Number(employee.id),
            data: {
              firstName,
            },
          })}
          size="small"
          value={employee.firstName}
        />
      </InfoLabel>
      <InfoLabel title="nazwisko">
        <TextField.Async
          mutationHook={manufacturingEmployeesActions.usePatchManufacturingEmployee}
          placeholder="Wpisz nazwisko"
          transformQueryData={lastName => ({
            id: Number(employee.id),
            data: {
              lastName,
            },
          })}
          size="small"
          value={employee.lastName}
        />
      </InfoLabel>
      <InfoLabel title="email">
        <TextField.Async
          mutationHook={manufacturingEmployeesActions.usePatchManufacturingEmployee}
          placeholder="Wpisz email"
          transformQueryData={email => ({
            id: Number(employee.id),
            data: {
              email,
            },
          })}
          size="small"
          value={employee.email}
        />
      </InfoLabel>
      <InfoLabel title="numer telefonu">
        <TextField.Async
          mutationHook={manufacturingEmployeesActions.usePatchManufacturingEmployee}
          placeholder="Wpisz numer telefonu"
          transformQueryData={phone => ({
            id: Number(employee.id),
            data: {
              phone,
            },
          })}
          size="small"
          value={employee.phone}
        />
      </InfoLabel>
      <InfoLabel title="numer pracownika">
        <TextField.Async
          mutationHook={manufacturingEmployeesActions.usePatchManufacturingEmployee}
          placeholder="Wpisz numer pracownika"
          transformQueryData={number => ({
            id: Number(employee.id),
            data: {
              number,
            },
          })}
          size="small"
          value={employee.number}
        />
      </InfoLabel>
      <InfoLabel className="mt-1 mb-1" title="gdzie zatrudniony">
        <div className="d-flex align-items-center gap-2">
          <div
            className="cursor-pointer"
            onClick={() => {
              employeePlaceMutation.mutate({
                id: Number(employee.id),
                data: {
                  employedPlace: EmployedPlace.INTERNAL,
                },
              });
            }}
          >
            <Tag
              label="wewnętrznie"
              variant={
                employee.employedPlace === EmployedPlace.INTERNAL ? "deepPurple50" : "quaternary"
              }
              type={employee.employedPlace === EmployedPlace.INTERNAL ? "filled" : "outlined"}
            />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              employeePlaceMutation.mutate({
                id: Number(employee.id),
                data: {
                  employedPlace: EmployedPlace.EXTERNAL,
                },
              });
            }}
          >
            <Tag
              label="u kontrahenta"
              variant={
                employee.employedPlace === EmployedPlace.EXTERNAL ? "deepPurple50" : "quaternary"
              }
              type={employee.employedPlace === EmployedPlace.EXTERNAL ? "filled" : "outlined"}
            />
          </div>
        </div>
      </InfoLabel>
      <InfoLabel title="data zatrudnienia">
        <DatePicker
          disabled={dateOfEmploymentMutation.isLoading}
          isNullable={false}
          onChange={dateOfEmployment => {
            assertIsDefined(dateOfEmployment);
            dateOfEmploymentMutation.mutate({
              id: Number(employee.id),
              data: {
                dateOfEmployment: dateUtils.formatDateToIso(dateOfEmployment),
              },
            });
          }}
          value={employee.dateOfEmployment}
        />
      </InfoLabel>
    </RightPanelSection>
  );
};
