import { Bank, BankAccount, BankTransaction, BankTransactionDetails } from "./models";
import { ApiFetcher } from "hooks/createApiQuery";
import { bankAccountsKeys } from "./keys";
import { Pagination } from "api/types";
import { CURRENCY_TYPE } from "CONSTANTS";
import { queryFetch } from "apiConnectors/queryFetch";

export const getBanks = (search: string = ""): ApiFetcher<Bank[]> => ({
  key: bankAccountsKeys.banks(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "bank-accounts/connect-to-users-bank-accounts/step-one/choose-a-bank" + search,
    }),
});

export const buildBankAccountLink = (data: {
  id: string;
  name: string;
  redirectUri: string;
  logo: string;
}) =>
  queryFetch<{
    redirectTo: string;
  }>({
    method: "POST",
    url: `/bank-accounts/connect-to-users-bank-accounts/step-two/build-a-link`,
    data,
  });

export const postBankAccount = (data: { referenceId: string }) =>
  queryFetch<{
    accountSelection: boolean;
    accounts: string[];
    agreement: string;
    created: string;
    id: string;
    institutionId: string;
    link: string;
    redirect: string;
    redirectImmediate: boolean;
    reference: string;
    ssn: unknown | null;
    status: string;
  }>({
    method: "POST",
    url: `/bank-accounts/connect-to-users-bank-accounts/step-three/create-accounts`,
    data,
  });

export const getBankAccounts = (search: string = ""): ApiFetcher<Pagination<BankAccount>> => ({
  key: bankAccountsKeys.bankAccounts(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/bank-accounts/items" + search,
    }),
});

export const getBankAccountTransactions = (
  search: string,
): ApiFetcher<Pagination<BankTransaction>> => ({
  key: bankAccountsKeys.bankAccountsTransactions(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `bank-transactions/items` + search,
    }),
});

export const getBankAccountTransaction = (id: string): ApiFetcher<BankTransactionDetails> => ({
  key: bankAccountsKeys.bankAccountsTransaction(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `bank-transactions/items/` + id,
    }),
});

export const patchBankTransaction = (data: Partial<BankTransaction> & { id: number }) =>
  queryFetch<BankTransaction>({
    method: "PATCH",
    url: `bank-transactions/items/${data.id}`,
    data,
  });

export const deleteBankTransactionOrder = (relationId: number) =>
  queryFetch<BankTransaction>({
    method: "DELETE",
    url: `bank-transactions/orders/${relationId}`,
  });

export const postBankTransactionOrder = (data: {
  order: number;
  bankTransaction: number;
  currency: CURRENCY_TYPE;
  amount: string;
  addedBySource: "MANUALLY";
}) =>
  queryFetch<{
    id: number;
    order: number;
    bankTransaction: number;
    createdBy: {
      id: number;
      avatar: string;
      firstName: string;
      lastName: string;
      phone: string;
      isActive: boolean;
      isSuperuser: boolean;
    };
    created: string;
  }>({
    method: "POST",
    url: `bank-transactions/orders`,
    data,
  });

export const postBankTransactionXMLFile = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/bank-transactions/import-xml-file",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.xlsm"',
    },
    onUploadProgress,
  });

export const postBankTransactionCSVFile = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/bank-transactions/import-csv-files",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.xlsm"',
    },
    onUploadProgress,
  });
