import { Button } from "components/common";
import ReactTooltip from "react-tooltip";
import styles from "./PopupMenu.module.css";
import optionsImg from "assets/images/41.svg";

/**
 * @deprecated
 */
export const PopupMenu: React.FC<{
  dataFor: string;
  customButton?: any;
  customStyles?: {
    readonly [key: string]: string;
  };
}> = ({ children, dataFor, customStyles, customButton }) => {
  let stylez = styles;
  if (customStyles) stylez = customStyles;

  return (
    <div className="position-relative">
      {customButton ? (
        customButton
      ) : (
        <Button
          kind="secondary"
          size="round-s"
          className={stylez.moreOptions}
          data-for={dataFor}
          data-event="click"
          data-event-off="mouseleave"
          data-tip="2"
        >
          <img src={optionsImg} alt="Więcej opcji" />
        </Button>
      )}

      <ReactTooltip
        className={stylez.tooltip}
        id={dataFor}
        type="light"
        delayHide={400}
        effect="solid"
        place="bottom"
      >
        <div className={stylez.tooltipContainer}>{children}</div>
      </ReactTooltip>
    </div>
  );
};
