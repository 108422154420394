import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { useQuery, useToggle } from "hooks";
import { AddCarrierModal } from "./addCarrierModal/AddCarrierModal";
import { useCarriers } from "api/logistics/carriers/hooks";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Carrier, CarrierTab } from "api/logistics/carriers/models";
import { useCarriersListColumns } from "./hooks/useCarriersListColumns";
import { RouteComponentProps } from "react-router";
import { getSearch } from "./utils/getSearch";
import { RightPanel } from "./rightPanel/RightPanel";
import { getTabs } from "./utils/getTabs";
import { carriers } from "components/common/moduleNavigation/moduleConfig/logistics/routes/carriers";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";

export const CarriersList = ({ match }: RouteComponentProps<{ tab: CarrierTab }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const { data, error, isLoading, isPreviousData, pagination } = useCarriers(search);
  const addCarrierModal = useToggle();
  const columns = useCarriersListColumns();
  const tableProps = useTableFeatureConnector({
    rows: data,
    withDrawer: "logisticsCarriers",
    withPagination: { pagination, defaultPaginationVisibility: false },
  });

  return (
    <>
      <PageWrapper>
        <PageHeader
          searchInput={{
            label: "Szukaj wśród przewoźników",
            tags: [],
          }}
          createButton={{
            alt: "utwórz",
            img: darkPlusIcon,
            label: "Utwórz przewoźnika",
            onClick: addCarrierModal.open,
          }}
          tabs={{
            list: getTabs(),
            routesRoot: `logistics/${carriers.url}`,
            urlSpan: "list",
          }}
          viewLabel="LOGISTICS_CARRIERS"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<Carrier>
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={mainListUiSchema}
            {...tableProps}
          />
          <RightPanel />
        </div>
      </PageWrapper>
      {addCarrierModal.isOpen && <AddCarrierModal close={addCarrierModal.close} />}
    </>
  );
};
